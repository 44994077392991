import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import { useEffect, useState } from "react";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { SvgIcons } from "../../Components/Svg/SvgIcons";
import moment from "moment";
import { Link } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import { OrderHelperStatus } from "../../Helper/Helper";

export default function MyOrder() {
  const [Orders, setOrders] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const userDetails = useSelector((store) => store?.user?.userDetails);
  const fetchTickets = () => {
    const options = {
      where: {
        userId: userDetails?.id,
      },
      itemType: "instrument",
      limit: 20,
      pageNumber: 1,
      sortField: "createdAt",
      // sortType: "DESC"
    };
    if (sortBy !== null) {
      options.status = sortBy;
    }
    if (searchTerm !== null) {
      options.search = searchTerm;
    }

    handleFetchRequest(ApiUrl.orderItemList, "POST", options).then(
      (response) => {
        if (response.status === true) {
          // Flatten all OrderItems from the response and set them in state
          setOrders(response?.data?.order);
        } else {
          toast.error(response?.message);
        }
      }
    );
  };

  useEffect(() => {
    fetchTickets();
  }, [sortBy, searchTerm]);
  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <section className="px-0 main-content">
        <div className="max-width-container">
          {/* =========================== */}
          {/* Main feed grid Start */}
          {/* =========================== */}
          <div className="main-feed-grid">
            {/* =========================== */}
            {/*left side content Start */}
            {/* =========================== */}
            <DashboardSidebar />
            {/* =========================== */}
            {/*left side content end */}
            {/* =========================== */}
            {/* =========================== */}
            {/* center content Start */}
            {/* =========================== */}
            <div className="center-content d-block">
              <div className="card tab-card activity-card manage-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="filter-row position-relative">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <p>My Orders</p>
                        </div>
                      </div>
                      <div className="card resource-card my-order-card">
                        <div className="card-body">
                          <div className="filter-row position-relative">
                            <div className="d-flex align-items-center justify-content-between w-100 flex-wrap gap-2">
                              <div className="d-flex align-items-center gap-2 gap-lg-4 flex-wrap">
                                <p>All orders</p>
                                <div className="d-flex align-items-center passfield-wrapper">
                                  <span className="icon">
                                    {SvgIcons?.searchBarIcon}
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="searchbar"
                                    placeholder="Search orders"
                                    onChange={(e) =>
                                      setSearchTerm(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="inner-filter-row">
                                {/* <div className="dropdown dropdown-menu-end">
                                  <button
                                    className="btn btn-light dropdown-toggle d-flex align-items-center gap-2"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    {SvgIcons?.filtericon}
                                    Filter
                                    <span className="selected-num">( 0 )</span>
                                  </button>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        A to Z
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        Newest
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        Oldest
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item clr-all" href="#">
                                        Clear all filter
                                      </a>
                                    </li>
                                  </ul>
                                </div> */}
                                <div className="dropdown dropdown-menu-end">
                                  {sortBy !== null && (
                                    <span className="selected"></span>
                                  )}
                                  <button
                                    className="btn btn-light dropdown-toggle d-flex align-items-center gap-2"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <span>{SvgIcons?.shorByIcon}</span> Filter
                                    by
                                  </button>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <span
                                        className="dropdown-item"
                                        onClick={() => setSortBy(null)}
                                      >
                                        All
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.placed === sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(OrderHelperStatus?.placed)
                                        }
                                      >
                                        Placed
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.confirmed ===
                                          sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(
                                            OrderHelperStatus?.confirmed
                                          )
                                        }
                                      >
                                        Confirmed
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.cancelled ===
                                          sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(
                                            OrderHelperStatus?.cancelled
                                          )
                                        }
                                      >
                                        Cancelled
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.shipped === sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(OrderHelperStatus?.shipped)
                                        }
                                      >
                                        Shipped
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.delivered ===
                                          sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(
                                            OrderHelperStatus?.delivered
                                          )
                                        }
                                      >
                                        Delivered
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.refund_requested ===
                                          sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(
                                            OrderHelperStatus?.refund_requested
                                          )
                                        }
                                      >
                                        Refund Requested
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.refunded === sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(OrderHelperStatus?.refunded)
                                        }
                                      >
                                        Refunded
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.refundCancelled ===
                                          sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(
                                            OrderHelperStatus?.refundCancelled
                                          )
                                        }
                                      >
                                        Refund Cancelled
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          {Orders?.length > 0 ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Product</th>
                                    <th scope="col">Product name</th>
                                    <th scope="col">Date&amp;Time</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Orders &&
                                    Orders.map((res, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>
                                            <img
                                              src={res?.itemDetail?.itemImage}
                                              alt=""
                                            />
                                          </td>
                                          <td>
                                            <Link
                                              to={RoutesLink?.orderDetail}
                                              state={res}
                                            >
                                              {res?.itemDetail?.itemName}
                                              <p> {res?.itemDetail?.brand}</p>
                                            </Link>
                                          </td>
                                          <td>
                                            {moment(res?.createdAt).format(
                                              "LLL"
                                            )}
                                          </td>
                                          <td>
                                            <span
                                              className={`badge ${
                                                res?.status ===
                                                OrderHelperStatus?.cancelled
                                                  ? "cancelled"
                                                  : res?.status ===
                                                    OrderHelperStatus?.placed
                                                  ? "pending"
                                                  : res?.status ===
                                                    OrderHelperStatus?.confirmed
                                                  ? "in-progress"
                                                  : res?.status ===
                                                    OrderHelperStatus?.delivered
                                                  ? "delivered"
                                                  : res?.status ===
                                                    OrderHelperStatus?.shipped
                                                  ? "delivered"
                                                  : res?.status ===
                                                    OrderHelperStatus?.refund_requested
                                                  ? "refunded"
                                                  : res?.status ===
                                                    OrderHelperStatus?.refunded
                                                  ? "refunded"
                                                  : res?.status ===
                                                    OrderHelperStatus?.refundCancelled
                                                  ? "cancelled"
                                                  : ""
                                              }`}
                                            >
                                              {res?.status}
                                            </span>
                                          </td>
                                          <td>${res?.price}</td>
                                          <td>
                                            <Link
                                              to={RoutesLink?.orderDetail}
                                              state={res}
                                              className="text-primary"
                                            >
                                              View More
                                            </Link>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="text-center">No order found</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* =========================== */}
            {/*center content end */}
            {/* =========================== */}
          </div>
          {/* =========================== */}
          {/* Main feed grid End */}
          {/* =========================== */}
        </div>
      </section>
    </>
  );
}
