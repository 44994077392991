import React, { useEffect, useState } from "react";
import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardLayout from "../../Components/Layout/DashboardLayout";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { useLocation } from "react-router-dom";
import { getLoginUserCaption } from "../../Helper/Helper";
import PerformanceCard from "./PerformanceCard";
import LikeActivity from "./LikeActivity";
import CommentActivity from "./CommentActivity";
import ReviewActivitiy from "./ReviewActivitiy";
import SavedActivity from "./SavedActivity";
import { useSelector } from "react-redux";
import TaggedAcitivity from "./TaggedActivity";
import SharedAcitivity from "./SharedActivity";
import InstrumentLikes from "./InstrumentLikes";
import InstrumentShared from "./InstrumentShared";
import InstrumentSaved from "./InstrumentSaved";
import LikeEventAcitivity from "./EventActivity.js/LikeEventAcitivity";
import SavedEventAcitivity from "./EventActivity.js/SavedEventAcitivity";
import SharedEventActivity from "./EventActivity.js/SharedEventActivity";
import ReviewActivities from "./ReviewActivities";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import bootstrapBundle from "bootstrap/dist/js/bootstrap.bundle";

function Activity() {
  const [UserDetials, setUserDetials] = useState([]);
  const [MyPosts, setMyPosts] = useState([]);
  const userDetails = useSelector((store) => store?.user?.userDetails);
  const [MyLikedevent, setMyLikedevent] = useState([]);
  const [MySavedEvent, setMySavedEvent] = useState([]);
  const [MySharedEvent, setMySharedEvent] = useState([]);
  const [MySharedInstrument, setMySharedInstrument] = useState([]);

  const [MySavedInstrument, setMySavedInstrument] = useState([]);

  useEffect(() => {
    handleFetchRequest(
      `${ApiUrl.get_user_detials}${userDetails?.Profile?.userId}`
    ).then((res) => {
      if (res.status === true) {
        setUserDetials(res.data);
      }
    });

    handleFetchRequest(
      `${ApiUrl.get_user_posts}${userDetails?.Profile?.userId}`
    ).then((res) => {
      if (res.status === true) {
        setMyPosts(res?.data?.posts);
      }
    });
    handleFetchRequest(`${ApiUrl.my_liked_event}`).then((res) => {
      if (res.status === true) {
        setMyLikedevent(res?.data);
      }
    });
    handleFetchRequest(`${ApiUrl.my_saved_event}`).then((res) => {
      if (res.status === true) {
        setMySavedEvent(res?.data);
      }
    });
    handleFetchRequest(`${ApiUrl.my_shared_event}`).then((res) => {
      if (res.status === true) {
        setMySharedEvent(res?.data?.events);
      }
    });
    handleFetchRequest(`${ApiUrl.my_shared_instruments}`).then((res) => {
      if (res.status === true) {
        setMySharedInstrument(res?.data?.insturments);
      }
    });
    handleFetchRequest(`${ApiUrl.my_saved_instreuments}`).then((res) => {
      if (res.status === true) {
        setMySavedInstrument(res?.data);
      }
    });
  }, [""]);

  const location = useLocation();

  useEffect(() => {
    // Check if we navigated from the notification (from the state)
    if (location.state?.tag) {
      const taggedTab = document.querySelector("#tagged-tab");
      const taggedTabPane = new bootstrapBundle.Tab(taggedTab);
      taggedTabPane.show();
    }
  }, [location]);

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <section className="px-0 main-content">
        <div className="max-width-container">
          {/* =========================== */}
          {/* Main feed grid Start */}
          {/* =========================== */}
          <div className="main-feed-grid">
            {/* =========================== */}
            {/*left side content Start */}
            {/* =========================== */}
            <DashboardSidebar />
            {/* =========================== */}
            {/*left side content end */}
            {/* =========================== */}
            {/* =========================== */}
            {/* center content Start */}
            {/* =========================== */}
            <div className="center-content d-block">
              <div>
                <div className="card tab-card activity-card">
                  <div className="card-body pb-0 position-relative">
                    <div className="divideder position-absolute" />
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="activity-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#activity-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="activity-tab-pane"
                          aria-selected="true"
                        >
                          Posts Activity
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="equi-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#equi-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="equi-tab-pane"
                          aria-selected="false"
                          tabIndex={-1}
                        >
                          Instruments / Equipments
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="event-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#event-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="event-tab-pane"
                          aria-selected="false"
                          tabIndex={-1}
                        >
                          Events
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade active show"
                        id="activity-tab-pane"
                        role="tabpanel"
                        aria-labelledby="like-tab"
                        tabIndex={0}
                      >
                        <div className="d-flex flex-wrap flex-md-nowrap">
                          <ul
                            className="nav nav-tabs flex-sm-column nav-tabs-vertical"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link active"
                                id="like-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#like-tab-pane"
                                type="button"
                                role="tab"
                                aria-controls="like-tab-pane"
                                aria-selected="false"
                                tabIndex={-1}
                              >
                                Like
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id="comment-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#comment-tab-pane"
                                type="button"
                                role="tab"
                                aria-controls="comment-tab-pane"
                                aria-selected="false"
                                tabIndex={-1}
                              >
                                Comments
                              </button>
                            </li>

                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id="review-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#review-tab-pane"
                                type="button"
                                role="tab"
                                aria-controls="review-tab-pane"
                                aria-selected="false"
                                tabIndex="-1"
                              >
                                Reviews
                              </button>
                            </li>

                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id="tagged-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#tagged-tab-pane"
                                type="button"
                                role="tab"
                                aria-controls="tagged-tab-pane"
                                aria-selected="false"
                                tabIndex={-1}
                              >
                                Tagged
                              </button>
                            </li>

                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link "
                                id="shared-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#shared-tab-pane"
                                type="button"
                                role="tab"
                                aria-controls="shared-tab-pane"
                                aria-selected="true"
                              >
                                Shared
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id="saved-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#saved-tab-pane"
                                type="button"
                                role="tab"
                                aria-controls="saved-tab-pane"
                                aria-selected="false"
                                tabindex="-1"
                              >
                                Saved
                              </button>
                            </li>
                          </ul>
                          <div
                            className="tab-content vertical-content"
                            id="myTabContent"
                          >
                            <LikeActivity callApi={true} />
                            <CommentActivity />
                            <TaggedAcitivity />
                            <SharedAcitivity />
                            <SavedActivity />
                            <ReviewActivities />
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="equi-tab-pane"
                        role="tabpanel"
                        aria-labelledby="comment-tab"
                        tabIndex={0}
                      >
                        <div className="d-flex flex-wrap flex-md-nowrap">
                          <ul
                            className="nav nav-tabs flex-sm-column nav-tabs-vertical"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link active"
                                id="like-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#like-tab-pane2"
                                type="button"
                                role="tab"
                                aria-controls="like-tab-pane"
                                aria-selected="false"
                                tabIndex={-1}
                              >
                                Like
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id="saved-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#saved-tab-pane2"
                                type="button"
                                role="tab"
                                aria-controls="saved-tab-pane"
                                aria-selected="false"
                                tabIndex={-1}
                              >
                                Saved
                              </button>
                            </li>
                            {/* <li className="nav-item" role="presentation">
                              <button
                                className="nav-link "
                                id="shared-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#shared-tab-pane2"
                                type="button"
                                role="tab"
                                aria-controls="shared-tab-pane"
                                aria-selected="true"
                              >
                                Shared
                              </button>
                            </li> */}
                          </ul>
                          <div
                            className="tab-content vertical-content"
                            id="myTabContent"
                          >
                            <InstrumentLikes id={userDetails?.id} />
                            <InstrumentSaved
                              id={userDetails?.Profile?.userId}
                            />
                            {/* <InstrumentShared
                              id={userDetails?.Profile?.userId}
                            /> */}
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="event-tab-pane"
                        role="tabpanel"
                        aria-labelledby="review-tab"
                        tabIndex={0}
                      >
                        <div className="d-flex flex-wrap flex-md-nowrap">
                          <ul
                            className="nav nav-tabs flex-sm-column nav-tabs-vertical"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link active"
                                id="like-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#like-tab-pane3"
                                type="button"
                                role="tab"
                                aria-controls="like-tab-pane"
                                aria-selected="false"
                                tabIndex={-1}
                              >
                                Like
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id="saved-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#saved-tab-pane3"
                                type="button"
                                role="tab"
                                aria-controls="saved-tab-pane"
                                aria-selected="false"
                                tabIndex={-1}
                              >
                                Saved
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link "
                                id="shared-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#shared-tab-pane3"
                                type="button"
                                role="tab"
                                aria-controls="shared-tab-pane"
                                aria-selected="true"
                              >
                                Shared
                              </button>
                            </li>
                          </ul>
                          <div
                            className="tab-content vertical-content"
                            id="myTabContent"
                          >
                            <LikeEventAcitivity LikeEvent={MyLikedevent} />
                            <SavedEventAcitivity LikeEvent={MySavedEvent} />
                            <SharedEventActivity LikeEvent={MySharedEvent} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* =========================== */}
            {/*center content end */}
            {/* =========================== */}
          </div>
          {/* =========================== */}
          {/* Main feed grid End */}
          {/* =========================== */}
        </div>
      </section>
    </>
  );
}

export default Activity;
