import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

function BacklineService({
  open, // Prop to control modal visibility
  close, // Function to close the modal
  backline_serviceData, // Current state of backline service data
  setBacklineServiceData, // Function to update backline service data
  onCountinue, // Function to call when continuing to the next step
}) {
  // Function to handle individual service data selection
  const handleClickServiceData = (id, val) => {
    // Update backline service data with the selected value for the specified ID
    setBacklineServiceData((prevState) =>
      prevState.map((data) => {
        if (data.id === id) {
          return { ...data, answer: val }; // Update the answer for the selected service
        } else {
          return data; // Return the original data for other services
        }
      })
    );
  };

  // Function to handle "Select All" checkbox
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked; // Check if the "Select All" checkbox is checked
    console.log("Checkbox Checked:", isChecked); // Log the checkbox state

    // Update the backline service data to reflect the checkbox selection
    setBacklineServiceData((prevState) => {
      const updatedData = prevState.map((data) => ({
        ...data,
        answer: isChecked, // Set the answer to the checked state for all services
      }));
      console.log("Updated Data:", updatedData); // Log the updated data for debugging
      return updatedData; // Return the updated state
    });
  };
  return (
    <Modal
      className="modal-dialog  get-app-dialog"
      toggle={close}
      isOpen={open}
    >
      <ModalBody className="modal-dialog modal-dialog modal-dialog-centered create-post-dialog modal-fullscreen-lg-down">
        <div className="modal-content create-post post-preview backline-services">
          <div className="modal-body p-4 p-lg-0">
            <h4>Backline services/equipment will be provided?</h4>
            <div className="row">
              <div className="col-12 p-0">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="allBackline"
                    onChange={handleSelectAll}
                  />
                  <label className="form-check-label" htmlFor="allBackline">
                    Select all the services
                  </label>
                </div>
              </div>
              {backline_serviceData?.map(
                (data, index) =>
                  data.answerType === "boolean" && (
                    <div className="col-12 p-0" key={data?.id}>
                      <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between gap-3 column-gap-4">
                        <p>
                          {data?.question}
                          <span className="position-relative info-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                            >
                              <path
                                d="M6 0.5C4.81331 0.5 3.65328 0.851894 2.66658 1.51118C1.67989 2.17047 0.910851 3.10754 0.456726 4.2039C0.00259972 5.30026 -0.11622 6.50666 0.115291 7.67054C0.346802 8.83443 0.918247 9.90353 1.75736 10.7426C2.59648 11.5818 3.66558 12.1532 4.82946 12.3847C5.99335 12.6162 7.19975 12.4974 8.2961 12.0433C9.39246 11.5892 10.3295 10.8201 10.9888 9.83342C11.6481 8.84673 12 7.68669 12 6.5C12 4.9087 11.3679 3.38258 10.2426 2.25736C9.11742 1.13214 7.5913 0.5 6 0.5ZM6 11.5C5.0111 11.5 4.0444 11.2068 3.22215 10.6573C2.39991 10.1079 1.75904 9.32705 1.38061 8.41342C1.00217 7.49979 0.90315 6.49445 1.09608 5.52455C1.289 4.55464 1.76521 3.66373 2.46447 2.96447C3.16373 2.2652 4.05465 1.789 5.02455 1.59607C5.99446 1.40315 6.99979 1.50216 7.91342 1.8806C8.82705 2.25904 9.60794 2.8999 10.1574 3.72215C10.7068 4.54439 11 5.51109 11 6.5C11 7.82608 10.4732 9.09785 9.53554 10.0355C8.59785 10.9732 7.32609 11.5 6 11.5Z"
                                fill="#FB9804"
                              />
                              <path
                                d="M6.00024 5.35015C5.86763 5.35015 5.74045 5.40283 5.64668 5.4966C5.55292 5.59037 5.50024 5.71754 5.50024 5.85015V9.25015C5.50024 9.38276 5.55292 9.50994 5.64668 9.6037C5.74045 9.69747 5.86763 9.75015 6.00024 9.75015C6.13285 9.75015 6.26002 9.69747 6.35379 9.6037C6.44756 9.50994 6.50024 9.38276 6.50024 9.25015V5.85015C6.50024 5.71754 6.44756 5.59037 6.35379 5.4966C6.26002 5.40283 6.13285 5.35015 6.00024 5.35015ZM6.00024 3.30015C5.86488 3.30524 5.73661 3.36196 5.64176 3.45867C5.54692 3.55537 5.4927 3.68472 5.49024 3.82015V3.90015C5.49 3.96539 5.50335 4.02996 5.52943 4.08975C5.55551 4.14955 5.59375 4.20326 5.64172 4.24747C5.6897 4.29168 5.74635 4.32542 5.80807 4.34654C5.86979 4.36766 5.93524 4.3757 6.00024 4.37015C6.13127 4.3652 6.25559 4.31093 6.3483 4.21822C6.44102 4.1255 6.49529 4.00118 6.50024 3.87015V3.75015C6.50063 3.6866 6.48756 3.62369 6.46189 3.56556C6.43621 3.50743 6.39852 3.45539 6.35128 3.41288C6.30404 3.37037 6.24834 3.33834 6.18783 3.31891C6.12733 3.29948 6.06339 3.29309 6.00024 3.30015Z"
                                fill="#FB9804"
                              />
                            </svg>
                            <span className="info-box">
                              <span>{data?.question}</span>
                            </span>
                          </span>
                        </p>

                        <div className="d-flex align-items-center gap-4 align-self-end">
                          <div className="form-check p-0">
                            <input
                              className="form-check-input yes d-none"
                              type="radio"
                              name={`check-${index + 1}`}
                              id={`yes-${index + 1}`}
                              onClick={(event) =>
                                handleClickServiceData(data.id, true)
                              }
                              checked={data.answer === true}
                            />
                            <label
                              className="form-check-label yes-btn m-0"
                              for={`yes-${index + 1}`}
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check p-0">
                            <input
                              className="form-check-input no d-none"
                              type="radio"
                              name={`check-${index + 1}`}
                              id={`no-${index + 1}`}
                              onClick={(event) =>
                                handleClickServiceData(data.id, false)
                              }
                              checked={data.answer === false}
                            />
                            <label
                              className="form-check-label no-btn m-0"
                              for={`no-${index + 1}`}
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className="for-talent">
              <p>Check all backline services available for Talent?</p>
              <div className="row">
                {backline_serviceData?.map(
                  (data, index) =>
                    data.answerType === "checkbox" && (
                      <div className="col-12 p-0" key={index}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id={`checkbox-${index}`}
                            checked={data.answer === true}
                            onChange={(event) =>
                              handleClickServiceData(
                                data.id,
                                event.target.checked
                              )
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`checkbox-${index}`}
                          >
                            {data?.question}
                          </label>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
          <ModalFooter className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
              onClick={close}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={onCountinue}
            >
              Confirm
            </button>
          </ModalFooter>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default BacklineService;
