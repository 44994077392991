import { useLocation, useNavigate } from "react-router-dom";
import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "material-react-toastify";
import { Spinner } from "reactstrap";
import { RoutesLink } from "../../utility/RoutesLink";

export default function PlanCheckout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [cards, setCards] = useState([]);
  const [Addcard, setAddcard] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState(null);
  const [cardDetail, setCardDetail] = useState({
    cardNumber: "",
    type: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    if (
      !selectedCard &&
      (!data?.cardName || !data?.cardNumber || !data?.validity || !data?.cvv)
    ) {
      toast?.error("Please select a card or add new card");
      return;
    }
    if (selectedCard) {
      const planData = {
        planId: location?.state?.plan?.id,
        transactionId: data?.cardNumber || selectedCard,
      };
    }
    if (Addcard) {
      // await setCardDetail({
      //   cardNumber: data?.cardNumber,
      //   type: data?.cardName,
      // });
      const month = data?.validity?.split("-")[1];
      const year = data?.validity?.split("-")[0].slice(2);
      const cardData = {
        cardNumber: data?.cardNumber,
        cardName: data?.cardName,
        validity: `${month}/${year}`,
        cvv: data?.cvv,
        type: data?.cardName || "visa",
      };
      setLoading(true);
      console.log("Selected card:", cardData);
      if (data?.save === true) {
        console.log(true);
        await handleFetchRequest(
          `${ApiUrl.create_card}`,
          "POST",
          cardData
        ).then((res) => {
          if (res.status === true) {
            // getAddress();
          }
        });
        const planData = {
          planId: location?.state?.plan?.id,
          transactionId: data?.cardNumber,
        };
        handleFetchRequest(ApiUrl.create_plan, "POST", planData).then(
          (response) => {
            if (response.status === true) {
              toast?.success(response?.message);
              navigate("/dashboard");
              setLoading(false);
            } else {
              console.log("api faillll");
              setLoading(false);
            }
          }
        );
      }
      //   await checkoutHandler(cardData?.cardNumber, cardData?.cardName);
    }
  };
  //new codee

  useEffect(() => {
    const initializeSquarePayment = async () => {
      try {
        const payments = window.Square.payments(
          "sandbox-sq0idb-T_G-6votRDRzncYZCqZb1w",
          "LQJ4PZYJ257G"
        );

        // Updated styles with valid CSS properties for Square Payment form
        const cardStyles = {
          input: {
            color: "#333", // Text color
            fontSize: "16px", // Font size for input text
            fontFamily: "Arial", // Set the font family
          },
        };

        // Initialize the card instance with styles
        const cardInstance = await payments.card({
          style: cardStyles,
          // collectPostalCode: false,
          // postal_code: "12345",
        });

        // Attach the card instance to the DOM element
        await cardInstance.attach("#card-container");

        // Set the card instance state
        setCard(cardInstance);
      } catch (error) {
        console.error("Square Payment form initialization failed:", error);
      }
    };

    initializeSquarePayment();
  }, []);

  const handlePayment = async () => {
    if (!card) return;
    setLoading(true);

    try {
      const result = await card.tokenize();
      if (result.status === "OK") {
        console.log("Payment token:", result.token);
        // Send payment token, name, and email to the server
        const planData = {
          planId: location?.state?.plan?.id,
          transactionId: result.token,
        };
        setLoading(true);
        handleFetchRequest(ApiUrl.create_plan, "POST", planData).then(
          (response) => {
            if (response.status === true) {
              toast?.success(response?.message);
              navigate("/dashboard");
              setLoading(false);
            } else {
              toast?.error(response?.message);
              setLoading(false);
            }
          }
        );
      }
    } catch (error) {
      console.error("Payment failed:", error);
    }
  };

  return (
    <>
      <MobileHeader />

      <section className="px-0 main-content">
        <div className="checkout-head">
          <h3>Checkout</h3>
          <button className="btn btn-link" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M9.87861 8.53323L15.6066 2.8185C15.8575 2.56766 15.9984 2.22745 15.9984 1.87271C15.9984 1.51797 15.8575 1.17776 15.6066 0.926917C15.3558 0.676077 15.0156 0.535156 14.6609 0.535156C14.3061 0.535156 13.9659 0.676077 13.7151 0.926917L8.00034 6.65496L2.28562 0.926917C2.03478 0.676077 1.69457 0.535156 1.33983 0.535156C0.985086 0.535156 0.644874 0.676077 0.394034 0.926917C0.143194 1.17776 0.00227337 1.51797 0.00227337 1.87271C0.00227336 2.22745 0.143194 2.56766 0.394034 2.8185L6.12208 8.53323L0.394034 14.2479C0.269178 14.3718 0.170077 14.5191 0.102448 14.6814C0.0348191 14.8438 0 15.0179 0 15.1937C0 15.3696 0.0348191 15.5437 0.102448 15.706C0.170077 15.8684 0.269178 16.0157 0.394034 16.1395C0.51787 16.2644 0.665202 16.3635 0.827531 16.4311C0.98986 16.4987 1.16397 16.5336 1.33983 16.5336C1.51568 16.5336 1.68979 16.4987 1.85212 16.4311C2.01445 16.3635 2.16178 16.2644 2.28562 16.1395L8.00034 10.4115L13.7151 16.1395C13.8389 16.2644 13.9862 16.3635 14.1486 16.4311C14.3109 16.4987 14.485 16.5336 14.6609 16.5336C14.8367 16.5336 15.0108 16.4987 15.1732 16.4311C15.3355 16.3635 15.4828 16.2644 15.6066 16.1395C15.7315 16.0157 15.8306 15.8684 15.8982 15.706C15.9659 15.5437 16.0007 15.3696 16.0007 15.1937C16.0007 15.0179 15.9659 14.8438 15.8982 14.6814C15.8306 14.5191 15.7315 14.3718 15.6066 14.2479L9.87861 8.53323Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <div className="max-width-container">
          <div className="checkout">
            <div className="row row-gap-5">
              <div className="col-12 col-lg-7 p-0 pe-lg-3">
                <div
                  className="accordion accordion-flush details-cards-wrapper"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed p-0 d-block"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="true"
                        aria-controls="flush-collapseThree"
                      >
                        <div className="add-head">
                          <label className="form-check-label" for="biling">
                            Make your payment method
                          </label>
                        </div>
                      </button>
                    </h2>
                  </div>
                  <div id="card-container"></div>{" "}
                  {/* The card form will render here */}
                  <button
                    type="button"
                    onClick={handlePayment}
                    disabled={loading}
                    className="btn btn-primary"
                  >
                    {loading ? "Processing..." : "Pay Now"}
                  </button>
                </div>
              </div>
              <div className="col-12 col-lg-5 p-0 ps-lg-3 ">
                <div className="card price-card position-relative">
                  <div className="card-body">
                    <div className="items-wrapper">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Coupons"
                          aria-label="Coupons"
                          aria-describedby="button-addon2"
                        />
                        <button
                          className="btn btn-link inactive"
                          type="button"
                          id="button-addon2"
                        >
                          Apply
                        </button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <h3>Your plan</h3>
                      </div>
                      {/* {cart?.cart?.map((cartItem) => ( */}
                      {/* <div className="item">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/share.png`}
                          alt=""
                        />
                        <div className="d-flex flex-column gap-1 gap-md-2 gap-md-3 w-100">
                          <div className="d-flex justify-content-between gap-2 gap-md-3">
                            <h4>{location?.state?.plan?.planName}</h4>
                          </div>
                          <p>Nov 12, 10PM - Nov 15, 6PM</p>

                          <div className="d-flex justify-content-between align-items-center">
                            <div className="qnty">Billed monthly</div>
                            <h3>${location?.state?.plan?.price}</h3>
                          </div>
                        </div>
                      </div> */}
                      <div className="item plan">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/share.png`}
                          alt=""
                        />
                        <div className="d-flex flex-column gap-1 gap-md-2 gap-md-3 flex-grow-1">
                          <h3>Standres subscription plan</h3>
                          <p>Billed monthly</p>
                          <h4>
                            ${location?.state?.plan?.price}
                            <span>/month</span>
                          </h4>
                        </div>
                      </div>
                      {/* <div className="nxt-plan">
                        <div>
                          <h5>Next payment due on</h5>
                          <p>sep-15 2024</p>
                        </div>
                        <div>
                          <h5>
                            ${location?.state?.plan?.price}<span>/month</span>
                          </h5>
                          <span>incl.platform fee</span>
                        </div>
                      </div> */}
                    </div>
                    <div className="pay-desc">
                      <div className="pay-sum">
                        <h3>Order summary</h3>
                        <div className="d-flex align-items-center justify-content-between">
                          <p>Total Amount</p>
                          <p>${location?.state?.plan?.price?.toFixed(2)}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p>Service Fee</p>
                          <p>
                            <span>$0</span>
                          </p>
                        </div>
                        <hr />
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>Payable Amount</h5>
                          <h5>${location?.state?.plan?.price?.toFixed(2)}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MobileBottomNav />
    </>
  );
}
