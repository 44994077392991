import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { Modal } from "reactstrap";
import { toast } from "material-react-toastify";
import { RoutesLink } from "../../utility/RoutesLink";
import moment from "moment/moment";
import generatePDF from "react-to-pdf";

export default function AgreementModal({ isOpen, toggle }) {
  const { id } = useParams(); // Get the booking ID from URL parameters
  const [bookingDetail, setBookingDetail] = useState({}); // State to hold booking details
  const pdfRef = useRef(null); // Reference to the PDF element for generating PDFs
  // State to hold signature image information (file and URL for preview)
  const [signImg, setSignImg] = useState({
    sign_url: "",
    sign_view: "",
  });
  const userInfo = useSelector((store) => store?.user?.userDetails); // Get user information from Redux store

  const navigate = useNavigate(); // Hook for navigation

  // Effect to fetch booking details based on the booking ID
  useEffect(() => {
    const fetchBookingDetail = async () => {
      const Url = `${ApiUrl.get_hire_booking_detials}${id}`; // Construct API URL for booking details
      const options = {
        method: "GET",
      };
      handleFetchRequest(Url).then((response) => {
        console.log("rssssss", response); // Debugging log
        if (response?.status == true) {
          setBookingDetail(response.data); // Set the booking details in state
        }
      });
    };
    fetchBookingDetail(); // Call the function to fetch booking details
  }, [id]); // Depend on the booking ID

  // Function to handle the change in file input (signature upload)
  const handleFileChange = (e) => {
    let file = e?.target?.files?.[0]; // Get the first file from the input
    let url = URL.createObjectURL(file); // Create a temporary URL for the uploaded file

    if (file) {
      setSignImg({
        sign_url: file, // Store the file in state
        sign_view: url, // Store the URL for previewing
      });
    }
  };

  // Function to close the modal and reset signature state
  const closeModal = () => {
    toggle(); // Toggle the modal visibility
    setSignImg({
      sign_url: "", // Clear the signature file URL
      sign_view: "", // Clear the signature view URL
    });
  };

  // Function to accept the booking and upload the signature
  const acceptBooking = async (img) => {
    const formData = new FormData(); // Create a FormData object for sending the file

    // Append the signature to the FormData based on user type
    if (userInfo?.type == 2) {
      formData.append("vendorSignature", img?.sign_url);
    }
    if (userInfo?.type == 3) {
      formData.append("talentSignature", img?.sign_url);
    }

    // Send the signature to the API
    handleFetchRequest(
      ApiUrl?.add_Signature + bookingDetail?.id, // API URL to add the signature
      "POST",
      null,
      null,
      formData // Attach the form data with the signature
    ).then((res) => {
      if (res.status === true) {
        let options2 = {}; // Options for updating booking status

        // Set the booking status based on user type
        if (userInfo?.type == 2) {
          options2.status = 3; // Vendor status
        }
        if (userInfo?.type == 3) {
          options2.status = 1; // Talent status
        }

        // Update the booking status
        handleFetchRequest(
          ApiUrl?.edit_booking + bookingDetail?.id, // API URL to edit booking
          "POST",
          options2 // Send the new status
        ).then((response) => {
          if (response.status == true) {
            toast.success("Booking completed by you"); // Success message for booking completion
          }
        });
        toast.success(res?.message); // Success message for signature upload
        toggle(); // Close the modal
        navigate(RoutesLink?.ManageRequest); // Redirect to the manage request page
      }
    });
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog modal-dialog-centered create-post-dialog modal-fullscreen-lg-down"
    >
      <div className="modal-content create-post post-preview agreement-modal">
        <div className="modal-body p-0 position-relative">
          <button
            className="btn btn-light position-absolute end-0"
            onClick={() => {
              generatePDF(pdfRef, {
                filename: "SERVICE AGREEMENT.pdf",
              });
              toggle();
            }}
          >
            Download Agreement
          </button>
          <div className="row" ref={pdfRef}>
            <div className="col-12 p-0">
              <div className="d-flex justify-content-between">
                <div className="d-flex header align-items-center">
                  {/* <img src={hitWriteImg} alt="" /> */}
                  <img src="images/hitwrite.png" alt="" className="frame" />
                  <h3>
                    ARTIST AND CONCERT PRODUCER <br />
                    CONTRACT AGREEMENT
                  </h3>
                </div>
              </div>

              {console.log("bookingDetail", bookingDetail?.performanceDetail)}

              <p>
                This Agreement ("Agreement") is entered into on <br />[{" "}
                <span>
                  {moment(bookingDetail?.performanceDetail?.date).format("ll")}{" "}
                  {bookingDetail?.performanceDetail?.startTime} -{" "}
                  {bookingDetail?.performanceDetail?.endTime}
                </span>
                ], by and between
                <span>
                  {" "}
                  {bookingDetail?.contactFullName} and{" "}
                  {bookingDetail.talent?.firstName}{" "}
                  {bookingDetail.talent?.lastName}
                </span>
              </p>
            </div>

            <div className="col-12 p-0">
              <div className="d-flex">
                <div className="desc-box">
                  <div className="d-flex flex-column">
                    <div>
                      <h5>Artist name:</h5>
                      <p>
                        {bookingDetail.talent?.firstName}{" "}
                        {bookingDetail.talent?.lastName}
                      </p>
                    </div>
                    <div>
                      <h5>Address:</h5>
                      <p>
                        {bookingDetail?.talent?.location &&
                          JSON.parse(bookingDetail?.talent?.location)?.address}
                        {/* {(() => {
                            const locationString =
                              bookingDetail.talent?.location;
                              const location = JSON.parselocationString;
                            if (locationString) {
                              // const location = JSON.parse(locationString);
                              return `${location.areaCode}, ${location.address
                                .split(",")
                                .slice(1, 6)
                                .join(", ")}`;
                            }
                            else{
                                return location.area;
                            }
                          })()} */}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="desc-box">
                  <div className="d-flex flex-column">
                    <div>
                      <h5>Event Producer:</h5>
                      <p>{bookingDetail.contactFullName}</p>
                    </div>
                    <div>
                      <h5>Concert Producer's Address</h5>
                      <p>
                        {" "}
                        {bookingDetail?.Event?.eventTimeLocation &&
                          bookingDetail?.Event?.eventTimeLocation?.location}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 p-0">
              <h5>Services</h5>
              <p>
                Artist's Services: Artist agrees to perform the following
                services ("Services"): [Detailed description of the performance,
                including date, time, venue, and any other relevant details]
                <br />
                Compensation: In consideration for the Services, Event Producer
                agrees to pay Artist the total sum of [Agreed Amount] as
                compensation, payable as follows: [Payment terms, e.g., 100% on
                the day of the performance].
              </p>
            </div>
            <div className="col-12 p-0">
              <h5>Additional Expenses</h5>
              <p>
                Travel and Accommodation: Concert Producer agrees to cover all
                reasonable travel and accommodation expenses for Artist and
                their entourage.
              </p>
            </div>
            <div className="col-12 p-0">
              <h5>Promotion</h5>
              <p>
                Marketing: Both parties agree to collaborate on marketing
                efforts to promote the event, leveraging their respective
                networks.
              </p>
            </div>
            <div className="col-12 p-0">
              <h5>Representation and warranties</h5>
              <p>
                Artist's Representations: Artist represents and warrants that
                they have the legal right to enter into this Agreement and
                perform the Services. <br />
                Event Producer's Representations: Concert Producer represents
                and warrants that they have the legal right to organize the
                event and fulfill their obligations under this Agreement.
              </p>
            </div>
            <div className="col-12 p-0">
              <h5>Cancellations</h5>
              <p>
                Cancellation by Artist: If Artist cancels the performance for
                any reason other than Force Majeure, Artist shall refund any
                advance payment received and be liable for any direct expenses
                incurred by Concert Producer. <br />
                Cancellation by Concert Producer: If Concert Producer cancels
                the event for any reason other than Force Majeure, Concert
                Producer shall pay Artist the full compensation agreed upon in
                Section 1.
              </p>
            </div>
            <div className="col-12 p-0">
              <h5>Force Majeure</h5>
              <p>
                Definition: Neither party shall be liable for any failure or
                delay in performance due to events beyond their reasonable
                control, including but not limited to acts of God, war,
                terrorism, pandemics, strikes, and government regulations.
              </p>
            </div>
            <div className="col-12 p-0">
              <h5>Confidentiality</h5>
              <p>
                Non-Disclosure: Both parties agree to keep confidential all
                information provided by the other party in connection with this
                Agreement.
              </p>
            </div>
            <div className="col-12 p-0">
              <h5>Miscellaneous</h5>
              <p>
                Governing Law: This Agreement shall be governed by and construed
                in accordance with the laws of [State/Country]. 8.2 Entire
                Agreement: This Agreement constitutes the entire understanding
                between the parties and supersedes all prior agreements and
                understandings. IN WITNESS WHEREOF, the parties hereto have
                executed this Artist and Concert Producer Contract Agreement as
                of the date first above written.
              </p>
            </div>
            <div className="col-12 p-0">
              <h5>DISCLAIMER</h5>
              <p>
                This Artist and Concert Producer Contract Agreement form
                ("Agreement") has been provided for informational purposes only.
                The parties involved should seek independent legal advice and
                customize the agreement to suit their specific needs and
                jurisdiction. The use of this template does not create an
                attorney-client relationship and does not constitute legal
                advice. The template is a general guide and may not be suitable
                for every situation. It may not reflect the latest legal
                requirements or changes in applicable laws. The parties are
                encouraged to consult with legal professionals to ensure
                compliance with local laws and regulations. Any reliance on the
                information provided in this template is at the parties' own
                risk. The creators and distributors of this template disclaim
                any liability for damages or losses arising from the use of this
                template or any reliance on its contents. The template may be
                subject to copyright, and users are advised to respect all
                intellectual property rights associated with its creation.
                Unauthorized reproduction or distribution of this template is
                prohibited. By using this Agreement template, the parties
                acknowledge that they have read, understood, and agreed to the
                terms of this disclaimer.
              </p>
            </div>
            <div className="col-12 p-0">
              <div className="legal-desc">
                <div>
                  <p>
                    {bookingDetail.talent?.firstName}{" "}
                    {bookingDetail.talent?.lastName}
                  </p>

                  {bookingDetail?.talentSignature ? (
                    <img
                      src={bookingDetail?.talentSignature}
                      alt={bookingDetail.contactFullName}
                    />
                  ) : userInfo?.type == 3 && signImg?.sign_view !== "" ? (
                    <img src={signImg?.sign_view} alt="" />
                  ) : (
                    <p>[Signature]</p>
                  )}
                  {/* <p>
                    Date:{" "}
                    {moment(bookingDetail?.Event?.createdAt).format("lll")}
                  </p> */}
                </div>
                <div>
                  <p>{bookingDetail.contactFullName}</p>

                  {bookingDetail?.vendorSignature ? (
                    <img
                      src={bookingDetail?.vendorSignature}
                      alt={bookingDetail.contactFullName}
                    />
                  ) : userInfo?.type == 2 && signImg?.sign_view !== "" ? (
                    <img src={signImg?.sign_view} alt="" />
                  ) : (
                    <p>[Signature]</p>
                  )}
                  {/* <p>Date: {bookingDetail.eventDateTime?.event_date}</p> */}
                </div>
                <div>
                  <p>
                    {bookingDetail?.vendor?.Profile?.firstName}{" "}
                    {bookingDetail?.vendor?.Profile?.lastName}
                  </p>
                  <p>
                    {/* {(() => {
                      const locationString = bookingDetail?.vendor?.location;
                      const location = JSON.parse(locationString);
                      if (locationString) {
                        // const location = JSON.parse(locationString);
                        return location?.address;
                      } else {
                        return location?.area;
                      }
                    })()} */}
                  </p>
                  {console.log("bookingDetail?.status", bookingDetail?.status)}
                  <p className="m-0">
                    {bookingDetail.vendor?.countryCode}{" "}
                    {bookingDetail.vendor?.phone}
                  </p>
                  <p className="m-0">{bookingDetail.vendor?.email}</p>
                  <>
                    {bookingDetail?.talentBookingCompleted &&
                    bookingDetail?.vendorBookingCompleted ? (
                      ""
                    ) : (
                      <>
                        {bookingDetail?.status == 3 ? (
                          ""
                        ) : (
                          <>
                            <label htmlFor="sign" className="pe-3 mt-5">
                              Upload signature here
                            </label>
                            <input
                              type="file"
                              name=""
                              id="sign"
                              onChange={handleFileChange}
                              accept="image/*"
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            type="button"
            className="btn btn-light"
            // data-bs-dismiss="modal"
            onClick={() => closeModal()}
          >
            I’ll do it later
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => acceptBooking(signImg)}
            disabled={signImg?.sign_url == ""}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}
