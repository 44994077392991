import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

function PaymentModal({ modal, toggleModal, handlePayment }) {
  return (
    <Modal isOpen={modal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Payment Confirmation</ModalHeader>
      <ModalBody>
        <div id="card-container"></div> {/* The card form will render here */}
        <button type="button" onClick={handlePayment}>
          {"Pay Now"}
        </button>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default PaymentModal;
