import React from "react";
import { Link } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import moment from "moment";
import { getLoginUserCaption } from "../../Helper/Helper";
import { useSelector } from "react-redux";
import ReactOwlCarousel from "react-owl-carousel";

function TalentEventRequest({ list }) {
  const userDetails = useSelector((store) => store?.user?.userDetails);

  return (
    <div className="col-12 p-0" key={list.id}>
      <div className="request-card">
        <div className="head">
          <div className="d-flex gap-2 gap-lg-3 align-items-center flex-wrap">
            <div className="user-det d-flex gap-3 gap-lg-4 align-items-center">
              {list?.vendor?.profilePhoto != null ? (
                <Link
                  to={RoutesLink?.profile}
                  state={{ id: list?.vendor?.id }}
                  className="user-dp"
                >
                  <img src={list?.vendor?.profilePhoto} alt="" />
                </Link>
              ) : (
                <Link
                  to={RoutesLink?.profile}
                  state={{ id: list?.vendor?.id }}
                  className="user-dp no-img"
                >
                  {list?.vendor?.firstName?.[0]}
                </Link>
              )}

              <div className="user-ac">
                <div className="d-flex gap-2 align-items-center">
                  <span className="username">
                    <Link
                      to={RoutesLink?.profile}
                      state={{ id: list?.vendor?.id }}
                    >
                      {list?.vendor?.firstName} {list?.vendor?.lastName}
                    </Link>
                  </span>
                  <span className="usertype vendor mx-1">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={13}
                        height={13}
                        viewBox="0 0 13 13"
                        fill="none"
                      >
                        <circle
                          cx="5.74979"
                          cy="6.72714"
                          r="3.75247"
                          fill="white"
                        ></circle>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    Vendor
                  </span>
                </div>
                <p className="d-lg-none">{getLoginUserCaption(list?.vendor)}</p>
              </div>
            </div>
          </div>
          {(() => {
            const eventDateString = list?.Event?.eventTimeLocation?.start_date;
            const [day, month, year] = eventDateString?.split("-");
            const eventDate = new Date(`${year}-${month}-${day}`);
            const currentDate = new Date();

            // Set the time to midnight to compare dates only
            eventDate.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);

            if (list?.eventStatus === 4) {
              return (
                <span className="badge rounded-pill text-bg-success">
                  Completed
                </span>
              );
            } else if (list?.Event?.eventStatus === 0) {
              return (
                <span className="badge rounded-pill text-bg-warning">
                  Pending
                </span>
              );
            } else if (list?.Event?.eventStatus === 1) {
              return (
                <span className="badge rounded-pill text-bg-primary">
                  Closed
                </span>
              );
            } else if (list?.Event?.eventStatus === 3) {
              return (
                <span className="badge rounded-pill text-bg-success">
                  Published
                </span>
              );
            } else {
              return (
                <span className="badge rounded-pill text-bg-primary">
                  Cancelled
                </span>
              );
            }
          })()}
        </div>
        <div className="body">
          <div className="row">
            <div className="col-12 col-lg-9 p-0 order-1 order-md-1">
              <div className="d-flex align-items-center gap-4">
                <img
                  src={list?.Event?.eventImage}
                  alt=""
                  className="event-cover"
                />
                <div className="d-flex flex-column gap-2">
                  <h4>{list?.Event?.eventName}</h4>
                  <p>{list?.Event?.eventType}</p>
                  <span className="location">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="17"
                      viewBox="0 0 15 17"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1 7.27926C1.01186 3.7879 3.85178 0.967216 7.34313 0.979041C10.8345 0.99094 13.6552 3.83086 13.6434 7.32221V7.39381C13.6004 9.6633 12.3332 11.761 10.7796 13.4005C9.89111 14.3231 8.89893 15.1399 7.82281 15.8346C7.53506 16.0835 7.10825 16.0835 6.82051 15.8346C5.21627 14.7904 3.80829 13.4721 2.66096 11.94C1.63836 10.6039 1.05777 8.98224 1 7.30074V7.27926Z"
                        stroke="#343B4E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.32111 9.42738C8.44014 9.42738 9.3473 8.52022 9.3473 7.40119C9.3473 6.28215 8.44014 5.375 7.32111 5.375C6.20208 5.375 5.29492 6.28215 5.29492 7.40119C5.29492 8.52022 6.20208 9.42738 7.32111 9.42738Z"
                        stroke="#343B4E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {list?.Event?.eventTimeLocation?.location}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 p-0 text-md-end order-2 order-md-2">
              <div className="d-flex flex-column gap-2 align-items-end">
                <p>
                  {moment(
                    list?.Event?.eventTimeLocation?.start_date,
                    "DD-MM-YYYY"
                  ).format(" Do MMM YY")}{" "}
                  {list?.Event?.eventTimeLocation?.start_time} {" - "}
                  {moment(
                    list?.Event?.eventTimeLocation?.end_date,
                    "DD-MM-YYYY"
                  ).format(" Do MMM YY")}{" "}
                  {list?.Event?.eventTimeLocation?.end_time}
                </p>
                <Link
                  to={`${RoutesLink?.RequestDetails}/${list?.id}`}
                  state={list?.id}
                  className="btn btn-link pe-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    fill="none"
                  >
                    <path
                      d="M9.58151 8.00733L2.22391 0.398358C2.10306 0.272132 1.95929 0.171944 1.80088 0.103572C1.64247 0.0352012 1.47257 0 1.30096 0C1.12935 0 0.959445 0.0352012 0.801037 0.103572C0.642629 0.171944 0.498855 0.272132 0.37801 0.398358C0.135897 0.650683 0 0.992012 0 1.3478C0 1.70358 0.135897 2.04491 0.37801 2.29724L6.81266 9.03084L0.37801 15.6971C0.135897 15.9494 0 16.2908 0 16.6465C0 17.0023 0.135897 17.3437 0.37801 17.596C0.498402 17.7232 0.641973 17.8245 0.800402 17.8938C0.958832 17.9632 1.12897 17.9993 1.30096 18C1.47295 17.9993 1.64309 17.9632 1.80152 17.8938C1.95995 17.8245 2.10352 17.7232 2.22391 17.596L9.58151 9.98701C9.71346 9.8609 9.81876 9.70784 9.89079 9.53749C9.96282 9.36713 10 9.18316 10 8.99717C10 8.81119 9.96282 8.62722 9.89079 8.45686C9.81876 8.2865 9.71346 8.13344 9.58151 8.00733Z"
                      fill="#86889A"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
            <div className="col-12 mt-4 p-0 order-3 order-md-3">
              <h5>Artist performing</h5>
              <div className="mt-3">
                <ReactOwlCarousel
                  items={3} // Number of items to display
                  autoplay={false} // Enable auto-play
                  dots={false} // Hide dots/navigation
                  nav={false} // Hide next/prev buttons
                  animateOut="fadeOut" // Animation for slide-out (optional for smoothness)
                  animateIn="fadeIn" // Animation for slide-in (optional for smoothness)
                  mouseDrag={true} // Disable mouse dragging for smoother autoplay
                  touchDrag={true} // Disable touch dragging for smoother autoplay
                  slideTransition="ease-in-out" // CSS easing function for transition
                >
                  <div className="user-det-head">
                    <div className="user-det d-flex justify-content-between">
                      <div className="d-flex gap-3 align-items-center">
                        {list?.talent?.profilePhoto ? (
                          <Link
                            to={
                              userDetails?.id == list?.talent?.id
                                ? RoutesLink?.MyProfile
                                : RoutesLink?.profile
                            }
                            state={{ id: list?.talent?.id }}
                            className="user-dp"
                          >
                            <img
                              src={list?.talent?.profilePhoto}
                              alt="Profile"
                            />
                          </Link>
                        ) : (
                          <Link
                            to={
                              userDetails?.id == list?.talent?.id
                                ? RoutesLink?.MyProfile
                                : RoutesLink?.profile
                            }
                            state={{ id: list?.talent?.id }}
                            className="user-dp"
                          >
                            {list?.talent?.firstName?.[0]}
                          </Link>
                        )}

                        <div className="user-ac">
                          <div className="d-flex gap-2 align-items-center">
                            <span className="username">
                              <Link
                                to={
                                  userDetails?.id == list?.talent?.id
                                    ? RoutesLink?.MyProfile
                                    : RoutesLink?.profile
                                }
                                state={{ id: list?.talent?.id }}
                              >
                                {list?.talent?.firstName}{" "}
                                {list?.talent?.lastName}
                              </Link>
                            </span>
                            <span className="usertype mx-1">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 13"
                                  fill="none"
                                >
                                  <circle
                                    cx="5.74979"
                                    cy="6.72714"
                                    r="3.75247"
                                    fill="white"
                                  ></circle>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                    fill="#F4336D"
                                  ></path>
                                </svg>
                              </span>
                              Talent
                            </span>
                          </div>
                          <p className="m-0">
                            {getLoginUserCaption(list?.talent)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ReactOwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TalentEventRequest;
