import React from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { RoutesLink } from "../utility/RoutesLink"; // Ensure this imports correctly
import Cookies from "js-cookie";

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = Cookies.get("jubal_web_access"); // Check login state from cookies
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // Check for 'type' and 'id' in the URL
  const type = searchParams.get("type");
  const id = searchParams.get("id");

  // Allow access to the Instrument page without login
  if (type === "instrument" && id) {
    return children; // Allow access to the instrument page
  }

  // If not logged in and trying to access other protected routes
  if (!isLoggedIn) {
    return (
      <Navigate to={RoutesLink.sign_in} state={{ from: location }} replace />
    );
  }

  // Render the protected component if logged in or accessing the instrument page
  return children;
};

export default ProtectedRoute;
