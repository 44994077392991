import React from "react";
import "./helper.css";

// Functional component to display a loading placeholder for events
function EventLoader() {
  return (
    <>
      <div className="row">
        {" "}
        {/* Main container row */}
        <div className="Loader col-lg-12">
          {" "}
          {/* Loader container with full column width */}
          <div className="wrapper">
            {" "}
            {/* Wrapper for the loader content */}
            <div className="wrapper-cell">
              {" "}
              {/* Cell for holding text lines */}
              <div className="text">
                {" "}
                {/* Container for the loading text placeholder */}
                <div className=""></div>{" "}
                {/* Placeholder for additional styling if needed */}
                <div className="text-line"> </div>{" "}
                {/* Placeholder for a text line */}
                <div className="text-line"></div>{" "}
                {/* Placeholder for a text line */}
                <div className="text-line"></div>{" "}
                {/* Placeholder for a text line */}
                <div className="text-line"></div>{" "}
                {/* Placeholder for a text line */}
                <div className="text-line"></div>{" "}
                {/* Placeholder for a text line */}
                <div className="text-line"></div>{" "}
                {/* Placeholder for a text line */}
              </div>
            </div>
            <div className="Image-wapper">
              {" "}
              {/* Wrapper for the image placeholder */}
              <div className="image"></div> {/* Placeholder for the image */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventLoader;
