import { Link } from "react-router-dom";
import { RoutesLink } from "../utility/RoutesLink";

export default function HomeFooter() {
  return (
    <footer className="dark-footer">
      <div className="max-width-container">
        <nav className="navbar">
          <div className="container-fluid justify-content-center">
            <ul className="navbar-nav flex-row flex-wrap justify-content-center">
              <li className="nav-item">
                <Link
                  className="nav-link text-jt-dark"
                  to={RoutesLink?.about_us}
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-jt-dark"
                  to={RoutesLink?.subscription}
                >
                  Subscription
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-jt-dark" to={RoutesLink?.faq}>
                  FAQ
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-jt-dark"
                  to={RoutesLink?.terms_conditions}
                >
                  Terms & Conditions
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-jt-dark"
                  to={RoutesLink?.privacy_policy}
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-jt-dark"
                  to={RoutesLink?.contact}
                >
                  Help & Support
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        <div className="coprightsec d-flex align-items-center justify-content-center">
          <p className="text-jt-dark">© 2024 Jubaltalents.com</p>
          <div className="vr"></div>
          <p className="text-jt-dark">jubaltalents@gmail.com</p>
        </div>
      </div>
    </footer>
  );
}
