import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import { useEffect, useState } from "react";
import { ApiUrl } from "../../../utility/ApiUrl";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { RoutesLink } from "../../../utility/RoutesLink";
import { useForm } from "react-hook-form";
import {
  SocialLinks,
  validateSocialLink,
} from "../../../Helper/SocialLinkHelper";

const TalentSignUpS2 = () => {
  // Hook to get the current location object
  const location = useLocation();
  // Hook for programmatic navigation
  const navigate = useNavigate();

  // State variables to manage form data and selections
  const [listData, setListData] = useState({}); // List of instruments or categories
  const [selectedData, setSelectedData] = useState([]); // Selected instrument data
  const [imageUrl, setImageUrl] = useState(); // URL of the uploaded image
  const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering
  const [addLink, setAddLink] = useState(false); // Flag to control link addition
  const [addedLinks, setAddedLinks] = useState([]); // List of added social links
  const [selectedLinkType, setSelectedLinkType] = useState(""); // Currently selected link type
  const [linkvalues, setLinkValues] = useState([]); // Values of the social links
  const [linkError, setLinkError] = useState(null); // Error state for link validation

  console.log("selectedData", selectedData); // Debugging: Log selected data

  // useForm hook to handle form submission and validation
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  console.log(location?.state); // Log location state for debugging

  // Effect to fetch instrument data based on user type from the location state
  useEffect(() => {
    setSelectedData(location?.state?.instrumentData); // Set previously selected instrument data
    if (location?.state?.usertype === 3) {
      handleFetchRequest(ApiUrl?.instrument_name, "GET").then((response) => {
        if (response?.status === true) {
          setListData(response?.data); // Update listData with fetched instruments
        } else {
          // Handle error case if needed
        }
      });
    }
  }, []);

  // Effect to initialize addedLinks with a default link type if empty
  useEffect(() => {
    if (addedLinks?.length < 1) {
      addedLinks?.push("instagram"); // Add a default social link if none exist
    }
  }, [addedLinks]);

  // Handler for image upload, sets the uploaded file to state
  const imageUploadHandler = (e) => {
    let file = e?.target?.files?.[0]; // Get the uploaded file
    setImageUrl(file); // Set the image URL
  };

  // Handler to add/remove items from selectedData based on checkbox status
  const addHandler = (event, id) => {
    if (event.target.checked) {
      // If checkbox is checked, add the id to selectedData
      setSelectedData((preSelect) => [...(preSelect || []), id]);
    } else {
      // If unchecked, remove the id from selectedData
      let newData = (selectedData || [])?.filter((data) => {
        return data !== id; // Filter out the unchecked id
      });
      setSelectedData(newData);
    }
  };

  // Function to check if a category is already selected
  const checkHandler = (data) => {
    if (Array.isArray(selectedData)) {
      const checkedData = selectedData.find(
        (selectId) => (selectId === data?.catagory ? true : false) // Check if the category matches
      );
      return checkedData; // Return the checked status
    } else {
      console.log("Error is Empty"); // Log an error if selectedData is not an array
    }
  };

  // Function to add a new social link if there's no validation error
  const addMoreLink = () => {
    if (linkError == null) {
      setAddLink(true); // Allow adding a new link if no errors
    }
  };

  // Handler for changing the link type, updates addedLinks state
  const handleLinkTypeChange = (e) => {
    const newLinkType = e.target.value; // Get the new link type
    if (newLinkType && !addedLinks.includes(newLinkType)) {
      setAddedLinks([...addedLinks, newLinkType]); // Add new link type to addedLinks
    }
    setSelectedLinkType(""); // Reset selected link type
    setAddLink(false); // Hide the link addition form
  };

  // Handler for changing the social link value and validating it
  const socialLinkChange = (e, link) => {
    const isValid = validateSocialLink(link.type, e.target.value); // Validate the social link

    if (!isValid) {
      setLinkError("Please enter valid url"); // Set error if invalid
    } else {
      setLinkError(null); // Clear error if valid
    }
    console.log(linkError); // Log the current link error for debugging

    const linkData = {
      title: e.target.value, // Set link title
      value: e.target.value, // Set link value
      type: link?.type, // Set link type
    };

    // Update the link values state with new or updated links
    setLinkValues((prevValues) => {
      const existingLinkIndex = prevValues.findIndex(
        (item) => item.type === link.type // Check if link type already exists
      );

      if (existingLinkIndex > -1) {
        // If it exists, update the existing link
        const updatedValues = [...prevValues];
        updatedValues[existingLinkIndex] = linkData; // Replace old value with new
        return updatedValues;
      } else {
        // If it doesn't exist, add a new link
        return [...prevValues, linkData];
      }
    });
  };

  // Submit handler for the form
  const onSubmit = (data) => {
    let socialUrl = [
      data.apple_link,
      data?.fb_link,
      data?.insta_link,
      data?.twitter_link,
    ]; // Collect social URLs from form data
    console.log("onSubmit", socialUrl); // Log the collected social URLs for debugging

    // Navigate to the next step in the signup process with state
    navigate(RoutesLink?.talent_sign_up_s5, {
      state: {
        ...location?.state, // Pass along previous state
        socialUrl: linkvalues, // Include the validated social link values
        post: imageUrl, // Include the uploaded image
      },
    });
  };

  return (
    <>
      <Header />
      <section className="signin navbar-spc">
        <div className="max-width-container h-100">
          <div className="row h-100 justify-content-between justify-content-lg-center">
            <div className="col-12 col-md-5 col-lg-5 col-xl-6 p-0 d-none d-md-flex align-items-center justify-content-center position-relative">
              <div className="img-box">
                <img src="images/sign_in/frame.png" alt="" className="frame" />
                <img
                  src="images/sign_in/sign-img2.jpg"
                  alt=""
                  className=" content-img"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-6 p-0 d-flex justify-content-center align-items-center">
              <div className="card signin-card">
                <div className="card-body">
                  <div className="tab-content" id="myTabContent">
                    {location?.state?.usertype === 3 && (
                      <>
                        <div className="row tab-descp">
                          <div className="col-12 p-0">
                            <h4>Talent Community</h4>
                            <p>
                              If you would like to proceed with signing up as a
                              Jubal Talent, you will be part of the Jubal
                              Talents community granting you access to
                              performance and networking opportunities
                            </p>
                          </div>
                          <div className="col-12 p-0 cat-detl">
                            <h4>What is your primary musical instruments?</h4>
                            <p>Your music primary preferences</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 p-0">
                            <div className="d-flex align-items-center passfield-wrapper">
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
                                    fill="#818898"
                                  />
                                </svg>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                id="searchbar"
                                placeholder="Search catagory"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                              <span className="visi">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="12"
                                  viewBox="0 0 21 12"
                                  fill="none"
                                >
                                  <path
                                    d="M8.31407 11.9026H12.9271V9.93506H8.31407V11.9026ZM0.241211 0.0974121V2.06494H21V0.0974121H0.241211ZM3.70101 6.98376H17.5402V5.01624H3.70101V6.98376Z"
                                    fill="#818898"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                          <div
                            className="col-12 p-0"
                            style={{ height: "400px", overflow: "auto" }}
                          >
                            {(() => {
                              // Filter and sort the instruments, and store the result in a variable
                              const filteredInstruments = listData?.instruments
                                ?.filter((inst) =>
                                  inst?.instrumentName
                                    ?.toLowerCase()
                                    ?.includes(searchTerm.toLowerCase())
                                )
                                ?.sort((a, b) =>
                                  a.instrumentName.localeCompare(
                                    b.instrumentName
                                  )
                                );

                              // If there are no results, show "No results found"
                              if (
                                filteredInstruments &&
                                filteredInstruments?.length === 0
                              ) {
                                return (
                                  <p className="text-center mt-3">
                                    No results found
                                  </p>
                                );
                              }

                              // Otherwise, map over the filtered results and render them
                              return filteredInstruments?.map((data, index) => (
                                <div className="col-12 p-0" key={data.id}>
                                  <div className="form-check d-flex align-items-center justify-content-between">
                                    <label
                                      className="form-check-label"
                                      htmlFor={`instrument-${index}`}
                                    >
                                      {data?.instrumentName}
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={data?.id}
                                      id={`instrument-${index}`}
                                      onChange={(event) =>
                                        addHandler(event, data?.instrumentName)
                                      }
                                      checked={checkHandler(data)}
                                    />
                                  </div>
                                </div>
                              ));
                            })()}
                          </div>
                        </div>
                      </>
                    )}

                    {location?.state?.usertype === 2 && (
                      <>
                        <div className="row tab-descp">
                          <div className="col-12 p-0">
                            <h4>Vendor</h4>
                            <p>
                              Become a Jubal Vendor and unlock all the features
                              and benefits of the Jubal Community. Sell tickets,
                              find and hire talent, promote your artist, create
                              events, and more.
                            </p>
                          </div>

                          <div className="col-12 p-0 cat-detl">
                            <h4>Finalize your account</h4>
                            <p>
                              Let’s share something related to your talent
                              priorities
                            </p>
                          </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div class="row">
                            {SocialLinks?.map(
                              (socialLink, index) =>
                                addedLinks.includes(socialLink.type) && (
                                  <div className="col-12 p-0" key={index}>
                                    <div className="d-flex align-items-center passfield-wrapper loctn">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={socialLink.type}
                                        // value={socialLink.value}
                                        onChange={(e) =>
                                          socialLinkChange(e, socialLink)
                                        }
                                        placeholder={socialLink.title}
                                      />
                                      <span className="visi smlogo">
                                        {socialLink.icon}
                                      </span>
                                    </div>
                                  </div>
                                )
                            )}
                            {addLink && (
                              <div className="col-12 px-0">
                                <label className="form-label">
                                  Select link type
                                </label>
                                <select
                                  className="form-select"
                                  // value={selectedLinkType}
                                  onChange={handleLinkTypeChange}
                                >
                                  <option value="">Select a type</option>
                                  {SocialLinks?.filter(
                                    (option) =>
                                      !addedLinks.includes(option.type)
                                  ).map((option) => (
                                    <option
                                      key={option.type}
                                      // value={option.type}
                                    >
                                      {option.type}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                            {linkError == null ? (
                              ""
                            ) : (
                              <span className="form-text text-danger">
                                {linkError}
                              </span>
                            )}
                            <div className="col-12 px-0">
                              <p className="text-center">
                                <button
                                  className="btn btn-light"
                                  type="button"
                                  onClick={addMoreLink}
                                >
                                  {linkvalues?.length > 0
                                    ? "Add more link"
                                    : "Add link"}
                                </button>
                              </p>
                            </div>

                            <div class="col-12 px-0">
                              <div class="row">
                                <div class="col-6 ps-0 pe-4">
                                  <Link
                                    onClick={() => navigate(-1)}
                                    // to={RoutesLink?.talent_sign_up_s1}
                                    // state={{
                                    //   ...location?.state,
                                    //   ...location?.state?.category_service_dat,
                                    // }}
                                    state={{ back: location?.state }}
                                    class="btn btn-light btn-back w-100"
                                  >
                                    Back
                                  </Link>
                                </div>
                                <div className="col-6 ps-4 pe-0">
                                  <button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                  >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </>
                    )}
                    {location?.state?.usertype === 3 && (
                      <div className="col-12 px-0">
                        <div className="row">
                          <div className="col-6 ps-0 pe-4">
                            <Link
                              to={RoutesLink?.talent_sign_up_s1}
                              state={{
                                ...location?.state,
                                ...location?.state?.category_service_data,
                              }}
                              href="talent_sign_up s1.html"
                              className="btn btn-light btn-back w-100"
                            >
                              Back
                            </Link>
                          </div>
                          <div className="col-6 ps-4 pe-0">
                            <Link
                              to={RoutesLink?.talent_sign_up_s3}
                              state={{
                                ...location?.state,
                                instrumentData: selectedData,
                              }}
                              className="btn btn-primary w-100"
                            >
                              Continue
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TalentSignUpS2;
