import { useState, useEffect } from "react"; // Import necessary hooks from React
import { ApiUrl } from "../../utility/ApiUrl"; // Import API URL constants
import { handleFetchRequest } from "../../ApiHelper/ApiHelper"; // Import the API helper function

export default function PostDeleted() {
  // State to hold deleted posts
  const [deletedPosts, setDeletedPosts] = useState([]);

  // Effect to fetch archived (deleted) posts when the component mounts
  useEffect(() => {
    // Fetch archived posts from the API
    handleFetchRequest(ApiUrl.archived_posts).then((response) => {
      // Check if the API response indicates success
      if (response.status === true) {
        let likesData = response?.data?.posts || []; // Extract posts data, default to an empty array
        setDeletedPosts(likesData); // Update state with fetched posts
      }
    });
  }, []); // Empty dependency array means this runs once when the component mounts

  return (
    <>
      <h3 className="mt-4">Deleted Posts</h3>
      <div className="grid-gallery mt-3 mt-md-4 mt-lg-5">
        {deletedPosts &&
          deletedPosts.map((data, index) => {
            return (
              <div key={index} className="post-content position-relative">
                {data?.mediaType === "photo" ? (
                  <img src={data?.postImageUrl} alt="" />
                ) : (
                  data?.mediaType === "video" && (
                    <video autoPlay muted loop={true}>
                      <source src={data?.postImageUrl} />
                    </video>
                  )
                )}
              </div>
            );
          })}
      </div>
      {deletedPosts?.length < 1 && <p className="form-text"> No post found</p>}
    </>
  );
}
