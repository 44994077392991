import React, { useState } from "react";
import ImageCropper from "../../Helper/ImageCropper";
import { Footer, Header } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import { ApiUrl } from "../../utility/ApiUrl";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { toast } from "material-react-toastify";

function FanProfilePicture() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userID = useSelector((state) => state?.user);
  const [BannerLoading, setBannerLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [imageUrl, setImageUrl] = useState({
    image_view: "",
    image_link: "",
  });
  console.log("userID", userID);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setSelectedImage("");
    setShowModal(false);
  };
  const handleShow = () => setShowModal(true);
  const handleProfileCrop = (image) => {
    console.log("image", image);
    setShowModal(false);
    let url = URL.createObjectURL(image);
    setImageUrl({
      image_view: url,
      image_link: image,
    });
  };

  const imageUploadHandler = (e) => {
    const file = e.target.files[0];
    console.log("file", file);
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
      handleShow();
    };
    reader.readAsDataURL(file);
  };
  const imageRemoveHandler = () => {
    setImageUrl({
      image_view: "",
      image_link: "",
    });
  };

  const submitHandler = async () => {
    console.log("profilePhoto", imageUrl.image_link);
    if (imageUrl.image_link) {
      setBannerLoading(true);
      const formData = new FormData();
      formData.append("profilePhoto", imageUrl.image_link);
      formData.append("type", "profilePhoto");
      formData.append("userId", userID?.userDetails?.id);
      handleFetchRequest(
        `${ApiUrl?.add_banner_image}`,
        "POST",
        null,
        null,
        formData
      ).then((response) => {
        if (response?.status === true) {
          toast.success("Banner Updated successfully!");
          setBannerLoading(false);
          navigate(RoutesLink?.dashboard);
        } else {
          toast.error("Data is Not Found!");
          setBannerLoading(false);
        }
      });
    } else {
      toast.error("Banner is not selected");
    }
  };
  return (
    <>
      <Header />
      <section class="signin navbar-spc">
        <div class="max-width-container h-100">
          <div class="row h-100 justify-content-between justify-content-lg-center">
            <div class="col-12 col-md-5 col-lg-5 col-xl-6 p-0 d-none d-md-flex align-items-center justify-content-center position-relative">
              <div class="img-box">
                <img src="images/sign_in/frame.png" alt="" class="frame" />
                <img
                  src="images/sign_in/sign-img2.jpg"
                  alt=""
                  class=" content-img"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5 col-xl-6 p-0 d-flex justify-content-center align-items-center">
              <div class="card signin-card">
                <div class="card-body">
                  {/* <ul class="nav nav-pills card-tabs" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="Talent-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Talent-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="Talent-tab-pane"
                  aria-selected="true"
                >
                  Talent
                </button>
              </li>
              <li class="nav-item">
                <button
                  class="nav-link"
                  id="Vendor-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Vendor-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="Vendor-tab-pane"
                  aria-selected="false"
                >
                  Vendor
                </button>
              </li>
            </ul> */}
                  <div class="tab-content" id="myTabContent">
                    {
                      <div
                      // class="tab-pane fade show active"
                      // id="Talent-tab-pane"
                      // role="tabpanel"
                      // aria-labelledby="Talent-tab"
                      // tabindex="0"
                      >
                        {location?.state?.usertype === 3 ? (
                          <div class="row tab-descp">
                            <div class="col-12 p-0">
                              <h4>Talent Community</h4>
                              <p>
                                If you would like to proceed with signing up as
                                a Jubal Talent, you will be part of the Jubal
                                Talents community granting you access to
                                performance and networking opportunities
                              </p>
                            </div>
                            <div class="col-12 p-0 cat-detl">
                              <h4>
                                Adding a picture helps people recognise you
                              </h4>
                              <p>
                                Based on your level of performance people can
                                know more about you and your profession
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div class="row tab-descp">
                            <div class="col-12 p-0">
                              <h4>Vendor</h4>
                              <p>
                                Become a Jubal Vendor and unlock all the
                                features and benefits of the Jubal Community.
                                Sell tickets, find and hire talent, promote your
                                artist, create events, and more.
                              </p>
                            </div>

                            <div class="col-12 p-0 cat-detl">
                              <h4>
                                Adding a picture helps people recognise you
                              </h4>
                              <p>
                                Based on your level of performance people can
                                know more about you and your profession
                              </p>
                            </div>
                          </div>
                        )}
                        <form action="">
                          <div class="row">
                            {imageUrl?.image_view ? (
                              <div className="position-relative">
                                <button
                                  className=" btn position-absolute top-0 end-0 translate-middle-x "
                                  onClick={imageRemoveHandler}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none"
                                  >
                                    <path
                                      d="M13.7188 5.12305L4.71875 14.123M4.71875 5.12305L13.7188 14.123"
                                      stroke="black"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </button>
                                <div>
                                  <img
                                    style={{ borderRadius: "50%" }}
                                    src={imageUrl?.image_view}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div class="col-12 p-0">
                                <label for="file" class="form-label w-100">
                                  <div class="fileUploadBox d-flex justify-content-center flex-column align-items-center">
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="41"
                                        height="41"
                                        viewBox="0 0 41 41"
                                        fill="none"
                                      >
                                        <path
                                          d="M20.6308 0.755859C9.76922 0.755859 0.944336 9.58074 0.944336 20.4423C0.944336 31.3038 9.76922 40.1287 20.6308 40.1287C31.4923 40.1287 40.3172 31.3038 40.3172 20.4423C40.3172 9.58074 31.4923 0.755859 20.6308 0.755859ZM20.6308 2.54553C30.5259 2.54553 38.5275 10.5481 38.5275 20.4423C38.5275 22.2302 38.259 23.9527 37.7723 25.5804L32.4489 20.257C31.9075 19.7157 31.1907 19.4445 30.474 19.4445C29.7572 19.4445 29.0404 19.7157 28.4991 20.257L23.7627 24.9934L15.447 16.6777C14.9056 16.1363 14.1888 15.8652 13.4721 15.8652C12.7553 15.8652 12.0385 16.1363 11.4971 16.6777L3.3076 24.8672C2.93265 23.4219 2.73996 21.9354 2.73401 20.4423C2.73401 10.5472 10.7356 2.54553 20.6308 2.54553ZM13.4721 17.6441C13.7271 17.6441 13.9821 17.7426 14.1817 17.943L22.4974 26.2587L17.3136 31.4425C17.1506 31.6113 17.0604 31.8373 17.0624 32.0719C17.0645 32.3066 17.1586 32.531 17.3245 32.6969C17.4904 32.8628 17.7148 32.9569 17.9495 32.959C18.1841 32.961 18.4101 32.8708 18.5789 32.7078L29.7644 21.5223C29.8567 21.4276 29.967 21.3523 30.0889 21.3008C30.2107 21.2494 30.3417 21.2229 30.474 21.2229C30.6062 21.2229 30.7372 21.2494 30.8591 21.3008C30.9809 21.3523 31.0913 21.4276 31.1836 21.5223L37.1074 27.4462C34.3844 33.8514 28.0373 38.339 20.6308 38.339C12.9772 38.339 6.47443 33.5427 3.91162 26.7938L12.7616 17.9439C12.962 17.7434 13.217 17.645 13.4721 17.645V17.6441Z"
                                          fill="#CAC8C8"
                                        />
                                        <path
                                          d="M20.6309 6.12524C18.1701 6.12524 16.1567 8.13863 16.1567 10.5994C16.1567 13.0602 18.1701 15.0736 20.6309 15.0736C23.0917 15.0736 25.1051 13.0602 25.1051 10.5994C25.1051 8.13863 23.0917 6.12524 20.6309 6.12524ZM20.6309 7.91492C22.1244 7.91492 23.3154 9.10595 23.3154 10.5994C23.3154 12.0929 22.1244 13.2839 20.6309 13.2839C19.1374 13.2839 17.9464 12.0929 17.9464 10.5994C17.9464 9.10595 19.1374 7.91492 20.6309 7.91492Z"
                                          fill="#CAC8C8"
                                        />
                                      </svg>
                                    </span>

                                    <p>Upload here</p>
                                  </div>
                                </label>
                                <input
                                  type="file"
                                  class="form-control d-none"
                                  id="file"
                                  accept="image/*"
                                  onChange={imageUploadHandler}
                                />
                              </div>
                            )}
                            <div class="col-12 px-4">
                              <button
                                type="button"
                                // to={RoutesLink?.talent_main_sign_up}
                                // state={{ ...location.state, profile_pic: imageUrl?.image_link }}
                                onClick={submitHandler}
                                // href="./Dashboard/dashboard.html"
                                class="btn btn-primary w-100"
                                disabled={
                                  imageUrl?.image_view == "" ||
                                  imageUrl?.image_view == null
                                }
                              >
                                Continue
                              </button>
                            </div>
                            <div class="col-12 px-4">
                              <Link
                                to={RoutesLink?.dashboard}
                                class="btn btn-light btn-back w-100"
                              >
                                Skip
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ImageCropper
        show={showModal}
        handleClose={handleClose}
        image={selectedImage}
        onCrop={handleProfileCrop}
        width="150"
        height="150"
        ratio="1"
        customClass="profile_croper"
      />
      <Footer />
    </>
  );
}

export default FanProfilePicture;
