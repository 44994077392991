import AppLink from "../Components/AppLink";
import Header from "../Components/Header";
import HeroSection from "../Components/HeroSection";
import JoinUsSection from "../Components/JoinUsSection";
import MissionSection from "../Components/MissionSection";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import HomeFooter from "../Components/HomeFooter";

const Homepage = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <JoinUsSection />
      <MissionSection />
      <AppLink />
      <HomeFooter />
    </>
  );
};
export default Homepage;
