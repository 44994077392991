import { Link, useNavigate } from "react-router-dom";
import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import { useEffect, useState } from "react";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import ConfrimDeleteModal from "../../Components/Modals/ConfirmDeleteModal";
import AddAddressModal from "../../Components/Modals/AddAddressModal";
import { toast } from "material-react-toastify";
import { Modal, Spinner } from "reactstrap";
import { SvgIcons } from "../../Components/Svg/SvgIcons";
import { RoutesLink } from "../../utility/RoutesLink";
import { confirm } from "../../Components/Modals/ConfirmModal";
import { useForm } from "react-hook-form";
import { calculateShippingFees, PlatformFeesText } from "../../Helper/Helper";
import MangeRequestLoader from "../../Helper/MangeRequestLoader";
import PaymentModal from "../PaymentGetway/PaymentModal";

export default function Checkout() {
  // State variables for managing various aspects of the checkout process
  const [cart, setCart] = useState({}); // To hold cart items
  const [notificationCheckbox, setNotificationCheckbox] = useState(false); // For managing notification settings
  const [removeNotificationIds, setRemoveNotificationIds] = useState([]); // To store IDs of items to be removed from cart
  const [deleteModal, setDeleteModal] = useState(false); // To control the visibility of the delete confirmation modal
  const [addressModal, setAddressModal] = useState(false); // To control the visibility of the add/edit address modal
  const [ApiCall, setApiCall] = useState(true); // To trigger API calls on state changes
  const [address, setAddress] = useState([]); // To store user addresses
  const [shippingAddressId, setShippingAddressId] = useState(null); // To store the selected shipping address ID
  const [bilingAddressId, setBillingAddressId] = useState(null); // To store the selected billing address ID
  const [editAddress, setEditAddress] = useState(null); // To hold data for editing an existing address
  const [Addcard, setAddcard] = useState(false); // To control the visibility of the add card modal
  const [selectedCard, setSelectedCard] = useState(null); // To store the currently selected payment card
  const [cards, setCards] = useState([]); // To store user payment cards
  const [billingDefaultAddress, setBillingDefaultAddress] = useState(null); // To store the default billing address
  const [shippingDefaultAddress, setShippingDefaultAddress] = useState(null); // To store the default shipping address
  const [loading, setLoading] = useState(false); // To manage loading states
  const [changeAddress, setChangeAddress] = useState(false); // To manage address change actions
  const [addressCheck, setAddressCheck] = useState(null); // To determine the type of address to change
  const [isLoading, setIsLoading] = useState(false); // To manage the loading state of checkout
  const [note, setNote] = useState(null); // To hold any delivery instructions
  const navigate = useNavigate(); // For navigation purposes
  const [cardDetail, setCardDetail] = useState({
    cardNumber: "",
    type: "",
  });
  const [shippingDetails, setShippingDetails] = useState({
    totalFee: 0,
    services: [],
  });

  const [modal, setModal] = useState(false); // To control the visibility of a generic modal
  const toggleModal = () => setModal(!modal); // Function to toggle the generic modal
  const [totalShippingFees, setTotalShippingFees] = useState(0);
  console.log("selectedCard", selectedCard); // Debugging log for selected card
  const [errorMessage, setErrorMessage] = useState("");
  const maxCharacters = 500;
  // Setting up react-hook-form for form management
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if(cart?.cart?.length < 1) {
      navigate(RoutesLink?.dashboard);
      return;
    }
    if (cart?.cart && shippingDefaultAddress) {
      calculateTotalShippingFees();
    }
  }, [cart, shippingDefaultAddress, removeNotificationIds]);

  const calculateTotalShippingFees = async () => {
    let totalFee = 0;
    const services = [];

    for (const item of cart.cart) {
      if (removeNotificationIds.includes(item.itemId)) {
        const pickupLocation = JSON.parse(item.itemDetail.address);
        console.log("pppp", pickupLocation);
        const { serviceType, shippingFee } = await calculateShippingFees(
          pickupLocation,
          shippingDefaultAddress,
          item.quantity
        );
        totalFee += shippingFee;
        services.push({ itemId: item.itemId, serviceType, shippingFee });
      }
    }

    setShippingDetails({ totalFee, services });
  };

  const handleCheckboxChange = (notificationId, isChecked) => {
    setRemoveNotificationIds((prev) => {
      const newIds = isChecked
        ? [...new Set([...prev, notificationId])]
        : prev.filter((id) => id !== notificationId);
      return newIds;
    });
  };

  const toggleAddressModal = () => {
    setAddressModal(!addressModal); // Function to toggle the address modal
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal); // Function to toggle the delete modal
  };

  const addressChange = (type) => {
    setAddressCheck(type); // Set the type of address to change
    setChangeAddress(true); // Indicate that an address change is occurring
    toggleAddressModal(); // Open the address modal
  };

  const manageNotifications = async (tabId) => {
    setNotificationCheckbox(true); // Activate notification settings
  };

  const getCartData = async () => {
    setLoading(true); // Start loading state
    try {
      const response = await handleFetchRequest(ApiUrl.get_cart_data, "GET"); // Fetch cart data
      if (response.status === true) {
        setCart(response?.data); // Update cart state with fetched data
        const cartIds = response.data?.cart?.map((item) => item.itemId) || []; // Extract item IDs from the cart
        setRemoveNotificationIds(cartIds); // Set the IDs to be removed
        setLoading(false); // End loading state
      }
    } catch (error) {
      console.error("Error fetching cart data:", error); // Log errors if fetching fails
    }
  };

  const removeAllCartItems = async () => {
    // Loop through each item ID and remove it from the cart
    for (const cartId of removeNotificationIds) {
      const cartItem = cart?.cart.find((item) => item.itemId === cartId); // Find the cart item by ID

      if (cartItem) {
        const itemId = cartItem.id; // Get the item ID
        const res = await removeCartItem(itemId); // Remove the item from the cart
        if (res) {
          setRemoveNotificationIds(
            (prevIds) => prevIds.filter((id) => id !== cartId) // Remove ID from the state
          );
        }
      }
    }
    setApiCall(!ApiCall); // Trigger API call to refresh cart data
    toast.success("Item removed from cart"); // Show success message
    setNotificationCheckbox(false); // Reset notification checkbox
    setRemoveNotificationIds([]); // Clear removed item IDs
  };

  const removeCartItem = async (itemId) => {
    // Remove a single item from the cart
    handleFetchRequest(`${ApiUrl.remove_from_cart}${itemId}`, "GET").then(
      (response) => {
        if (response.status === true) {
          // Handle success if needed
        }
      }
    );
  };

  const handleConfirmDelete = () => {
    // Logic for confirming the deletion of cart items
    removeAllCartItems(); // Remove all selected cart items
    getCartData();
    toggleDeleteModal(); // Close the delete modal
    setNotificationCheckbox(false); // Reset notification checkbox
    setRemoveNotificationIds([]); // Clear removed item IDs
  };

  const getAddress = async () => {
    // Fetch user's addresses
    handleFetchRequest(ApiUrl.get_address).then((response) => {
      if (response.status === true) {
        setAddress(response?.data); // Update address state with fetched data
      } else {
        console.log("api faillll"); // Log failure
      }
    });
  };

  const getCard = async () => {
    // Fetch user's saved cards
    handleFetchRequest(ApiUrl.get_card).then((response) => {
      if (response.status === true) {
        setCards(response?.data); // Update cards state with fetched data
      } else {
        console.log("api faillll"); // Log failure
      }
    });
  };

  const plusMinusQuantity = async (itemId, itemType, quantity, id) => {
    // Manage item quantity in the cart
    if (quantity < 1) {
      handleFetchRequest(`${ApiUrl.remove_from_cart}${id}`, "GET").then(
        (response) => {
          if (response.status === true) {
            getCartData(); // Refresh cart data after removal
          }
        }
      );
    } else {
      handleFetchRequest(
        `${ApiUrl.increase_cart_quantity}${itemId}?itemType=${itemType}`,
        "GET"
      ).then((response) => {
        if (response.status === true) {
          getCartData(); // Refresh cart data after updating quantity
        }
      });
    }
  };

  const handleDeleteAddress = async (id) => {
    if (await confirm("Are you sure you want to Delete This Address")) {
      handleFetchRequest(`${ApiUrl.get_delete_Address}${id}`).then((res) => {
        if (res.status === true) {
          getAddress();
        }
      });
    }
  };

  const totalInstruments = cart?.cart
    ?.filter(
      (item) =>
        item.itemType === "instrument" &&
        removeNotificationIds?.includes(item.itemId)
    )
    .reduce((total, item) => total + item.price, 0);

  const totalTickets = cart?.cart
    ?.filter(
      (item) =>
        item.itemType === "event" &&
        removeNotificationIds?.includes(item.itemId)
    )
    .reduce((total, item) => total + item.price, 0);

  useEffect(() => {
    getAddress();
    getCard();
  }, []);

  useEffect(() => {
    getCartData();
  }, [ApiCall]);

  useEffect(() => {
    setShippingDefaultAddress(
      address.find((addr) => addr.isDefault === true) || address?.[0]
    );
    setBillingDefaultAddress(
      address.find((addr) => addr.isDefault === true) || address?.[0]
    );
    if (billingDefaultAddress) {
      setShippingAddressId(billingDefaultAddress.id);
    }
    if (shippingDefaultAddress) {
      setBillingAddressId(shippingDefaultAddress.id);
    }
  }, [address]);

  useEffect(() => {}, []);
  const handleChange = (card) => {
    setSelectedCard(card);
    setCardDetail({
      cardNumber: card?.cardNumber,
      type: card?.type,
    });
    setAddcard(false);
    reset();
  };

  const selectAll = () => {
    if (removeNotificationIds?.length == cart?.cart?.length) {
      setRemoveNotificationIds([]);
    } else {
      const cartIds = cart?.cart?.map((item) => item.itemId) || [];
      setRemoveNotificationIds(cartIds);
    }
  };

  if (loading) {
    return <MangeRequestLoader />;
  }

  const handleInputChange = (e) => {
    const input = e.target.value;

    if (input.length <= maxCharacters) {
      setNote(input); // Update aboutText if within the limit
      setErrorMessage(""); // Clear error message
    } else {
      setErrorMessage("Delivery Instructions must not exceed 500 characters"); // Show error message
    }
  };

  return (
    <>
      <MobileHeader />

      <section className="px-0 main-content">
        <div className="checkout-head">
          <h3>Checkout</h3>
          <button
            className="btn btn-link"
            onClick={() => navigate("/dashboard")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M9.87861 8.53323L15.6066 2.8185C15.8575 2.56766 15.9984 2.22745 15.9984 1.87271C15.9984 1.51797 15.8575 1.17776 15.6066 0.926917C15.3558 0.676077 15.0156 0.535156 14.6609 0.535156C14.3061 0.535156 13.9659 0.676077 13.7151 0.926917L8.00034 6.65496L2.28562 0.926917C2.03478 0.676077 1.69457 0.535156 1.33983 0.535156C0.985086 0.535156 0.644874 0.676077 0.394034 0.926917C0.143194 1.17776 0.00227337 1.51797 0.00227337 1.87271C0.00227336 2.22745 0.143194 2.56766 0.394034 2.8185L6.12208 8.53323L0.394034 14.2479C0.269178 14.3718 0.170077 14.5191 0.102448 14.6814C0.0348191 14.8438 0 15.0179 0 15.1937C0 15.3696 0.0348191 15.5437 0.102448 15.706C0.170077 15.8684 0.269178 16.0157 0.394034 16.1395C0.51787 16.2644 0.665202 16.3635 0.827531 16.4311C0.98986 16.4987 1.16397 16.5336 1.33983 16.5336C1.51568 16.5336 1.68979 16.4987 1.85212 16.4311C2.01445 16.3635 2.16178 16.2644 2.28562 16.1395L8.00034 10.4115L13.7151 16.1395C13.8389 16.2644 13.9862 16.3635 14.1486 16.4311C14.3109 16.4987 14.485 16.5336 14.6609 16.5336C14.8367 16.5336 15.0108 16.4987 15.1732 16.4311C15.3355 16.3635 15.4828 16.2644 15.6066 16.1395C15.7315 16.0157 15.8306 15.8684 15.8982 15.706C15.9659 15.5437 16.0007 15.3696 16.0007 15.1937C16.0007 15.0179 15.9659 14.8438 15.8982 14.6814C15.8306 14.5191 15.7315 14.3718 15.6066 14.2479L9.87861 8.53323Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <div className="max-width-container">
          <div className="checkout">
            <div className="row row-gap-5">
              <div className="col-12 col-lg-7 p-0 pe-lg-3">
                <div
                  className="accordion accordion-flush details-cards-wrapper"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed p-0 d-block"
                        type="button"
                        // data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="true"
                        // aria-controls="flush-collapseOne"
                      >
                        <div className="add-head">
                          <label className="form-check-label" for="shiping">
                            Shipping address
                          </label>
                          {shippingDefaultAddress?.id !== null && (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="shiping"
                              checked={shippingDefaultAddress?.id != null}
                            />
                          )}
                        </div>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body px-0">
                        <div className="row desc-card-wrapper">
                          {shippingDefaultAddress && (
                            <>
                              <div
                                className="col-12 p-0"
                                key={shippingDefaultAddress?.id}
                              >
                                <div className="address-card bg-white">
                                  <div className="row">
                                    <div className="col-12 p-0 d-flex justify-content-between align-items-center">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            id={`radio-${shippingDefaultAddress?.id}`}
                                            name="shipping"
                                            className="form-check-input type-one"
                                            onChange={() =>
                                              setShippingAddressId(
                                                shippingDefaultAddress?.id
                                              )
                                            }
                                            checked={shippingDefaultAddress?.id}
                                          />
                                        </div>
                                        <span className="type-badge">
                                          <span>
                                            {shippingDefaultAddress?.type ===
                                            "home"
                                              ? SvgIcons?.HomeIcon
                                              : SvgIcons?.OfficeIcon}
                                          </span>
                                          {shippingDefaultAddress?.type ===
                                          "home"
                                            ? "Home"
                                            : shippingDefaultAddress?.type ===
                                              "office"
                                            ? "Office"
                                            : "Other"}
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center gap-3">
                                        <button
                                          className="btn btn-link p-0 rounded-circle"
                                          onClick={() => {
                                            setAddressModal(true);
                                            toggleAddressModal();
                                            setEditAddress(
                                              shippingDefaultAddress
                                            );
                                            setChangeAddress(false);
                                          }}
                                        >
                                          {SvgIcons?.editAddressIcon}
                                        </button>
                                        <button
                                          className="btn btn-link p-0 rounded-circle"
                                          onClick={() =>
                                            handleDeleteAddress(
                                              shippingDefaultAddress?.id
                                            )
                                          }
                                        >
                                          {SvgIcons?.deleteAddressIcon}
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-12 p-0">
                                      <ul>
                                        <li>
                                          <p className="para">
                                            City:{" "}
                                            {
                                              shippingDefaultAddress?.location
                                                ?.city
                                            }
                                          </p>
                                          <p className="para">
                                            Address:{" "}
                                            {
                                              shippingDefaultAddress?.location
                                                ?.address
                                            }
                                          </p>
                                          <p className="para">
                                            Zip Code:{" "}
                                            {
                                              shippingDefaultAddress?.location
                                                ?.postal_code
                                            }
                                            {console.log(
                                              "shippingDefaultAddress",
                                              shippingDefaultAddress
                                            )}
                                          </p>
                                        </li>
                                        <li>
                                          <p className="para">
                                            Mobile:{" "}
                                            {
                                              shippingDefaultAddress?.countryCode
                                            }{" "}
                                            {shippingDefaultAddress?.phone}
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* {shippingAddressId === defaultAddress?.id && (
                                <div className="col-12 col-md-4 p-0">
                                  <button
                                    className="btn btn-primary w-100"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    Ship Here
                                  </button>
                                </div>
                              )} */}
                            </>
                          )}

                          <div className="col-12 p-0">
                            <div className="row">
                              {address?.length > 0 && (
                                <div className="col-12 col-md-4 p-0 pe-md-4">
                                  <button
                                    className="btn btn-primary w-100"
                                    onClick={() => addressChange("ship")}
                                  >
                                    Change address
                                  </button>
                                </div>
                              )}
                              <div className="col-12 col-md-4 p-0 ">
                                <Link
                                  to="#"
                                  className="btn btn-light w-100"
                                  onClick={() => {
                                    setAddressModal(true);
                                    toggleAddressModal();
                                    setChangeAddress(false);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                    className="me-2"
                                  >
                                    <path
                                      d="M8.5 1.42578V15.4258M1.5 8.42578H15.5"
                                      stroke="#343B4E"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                  Add new address
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed p-0 d-block"
                        type="button"
                        // data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="true"
                        // aria-controls="flush-collapseTwo"
                      >
                        <div className="add-head">
                          <label className="form-check-label" for="biling">
                            Billing Address
                          </label>
                          {billingDefaultAddress?.id !== null && (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="biling"
                              checked={billingDefaultAddress?.id != null}
                            />
                          )}
                        </div>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body px-0">
                        <div className="row desc-card-wrapper">
                          {billingDefaultAddress && (
                            <>
                              <div
                                className="col-12 p-0"
                                key={billingDefaultAddress?.id}
                              >
                                <div className="address-card bg-white">
                                  <div className="row">
                                    <div className="col-12 p-0 d-flex justify-content-between align-items-center">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="form-check">
                                          <input
                                            type="radio"
                                            id={`radio-${billingDefaultAddress?.id}`}
                                            name="billing"
                                            className="form-check-input type-one"
                                            onChange={() =>
                                              setBillingAddressId(
                                                billingDefaultAddress?.id
                                              )
                                            }
                                            checked={billingDefaultAddress?.id}
                                          />
                                        </div>
                                        <span className="type-badge">
                                          <span>
                                            {billingDefaultAddress?.type ===
                                            "home"
                                              ? SvgIcons?.HomeIcon
                                              : SvgIcons?.OfficeIcon}
                                          </span>
                                          {billingDefaultAddress?.type ===
                                          "home"
                                            ? "Home"
                                            : billingDefaultAddress?.type ===
                                              "office"
                                            ? "Office"
                                            : "Other"}
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center gap-3">
                                        <button
                                          className="btn btn-link p-0 rounded-circle"
                                          onClick={() => {
                                            setAddressModal(true);
                                            toggleAddressModal();
                                            setEditAddress(
                                              billingDefaultAddress
                                            );
                                            setChangeAddress(false);
                                          }}
                                        >
                                          {SvgIcons?.editAddressIcon}
                                        </button>
                                        <button
                                          className="btn btn-link p-0 rounded-circle"
                                          onClick={() =>
                                            handleDeleteAddress(
                                              billingDefaultAddress?.id
                                            )
                                          }
                                        >
                                          {SvgIcons?.deleteAddressIcon}
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-12 p-0">
                                      <ul>
                                        <li>
                                          <p className="para">
                                            City:{" "}
                                            {
                                              billingDefaultAddress?.location
                                                ?.city
                                            }
                                          </p>
                                          <p className="para">
                                            Address:{" "}
                                            {
                                              billingDefaultAddress?.location
                                                ?.address
                                            }
                                          </p>
                                          <p className="para">
                                            Zip Code:{" "}
                                            {
                                              billingDefaultAddress?.location
                                                ?.postal_code
                                            }
                                          </p>
                                        </li>
                                        <li>
                                          <p className="para">
                                            Mobile:{" "}
                                            {billingDefaultAddress?.countryCode}{" "}
                                            {billingDefaultAddress?.phone}
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* {bilingAddressId === defaultAddress?.id && (
                                <div className="col-12 p-0">
                                  <div className="row">
                                    <div className="col-4 p-0">
                                      <button
                                        className="btn btn-primary w-100"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThree"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThree"
                                      >
                                        Deliver here
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )} */}
                            </>
                          )}
                          <div className="col-12 p-0">
                            <div className="row">
                              {address?.length > 0 && (
                                <div className="col-12 col-md-4 p-0 pe-md-4">
                                  <button
                                    className="btn btn-primary w-100"
                                    onClick={() => addressChange("bill")}
                                  >
                                    Change address
                                  </button>
                                </div>
                              )}
                              <div className="col-12 col-md-4 p-0">
                                <Link
                                  to="#"
                                  className="btn btn-light w-100"
                                  onClick={() => {
                                    setAddressModal(true);
                                    toggleAddressModal();
                                    setChangeAddress(false);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                    className="me-2"
                                  >
                                    <path
                                      d="M8.5 1.42578V15.4258M1.5 8.42578H15.5"
                                      stroke="#343B4E"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                  Add new address
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 p-0">
                            <label for="note" className="form-label">
                              Delivery Instructions
                            </label>
                            <div>
                              <textarea
                                className="form-control"
                                id="about"
                                placeholder="Enter about you.."
                                value={note}
                                onChange={handleInputChange}
                              />
                              {/* Error message */}
                              {errorMessage && (
                                <div className="form-text mt-2 text-danger">
                                  {errorMessage}
                                </div>
                              )}
                              {/* Character count */}
                              <div className="form-text mt-2">
                                {note?.length}/{maxCharacters} characters
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-5 p-0 ps-lg-3">
                <div className="card price-card position-relative">
                  <div className="card-body">
                    <div className="items-wrapper">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Coupons"
                          aria-label="Coupons"
                          aria-describedby="button-addon2"
                        />
                        <button
                          className="btn btn-link inactive"
                          type="button"
                          id="button-addon2"
                        >
                          Apply
                        </button>
                      </div>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <h3>Order items</h3>
                        {notificationCheckbox == false && (
                          <button
                            className="btn btn-link"
                            disabled={!cart?.cart || cart?.cart?.length < 1}
                            onClick={manageNotifications}
                          >
                            Manage
                          </button>
                        )}
                        {notificationCheckbox == true && (
                          <button
                            className="btn btn-link"
                            disabled={!cart?.cart || cart?.cart?.length < 1}
                            onClick={selectAll}
                          >
                            {removeNotificationIds?.length == cart?.cart?.length
                              ? "Deselect all"
                              : "Select all"}
                          </button>
                        )}
                      </div>
                      {cart && cart?.cart?.length == 0 ? (
                        <p>Cart is empty</p>
                      ) : (
                        cart?.cart?.map((cartItem, index) => (
                          <div className="item" key={index}>
                            <img src={cartItem?.itemDetail?.itemImage} alt="" />
                            <div className="d-flex flex-column gap-1 gap-md-2 gap-md-3 w-100">
                              <div className="d-flex justify-content-between gap-2 gap-md-3">
                                <h4>{cartItem?.itemDetail?.itemName}</h4>

                                <div className="form-check">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`notification-${cartItem?.id}`}
                                      checked={removeNotificationIds?.includes(
                                        cartItem?.itemId
                                      )}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          cartItem?.itemId,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </div>
                                  {/* )} */}
                                </div>
                              </div>

                              {/* <p>Nov 12, 10PM - Nov 15, 6PM</p> */}

                              <div className="d-flex justify-content-between align-items-center">
                                {cartItem.itemType === "event" && (
                                  <div className="qnty">
                                    <Link
                                      to=""
                                      onClick={() =>
                                        plusMinusQuantity(
                                          cartItem.itemId,
                                          cartItem.itemType,
                                          cartItem.quantity - 1,
                                          cartItem?.id
                                        )
                                      }
                                    >
                                      -
                                    </Link>
                                    {cartItem?.quantity}
                                    <Link
                                      to=""
                                      onClick={() =>
                                        plusMinusQuantity(
                                          cartItem.itemId,
                                          cartItem.itemType,
                                          cartItem.quantity + 1
                                        )
                                      }
                                    >
                                      +
                                    </Link>
                                  </div>
                                )}
                                {cartItem?.price > 0 ? (
                                  <h3>${cartItem?.price}</h3>
                                ) : (
                                  <h3>Free</h3>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                    <div className="pay-desc">
                      <div className="pay-sum">
                        <h3>Order summary</h3>
                        {totalInstruments == 0 ? (
                          ""
                        ) : (
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Total instruments</p>
                            <p>${totalInstruments?.toFixed(2)}</p>
                          </div>
                        )}
                        {totalTickets == 0 ? (
                          ""
                        ) : (
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Total tickets</p>
                            <p>${totalTickets?.toFixed(2)}</p>
                          </div>
                        )}
                        {cart?.shipping_fees > 0 && (
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Shipping Fee</p>
                            {/* <p>${cart?.shipping_fees?.toFixed(2)}</p> */}
                            <p>${shippingDetails.totalFee.toFixed(2)}</p>
                          </div>
                        )}
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex">
                            <p>Service Fee</p>
                            <span className="position-relative info-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                              >
                                <path
                                  d="M6 0.5C4.81331 0.5 3.65328 0.851894 2.66658 1.51118C1.67989 2.17047 0.910851 3.10754 0.456726 4.2039C0.00259972 5.30026 -0.11622 6.50666 0.115291 7.67054C0.346802 8.83443 0.918247 9.90353 1.75736 10.7426C2.59648 11.5818 3.66558 12.1532 4.82946 12.3847C5.99335 12.6162 7.19975 12.4974 8.2961 12.0433C9.39246 11.5892 10.3295 10.8201 10.9888 9.83342C11.6481 8.84673 12 7.68669 12 6.5C12 4.9087 11.3679 3.38258 10.2426 2.25736C9.11742 1.13214 7.5913 0.5 6 0.5ZM6 11.5C5.0111 11.5 4.0444 11.2068 3.22215 10.6573C2.39991 10.1079 1.75904 9.32705 1.38061 8.41342C1.00217 7.49979 0.90315 6.49445 1.09608 5.52455C1.289 4.55464 1.76521 3.66373 2.46447 2.96447C3.16373 2.2652 4.05465 1.789 5.02455 1.59607C5.99446 1.40315 6.99979 1.50216 7.91342 1.8806C8.82705 2.25904 9.60794 2.8999 10.1574 3.72215C10.7068 4.54439 11 5.51109 11 6.5C11 7.82608 10.4732 9.09785 9.53554 10.0355C8.59785 10.9732 7.32609 11.5 6 11.5Z"
                                  fill="#FB9804"
                                />
                                <path
                                  d="M6.00024 5.35015C5.86763 5.35015 5.74045 5.40283 5.64668 5.4966C5.55292 5.59037 5.50024 5.71754 5.50024 5.85015V9.25015C5.50024 9.38276 5.55292 9.50994 5.64668 9.6037C5.74045 9.69747 5.86763 9.75015 6.00024 9.75015C6.13285 9.75015 6.26002 9.69747 6.35379 9.6037C6.44756 9.50994 6.50024 9.38276 6.50024 9.25015V5.85015C6.50024 5.71754 6.44756 5.59037 6.35379 5.4966C6.26002 5.40283 6.13285 5.35015 6.00024 5.35015ZM6.00024 3.30015C5.86488 3.30524 5.73661 3.36196 5.64176 3.45867C5.54692 3.55537 5.4927 3.68472 5.49024 3.82015V3.90015C5.49 3.96539 5.50335 4.02996 5.52943 4.08975C5.55551 4.14955 5.59375 4.20326 5.64172 4.24747C5.6897 4.29168 5.74635 4.32542 5.80807 4.34654C5.86979 4.36766 5.93524 4.3757 6.00024 4.37015C6.13127 4.3652 6.25559 4.31093 6.3483 4.21822C6.44102 4.1255 6.49529 4.00118 6.50024 3.87015V3.75015C6.50063 3.6866 6.48756 3.62369 6.46189 3.56556C6.43621 3.50743 6.39852 3.45539 6.35128 3.41288C6.30404 3.37037 6.24834 3.33834 6.18783 3.31891C6.12733 3.29948 6.06339 3.29309 6.00024 3.30015Z"
                                  fill="#FB9804"
                                />
                              </svg>
                              <span className="info-box">
                                <span>{PlatformFeesText.text}</span>
                              </span>
                            </span>
                          </div>

                          <p>
                            <span>${cart?.platformFees?.toFixed(2)}</span>
                          </p>
                        </div>
                        <hr />
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>Payable Amount</h5>
                          <h5>
                            $
                            {(
                              cart?.vatAmount +
                              totalInstruments +
                              cart?.platformFees +
                              totalTickets +
                              shippingDetails.totalFee
                            )?.toFixed(2)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  {notificationCheckbox && (
                    <div className="delete-btns">
                      <p>Selected Items ({removeNotificationIds?.length})</p>
                      <div className="btn-group">
                        <button
                          className="btn btn-link"
                          disabled={removeNotificationIds?.length < 1}
                          onClick={() => toggleDeleteModal()}
                          // data-bs-toggle="modal"
                          // data-bs-target="#deletemodal"
                        >
                          Delete
                        </button>
                        <button
                          className="btn btn-link"
                          onClick={() => setNotificationCheckbox(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {note == null ? (
                  <Link
                    className="btn btn-primary w-100 mt-3"
                    onClick={() =>
                      toast.error("Please add Delivery Instructions")
                    }
                  >
                    Pay Now
                  </Link>
                ) : !billingDefaultAddress ? (
                  <Link
                    className="btn btn-primary w-100 mt-3"
                    onClick={() => toast.error("Billing address is required")}
                  >
                    Pay Now
                  </Link>
                ) : !shippingDefaultAddress ? (
                  <Link
                    className="btn btn-primary w-100 mt-3"
                    onClick={() => toast.error("Shipping address is required")}
                  >
                    Pay Now
                  </Link>
                ) : billingDefaultAddress?.location?.postal_code === "" ? (
                  <Link
                    className="btn btn-primary w-100 mt-3"
                    onClick={() =>
                      toast.error("Postal code is missing in billing address")
                    }
                  >
                    Pay Now
                  </Link>
                ) : (
                  <Link
                    className="btn btn-primary w-100 mt-3"
                    to={RoutesLink?.stripe}
                    state={{
                      removeNotificationIds: removeNotificationIds,
                      note: note,
                      shippingDefaultAddress: shippingDefaultAddress,
                      billingDefaultAddress: billingDefaultAddress,
                      shippingDetails: shippingDetails.services,
                      vatAmount: cart?.vatAmount,
                      instrument: totalInstruments,
                      platformFees: cart?.platformFees,
                      shiping: shippingDetails.totalFee,
                      totalTickets: totalTickets,
                      amount:
                        cart?.vatAmount +
                        totalInstruments +
                        cart?.platformFees +
                        totalTickets +
                        shippingDetails.totalFee,
                    }}
                  >
                    Pay now
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <MobileBottomNav />

      <PaymentModal modal={modal} toggleModal={toggleModal} />

      {deleteModal && (
        <Modal
          isOpen={true}
          toggle={toggleDeleteModal}
          className="modal-dialog modal-dialog-centered deletemodal"
        >
          <ConfrimDeleteModal
            toggleDeleteModal={toggleDeleteModal}
            onConfirm={handleConfirmDelete}
          />
        </Modal>
      )}

      {addressModal && (
        <Modal
          isOpen={true}
          toggle={toggleAddressModal}
          className="modal-dialog modal-dialog-centered addressmodal modal-dialog-scrollable modal-fullscreen-sm-down"
        >
          <AddAddressModal
            toggleAddressModal={toggleAddressModal}
            getAddress={getAddress}
            editAddress={editAddress}
            setEditAddress={setEditAddress}
            address={address}
            changeAddress={changeAddress}
            setChangeAddress={setChangeAddress}
            billingDefaultAddress={billingDefaultAddress}
            shippingDefaultAddress={shippingDefaultAddress}
            setBillingDefaultAddress={setBillingDefaultAddress}
            setShippingDefaultAddress={setShippingDefaultAddress}
            addressCheck={addressCheck}
            handleDeleteAddress={handleDeleteAddress}
          />
        </Modal>
      )}
    </>
  );
}
