import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts"; // Importing ApexCharts component for rendering charts

// ApexChart component that renders an area chart based on event and instrument data
const ApexChart = ({ data }) => {
  // Initial state for the chart series (data to be displayed on the chart)
  const [series, setSeries] = useState([
    {
      name: "Events", // First data series: Events
      data: [], // Initially empty
    },
    {
      name: "Instruments", // Second data series: Instruments
      data: [], // Initially empty
    },
  ]);

  // Initial options for configuring the chart (e.g., chart type, axis, labels)
  const [options, setOptions] = useState({
    chart: {
      height: 350, // Set chart height to 350px
      type: "area", // Set chart type to "area"
    },
    dataLabels: {
      enabled: false, // Disable data labels on the chart
    },
    stroke: {
      curve: "smooth", // Set stroke line to smooth (curved lines)
    },
    xaxis: {
      type: "datetime", // X-axis will be in datetime format
      categories: [], // Initially no categories (to be updated dynamically)
    },
    tooltip: {
      x: {
        format: "dd/MM/yy", // Format tooltip date as "dd/MM/yy"
      },
    },
  });

  // useEffect to update chart data whenever 'data' prop changes
  useEffect(() => {
    if (data?.eventCount) {
      // Extract event dates and amounts from the data prop
      const eventDates = Object.keys(data?.eventCount?.chart);
      const eventAmounts = eventDates.map(
        (date) => data.eventCount.chart[date].amount
      );

      // Extract instrument dates and amounts from the data prop
      const instrumentDates = Object.keys(data.instrumentCount.chart);
      const instrumentAmounts = instrumentDates.map(
        (date) => data.instrumentCount.chart[date].amount
      );

      // Update the series data with event and instrument amounts
      setSeries([
        {
          name: "Events",
          data: eventAmounts, // Event data series updated with amounts
        },
        {
          name: "Instruments",
          data: instrumentAmounts, // Instrument data series updated with amounts
        },
      ]);

      // Update the chart options to set x-axis categories (dates for events and instruments)
      setOptions((prevOptions) => ({
        ...prevOptions, // Keep existing options
        xaxis: {
          ...prevOptions.xaxis,
          categories: [...eventDates, ...instrumentDates], // Set x-axis categories to combined dates
        },
      }));
    }
  }, [data]); // Dependency on 'data' to re-run when data changes

  return (
    <div>
      <div id="chart">
        {/* Render the ReactApexChart with updated options and series */}
        <ReactApexChart
          options={options} // Chart configuration options
          series={series} // Data series for the chart
          type="area" // Chart type is set to "area"
          height={350} // Chart height set to 350px
        />
      </div>
      <div id="html-dist"></div> {/* Placeholder div */}
    </div>
  );
};

export default ApexChart;
