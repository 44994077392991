import { useEffect, useState } from "react"; // Importing necessary hooks
import { Modal, ModalBody, ModalHeader } from "reactstrap"; // Importing modal components from Reactstrap
import { handleFetchRequest } from "../../ApiHelper/ApiHelper"; // Importing function to handle API requests
import { ApiUrl } from "../../utility/ApiUrl"; // Importing API URL constants
import { Link } from "react-router-dom"; // Importing Link for navigation
import { RoutesLink } from "../../utility/RoutesLink"; // Importing route links
import { getLoginUserCaption } from "../../Helper/Helper"; // Importing helper function for user captions
import { SvgIcons } from "../Svg/SvgIcons"; // Importing SVG icons
import { useSelector } from "react-redux"; // Importing hook to access Redux store

// FollowingModal component definition
const FollowingModal = ({
  isOpen, // Boolean to control modal visibility
  onRequestClose, // Function to close the modal
  postData, // Data related to the post
  id, // ID of the user whose following list is being displayed
  Unfollwo, // Function to handle unfollow action
  ArcivePost, // Funcation to handle Arcive Post
}) => {
  const [likeList, setLikeList] = useState([]); // State to store the list of users the current user is following
  const [searchQuery, setSearchQuery] = useState(""); // State to store the search query for filtering the following list
  const userDetails = useSelector((store) => store?.user?.userDetails); // Accessing user details from Redux store

  // Function to handle changes in the search input
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value); // Update search query state based on user input
  };

  // Effect hook to fetch the following list when the modal is opened or dependencies change
  useEffect(() => {
    if (isOpen) {
      // Check if the modal is open
      handleFetchRequest(
        `${ApiUrl.myFollowing}${
          postData?.id === undefined ? id : postData?.id
        }?search=${searchQuery && searchQuery}` // Constructing the API URL to fetch the following list based on the ID and search query
      ).then((response) => {
        if (response.status === true) {
          setLikeList(response.data?.user); // Update likeList with fetched user data
        }
      });
    }
  }, [isOpen, postData, searchQuery]); // Dependencies to re-run the effect

  // Function to handle removal of a following user
  const handleRemoveFollower = (res) => {
    new Promise((resolve, reject) => {
      try {
        Unfollwo(res); // Call the unfollow function with the user to be unfollowed
        resolve(); // Resolve the promise after successfully calling the function
      } catch (error) {
        reject(error); // Reject the promise if an error occurs
      }
    })
      .then(() => {
        // Update the local state after removing the user from the following list
        setLikeList((prevList) => prevList.filter((user) => user !== res)); // Filter out the unfollowed user from the likeList
      })
      .catch((error) => {
        console.error("Error unfollowing user:", error); // Log any errors encountered while unfollowing
      });
  };
  return (
    <>
      <div
        className="modal fade"
        id="likes"
        tabIndex={-1}
        aria-labelledby="likes"
        aria-hidden="true"
      >
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel="Likes"
          className="modal-dialog modal-dialog-centered share-modal-dialog"
          toggle={onRequestClose}
        >
          {/* <ModalHeader className="center">
            <h3 className="text-center">Following Artists</h3>{" "}
          </ModalHeader> */}
          <div className="modal-content create-post comment-modal share-modal">
            <div className="modal-body p-0">
              <div className="row">
                <div className="col-12 p-0">
                  <div className="d-flex align-items-start justify-content-between">
                    <h2 className="mt-0">Following</h2>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={onRequestClose}
                    ></button>
                  </div>

                  {likeList && likeList.length === 0 ? (
                    <span> No Data Found</span>
                  ) : (
                    <div className="d-flex align-items-center passfield-wrapper search-bar">
                      <span className="icon">{SvgIcons?.searchBarIcon}</span>
                      <input
                        type="text"
                        className="form-control"
                        id="search"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                      />
                    </div>
                  )}
                  <div className="featured-card all-likes scrollable like-scrollable p-0">
                    <div className="row">
                      {likeList &&
                        likeList.map((res) => (
                          <div className="col-12 px-0" key={res.id}>
                            <div className="user-det d-flex justify-content-between align-items-start">
                              <Link
                                to={
                                  res?.following?.Profile?.userId ===
                                  userDetails?.Profile?.userId
                                    ? RoutesLink?.MyProfile
                                    : RoutesLink?.profile
                                }
                                state={{ id: res?.following?.Profile?.userId }}
                                // to={RoutesLink?.profile}
                                // state={res?.following?.Profile?.id}
                                className="user-det-head"
                              >
                                <div className="d-flex gap-3">
                                  {res?.following?.Profile?.profilePhoto ? (
                                    <div className="user-dp">
                                      <img
                                        src={
                                          res?.following?.Profile?.profilePhoto
                                        }
                                        alt=""
                                      />
                                    </div>
                                  ) : (
                                    <div className="user-dp no-img">
                                      {res?.following?.Profile?.firstName[0]}
                                    </div>
                                  )}
                                  <div className="user-ac">
                                    <div className="d-flex gap-2 align-items-center">
                                      <span className="username">
                                        <Link
                                          to={
                                            res?.following?.Profile?.userId ===
                                            userDetails?.Profile?.userId
                                              ? RoutesLink?.MyProfile
                                              : RoutesLink?.profile
                                          }
                                          state={{
                                            id: res?.following?.Profile?.userId,
                                          }}
                                        >
                                          {res?.following?.Profile?.firstName}{" "}
                                          {res?.following?.Profile?.lastName}
                                        </Link>
                                      </span>
                                      {(res?.following?.type == 3 ||
                                        res?.following?.type == 2) && (
                                        <span
                                          className={
                                            res?.following?.type === 3
                                              ? "usertype talent mx-1"
                                              : res?.following?.type === 2
                                              ? "usertype vendor mx-1"
                                              : ""
                                          }
                                        >
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={13}
                                              height={13}
                                              viewBox="0 0 13 13"
                                              fill="none"
                                            >
                                              <circle
                                                cx="5.74979"
                                                cy="6.72714"
                                                r="3.75247"
                                                fill="white"
                                              />
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                                fill="#F4336D"
                                              />
                                            </svg>
                                          </span>
                                          {res?.following?.type == 3
                                            ? "Talent"
                                            : res?.following?.type == 2
                                            ? "Vendor"
                                            : ""}
                                        </span>
                                      )}
                                    </div>
                                    <p>{getLoginUserCaption(res?.following)}</p>
                                  </div>
                                </div>
                              </Link>
                              {id === userDetails?.id && (
                                <button
                                  type="button"
                                  className="btn btn-light remove-btn"
                                  onClick={() => handleRemoveFollower(res)}
                                >
                                  Unfollow
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default FollowingModal;
