import { useEffect, useState } from "react"; // Importing necessary hooks
import { Modal, ModalBody, ModalHeader } from "reactstrap"; // Importing modal components from Reactstrap
import { handleFetchRequest } from "../../ApiHelper/ApiHelper"; // Importing function to handle API requests
import { ApiUrl } from "../../utility/ApiUrl"; // Importing API URL constants
import { Link } from "react-router-dom"; // Importing Link for navigation
import { SvgIcons } from "../Svg/SvgIcons"; // Importing SVG icons
import { useSelector } from "react-redux"; // Importing hook to access Redux store
import { RoutesLink } from "../../utility/RoutesLink"; // Importing route links
import { getLoginUserCaption } from "../../Helper/Helper";

// LikesModal component definition
const LikesModal = ({
  isOpen, // Boolean to control modal visibility
  onRequestClose, // Function to close the modal
  postData, // Data related to the post for which likes are being displayed
  likePost, // Function to handle liking/unliking the post
  savePost, // Function to handle saving/unsaving the post
  handleFollowClick, // Function to handle following/unfollowing a user
}) => {
  const [likeList, setLikeList] = useState([]); // State to store the list of users who liked the post
  const userDetails = useSelector((store) => store?.user?.userDetails); // Accessing user details from Redux store

  // Function to fetch likes data for the post
  const fetchLikesData = async () => {
    if (isOpen) {
      // Check if the modal is open
      handleFetchRequest(`${ApiUrl.get_posts_likes}${postData?.id}`) // API call to fetch likes data based on post ID
        .then((response) => {
          if (response.status === true) {
            setLikeList(response.data?.likes); // Update the likeList state with the fetched likes data
          }
        });
    }
  };

  // Effect hook to fetch likes data when the modal is opened or postData changes
  useEffect(() => {
    fetchLikesData(); // Fetch likes data whenever the modal is opened or postData changes
  }, [isOpen, postData]);

  // Function to handle liking or unliking the post
  const like = async () => {
    const isLiked = postData?.isLikedByMe; // Check if the post is currently liked by the user
    postData.isLikedByMe = !isLiked; // Toggle the liked state
    postData.Likes += isLiked ? -1 : 1; // Update the like count based on the new liked state
    await likePost(postData, postData?.isLikedByMe, "POST"); // Call the likePost function with updated post data
  };

  // Function to handle saving or unsaving the post
  const save = async () => {
    const isSave = postData?.isSavedByMe; // Check if the post is currently saved by the user
    postData.isSavedByMe = !isSave; // Toggle the saved state
    await savePost(postData, postData?.isSavedByMe); // Call the savePost function with updated post data
  };

  // Function to handle following or unfollowing a user
  const getFollow = async (res) => {
    const isFollowed = postData?.isFollowedByMe; // Check if the user is currently followed by the current user
    postData.isFollowedByMe = !isFollowed; // Toggle the followed state
    await handleFollowClick(res, res?.User?.isFollowedByMe, "POST"); // Call the handleFollowClick function to update follow status
    fetchLikesData(); // Refresh the likes data after updating the follow status
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Likes"
        className="modal-dialog-centered comments-post-dialog modal-dialog-scrollable"
        toggle={onRequestClose}
      >
        {/* <ModalHeader className="center" toggle={onRequestClose}>
          <h3 className="text-center">Likes</h3>
        </ModalHeader> */}
        {/* <ModalBody> */}
        <div className="modal-content create-post comment-modal">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-5 p-0 pe-2 d-none d-lg-block">
                <div className="user-det-head d-flex justify-content-between align-items-start">
                  <div className="user-det d-flex gap-3">
                    <Link
                      to={
                        postData?.User?.id === userDetails?.id
                          ? RoutesLink?.MyProfile
                          : RoutesLink?.profile
                      }
                      state={{ id: postData?.User?.id }}
                      className="user-dp"
                    >
                      {postData?.User?.profilePhoto ? (
                        <img src={postData?.User?.profilePhoto} alt="" />
                      ) : (
                        postData?.User?.firstName[0]
                      )}
                    </Link>
                    <div className="user-ac">
                      <div className="d-flex gap-2 align-items-center">
                        <span className="username">
                          <Link
                            to={
                              postData?.User?.id === userDetails?.id
                                ? RoutesLink?.MyProfile
                                : RoutesLink?.profile
                            }
                            state={{ id: postData?.User?.id }}
                          >{`${postData?.User?.firstName} ${postData?.User?.lastName}`}</Link>
                        </span>
                        {postData?.User?.type === 3 && (
                          <span className="usertype mx-1">
                            <span>{SvgIcons?.talentIcon}</span>
                            Talent
                          </span>
                        )}

                        {postData?.User?.type !== 1
                          ? ""
                          : !postData?.isFollowedByMe &&
                            postData?.User?.id !== userDetails?.id &&
                            postData?.User?.type !== 1 && (
                              <span className="ms-2 follow"> Follow</span>
                            )}
                      </div>
                      <p>
                        {postData?.User?.Talent?.catagory
                          ? Array.isArray(postData?.User?.Talent?.catagory)
                            ? postData?.User?.Talent?.catagory[0]
                            : JSON.parse(postData?.User?.Talent?.catagory)[0]
                          : postData?.User?.Vendor?.services
                          ? Array.isArray(postData?.User?.Vendor?.services)
                            ? postData?.User?.Vendor?.services[0]
                            : JSON.parse(postData?.User?.Vendor?.services)[0]
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="img-placeholder d-flex justify-content-center align-items-center">
                  {postData?.mediaType === "video" ? (
                    <video
                      src={postData?.postImageUrl}
                      // controls
                      autoPlay
                      muted
                      loop
                      className="w-100"
                    />
                  ) : (
                    <img
                      src={postData?.postImageUrl}
                      alt="Post"
                      className="w-100"
                    />
                  )}
                </div>

                <div className="user-activity d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <span className="liked">{postData?.Likes} likes</span>
                    {/* <span className="shared">4 Shared</span> */}
                  </div>
                  <div className="d-flex user-activity">
                    <span
                      className={`like ${
                        postData?.isLikedByMe ? "activated" : ""
                      }`}
                      onClick={() => like()}
                    >
                      {SvgIcons?.likeIcon}
                    </span>
                    <span
                      className={`save ${
                        postData?.isSavedByMe ? "activated" : ""
                      }`}
                      onClick={() => save()}
                    >
                      {SvgIcons?.saveIcon}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-7 p-0 ps-lg-4 ps-xl-5">
                <div className="row side-row">
                  <div className="col-12 p-0">
                    <div className=" d-flex align-items-start justify-content-between">
                      <h2>{postData?.Likes} Likes</h2>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={onRequestClose}
                      ></button>
                    </div>
                    <div className="featured-card all-likes scrollable like-scrollable">
                      <div className="row">
                        {likeList &&
                          likeList?.map((res, index) => {
                            return (
                              <div className="col-12 px-0" key={index}>
                                <div className="user-det-head">
                                  <div className="user-det d-flex justify-content-between align-items-start">
                                    <div className="d-flex gap-3">
                                      {res?.User?.profilePhoto ? (
                                        <Link
                                          to={ res?.User?.type !== 1 &&
                                            res?.userId === userDetails?.id
                                              ? RoutesLink?.MyProfile
                                              : RoutesLink?.profile
                                          }
                                          state={{ id: res?.userId }}
                                          className="user-dp"
                                        >
                                          <img
                                            src={res?.User?.profilePhoto}
                                            alt=""
                                          />
                                        </Link>
                                      ) : (
                                        <Link
                                          to={ res?.User?.type !== 1 &&
                                            res?.userId === userDetails?.id
                                              ? RoutesLink?.MyProfile
                                              : RoutesLink?.profile
                                          }
                                          state={{ id: res?.userId }}
                                          className="user-dp no-img"
                                        >
                                          {res?.User?.firstName?.[0]}
                                        </Link>
                                      )}

                                      <div className="user-ac">
                                        <div className="d-flex gap-2 align-items-center">
                                          <span className="username">
                                            {res?.User?.type !== 1 ? (
                                              <Link
                                                to={
                                                  res?.userId ===
                                                  userDetails?.id
                                                    ? RoutesLink?.MyProfile
                                                    : RoutesLink?.profile
                                                }
                                                state={{ id: res?.userId }}
                                              >
                                                {res?.User?.firstName}{" "}
                                                {res?.User?.lastName}
                                              </Link>
                                            ) : (
                                              <Link>
                                                {res?.User?.firstName}{" "}
                                                {res?.User?.lastName}
                                              </Link>
                                            )}
                                          </span>
                                          {res?.User?.type === 2 && (
                                            <span className="usertype vendor mx-1">
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={13}
                                                  height={13}
                                                  viewBox="0 0 13 13"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="5.74979"
                                                    cy="6.72714"
                                                    r="3.75247"
                                                    fill="white"
                                                  />
                                                  <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                                    fill="currentColor"
                                                  />
                                                </svg>
                                              </span>
                                              Vendor
                                            </span>
                                          )}
                                          {res?.User?.type === 3 && (
                                            <span className="usertype mx-1">
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={13}
                                                  height={13}
                                                  viewBox="0 0 13 13"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="5.74979"
                                                    cy="6.72714"
                                                    r="3.75247"
                                                    fill="white"
                                                  />
                                                  <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                                    fill="#F4336D"
                                                  />
                                                </svg>
                                              </span>
                                              Talent
                                            </span>
                                          )}
                                        </div>
                                        <p>
                                          {getLoginUserCaption(res?.User)}
                                          {/* Concert Promoter */}
                                          {res?.commanFriends?.length > 0 && 
                                          <span className="common-frnds ms-2">
                                            {res?.commanFriends?.length} common
                                            friends
                                          </span>}
                                        </p>
                                      </div>
                                    </div>

                                    {!res?.isFollowedByMe &&
                                      res?.User?.type !== 1 &&
                                      res?.userId !== userDetails?.id && (
                                        <button
                                          type="button"
                                          className="btn btn-outline-info"
                                          onClick={() => getFollow(res)}
                                        >
                                          Follow
                                        </button>
                                      )}
                                    {console.log(
                                      " postData?.User?.type",
                                      res?.User?.type
                                    )}
                                    {/* {postData?.User?.type !== 1
                          ? ""
                          : !postData?.isFollowedByMe &&
                            postData?.User?.id !== userDetails?.id &&
                            postData?.User?.type !== 1 && (
                              <span className="ms-2 follow"> Follow</span>
                            )} */}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </>
  );
};
export default LikesModal;
