import React, { useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { SvgIcons } from "../Components/Svg/SvgIcons";
import {
  ArtistQuestions,
  EventQuestions,
  FanQuestions,
  InstrumentQuestions,
  VendorQuestions,
} from "../Helper/FaqHelper";

function Faq() {
  const [activeAccordion, setActiveAccordion] = useState(null); // State to track the currently active accordion

  const toggleAccordion = (id) => {
    setActiveAccordion((prevActive) => (prevActive === id ? null : id)); // Toggle accordion open/close
  };

  return (
    <>
      <Header />
      <section className="faq">
        <div className="max-width-container position-relative">
          <div className="row row-gap-4">
            <div className="col-12 col-md-5 col-lg-4 p-0 pe-md-3 pe-xl-4">
              <div className="card faq-card">
                <div className="card-body">
                  <h2>Browse Questions</h2>
                  <h3>
                    <img src="/images/logos/jubal_logo.png" alt="Jubal_logo" />{" "}
                    All About Jubal Talents
                  </h3>
                  <ul
                    className="nav flex-column nav-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="fan-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#fan-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="fan-tab-pane"
                        aria-selected="true"
                      >
                        Jubal Fan
                        {SvgIcons?.arrowIcon}
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="artists-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#artists-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="artists-tab-pane"
                        aria-selected="false"
                      >
                        Jubal Artists
                        {SvgIcons?.arrowIcon}
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="vendor-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#vendor-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="vendor-tab-pane"
                        aria-selected="false"
                      >
                        Jubal Vendors
                        {SvgIcons?.arrowIcon}
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="event-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#event-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="event-tab-pane"
                        aria-selected="false"
                      >
                        Jubal Events
                        {SvgIcons?.arrowIcon}
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="instruments-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#instruments-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="instruments-tab-pane"
                        aria-selected="false"
                      >
                        Jubal Instruments
                        {SvgIcons?.arrowIcon}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-7 col-lg-8 p-0">
              <div className="card faq-card-side">
                <div className="card-body">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="fan-tab-pane"
                      role="tabpanel"
                      aria-labelledby="fan-tab"
                      tabindex="0"
                    >
                      <div
                        className="accordion accordion-flush"
                        id="faqaccordion"
                      >
                        {FanQuestions.map((item) => (
                          <div className="accordion-item" key={item.id}>
                            <h2 className="accordion-header">
                              <button
                                className={`accordion-button ${
                                  activeAccordion === item.id ? "" : "collapsed"
                                }`}
                                type="button"
                                onClick={() => toggleAccordion(item.id)} // Call function to manage open/close state
                              >
                                <div className="d-flex align-items-start">
                                  <span className="num">{item.number}</span>{" "}
                                  {item.question}
                                </div>
                                {SvgIcons?.plusIcon}
                              </button>
                            </h2>
                            <div
                              id={`collapse${item.id}`}
                              className={`accordion-collapse collapse ${
                                activeAccordion === item.id ? "show" : ""
                              }`}
                            >
                              <div className="accordion-body">
                                {item.answer}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="artists-tab-pane"
                      role="tabpanel"
                      aria-labelledby="artists-tab"
                      tabindex="0"
                    >
                      <div
                        className="accordion accordion-flush"
                        id="faqaccordion"
                      >
                        {ArtistQuestions?.map((item) => (
                          <div className="accordion-item" key={item.id}>
                            <h2 className="accordion-header">
                              <button
                                className={`accordion-button ${
                                  activeAccordion === item.id ? "" : "collapsed"
                                }`}
                                type="button"
                                onClick={() => toggleAccordion(item.id)} // Call function to manage open/close state
                              >
                                <div className="d-flex align-items-start">
                                  <span className="num">{item.number}</span>{" "}
                                  {item.question}
                                </div>
                                {SvgIcons?.plusIcon}
                              </button>
                            </h2>
                            <div
                              id={`collapse${item.id}`}
                              className={`accordion-collapse collapse ${
                                activeAccordion === item.id ? "show" : ""
                              }`}
                            >
                              <div className="accordion-body">
                                {item.answer}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="vendor-tab-pane"
                      role="tabpanel"
                      aria-labelledby="vendor-tab"
                      tabindex="0"
                    >
                      <div
                        className="accordion accordion-flush"
                        id="faqaccordion"
                      >
                        {VendorQuestions.map((item) => (
                          <div className="accordion-item" key={item.id}>
                            <h2 className="accordion-header">
                              <button
                                className={`accordion-button ${
                                  activeAccordion === item.id ? "" : "collapsed"
                                }`}
                                type="button"
                                onClick={() => toggleAccordion(item.id)} // Call function to manage open/close state
                              >
                                <div className="d-flex align-items-start">
                                  <span className="num">{item.number}</span>{" "}
                                  {item.question}
                                </div>
                                {SvgIcons?.plusIcon}
                              </button>
                            </h2>
                            <div
                              id={`collapse${item.id}`}
                              className={`accordion-collapse collapse ${
                                activeAccordion === item.id ? "show" : ""
                              }`}
                            >
                              <div className="accordion-body">
                                {item.answer}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="event-tab-pane"
                      role="tabpanel"
                      aria-labelledby="event-tab"
                      tabindex="0"
                    >
                      <div
                        className="accordion accordion-flush"
                        id="faqaccordion"
                      >
                        {EventQuestions.map((item) => (
                          <div className="accordion-item" key={item.id}>
                            <h2 className="accordion-header">
                              <button
                                className={`accordion-button ${
                                  activeAccordion === item.id ? "" : "collapsed"
                                }`}
                                type="button"
                                onClick={() => toggleAccordion(item.id)} // Call function to manage open/close state
                              >
                                <div className="d-flex align-items-start">
                                  <span className="num">{item.number}</span>{" "}
                                  {item.question}
                                </div>
                                {SvgIcons?.plusIcon}
                              </button>
                            </h2>
                            <div
                              id={`collapse${item.id}`}
                              className={`accordion-collapse collapse ${
                                activeAccordion === item.id ? "show" : ""
                              }`}
                            >
                              <div className="accordion-body">
                                {item.answer}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="instruments-tab-pane"
                      role="tabpanel"
                      aria-labelledby="instruments-tab"
                      tabindex="0"
                    >
                      <div
                        className="accordion accordion-flush"
                        id="faqaccordion"
                      >
                        {InstrumentQuestions.map((item) => (
                          <div className="accordion-item" key={item.id}>
                            <h2 className="accordion-header">
                              <button
                                className={`accordion-button ${
                                  activeAccordion === item.id ? "" : "collapsed"
                                }`}
                                type="button"
                                onClick={() => toggleAccordion(item.id)} // Call function to manage open/close state
                              >
                                <div className="d-flex align-items-start">
                                  <span className="num">{item.number}</span>{" "}
                                  {item.question}
                                </div>
                                {SvgIcons?.plusIcon}
                              </button>
                            </h2>
                            <div
                              id={`collapse${item.id}`}
                              className={`accordion-collapse collapse ${
                                activeAccordion === item.id ? "show" : ""
                              }`}
                            >
                              <div className="accordion-body">
                                {item.answer}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Faq;
