import React, { useEffect, useState } from "react";
import { Modal, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { toast } from "material-react-toastify";
import EmptyCart from "../Layout/EmptyCart";
import { useDispatch, useSelector } from "react-redux";
import {
  closeCartModal,
  openCartModal,
} from "../../store/actions/cartModalActions";
import { addItemToCart } from "../../store/actions/cartActions";
import { Link, useNavigate } from "react-router-dom";
import ConfrimDeleteModal from "./ConfirmDeleteModal";
import { RoutesLink } from "../../utility/RoutesLink";
import { SaveInstrument_Likes } from "../../store/actions/UserActions";

function CartModal({ modal, CartMenuToggle, id, cartData, setCartData }) {
  const dispatch = useDispatch(); // For managing state in Redux
  const navigate = useNavigate(); // For navigation

  // Redux state for cart modal visibility
  const isCartModalOpen = useSelector(
    (state) => state.cartModal.isCartModalOpen
  );

  // User details from Redux store
  const userDetails = useSelector((state) => state?.user?.userDetails);

  // Function to close the cart modal
  const handleClose = () => {
    dispatch(closeCartModal());
  };

  // State variables
  const [ApiCall, setApiCall] = useState(true); // Flag for API calls
  const [notificationCheckbox, setNotificationCheckbox] = useState(false); // Checkbox state for notifications
  const [removeNotificationIds, setRemoveNotificationIds] = useState([]); // IDs of items to be removed from cart
  const [totalInstrumentPrice, setTotalInstrumentPrice] = useState(0); // Total price for instruments
  const [totalEventPrice, setTotalEventPrice] = useState(0); // Total price for events
  const [deleteModal, setDeleteModal] = useState(false); // State for delete confirmation modal
  const [cart, setCart] = useState(null); // State for cart data

  // Function to toggle the delete confirmation modal
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  // Function to manage notifications (currently only sets the checkbox state)
  const manageNotifications = async (tabId) => {
    setNotificationCheckbox(true);
  };

  // Function to calculate total prices for instruments and events in the cart
  const calculateTotalPrices = (cart) => {
    if (cart && cart.length > 0) {
      const totals = cart.reduce(
        (acc, item) => {
          if (item?.itemType === "event") {
            acc.totalEventPrice += item?.price; // Add event price
          } else if (item?.itemType === "instrument") {
            acc.totalInstrumentPrice += item?.price; // Add instrument price
          }
          return acc;
        },
        { totalEventPrice: 0, totalInstrumentPrice: 0 }
      );

      const { totalEventPrice, totalInstrumentPrice } = totals;
      setTotalEventPrice(totalEventPrice); // Update state with total event price
      setTotalInstrumentPrice(totalInstrumentPrice); // Update state with total instrument price
    } else {
      console.log("Cart is empty");
    }
  };

  // Function to handle checkbox changes for notification IDs
  const handleCheckboxChange = (notificationId, isChecked) => {
    setRemoveNotificationIds((prev) => {
      const newIds = isChecked
        ? [...new Set([...prev, notificationId])] // Add new ID if checked
        : prev.filter((id) => id !== notificationId); // Remove ID if unchecked
      return newIds;
    });
  };

  // Function to format price in USD currency format
  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price);
  };

  // Function to handle quantity changes for cart items
  const plusMinusQuantity = async (itemId, itemType, quantity, id) => {
    if (quantity < 1) {
      // Remove item from cart if quantity is less than 1
      handleFetchRequest(`${ApiUrl.remove_from_cart}${id}`, "GET").then(
        (response) => {
          if (response.status === true) {
            setApiCall(true); // Trigger API call to refresh cart
          }
        }
      );
      return;
    }
    if (quantity < 0) {
      return; // Prevent negative quantity
    }

    const options = {
      data: {
        itemId: itemId,
        itemType: itemType,
        quantity: quantity,
      },
    };
    // Update cart data with new quantity
    handleFetchRequest(ApiUrl.add_cart_data, "POST", options?.data).then(
      (response) => {
        if (response.status === true) {
          setCartData(response.data?.cart); // Update cart state
          setApiCall(true); // Trigger API call to refresh cart
        } else {
          console.log("API failed");
        }
      }
    );
  };

  // Effect to fetch cart data when modal opens or API call is triggered
  useEffect(() => {
    if (ApiCall || isCartModalOpen) {
      handleFetchRequest(ApiUrl.get_cart_data, "GET").then((response) => {
        if (response.status === true) {
          setCartData(response.data?.cart); // Update cart data in state
          dispatch(addItemToCart(response?.data?.cart)); // Update Redux cart state
          calculateTotalPrices(response.data.cart); // Calculate total prices
          setCart(response?.data); // Set cart data
          setApiCall(false); // Reset API call flag
        } else {
          setApiCall(false); // Reset API call flag
          console.log("API failed");
        }
      });
    }
  }, [ApiCall, isCartModalOpen]);

  // Function to confirm deletion of cart items
  const handleConfirmDelete = () => {
    // Call function to remove items from cart
    removeAllCartItems();
    toggleDeleteModal(); // Close delete confirmation modal
    setNotificationCheckbox(false); // Reset notification checkbox
    setRemoveNotificationIds([]); // Reset IDs for removed items
  };

  // Function to remove all selected items from the cart
  const removeAllCartItems = async () => {
    for (const cartId of removeNotificationIds) {
      await removeCartItem(cartId); // Remove each item
    }
    setApiCall(true); // Trigger API call to refresh cart
    toast.success("Item removed from cart"); // Show success notification
    setNotificationCheckbox(false); // Reset notification checkbox
    setRemoveNotificationIds([]); // Reset IDs for removed items
  };

  // Function to remove a single item from the cart
  const removeCartItem = async (cartId) => {
    handleFetchRequest(`${ApiUrl.remove_from_cart}/${cartId}`, "GET").then(
      (response) => {
        if (response.status === true) {
          // Optionally handle successful removal
        }
      }
    );
  };

  // Function to handle checkout navigation
  const checkoutHandler = () => {
    navigate("/checkout"); // Navigate to checkout page
    handleClose(); // Close cart modal
  };
  return (
    <>
      <Offcanvas
        direction="end"
        className="offcanvas offcanvas-end cart"
        isOpen={isCartModalOpen}
        toggle={handleClose}
      >
        <OffcanvasHeader toggle={handleClose} className="offcanvas-header">
          <h5 className="offcanvas-title" id="cartLabel">
            Checkout
          </h5>
        </OffcanvasHeader>

        <OffcanvasBody className="offcanvas-body offcanvas-end cart" id="cart">
          {cartData?.length > 0 ? (
            <div
              className="d-flex flex-column justify-content-between position-relative"
              key={cartData?.length}
            >
              <div className="item-desc">
                <div className="items-wrapper">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <h3>Order items: {cartData?.length}</h3>
                    {cartData && cartData?.length > 0 && (
                      <button
                        className="btn btn-link"
                        disabled={!cartData || cartData?.length < 1}
                        onClick={manageNotifications}
                      >
                        Manage
                      </button>
                    )}
                  </div>
                  {cartData &&
                    cartData.map((item, index) => {
                      return (
                        <>
                          <div className="item" key={index}>
                            <img src={item?.itemDetail?.itemImage} alt="" />
                            <div className="d-flex flex-column gap-1 gap-md-2 gap-md-3 w-100">
                              <div className="d-flex justify-content-between gap-2 gap-md-3">
                                <h4>{item?.itemDetail?.itemName}</h4>
                                {notificationCheckbox && (
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`notification-${item?.id}`}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          item?.id,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                              {/* <p className="">
                                {`${new Date(
                                  item.createdAt
                                ).getDate()} ${new Date(
                                  item.createdAt
                                ).toLocaleString("default", {
                                  month: "long",
                                })}, ${
                                  new Date(item.createdAt).getHours() % 12 || 12
                                } ${
                                  new Date(item.createdAt).getHours() >= 12
                                    ? "pm"
                                    : "am"
                                } - ${new Date(
                                  item.createdAt
                                ).getDate()} ${new Date(
                                  item.createdAt
                                ).toLocaleString("default", {
                                  month: "long",
                                })}, 6 ${
                                  new Date(item.createdAt).getHours() >= 18
                                    ? "pm"
                                    : "pm"
                                }`}
                              </p> */}
                              {/* <p>Nov 12, 10PM - Nov 15, 6PM</p> */}
                              <div className="d-flex justify-content-between align-items-center">
                                {item.itemType === "event" && (
                                  <div className="qnty">
                                    <span
                                      onClick={() =>
                                        plusMinusQuantity(
                                          item.itemId,
                                          item.itemType,
                                          item.quantity - 1,
                                          item?.id
                                        )
                                      }
                                    >
                                      -
                                    </span>
                                    {item.quantity}
                                    <span
                                      onClick={() =>
                                        plusMinusQuantity(
                                          item.itemId,
                                          item.itemType,
                                          item.quantity + 1
                                        )
                                      }
                                    >
                                      +
                                    </span>
                                  </div>
                                )}
                                {item?.price > 0 ? (
                                  <h3>$ {item?.price?.toFixed(2)}</h3>
                                ) : (
                                  <h3>Free</h3>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  {(!cartData || cartData?.length === 0) && (
                    <p className="form-text"> No items in cart</p>
                  )}
                </div>
                <div>
                  {notificationCheckbox && (
                    <div className="delete-btns">
                      <p>Selected Items ({removeNotificationIds?.length})</p>
                      <div className="btn-group">
                        <button
                          className="btn btn-link"
                          disabled={removeNotificationIds?.length < 1}
                          onClick={() => toggleDeleteModal()}
                        >
                          Delete
                        </button>
                        <button
                          className="btn btn-link"
                          onClick={() => setNotificationCheckbox(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                  {cartData && cartData?.length > 0 && (
                    <div className="pay-desc">
                      <div className="pay-sum">
                        <h3>Order summary</h3>
                        {totalInstrumentPrice > 0 && (
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Total Instruments</p>
                            {/* <p>2 x $667 @$339 each</p> */}
                            <p>{formatPrice(totalInstrumentPrice)}</p>
                          </div>
                        )}
                        {totalEventPrice > 0 && (
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Total Tickets</p>
                            {/* <p>2 x $667 @$339 each</p> */}
                            <p>{formatPrice(totalEventPrice)}</p>
                          </div>
                        )}
                        {cart?.shipping_fees > 0 && (
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Shipping Fee</p>
                            <p>${cart?.shipping_fees?.toFixed(2)}</p>
                          </div>
                        )}
                        <div className="d-flex align-items-center justify-content-between">
                          <p>Service Fee</p>
                          <p>${cart?.platformFees?.toFixed(2)}</p>
                        </div>

                        <hr />
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>Payable Amount</h5>
                          <h5>
                            {(
                              totalEventPrice +
                              totalInstrumentPrice +
                              cart?.platformFees +
                              cart?.shipping_fees
                            ).toFixed(2)}
                          </h5>
                          {/* <h5>$ {calculateTotalPrice(cartData)}</h5> */}
                        </div>
                      </div>
                      <div className="d-flex">
                        <Link
                          to={RoutesLink?.Instruments}
                          // disabled={!cartData || cartData?.length < 1 || !addressId}
                          className="btn btn-primary w-100 w-sm-75 w-md-50 mx-auto d-block me-2"
                          onClick={checkoutHandler}
                        >
                          Continue Shoping
                        </Link>
                        <button
                          // disabled={!cartData || cartData?.length < 1 || !addressId}
                          className="btn  btn-outline-primary  w-100 w-sm-75 w-md-50 mx-auto d-block ms-2"
                          onClick={checkoutHandler}
                        >
                          Proceed to checkout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <EmptyCart closeCartModal={closeCartModal} />
          )}
        </OffcanvasBody>
      </Offcanvas>
      {deleteModal && (
        <Modal
          isOpen={true}
          toggle={toggleDeleteModal}
          className="modal-dialog modal-dialog-centered deletemodal"
        >
          <ConfrimDeleteModal
            toggleDeleteModal={toggleDeleteModal}
            onConfirm={handleConfirmDelete}
          />
        </Modal>
      )}
    </>
  );
}

export default CartModal;
