import React from "react";
import { Link } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import { useSelector } from "react-redux";

function PromoModal({ onClose }) {
  const userDetails = useSelector((store) => store?.user?.userDetails);
  return (
    <div className="promo">
      <div className="container-fluid">
        <div
          className="promo-card"
          style={{
            translate: "none",
            rotate: "none",
            scale: "none",
            transform: "translate(0px, 0px)",
            opacity: 1,
          }}
        >
          <button onClick={onClose} className="btn-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={15}
              height={15}
              viewBox="0 0 15 15"
              fill="none"
            >
              <path
                d="M8.70611 7.04877L13.7543 2.01233C13.9754 1.79127 14.0995 1.49144 14.0995 1.1788C14.0995 0.866162 13.9754 0.56633 13.7543 0.345262C13.5332 0.124195 13.2334 0 12.9208 0C12.6081 0 12.3083 0.124195 12.0872 0.345262L7.05078 5.39344L2.01434 0.345262C1.79327 0.124195 1.49344 -2.32932e-09 1.1808 0C0.868165 2.32933e-09 0.568333 0.124195 0.347266 0.345262C0.126198 0.56633 0.00200354 0.866162 0.00200354 1.1788C0.00200354 1.49144 0.126198 1.79127 0.347266 2.01233L5.39544 7.04877L0.347266 12.0852C0.237229 12.1943 0.149891 12.3242 0.0902886 12.4673C0.0306864 12.6103 0 12.7638 0 12.9187C0 13.0737 0.0306864 13.2272 0.0902886 13.3702C0.149891 13.5133 0.237229 13.6431 0.347266 13.7523C0.456404 13.8623 0.586249 13.9497 0.729311 14.0093C0.872373 14.0689 1.02582 14.0995 1.1808 14.0995C1.33578 14.0995 1.48923 14.0689 1.63229 14.0093C1.77536 13.9497 1.9052 13.8623 2.01434 13.7523L7.05078 8.7041L12.0872 13.7523C12.1964 13.8623 12.3262 13.9497 12.4693 14.0093C12.6123 14.0689 12.7658 14.0995 12.9208 14.0995C13.0757 14.0995 13.2292 14.0689 13.3722 14.0093C13.5153 13.9497 13.6451 13.8623 13.7543 13.7523C13.8643 13.6431 13.9517 13.5133 14.0113 13.3702C14.0709 13.2272 14.1016 13.0737 14.1016 12.9187C14.1016 12.7638 14.0709 12.6103 14.0113 12.4673C13.9517 12.3242 13.8643 12.1943 13.7543 12.0852L8.70611 7.04877Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <img src="images/promo.png" alt="" />
          <h1>Jubal Talents</h1>
          <p>
            Join as a Talent or Vendor to be part of the Jubal Talents community
            granting you access to performance and Sell tickets, find and hire
            talent, promote your artist, create events, and more.
          </p>
          <Link
            to={RoutesLink.talent_sign_up}
            state={userDetails}
            className="btn btn-outline-light"
          >
            Let’s get started
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PromoModal;
