import React, { useRef, useEffect, useState } from "react";
import "./CustomVideoPlayer.css";

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null); // Ref to access the video element
  const observerRef = useRef(null); // Ref to hold the IntersectionObserver instance
  const [hasUserInteracted, setHasUserInteracted] = useState(false); // State to track if the user has interacted

  useEffect(() => {
    // Function to handle play/pause based on video visibility in the viewport
    const handlePlayPause = (entries) => {
      entries.forEach((entry) => {
        // If video is in view and the user has interacted, play the video
        if (entry.isIntersecting && hasUserInteracted) {
          videoRef.current?.play().catch((error) => {
            console.log("Failed to play video:", error); // Handle play error
          });
        } else {
          videoRef.current?.pause(); // Pause the video if it's not in view
        }
      });
    };

    // Options for IntersectionObserver to trigger play/pause when 25%-75% of video is in view
    const observerOptions = {
      rootMargin: "0px",
      threshold: [0.25, 0.75],
    };

    // Create an IntersectionObserver instance and start observing the video element
    observerRef.current = new IntersectionObserver(
      handlePlayPause,
      observerOptions
    );
    observerRef.current.observe(videoRef.current); // Observe the video element

    // Cleanup the observer when the component is unmounted
    return () => {
      observerRef.current.disconnect();
    };
  }, [hasUserInteracted]); // Re-run the effect when `hasUserInteracted` changes

  // Function to set the user interaction flag to true
  const handleUserInteraction = () => {
    setHasUserInteracted(true); // Mark that the user has interacted
  };

  useEffect(() => {
    // Add event listeners for click and keydown to detect user interaction
    window.addEventListener("click", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);

    // Cleanup event listeners when the component is unmounted
    return () => {
      window.removeEventListener("click", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
    };
  }, []); // Empty dependency array means this runs only once when the component mounts

  return (
    <div className="App content">
      <div className="container d-flex align-items-center">
        <div className="video-container ">
          <video
            style={{ maxWidth: "100%" }}
            ref={videoRef}
            src={src}
            muted // Ensure the video is muted for autoplay
            controls
          ></video>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
