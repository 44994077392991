import { Link } from "react-router-dom";

const JoinUsSection = () => {
  return (
    <>
      <div className="max-width-container p-0">
        <section className="join-us d-flex align-items-end parallax-bg">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 text-center">
                <h2 className="h3">
                  Let's build a future where talent knows no boundaries.
                  Together, we can transform challenges into opportunities for
                  musicians around the world.
                </h2>
              </div>
              <div className="col-12 col-md-5 col-lg-4">
                <Link to="/sign-in" className="btn btn-light w-100">
                  Join us on this musical journey
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default JoinUsSection;
