// src/actions/cartModalActions.js

import { OPEN_CART_MODAL, CLOSE_CART_MODAL } from "../types";

export const openCartModal = () => ({
  type: OPEN_CART_MODAL,
});

export const closeCartModal = () => ({
  type: CLOSE_CART_MODAL,
});
