// src/withNetworkDetection.js

import React, { useEffect, useState } from "react";
import AddAddressModal from "./Components/Modals/AddAddressModal";
import Nointernet from "./Helper/Nointernet";

const withNetworkDetection = (WrappedComponent) => {
  // The HOC returns a functional component
  const NetworkDetectionWrapper = (props) => {
    // Declare the state and effect hooks correctly at the top level of the component
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
      const handleOnline = () => setIsOnline(true);
      const handleOffline = () => setIsOnline(false);

      // Add event listeners for online and offline status
      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);

      // Cleanup function to remove event listeners
      return () => {
        window.removeEventListener("online", handleOnline);
        window.removeEventListener("offline", handleOffline);
      };
    }, []); // Empty dependency array to run once on mount

    // Conditionally render the AddAddressModal when offline
    if (!isOnline) {
      return <Nointernet />;
    }

    // Otherwise, render the wrapped component with all passed props
    return <WrappedComponent {...props} />;
  };

  // Return the functional component from the HOC
  return NetworkDetectionWrapper;
};

export default withNetworkDetection;
