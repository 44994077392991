import ReactOwlCarousel from "react-owl-carousel";
import CommentModal from "../../Components/Modals/CommentModal";
import LikesModal from "../../Components/Modals/LikesModal";
import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import DashboardLayout from "../../Components/Layout/DashboardLayout";
import { useEffect, useId, useState } from "react";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import SkulLoader from "../../Helper/SkulLoader";
import useScrollPagination from "../../Helper/Helper";
import { useDispatch, useSelector } from "react-redux";
import {
  Save_talents,
  SaveEvent_Likes,
  SaveInstrument_Likes,
} from "../../store/actions/UserActions";
import { Link } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import DashboardPostCard from "../../Components/Layout/DashboardPostCard";
import PromoModal from "../../Components/Modals/PromoModal";
import { SvgIcons } from "../../Components/Svg/SvgIcons";
import { toast } from "material-react-toastify";
import { Spinner } from "reactstrap";

const Dashboard = () => {
  // State declarations
  const [createPostModal, setCreatePostModal] = useState(false);
  const postToggle = () => setCreatePostModal(!createPostModal);
  const [Allpost, setAllposts] = useState([]);
  const [isLoading, setisLoading] = useState([false]);
  const [likeModalVisible, setLikeModalVisible] = useState(false);
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [currentPostId, setCurrentPostId] = useState(null);
  const [currentPostdata, setcurrentPostdata] = useState(null);
  const [AllEvents, setAllEvents] = useState(null);
  const [page, setpage] = useState({
    totalpage: 0,
    page: 1,
  });
  const [loading, setLoading] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(true); // New state for global loading
  const userID = useSelector((state) => state?.user);
  const AllInstruments = useSelector(
    (state) => state?.user?.SaveInstrument_Likes
  );

  const [SuggestedTalents, setSuggestedTalents] = useState([]);
  // const SuggestedTalents = useSelector((state) => state?.user?.Save_talents);

  const AllReduxEvent = useSelector((state) => state?.user?.SaveEvent_Likes);
  const dispatch = useDispatch();

  // Function to fetch instruments list
  const GetInstrumentsList = () => {
    let bodyData = {
      limit: 20,
      page: 1,
      where: {
        status: "published",
      },
    };
    return handleFetchRequest(ApiUrl.get_instruments, "POST", bodyData).then(
      (response) => {
        if (response.status === true) {
          dispatch(SaveInstrument_Likes(response?.data?.instrument));
        }
      }
    );
  };

  // Function to fetch artist list
  const GetArtistList = () => {
    handleFetchRequest(ApiUrl.fillter_user, "GET").then((res) => {
      if (res.status === true) {
        setSuggestedTalents(
          res?.data?.filter(
            (talent) =>
              talent?.User?.type === 3 &&
              !talent?.isFollowedByMe &&
              talent?.User?.id !== userID?.userDetails?.id
          )
        );
      }
    });
  };

  // Function to fetch event list
  const GetEventList = () => {
    let bodyData = {
      where: {
        visibilityType: "public",
        eventStatus: 3,
      },
      limit: 20,
      showDeleted: false,
    };
    return handleFetchRequest(ApiUrl.get_event, "POST", bodyData).then(
      (response) => {
        if (response.status === true) {
          dispatch(SaveEvent_Likes(response?.data?.event));
        }
      }
    );
  };

  // Function to fetch posts
  const GetPostHandler = () => {
    setLoading(true);
    return handleFetchRequest(
      `${ApiUrl.get_post}?limit=10&pageNumber=${page.page}`
    )
      .then((response) => {
        if (response.status === true) {
          setAllposts((prevPosts) => [...prevPosts, ...response.data.posts]);
          setpage((prepage) => ({
            ...prepage,
            totalpage: response?.data?.totalPages,
          }));
          setpage((prevPage) => ({
            ...prevPage,
            page: prevPage.page + 1,
          }));
          setisLoading(false);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // Use custom hook for scroll pagination
  useScrollPagination(GetPostHandler, page.totalpage, loading, page.page);

  // Effect to fetch initial data
  useEffect(() => {
    Promise.all([
      GetEventList(),
      GetPostHandler(),
      GetInstrumentsList(),
      GetArtistList(),
    ]).then(() => {
      setGlobalLoading(false);
    });
  }, []);

  // Helper function to update data in the posts array
  const DataMapHandler = (Arr, id, object, condition, objLabel, objCount) => {
    console.log("first", Arr, id, object, condition, objLabel, objCount);
    const newData = Arr.map((item) => {
      if (item?.id === id) {
        return {
          ...item,
          [objLabel]: objCount,
          [object]: !condition,
        };
      } else {
        return item;
      }
    });
    return newData;
  };

  // Function to handle post likes
  const LikeHandler = (data, like, type) => {
    const newData = DataMapHandler(
      Allpost,
      data?.id,
      "isLikedByMe",
      like,
      "Likes",
      like ? Number(data?.Likes) - 1 : Number(data?.Likes) + 1
    );
    setAllposts(newData);

    const LikesData = {
      type: type,
      postId: data?.id,
    };

    handleFetchRequest(ApiUrl.post_like_comment, "POST", LikesData).then(
      (response) => {
        if (response.status !== true) {
          const newData = DataMapHandler(
            Allpost,
            data?.id,
            "isLikedByMe",
            like,
            "Likes",
            like ? Number(data?.Likes) - 1 : Number(data?.Likes) + 1
          );
          setAllposts(newData);
        }
      }
    );
  };

  // Function to handle following/unfollowing users
  const handleFollowClick = (data, like, type) => {
    const updatedData = DataMapHandler(
      Allpost,
      data?.id,
      "isFollowedByMe",
      !data.isFollowedByMe
    );

    setAllposts(updatedData);
    handleFetchRequest(`${ApiUrl.Follow_user}${data?.User?.id}`).then(
      (response) => {
        if (response.status === true) {
          // toast.success("User followed");
          const newData = DataMapHandler(
            Allpost,
            data?.id,
            "isFollowedByMe",
            response?.message == "FOLLOWED" ? false : true
          );
          setAllposts(newData);
        }
      }
    );
  };

  // Function to handle following/unfollowing talents
  const handleTalentFollowClick = (talentId) => {
    // handleFetchRequest(`${ApiUrl.Follow_user}${talentId}`).then(
    //   (response) => {
    //     if (response.status === true) {
    //       GetArtistList();
    //     }
    //   }
    // );
    setSuggestedTalents((prevTalents) =>
      prevTalents.map((t) =>
        t?.User?.id === talentId
          ? { ...t, isFollowedByMe: !t.isFollowedByMe }
          : t
      )
    );
    handleFetchRequest(`${ApiUrl.Follow_user}${talentId}`).then((response) => {
      if (response.status === true) {
        // toast.success(response?.message);
      }
    });
  };

  // Function to save/unsave posts
  const savePost = (data, save) => {
    let API;
    if (save) {
      API = `${ApiUrl.post_remove_save}${data?.id}`;
    } else {
      API = `${ApiUrl.post_save}${data?.id}`;
    }
    handleFetchRequest(API).then((response) => {
      if (response.status === true) {
        const newData = DataMapHandler(Allpost, data?.id, "isSavedByMe", save);
        setAllposts(newData);
      }
    });
  };

  // Function to add comments to posts
  const commentPost = (id, comment, type, setCommentData) => {
    const data = {
      postId: id,
      comment: comment,
    };

    handleFetchRequest(`${ApiUrl?.post_comment}`, type, data).then(
      (response) => {
        if (response.status === true) {
          const newData = DataMapHandler(
            Allpost,
            id,
            null,
            null,
            "Comments",
            Number(response?.data?.commentCount)
          );
          setAllposts(newData);
          setCommentData("");
        }
      }
    );
  };

  // Functions to handle modal visibility
  const handleLikeClick = (data) => {
    setcurrentPostdata(data);
    setLikeModalVisible(true);
  };

  const handleCommentClick = (data) => {
    setcurrentPostdata(data);
    setCommentModalVisible(true);
  };

  const closeModals = () => {
    setLikeModalVisible(false);
    setCommentModalVisible(false);
  };

  const [showPopup, setShowPopup] = useState(false);

  // Effect to show promo popup for new users
  useEffect(() => {
    const hasSeenPopup = localStorage.getItem("hasSeenPopup");
    const userType = userID?.userDetails?.type;

    if (hasSeenPopup == "false" && userType == 1) {
      setShowPopup(true);
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
    localStorage.setItem("hasSeenPopup", "true");
  };

  // Function to handle comment likes
  const likeComment = (data, like, type) => {
    console.log("data", data);

    // Ensure data and latestComment are valid
    if (!data || !data?.latestComment) {
      console.error("Invalid data or missing latestComment");
      return;
    }

    // Get current comment like count and ensure it's a valid number
    const currentCommentLikesCount =
      Number(data?.latestComment?.commentLikes) || 0;

    // Determine new comment like count based on like action
    const newCommentLikesCount = like
      ? currentCommentLikesCount - 1
      : currentCommentLikesCount + 1;

    // Optimistically update comment data in latestComment object
    const optimisticData = CommentLikeDataMap(
      Allpost,
      data?.id, // Post ID to identify the post in the array
      data?.latestComment?.id, // Comment ID to identify the comment in the post
      like, // Current state of like, to toggle it
      "isLikedByMe", // Field to update inside latestComment
      newCommentLikesCount // New count for comment likes after like/dislike
    );

    setAllposts(optimisticData); // Optimistically update state

    // API call to update the like status in the backend
    const LikesData = {
      type: "COMMENT",
      commentId: data?.latestComment?.id,
    };

    handleFetchRequest(ApiUrl.post_like_comment, type, LikesData)
      .then((response) => {
        if (response.status !== true) {
          console.error("Failed to like comment:", response.message);
          // Revert state change if the API call fails
          const revertData = CommentLikeDataMap(
            Allpost,
            data?.id,
            data?.latestComment?.id,
            !like, // Revert to the previous state
            "isLikedByMe", // Field to revert inside latestComment
            currentCommentLikesCount // Revert to original comment likes count
          );
          setAllposts(revertData); // Revert state update
        }
      })
      .catch((error) => {
        console.error("Error in like request", error);
        // Revert state change if the API call fails
        const revertData = CommentLikeDataMap(
          Allpost,
          data?.id,
          data?.latestComment?.id,
          !like, // Revert to the previous state
          "isLikedByMe", // Field to revert inside latestComment
          currentCommentLikesCount // Revert to original comment likes count
        );
        setAllposts(revertData); // Revert state update
      });
  };

  // Helper function to update comment like data
  const CommentLikeDataMap = (
    Arr,
    postId,
    commentId,
    condition,
    object,
    commentLikeCount
  ) => {
    return Arr.map((item) => {
      // Match the post by id
      if (item?.id === postId) {
        return {
          ...item,

          // Update the latestComment's fields if the commentId matches
          latestComment:
            item?.latestComment?.id === commentId
              ? {
                  ...item.latestComment,
                  [object]: !condition, // Toggle isLikedByMe based on the current state
                  commentLikes: commentLikeCount, // Update comment likes count
                }
              : item.latestComment,
        };
      } else {
        return item;
      }
    });
  };

  // Function to report a post
  const reportPost = (data) => {
    console.log("data ====", data);
    handleFetchRequest(`${ApiUrl.report_post}${data?.id}`, "POST").then(
      (response) => {
        if (response.status === true) {
          const newData = DataMapHandler(
            Allpost,
            data?.id,
            "isReportedByMe",
            response?.message == "POST REPORTED" ? false : true
          );
          setAllposts(newData);
          toast?.success(response?.message);
        }
      }
    );
  };

  // Function to report a user
  const reportUser = (data) => {
    const options = {
      reason: "reason",
    };
    handleFetchRequest(
      `${ApiUrl.report_user}${data?.User?.id}`,
      "POST",
      options
    ).then((response) => {
      if (response.status === true) {
        const newData = DataMapHandler(
          Allpost,
          data?.id,
          "isUserReportedByMe",
          response?.message == "User Reported" ? false : true
        );
        setAllposts(newData);
        toast.success(response?.message);
      }
    });
  };

  // Render the component
  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <DashboardLayout>
        {/* Render promo modal if needed */}
        {showPopup && <PromoModal onClose={handleClosePopup} />}
        <DashboardSidebar postToggle={postToggle} />
        {globalLoading === true ? (
          <SkulLoader />
        ) : (
          <div className="center-content" key={Allpost.length}>
            {/* Render feed section */}
            <div className="feed">
              {/* Map through posts and render DashboardPostCard for each */}
              {Allpost &&
                Allpost.map((res, index) => {
                  return (
                    <DashboardPostCard
                      data={res}
                      index={index}
                      LikeHandler={LikeHandler}
                      savePost={savePost}
                      onLikeClick={handleLikeClick}
                      onCommentClick={handleCommentClick}
                      onfollwuser={handleFollowClick}
                      commentPost={commentPost}
                      likeComment={likeComment}
                      reportPost={reportPost}
                      reportUser={reportUser}
                    />
                  );
                })}
              {/* Show loading spinner if more posts are being fetched */}
              {loading && (
                <p className="text-center">
                  {" "}
                  <Spinner />
                </p>
              )}
            </div>
            {/* Render right sidebar content */}
            <div className="side-content-right">
              {/* Suggested Talents section */}
              <div className="card featured-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 card-head border-0">
                      <div className="d-flex justify-content-between">
                        <h5>Suggested Talents</h5>
                        <Link to="/jubal-artist">Show more</Link>
                      </div>
                    </div>
                    {SuggestedTalents?.slice(1, 5)?.map((talent) => (
                      <div className="col-12 px-0" key={talent.id}>
                        <div className="user-det-head">
                          <div className="user-det d-flex justify-content-between">
                            <div className="d-flex gap-3">
                              <Link
                                to={
                                  talent?.User?.id === userID?.userDetails?.id
                                    ? RoutesLink?.MyProfile
                                    : RoutesLink?.profile
                                }
                                state={{ id: talent?.User?.id }}
                              >
                                {talent?.profilePhoto ? (
                                  <span className="user-dp">
                                    <img
                                      src={talent?.profilePhoto}
                                      alt={talent?.firstName}
                                    />
                                  </span>
                                ) : (
                                  <span className="user-dp no-img">
                                    {" "}
                                    {talent?.firstName?.toUpperCase()[0]}
                                  </span>
                                )}
                              </Link>
                              <div className="user-ac">
                                <div className="d-flex gap-2 align-items-center">
                                  <span className="username">
                                    <Link
                                      to={
                                        talent?.User?.id ===
                                        userID?.userDetails?.id
                                          ? RoutesLink?.MyProfile
                                          : RoutesLink?.profile
                                      }
                                      state={{ id: talent?.User?.id }}
                                    >
                                      {talent?.firstName} {talent?.lastName}
                                    </Link>
                                  </span>
                                  <span className="usertype mx-1">
                                    <span>{SvgIcons?.talentIcon}</span>
                                    Talent
                                  </span>
                                </div>

                                <p>
                                  {talent?.Talent?.catagory &&
                                    (Array.isArray(talent?.Talent?.catagory) ? (
                                      talent?.Talent?.catagory[0]
                                    ) : (
                                      <span>
                                        {
                                          JSON.parse(
                                            talent?.Talent?.catagory
                                          )[0]
                                        }
                                      </span>
                                    ))}
                                </p>
                              </div>
                            </div>
                            <span
                              className="ms-2 follow align-self-center"
                              onClick={() =>
                                handleTalentFollowClick(talent?.User?.id)
                              }
                            >
                              {talent?.isFollowedByMe ? "Unfollow" : "Follow"}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Jubal Events section */}
              <div className="card featured-card event-card-side">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 card-head border-0">
                      <div className="d-flex justify-content-between">
                        <h5>Jubal Events</h5>
                        <Link to={RoutesLink.allEvents}>Show more</Link>
                      </div>
                    </div>
                    {/* {console.log("AllEvents", AllEvents)} */}
                    {AllReduxEvent &&
                      AllReduxEvent.slice(0, 3).map((res, index) => {
                        return (
                          <div className="col-12 px-0" key={index}>
                            <Link
                              to={RoutesLink?.EventDetails}
                              state={res?.id}
                              className="event-info"
                              key={index}
                            >
                              <div className="d-flex align-items-center gap-2 gap-md-3">
                                <img
                                  src={res?.eventImage}
                                  className="Event-image"
                                  style={{ borderRadius: 5 }}
                                  alt=""
                                />
                                <div>
                                  <span>{res.eventName}</span>
                                  <h6>{res.eventDescription}</h6>
                                  <p>{res?.eventTimeLocation?.location}</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              {/* Jubal Instruments section */}
              <div className="card featured-card instrument-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 card-head border-0">
                      <div className="d-flex justify-content-between">
                        <h5>Jubal Instruments</h5>
                        <Link to={RoutesLink?.Instruments}>Show more</Link>
                      </div>
                    </div>

                    <div className="col-12 p-0">
                      <div className="top-instruments">
                        <ReactOwlCarousel
                          items={1.3} // Number of items to display
                          autoplay={true} // Enable auto-play
                          autoplayTimeout={2000} // Delay between slides in milliseconds (2 seconds)
                          autoplaySpeed={1000} // Transition speed for sliding in milliseconds (1 second)
                          loop={true} // Enable infinite loop
                          smartSpeed={1000} // Speed of slide animation in milliseconds (1 second)
                          dots={false} // Hide dots/navigation
                          nav={false} // Hide next/prev buttons
                          animateOut="fadeOut" // Animation for slide-out (optional for smoothness)
                          animateIn="fadeIn" // Animation for slide-in (optional for smoothness)
                          mouseDrag={false} // Disable mouse dragging for smoother autoplay
                          touchDrag={false} // Disable touch dragging for smoother autoplay
                          slideTransition="ease-in-out" // CSS easing function for transition
                        >
                          {AllInstruments &&
                            AllInstruments?.filter(
                              (inst) => inst?.instrument_image
                            )?.map((data, index) => {
                              return (
                                <div className="top-items me-3" key={index}>
                                  <div>
                                    <img
                                      src={data?.instrument_image[0]}
                                      alt=""
                                    />
                                  </div>
                                  <div>
                                    <div className="d-flex flex-row p-0 align-items-center justify-content-between gap-2">
                                      <Link
                                        to={RoutesLink?.InstrumentsDetails}
                                        state={{
                                          instrumentdata: data?.id,
                                        }}
                                      >
                                        <h2>{data?.instrument_name}</h2>
                                      </Link>
                                      <p>${data?.selling_price}</p>
                                    </div>
                                    <p>{data?.brand}</p>
                                  </div>
                                </div>
                              );
                            })}
                        </ReactOwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </DashboardLayout>

      {/* Render modals */}
      <CommentModal
        isOpen={commentModalVisible}
        onRequestClose={closeModals}
        PostId={currentPostdata?.id}
        postData={currentPostdata}
        commentPost={commentPost}
        likePost={LikeHandler}
        savePost={savePost}
      />

      <LikesModal
        isOpen={likeModalVisible}
        onRequestClose={closeModals}
        postData={currentPostdata}
        likePost={LikeHandler}
        savePost={savePost}
        handleFollowClick={handleFollowClick}
      />
    </>
  );
};

export default Dashboard;
