import { useEffect, useState } from "react";
import DashboardSearchbar from "../Searchbar/DashboardSearchbar";
import UsersMenuModal from "../Modals/UsersMenuModal";
import NotificationModal from "../Modals/NotificationModal";
import CartModal from "../Modals/CartModal";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import { openCartModal } from "../../store/actions/cartModalActions";
import EventSearchBar from "../Searchbar/EventSearchBar";

export default function MobileHeader({
  isPage,
  searchTerm,
  setSearchTerm,
  artistFillter,
  setartistFillter,
}) {
  const [modal, setModal] = useState(false); // State for notification modal visibility
  const toggle = () => setModal(!modal); // Function to toggle notification modal
  const [UserMenuModal, setUserMenuModal] = useState(false); // State for user menu modal visibility
  const UserMenuToggle = () => setUserMenuModal(!UserMenuModal); // Function to toggle user menu modal
  const [cartModal, setCartModal] = useState(false); // State for cart modal visibility
  const CartMenuToggle = () => setCartModal(!cartModal); // Function to toggle cart modal
  const [UnreadNotification, setUnreadNotification] = useState([]); // State for unread notifications
  const [cartData, setCartData] = useState([]); // State for cart data
  const [userName, setUserName] = useState(""); // State for user's name
  // const itemCount = useSelector((state) => state.cartReducer.items?.length - 1); // Selector for item count in cart
  const userDetails = useSelector((store) => store?.user?.userDetails); // Selector for user details from Redux store

  const dispatch = useDispatch(); // Hook to access Redux dispatch function

  const handleOpen = () => {
    dispatch(openCartModal()); // Dispatch action to open cart modal
  };

  useEffect(() => {
    // useEffect to set user's name after fetching user details
    const usernameparsh = userDetails; // Get user details
    setUserName(
      usernameparsh.Profile?.firstName + " " + usernameparsh.Profile?.lastName // Set user's full name
    );
  }, [""]);

  return (
    <>
      <header>
        <div className="max-width-container dash-nav">
          <nav className="d-flex align-item-center gap-3 justify-content-between">
            <div className="brand-wrapper d-flex align-items-center">
              <Link to="/" className="p-0 d-flex align-items-center logo">
                <img
                  src={`${process.env.PUBLIC_URL}/images/logos/jubal_logo.png`}
                  alt="Jubal_logo"
                />
                <span className="d-none d-lg-block"> Jubal Talents </span>
              </Link>
            </div>
            <div className="d-flex align-items-center passfield-wrapper position-relative dash-src">
              <DashboardSearchbar />
            </div>
            <div className="d-flex align-items-center gap-3 gap-lg-4 gap-5">
              <div className="d-flex gap-3 align-items-center">
                <a
                  onClick={() => setModal(!modal)}
                  className="position-relative"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="10.223999999999998 9.432 15.696 17.136"
                      style={{ maxHeight: "500px" }}
                      width="15.696"
                      height="17.136"
                    >
                      <path
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        stroke="white"
                        d="M22.5 15C22.5 13.8065 22.0259 12.6619 21.182 11.818C20.3381 10.9741 19.1935 10.5 18 10.5C16.8065 10.5 15.6619 10.9741 14.818 11.818C13.9741 12.6619 13.5 13.8065 13.5 15C13.5 20.25 11.25 21.75 11.25 21.75H24.75C24.75 21.75 22.5 20.25 22.5 15Z"
                      />
                      <path
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        stroke="white"
                        d="M19.2981 24.75C19.1663 24.9773 18.977 25.166 18.7493 25.2971C18.5216 25.4283 18.2634 25.4973 18.0006 25.4973C17.7378 25.4973 17.4797 25.4283 17.252 25.2971C17.0242 25.166 16.835 24.9773 16.7031 24.75"
                      />
                    </svg>
                    {UnreadNotification?.length > 0 && (
                      <div className="dot top-0 end-0"></div>
                    )}
                  </span>
                </a>
                <a
                  onClick={() => handleOpen()}
                  aria-controls="cart"
                  className="position-relative"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M1.75 1.75H4.75L6.76 11.7925C6.82858 12.1378 7.01643 12.448 7.29066 12.6687C7.56489 12.8895 7.90802 13.0067 8.26 13H15.55C15.902 13.0067 16.2451 12.8895 16.5193 12.6687C16.7936 12.448 16.9814 12.1378 17.05 11.7925L18.25 5.5H5.5M16.75 16.75C16.75 17.1642 16.4142 17.5 16 17.5C15.5858 17.5 15.25 17.1642 15.25 16.75C15.25 16.3358 15.5858 16 16 16C16.4142 16 16.75 16.3358 16.75 16.75ZM8.5 16.75C8.5 17.1642 8.16421 17.5 7.75 17.5C7.33579 17.5 7 17.1642 7 16.75C7 16.3358 7.33579 16 7.75 16C8.16421 16 8.5 16.3358 8.5 16.75Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {cartData?.length > 0 && (
                      <div className="dot top-0 end-0"></div>
                    )}
                    {/* {itemCount != 0 && <span>{itemCount} </span>} */}
                  </span>
                </a>
              </div>
              <div className="position-relative d-none d-lg-block">
                <div
                  className="user-det-head"
                  onClick={() => setUserMenuModal(!UserMenuModal)}
                >
                  <div className="user-det d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-3 align-items-center">
                      {<img src="" alt="" />}
                      {userDetails?.Profile?.profilePhoto ? (
                        <div className="user-dp">
                          <img
                            src={userDetails?.Profile?.profilePhoto}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="user-dp no-img">
                          {userDetails?.Profile?.firstName?.toUpperCase()?.[0]}
                        </div>
                      )}

                      <div className="user-ac">
                        <div className="d-flex gap-2 align-items-center">
                          <span className="username text-white">
                            <h5>
                              {userDetails?.Profile?.firstName +
                                " " +
                                userDetails?.Profile?.lastName}
                            </h5>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      {/* {modal && <NotificationModal />} */}
      <NotificationModal
        modal={modal}
        toggle={(val) => toggle(val)}
        UnreadNotification={UnreadNotification}
        setUnreadNotification={setUnreadNotification}
        // id={EnquiryDetials?.data?.enquiryschedules}
      />
      <UsersMenuModal
        modal={UserMenuModal}
        UserMenuToggle={(val) => UserMenuToggle(val)}
      />
      <CartModal
        modal={cartModal}
        CartMenuToggle={CartMenuToggle}
        cartData={cartData}
        setCartData={setCartData}
      />
    </>
  );
}
