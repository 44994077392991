import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import { handleFetchRequest } from "../ApiHelper/ApiHelper";
import { ApiUrl } from "../utility/ApiUrl";

export const getLoginUserCaption = (userData) => {
  let caption = "";

  // Function to parse the category string or array
  const parseCategory = (category) => {
    if (typeof category === "string") {
      try {
        return JSON.parse(category); // If category is a string, attempt to parse it as JSON
      } catch (error) {
        return [category]; // If parsing fails, return it as a single item array
      }
    }
    return category; // If it's already an array, return it
  };

  // If the user is Talent, retrieve the first category as caption
  if (userData?.type === 3) {
    const category = parseCategory(userData?.Talent?.catagory);
    if (Array.isArray(category) && category.length > 0) {
      caption = category[0]; // Set first category as caption
    }
  }
  // If user has a category, retrieve the first category as caption
  if (userData?.catagory) {
    const category = parseCategory(userData?.catagory);
    if (Array.isArray(category) && category.length > 0) {
      caption = category[0]; // Set first category as caption
    }
  }

  // If the user is Vendor, retrieve the first service as caption
  if (userData?.type === 2) {
    const services = parseCategory(userData?.Vendor?.services);
    if (Array.isArray(services) && services.length > 0) {
      caption = services[0]; // Set first service as caption
    }
  }
  // Append the user's location to the caption if available
  if (userData?.Profile?.location && userData?.Profile?.location?.length > 0) {
    const location = parseCategory(userData?.Profile?.location);
    if (location?.city) {
      if (caption) {
        caption = `${caption}, ${location.city}`;
      } else {
        caption = location.city;
      }
    }
  }

  return caption; // Return the generated caption
};
// Function to parse the category string or array
export const getAddress = (address) => {
  const addressparsh = JSON.parse(address);
  let addressStrint = "";
  // Concatenate address parts into a single string
  if (address?.length > 0) {
    addressStrint =
      addressparsh.address +
      addressparsh?.state +
      addressparsh?.district +
      addressparsh?.area;
  }
  return addressStrint; // Return the full address string
};
// Function to return a badge label based on the hiring status
export const HiringStatus = (hiringStatus) => {
  // Based on the hiringStatus value, return different badge types
  if (hiringStatus === 0) {
    return (
      <span className="badge rounded-pill text-bg-warning ">
        Hiring request Sent
      </span>
    );
  } else if (hiringStatus === 2) {
    return (
      <span className="badge rounded-pill text-bg-danger ">Cancelled</span>
    );
  } else if (hiringStatus === 3) {
    return (
      <span className="badge rounded-pill text-bg-primary">Confirmed</span>
    );
  } else if (hiringStatus === 4) {
    return (
      <span className="badge rounded-pill text-bg-success">Completed</span>
    );
  } else if (hiringStatus === 1) {
    return (
      <span
        className="badge rounded-pill text-bg-primary
      "
      >
        acceptedByTalent
      </span>
    );
  }
};

// Custom hook for scroll pagination

const useScrollPagination = (getPageData, totalPage, loading, page) => {
  // Handle the scroll event
  const handleScroll = useCallback(() => {
    // If the user scrolls to the bottom, trigger page data fetch

    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      getPageData(); // Fetch more data when scrolled to bottom
    }
  }, [getPageData]);
  // Add or remove the scroll event listener based on loading and total pages
  useEffect(() => {
    if (totalPage > 1 && !loading && page <= totalPage) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, loading, totalPage, page]);
};

export default useScrollPagination;
// Function to set location details from a selected place
export const setIsLocationDetails = (selectedPlace) => {
  const locationData = {
    areaCode: "", // Initialize location fields
    area: "",
    district: "",
    town: "",
    state: "",
    address: selectedPlace?.formatted_address || "", // Use formatted address if available
    city: "",
    country: "",
    lat: selectedPlace?.geometry?.location?.lat() || null, // Latitude
    lng: selectedPlace?.geometry?.location?.lng() || null, // Longitude
    postal_code: "",
    title: "",
  };
  // Loop through address components and populate locationData accordingly
  selectedPlace?.address_components?.forEach((component) => {
    const component_types = component.types;

    // Map the component to the respective locationData field based on the type
    if (
      component_types.includes("premise") ||
      component_types.includes("street_number")
    ) {
      locationData.areaCode = component.long_name;
    }
    if (
      component_types.includes("premise") ||
      component_types.includes("street_number") ||
      component_types.includes("plus_code")
    ) {
      locationData.area = component.long_name;
    }
    if (component_types.includes("administrative_area_level_2")) {
      locationData.town = component.long_name;
    }
    if (component_types.includes("administrative_area_level_1")) {
      locationData.state = component.long_name;
    }
    if (component_types.includes("postal_code")) {
      locationData.postal_code = component.long_name;
    }
    if (component_types.includes("locality")) {
      locationData.city = component.long_name;
    }
    if (component_types.includes("country")) {
      locationData.country = component.long_name;
    }
    if (component_types.includes("administrative_area_level_3")) {
      locationData.district = component.long_name;
      locationData.title = component.long_name;
    }
  });

  return locationData; // Return the populated locationData object
};
// Function to calculate the time difference between now and a given start time
export function calculateHours(startTimeStr) {
  let startDate = moment(startTimeStr); // Parse the start time
  let endDate = moment(); // Get the current time

  let duration = moment.duration(endDate.diff(startDate)); // Calculate the duration between now and the start time
  let years = duration.asYears();
  let months = duration.asMonths();
  let days = duration.asDays();
  let hours = duration.asHours();
  let minutes = duration.asMinutes();

  // Return the time difference in the most relevant unit (years, months, etc.)
  if (years >= 1) {
    return `${years.toFixed()} years`; // Return in years if >= 1 year
  } else if (months >= 1) {
    return `${months.toFixed()} months`; // Return in months if >= 1 month
  } else if (days >= 1) {
    return `${days.toFixed()} days`; // Return in days if >= 1 day
  } else if (hours >= 1) {
    return `${Math.floor(hours)} hour`; // Return in hours if >= 1 hour
  } else {
    return `${Math.floor(minutes)} minutes`; // Return in minutes otherwise
  }
}

// Object holding different notification types and their corresponding labels

export const notificationTypes = {
  get_booking: "Get Booking",
  createBooking: "createBooking",
  updateBooking: "updateBooking",
  reviewBooking: "reviewBooking",
  postCreate: "postCreate",
  likePost: "likePost",
  likeComment: "likeComment",
  createComment: "createComment",
  replyComment: "replyComment",
  sharePost: "sharePost",
  following: "following",
  eventList: "eventList",
  follower: "follower",
  get_my_order: "Get My Order",
  get_instrument_order: "Get Insturment Order",
  tagPost: "tagPost",
  eventPurchase: "eventPurchase",
  instrumentUpdate: "instrumentUpdate",
  eventUpdate: "eventUpdate",
  orderUpdate: "orderUpdate",
};

// Helper object for utility functions
export const Helper = {
  // Function to get full name from first and last name
  getFullName: function ({ firstName = "", lastName = "" }) {
    return `${firstName} ${lastName}`; // Concatenate first and last names
  },
};

// Filters for talents, dashboard, events, and instruments

// Talent Filters
export const TalentFilters = [
  { id: "all", name: "All" },
  { id: "topRated", name: "Top Rated" },
  { id: "topFollowers", name: "Top Followers" },
  { id: "hasInstruments", name: "Has Instruments" },
  { id: "isAvailable", name: "Is Available" },
];

// Dashboard Filters
export const DashboardFilters = [
  { id: "topReated", name: "Top Rated" },
  { id: "city", name: "City" },
  { id: "topFollowers", name: "Top Followers" },
  { id: "hasInstruments", name: "Has Instruments" },
  { id: "genre", name: "Genre" },
  { id: "cityAndZipCode", name: "City & Zip code" },
];

export const EventFilters = [
  { id: "city", name: "City" },
  { id: "eventType", name: "Event Type" },
  { id: "genra", name: "Genra" },
];
// Instrument Filters
export const InstrumentFillter = [
  { id: "AllInstruments", name: "All Instruments" },
  { id: "LatestInstruments", name: "Latest Instruments" },
  { id: "PriceHighToLow", name: "Price High To Low" },
  { id: "PriceLowToHign", name: "Price Low To Hign" },
];
// Booking Filters
export const BookingFillters = [
  { id: "0", Name: "Pending" },
  { id: "1", Name: "acceptedByTalent" },
  { id: "2", Name: "cancelled" },
  { id: "3", Name: "approved" },
  { id: "4", Name: "completed" },
];

export const bookingStatusTypes = {
  pending: "0",
  acceptedByTalent: "1",
  cancelled: "2",
  approved: "3",
  completed: "4",
  draft: "5",
};

export const eventStatusTypes = [
  { pending: "0" }, // This also used as draft
  { closed: "1" },
  { started: "2" },
  { publish: "3" },
  { rejected: "4" },
];

export const OrderHelperStatus = {
  placed: "placed",
  confirmed: "confirmed",
  cancelled: "cancelled",
  shipped: "shipped",
  delivered: "delivered",
  refund_requested: "refund requested",
  refunded: "refunded",
  refundCancelled: "refund cancelled",
};

export const PlatformFeesText = {
  text: "A service fee is a small percentage charged for using the platform's services. This fee helps cover operational costs, ensure a smooth experience, and provide continued support for the features you enjoy.",
};

export const getStatusDate = (dateObj) => {
  const date = moment(dateObj).format("[Sub] DD MMM");
  return "By " + date;
};

export const calculateShippingFees = async (
  pickupLocation,
  deliveryLocation,
  quantity
) => {
  const headerData = [
    {
      itemId: "12345",
      accountNumber: {
        value: "XXXXX7364",
      },
      requestedShipment: {
        shipper: {
          address: {
            postalCode: pickupLocation.postal_code,
            countryCode: "US",
          },
        },
        recipient: {
          address: {
            postalCode: deliveryLocation.location.postal_code,
            countryCode: "US",
          },
        },
        pickupType: "DROPOFF_AT_FEDEX_LOCATION",
        rateRequestType: ["ACCOUNT", "LIST"],
        requestedPackageLineItems: [
          {
            weight: {
              units: "LB",
              value: quantity, // Assuming 1 unit of weight per quantity
            },
          },
        ],
      },
    },
  ];

  try {
    const response = await handleFetchRequest(
      ApiUrl.get_rate,
      "POST",
      headerData
    );
    if (
      response.status === true &&
      Array.isArray(response.data) &&
      response.data.length > 0
    ) {
      const quoteData = response.data[0].quote.output.rateReplyDetails;

      // Find the cheapest shipping option
      const cheapestOption = quoteData.reduce(
        (cheapest, option) => {
          const accountRate = option.ratedShipmentDetails.find(
            (detail) => detail.rateType === "ACCOUNT"
          );
          const totalNetCharge = accountRate
            ? accountRate.totalNetCharge
            : Infinity;
          return totalNetCharge < cheapest.totalNetCharge
            ? { serviceType: option.serviceType, totalNetCharge }
            : cheapest;
        },
        { serviceType: "", totalNetCharge: Infinity }
      );

      return {
        serviceType: cheapestOption.serviceType,
        shippingFee: cheapestOption.totalNetCharge,
      };
    }
    return { serviceType: "", shippingFee: 0 };
  } catch (error) {
    console.error("Error calculating shipping fees:", error);
    return { serviceType: "", shippingFee: 0 };
  }
};
