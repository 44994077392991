/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import HireingHeader from "./HireingHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import moment from "moment";
import BacklineService from "../../Components/Modals/BacklineServices";
import { toast } from "material-react-toastify";
import CheckBacklineService from "../../Components/Modals/CheckBacklineService";
import { RoutesLink } from "../../utility/RoutesLink";
import { Spinner } from "reactstrap";
import AgreementModal from "../../Components/Modals/AgreementModal";

function ConfirmHiring() {
  // Import necessary hooks and functions
  const location = useLocation(); // Hook to get the current location object
  const [HiringDetials, setHiringDetials] = useState([]); // State to hold hiring details
  const [isLoading, setIsLoading] = useState(false); // Loading state for submission
  const [backlineService, setBacklineService] = useState(false); // State to control visibility of backline service
  const [ShowAgreement, setShowAgreement] = useState(false); // State to control visibility of the agreement

  console.log(location?.state);
  // Function to toggle the display of the agreement
  const handleShowAgreement = () => {
    setShowAgreement((pre) => !pre); // Toggle agreement visibility
  };

  const navigate = useNavigate(); // Hook to programmatically navigate to different routes

  // Function to toggle the display of backline service information
  const handleShowBacklineService = () => {
    setBacklineService((pre) => !pre); // Toggle backline service visibility
  };

  // Effect to fetch hiring booking details when the component mounts
  useEffect(() => {
    handleFetchRequest(
      `${ApiUrl.get_hire_booking_detials}${location.state?.formdata.eventId}`
    ).then((res) => {
      if (res.status === true) {
        console.log(res); // Log the response for debugging
        setHiringDetials(res?.data); // Set the fetched hiring details in state
      }
    });
    // Set hiring details from location state (could be from a previous step)
    setHiringDetials(location.state?.hireData);
  }, [""]); // Empty dependency array to run effect only once on mount

  // Function to submit hire data
  const submitHireData = () => {
    setIsLoading(true); // Set loading state to true while submitting
    handleFetchRequest(
      `${ApiUrl.hire_telent}`,
      "POST",
      location.state?.formdata // Send form data in POST request
    ).then((res) => {
      if (res.status === true) {
        setIsLoading(false); // Set loading state to false after submission
        // Uncomment below lines if you need to set user details or handle login
        // setUserDetials(res.data);
        // getLoginUserCaption(res?.data);
        toast.success(res?.message); // Show success message on successful submission
        // Navigate to ManageRequest route with response data
        navigate(RoutesLink?.ManageRequest, {
          state: res?.data,
        });
      } else {
        setIsLoading(false); // Set loading state to false if submission fails
        toast.error(res?.message); // Show error message on failure
      }
    });
  };

  // Prepare start and end date strings from the location state
  const startDateString = `${location?.state?.formdata?.performanceDetail?.date}T${location?.state?.formdata?.performanceDetail?.startTime}:00`;
  const endDateString = `${location?.state?.formdata?.performanceDetail?.date}T${location?.state?.formdata?.performanceDetail?.endTime}:00`;

  // Convert the start and end date strings into Date objects
  const startTime = new Date(startDateString); // Convert start date string to Date object
  const endTime = new Date(endDateString); // Convert end date string to Date object

  // Calculate the duration between the start and end times
  const duration = endTime - startTime; // Duration in milliseconds

  // Calculate the duration in hours
  const hours = Math.floor(duration / 3600000); // Convert duration to hours

  return (
    <section className="px-0 main-content">
      <div className="max-width-container">
        {/* =========================== */}
        {/* Main feed grid Start */}
        {/* =========================== */}
        <div className="row justify-content-between 5">
          <div className="col-12 p-0">
            <HireingHeader
              userData={location?.state?.userdata}
              // user={location?.state?.userDetials}
            />
          </div>
          <div className="col-12 p-0">
            <div className="user-availability">
              <p>Confirm Process</p>
              <span>Complete the hiring process</span>
            </div>
          </div>
          <div className="col-12 col-lg-6 p-0 pe-lg-3 pe-xl-4 mb-4 mb-lg-0">
            <div className="card event-card confirm-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-6 p-0">
                    <div className="d-flex flex-column">
                      <p>Contact name</p>
                      <span>{location?.state?.formdata?.contactFullName}</span>
                    </div>
                  </div>
                  <div className="col-6 p-0 text-end">
                    <div className="d-flex flex-column">
                      <p>Title of event</p>
                      <span>{location?.state?.evtTitle}</span>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="d-flex flex-column">
                      <p>Organisation name</p>
                      <span>
                        {location?.state?.formdata?.contactOrganisation}
                      </span>
                    </div>
                  </div>
                  <div className="col-6 p-0 text-end">
                    <div className="d-flex flex-column">
                      <p>Type of performance</p>
                      <span>{location?.state?.formdata?.performanceType}</span>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="d-flex flex-column">
                      <p>Date &amp; Time</p>
                      <span>
                        {" "}
                        {moment(
                          location?.state?.formdata?.performanceDetail?.date
                        ).format("ll")}
                      </span>
                      <span>
                        {
                          location?.state?.formdata?.performanceDetail
                            ?.startTime
                        }{" "}
                        To{" "}
                        {location?.state?.formdata?.performanceDetail?.endTime}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col-6 p-0 text-end">
                    <div className="d-flex flex-column">
                      <p>Duration</p>
                      <span>
                        {" "}
                        {hours} hours
                        {/* {hours > 0 ? `${hours} hours` : ""}{" "}
                        {minutes > 0 ? `${minutes} minutes` : ""} */}
                      </span>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="d-flex flex-column">
                      <p>Budget</p>
                      <span>${location?.state?.formdata?.budget}</span>
                    </div>
                  </div>
                  <div className="col-6 p-0 text-end">
                    <div className="d-flex flex-column">
                      <p>Travel Required</p>
                      <span>
                        {location?.state?.formdata?.anyTravelRequired === true
                          ? "Yes"
                          : "No"}
                      </span>
                    </div>
                  </div>
                  {/* <div className="col-12 p-0">
                    <div className="d-flex flex-column">
                      <p>Service Fee</p>
                      <span>$.00</span>
                    </div>
                  </div> */}
                  <div className="col-12 p-0">
                    <div className="d-flex flex-column">
                      <p>Sound Check Time</p>
                      <span>
                        {location?.state?.formdata?.isSoundCheck === false
                          ? "No Sound Check"
                          : location?.state?.formdata?.soundCheckTime?.date}
                        {location?.state?.formdata?.isSoundCheck === true &&
                          location?.state?.formdata?.soundCheckTime?.time}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 p-0">
                    <div className="d-flex flex-column">
                      <p>Location</p>
                      <span>
                        {location?.state?.evtLocation}
                        {/* {location?.state?.formdata?.location?.address} */}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 p-0">
                    <div className="d-flex flex-column">
                      <p>Additional information</p>
                      <span>
                        {location?.state?.formdata?.additionalInfo}
                        {/* {location?.state?.formdata?.location?.address} */}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 p-0">
                    <div className="d-flex">
                      <p>Included backline services</p>
                      <button
                        onClick={handleShowBacklineService}
                        className="check-btn"
                      >
                        Check services
                      </button>
                    </div>
                  </div>
                  <CheckBacklineService
                    open={backlineService}
                    backline_serviceData={
                      location?.state?.formdata?.backlineService
                    }
                    toggle={handleShowBacklineService}
                  />
                </div>
                <div className="row">
                  <div className="col-12 col-md-5 col-lg-4 p-0 pe-3">
                    <button
                      type="button"
                      className="btn btn-light w-100"
                      onClick={() => navigate(-2)}
                    >
                      Cancel
                      {/* Chat with {location?.state?.userDetials?.firstName} */}
                    </button>
                  </div>
                  <div className="col-12 col-md-4 col-lg-5 col-xl-4 p-0">
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={() => submitHireData()}
                    >
                      {isLoading ? <Spinner /> : "Send Reuest"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ConfirmHiring;
