import Header from "../../Components/Header";

export default function PageNotFound() {
  return (
    <>
      <Header />
      <div
        className="d-flex justify-content-center 
                align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="text-center">
          <h1>404</h1>
          <h2>Page Not Found</h2>
          <p>Sorry, the page you are looking for does not exist.</p>
        </div>
      </div>
    </>
  );
}
