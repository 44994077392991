import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import { eventStatusTypes } from "../../Helper/Helper";

function MyEventCard({ item, deleteEvent }) {
  return (
    <>
      <div className="col-12 px-0 ">
        <div className="event-row">
          <Link
            to={RoutesLink?.EventDetails}
            state={item?.id}
            className="d-flex gap-4"
          >
            <div className="event-cover">
              <img src={item?.eventImage} alt="" />
            </div>
            <div className="d-flex flex-column ">
              <div className="d-flex align-items-center gap-3">
                {item?.visibilityType == eventStatusTypes.pending ? (
                  <h5 className="text-md-end free">{item?.eventStatus}</h5>
                ) : (
                  <>
                    <span
                      // className="event-type public-event"
                      className={
                        item?.eventStatus == 3
                          ? "event-type public-event"
                          : "event-type private-event"
                      }
                    >
                      {/* {item?.visibilityType} */}
                      {item?.eventStatus == 3
                        ? "Published"
                        : item?.eventStatus == 0
                        ? "Draft"
                        : item?.eventStatus == 1
                        ? "Closed"
                        : ""}
                    </span>
                    {item?.eventPricing?.type == "Paid" &&
                    item?.tickets?.price > 0 ? (
                      <h5 className="text-md-end">${item?.tickets?.price}</h5>
                    ) : (
                      <h5 className="text-md-end free">Free</h5>
                    )}
                  </>
                )}
              </div>
              <p>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={13}
                    height={13}
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <path
                      d="M10.6441 1.49053H9.39185V0.864404C9.39185 0.698346 9.32588 0.539089 9.20846 0.421668C9.09104 0.304248 8.93178 0.238281 8.76572 0.238281C8.59966 0.238281 8.44041 0.304248 8.32299 0.421668C8.20557 0.539089 8.1396 0.698346 8.1396 0.864404V1.49053H4.38286V0.864404C4.38286 0.698346 4.3169 0.539089 4.19947 0.421668C4.08205 0.304248 3.9228 0.238281 3.75674 0.238281C3.59068 0.238281 3.43142 0.304248 3.314 0.421668C3.19658 0.539089 3.13062 0.698346 3.13062 0.864404V1.49053H1.87837C1.38019 1.49053 0.902424 1.68843 0.550162 2.04069C0.197899 2.39295 0 2.87072 0 3.3689V10.8824C0 11.3805 0.197899 11.8583 0.550162 12.2106C0.902424 12.5628 1.38019 12.7607 1.87837 12.7607H10.6441C11.1423 12.7607 11.62 12.5628 11.9723 12.2106C12.3246 11.8583 12.5225 11.3805 12.5225 10.8824V3.3689C12.5225 2.87072 12.3246 2.39295 11.9723 2.04069C11.62 1.68843 11.1423 1.49053 10.6441 1.49053ZM11.2702 10.8824C11.2702 11.0484 11.2042 11.2077 11.0868 11.3251C10.9694 11.4425 10.8102 11.5085 10.6441 11.5085H1.87837C1.71231 11.5085 1.55305 11.4425 1.43563 11.3251C1.31821 11.2077 1.25225 11.0484 1.25225 10.8824V6.49951H11.2702V10.8824ZM11.2702 5.24727H1.25225V3.3689C1.25225 3.20284 1.31821 3.04358 1.43563 2.92616C1.55305 2.80874 1.71231 2.74277 1.87837 2.74277H3.13062V3.3689C3.13062 3.53495 3.19658 3.69421 3.314 3.81163C3.43142 3.92905 3.59068 3.99502 3.75674 3.99502C3.9228 3.99502 4.08205 3.92905 4.19947 3.81163C4.3169 3.69421 4.38286 3.53495 4.38286 3.3689V2.74277H8.1396V3.3689C8.1396 3.53495 8.20557 3.69421 8.32299 3.81163C8.44041 3.92905 8.59966 3.99502 8.76572 3.99502C8.93178 3.99502 9.09104 3.92905 9.20846 3.81163C9.32588 3.69421 9.39185 3.53495 9.39185 3.3689V2.74277H10.6441C10.8102 2.74277 10.9694 2.80874 11.0868 2.92616C11.2042 3.04358 11.2702 3.20284 11.2702 3.3689V5.24727Z"
                      fill="#86889A"
                    />
                  </svg>
                </span>
                {item?.eventTimeLocation?.start_date},{" "}
                {item?.eventTimeLocation?.start_time} -{" "}
                {item?.eventTimeLocation?.end_date},{" "}
                {item?.eventTimeLocation?.end_time}
                {/* Nov 12, 10PM - Nov 15, 6PM */}
              </p>
              <Link to="#" className="title">
                {" "}
                {item?.eventName}
              </Link>
              <p>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={13}
                    height={15}
                    viewBox="0 0 13 15"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1 6.94651C1.01023 3.93422 3.46047 1.50057 6.47276 1.51077C9.48505 1.52104 11.9187 3.97128 11.9085 6.98357V7.04534C11.8714 9.00342 10.7781 10.8133 9.43769 12.2278C8.67112 13.0238 7.81507 13.7285 6.88662 14.3279C6.63835 14.5427 6.27011 14.5427 6.02184 14.3279C4.63774 13.427 3.42295 12.2896 2.43305 10.9677C1.55077 9.81495 1.04984 8.41581 1 6.96504V6.94651Z"
                      stroke="#ADADAD"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M6.45319 9.1261C7.81236 9.1261 8.91419 8.02427 8.91419 6.6651C8.91419 5.30593 7.81236 4.2041 6.45319 4.2041C5.09401 4.2041 3.99219 5.30593 3.99219 6.6651C3.99219 8.02427 5.09401 9.1261 6.45319 9.1261Z"
                      stroke="#ADADAD"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
                {item?.eventTimeLocation?.location}{" "}
                {/* {item?.Event?.eventTimeLocation?.state} */}
              </p>
            </div>
          </Link>
          <div className="d-flex flex-column align-items-md-end">
            <div className="dropdown dropdown-menu-end">
              <Link
                to="#"
                className="action-btn"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={4}
                  height={13}
                  viewBox="0 0 4 13"
                  fill="none"
                >
                  <path
                    d="M1.25 1.49023C1.25 1.90445 1.58579 2.24023 2 2.24023C2.41421 2.24023 2.75 1.90445 2.75 1.49023C2.75 1.07602 2.41421 0.740234 2 0.740234C1.58579 0.740234 1.25 1.07602 1.25 1.49023Z"
                    stroke="#86889A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.25 6.39063C1.25 6.80484 1.58579 7.14063 2 7.14063C2.41421 7.14063 2.75 6.80484 2.75 6.39063C2.75 5.97641 2.41421 5.64062 2 5.64062C1.58579 5.64062 1.25 5.97641 1.25 6.39063Z"
                    stroke="#86889A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.25 11.291C1.25 11.7052 1.58579 12.041 2 12.041C2.41421 12.041 2.75 11.7052 2.75 11.291C2.75 10.8768 2.41421 10.541 2 10.541C1.58579 10.541 1.25 10.8768 1.25 11.291Z"
                    stroke="#86889A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    to={RoutesLink?.AddEvent}
                    state={item}
                    className="dropdown-item"
                  >
                    Edit event
                  </Link>
                </li>
                <li>
                  {item?.eventStatus != 3 && (
                    <Link
                      className="dropdown-item"
                      onClick={() => deleteEvent(item?.id)}
                    >
                      Delete Event
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyEventCard;
