import React, { useEffect } from "react";

const PreventEnterKey = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 13) {
        // Enter key
        event.preventDefault();
        return false;
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return null; // This component does not render anything
};

export default PreventEnterKey;
