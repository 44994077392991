import React, { useRef, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { setIsLocationDetails } from "../Helper/Helper";

const libraries = ["places"];

const GooglePlaceAutocomplete = ({
  setSelectedPlace,
  selectedPlace,
  defaultValues,
  placeholder = "location",
}) => {
  const autocompleteRef = useRef(null);

  const onLoad = (autocomplete) => {
    // console.log("Autocomplete loaded:", autocomplete);
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChangedhandler = () => {
    console.log("Place changed!");
    if (autocompleteRef.current !== null) {
      const place = autocompleteRef.current.getPlace();
      setSelectedPlace(place);
      console.log("Selected Place:", place);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handlePlaceSelected = (data) => {
    setSelectedPlace(setIsLocationDetails(data));
  };

  return (
    <ReactGoogleAutocomplete
      className="form-control"
      placeholder={placeholder}
      defaultValue={defaultValues}
      apiKey={"AIzaSyCrHaPgAZDdrSPc0gfNEDRemGZrfcTt490"}
      // apiKey={"AIzaSyD7KtQoq29-5TqELLdPBSQoqCD376-qGjA"}
      //  type={['geocode', 'cities', 'establishment','address']} cha
      onPlaceSelected={(data) => handlePlaceSelected(data)}
      onChange={(data) => {
        console.log("hello auto", data.target.value);
        // setAutocompleteValue(data.target.value);
      }}
      options={{
        types: ["address"], // Only show address results
        componentRestrictions: { country: "us" }, // Restrict to US addresses
      }}
    />
    // <LoadScript
    //   googleMapsApiKey="AIzaSyD7KtQoq29-5TqELLdPBSQoqCD376-qGjA"
    //   libraries={libraries}
    //   onLoad={() => console.log('Google Maps API script loaded')}
    //   onError={(error) => console.log('Error loading Google Maps API script:', error)}
    // >
    //   <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChangedhandler}>
    //     <input
    //       type="text"
    //       placeholder="Search for places"
    //       style={{ width: '300px', height: '40px', padding: '10px' }}
    //     />
    //   </Autocomplete>
    //   {selectedPlace && (
    //     <div>
    //       <h2>Selected Place Details</h2>
    //       <p>Name: {selectedPlace.name}</p>
    //       <p>Address: {selectedPlace.formatted_address}</p>
    //       {selectedPlace.geometry && (
    //         <>
    //           <p>Latitude: {selectedPlace.geometry.location.lat()}</p>
    //           <p>Longitude: {selectedPlace.geometry.location.lng()}</p>
    //         </>
    //       )}
    //     </div>
    //   )}
    // </LoadScript>
  );
};

export default GooglePlaceAutocomplete;
