import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  OrderHelperStatus,
  OrderStatus,
  getLoginUserCaption,
} from "../../Helper/Helper";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { toast } from "material-react-toastify";
import { RoutesLink } from "../../utility/RoutesLink";

export default function MyOrder() {
  const [OrderDetials, setOrderDetials] = useState({}); // State to store order details
  const [UserDetials, setUserDetials] = useState({}); // State to store user details
  const location = useLocation(); // Get location state passed from previous screen
  const [selectedReason, setSelectedReason] = useState(""); // State to store selected cancellation reason
  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  const [isLoading, setIsLoading] = useState(false);
  const [reasons, setReasons] = useState([]);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked); // Update checkbox state
  };

  const navigate = useNavigate();

  // UseEffect to set order and user details from location state on component mount
  useEffect(() => {
    setOrderDetials(location?.state?.item);
    setUserDetials(location?.state?.userdata);
    // getReasons();
  }, [""]); // Empty dependency array to run only once
  useEffect(() => {
    getReasons();
  }, [OrderDetials]); 


  console.log(location?.state); // Debugging to check location state values

  const [formData, setFormData] = useState({
    files: [], // Array to store uploaded files (images)
    additionalNote: "", // Additional note for the cancellation
  });

  const getReasons = () => {
      handleFetchRequest(
        `${ApiUrl.return_reasons}return`,
        "GET",
      ).then((response) => {
        if (response.status === true) {
          setReasons(response?.data);
        }
      });
  }

  // Handle radio button change for selecting a reason
  const handleRadioChange = (e) => {
    setSelectedReason(e.target.value);
  };

  // Handle file upload with a limit of 3 images
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    if (formData.files.length + files.length > 3) {
      toast.success("You can only upload up to 3 images.");
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      files: [...prevData.files, ...files].slice(0, 3), // Keep only up to 3 images
    }));
  };

  // Handle text area input for additional note
  const handleNoteChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      additionalNote: e.target.value,
    }));
  };

  // Handle image removal from the file list
  const handleRemoveImage = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      files: prevData.files.filter((_, i) => i !== index), // Remove the image at the given index
    }));
  };

  // Submit form data for order cancellation
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation checks before submission
    if (!selectedReason) {
      toast.error("A reason must be selected."); // Show error if no reason is selected
      return;
    }

    if (formData.files.length === 0) {
      toast.error("At least one image is required."); // Show error if no images are uploaded
      return;
    }

    if (!formData.additionalNote.trim()) {
      toast.error("Additional note is required."); // Show error if additional note is empty
      return;
    }

    const orderData = new FormData(); // Create a new FormData instance

    // Append the necessary form data to orderData
    orderData.append("orderId", OrderDetials?.OrderId); // Order ID
    orderData.append("itemId", OrderDetials?.id); // Item ID
    orderData.append("status", OrderHelperStatus.refund_requested); // Set status to 'refund_requested'

    // Append images to the form data
    formData.files.forEach((image, index) => {
      orderData.append(`images[${index}]`, image);
    });

    // Append the selected reason for cancellation
    orderData.append("reason", selectedReason);

    // Append the additional note
    orderData.append("note", formData.additionalNote);

    // Log the formData for debugging (can be removed in production)
    orderData.forEach((value, key) => {
      console.log(key, value);
    });



    try {
      // Make API call to cancel the order using handleFetchRequest
      const res = await handleFetchRequest(
        ApiUrl?.cancel_order,
        "POST",
        null,
        null,
        orderData
      );

      if (res.status === true) {
        toast.success(res?.message); // Show success toast notification
        navigate(-1); // Navigate back to the previous page
      } else {
        toast.error(res?.message); // Show error toast notification
      }
    } catch (error) {
      console.error("Error occurred:", error); // Log error for debugging
      toast.error("Something went wrong, please try again."); // Show error toast
    }
  };

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <section className="px-0 main-content">
        <div className="max-width-container">
          {/* =========================== */}
          {/* Main feed grid Start */}
          {/* =========================== */}
          <div className="main-feed-grid">
            {/* =========================== */}
            {/*left side content Start */}
            {/* =========================== */}
            <DashboardSidebar />
            {/* =========================== */}
            {/*left side content end */}
            {/* =========================== */}
            {/* =========================== */}
            {/* center content Start */}
            {/* =========================== */}
            <div className="center-content d-block">
              <form onSubmit={handleSubmit}>
                <div className="card tab-card activity-card manage-card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="filter-row position-relative">
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <p>
                              My Orders / {OrderDetials?.itemDetail?.itemName} (
                              {OrderDetials?.itemDetail?.brand}) / Order Return
                            </p>
                          </div>
                        </div>
                        <div className="card resource-card my-order-detail-card">
                          <div className="top-head">
                            <div className="user-det-head">
                              <div className="user-det d-flex gap-3">
                                <Link
                                  to={RoutesLink?.profile}
                                  state={{ id: UserDetials?.id }}
                                >
                                  <div className="user-dp">
                                    <img
                                      src={UserDetials?.profilePhoto}
                                      alt=""
                                    />
                                  </div>
                                </Link>

                                {OrderDetials?.user?.type == 2 ? (
                                  <div className="user-ac">
                                    <div className="d-flex gap-2 align-items-center">
                                      <span className="username">
                                        <h5>
                                          {UserDetials?.firstName}{" "}
                                          {UserDetials?.lastName}
                                        </h5>
                                      </span>
                                      <span className="usertype vendor mx-1">
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={13}
                                            height={13}
                                            viewBox="0 0 13 13"
                                            fill="none"
                                          >
                                            <circle
                                              cx="5.74979"
                                              cy="6.72714"
                                              r="3.75247"
                                              fill="white"
                                            />
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        Vendor
                                      </span>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center" />
                                    <p className="m-0">
                                      {getLoginUserCaption(UserDetials)}
                                    </p>
                                  </div>
                                ) : (
                                  <div className="user-ac">
                                    <div className="d-flex gap-2 align-items-center">
                                      <span className="username">
                                        <a href="#">
                                          {OrderDetials?.user?.type == 2}
                                        </a>
                                      </span>
                                      <span className="usertype vendor mx-1">
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={13}
                                            height={13}
                                            viewBox="0 0 13 13"
                                            fill="none"
                                          >
                                            <circle
                                              cx="5.74979"
                                              cy="6.72714"
                                              r="3.75247"
                                              fill="white"
                                            />
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        Vendor
                                      </span>
                                    </div>
                                    <p>
                                      {getLoginUserCaption(OrderDetials?.user)}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <h2>Order ID - {OrderDetials?.Order?.orderId}</h2>
                          </div>
                          <div className="card-body">
                            <div className="ordered-item d-flex border-bottom">
                              <div className="d-flex align-items-center gap-3">
                                <img
                                  src={OrderDetials?.itemDetail?.itemImage}
                                  alt=""
                                ></img>
                                <div className="d-flex flex-column gap-1 gap-md-2 gap-lg-3">
                                  <h3>{OrderDetials?.itemDetail?.itemName}</h3>
                                  <span>{OrderDetials?.itemDetail?.brand}</span>
                                </div>
                              </div>
                              <div className="d-flex flex-column align-items-end gap-3 refund">
                                <h3>$ {OrderDetials?.price}</h3>
                                <span>Refund credit amount</span>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-12 col-md-10 col-lg-8 p-0">
                                <div className="return-reason-wrapper">
                                  <h5>Want to return this order?</h5>
                                  <p className="desc">
                                    Don’t worry we are here to help you! choose
                                    the correct reason for th return this
                                    informatin is only used to improve our
                                    services
                                  </p>

                                  <div className="return-reasons">
                                    <p>Select the reason</p>
                                    <div>
                                      {reasons?.map((reason) => (<>
                                      <label
                                        className="form-check-label"
                                        htmlFor={reason?.id}
                                      >
                                        <span>
                                          {reason?.reason} <br />
                                          <span className="cap">
                                            {reason?.description}
                                          </span>
                                        </span>
                                        <input
                                          className="form-check-input type-one"
                                          type="radio"
                                          name="returnReason"
                                          id={reason?.id}
                                          value={reason?.reason}
                                          checked={
                                            selectedReason ===
                                            reason?.reason
                                          }
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                      </>))}

                                      {/* <label
                                        className="form-check-label "
                                        htmlFor="changeMind"
                                      >
                                        <span>
                                          Change my mind <br />
                                          <span className="cap">
                                            I don’t want this order
                                          </span>
                                        </span>
                                        <input
                                          className="form-check-input type-one"
                                          type="radio"
                                          name="returnReason"
                                          id="changeMind"
                                          value="changeMind"
                                          checked={
                                            selectedReason === "changeMind"
                                          }
                                          onChange={handleRadioChange}
                                        />
                                      </label>

                                      <label
                                        className="form-check-label "
                                        htmlFor="qualityIssues"
                                      >
                                        <span>
                                          Quality issues <br />
                                          <span className="cap">
                                            Poor quality product
                                          </span>
                                        </span>
                                        <input
                                          className="form-check-input type-one"
                                          type="radio"
                                          name="returnReason"
                                          id="qualityIssues"
                                          value="qualityIssues"
                                          checked={
                                            selectedReason === "qualityIssues"
                                          }
                                          onChange={handleRadioChange}
                                        />
                                      </label>

                                      <label
                                        className="form-check-label"
                                        htmlFor="damagedIssue"
                                      >
                                        <span>
                                          Damaged issue <br />
                                          <span className="cap">
                                            Not in good condition
                                          </span>
                                        </span>
                                        <input
                                          className="form-check-input type-one"
                                          type="radio"
                                          name="returnReason"
                                          id="damagedIssue"
                                          value="damagedIssue"
                                          checked={
                                            selectedReason === "damagedIssue"
                                          }
                                          onChange={handleRadioChange}
                                        />
                                      </label> */}
                                    </div>
                                  </div>

                                  <div className="product-imgs">
                                    <h6 className="mb-3 mb-lg-4">
                                      Upload product image (Max 3 images)
                                    </h6>
                                    <label
                                      htmlFor="file"
                                      className="form-label w-100"
                                    >
                                      <div className="fileUploadBox d-flex justify-content-center flex-column align-items-center">
                                        <span>Upload here</span>
                                      </div>
                                    </label>
                                    <input
                                      type="file"
                                      className="form-control d-none"
                                      id="file"
                                      multiple
                                      accept="image/*"
                                      onChange={handleFileUpload}
                                    />
                                    {formData.files.length > 0 && (
                                      <div className="uplaoded-images-wrapper">
                                        {formData.files.map((file, index) => (
                                          <div
                                            className="uploaded-img"
                                            key={index}
                                          >
                                            <img
                                              src={URL.createObjectURL(file)}
                                              alt={`upload-${index}`}
                                            />
                                            <button
                                              onClick={() =>
                                                handleRemoveImage(index)
                                              }
                                              className="btn btn-link"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="12"
                                                viewBox="0 0 19 19"
                                                fill="none"
                                              >
                                                <path
                                                  d="M13.7188 5.12305L4.71875 14.123M4.71875 5.12305L13.7188 14.123"
                                                  stroke="black"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </svg>
                                            </button>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </div>

                                  <div className="note">
                                    <h6>Additional note</h6>
                                    <textarea
                                      className="form-control"
                                      id="feedback"
                                      rows="3"
                                      placeholder="Write your comments here....."
                                      value={formData.additionalNote}
                                      onChange={handleNoteChange}
                                    />
                                  </div>

                                  <div className="pck-add">
                                    <h6 className="mb-2">Exchange address</h6>
                                    <p className="btm">
                                      Pickup will happen at the same address
                                    </p>
                                    <div className="address-card bg-white ">
                                      <div className="row">
                                        <div className="col-12 p-0 d-flex justify-content-between align-items-center">
                                          <div className="d-flex w-100 justify-content-between align-items-center">
                                            <span className="type-badge">
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="9"
                                                  height="9"
                                                  viewBox="0 0 9 9"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M16 16H0V15.2727H16V16ZM4.36364 2.18182V14.9091H0.727273V3.27273C0.727273 2.9834 0.842208 2.70592 1.04679 2.50134C1.25138 2.29675 1.52885 2.18182 1.81818 2.18182H4.36364ZM3.63636 13.0909H1.81818V13.8182H3.63636V13.0909ZM3.63636 10.9091H1.81818V11.6364H3.63636V10.9091ZM3.63636 8.72727H1.81818V9.45455H3.63636V8.72727ZM3.63636 6.54545H1.81818V7.27273H3.63636V6.54545ZM3.63636 4.36364H1.81818V5.09091H3.63636V4.36364ZM15.2727 3.27273V14.9091H11.6364V2.18182H14.1818C14.4711 2.18182 14.7486 2.29675 14.9532 2.50134C15.1578 2.70592 15.2727 2.9834 15.2727 3.27273ZM14.1818 13.0909H12.3636V13.8182H14.1818V13.0909ZM14.1818 10.9091H12.3636V11.6364H14.1818V10.9091ZM14.1818 8.72727H12.3636V9.45455H14.1818V8.72727ZM14.1818 6.54545H12.3636V7.27273H14.1818V6.54545ZM14.1818 4.36364H12.3636V5.09091H14.1818V4.36364ZM11.2727 1.09091V14.9091H9.81818V12C9.81818 11.9036 9.77987 11.8111 9.71168 11.7429C9.64348 11.6747 9.55099 11.6364 9.45455 11.6364H6.54545C6.44901 11.6364 6.35652 11.6747 6.28832 11.7429C6.22013 11.8111 6.18182 11.9036 6.18182 12V14.9091H4.72727V1.09091C4.72727 0.801582 4.84221 0.524105 5.04679 0.31952C5.25138 0.114935 5.52885 0 5.81818 0H10.1818C10.4711 0 10.7486 0.114935 10.9532 0.31952C11.1578 0.524105 11.2727 0.801582 11.2727 1.09091ZM7.63636 9.81818H6.18182V10.5455H7.63636V9.81818ZM7.63636 7.63636H6.18182V8.36364H7.63636V7.63636ZM7.63636 5.45455H6.18182V6.18182H7.63636V5.45455ZM7.63636 3.27273H6.18182V4H7.63636V3.27273ZM7.63636 1.09091H6.18182V1.81818H7.63636V1.09091ZM9.81818 9.81818H8.36364V10.5455H9.81818V9.81818ZM9.81818 7.63636H8.36364V8.36364H9.81818V7.63636ZM9.81818 5.45455H8.36364V6.18182H9.81818V5.45455ZM9.81818 3.27273H8.36364V4H9.81818V3.27273ZM9.81818 1.09091H8.36364V1.81818H9.81818V1.09091ZM6.90909 12.3636V14.9091H9.09091V12.3636H6.90909Z"
                                                    fill="black"
                                                  ></path>
                                                </svg>
                                              </span>
                                              {
                                                OrderDetials?.user
                                                  ?.shippingAddress[0]?.type
                                              }
                                            </span>
                                            {/* <button className="btn btn-light">
                                              Change address
                                            </button> */}
                                          </div>
                                        </div>
                                        <div className="col-12 p-0">
                                          <ul>
                                            <li>
                                              <p className="para">
                                                Name:{" "}
                                                {
                                                  OrderDetials?.user
                                                    ?.shippingAddress[0]?.name
                                                }
                                              </p>
                                              <p className="para">
                                                City:{" "}
                                                {
                                                  OrderDetials?.user
                                                    ?.shippingAddress[0]
                                                    ?.location?.city
                                                }
                                              </p>
                                              <p className="para">
                                                Address:{" "}
                                                {
                                                  OrderDetials?.user
                                                    ?.shippingAddress[0]
                                                    ?.location?.address
                                                }
                                              </p>
                                              <p className="para">
                                                Zip Code:{" "}
                                                {
                                                  OrderDetials?.user
                                                    ?.shippingAddress[0]
                                                    ?.location?.zipcode
                                                }{" "}
                                              </p>
                                            </li>
                                            <li>
                                              <p className="para">
                                                Mobile:{" "}
                                                {
                                                  OrderDetials?.user
                                                    ?.shippingAddress[0]
                                                    ?.countryCode
                                                }{" "}
                                                {
                                                  OrderDetials?.user
                                                    ?.shippingAddress[0]?.phone
                                                }
                                              </p>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="refd-src">
                                    <h6>Refund back to source</h6>
                                    <div className="refd-src-card d-flex align-items-center justify-content-between">
                                      <div className="d-flex align-items-start gap-3">
                                        <span>
                                          <svg
                                            width="25"
                                            height="25"
                                            viewBox="0 0 25 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              x="0.1875"
                                              y="0.822266"
                                              width="24.0234"
                                              height="23.8352"
                                              rx="11.9176"
                                              fill="#F2F2F4"
                                            ></rect>
                                            <path
                                              d="M15.4815 9.82227H8.9169C8.51407 9.82227 8.1875 10.1488 8.1875 10.5517V14.9281C8.1875 15.3309 8.51407 15.6575 8.9169 15.6575H15.4815C15.8844 15.6575 16.2109 15.3309 16.2109 14.9281V10.5517C16.2109 10.1488 15.8844 9.82227 15.4815 9.82227Z"
                                              stroke="#343B4E"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              d="M8.1875 12.0098H16.2109"
                                              stroke="#343B4E"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                          </svg>
                                        </span>
                                        <div>
                                          <h5>
                                            {
                                              OrderDetials?.Order?.cardDetail
                                                ?.type
                                            }
                                          </h5>
                                          <p>
                                            {OrderDetials?.Order?.cardDetail
                                              ?.cardNumber
                                              ? `${"***** ".repeat(
                                                  3
                                                )} ${OrderDetials?.Order?.cardDetail.cardNumber.slice(
                                                  -4
                                                )}`
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                      <h3>$ {OrderDetials?.price}</h3>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="acpt"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                      />
                                      <label
                                        className="form-check-label"
                                        for="acpt"
                                      >
                                        I accept the refund credited as
                                        mentioned above
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row justify-content-end row-gap-3">
                                    <div className="col-12 col-md-4">
                                      <button
                                        onClick={() => navigate(-1)}
                                        type="button"
                                        className="btn btn-light btn-back w-100"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                    <div className="col-12 col-md-4">
                                      <button
                                        disabled={!isChecked || isLoading} // Button is disabled if isChecked is false or isLoading is true
                                        type="submit"
                                        className="btn btn-primary w-100"
                                      >
                                        {isLoading
                                          ? "Loading..."
                                          : "Confirm return"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* =========================== */}
            {/*center content end */}
            {/* =========================== */}
          </div>
          {/* =========================== */}
          {/* Main feed grid End */}
          {/* =========================== */}
        </div>
      </section>
    </>
  );
}
