import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GooglePlaceAutocomplete from "../../../Components/GooglePlaceAutocomplete";
import { Spinner } from "reactstrap";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../../utility/ApiUrl";
import { toast } from "material-react-toastify";
import { confirm } from "../../../Components/Modals/ConfirmModal";
import { SvgIcons } from "../../../Components/Svg/SvgIcons";
import { getLoginUserCaption } from "../../../Helper/Helper";

function ManageEvent() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [startselectedHour, setStartSelectedHour] = useState(null);
  const [endselectedHour, setendSelectedHour] = useState(null);
  const [hireTalenetForm, sethireTalenetForm] = useState(false);
  const [EventType, setEventType] = useState([]);
  const [Entertainment, setEntertainment] = useState([]);
  const [Music, setmusic] = useState([]);
  const [ImageErrorState, setImageErrorState] = useState("");
  const [MyEvents, setMyEvents] = useState([]);
  const [Availibilty, SetAvailibilty] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedResion, setSelectedResion] = useState("");
  const [MoreInformation, setMoreInformation] = useState("");
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [calendarData, setCalendarData] = useState([]);
  const [dateStartTime, setDateStartTime] = useState(null);
  const [dateEndTime, setDateEndTime] = useState(null);
  const [calendarStartTime, setCalendarStartTime] = useState("");
  const [calendarEndTime, setCalendarEndTime] = useState("");
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [imageUrl, setImageUrl] = useState({
    image_url: "",
    image_view: "",
  });
  const [loading, setLoading] = useState(false);
  const [talentModal, setTalentModal] = useState(false);
  const [privatePerformers, setPrivatePerformers] = useState([]);
  const [selectedInstruments, setSelectedInstruments] = useState([]); // State for selected instruments

  const toggleTalentModal = () => {
    setTalentModal(!talentModal);
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const navigator = useNavigate();

  //   console.log("value", moment(value).format("YYYY-MM-DD"));

  const hours = [];
  for (let i = 10; i <= 23; i++) {
    const hour = i % 24; // Adjust for 24-hour format
    const value = `${hour < 10 ? "0" + hour : hour}:00`;
    const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
    const period = hour >= 12 ? "PM" : "AM";
    const displayTime = `${displayHour}:00 ${period}`;

    hours.push({
      value: value,
      displayTime: displayTime,
    });
  }

  function prepareTimeslotForAPI(timeslot) {
    // Remove AM/PM and any extra spaces from the string
    return timeslot?.replace(/AM|PM|\s/g, "");
  }

  useEffect(() => {
    handleFetchRequest(ApiUrl.event_type).then((res) => {
      if (res.status === true) {
        setEventType(res?.data);
      }
    });
    handleFetchRequest(ApiUrl.entertainment_types).then((res) => {
      if (res.status === true) {
        setEntertainment(res?.data);
      }
    });
    handleFetchRequest(ApiUrl.music_type).then((res) => {
      if (res.status === true) {
        setmusic(res?.data);
      }
    });
  }, []);

  const fetechMyEvents = () => {
    handleFetchRequest(
      `${ApiUrl?.check_calender}?month=${currentMonth}&talentId=${location?.state?.Profile?.userId}`
    ).then((response) => {
      if (response.status === true) {
        SetAvailibilty(response?.data);
        setCalendarData(response.data);
        setMyEvents(response?.data);
      }
    });
  };
  useEffect(() => {
    fetechMyEvents();
  }, [currentMonth]);

  const SelectedTime = (hour) => {
    if (!startselectedHour) return false;
    if (startselectedHour === hour || endselectedHour === hour) return true;
    if (
      endselectedHour &&
      hours.indexOf(hour) > hours.indexOf(startselectedHour) &&
      hours.indexOf(hour) < hours.indexOf(endselectedHour)
    ) {
      return true;
    }
    return false;
  };

  const handleHourClickData = (hour) => {
    console.log(hour);
    if (!startselectedHour || !endselectedHour) {
      // Reset selection if there's no start hour or both hours have been selected
      setStartSelectedHour(hour);
      setendSelectedHour(hour); // Clear end hour if new selection starts
    } else {
      // If start hour is selected, update the end hour
      if (hour < startselectedHour) {
        // Ensure the start hour is always the earlier one
        setStartSelectedHour(hour);
        setendSelectedHour(startselectedHour);
      } else {
        setendSelectedHour(hour);
      }
    }
  };
  console.log("if(startTime)", startselectedHour);
  const hireTalenetFormToggle = () => {
    if (selectedValue == 1) {
    }
    if (selectedValue == 2) {
      const getSelectedReson = (reson) => {
        if (reson == 1) {
          return "Family Function";
        }
        if (reson == 2) {
          return "Traveling";
        }
        if (reson == 3) {
          return "Other";
        }
      };
      if (startselectedHour == null) {
        toast.error("please select timeslot");
        return;
      } else if (MoreInformation == "") {
        toast.error("please add some information");
        return;
      } else {
        const data = [
          {
            timeZone: "",
            date: moment(selectedDate).format("YYYY-MM-DD"),
            startTime: prepareTimeslotForAPI(startselectedHour),
            endTime: prepareTimeslotForAPI(endselectedHour),
            reason: getSelectedReson(selectedResion),
            additionalNote: MoreInformation,
          },
        ];

        handleFetchRequest(ApiUrl?.create_calender, "POST", data).then(
          (res) => {
            if (res.status === true) {
              toast.success(res?.message);
              setSelectedDate("");
              setStartSelectedHour("");
              setendSelectedHour("");
              setSelectedValue("");
              fetechMyEvents();
              // navigator(RoutesLink?.dashboard);
            } else {
              toast.error(res?.message);
            }
          }
        );
      }
    }
    if (selectedValue == 3) {
      const getSelectedReson = (reson) => {
        if (reson == 1) {
          return "Family Function";
        }
        if (reson == 2) {
          return "Traveling";
        }
        if (reson == 3) {
          return "Other";
        }
      };
      if (MoreInformation == "") {
        toast.error("please add some information");
        return;
      } else {
        const data = [
          {
            timeZone: "",
            date: moment(selectedDate).format("YYYY-MM-DD"),
            startTime: startselectedHour,
            endTime: endselectedHour,
            // startTime: prepareTimeslotForAPI(hours[0]),
            // endTime: prepareTimeslotForAPI(hours[hours.length - 1]),
            reason: getSelectedReson(selectedResion),
            additionalNote: MoreInformation,
          },
        ];
        handleFetchRequest(ApiUrl?.create_calender, "POST", data).then(
          (res) => {
            if (res.status === true) {
              toast.success(res?.message);
              // navigator(RoutesLink?.dashboard);
            } else {
              toast.error(res?.message);
            }
          }
        );
      }
    }
  };

  const imageUploadHandler = (e) => {
    let file = e?.target?.files?.[0];
    let url = URL.createObjectURL(file);

    console.log("fileee", e);
    if (file) {
      setImageUrl({
        image_url: file,
        image_view: url,
      });
    }
  };

  const imageRemoveHandler = () => {
    setImageUrl({
      image_view: "",
      image_url: "",
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSelect = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value == 1) {
      sethireTalenetForm(true);
    }
    if (event.target.value == 2) {
      sethireTalenetForm(false);
    }
    if (event.target.value == 3) {
      setStartSelectedHour(":00");
      setendSelectedHour("23:00");
      sethireTalenetForm(false);
    }
  };
  const handleSelectReason = (event) => {
    setSelectedResion(event.target.value);
  };
  const handleMoreInformation = (event) => {
    setMoreInformation(event.target.value);
  };

  const handleRemovePerformer = (indexToRemove) => {
    setPrivatePerformers((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
  };
  const onSubmit = (data) => {
    if (startselectedHour == null) {
      toast.error("please select timeslot");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    if (isEdit && editData) {
      formData.append("eventName", data.fname);
      formData.append("eventDescription", data?.info);
      formData.append(
        "eventTimeLocation[start_date]",
        moment(selectedDate).format("DD-MM-YYYY")
      );
      formData.append(
        "eventTimeLocation[end_date]",
        moment(selectedDate).format("DD-MM-YYYY")
      );
      formData.append(
        "eventTimeLocation[start_time]",
        moment(startselectedHour, "HH:mm").format("hh:mm A")
      );
      formData.append(
        "eventTimeLocation[end_time]",
        moment(endselectedHour, "HH:mm").format("hh:mm A")
      );
      if (selectedPlace) {
        formData.append("eventTimeLocation[location]", selectedPlace?.address);
        formData.append("eventTimeLocation[latitude]", selectedPlace?.lat);
        formData.append("eventTimeLocation[longitude]", selectedPlace?.lng);
        formData.append("eventTimeLocation[state]", selectedPlace?.state);
      }
      formData.append("musicType", selectedInstruments);
      formData.append("eventType", data?.type);
      formData.append("eventStatus", 1);
      if (imageUrl?.image_url) {
        formData.append("eventImage", imageUrl?.image_url);
      }

      formData.append("calender[date]", selectedDate);
      formData.append(
        "calender[startTime]",
        moment(startselectedHour, ["h:mm A"]).format("HH:mm")
      );
      formData.append(
        "calender[endTime]",
        moment(endselectedHour, ["h:mm A"]).format("HH:mm")
      );

      for (let pair of formData.entries()) {
        console.log("edit formdata", pair[0] + ": " + pair[1]);
      }

      handleFetchRequest(
        `${ApiUrl?.edit_talent_event}${editData?.id}`,
        "PUT",
        null,
        null,
        formData
      ).then((response) => {
        if (response?.status === true) {
          toast.success("Event Updated");
          fetechMyEvents();
          setIsLoading(false);
          reset();
          setStartSelectedHour("");
          setendSelectedHour("");
          setSelectedValue("");
          setSelectedDate("");
          sethireTalenetForm(false);
          setImageUrl({ image_url: "", image_view: "" });
          setLoading(false);
        } else {
          toast.error("Event Can't Be Created!");
        }
      });
    } else {
      formData.append("eventName", data.fname);
      formData.append("eventDescription", data?.info);
      formData.append(
        "eventTimeLocation[start_date]",
        moment(selectedDate).format("DD-MM-YYYY")
      );
      formData.append(
        "eventTimeLocation[end_date]",
        moment(selectedDate).format("DD-MM-YYYY")
      );
      formData.append(
        "eventTimeLocation[start_time]",
        moment(startselectedHour, "HH:mm").format("hh:mm A")
      );
      formData.append(
        "eventTimeLocation[end_time]",
        moment(endselectedHour, "HH:mm").format("hh:mm A")
      );
      formData.append("eventTimeLocation[location]", selectedPlace?.address);
      formData.append("eventTimeLocation[latitude]", selectedPlace?.lat);
      formData.append("eventTimeLocation[longitude]", selectedPlace?.lng);
      formData.append("eventTimeLocation[state]", selectedPlace?.state);
      formData.append("musicType", selectedInstruments);
      formData.append("eventType", data?.type);
      // formData.append("entertainmentType", data?.entertemenet);
      formData.append("eventStatus", 1);
      formData.append("eventImage", imageUrl?.image_url);
      formData.append("calender[date]", selectedDate);
      formData.append(
        "calender[startTime]",
        moment(startselectedHour, ["h:mm A"]).format("HH:mm")
      );
      formData.append(
        "calender[endTime]",
        moment(endselectedHour, ["h:mm A"]).format("HH:mm")
      );

      for (let pair of formData.entries()) {
        console.log("formdata", pair[0] + ": " + pair[1]);
      }

      handleFetchRequest(
        `${ApiUrl?.create_talent_event}`,
        "POST",
        null,
        null,
        formData
      ).then((response) => {
        if (response?.status === true) {
          toast.success("Event Added");
          fetechMyEvents();
          setIsLoading(false);
          reset();
          setStartSelectedHour("");
          setendSelectedHour("");
          setSelectedValue("");
          setSelectedDate("");
          sethireTalenetForm(false);
          setImageUrl({ image_url: "", image_view: "" });
          setLoading(false);
        } else {
          toast.error("Event Can't Be Created!");
        }
      });
    }
  };

  const handleCancel = () => {
    reset();
    setStartSelectedHour("");
    setendSelectedHour("");
    setSelectedValue("");
    setSelectedDate("");
    sethireTalenetForm(false);
    setImageUrl({ image_url: "", image_view: "" });
    setLoading(false);
  };

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setCurrentMonth(moment(activeStartDate).format("MM"));
  };

  const handlePreviousMonth = () => {
    if (currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const getDayClass = (day) => {
    const date = `${currentYear}-${String(currentMonth).padStart(
      2,
      "0"
    )}-${String(day).padStart(2, "0")}`;
    const dayData = calendarData.find((item) => item.date === date);
    if (dayData) {
      if (dayData.reason) {
        return "na";
      }
      if (dayData.talentEvent) {
        return "event";
      }
      if (dayData.isBooked) {
        return "booked";
      }
    }
    return "";
  };

  const handleDateClick = (date) => {
    console.log(date);
    setSelectedDate(date);
    console.log(selectedDate);
    handleFetchRequest(
      `${ApiUrl?.check_calender}?date=${date}&talentId=${location?.state?.Profile?.userId}`
    ).then((response) => {
      console.log(response);
      setCalendarStartTime(response?.data?.[0]?.startTime);
      setCalendarEndTime(response?.data?.[0]?.endTime);

      if (response.status === true) {
        let earliestStartTime = null;
        let latestEndTime = null;
        response.data.forEach((item) => {
          const startTime = parseFloat(
            item.startTime.split(".")[0] + "." + item.startTime.split(".")[1]
          );
          const endTime = parseFloat(
            item.endTime.split(".")[0] + "." + item.endTime.split(".")[1]
          );
          if (!earliestStartTime || startTime < earliestStartTime) {
            earliestStartTime = startTime;
          }
          if (!latestEndTime || endTime > latestEndTime) {
            latestEndTime = endTime;
          }
        });
        setDateStartTime(earliestStartTime);
        setDateEndTime(latestEndTime);
      }
    });
  };

  // const handleDateClick = (date) => {
  //   setSelectedDate(date);
  //   const ApiUrl = `${ApiUrl?.check_calender}?date=${date}&talentId=${id}`;
  //   const options = {
  //     method: "GET",
  //   };
  //   callApi(ApiUrl, options).then((response) => {
  //     if (response?.status) {
  //       let earliestStartTime = null;
  //       let latestEndTime = null;
  //       response.data.forEach((item) => {
  //         const startTime = parseFloat(
  //           item.startTime.split(".")[0] + "." + item.startTime.split(".")[1]
  //         );
  //         const endTime = parseFloat(
  //           item.endTime.split(".")[0] + "." + item.endTime.split(".")[1]
  //         );
  //         if (!earliestStartTime || startTime < earliestStartTime) {
  //           earliestStartTime = startTime;
  //         }
  //         if (!latestEndTime || endTime > latestEndTime) {
  //           latestEndTime = endTime;
  //         }
  //       });
  //       setDateStartTime(earliestStartTime);
  //       setDateEndTime(latestEndTime);
  //     } else {
  //       alert("couldn't fetch calendar: " + response.message);
  //     }
  //   });

  //   const selectedDayData = calendarData.filter((item) => item?.date === date);
  //   const newTimeSlots = generateTimeSlots();
  //   selectedDayData.forEach((item) => {
  //     const startTime = parseInt(item.startTime.split(":")[0], 10);
  //     const endTime = parseInt(item.endTime.split(":")[0], 10);
  //     for (let i = startTime; i <= endTime; i++) {
  //       newTimeSlots[i] = "na disabled";
  //     }
  //   });
  //   setTimeSlots(newTimeSlots);
  // };
  const handleNextMonth = () => {
    if (currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const renderCalendarDays = () => {
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
    const firstDay = new Date(currentYear, currentMonth - 1, 1).getDay();
    const currentDate = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format
    const days = [];
    for (let i = 0; i < firstDay; i++) {
      days.push(<li key={`empty-${i}`} className="not-inc"></li>);
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const date = `${currentYear}-${String(currentMonth).padStart(
        2,
        "0"
      )}-${String(day).padStart(2, "0")}`;
      const isCurrentDate = date === currentDate; // Check if the date is the current date
      const isPastDate = date < currentDate;

      days.push(
        <li
          key={day}
          className={`day ${getDayClass(day)} ${
            selectedDate === date ? "border border-danger" : ""
          } ${isCurrentDate ? "text-danger" : ""} ${
            isPastDate ? "disabled" : ""
          }`}
          style={{
            cursor: isPastDate ? "not-allowed" : "pointer",
            opacity: isPastDate ? 0.5 : 1,
            pointerEvents: isPastDate ? "none" : "auto",
          }}
          onClick={() =>
            handleDateClick(
              `${currentYear}-${String(currentMonth).padStart(2, "0")}-${String(
                day
              ).padStart(2, "0")}`
            )
          }
        >
          {day}
        </li>
      );
    }
    return days;
  };

  const deleteEvent = async (eventId) => {
    if (await confirm("Are you sure you want to delete this event")) {
      handleFetchRequest(
        `${ApiUrl.delete_talent_event}${eventId}`,
        "DELETE"
      ).then((res) => {
        if (res.status === true) {
          toast?.success("Event removed");
          fetechMyEvents();
        }
      });
    }
  };

  const editEvent = (evt) => {
    console.log(evt);
    if (evt) {
      setEditData(evt);
      setIsEdit(true);
      setSelectedValue(1);
      sethireTalenetForm(true);
    }
  };

  useEffect(() => {
    if (isEdit === true) {
      setSelectedDate(
        moment(editData?.eventTimeLocation?.start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )
      );
      console.log(editData);
      setStartSelectedHour(editData?.eventTimeLocation?.start_time);
      setendSelectedHour(editData?.eventTimeLocation?.end_time);
      setValue("fname", editData?.eventName);
      setValue("music", editData?.musicType);
      setValue("info", editData?.eventDescription);
      setValue("type", editData?.eventType);
      setImageUrl({
        image_url: null,
        image_view: editData?.eventImage,
      });
    }
  }, [isEdit]);

  const deleteAvailability = async (id) => {
    if (await confirm("Are you sure you want to delete this availability")) {
      handleFetchRequest(`${ApiUrl.delete_calendar}${id}`, "GET").then(
        (res) => {
          if (res.status === true) {
            toast?.success(res?.message);
            fetechMyEvents();
          }
        }
      );
    }
  };

  const handleInstrumentChange = (e) => {
    const value = e.target.value;
    setSelectedInstruments((prevSelected) => {
      if (prevSelected.includes(value)) {
        // Remove the instrument if it is already selected
        return prevSelected.filter((instrument) => instrument !== value);
      } else {
        // Add the new instrument to the selected instruments
        return [...prevSelected, value];
      }
    });
  };

  console.log("selectedInstruments", selectedInstruments);

  return (
    <>
      <section className="px-0 main-content pt-2">
        <div className="max-width-container">
          {/* =========================== */}
          {/* Main feed grid Start */}
          {/* =========================== */}
          <div className="row justify-content-between 5">
            <div className="col-12 p-0">
              <div className="user-det-head-wrapper">
                <div className="user-det-head d-flex justify-content-between align-items-center">
                  <div className="user-det d-flex gap-3 gap-lg-4 align-items-center">
                    {location?.state?.Profile?.profilePhoto ? (
                      <div className="user-dp" onClick={() => navigate(-1)}>
                        <img
                          src={location?.state?.Profile?.profilePhoto}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div
                        className="user-dp no-img"
                        onClick={() => navigate(-1)}
                      >
                        <img
                          src={location?.state?.Profile?.firstName?.[0]}
                          alt=""
                        />
                      </div>
                    )}
                    <div className="user-ac">
                      <div className="d-flex gap-2 align-items-center">
                        <span className="username">
                          <Link to="#" onClick={() => navigate(-1)}>
                            {location?.state?.Profile?.firstName}{" "}
                            {location?.state?.Profile?.lastName}
                          </Link>
                        </span>
                        <span className="usertype mx-1">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                            >
                              <circle
                                cx="5.74979"
                                cy="6.72714"
                                r="3.75247"
                                fill="#7133F4"
                              ></circle>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                          Talent
                        </span>
                      </div>
                      <p>
                        {getLoginUserCaption(location?.state?.Talent?.catagory)}
                      </p>
                    </div>
                  </div>

                  <div className="btn-wrapper">
                    <button
                      className="btn btn-light rounded-circle"
                      onClick={() => navigate(-1)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M7.05762 6.00164L11.7712 1.28784C11.8428 1.21874 11.8998 1.13609 11.9391 1.0447C11.9783 0.953313 11.999 0.855022 11.9999 0.755563C12.0007 0.656104 11.9818 0.557469 11.9441 0.465413C11.9065 0.373357 11.8508 0.289723 11.7805 0.219393C11.7102 0.149062 11.6265 0.0934424 11.5345 0.0557793C11.4424 0.0181162 11.3438 -0.000835989 11.2443 2.82821e-05C11.1449 0.000892553 11.0466 0.0215562 10.9552 0.0608134C10.8638 0.100071 10.7812 0.157135 10.7121 0.228678L5.99827 4.94229L1.28466 0.228678C1.14367 0.0907862 0.953989 0.0140576 0.756783 0.0151437C0.559576 0.0162297 0.370754 0.0950426 0.231293 0.234478C0.0918327 0.373914 0.0129868 0.562723 0.0118659 0.759929C0.010745 0.957135 0.0874397 1.14683 0.225306 1.28784L4.9391 6.00164L0.225306 10.7154C0.154478 10.7847 0.0980952 10.8673 0.0594229 10.9586C0.0207506 11.0498 0.000556963 11.1477 1.13597e-05 11.2468C-0.000534243 11.3459 0.0185791 11.4441 0.0562444 11.5357C0.0939097 11.6274 0.149378 11.7106 0.21944 11.7807C0.289501 11.8507 0.372762 11.9062 0.464402 11.9438C0.556042 11.9815 0.654241 12.0006 0.753312 12C0.852383 11.9994 0.950358 11.9792 1.04156 11.9405C1.13277 11.9018 1.2154 11.8454 1.28466 11.7746L5.99827 7.06099L10.7121 11.7746C10.8583 11.9209 11.0501 11.9941 11.2416 11.9941C11.4332 11.9941 11.6252 11.9209 11.7712 11.7746C11.9117 11.6341 11.9905 11.4436 11.9905 11.245C11.9905 11.0464 11.9117 10.8559 11.7712 10.7154L7.05762 6.00164Z"
                          fill="#343B4E"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12 p-0">
              <div className="user-det-head-wrapper">
                <div className="user-det d-flex gap-3 gap-lg-4 align-items-center">
                  <div className="user-dp">
                    <img src={location?.state?.Profile?.profilePhoto} alt="" />
                  </div>
                  <div className="user-ac">
                    <div className="d-flex gap-2 align-items-center">
                      <span className="username">
                        <Link to="#">
                          {location?.state?.Profile?.firstName}{" "}
                          {location?.state?.Profile?.lastName}
                        </Link>
                      </span>
                      <span className="usertype mx-1">
                        <span>{SvgIcons?.talentIcon}</span>
                        Talent
                      </span>
                    </div>
                    <p> </p>
                  </div>
                </div>
                <div className="btn-wrapper">
                  <button
                    className="btn btn-light rounded-circle"
                    onClick={() => navigate(-1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={12}
                      height={12}
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M7.05762 6.00164L11.7712 1.28784C11.8428 1.21874 11.8998 1.13609 11.9391 1.0447C11.9783 0.953313 11.999 0.855022 11.9999 0.755563C12.0007 0.656104 11.9818 0.557469 11.9441 0.465413C11.9065 0.373357 11.8508 0.289723 11.7805 0.219393C11.7102 0.149062 11.6265 0.0934424 11.5345 0.0557793C11.4424 0.0181162 11.3438 -0.000835989 11.2443 2.82821e-05C11.1449 0.000892553 11.0466 0.0215562 10.9552 0.0608134C10.8638 0.100071 10.7812 0.157135 10.7121 0.228678L5.99827 4.94229L1.28466 0.228678C1.14367 0.0907862 0.953989 0.0140576 0.756783 0.0151437C0.559576 0.0162297 0.370754 0.0950426 0.231293 0.234478C0.0918327 0.373914 0.0129868 0.562723 0.0118659 0.759929C0.010745 0.957135 0.0874397 1.14683 0.225306 1.28784L4.9391 6.00164L0.225306 10.7154C0.154478 10.7847 0.0980952 10.8673 0.0594229 10.9586C0.0207506 11.0498 0.000556963 11.1477 1.13597e-05 11.2468C-0.000534243 11.3459 0.0185791 11.4441 0.0562444 11.5357C0.0939097 11.6274 0.149378 11.7106 0.21944 11.7807C0.289501 11.8507 0.372762 11.9062 0.464402 11.9438C0.556042 11.9815 0.654241 12.0006 0.753312 12C0.852383 11.9994 0.950358 11.9792 1.04156 11.9405C1.13277 11.9018 1.2154 11.8454 1.28466 11.7746L5.99827 7.06099L10.7121 11.7746C10.8583 11.9209 11.0501 11.9941 11.2416 11.9941C11.4332 11.9941 11.6252 11.9209 11.7712 11.7746C11.9117 11.6341 11.9905 11.4436 11.9905 11.245C11.9905 11.0464 11.9117 10.8559 11.7712 10.7154L7.05762 6.00164Z"
                        fill="#343B4E"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div> */}
            <div className="col-12 p-0">
              <div className="user-availability">
                <p>Check {location?.state?.Profile?.firstName} availability</p>
                <span>Let client know you available time to be hired</span>
              </div>
            </div>
            <div className="col-12 col-lg-6 p-0 pe-lg-3 pe-xl-4 mb-4 mb-lg-0">
              <div className="card calender-card">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <div className="col-12 p-0">
                      <div className="calender">
                        <div className="calender-header">
                          <button
                            className="btn btn-light"
                            onClick={handlePreviousMonth}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="15"
                              viewBox="0 0 10 15"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_2237_17146)">
                                <path
                                  d="M-0.294044 7.06881C-0.294044 6.82079 -0.173547 6.5728 0.0669622 6.3837L7.63894 0.433306C8.1206 0.0547835 8.90156 0.0547835 9.38304 0.433306C9.86451 0.811677 9.86451 1.42527 9.38304 1.80382L2.68294 7.06881L9.3828 12.3338C9.86428 12.7124 9.86428 13.3259 9.3828 13.7042C8.90132 14.0829 8.12038 14.0829 7.6387 13.7042L0.0667124 7.75391C-0.173821 7.56471 -0.294044 7.31674 -0.294044 7.0688V7.06881Z"
                                  fill="#343B4E"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2237_17146">
                                  <rect
                                    width="14"
                                    height="9.63593"
                                    fill="white"
                                    transform="matrix(0 1 -1 0 9.74414 0.149414)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                          <div>
                            <p>
                              {new Date(
                                currentYear,
                                currentMonth - 1
                              ).toLocaleString("default", { month: "long" })}
                            </p>
                            <span>{currentYear}</span>
                          </div>
                          <button
                            className="btn btn-light"
                            onClick={handleNextMonth}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="15"
                              viewBox="0 0 12 15"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_2237_17141)">
                                <path
                                  d="M11.9679 7.23002C11.9679 7.47804 11.8354 7.72603 11.5709 7.91512L3.24461 13.8655C2.71496 14.244 1.8562 14.244 1.32676 13.8655C0.79733 13.4872 0.79733 12.8736 1.32676 12.495L8.69432 7.23002L1.32702 1.965C0.797577 1.58647 0.797577 0.972949 1.32702 0.59461C1.85647 0.215904 2.71521 0.215904 3.24487 0.59461L11.5712 6.54492C11.8357 6.73411 11.9679 6.98209 11.9679 7.23003V7.23002Z"
                                  fill="#343B4E"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2237_17141">
                                  <rect
                                    width="14"
                                    height="10.8404"
                                    fill="white"
                                    transform="matrix(0 -1 1 0 0.929688 14.1494)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                        </div>
                        <div className="calender-body">
                          <ul className="days">
                            <li>Sun</li>
                            <li>Mon</li>
                            <li>Tue</li>
                            <li>Wed</li>
                            <li>Thu</li>
                            <li>Fri</li>
                            <li>Sat</li>
                          </ul>
                          <ul>{renderCalendarDays()}</ul>
                        </div>
                        <div className="calender-footer">
                          <div className="d-flex align-items-center gap-3">
                            <span className="booked"></span>
                            <p>Booked</p>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <span className="event"></span>
                            <p>Artist event</p>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <span className="na"></span>
                            <p>Not Available</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 p-0">
                      <div className="availability-wrapper">
                        <>
                          {Availibilty?.map(
                            (res, index) =>
                              (res?.additionalNote || res?.reason) && (
                                <div className="available-info" key={index}>
                                  <div className="d-flex align-items-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="10"
                                      height="10"
                                      viewBox="0 0 10 10"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M4.89642 2.92838C3.75416 2.92838 2.82486 3.85769 2.82486 4.99994C2.82486 6.1422 3.75417 7.0715 4.89642 7.0715C6.03868 7.0715 6.96798 6.14219 6.96798 4.99994C6.96798 3.85768 6.03867 2.92838 4.89642 2.92838ZM4.89642 0.103516C7.60064 0.103516 9.79284 2.29573 9.79284 4.99994C9.79284 7.70416 7.60063 9.89636 4.89642 9.89636C2.19221 9.89636 0 7.70415 0 4.99994C0 2.29573 2.19221 0.103516 4.89642 0.103516Z"
                                        fill="#C7C8D0"
                                      />
                                    </svg>
                                    <p>{res?.date}</p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <span>
                                      Not Available for: {res?.additionalNote}{" "}
                                      <br />
                                      <span className="time">
                                        {res?.startTime} to {res?.endTime}
                                      </span>
                                    </span>

                                    <button
                                      className="btn btn-light rounded-circle py-3"
                                      onClick={() =>
                                        deleteAvailability(res?.id)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M7.05762 6.00164L11.7712 1.28784C11.8428 1.21874 11.8998 1.13609 11.9391 1.0447C11.9783 0.953313 11.999 0.855022 11.9999 0.755563C12.0007 0.656104 11.9818 0.557469 11.9441 0.465413C11.9065 0.373357 11.8508 0.289723 11.7805 0.219393C11.7102 0.149062 11.6265 0.0934424 11.5345 0.0557793C11.4424 0.0181162 11.3438 -0.000835989 11.2443 2.82821e-05C11.1449 0.000892553 11.0466 0.0215562 10.9552 0.0608134C10.8638 0.100071 10.7812 0.157135 10.7121 0.228678L5.99827 4.94229L1.28466 0.228678C1.14367 0.0907862 0.953989 0.0140576 0.756783 0.0151437C0.559576 0.0162297 0.370754 0.0950426 0.231293 0.234478C0.0918327 0.373914 0.0129868 0.562723 0.0118659 0.759929C0.010745 0.957135 0.0874397 1.14683 0.225306 1.28784L4.9391 6.00164L0.225306 10.7154C0.154478 10.7847 0.0980952 10.8673 0.0594229 10.9586C0.0207506 11.0498 0.000556963 11.1477 1.13597e-05 11.2468C-0.000534243 11.3459 0.0185791 11.4441 0.0562444 11.5357C0.0939097 11.6274 0.149378 11.7106 0.21944 11.7807C0.289501 11.8507 0.372762 11.9062 0.464402 11.9438C0.556042 11.9815 0.654241 12.0006 0.753312 12C0.852383 11.9994 0.950358 11.9792 1.04156 11.9405C1.13277 11.9018 1.2154 11.8454 1.28466 11.7746L5.99827 7.06099L10.7121 11.7746C10.8583 11.9209 11.0501 11.9941 11.2416 11.9941C11.4332 11.9941 11.6252 11.9209 11.7712 11.7746C11.9117 11.6341 11.9905 11.4436 11.9905 11.245C11.9905 11.0464 11.9117 10.8559 11.7712 10.7154L7.05762 6.00164Z"
                                          fill="#343B4E"
                                        ></path>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              )
                          )}
                        </>
                        <div className="col-12 p-0">
                          <h6>Choose Option</h6>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={selectedValue}
                            onChange={handleSelect}
                          >
                            <option value="" disabled>
                              Select option
                            </option>
                            <option value={1}>Create my Event</option>
                            <option value={2}>
                              Not Available for Same hours
                            </option>
                            <option value={3}>
                              Not Available for full day
                            </option>
                          </select>
                        </div>

                        {/* Display timeslot for "Create my Event" and "Not Available for Same hours" */}
                        {(selectedValue == 1 || selectedValue == 2) && (
                          <div className="available-time">
                            <p>Available hours</p>
                            <div className="time">
                              <ul>
                                {
                                  selectedDate &&
                                    hours.map((list, key) => {
                                      const isBetween =
                                        startselectedHour && endselectedHour
                                          ? list.value >= startselectedHour &&
                                            list.value <= endselectedHour
                                          : false;

                                      const isDisabled =
                                        calendarStartTime && calendarEndTime
                                          ? list.value >= calendarStartTime &&
                                            list.value <= calendarEndTime
                                          : false;

                                      return (
                                        <li
                                          key={key}
                                          onClick={() => {
                                            if (!isDisabled) {
                                              handleHourClickData(list?.value);
                                            }
                                          }}
                                          className={
                                            isBetween
                                              ? "color"
                                              : isDisabled
                                              ? "na"
                                              : ""
                                          }
                                        >
                                          {list?.displayTime}
                                        </li>
                                      );
                                    })

                                  // hours.map((list, key) => {
                                  //   const isBetween =
                                  //   startselectedHour && endselectedHour
                                  //     ? list.value >= startselectedHour && list.value <= endselectedHour
                                  //     : false;

                                  // // Logic for the 'disabled' condition
                                  // const isDisabled =
                                  //   calendarStartTime && calendarEndTime
                                  //     ? list.value >= calendarStartTime && list.value <= calendarEndTime
                                  //     : false;

                                  //     console.log(calendarStartTime);
                                  //     console.log(calendarEndTime)

                                  //   return (

                                  //     <li
                                  //     key={key}
                                  //     onClick={() => handleHourClickData(list?.value)}
                                  //     className={`${isBetween ? "highlight" : ""} ${isDisabled ? "na" : ""}`}
                                  //     style={{
                                  //       cursor: isDisabled ? "not-allowed" : "pointer",
                                  //       opacity: isDisabled ? 0.5 : 1,
                                  //       pointerEvents: isDisabled ? "none" : "auto",
                                  //     }}
                                  //   >
                                  //     {list?.displayTime}
                                  //   </li>
                                  //   );
                                  // })
                                }
                                {/* {selectedDate &&
                                  hours.map((list) => (
                                    <li
                                      key={list}
                                      onClick={() => handleHourClickData(list)}
                                      className={`${
                                        SelectedTime(list) ? "color" : ""
                                      }`}
                                    >
                                      {list}
                                    </li>
                                  ))} */}
                                {/* {selectedDate && isMarkedDate(selectedDate) && (
                                <p>No available slots for this date.</p>
                              )} */}
                              </ul>
                            </div>
                          </div>
                        )}

                        {/* Display explanation and additional information for "Not Available for Same hours" or "Not Available for full day" */}
                        {(selectedValue == 2 || selectedValue == 3) && (
                          <div className="available-time">
                            <h6>Unavailable Explanation</h6>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={selectedResion}
                              onChange={handleSelectReason}
                            >
                              <option value={1}>Family Function</option>
                              <option value={2}>Traveling</option>
                              <option value={3}>Other</option>
                            </select>
                            <div className="col-12 p-0">
                              <h6 className="mt-3">Additional Information</h6>
                              <textarea
                                className="form-control"
                                id="info"
                                rows={3}
                                onChange={handleMoreInformation}
                                placeholder="Type here..."
                              />
                            </div>
                          </div>
                        )}

                        {/* Conditional rendering of the "Confirm" button */}
                        {hireTalenetForm === true ||
                        (selectedValue == 1 && startselectedHour == null) ||
                        (selectedValue == 1 && endselectedHour == null) ? (
                          ""
                        ) : (
                          <button
                            className="btn btn-primary w-100 mt-2"
                            onClick={() => hireTalenetFormToggle()}
                          >
                            Confirm
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {hireTalenetForm === false ? (
              <div className="col-12 col-lg-6 p-0 ps-lg-3 ps-xl-4 ">
                <div className="card event-card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="heading">
                          <h5>My Events</h5>
                        </div>
                      </div>

                      {MyEvents?.length > 0 &&
                      MyEvents?.some((event) => event.talentEvent) ? (
                        MyEvents?.map((event, index) => {
                          if (event.talentEvent) {
                            const isLastEvent = index === MyEvents.length - 1;

                            return (
                              <div className="col-12 p-0" key={index}>
                                <div
                                  className={`event-desc ${
                                    isLastEvent ? "pb-0 border-0" : ""
                                  }`}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center gap-1 gap-md-3 private">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="17"
                                        viewBox="0 0 18 17"
                                        fill="none"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M8.90938 4.88991C6.83103 4.88991 5.14015 6.49833 5.14015 8.4753C5.14015 10.4523 6.83105 12.0607 8.90938 12.0607C10.9877 12.0607 12.6786 10.4523 12.6786 8.4753C12.6786 6.49831 10.9877 4.88991 8.90938 4.88991ZM8.9091 0C13.8295 0 17.8182 3.79421 17.8182 8.47457C17.8182 13.155 13.8294 16.9491 8.9091 16.9491C3.98875 16.9491 0 13.1549 0 8.47457C0 3.79421 3.98875 0 8.9091 0Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                      <p>{event?.talentEvent?.eventType}</p>
                                      {/* <span className="time">
                                        {
                                          event?.talentEvent?.eventTimeLocation
                                            ?.start_date
                                        }
                                      </span> */}
                                    </div>
                                    {/* <span className="time">
                                      {
                                        event?.talentEvent?.eventTimeLocation
                                          ?.start_time
                                      }{" "}
                                      -{" "}
                                      {
                                        event?.talentEvent?.eventTimeLocation
                                          ?.end_time
                                      }
                                    </span> */}
                                  </div>
                                  <div>
                                    <span className="time">
                                      {
                                        event?.talentEvent?.eventTimeLocation
                                          ?.start_time
                                      }{" "}
                                      -{" "}
                                      {
                                        event?.talentEvent?.eventTimeLocation
                                          ?.end_time
                                      }
                                    </span>
                                    <p>
                                      {/* {event?.talentEvent?.entertainmentType}:{" "} */}
                                      {event?.talentEvent?.eventType}
                                      {": "}
                                      {event?.talentEvent?.eventName}
                                    </p>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="desc">
                                        {
                                          event?.talentEvent?.eventTimeLocation
                                            ?.location
                                        }
                                      </p>
                                      {/* <Link
                                      to="#"
                                      style={{ marginRight: "10px" }}
                                      onClick={() =>
                                        editEvent(event?.talentEvent)
                                      }
                                    >
                                      Edit
                                    </Link>
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        deleteEvent(event?.talentEvent?.id)
                                      }
                                    >
                                      Delete
                                    </Link> */}
                                      <div className="btn-wrapper">
                                        <button
                                          className="btn btn-light rounded-circle"
                                          onClick={() =>
                                            editEvent(event?.talentEvent)
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                          >
                                            <path
                                              d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95515 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43314 1.6506L12.3494 4.56687L13.7725 3.14373Z"
                                              fill="#343B4E"
                                            ></path>
                                          </svg>
                                        </button>
                                        <button
                                          className="btn btn-light rounded-circle"
                                          onClick={() =>
                                            deleteEvent(event?.talentEvent?.id)
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                          >
                                            <path
                                              d="M7.05762 6.00164L11.7712 1.28784C11.8428 1.21874 11.8998 1.13609 11.9391 1.0447C11.9783 0.953313 11.999 0.855022 11.9999 0.755563C12.0007 0.656104 11.9818 0.557469 11.9441 0.465413C11.9065 0.373357 11.8508 0.289723 11.7805 0.219393C11.7102 0.149062 11.6265 0.0934424 11.5345 0.0557793C11.4424 0.0181162 11.3438 -0.000835989 11.2443 2.82821e-05C11.1449 0.000892553 11.0466 0.0215562 10.9552 0.0608134C10.8638 0.100071 10.7812 0.157135 10.7121 0.228678L5.99827 4.94229L1.28466 0.228678C1.14367 0.0907862 0.953989 0.0140576 0.756783 0.0151437C0.559576 0.0162297 0.370754 0.0950426 0.231293 0.234478C0.0918327 0.373914 0.0129868 0.562723 0.0118659 0.759929C0.010745 0.957135 0.0874397 1.14683 0.225306 1.28784L4.9391 6.00164L0.225306 10.7154C0.154478 10.7847 0.0980952 10.8673 0.0594229 10.9586C0.0207506 11.0498 0.000556963 11.1477 1.13597e-05 11.2468C-0.000534243 11.3459 0.0185791 11.4441 0.0562444 11.5357C0.0939097 11.6274 0.149378 11.7106 0.21944 11.7807C0.289501 11.8507 0.372762 11.9062 0.464402 11.9438C0.556042 11.9815 0.654241 12.0006 0.753312 12C0.852383 11.9994 0.950358 11.9792 1.04156 11.9405C1.13277 11.9018 1.2154 11.8454 1.28466 11.7746L5.99827 7.06099L10.7121 11.7746C10.8583 11.9209 11.0501 11.9941 11.2416 11.9941C11.4332 11.9941 11.6252 11.9209 11.7712 11.7746C11.9117 11.6341 11.9905 11.4436 11.9905 11.245C11.9905 11.0464 11.9117 10.8559 11.7712 10.7154L7.05762 6.00164Z"
                                              fill="#343B4E"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="no-event">
                          <h3>No events for you</h3>
                          <p>
                            Your upcoming and ongoing event will show here...
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-12 col-lg-6 p-0 ps-lg-3 ps-xl-4">
                <div className="card event-card">
                  <div className="card-body">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="hiring-form"
                    >
                      <div className="row justify-content-center">
                        <div className="col-12 p-0">
                          <h4>Schedule my Event</h4>
                        </div>
                        <div className="col-12 p-0">
                          <label htmlFor="fname" className="form-label">
                            Enter your event title
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="fname"
                            placeholder="Event Title"
                            {...register("fname", {
                              required: "Event Title is required",
                            })}
                          />
                          {errors.fname && (
                            <p className="form-text text-danger">
                              {errors.fname.message}
                            </p>
                          )}
                        </div>
                        <div className="col-12 p-0">
                          <label htmlFor="type" className="form-label">
                            What type of event are you hosting?
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <select
                            className="form-select"
                            id="type"
                            {...register("type", {
                              required: "Event Type is required",
                            })}
                            value={watch("type") || ""}
                          >
                            <option value="">Select Event Type</option>
                            {EventType &&
                              EventType.map((res, index) => {
                                return (
                                  <option key={index} value={res?.type}>
                                    {res?.type}
                                  </option>
                                );
                              })}
                          </select>
                          {errors.type && (
                            <p className="form-text text-danger">
                              {errors.type.message}
                            </p>
                          )}
                        </div>

                        {/* <div className="col-12 p-0">
                          <label htmlFor="entertemenet" className="form-label">
                            What type of entertainment would you like?
                          </label>
                          <select
                            className="form-select"
                            id="entertemenet"
                            {...register("entertemenet", {
                              required: "Event Type is required",
                            })}
                          >
                            <option value="">Select Event Type</option>
                            {Entertainment &&
                              Entertainment.map((res) => {
                                return (
                                  <option value={res?.type}>{res?.type}</option>
                                );
                              })}
                          </select>
                          {errors.entertemenet && (
                            <p className="error">
                              {errors.entertemenet.message}
                            </p>
                          )}
                        </div> */}
                        <div className="col-12 p-0">
                          <label htmlFor="type" className="form-label">
                            What type of instrument genre are you looking for?
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <div className="form-check">
                            {Music &&
                              Music.map((res, index) => (
                                <div key={index} className="form-check mb-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`instrument-${index}`}
                                    value={res?.type}
                                    checked={selectedInstruments.includes(
                                      res?.type
                                    )} // Check if the instrument is selected
                                    onChange={handleInstrumentChange} // Handle checkbox change
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`instrument-${index}`}
                                  >
                                    {res?.type}
                                  </label>
                                </div>
                              ))}
                          </div>
                          {errors.music && (
                            <p className="form-text text-danger">
                              {errors.music.message}
                            </p>
                          )}
                        </div>

                        <div className="col-12 p-0">
                          <label htmlFor="location" className="form-label">
                            Event Location
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <GooglePlaceAutocomplete
                            selectedPlace={selectedPlace}
                            setSelectedPlace={setSelectedPlace}
                            defaultValues={
                              editData?.eventTimeLocation?.location
                            }
                          />
                          {errors.location && (
                            <p className="form-text text-danger">
                              {errors.location.message}
                            </p>
                          )}
                        </div>

                        <div className="col-12 p-0">
                          <label htmlFor="info" className="form-label">
                            Additional Information
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            id="info"
                            rows={3}
                            maxLength={500}
                            placeholder="Type here..."
                            {...register("info", {
                              required: "Additional information is required",
                            })}
                          />
                          {errors.info && (
                            <p className="form-text text-danger">
                              {errors.info.message}
                            </p>
                          )}
                        </div>
                        <div className="col-12  p-0">
                          {imageUrl?.image_view ? (
                            <>
                              <div className="position-relative img-preview">
                                <button
                                  className="btn btn-link"
                                  onClick={imageRemoveHandler}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none"
                                  >
                                    <path
                                      d="M13.7188 5.12305L4.71875 14.123M4.71875 5.12305L13.7188 14.123"
                                      stroke="black"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>

                                {imageUrl.image_view && (
                                  <img
                                    src={imageUrl?.image_view}
                                    key={imageUrl.image_url?.name}
                                    alt=""
                                  />
                                )}
                              </div>
                            </>
                          ) : (
                            <label for="file" className="form-label w-100 p-0">
                              <div className="fileUploadBox d-flex justify-content-center flex-column align-items-center">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="41"
                                    height="41"
                                    viewBox="0 0 41 41"
                                    fill="none"
                                  >
                                    <path
                                      d="M20.6308 0.755859C9.76922 0.755859 0.944336 9.58074 0.944336 20.4423C0.944336 31.3038 9.76922 40.1287 20.6308 40.1287C31.4923 40.1287 40.3172 31.3038 40.3172 20.4423C40.3172 9.58074 31.4923 0.755859 20.6308 0.755859ZM20.6308 2.54553C30.5259 2.54553 38.5275 10.5481 38.5275 20.4423C38.5275 22.2302 38.259 23.9527 37.7723 25.5804L32.4489 20.257C31.9075 19.7157 31.1907 19.4445 30.474 19.4445C29.7572 19.4445 29.0404 19.7157 28.4991 20.257L23.7627 24.9934L15.447 16.6777C14.9056 16.1363 14.1888 15.8652 13.4721 15.8652C12.7553 15.8652 12.0385 16.1363 11.4971 16.6777L3.3076 24.8672C2.93265 23.4219 2.73996 21.9354 2.73401 20.4423C2.73401 10.5472 10.7356 2.54553 20.6308 2.54553ZM13.4721 17.6441C13.7271 17.6441 13.9821 17.7426 14.1817 17.943L22.4974 26.2587L17.3136 31.4425C17.1506 31.6113 17.0604 31.8373 17.0624 32.0719C17.0645 32.3066 17.1586 32.531 17.3245 32.6969C17.4904 32.8628 17.7148 32.9569 17.9495 32.959C18.1841 32.961 18.4101 32.8708 18.5789 32.7078L29.7644 21.5223C29.8567 21.4276 29.967 21.3523 30.0889 21.3008C30.2107 21.2494 30.3417 21.2229 30.474 21.2229C30.6062 21.2229 30.7372 21.2494 30.8591 21.3008C30.9809 21.3523 31.0913 21.4276 31.1836 21.5223L37.1074 27.4462C34.3844 33.8514 28.0373 38.339 20.6308 38.339C12.9772 38.339 6.47443 33.5427 3.91162 26.7938L12.7616 17.9439C12.962 17.7434 13.217 17.645 13.4721 17.645V17.6441Z"
                                      fill="#CAC8C8"
                                    />
                                    <path
                                      d="M20.6309 6.12524C18.1701 6.12524 16.1567 8.13863 16.1567 10.5994C16.1567 13.0602 18.1701 15.0736 20.6309 15.0736C23.0917 15.0736 25.1051 13.0602 25.1051 10.5994C25.1051 8.13863 23.0917 6.12524 20.6309 6.12524ZM20.6309 7.91492C22.1244 7.91492 23.3154 9.10595 23.3154 10.5994C23.3154 12.0929 22.1244 13.2839 20.6309 13.2839C19.1374 13.2839 17.9464 12.0929 17.9464 10.5994C17.9464 9.10595 19.1374 7.91492 20.6309 7.91492Z"
                                      fill="#CAC8C8"
                                    />
                                  </svg>
                                </span>

                                <p>
                                  Upload here{" "}
                                  <span className="text-danger fs-2 ps-1">
                                    *
                                  </span>
                                </p>
                              </div>
                            </label>
                          )}
                          <input
                            type="file"
                            className="form-control d-none"
                            id="file"
                            onChange={imageUploadHandler}
                            accept="image/*"
                          />
                        </div>
                        <p>{ImageErrorState}</p>

                        <div className="col-12 col-md-4 mx-3">
                          <button
                            type="button"
                            onClick={handleCancel}
                            className="btn btn-light w-100 mr-4"
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-12 col-md-4 mx-3">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                            disabled={imageUrl?.image_view == ""}
                          >
                            {loading ? <Spinner /> : "Confirm"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* =========================== */}
          {/* Main feed grid End */}
          {/* =========================== */}
        </div>
      </section>
    </>
  );
}

export default ManageEvent;
