import Header from "../Components/Header";
import HomeFooter from "../Components/HomeFooter";

export default function TermsConditions() {
  return (
    <>
    <Header />
      <section className="terms-wrapper">
        <h1>TERMS OF USE</h1>

        <div className="max-width-container">
          <div className="row">
            <div className="col-12">
              <p className="my-3 my-md-4 updated">Updated: July 29, 2024</p>
              <p>
                THESE TERMS OF USE, including any additional terms and
                conditions that are referenced here in or presented elsewhere on
                the Site (defined below), generally or in relation to a specific
                service or feature, including our Privacy Policy (collectively,
                the “Terms” and/or “Agreement”) set forth the terms and
                conditions that apply to your use of the Site. (company’s
                registered name) (“Jubal Talents”), is the owner of the website
                “www.jubaltalents.com” and all rights therein and associated
                therewith, including, but not limited to, the copyrights and
                trademarks (hereinafter, individually and collectively, the
                “Site”).
              </p>

              <p className="mb-3 mb-md-4">
                This Agreement sets forth the legally binding terms of service
                for your use of the Site and all services provided by Jubal
                Talents on the Site (the “Services”). By using the Site and/or
                Services offered by Jubal Talents, which includes but is not
                limited to browsing the site, you, whether an individual or an
                entity, (the “User” or “You”) are expressly agreeing to be bound
                by these Terms, including those additional terms of use and
                policies referenced herein. You further agree to comply with all
                applicable laws and regulations, including United States and New
                York laws. In these Terms, the words “we,” “us,” and “our” refer
                to Jubal Talents. These Terms apply to all users of the Site,
                including, without limitation, users who are a business entity,
                customers, buyers, vendors, sellers, and contributors of
                content, information, and other materials or services on the
                Site. By using the Site and/or Services offered by Jubal
                Talents, you hereby waive any and all claims challenging the
                applicability or binding nature of the Terms.
              </p>

              <p className="mb-3 mb-md-4">
                YOU AGREE THAT BY USING THE SITE AND THE SERVICES YOU ARE AT
                LEAST 18 YEARS OF AGE AND YOU ARE LEGALLY CAPABLE OF ENTERING
                INTO A CONTRACT. Minors are not eligible to use the Site without
                the supervision of a legal guardian and we ask that they not
                submit any personal information to us. You acknowledge that we
                reserve the right to refuse service to anyone and to cancel user
                access at any time.
              </p>

              <p className="mb-3 mb-md-4">
                IT IS YOUR RESPONSIBILITY TO REVIEW THE TERMS PERIODICALLY. IF
                YOU DO NOT AGREE TO THE TERMS, DO NOT USE THE SITE. WE MAY
                MODIFY THESE TERMS AT ANY TIME WITHOUT NOTICE TO YOU, AND SUCH
                MODIFICATIONS, ADDITIONS, OR DELETIONS SHALL BE EFFECTIVE
                IMMEDIATELY UPON POSTING. WE MAY CHANGE OR DISCONTINUE ANY
                ASPECT, SERVICE, OR FEATURE OF THE SITE, INCLUDING, BUT NOT
                LIMITED TO, THE CONTENT, AVAILABILITY, AND EQUIPMENT NEEDED TO
                ACCESS OR USE THE SITE, AT ANY TIME AND WITHOUT NOTICE. IF YOU
                HAVE ANY QUESTIONS ABOUT THESE TERMS, PLEASE CONTACT US.
              </p>
            </div>
            <div className="col-12">
              <h2>1. ABOUT THE SITE</h2>
              <p>
                The Site is a marketplace platform dedicated to music and
                multimedia production. You can read more about us on our About
                page. Through use of the Site, Vendor can access, browse, and
                register for services offered by independent Talent (the
                “Talent(s)”) for music, performance, or multimedia services (the
                “Service(s)”) that can be facilitated through the Site. Talent,
                and not Jubal Talents, set the terms for their Services
                (including, without limitation, the scope of their services,
                price, work hours, etc.). Vendors who engage with Talents
                through the Site for an Event are referred to herein as
                “Vendors.” Communication, file transfer(s), and payment between
                the Vendors and Talents are all completed through the Site.
                Payments for Events are made via the Jubal Talents payment
                portal. Jubal Talents will hold the payment until the Event has
                been completed. Once a Talent performs in the event, the Vendor
                will have three (3) days to either reject or accept and review
                (the “Review Period”). In the event that the Vendor neither
                accepted nor rejected within the Review Period, the Event (and
                any work product or services related thereto) will be deemed
                accepted. Upon the first of: a. Vendor’s acceptance, or b. Upon
                the conclusion of the Review Period with neither an acceptance
                or rejection communicated from the Vendor to the Talent; Jubal
                Talents will release the payment to the Talent, less commission
                (the “Commission”) retained by Jubal Talents. Events will be
                assessed a ? percent (?%) service fee (the “Service Fee”)
                payable by the Vendor at the time of payment via the Jubal
                Talents payment portal. After the conclusion of the Event, the
                Vendor and Talent will have the opportunity to post a review
                regarding the Event Performance and/or their experience with
                Vendor or Talent, as applicable. For the avoidance of doubt, the
                Talent will be under no obligation to perform any services in
                connection with the Event unless and until payment therefore is
                received by Jubal Talents via the Jubal Talent payment portal.
                Additional information regarding Events, as well as Vendors’ and
                Talents’ rights and responsibilities, is included below.
              </p>
            </div>
            <div className="col-12">
              <h2>2. ACCOUNTS ON THE SITE</h2>
              <p>
                During your use of the Site, it shall be solely your
                responsibility to maintain the confidentiality of your email
                address, password, and any other account identifiers related to
                any personal account you created on the Site (the “Account”),
                and for restricting access by other users or computers. You also
                acknowledge and accept sole responsibility for any and all
                activity that occurs under your Account. Only registered users
                can engage in the Services on the Site as a Vendor or Talent,
                subject to the terms and conditions of this Agreement. Jubal
                Talents shall have the right, but not the obligation, to monitor
                your or other users’ use of the Site to determine compliance
                with the Terms and to satisfy any law, regulation, or authorized
                government request. Jubal Talents may share personally
                identifiable information in response to a law enforcement
                agency’s request, or where we deem, in our sole discretion, that
                it is necessary or otherwise required by law. Additional
                information about the use of personally identifiable information
                is available in our Privacy Policy, which is hereby incorporated
                by reference.
                <br />
                Jubal Talents reserves the right to curate marketplace and/or
                platform content by pausing, restricting, or deleting any
                service or account based on its sole discretion. Users with
                accounts on hold will not be able to sell or buy on the Site.
                Jubal Talents has the sole discretion and exclusive right,
                regardless of the reason, to permanently and/or temporarily
                suspend, restrict, or delete your account and/or access to the
                Services.
              </p>
            </div>
            <div className="col-12">
              <h2>3. USER BEHAVIOR</h2>
              <p>
                Jubal Talents grants you permission to use the Site subject to
                all of the terms and conditions set forth in these Terms. As a
                condition of use, you agree not to use the Site for any purpose
                that is unlawful. You agree to abide by all applicable local,
                state, national, and international laws and regulations,
                including, without limitation, all intellectual property laws
                (such as U.S. copyright laws). Any unauthorized use of the Site
                is expressly prohibited. By way of example, you agree not to (a)
                take any action, or (b) upload, download, post, submit, or have
                Jubal Talents upload or post on your behalf, or otherwise
                distribute or facilitate distribution of any material and/or
                content, using any communications service or other service
                available on or through the Site, that:
              </p>
              <ul>
                <li>
                  Infringes any patent, trademark, trade secret, copyright,
                  right of publicity, or other right of any other person or
                  entity
                </li>
                <li>
                  Is threatening, abusive, harassing, defamatory, libelous,
                  deceptive, fraudulent, invasive of another’s privacy,
                  tortious, offensive, profane, pornographic, or obscene, or
                  promotes hate or incites violence
                </li>
                <li>
                  Misrepresents the source, identity, or content of information
                  transmitted via the Site
                </li>
                <li>
                  Constitutes unauthorized or unsolicited advertising, junk or
                  bulk e-mail (“spamming”) or a chain letter, a pyramid scheme,
                  petitions for signatures, charity requests, or any other
                  similar solicitation.
                </li>
                <li>
                  Contains software viruses or any other computer codes, files,
                  or programs that are designed or intended to disrupt, damage,
                  limit, or interfere with the proper function of any software,
                  hardware, or telecommunications equipment or to damage or
                  obtain unauthorized access to any system, data, or other
                  information of Jubal Talents or any third party.
                </li>
                <li>
                  Impersonates, or falsely indicates an affiliation with, any
                  person or entity, including, without limitation, any employee
                  or representative of Jubal Talents.
                </li>
                <li>
                  Collects, or attempts to collect, personal information about
                  users without their consent or constitutes a solicitation, for
                  commercial purposes, of any users of the Site.
                </li>
                <li>
                  Otherwise violates these Terms, the Privacy Policy, or any
                  other policy posted on the Site.
                </li>
              </ul>
              <p>
                Additionally, you agree that you will not: (i) take any action
                that imposes, or may impose, in Jubal Talents’ sole discretion,
                an unreasonable or disproportionately large load on Jubal
                Talents’ infrastructure; (ii) interfere or attempt to interfere
                with the proper working of the Site or any activities conducted
                on the Site; (iii) alter or modify any part of the website; (iv)
                bypass any measures Jubal Talents may use to prevent or restrict
                access to the Site, other accounts, or computer systems or
                networks connected to the Site; (v) use the Site to collect user
                names, users’ contact information, personal information; (vi)
                use the Site for any business or commercial purpose other than
                as specifically intended and provided for herein; or (vi)
                interfere with any other user’s use or enjoyment of the Site,
                including, without limitation, accessing an account of an Jubal
                Talents user that is not yours.
                <br />
                You may not (and may not permit others to), directly or
                indirectly, modify, translate, decompile, disassemble, data
                mine, scrape, index, or reverse engineer any part of the Site,
                any content of third parties, or any other content available
                through the Site (except to the limited extent applicable laws
                specifically prohibit such restriction), or copy, rent, lease,
                distribute, or otherwise transfer any of the rights that you
                receive hereunder. In addition, you may not remove any
                proprietary notices, marks, or labels.
                <br />
                You shall not launch or otherwise use any robot, spider,
                scraper, or other automated means to access the Site or use the
                Service in a manner which sends more request messages to the
                Jubal Talents server in any given period of time than a typical
                human would normally produce in the same period by using a
                conventional on-line web browser to read, view, and submit
                content. Notwithstanding the foregoing, we grant the operators
                of search engines permission to use robots to copy Materials (as
                defined herein below) from the Site for the sole purpose of
                creating publicly-available searchable indexes of the Site and
                Materials, but not caches or archives of the Materials, provided
                that we reserve the right to revoke these exceptions either
                generally or in specific cases.
                <br />
                Through the use of the Site, the User may have access to
                confidential information relating to such matters as other
                users’ and/or Jubal Talents’ business, trade secrets, systems,
                procedures, manuals, products, contracts, personnel, and
                clients. As used herein, “Confidential Information” means
                information belonging to such other users or Jubal Talents which
                is of value to such party and the disclosure of which could
                result in a competitive or other disadvantage to either party,
                including, without limitation, financial information, business
                practices and policies, know-how, trade secrets, market or sales
                information or plans, customer lists, and business plans.
                Confidential Information does not include: (i) information that
                was known to the receiving party before receipt thereof from or
                on behalf of the disclosing party; (ii) information that is
                disclosed to the receiving party by a third person who has a
                right to make such disclosure without any obligation of
                confidentiality to the party seeking to enforce its rights
                hereunder; (iii) information that is or becomes generally known
                in the trade without violation of these Terms by the receiving
                party; or (iv) information that is independently developed by
                the receiving party or its employees or affiliates without
                reference to the disclosing party’s information. Each party will
                protect the other’s Confidential Information with at least the
                same degree of care it uses with respect to its own Confidential
                Information and will not use the other party’s Confidential
                Information other than in connection with its obligations
                hereunder. Notwithstanding the foregoing, a party may disclose
                the other’s Confidential Information if (i) required by law,
                regulation, or legal process or if requested by any government
                agency; (ii) it is advised by counsel that it may incur
                liability for failure to make such disclosure; (iii) requested
                to by the other party; provided that in the event of (i) or (ii)
                the disclosing party shall give the other party reasonable prior
                notice of such disclosure to the extent reasonably practicable
                and cooperate with the other party (at such other party’s
                expense) in any efforts to prevent such disclosure.
              </p>
            </div>
            <div className="col-12">
              <h2>4. EVENTS HELD BY VENDORS ON THE SITE</h2>
              <p>
                Confirmation of Event. While it is our practice to confirm
                Events by email, the receipt of an email event confirmation does
                not constitute the acceptance of an event. We reserve the right,
                without prior notice, to limit the order for a Project and/or to
                refuse service to any Vendor. We also may require verification
                of information prior to the acceptance of any Event. An Event is
                complete once the Vendor confirms the event is performed or the
                conclusion of the Review Period (whichever comes first).
                <br />
                Cancellation or Refusal of Events. Jubal Talents reserves the
                right to refuse any event held on the Site. In the event we make
                a change to or cancel an event, we will attempt to notify you by
                contacting the email and/or phone number provided at the time
                your account was made.
                <br />
                Payment Method. All Events must be completed by the Vendor
                through the Site. Vendors must pay for Events on the Site by
                using the Site’s payment portal. It is explicitly forbidden for
                Vendors to pay Talents directly for Events, whether in person or
                by using any other payment method other than the payment portal
                provided through the Site. Upon request, which shall be granted
                on a case-by-case basis and in the sole discretion of Jubal
                Talents, Jubal Talents may send an invoice from an alternate
                payment processor. Such requests must be made by emailing (Gmail
                Address). If you have been asked to use a payment method not
                offered by Jubal Talents, please report it immediately by
                contacting (Gmail Address).
                <br />
                Provision of Services. Jubal Talents prides itself in providing
                quality services. Despite our best efforts, however, some
                services may be limited. Display of an Event on the Site is not
                a guarantee that the Project is currently or will be available
                during your visit. If you are unable to locate a particular
                service or Event, if you have any questions concerning the
                availability of a service or Event, please contact us at (Gmail
                Address). Jubal Talents makes no representations or warranties
                concerning inaccuracies, typographical errors, or other defects
                which may be present in the content available on the Site or
                provided as a result of an Event.
              </p>
            </div>
            <div className="col-12">
              <h2>5. TALENTS ON THE SITE</h2>
              <p>
                When the Vendor books a Talent(s) for an Event through the Site,
                the Talent will be notified through their account and by email.
                After the Talent successfully delivers the services to Vendor,
                Jubal Talents will credit the Talents’ account with the revenue
                derived from the established booking price minus Jubal Talents’
                commission. Jubal Talents agrees to process funds to a Talent’s
                Account within a reasonable time frame (usually three business
                days) after Vendor’s acceptance or the conclusion of the Review
                Period. However, Jubal Talents cannot guarantee a specific
                delivery time of funds.
                <br />
                It is explicitly prohibited for Talents to include outbound
                links (to websites, platforms, marketplaces, online groups,
                etc.). Talent who use the Site to redirect other users to other
                sites may have their account terminated without notice and if,
                in Jubal Talents’ sole determination, the redirection was for
                the purpose of circumventing the Jubal Talents payment portal,
                Talent shall pay Jubal Talents liquidated damages in the amount
                of Two Thousand Dollars ($2,000) within thirty (30) days of
                Jubal Talents’ termination of the account, which Talent
                acknowledges and agrees is a reasonable estimate of the damages
                suffered by Jubal Talents.
                <br />A Talent's rating is calculated based on reviews submitted
                by Vendors. “Top Rated” Talents are chosen automatically by
                Jubal Talents based on a list of criteria, number tickets sold,
                numbers of Events Performed, high review ratings, and
                exceptional performances. The “Top Rated” designation may be
                removed by Jubal Talents at any time, with or without notice,
                for any reason.
              </p>
            </div>
            <div className="col-12">
              <h2>6. SERVICE DELIVERY & COMMUNICATIONS</h2>
              <p>
                Quality of Services. All Talents on the Site shall strive to
                deliver professional quality work. Specifically, Talents shall
                ensure that all deliverables are:
              </p>
              <ul>
                <li>Error free.</li>
                <li>
                  Fully address each of the Vendor’s defined requirements.
                </li>
                <li>
                  Completed with the set of deliverables defined in the Event or
                  Service description or as agreed to with the Vendor within the
                  Site as part of the requirements for custom Events.
                </li>
                <li>
                  Consistent with the level of expertise indicated in the
                  Vendor’ skills pages, profile,custom offers, and/or proposals.
                </li>
              </ul>
              <p>
                To complete an Event, Talent must deliver the performance to
                complete the Event and it also should be approved by the Vendor
                <br />
                Third-Party Delivery. Users may use third-party file transfer
                (“Share Links”) to deliver files, including, without limitation,
                Box, Dropbox, WeTransfer, Google Drive, and Elsevier, among
                others. Use of any third-party file transfer application or any
                other site or service other than the Site will be governed by
                the terms of the third-party site.
                <br />
                Communication. By making a Talent or Vendor Account on the Site,
                you acknowledge and understand that we may, on occasion, send
                you communications or data via email regarding our Events,
                services, and promotions. You agree to receive such
                communications from us. Unless such email is necessary to
                facilitate a transaction, such as to complete an Event or
                provide you with information related to a new Event creation, we
                will give you the opportunity to opt-out of receiving these
                commercial emails from us by following the opt-out instructions
                provided in such messages. All communications, information, and
                directions related to an Event shall be performed exclusively
                using the Site and App. Communication outside of www.jubal.com
                regarding Events may result in account suspension and disqualify
                users from the dispute resolution procedure listed below.
                <br />
                Third-Party Content. The Site may contain links to websites
                controlled by third parties. We have no control over the content
                of any third-party site, and these Terms do not govern your use
                of any such third-party site. Additionally, the Site may
                reference products, services, or other information by trade
                name, trademark, or otherwise, and such reference shall not be
                construed as an endorsement, sponsorship, or recommendation of
                or by such third parties unless expressly stated to the
                contrary.
              </p>
            </div>
            <div className="col-12">
              <h2>7. PAYMENTS</h2>
              <p>
                Vendors may not offer, and Talents may not accept, payments
                using any method other than through the Site’s payment portal.
                Requesting or making a payment outside of the Site is a breach
                of these Terms unless and until the express written consent of
                Jubal Talents has been granted. In the event a prohibited
                request or payment occurs, the User’s Account may be subject to
                permanent suspension. Additionally, the User will be liable for
                any loss and legal expenses that Jubal Talents may incur in
                recovering monies duly owed to Jubal Talents related to such
                Project. Users consent to and acknowledge that payments by
                Vendor are routinely checked by Jubal Talents for fraud
                prevention purposes before any payments are released. Jubal
                Talents reserves the right to withhold funds associated with an
                Account in order to recover lost fees. Moreover, User
                acknowledges and agrees that Jubal Talents will not mediate any
                disputes or be liable for loss or damage of any kind arising out
                of or related to a violation of this Agreement. Users must
                report attempts or offers to make a payment outside of the Site
                by the Vendor or Talents to Jubal Talents immediately to (Gmail
                Address).
              </p>
            </div>
            <div className="col-12">
              <h2>8. WITHDRAWING FUNDS</h2>
              <p>
                In order to withdraw funds from the Account, Users are required
                to have a valid Paypal or ? account. Your Account must be able
                to receive incoming payments before withdrawing funds. Your
                Account can only be associated with one Paypal or ? account.
                Revenues derived from Projects are only made available for
                withdrawal after a clearance period of at least three (3) days.
                Funds withdrawn from your Account to your Paypal or ? account
                may be subject to third-party fees. Withdrawals are final and
                cannot be reversed. Jubal Talents will not refund or change the
                withdrawal process once it has begun. Withdrawals will be
                processed within 24 to 48 hours. Users with restricted and/or
                suspended accounts will be able to withdraw their funds after a
                hold period of 45 to 90 days. The duration of the hold period
                will vary depending on the reason for the account restriction
                and/or suspension.
              </p>
            </div>
            <div className="col-12">
              <h2>9. USER COMMENTS</h2>
              <p>
                From time to time, at our discretion, Jubal Talents may allow
                users to submit material to the Site and/or post comments, which
                may include, without limitation, text, images, videos, and
                recordings (individually and jointly, "User Comments") on the
                Site. These User Comments are provided on a non-confidential
                basis and, by submitting User Comments, you are granting us an
                irrevocable, perpetual, worldwide, unrestricted license to
                publicly display, distribute, reproduce, and otherwise use such
                User Comments in any manner we determine, in our sole
                discretion. You represent and warrant that no User Comments
                submitted by you to the Site will violate any right of any third
                party, including without limitation copyright, trademark,
                privacy, or other personal or proprietary rights, or contain
                libelous or otherwise unlawful, abusive, or obscene material.
                You are and shall remain solely responsible for the content of
                any User Comments you make.
                <br />
                Although User Comments may be posted on the Site, the posting of
                those submissions does not in any way constitute our endorsement
                of such User Comments nor is Jubal Talents obligated to post any
                User Comments. We are not responsible or liable for any claim,
                including, without limitation, loss or injury to real, tangible,
                or intellectual property, violations of personal privacy or
                privacy rights, actual, consequential or punitive damages,
                personal injury, or wrongful death made in connection with User
                Comments. We reserve the right to remove any User Comments for
                any or no reason.
              </p>
            </div>
            <div className="col-12">
              <h2>10. COPYRIGHTS; OWNERSHIP AND LIMITATIONS</h2>
              <span className="mb-3 d-block">
                This section impacts your legal rights in and to the Works.
                Please read carefully.
              </span>
              <p>
                Site Ownership. This Site is owned and operated by Jubal
                Talents. Aside from third-party licensed content, all rights,
                title, and interest in and to the materials provided on this
                Site, including, but not limited to, information, documents,
                logos, graphics, sounds, images, audio-visual works, and the
                intellectual property rights therein, including, without
                limitation, copyright and trademark (the “Materials”) are owned
                by us. “Jubal Talents” and “Jubal Talents Logo” are trademarks
                and are the exclusive property of Jubal Talents. Except as
                otherwise expressly provided herein, none of the Materials may
                be copied, reproduced, republished, downloaded, uploaded,
                posted, displayed, transmitted, or distributed in any way, and
                nothing on this Site shall be construed to confer any license
                under any of our intellectual property rights, whether by
                estoppel, implication, or otherwise. Any rights not expressly
                granted herein are reserved by us.
              </p>
              <p>
                Ownership of Works. PLEASE READ CAREFULLY. Unless clearly stated
                otherwise in the Event or Service description, it is intended
                that the results of the Services rendered by Talent (the
                “Works”), including, but not limited to, the copyrights thereto,
                are to be a work made for hire. Talent acknowledges and agrees
                that the Works (and all rights therein, including, without
                limitation, copyright) belong to and shall be the sole and
                exclusive property of Vendor. Vendor shall have the exclusive,
                unrestricted, perpetual right throughout the universe to use,
                distribute, sell, and exploit the Works in any and all media, by
                any and all methods and formats. If, for any reason, a court of
                competent jurisdiction determines that the Works are not a work
                for hire, Talent hereby assigns all right, title, and interest
                in and to the Works and the copyrights therein to Vendor <br />
                Independent Counsel. User hereby acknowledges and agrees that it
                is User’s responsibility to seek legal advice regarding the
                ownership of intellectual property rights and whether the grant
                of rights contained herein is adequate for User’s needs. User
                further acknowledges and agrees that Jubal Talent is not liable
                for a User’s failure to perform due diligence, neglecting to
                seek legal advice, or lack of understanding. In the event that
                User desires any additional documentation regarding the rights
                to the Works, it shall be User’s sole responsibility to obtain
                such documentation.
              </p>
              <p>
                Warranty and Representation. User warrants and represents that
                all materials furnished by User in connection with User’s use of
                the Site are or will be original to User or in the public domain
                throughout the world or used with the consent of the original
                owner thereof, and shall not infringe upon or violate any
                copyright, trademark, the right of privacy, or any other right
                of any kind or nature of any person or entity. User further
                warrants that User has properly secured, at User’s sole cost and
                expense, any and all permits, licenses, permissions, and
                insurance policies that a reasonably prudent person would secure
                in connection with User’s use of the Site. User hereby
                indemnifies Jubal Talents and undertakes to defend Jubal Talents
                against and hold Jubal talents harmless (including, without
                limitation, settlement amounts agreed to by Jubal Talents in its
                sole discretion, attorneys’ fees, and costs) from any and all
                claims and/or damage arising out of or related to User’s use of
                the Site and/or a breach of User’s warranties, covenants,
                representations, or agreements as provided herein.
              </p>
              <p>
                Reporting. The Site contains User Generated Content (UGC). Jubal
                Talents does not check UGC for violations of copyrights,
                trademarks, and/or other intellectual property rights. If you
                suspect violations of any rights outlined in this section,
                please contact the support team at (Gmail Address). Any content
                reportedly violating intellectual property rights will be
                removed immediately in accordance with Jubal Talents’ DMCA
                Copyright Policy herein below.
              </p>
            </div>
            <div className="col-12">
              <h2>11. DISCLAIMER OF WARRANTY</h2>
              <p>
                YOU EXPRESSLY AGREE THAT YOUR USE OF THE SITE IS AT YOUR SOLE
                RISK. THE SITE, AND ALL MATERIALS PROVIDED ON OR THROUGH YOUR
                USE OF THE SITE OR APPLICATIONS, ARE PROVIDED ON AN “AS IS” AND
                “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
                EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
                OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT: (A) THE SITE,
                SERVICES, OR MATERIALS WILL MEET YOUR REQUIREMENTS; (B) THE
                SITE, SERVICES, OR MATERIALS WILL BE AVAILABLE ON AN
                UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; (C) THE
                RESULTS THAT MAY BE OBTAINED FROM USE OF THE SITE, OR ANY
                SERVICES OR MATERIALS OFFERED THROUGH THE SITE, WILL BE ACCURATE
                OR RELIABLE; (D) THE QUALITY OF ANY PRODUCTS, SERVICES,
                INFORMATION, OR OTHER MATERIAL OR SERVICES PURCHASED OR OBTAINED
                BY YOU THROUGH THE SITE, OR IN RELIANCE ON THE MATERIALS OR
                SERVICES, WILL MEET YOUR EXPECTATIONS; OR (E) THAT THE MATERIALS
                ARE FREE FROM INFRINGEMENT OF ANY KIND OR NATURE. OBTAINING ANY
                MATERIALS OR SERVICES THROUGH USE OF THE SITE IS DONE AT YOUR
                OWN DISCRETION AND AT YOUR OWN RISK. WE SHALL HAVE NO
                RESPONSIBILITY FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF
                DATA THAT RESULTS FROM THE DOWNLOAD OF ANY CONTENT, MATERIALS,
                OR INFORMATION. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, IN NO
                EVENT SHALL WE AND/OR OUR OFFICERS, OWNERS, DIRECTORS,
                EMPLOYEES, AND/OR AGENTS BE LIABLE TO YOU FOR ANY INDIRECT,
                PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER
                IT ARISES, INCLUDING, WITHOUT LIMITATION, ANY CLAIM FOR PERSONAL
                INJURY, PROPERTY DAMAGE, LOST PROFITS, LOSS OF DATA, LOSS OF
                GOODWILL, WORK STOPPAGE, COST OF SUBSTITUTE GOODS OR SERVICES,
                COMPUTER AND/OR DEVICE OR TECHNOLOGY FAILURE OR ANY ALLEGED
                COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION
                OF PROPERTY, AND/OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR
                USE OF OR POSTING OF ANY RECORD, CONTENT, OR TECHNOLOGY,
                PERTAINING TO OR ON THE SITE. YOU AGREE THAT THIS LIMITATION OF
                LIABILITY APPLIES WHETHER SUCH ALLEGATIONS ARE FOR BREACH OF
                CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, OR FALL UNDER ANY OTHER
                CAUSE OF ACTION, REGARDLESS OF THE BASIS UPON WHICH LIABILITY IS
                CLAIMED AND EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                SUCH LOSS OR DAMAGE.
              </p>
            </div>
            <div className="col-12">
              <h2>12. INTERPRETATION</h2>
              <p>
                These Terms were written in English (U.S.). To the extent any
                translated version of this Agreement conflicts with the English
                version, the English version controls.
              </p>
            </div>
            <div className="col-12">
              <h2>13. STATEMENT OF RIGHTS AND RESPONSIBILITIES</h2>
              <p>
                These Terms govern our relationship with you and others who
                interact with the Site as well as other products and Services.
                By using or accessing the Site or our Services, you agree to
                these Terms, as updated from time to time.
              </p>
            </div>
            <div className="col-12">
              <h2>14. PRIVACY</h2>
              <p>
                Your privacy is very important to us. We designed our Privacy
                Policy to make important disclosures about how you can use the
                Site and how we collect and can use the content and information
                you share with us. We encourage you to read the Privacy Policy
                and to use it to help you make informed decisions.
              </p>
            </div>
            <div className="col-12">
              <h2>15. ADVERTISING</h2>
              <p>
                We may run advertisements and promotions from third parties on
                the Site. Your business dealings or correspondence with, or
                participation in promotions of, advertisers other than us, and
                any terms, conditions, warranties, or representations associated
                with such dealings, are solely between you and such third party.
                We are not responsible or liable for any loss or damage of any
                sort incurred by you as the result of any such dealings or as
                the result of the presence of third-party advertisers on the
                Site.
              </p>
            </div>
            <div className="col-12">
              <h2>16. MOBILE AND OTHER DEVICES</h2>
              <p>
                We currently provide our Site and App, but please be aware that
                your carrier's normal rates and fees, such as data charges, will
                still apply.
              </p>
            </div>
            <div className="col-12">
              <h2>17. TERMINATION</h2>
              <p>
                If you violate the letter or spirit of these Terms, or otherwise
                create risk or possible legal exposure for us, we can stop
                providing all or part of the Site, App and/or Services to you.
              </p>
            </div>
            <div className="col-12">
              <h2>18. DISPUTES</h2>
              <p>
                Vendor and Talent Disputes. Jubal Talents encourages Vendors and
                Talents to resolve conflicts amongst themselves. If for any
                reason this fails, disputes between Vendor and Talent should be
                handled using the Site’s dispute resolution tools, by contacting
                the Jubal Talents customer support team at (Gmail Address),
                while the Event is still in process. Disputes must be filed
                before an Event is marked completed. Once a dispute is filed,
                Vendor and Talent must cease all communication between one
                another and Jubal Talents will attempt to mediate the dispute.
                Jubal Talents requires a period of up to fourteen (14) days to
                review all order communications, deliverables, and details
                before mediation begins. Disputes with Jubal Talents. Users
                acknowledge and agree that any payments and/or other actions
                made by Jubal Talents in accordance with the resolution process
                of a dispute are made in good faith and Users shall have no
                cause of action against Jubal Talents (whether in contract,
                tort, or otherwise) in connection with the resolution of a
                dispute or any actions or payments made pursuant to it. Users
                further acknowledge and agree that Jubal Talents is not and
                shall not be liable for any loss, damage, or harm arising out of
                or related to the Site, Services, or interactions with a Vendor,
                Talent, or other user of the Site. This Agreement, its validity,
                its construction, and all matters relating to the relationship
                between the parties shall be governed by the U.S. Federal law.
                If either party retains an attorney to enforce or litigate this
                Agreement, its validity, or any provision hereof, the prevailing
                party shall be entitled to all reasonable attorneys’ fees,
                costs, and necessary disbursements incurred through appeal. This
                Agreement shall bind and insure to the benefit of the parties
                hereto and their respective assigns. In the event of litigation,
                exclusive jurisdiction and venue shall rest in the state or
                federal courts.
              </p>
            </div>
            <div className="col-12">
              <h2>19. MISCELLANEOUS</h2>
              <p>
                The Terms and Privacy Policy constitute the entire Agreement
                between you and us and supersede all previous written or verbal
                agreements between you and us with respect to the subject matter
                herein. If any portion of these Terms are found to be
                unenforceable, the remaining portions will remain in full force
                and effect, and the invalid or unenforceable term shall be
                deemed replaced by a term that is valid and enforceable and that
                comes closest to expressing the intention of such invalid or
                unenforceable term. If we fail to enforce any of these Terms, it
                will not be considered a waiver. The section headings contained
                herein are for convenience only and shall not impact the meaning
                or effect on the content of the Terms. The Terms and Privacy
                Policy shall be interpreted to give them fair meaning, and any
                ambiguity shall not be construed against either party as the
                primary drafter hereof. Any amendment to or waiver of these
                Terms shall be unenforceable unless made in writing and signed
                by us. You may not transfer or assign any of your rights or
                obligations under these Terms without our written consent first
                being obtained. All of our rights and obligations under these
                Terms are freely assignable by us in connection with a merger,
                acquisition, or sale of assets, or by operation of law or
                otherwise. Nothing in these Terms shall prevent us from
                complying with the law. These Terms do not confer any
                third-party beneficiary rights. We reserve all rights not
                expressly granted herein. You will comply with all applicable
                laws when using or accessing the Site. If you access the Site
                from outside the United States, you accept full responsibility
                for compliance with local laws.
              </p>
            </div>
            <div className="col-12">
              <h2>20. FEEDBACK</h2>
              <p>
                We welcome and encourage you to provide feedback, comments, and
                suggestions for improvements to the Site (“Feedback“). You may
                submit Feedback by sending a message via the “Contact” section
                of the Site or emailing (Gmail Address). Any Feedback you submit
                to us will be considered non-confidential and non-proprietary to
                you. By submitting Feedback to us, you grant us a non-exclusive,
                worldwide, royalty-free, irrevocable, sub-licensable, perpetual
                license to use and publish those ideas and materials for any
                purpose, without compensation to you.
              </p>
            </div>
            <div className="col-12">
              <h2>21. DMCA POLICY</h2>
              <p>
                Jubal Talents respects the intellectual property rights of
                others and expects its users to do the same. In accordance with
                the Digital Millennium Copyright Act of 1998, the text of which
                may be found on the U.S. Copyright Office website at
                http://www.copyright.gov/legislation/dmca.pdf, Jubal Talents
                will respond expeditiously to claims of copyright infringement
                committed using Jubal Talents’ Service(s) and/or the Site, if
                such claims are reported to Jubal Talents’ Designated Copyright
                Agent identified in the sample notice below. If you are a
                copyright owner, authorized to act on behalf of one, or
                authorized to act under any exclusive right under copyright,
                please report alleged copyright infringements taking place on or
                through the Site by completing the following DMCA Notice of
                Alleged Infringement and delivering it to Jubal talents’
                Designated Copyright Agent. Upon receipt of Notice, as described
                below, Jubal Talents will take whatever action, in its sole
                discretion, it deems appropriate, including removal of the
                challenged content from the Site.
                <br />
                DMCA Notice of Alleged Infringement (“Notice”)
              </p>
              <ul>
                <li>
                  Identify the copyrighted work that you claim has been
                  infringed, or - if multiple copyrighted works are covered by
                  this Notice - you may provide a representative list of the
                  copyrighted works that you claim have been infringed.
                </li>
                <li>
                  Identify the material or link you claim is infringing (or the
                  subject of infringing activity) and to which access is to be
                  disabled, including at a minimum, if applicable, the URL of
                  the link shown on the Site or the exact location where such
                  material may be found.
                </li>
                <li>
                  Provide your company affiliation (if applicable), mailing
                  address, telephone number, and, if available, email address.
                </li>
                <li>
                  Include both of the following statements in the body of the
                  Notice:
                  <ul>
                    <li>
                      “I hereby state that I have a good faith belief that the
                      disputed use of the copyrighted material is not authorized
                      by the copyright owner, its agent, or the law (e.g., as a
                      fair use).
                    </li>
                    <li>
                      “I hereby state that the information in this Notice is
                      accurate and, under penalty of perjury, that I am the
                      owner, or authorized to act on behalf of the owner, of the
                      copyright or of an exclusive right under the copyright
                      that is allegedly infringed.”
                    </li>
                  </ul>
                </li>
                <li>
                  Provide your full legal name and your electronic or physical
                  signature.
                </li>
              </ul>
              <p>
                Deliver this Notice, with all items completed, to Jubal Talents’
                Email: (Gmail Address)
              </p>
            </div>
          </div>
        </div>
      </section>
      <HomeFooter />
    </>
  );
}
