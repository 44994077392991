/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import DashboardLayout from "../../Components/Layout/DashboardLayout";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import MobileHeader from "../../Components/Layout/MobileHeader";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { useDispatch, useSelector } from "react-redux";
import { addItemToCart } from "../../store/actions/cartActions";
import { toast } from "material-react-toastify";
import { openCartModal } from "../../store/actions/cartModalActions";
import { SvgIcons } from "../../Components/Svg/SvgIcons";
import ShareModal from "../../Components/Modals/ShareModal";
import ReactOwlCarousel from "react-owl-carousel";
import SearchBarLoader from "../../Helper/SearchBarLoader";
import { RoutesLink } from "../../utility/RoutesLink";

function InstrumentsDetails() {
  const navigate = useNavigate();
  // State for controlling the create post modal visibility
  const [createPostModal, setCreatePostModal] = useState(false);
  const { id: urlId } = useParams(); // Get id from the URL if present
  // Get current location using the useLocation hook
  const location = useLocation();

  // State to store all instrument data
  const [AllInstrumentsData, setAllInstrumentsData] = useState(null);

  // Get cart items from Redux state
  const items = useSelector((state) => state.cartReducer.items);

  // State to manage the quantity of an item (default is 1)
  const [Quantity, setQuantity] = useState(1);

  // State to store instrument details
  const [InstruemtnDetials, setInstruemtnDetials] = useState({});
  const [loading, setLoading] = useState(true);

  // State and function for managing modal visibility
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // Get liked instruments from the Redux state
  const AllInstrument = useSelector(
    (state) => state?.user?.SaveInstrument_Likes
  );

  // Get user details from Redux state
  const userDetails = useSelector((store) => store?.user?.userDetails);

  // useEffect hook to fetch instrument details by ID when the component mounts or instrument data changes

  useEffect(() => {
    const instrumentId = urlId || location?.state?.instrumentdata;

    if (instrumentId) {
      // Fetch instrument details using either the URL ID or location.state ID
      handleFetchRequest(ApiUrl.get_instruments_by_id + instrumentId).then(
        (res) => {
          if (res.status === true) {
            setInstruemtnDetials(res?.data);
            console.log("instrument", res?.data);
            setLoading(false);
          } else {
            toast.error("instrument Not Found");
            setTimeout(() => {
              navigate(RoutesLink.dashboard);
            }, 2000);
          }
        }
      );
    }
  }, [urlId, location?.state?.instrumentdata]);

  // Function to toggle the create post modal
  const postToggle = () => setCreatePostModal(!createPostModal);

  // Redux dispatch function
  const dispatch = useDispatch();

  // Function to map and update an array of data with certain conditions
  const DataMapHandler = (Arr, id, object, condition, objLabel, objCount) => {
    const newData = Arr?.map((item) => {
      if (item?.id === id) {
        return {
          ...item,
          [objLabel]: objCount,
          [object]: !condition,
        };
      } else {
        return item;
      }
    });
    return newData;
  };

  // Function to handle liking an instrument
  const GetHandleLike = (currentDetails) => {
    // Optimistically update the state immediately
    const newLikeStatus = currentDetails.isLikedByMe === 1 ? 0 : 1;
    const likeDifference = newLikeStatus === 1 ? 1 : -1;
    setInstruemtnDetials((prevDetails) => ({
      ...prevDetails,
      isLikedByMe: newLikeStatus,
      likes: prevDetails.likes + likeDifference,
    }));
    // Make the API call in the background
    handleFetchRequest(
      `${ApiUrl.get_instruments_like}${currentDetails?.id}`
    ).then((response) => {
      if (!response.success) {
        // Revert state back to original if API fails
      }
    });
  };

  // Function to handle saving an instrument
  const savePost = (currentDetails) => {
    // Optimistically update the state immediately
    const newLikeStatus = currentDetails.isSavedByMe === true ? false : true;
    setInstruemtnDetials((prevDetails) => ({
      ...prevDetails,
      isSavedByMe: newLikeStatus,
    }));
    // Make the API call in the background
    handleFetchRequest(ApiUrl?.get_instruments_saved + currentDetails?.id).then(
      (response) => {
        // If the API call fails, revert the state back
        if (!response.success) {
          // Revert state back to original if API fails
        }
      }
    );
  };

  // Function to check if the event was created by the current user
  const checkIscreateByME = (eventDetials, id) => {
    // Return false if the item was created by you
    if (eventDetials && eventDetials?.User?.id === userDetails?.id) {
      return false;
    }
  };

  // Function to check if data already exists in the cart
  const checkDataExists = (eventDetials, id) => {
    const item = items?.find((item) => item?.id === id);
    const isPresent = item !== undefined;

    return isPresent;
  };

  // Function to handle buying a ticket
  const BuyTicket = (EventDetails, id) => {
    const data = {
      itemId: id,
      itemType: "instrument", //event
      quantity: Quantity,
    };
    handleFetchRequest(`${ApiUrl.add_cart_data}/`, "POST", data).then(
      (response) => {
        if (response.status === true) {
          console.log("ressss", response.data);
          // Add the item to the cart in the Redux store
          dispatch(addItemToCart(EventDetails));
          openModal();
          toast.done(response.message);
        }
      }
    );
  };

  // Function to open the cart modal
  const openModal = () => {
    dispatch(openCartModal());
  };

  // Function to report an instrument
  const report = () => {
    const data = {
      report: "reason",
    };
    handleFetchRequest(
      `${ApiUrl.report}${AllInstrumentsData?.id}`,
      "POST",
      data
    ).then((response) => {
      if (response.status === true) {
        toast.success("Instrument reported");
      }
    });
  };

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <DashboardLayout>
        <DashboardSidebar postToggle={postToggle} />

        {/* {loading == true && <SearchBarLoader />} */}

        <div className="center-content d-block">
          {loading === true ? (
            <SearchBarLoader />
          ) : (
            <div className="card post-card event-post instruments-detail-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-lg-5 p-0 pe-lg-5 d-flex flex-column align-items-strech">
                    <div className="content  d-flex align-items-center justify-content-center">
                      <ReactOwlCarousel
                        items={1}
                        dots={false}
                        nav={true}
                        navText={[
                          "<span class='text-white slick-prev'></span>",
                          "<span class='text-white slick-next'></span>",
                        ]}
                      >
                        {InstruemtnDetials?.instrument_image?.map(
                          (image, index) => (
                            <img
                              key={index}
                              src={image}
                              alt={InstruemtnDetials?.instrument_name}
                              className="rounded-0"
                            />
                          )
                        )}
                      </ReactOwlCarousel>
                      {/* </div> */}
                    </div>

                    <div className="user-activity d-flex justify-content-between mt-4">
                      <div className="d-flex">
                        <span
                          className="liked d-none d-lg-block"
                          data-bs-toggle="modal"
                          data-bs-target="#likes"
                        >
                          {InstruemtnDetials?.likes} likes
                        </span>
                        <span
                          className="liked d-lg-none"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvaslikes"
                          aria-controls="offcanvaslikes"
                        >
                          {InstruemtnDetials?.isLikedByMe} likes
                        </span>
                      </div>
                      <div className="d-flex user-activity">
                        <span
                          className={`like ${
                            InstruemtnDetials?.isLikedByMe ? "activated" : ""
                          }`}
                          onClick={() => GetHandleLike(InstruemtnDetials)}
                        >
                          {SvgIcons?.likeIcon}
                        </span>
                        <span
                          className={`save ${
                            InstruemtnDetials?.isSavedByMe ? "activated" : ""
                          }`}
                          onClick={() => savePost(InstruemtnDetials)}
                        >
                          {SvgIcons?.saveIcon}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 col-lg-7">
                    <div className="user-det-head d-flex justify-content-between align-items-start">
                      <div className="user-det d-flex gap-3">
                        <div className="user-ac">
                          <div className="d-flex gap-2 align-items-center">
                            <span className="username">
                              <Link
                                to={`/profile/${InstruemtnDetials?.User?.id}`}
                              >
                                {InstruemtnDetials?.User?.firstName}{" "}
                                {InstruemtnDetials?.User?.lastName}
                              </Link>
                            </span>
                            <span
                              className={
                                InstruemtnDetials?.User?.type === 3
                                  ? "usertype talent mx-1"
                                  : "usertype vendor mx-1"
                              }
                            >
                              <span>{SvgIcons?.talentIcon}</span>
                              {InstruemtnDetials?.User?.type === 3
                                ? "Talent"
                                : "Vendor"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="post-det d-flex align-items-center gap-1 gap-1">
                        <span className="post-time">
                          {" "}
                          {/* {calculateTimeAgo(instrumentDetails?.createdAt)}{" "} */}
                        </span>
                        {InstruemtnDetials?.quantity < 1 && (
                          <span className="text-danger">Out of sotck</span>
                        )}
                        {/* <div className="dropdown dropdown-menu-end">
                        <Link
                          className="dropdown-toggle"
                          to="#"
                          role="button"
                          data-bs-toggle="dropdown"
                        >
                          <svg
                            width={31}
                            height={15}
                            viewBox="0 0 31 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.3859 7.59648C10.3859 6.26906 9.3098 5.19297 7.98237 5.19297C6.65495 5.19297 5.57886 6.26906 5.57886 7.59648C5.57886 8.92391 6.65495 10 7.98237 10C9.3098 10 10.3859 8.92391 10.3859 7.59648Z"
                              fill="#343B4E"
                            />
                            <path
                              d="M18.193 7.59648C18.193 6.26906 17.1169 5.19297 15.7895 5.19297C14.4621 5.19297 13.386 6.26906 13.386 7.59648C13.386 8.92391 14.4621 10 15.7895 10C17.1169 10 18.193 8.92391 18.193 7.59648Z"
                              fill="#343B4E"
                            />
                            <path
                              d="M25.9999 7.59648C25.9999 6.26906 24.9238 5.19297 23.5964 5.19297C22.269 5.19297 21.1929 6.26906 21.1929 7.59648C21.1929 8.92391 22.269 10 23.5964 10C24.9238 10 25.9999 8.92391 25.9999 7.59648Z"
                              fill="#343B4E"
                            />
                          </svg>
                        </Link>

                        <ul className="dropdown-menu post-dropdown">
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => toggle(AllInstrument?.id)}
                            >
                              Share Instrument
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => report()}
                            >
                              Report Instrument
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                      </div>
                    </div>

                    <div className="event-caption my-5">
                      <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between align-items-start gap-2">
                          <div className="d-flex flex-column gap-1">
                            <h6 className="mb-2">
                              {InstruemtnDetials?.instrument_name}
                            </h6>
                            <p>{InstruemtnDetials?.primaryCategory}</p>
                          </div>
                          <h5>${InstruemtnDetials?.selling_price}</h5>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-12 p-0">
                        <h6>Description</h6>
                        <p>{InstruemtnDetials?.about}</p>
                      </div>

                      <div className="col-12 p-0">
                        <h6>Features</h6>
                        <ul className="list-style">
                          {InstruemtnDetials?.features &&
                            InstruemtnDetials?.features?.map(
                              (feature, index) => <li key={index}>{feature}</li>
                            )}
                        </ul>
                      </div>
                      <div className="col-12 p-0">
                        <h6>Condition</h6>
                        <p>{InstruemtnDetials?.condition}</p>
                      </div>
                      {console.log(
                        " InstruemtnDetials?.User?.id == userDetails?.id",
                        InstruemtnDetials?.User?.id == userDetails?.id
                      )}
                      <div className="col-12 p-0">
                        {!checkDataExists(
                          InstruemtnDetials,
                          InstruemtnDetials?.id
                        ) ? (
                          <button
                            onClick={() =>
                              BuyTicket(
                                InstruemtnDetials,
                                InstruemtnDetials?.id
                              )
                            }
                            disabled={
                              InstruemtnDetials?.quantity < 1 ||
                              InstruemtnDetials?.User?.id == userDetails?.id
                            }
                            className="btn btn-primary w-100 text-white d-flex align-items-center justify-content-center gap-3"
                          >
                            <span>{SvgIcons?.cartIcon}</span> Add to cart
                          </button>
                        ) : (
                          <button
                            onClick={() => openModal()}
                            className="btn btn-primary w-100 text-white d-flex align-items-center justify-content-center gap-3"
                          >
                            <span>{SvgIcons?.cartIcon}</span> Go to cart
                          </button>
                        )}
                        {/* <Link
                        to="#"
                        // onClick={addToCart}
                        className="btn btn-primary w-100 text-white d-flex align-items-center justify-content-center gap-3"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M1.75 1.75H4.75L6.76 11.7925C6.82858 12.1378 7.01643 12.448 7.29066 12.6687C7.56489 12.8895 7.90802 13.0067 8.26 13H15.55C15.902 13.0067 16.2451 12.8895 16.5193 12.6687C16.7936 12.448 16.9814 12.1378 17.05 11.7925L18.25 5.5H5.5M16.75 16.75C16.75 17.1642 16.4142 17.5 16 17.5C15.5858 17.5 15.25 17.1642 15.25 16.75C15.25 16.3358 15.5858 16 16 16C16.4142 16 16.75 16.3358 16.75 16.75ZM8.5 16.75C8.5 17.1642 8.16421 17.5 7.75 17.5C7.33579 17.5 7 17.1642 7 16.75C7 16.3358 7.33579 16 7.75 16C8.16421 16 8.5 16.3358 8.5 16.75Z"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        Add to cart
                      </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </DashboardLayout>
      <ShareModal
        modal={modal}
        toggle={() => toggle()}
        postId={InstruemtnDetials?.id}
        type="instrument"
      />
    </>
  );
}

export default InstrumentsDetails;
