// Importing necessary libraries and modules
import Cookies from "js-cookie"; // For handling browser cookies
// import moment from "moment";   // Uncomment if you need to use moment for time manipulation
// import Swal from "sweetalert2"; // Uncomment if you need SweetAlert for alerts
import store from "../store/index"; // Redux store
import { setUserLoggedInDetails } from "../store/actions/UserActions"; // Redux action to set user login details
import { RoutesLink } from "../utility/RoutesLink"; // Link routes (use as necessary)

// Base server URL for API requests
export const serverUrl = "https://dev.zidni.academy/";

// Function to handle fetch requests
export async function handleFetchRequest(
  url, // API endpoint URL
  method = "GET", // HTTP method (default is GET)
  data = null, // Request payload data (if any)
  password, // Optional password for authorization
  formData // Optional form data for file uploads
) {
  let tokens = null; // Variable to store the user's access token
  let headers = {}; // Object to store request headers
  let requestOptions = {}; // Object to store fetch request options

  // Check if a valid token is stored in cookies
  if (Cookies.get("jubal_web_access")) {
    tokens = Cookies.get("jubal_web_access"); // Get the access token from cookies
  }

  // If no token is found, redirect user to the sign-in page (uncomment if needed)
  // if (!tokens) {
  //   window.location.href = "/jubal_web/sign-in";
  // }

  // If token exists and neither password nor formData is provided
  if (tokens && !password && !formData) {
    // Set headers for standard API requests
    headers = {
      Accept: "application/json", // Accept JSON response
      Authorization: "Bearer " + tokens, // Bearer token for authorization
      "Accept-Language": "en", // Language setting
      "allow-access-origin": "*", // CORS handling
      "X-localization": "en", // Localization settings
      // Uncomment if you're using ngrok or similar service to bypass warnings
      // 'ngrok-skip-browser-warning': 'true',
      "Content-Type": "application/json", // JSON content type
    };

    // Set request options
    requestOptions = {
      method: method, // HTTP method
      headers: headers, // Headers
      body: data ? JSON.stringify(data) : null, // Body: JSON stringified data if provided
    };
  }
  // If password is provided, likely for user login or protected actions
  else if (password) {
    headers = {
      Authorization: "Bearer " + tokens, // Bearer token
      "Content-Type": "application/json", // JSON content type
      "X-localization": "en", // Localization settings
      ...password, // Add password to headers
      Accept: "application/json", // Accept JSON response
      "allow-access-origin": "*", // CORS handling
      // Uncomment if you're using ngrok or similar service
      // 'ngrok-skip-browser-warning': 'true',
    };

    // Set request options for password-based requests
    requestOptions = {
      method: method, // HTTP method
      headers: headers, // Headers
      body: data ? JSON.stringify(data) : null, // Body: JSON stringified data
    };
  }
  // If formData is provided, typically for file uploads
  else if (formData) {
    headers = {
      Authorization: "Bearer " + tokens, // Bearer token
      "Accept-Language": "en", // Language settings
      // Uncomment if you need to specify form data content type
      // 'Content-Type': `multipart/form-data`,
      // Uncomment if you're using ngrok or similar service
      // 'ngrok-skip-browser-warning': 'true',
      "allow-access-origin": "*", // CORS handling
      "X-localization": "en", // Localization settings
    };
    // Set request options for form data requests
    requestOptions = {
      method: method, // HTTP method
      headers: headers, // Headers
      body: formData || null, // FormData object (if provided)
    };
  }
  // Default headers for non-token, non-form, and non-password requests
  else {
    headers = {
      Accept: "application/json", // Accept JSON response
      "Accept-Language": "en", // Language setting
      "allow-access-origin": "*", // CORS handling
      "X-localization": "en", // Localization settings
      // Uncomment if you're using ngrok or similar service
      // 'ngrok-skip-browser-warning': 'true',
      "Content-Type": "application/json", // JSON content type
    };

    // Set request options for simple GET or POST requests
    requestOptions = {
      method: method, // HTTP method
      headers: headers, // Headers
      body: data ? JSON.stringify(data) : null, // Body: JSON stringified data
    };
  }

  try {
    // Log request options for debugging (can be uncommented during development)
    // console.log("requestOption ", requestOptions);

    // Make the fetch request using the server URL and options
    const response = await fetch(serverUrl + url, requestOptions);

    // Parse the response as JSON
    const result = await response.json();

    // Handle expired token scenario (status code 500, JWT expired)
    if (result.statusCode == 500 && result.message == "jwt expired") {
      // Remove expired token and clear user session data
      Cookies.remove("jubal_web_access");
      Cookies.remove("jubal_web_data");
      store.dispatch(setUserLoggedInDetails({})); // Reset Redux user state
      window.location.href = process.env.PUBLIC_URL; // Redirect to the home page
      // Uncomment if you need to reload the page
      // var url = window.location.toString();
      // window.location = url.replace(window.location.href);
    }

    return result; // Return the result of the API call
  } catch (error) {
    console.error("Error:", error.message); // Log errors to console
    throw error; // Rethrow the error to be handled elsewhere
  }
}
