import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import { useEffect, useState } from "react";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import moment from "moment";
import { RoutesLink } from "../../utility/RoutesLink";
import { useNavigate } from "react-router-dom";

export default function MyOrder() {
  const [myPlans, setMyPlans] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const daysLeft = moment(myPlans?.activePlan?.planExpiredAt).diff(
    moment(),
    "days"
  );

  const getPlans = async () => {
    await handleFetchRequest(ApiUrl?.my_plans).then((response) => {
      if (response.status == true) {
        setMyPlans(response?.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getPlans();
  }, []);
  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <section className="px-0 main-content">
        <div className="max-width-container">
          {/* =========================== */}
          {/* Main feed grid Start */}
          {/* =========================== */}
          <div className="main-feed-grid">
            {/* =========================== */}
            {/*left side content Start */}
            {/* =========================== */}
            <DashboardSidebar />
            {/* =========================== */}
            {/*left side content end */}
            {/* =========================== */}
            {/* =========================== */}
            {/* center content Start */}
            {/* =========================== */}
            <div className="center-content d-block">
              <div className="card tab-card activity-card manage-card">
                <div className="card-body">
                  <div className="row">
                    {/* {myPlans?.activePlan ?  */}
                    <div className="col-12 p-0">
                      <div className="filter-row position-relative gap-1">
                        <div className="d-flex align-items-center justify-content-between w-100  flex-wrap row-gap-3">
                          <p>Active plans</p>
                          <div className="d-flex align-items-center gap-3"></div>
                        </div>
                        <p className="desc">Review your active plan status</p>
                      </div>
                      {myPlans?.activePlan ? (
                        <div className="card resource-card my-order-detail-card plan-card">
                          <div className="top-head d-block">
                            {daysLeft > 1 && (
                              <h3>
                                You still have{" "}
                                {moment(
                                  myPlans?.activePlan?.planExpiredAt
                                ).diff(moment(), "days")}{" "}
                                days left in your standard plan
                              </h3>
                            )}
                            {daysLeft == 1 && (
                              <span className="plan-exp">
                                <svg
                                  width="44"
                                  height="44"
                                  viewBox="0 0 44 44"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="22"
                                    cy="22"
                                    r="21.5"
                                    fill="#FB9804"
                                    fill-opacity="0.22"
                                    stroke="#FB7704"
                                  />
                                  <path
                                    d="M20.7176 15.8959L14.3651 26.5009C14.2341 26.7277 14.1648 26.9849 14.1641 27.2468C14.1633 27.5087 14.2312 27.7662 14.3609 27.9938C14.4906 28.2213 14.6776 28.4109 14.9034 28.5438C15.1291 28.6766 15.3857 28.748 15.6476 28.7509H28.3526C28.6145 28.748 28.8711 28.6766 29.0968 28.5438C29.3225 28.4109 29.5096 28.2213 29.6393 27.9938C29.769 27.7662 29.8368 27.5087 29.8361 27.2468C29.8354 26.9849 29.7661 26.7277 29.6351 26.5009L23.2826 15.8959C23.1489 15.6755 22.9606 15.4932 22.736 15.3667C22.5113 15.2403 22.2579 15.1738 22.0001 15.1738C21.7423 15.1738 21.4888 15.2403 21.2642 15.3667C21.0395 15.4932 20.8513 15.6755 20.7176 15.8959V15.8959Z"
                                    stroke="#FB6304"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M22 19.75V22.75"
                                    stroke="#FB6304"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M22 25.75H22.0067"
                                    stroke="#FB6304"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                Your membership is about to expire! You have 1
                                day left to upgrade and continue enjoying
                                uninterrupted access to our services. Don't miss
                                out—upgrade now!
                              </span>
                            )}
                            <div className="current-plan">
                              <div className="d-flex align-items-center gap-3 gap-lg-4">
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/plan.svg`}
                                  alt=""
                                ></img>
                                <div className="d-flex align-items-start gap-3s gap-lg-5">
                                  <div>
                                    <h5>
                                      {myPlans?.activePlan?.Plan?.planName}
                                    </h5>
                                    <p>Billed monthly</p>
                                  </div>
                                  <h5>
                                    ${myPlans?.activePlan?.Plan?.price}{" "}
                                    <span>/mo</span>
                                  </h5>
                                </div>
                              </div>
                              {daysLeft === 1 ? (
                                <span className="badge cancelled">
                                  Upgrade plan
                                </span>
                              ) : (
                                <span className="badge cancelled">
                                  Standard plan
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="card-body">
                            <h4>Included srvices</h4>
                            <ul className="services">
                              {myPlans?.activePlan?.Plan?.features?.map(
                                (feature, index) => (
                                  <li key={index}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="10"
                                      viewBox="0 0 14 10"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.11539 10C4.99572 9.99963 4.87741 9.97586 4.7678 9.93015C4.6582 9.88444 4.55965 9.81778 4.47827 9.7343L0.236577 5.44166C0.0780167 5.28091 -0.00692349 5.06682 0.000441987 4.84648C0.00780746 4.62615 0.106875 4.41763 0.275852 4.26679C0.444828 4.11594 0.669872 4.03514 0.901475 4.04214C1.13308 4.04915 1.35227 4.1434 1.51083 4.30415L5.10667 7.94916L12.4467 0.310412C12.5212 0.22217 12.6139 0.149346 12.719 0.0964136C12.8242 0.043481 12.9396 0.0115572 13.0582 0.00260293C13.1768 -0.0063513 13.296 0.00785317 13.4086 0.0443437C13.5212 0.0808342 13.6248 0.13884 13.713 0.2148C13.8012 0.290759 13.8721 0.383067 13.9215 0.486056C13.9708 0.589044 13.9974 0.700538 13.9998 0.813688C14.0022 0.926839 13.9802 1.03926 13.9353 1.14404C13.8903 1.24882 13.8233 1.34376 13.7384 1.42301L5.76125 9.726C5.68064 9.81099 5.58243 9.8792 5.47279 9.92635C5.36315 9.9735 5.24444 9.99858 5.12412 10H5.11539Z"
                                        fill="#90B10C"
                                      />
                                    </svg>{" "}
                                    {feature}
                                  </li>
                                )
                              )}
                              {/* <li>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="10"
                                viewBox="0 0 14 10"
                                fill="none"
                              >
                                <path
                                  d="M5.11539 10C4.99572 9.99963 4.87741 9.97586 4.7678 9.93015C4.6582 9.88444 4.55965 9.81778 4.47827 9.7343L0.236577 5.44166C0.0780167 5.28091 -0.00692349 5.06682 0.000441987 4.84648C0.00780746 4.62615 0.106875 4.41763 0.275852 4.26679C0.444828 4.11594 0.669872 4.03514 0.901475 4.04214C1.13308 4.04915 1.35227 4.1434 1.51083 4.30415L5.10667 7.94916L12.4467 0.310412C12.5212 0.22217 12.6139 0.149346 12.719 0.0964136C12.8242 0.043481 12.9396 0.0115572 13.0582 0.00260293C13.1768 -0.0063513 13.296 0.00785317 13.4086 0.0443437C13.5212 0.0808342 13.6248 0.13884 13.713 0.2148C13.8012 0.290759 13.8721 0.383067 13.9215 0.486056C13.9708 0.589044 13.9974 0.700538 13.9998 0.813688C14.0022 0.926839 13.9802 1.03926 13.9353 1.14404C13.8903 1.24882 13.8233 1.34376 13.7384 1.42301L5.76125 9.726C5.68064 9.81099 5.58243 9.8792 5.47279 9.92635C5.36315 9.9735 5.24444 9.99858 5.12412 10H5.11539Z"
                                  fill="#90B10C"
                                />
                              </svg>{" "}
                              Access to jubal talents community
                            </li>
                            <li>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="10"
                                viewBox="0 0 14 10"
                                fill="none"
                              >
                                <path
                                  d="M5.11539 10C4.99572 9.99963 4.87741 9.97586 4.7678 9.93015C4.6582 9.88444 4.55965 9.81778 4.47827 9.7343L0.236577 5.44166C0.0780167 5.28091 -0.00692349 5.06682 0.000441987 4.84648C0.00780746 4.62615 0.106875 4.41763 0.275852 4.26679C0.444828 4.11594 0.669872 4.03514 0.901475 4.04214C1.13308 4.04915 1.35227 4.1434 1.51083 4.30415L5.10667 7.94916L12.4467 0.310412C12.5212 0.22217 12.6139 0.149346 12.719 0.0964136C12.8242 0.043481 12.9396 0.0115572 13.0582 0.00260293C13.1768 -0.0063513 13.296 0.00785317 13.4086 0.0443437C13.5212 0.0808342 13.6248 0.13884 13.713 0.2148C13.8012 0.290759 13.8721 0.383067 13.9215 0.486056C13.9708 0.589044 13.9974 0.700538 13.9998 0.813688C14.0022 0.926839 13.9802 1.03926 13.9353 1.14404C13.8903 1.24882 13.8233 1.34376 13.7384 1.42301L5.76125 9.726C5.68064 9.81099 5.58243 9.8792 5.47279 9.92635C5.36315 9.9735 5.24444 9.99858 5.12412 10H5.11539Z"
                                  fill="#90B10C"
                                />
                              </svg>
                              Access to unlimited chats with talents
                            </li>
                            <li>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="10"
                                viewBox="0 0 14 10"
                                fill="none"
                              >
                                <path
                                  d="M5.11539 10C4.99572 9.99963 4.87741 9.97586 4.7678 9.93015C4.6582 9.88444 4.55965 9.81778 4.47827 9.7343L0.236577 5.44166C0.0780167 5.28091 -0.00692349 5.06682 0.000441987 4.84648C0.00780746 4.62615 0.106875 4.41763 0.275852 4.26679C0.444828 4.11594 0.669872 4.03514 0.901475 4.04214C1.13308 4.04915 1.35227 4.1434 1.51083 4.30415L5.10667 7.94916L12.4467 0.310412C12.5212 0.22217 12.6139 0.149346 12.719 0.0964136C12.8242 0.043481 12.9396 0.0115572 13.0582 0.00260293C13.1768 -0.0063513 13.296 0.00785317 13.4086 0.0443437C13.5212 0.0808342 13.6248 0.13884 13.713 0.2148C13.8012 0.290759 13.8721 0.383067 13.9215 0.486056C13.9708 0.589044 13.9974 0.700538 13.9998 0.813688C14.0022 0.926839 13.9802 1.03926 13.9353 1.14404C13.8903 1.24882 13.8233 1.34376 13.7384 1.42301L5.76125 9.726C5.68064 9.81099 5.58243 9.8792 5.47279 9.92635C5.36315 9.9735 5.24444 9.99858 5.12412 10H5.11539Z"
                                  fill="#90B10C"
                                />
                              </svg>
                              Create Post on Jubal
                            </li> */}
                            </ul>

                            <div className="other-plan">
                              <div>
                                <h4>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.7499 16.75L13.4874 13.4875M15.25 9.25C15.25 12.5637 12.5637 15.25 9.25 15.25C5.93629 15.25 3.25 12.5637 3.25 9.25C3.25 5.93629 5.93629 3.25 9.25 3.25C12.5637 3.25 15.25 5.93629 15.25 9.25Z"
                                      stroke="black"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  Find a better plan
                                </h4>
                                <p>
                                  Not enough access in your plan? Monthly
                                  payment too high? Let us help you find the
                                  right plan for your needs, and make the switch
                                  quick and easy.
                                </p>
                              </div>
                              <button
                                className="btn btn-light"
                                // onClick={() =>
                                //   navigate(RoutesLink?.subscription)
                                // }
                              >
                                Change plan
                              </button>
                            </div>
                            <div className="other-plan">
                              <div>
                                <h4>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M12.25 7.75L7.75 12.25M7.75 7.75L12.25 12.25M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                                      stroke="black"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  End your service
                                </h4>
                                <p>
                                  Sometimes you just need to call it quits. We
                                  get it.
                                </p>
                              </div>
                              <button className="btn btn-light">
                                Cancel plan
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="card resource-card my-order-detail-card plan-card">
                          <div className="top-head d-block border-0">
                            <p className="text-center">
                              You don't have any plan right now
                            </p>
                            <button
                              onClick={() => navigate(RoutesLink?.subscription)}
                              className="btn btn-primary mt-3 mt-lg-4 mx-auto d-block"
                            >
                              Purchase Now
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* =========================== */}
            {/*center content end */}
            {/* =========================== */}
          </div>
          {/* =========================== */}
          {/* Main feed grid End */}
          {/* =========================== */}
        </div>
      </section>
    </>
  );
}
