import { useState } from "react";
import { OrderHelperStatus } from "../../Helper/Helper";
import { toast } from "material-react-toastify";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { Modal } from "reactstrap";

export default function CancelOrder({
  toggleCancelOrderModal,
  cardDetail,
  orderId,
  setcallApi,
  reasons
}) {
  console.log(cardDetail);
  const [selectedReason, setSelectedReason] = useState(null);
  const [note, setNote] = useState(null);
  const handleRadioChange = (e) => {
    setSelectedReason(e.target.value);
  };

  const orderCancel = async (e) => {

    e.preventDefault();
    if(note == null || note == ""){
      toast.error("Additional note is required");
      return;
    }
    if (selectedReason == null) {
      toast?.error("please select a reason");
      return;
    }
    const cancelOrderData = {
      reason: selectedReason,
      note: note,
    };
    handleFetchRequest(
      `${ApiUrl?.update_order_status}${orderId}?status=${OrderHelperStatus?.cancelled}`,
      "POST",
      cancelOrderData
    ).then((res) => {
      if (res.status === true) {
        toast.success("Order cancelled");
        setcallApi(true);
        toggleCancelOrderModal();
      }
    });
  };
  return (
    <Modal
      className="modal-dialog modal-dialog-centered addressmodal modal-dialog-scrollable modal-fullscreen-sm-down"
      isOpen={true}
      toggle={toggleCancelOrderModal}
    >
      <div className="modal-content">
        <div className="modal-body py-0">
          <form onSubmit={orderCancel}>
            <div className="row">
              <div className="col-12 p-0">
                <div className="card resource-card my-order-detail-card border-0">
                  <div className="card-body">
                    <div className="row justify-content-center">
                      <div className="col-12 p-0">
                        <div className="return-reason-wrapper">
                          <h5>Want to cancel this order?</h5>
                          <p className="desc">
                            Don’t worry we are here to help you! choose the
                            correct reason for the cancel, this informatin is
                            only used to improve our services
                          </p>

                          <div className="  mb-4">
                            <label for="reason">Select the reason</label>
                            <select
                              id="reason"
                              className="form-select"
                              onChange={handleRadioChange}
                            >

                              
                              <option disabled selected>
                                Select reason
                              </option>
{
  reasons?.map((reason)=> (
<>
<option value={reason?.reason}>
                                {reason?.reason}
                              </option></>
  ))
}
                             
                              {/* <option value=" I don't like this product ">
                                Don't like this product
                              </option>
                              <option value=" Got a Batter Deal">
                                Got a Batter Deal
                              </option> */}
                            </select>
                          </div>
                          <div className="note mb-4">
                            <label for="feedback">Additional note</label>
                            <textarea
                              className="form-control"
                              id="feedback"
                              rows="3"
                              placeholder="Write your comments here....."
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                            />
                          </div>

                          <div className="refd-src">
                            <h6 className="mb-3">Refund back to source</h6>
                            <div className="refd-src-card d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-start gap-3">
                                <span>
                                  <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="0.1875"
                                      y="0.822266"
                                      width="24.0234"
                                      height="23.8352"
                                      rx="11.9176"
                                      fill="#F2F2F4"
                                    ></rect>
                                    <path
                                      d="M15.4815 9.82227H8.9169C8.51407 9.82227 8.1875 10.1488 8.1875 10.5517V14.9281C8.1875 15.3309 8.51407 15.6575 8.9169 15.6575H15.4815C15.8844 15.6575 16.2109 15.3309 16.2109 14.9281V10.5517C16.2109 10.1488 15.8844 9.82227 15.4815 9.82227Z"
                                      stroke="#343B4E"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M8.1875 12.0098H16.2109"
                                      stroke="#343B4E"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                                <div>
                                  <h5>{cardDetail?.type}</h5>
                                  <p>
                                    {cardDetail?.cardNumber
                                      ? `${"***** ".repeat(
                                          3
                                        )} ${cardDetail.cardNumber.slice(-4)}`
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row w-100 ">
                            <div className="col-12 col-md-6 p-0 pe-md-4 mt-2 mt-md-3">
                              <button
                                onClick={toggleCancelOrderModal}
                                type="button"
                                className="btn btn-light w-100"
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="col-12 col-md-6 p-0 mt-2 mt-md-3">
                              <button
                                type="submit"
                                className="btn btn-primary w-100"
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
