import React from "react";
import { getLoginUserCaption } from "../../Helper/Helper";
import { Link, useNavigate } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";

function HireingHeader({ userData, user }) {
  const navigate = useNavigate();
  return (
    <div className="user-det-head-wrapper">
      <div className="user-det-head d-flex justify-content-between align-items-center">
        <div className="user-det d-flex gap-3 gap-lg-4 align-items-center">
          {userData?.profilePhoto ? (
            <div className="user-dp">
              <img src={userData?.profilePhoto} alt="" />
            </div>
          ) : (
            <div className="user-dp no-img">{userData?.firstName?.[0]}</div>
          )}

          <div className="user-ac">
            <div className="d-flex gap-2 align-items-center">
              <span className="username">
                <Link to="">
                  {userData?.firstName} {userData?.lastName}
                </Link>
              </span>
              <span className="usertype mx-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={13}
                    height={13}
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <circle
                      cx="5.74979"
                      cy="6.72714"
                      r="3.75247"
                      fill="#7133F4"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                Talent
              </span>
            </div>
            <p>
              {" "}
              <p>{getLoginUserCaption(user)}</p>
            </p>
          </div>
        </div>
        <div className="btn-wrapper">
          {/* <button className="btn btn-light rounded-circle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={14}
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95515 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43314 1.6506L12.3494 4.56687L13.7725 3.14373Z"
                fill="#343B4E"
              />
            </svg>
          </button> */}
          <button
            className="btn btn-light rounded-circle"
            onClick={() =>
              navigate(RoutesLink?.profile, { state: { id: userData?.userId } })
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={12}
              height={12}
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M7.05762 6.00164L11.7712 1.28784C11.8428 1.21874 11.8998 1.13609 11.9391 1.0447C11.9783 0.953313 11.999 0.855022 11.9999 0.755563C12.0007 0.656104 11.9818 0.557469 11.9441 0.465413C11.9065 0.373357 11.8508 0.289723 11.7805 0.219393C11.7102 0.149062 11.6265 0.0934424 11.5345 0.0557793C11.4424 0.0181162 11.3438 -0.000835989 11.2443 2.82821e-05C11.1449 0.000892553 11.0466 0.0215562 10.9552 0.0608134C10.8638 0.100071 10.7812 0.157135 10.7121 0.228678L5.99827 4.94229L1.28466 0.228678C1.14367 0.0907862 0.953989 0.0140576 0.756783 0.0151437C0.559576 0.0162297 0.370754 0.0950426 0.231293 0.234478C0.0918327 0.373914 0.0129868 0.562723 0.0118659 0.759929C0.010745 0.957135 0.0874397 1.14683 0.225306 1.28784L4.9391 6.00164L0.225306 10.7154C0.154478 10.7847 0.0980952 10.8673 0.0594229 10.9586C0.0207506 11.0498 0.000556963 11.1477 1.13597e-05 11.2468C-0.000534243 11.3459 0.0185791 11.4441 0.0562444 11.5357C0.0939097 11.6274 0.149378 11.7106 0.21944 11.7807C0.289501 11.8507 0.372762 11.9062 0.464402 11.9438C0.556042 11.9815 0.654241 12.0006 0.753312 12C0.852383 11.9994 0.950358 11.9792 1.04156 11.9405C1.13277 11.9018 1.2154 11.8454 1.28466 11.7746L5.99827 7.06099L10.7121 11.7746C10.8583 11.9209 11.0501 11.9941 11.2416 11.9941C11.4332 11.9941 11.6252 11.9209 11.7712 11.7746C11.9117 11.6341 11.9905 11.4436 11.9905 11.245C11.9905 11.0464 11.9117 10.8559 11.7712 10.7154L7.05762 6.00164Z"
                fill="#343B4E"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default HireingHeader;
