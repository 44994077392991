// CheckoutForm.js
import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../../utility/ApiUrl";
import { toast } from "material-react-toastify";
import { RoutesLink } from "../../../utility/RoutesLink";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");

  const navigate = useNavigate();

  console.log("location", location.state);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",

      card: cardElement,
    });

    console.log("paymentMethod", paymentMethod);

    if (error) {
      setPaymentStatus(error.message);
    } else {
      // Send paymentMethod.id to your server for further processing.
      // const response = await fetch("/create-payment-intent", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      const paymentData = {
        token: paymentMethod.id,
        amount: location?.state?.amount, // Example amount in cents ($10.00) }),
        paymentGateway: "stripe",
      };
      handleFetchRequest(
        ApiUrl.create_payment_intent,
        "POST",
        paymentData
      ).then(async (res) => {
        console.log("rwssssssss", res);
        if (res?.data?.status == "succeeded") {
          console.log("ress", res);

          checkoutHandler(
            res?.data?.paymentId,
            res?.data?.paymentDetail?.cardDetails?.card
          );
        } else {
          alert(`Payment failed: ${res.error}`);
        }
      });

      // const paymentResult = await response.json();
    }
  };

  const checkoutHandler = (paymentId, card) => {
    setLoading(true);
    const checkoutData = {
      cardDetail: {
        cardNumber: card?.last4,
        brand: card?.cardBrand,
        type: card?.cardType,
      },
      paymentId: paymentId,
      // itemId: location.state.removeNotificationIds,
      itemId: location.state.removeNotificationIds.map((id) => ({
        id: id,
        shipping_fees: id.shippingFee ? id.shippingFee : 0, // Check if shippingFee exists, otherwise use an empty string
      })),
      delivery_instruction: location?.state?.note,
    };
    handleFetchRequest(
      `${ApiUrl.create_checkout}?addressId=${location?.state?.shippingDefaultAddress?.id}&billingAddressId=${location?.state?.billingDefaultAddress?.id}`,
      "POST",
      checkoutData
    ).then((response) => {
      if (response.status === true) {
        toast?.success("Order completed successfully");
        navigate(RoutesLink?.orderSuccess, {
          state: response?.data,
          replace: true,
        });
        setLoading(false);
      }
    });
  };

  return (
    <div className="row justify-content-center align-items-center h-100vh ">
      <div className="col-12 col-md-8">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-6">
                <form
                  onSubmit={handleSubmit}
                  className="d-flex h-100 flex-column justify-content-between"
                >
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          color: "#424770",
                          "::placeholder": {
                            color: "#aab7c4",
                          },
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                  />
                  <button
                    type="submit"
                    disabled={!stripe}
                    className="btn btn-primary w-75 d-block mx-auto mt-5"
                  >
                    Pay
                  </button>
                  {/* <p>{paymentStatus}</p> */}
                </form>
              </div>
              <div className="pay-sum col-lg-6">
                <h3>Order summary</h3>
                {location?.state?.instrument == 0 ? (
                  ""
                ) : (
                  <div className="d-flex align-items-center justify-content-between">
                    <p>Total instruments</p>
                    <p>${location?.state?.instrument?.toFixed(2)}</p>
                  </div>
                )}
                {location?.state?.totalTickets == 0 ? (
                  ""
                ) : (
                  <div className="d-flex align-items-center justify-content-between">
                    <p>Total tickets</p>
                    <p>${location?.state?.totalTickets?.toFixed(2)}</p>
                  </div>
                )}
                {location?.state?.shiping > 0 && (
                  <div className="d-flex align-items-center justify-content-between">
                    <p>Shipping Fee</p>
                    {/* <p>${cart?.shipping_fees?.toFixed(2)}</p> */}
                    <p>${location?.state?.shiping.toFixed(2)}</p>
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex">
                    <p>Service Fee</p>
                  </div>

                  <p>
                    <span>${location?.state?.platformFees?.toFixed(2)}</span>
                  </p>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <h5>Payable Amount</h5>
                  <h5>${location?.state?.amount?.toFixed(2)}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
