import { configureStore } from "@reduxjs/toolkit";
import { loadState } from "../utility/LocalStorage";
import reducer from "./reducers/Reducers";
import { thunk } from "redux-thunk";
import cartModalReducer from "./reducers/cartModalReducer";
import cartReducer from "./reducers/cartReducer";

const persistedState = loadState();

const rootReducer = {
  user: reducer,
  cartModal: cartModalReducer,
  cartReducer: cartReducer,
};
const mfunction = () => [thunk];

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: mfunction,
});

export default store;
