import { Link } from "react-router-dom";
import GooglePlaceAutocomplete from "../GooglePlaceAutocomplete";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { toast } from "material-react-toastify";
import { SvgIcons } from "../Svg/SvgIcons";
import { ApiUrl } from "../../utility/ApiUrl";
import { CountryCode } from "../../utility/CountryCode";
import { Spinner } from "reactstrap";

export default function AddAddressModal({
  toggleAddressModal, // Function to toggle the visibility of the address modal
  getAddress, // Function to retrieve updated address data
  editAddress, // Object containing address details for editing
  setEditAddress, // Function to set the editAddress state
  changeAddress, // Not used in this code, could be related to updating address
  address, // Current address data (not used in this code)
  setChangeAddress, // Not used in this code
  billingDefaultAddress, // Default billing address (not used directly)
  shippingDefaultAddress, // Default shipping address (not used directly)
  setBillingDefaultAddress, // Function to set the billing default address
  setShippingDefaultAddress, // Function to set the shipping default address
  addressCheck, // Indicates whether to set the billing or shipping address
  handleDeleteAddress, // Function to handle address deletion (not used in this code)
}) {
  const [selectedPlace, setSelectedPlace] = useState(null); // State for selected address from autocomplete
  const formRef = useRef(null); // Reference for the form (not used in this code)
  const [loading, setLoading] = useState(false);

  // Logging the current editAddress state for debugging
  console.log(editAddress);

  // Setting up the form with react-hook-form
  const {
    register, // Function to register input fields
    handleSubmit, // Function to handle form submission
    setValue, // Function to set values in the form
    formState: { errors }, // Object to manage form errors
    reset, // Function to reset the form fields
    getValues
  } = useForm({});

  // Effect to pre-fill form fields when editing an address
  useEffect(() => {
    if (editAddress) {
      setValue("name", editAddress?.name);
      setValue("countryCode", editAddress?.countryCode);
      setValue("email", editAddress?.email);
      setValue("location", editAddress?.location?.address);
      setValue("type", editAddress?.type);
      setValue("phone", editAddress?.phone);
      setValue("zipcode", editAddress?.zipcode);
      setValue("isDefault", editAddress?.isDefault);
      setValue("office_phone", editAddress?.office_phone);
      setValue(
        "office_phone_country_code",
        editAddress?.office_phone_country_code
      );
      setValue("recovery_email", editAddress?.recovery_email);
      setValue("city", editAddress?.location?.city);
      setValue("state", editAddress?.location?.state);
      setValue("country", editAddress?.location?.country);
      setValue("zipcode", editAddress?.location?.postal_code);
      setValue("fax", editAddress?.fax);
    }
  }, [editAddress]); // Runs when editAddress changes

  // Function to handle address changes based on selection type (shipping or billing)
  const handleAddressChange = (addr) => {
    if (addressCheck === "ship") {
      setShippingDefaultAddress(addr); // Set the shipping default address
    } else if (addressCheck === "bill") {
      setBillingDefaultAddress(addr); // Set the billing default address
    }
    toggleAddressModal(); // Close the modal after selecting an address
  };

  // Logging shipping and billing default addresses for debugging
  console.log(shippingDefaultAddress);
  console.log(billingDefaultAddress);

  // Function to handle form submission
  const onSubmit = async (data) => {
    setLoading(true);
    // If a place is selected, add additional data to it
    if (selectedPlace) {
      selectedPlace.city = data?.city;
      selectedPlace.country = data?.country;
      selectedPlace.state = data?.state;
      selectedPlace.postal_code = data?.zipcode;
    }

    // Constructing the full data object to be sent to the API
    const fullData = {
      name: data?.name,
      email: data?.email,
      countryCode: data?.countryCode,
      phone: data?.phone,
      office_phone: data?.office_phone,
      office_phone_country_code: data?.office_phone_country_code,
      recovery_email: data?.recovery_email,
      type: data?.type,
      fax: data?.fax,
      location: selectedPlace || editAddress?.location, // Use selected place or editAddress location
    };

    // Making API call to update or create an address
    if (editAddress) {
      handleFetchRequest(
        `${ApiUrl?.edit_address}${editAddress?.id}`,
        "POST",
        fullData
      ).then((response) => {
        // Handling response after updating the address
        if (response.status === true) {
          getAddress(); // Refresh address data
          reset(); // Reset form fields
          toggleAddressModal(); // Close the modal
          toast?.success(response?.message); // Show success message
          setEditAddress(null); // Clear edit address state
          setLoading(false);
        } else {
          toast.error(response?.message); // Show error message
          setLoading(false);
        }
      });
    } else {
      // Create a new address if editAddress is not defined
      handleFetchRequest(ApiUrl?.create_address, "POST", fullData).then(
        (response) => {
          // Handling response after creating the address
          if (response.status === true) {
            getAddress(); // Refresh address data
            reset(); // Reset form fields
            toggleAddressModal(); // Close the modal
            toast?.success(response?.message); // Show success message
            setLoading(false);
          } else {
            toast.error(response?.message); // Show error message
            setLoading(false);
          }
        }
      );
    }
  };

  // Effect to update form values when selected place changes
  useEffect(() => {
    if (selectedPlace) {
      setValue("city", selectedPlace?.city);
      setValue("state", selectedPlace?.state);
      setValue("country", selectedPlace?.country);
      setValue("zipcode", selectedPlace?.postal_code);
    }
  }, [selectedPlace]); // Runs when selectedPlace changes
  return (
    <div className="modal-content">
      <div className="modal-body ">
        {changeAddress == true ? (
          <div className="row">
            <h3 className="mb-4 p-0">Select Address</h3>
            {address?.map((addr) => (
              <>
                <div className="col-12 p-0" key={addr?.id}>
                  <div className="address-card bg-white">
                    <div className="row">
                      <div className="col-12 p-0 d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="form-check">
                            <input
                              type="radio"
                              id={`radio-${addr?.id}`}
                              name={`billing${addressCheck}`}
                              className="form-check-input type-one"
                              checked={
                                addressCheck === "ship"
                                  ? addr?.id === shippingDefaultAddress?.id
                                  : addressCheck === "bill"
                                  ? addr?.id === billingDefaultAddress?.id
                                  : false
                              }
                              onChange={() => handleAddressChange(addr)}
                            />
                          </div>
                          <span className="type-badge">
                            <span>
                              {addr?.type === "home"
                                ? SvgIcons?.HomeIcon
                                : SvgIcons?.OfficeIcon}
                            </span>
                            {addr?.type === "home"
                              ? "Home"
                              : addr?.type === "office"
                              ? "Office"
                              : "Other"}
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <button
                            className="btn btn-link p-0 rounded-circle"
                            onClick={() => {
                              setEditAddress(addr);
                              setChangeAddress(false);
                            }}
                          >
                            {SvgIcons?.editAddressIcon}
                          </button>
                          <button
                            className="btn btn-link p-0 rounded-circle"
                            onClick={() => handleDeleteAddress(addr?.id)}
                          >
                            {SvgIcons?.deleteAddressIcon}
                          </button>
                        </div>
                      </div>
                      <div className="col-12 p-0">
                        <ul>
                          <li>
                            <p className="para">City: {addr?.location?.city}</p>
                            <p className="para">
                              Address: {addr?.location?.address}
                            </p>
                            <p className="para">
                              Zip Code: {addr?.location?.postal_code}
                            </p>
                          </li>
                          <li>
                            <p className="para">
                              Mobile: {addr?.countryCode} {addr?.phone}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        ) : (
          <div className="personal-details-form">
            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <div className="row ">
                <div className="col-12 p-0">
                  <h4>Add new Address</h4>
                  <p className="para">
                    Add common address that you want to use for collection
                    and/or delivery here.
                  </p>
                </div>
                <div className="col-12 p-0">
                  <label for="name" className="form-label">
                    Full Name
                    <span className="text-danger fs-2 ps-1">*</span>
                  </label>
                  <div className="d-flex align-items-center passfield-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Full Name"
                      {...register("name", {
                        required: "Name is required",
                      })}
                    />

                    <span className="visi">{SvgIcons?.EditIcon}</span>
                  </div>
                  {errors.name && (
                    <span className="form-text text-danger">
                      {errors.name.message}
                    </span>
                  )}
                </div>

                <div className="col-12 p-0">
                  <label for="email" className="form-label">
                    Email Address
                    <span className="text-danger fs-2 ps-1">*</span>
                  </label>
                  <div className="d-flex align-items-center passfield-wrapper">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Email addrerss"
                      {...register("email", {
                        required: "Email is required",
                        validate: {
                          isValidEmail: value => {
                            // Regular expression for email validation
                            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            return emailPattern.test(value) || "Please enter a valid email address";
                          },
                        },
                      })}
                    />

                    <span className="visi">{SvgIcons?.EditIcon}</span>
                  </div>
                  {errors.email && (
                    <span className="form-text text-danger">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className="col-12 p-0">
                  <label for="phone" className="form-label">
                    Contact Number
                    <span className="text-danger fs-2 ps-1">*</span>
                  </label>
                  <div className="numfield-wrapper">
                    <select
                      className="form-select nopre-drop position-relative z-3"
                      id="countryCode"
                      {...register("countryCode", {
                        required: "Country code is required",
                      })}
                    >
                      {CountryCode?.map((data, index) => (
                        <option key={index} value={data?.dial_code}>
                          {data?.code} ({data?.dial_code})
                        </option>
                      ))}
                    </select>

                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      placeholder="Contact Number"
                      {...register("phone", {
                        required: "Phone number is required",
                        pattern: {
                          value: /^\d{10}$/, // Regular expression to allow only numbers
                          message: "Phone number must be 10 digits",
                        },
                      })}
                    />
                  </div>
                  {errors.phone && (
                    <span className="form-text text-danger">
                      {errors.phone.message}
                    </span>
                  )}
                </div>

                <div className="col-12 p-0">
                  <label for="office_phone" className="form-label">
                    Office Mobile Number
                  </label>
                  <div className="numfield-wrapper">
                    <select
                      className="form-select nopre-drop position-relative z-3"
                      id="office_phone_country_code"
                      {...register("office_phone_country_code", {
                        required: "Country code is required",
                      })}
                    >
                      {CountryCode?.map((data, index) => (
                        <option key={index} value={data?.dial_code}>
                          {data?.code} ({data?.dial_code})
                        </option>
                      ))}
                    </select>

                    <input
                      type="text"
                      className="form-control"
                      id="office_phone"
                      placeholder="Contact Number"
                      {...register("office_phone", {
                        validate: {
                          isValidPhone: value => {
                            // If the value is empty, return true (no validation needed)
                            if (!value) return true;
                            
                            // Regular expression to allow only numbers
                            const pattern = /^\d{10}$/;
                            return pattern.test(value) || "Phone number must be 10 digits";
                          },
                        },
                      })}
                    />
                  </div>
                  {errors.office_phone && (
                    <span className="form-text text-danger">
                      {errors.office_phone.message}
                    </span>
                  )}
                </div>

                <div className="col-12 p-0">
                  <label for="recovery_email" className="form-label">
                    Recovery Email Address
                  </label>
                  <div className="d-flex align-items-center passfield-wrapper">
                    <input
                      type="email"
                      className="form-control"
                      id="recovery_email"
                      placeholder="Email addrerss"
                      {...register("recovery_email", {
                        rvalidate: {
                          isValidEmail: value => {
                            // If the value is empty, return true (no validation needed)
                            if (!value) return true;
                            
                            // Regular expression for email validation
                            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            if (!emailPattern.test(value)) {
                              return "Please enter a valid email address";
                            }
                            
                            // Compare recovery email with the main email
                            const email = getValues("email"); 
                            if (value === email) {
                              return "Recovery email cannot be the same as the main email address";
                            }
                            
                            return true; // Valid email and not the same as the main email
                          },
                        },
                        
                      })}
                    />

                    <span className="visi">{SvgIcons?.EditIcon}</span>
                  </div>
                  {errors.recovery_email && (
                    <span className="form-text text-danger">
                      {errors.recovery_email.message}
                    </span>
                  )}
                </div>

                <div className="col-12 p-0">
                  <label for="fax" className="form-label">
                    Fax nuumber
                  </label>
                  <div className="d-flex align-items-center passfield-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      id="fax"
                      placeholder="Fax number"
                      {...register("fax", {
                        pattern: {
                          value: /^[0-9]+$/, // Regular expression to allow only numbers
                          message: "Only numbers are allowed",
                        },
                      })}
                    />

                    <span className="visi">{SvgIcons?.EditIcon}</span>
                  </div>
                  {errors.fax && (
                    <span className="form-text text-danger">
                      {errors.fax.message}
                    </span>
                  )}
                </div>

                <div className="col-12 p-0">
                  <label for="loc" className="form-label">
                    Location (City/State/Zip Code)
                    <span className="text-danger fs-2 ps-1">*</span>
                  </label>
                  <div className="d-flex align-items-center passfield-wrapper">
                    <GooglePlaceAutocomplete
                      selectedPlace={selectedPlace}
                      setSelectedPlace={(e) => setSelectedPlace(e)}
                      defaultValues={editAddress?.location?.address}
                    />

                    <span className="visi">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="17"
                        viewBox="0 0 15 17"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.21484 7.71052C1.22638 4.31624 3.98733 1.57399 7.3816 1.58549C10.7759 1.59705 13.5181 4.35801 13.5066 7.75228V7.82188C13.4648 10.0283 12.2329 12.0676 10.7225 13.6615C9.85873 14.5585 8.89414 15.3526 7.84794 16.028C7.5682 16.27 7.15325 16.27 6.87351 16.028C5.31388 15.0128 3.94504 13.7312 2.82962 12.2416C1.83545 10.9427 1.27101 9.36615 1.21484 7.7314V7.71052Z"
                          stroke="#818898"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M7.35902 10.1658C8.89055 10.1658 10.1321 8.92424 10.1321 7.39271C10.1321 5.86118 8.89055 4.61963 7.35902 4.61963C5.82749 4.61963 4.58594 5.86118 4.58594 7.39271C4.58594 8.92424 5.82749 10.1658 7.35902 10.1658Z"
                          stroke="#818898"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="col-12 p-0">
                  <label htmlFor="city" className="form-label">
                    City
                    <span className="text-danger fs-2 ps-1">*</span>
                  </label>
                  <div className="d-flex align-items-center passfield-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      placeholder="City"
                      {...register("city", {
                        required: "City is required",
                      })}
                    />
                    <span className="visi">{SvgIcons?.EditIcon}</span>
                  </div>
                  {errors.city && (
                    <div className="form-text text-danger">
                      {errors.city.message}
                    </div>
                  )}
                </div>
                <div className="col-12 p-0">
                  <label htmlFor="state" className="form-label">
                    State
                    <span className="text-danger fs-2 ps-1">*</span>
                  </label>
                  <div className="d-flex align-items-center passfield-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      placeholder="State"
                      {...register("state", {
                        required: "state is required",
                      })}
                    />
                    <span className="visi">{SvgIcons?.EditIcon}</span>
                  </div>
                  {errors.state && (
                    <div className="form-text text-danger">
                      {errors.state.message}
                    </div>
                  )}
                </div>
                <div className="col-12 p-0">
                  <label htmlFor="country" className="form-label">
                    Country
                    <span className="text-danger fs-2 ps-1">*</span>
                  </label>
                  <div className="d-flex align-items-center passfield-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      id="country"
                      placeholder="country"
                      {...register("country", {
                        required: "Country is required",
                      })}
                    />
                    <span className="visi">{SvgIcons?.EditIcon}</span>
                  </div>
                  {errors.country && (
                    <div className="form-text text-danger">
                      {errors.country.message}
                    </div>
                  )}
                </div>
                <div className="col-12 p-0">
                  <label htmlFor="zipcode" className="form-label">
                    Zipcode
                    <span className="text-danger fs-2 ps-1">*</span>
                  </label>
                  <div className="d-flex align-items-center passfield-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      id="zipcode"
                      placeholder="zipcode"
                      {...register("zipcode", {
                        required: "zipcode is required",
                      })}
                    />
                    <span className="visi">{SvgIcons?.EditIcon}</span>
                  </div>
                  {errors.zipcode && (
                    <div className="form-text text-danger">
                      {errors.zipcode.message}
                    </div>
                  )}
                </div>
                <div className="col-12 p-0">
                  <label for="type" className="form-label">
                    Address Type
                    <span className="text-danger fs-2 ps-1">*</span>
                  </label>
                  <select
                    id="type"
                    className="form-select"
                    aria-label="Default select example"
                    {...register("type", {
                      required: "Address type is required",
                    })}
                  >
                    <option value="home">Home</option>
                    <option value="office">Office</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      <div className="modal-footer border-0">
        <div className="row w-100 gap-3">
          <div className="col-12 col-md-5 p-0 mt-2 mt-md-3">
            {changeAddress == true ? (
              <button
                type="button"
                onClick={() => setChangeAddress(false)}
                className="btn btn-primary w-100"
              >
                Add new address
              </button>
            ) : (
              <button
                type="button"
                onClick={() =>
                  formRef.current &&
                  formRef.current.dispatchEvent(
                    new Event("submit", { cancelable: true, bubbles: true })
                  )
                }
                disabled={loading}
                className="btn btn-primary w-100"
              >
                {loading ? <Spinner /> :editAddress ? "Save" : "Add"}
              </button>
            )}
          </div>
          <div className="col-12 col-md-5 p-0 mt-2 mt-md-3">
            <Link
              to="#"
              className="btn btn-light w-100"
              onClick={() => {
                toggleAddressModal();
                setEditAddress(null);
              }}
            >
              cancel
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
