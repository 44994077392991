import Header from "../Components/Header";
import HomeFooter from "../Components/HomeFooter";

export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <section className="terms-wrapper">
        <h1>PRIVACY POLICY</h1>

        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* <p className="my-4 my-md-5 updated">Updated: July 29, 2024</p> */}
              <p className="mt-4">
                (company’s registered name) (“Jubal Talents” “we,” “us,” or
                “our”) was formed to create a community for music creators,
                producers, session musicians, audio engineers, recording
                studios, and multimedia professionals to gather in a marketplace
                platform fully dedicated to musicians and artists . We created
                this privacy policy (“Privacy Policy”), effective as of July 29,
                2024, because we know that you care about how information you
                provide to us is collected, used, and shared. This Privacy
                Policy relates to the information collection and use practices
                of Jubal Talents in connection with our online services (the
                “Services”), which are made available to you through our app
                Jubal Talents (the “App”) and website located
                www.jubaltalents.com (the “Site”).
                <br />
                While our App and Site is not intended for use by children, we
                are committed to complying with the Children’s Online Privacy
                Protection Act of 1998 (“COPPA”). We recommend that parents
                discuss with their children restrictions regarding the online
                release of Personal Information (as defined below) to anyone
                they don’t know.
                <br />
                Effective May 25th, 2018, if you are from the European Economic
                Area ("EEA"), our processing of your personal data will comply
                with the Regulations (EU) 2016/679 of the European Parliament
                and of the Council of 27 April 2016, known as the General Data
                Protection Regulation ("GDPR").
                <br />
                By visiting our Site and/or using our Services, or by permitting
                your child to use our Services, you are agreeing to the terms of
                this Privacy Policy and the accompanying Terms of Use.
                Capitalized terms not defined in this Privacy Policy shall have
                the meaning set forth in our Terms of Use.
              </p>
            </div>
            <div className="col-12">
              <h2>1. INFORMATION COLLECTION</h2>
              <h3 className="mt-4 mb-2">Personal Information</h3>
              <p>
                We do not collect any personal information from you unless you
                voluntarily provide it to us. When you sign up to become a user
                of the Site (a “User”), you will be asked to provide us with
                certain personal information, such as your first name, last
                name, address, applicable tax ID, and email address. In this
                Privacy Policy, we refer to this information and other
                information collected about you as “Personal Information.” In
                addition, in order for you to best utilize our Services, you may
                wish to include other types of information, including your
                studio/business name, resume/CV, service listing (eg. instrument
                you play), business address, business phone number, media
                (audio, video, images, and other digital materials), interests,
                and any other types of information that you may provide. <br />
                We do not permit access to or use of our Services by children
                under the age of 18, and we do not collect any Personal
                Information from Users under the age of 18. If you are a User
                under the age of 18, please do not send any Personal Information
                to us or use our Services. If you believe that a User under the
                age of 18 may have provided us Personal Information in violation
                of this Privacy Policy, please contact us at (Gmail Address) .
              </p>
              <h3 className="mt-4 mb-2">Usage Information</h3>
              <p>
                Like most websites, we use automatic data collection technology
                when you visit the Site to record information that identifies
                your computer, to track your use of our Site, and to collect
                certain basic information about you and your surfing habits.
                This includes user’s personal information about their operating
                system, your IP addresses, browser type and language, and
                similar information concerning your use of the Site and the
                Services (the “Usage Information”).
                <br />
                We collect this Usage Information by using cookies. Cookies are
                small packets of data that a website stores on your computer’s
                hard drive so that your computer will “remember” information
                about your visit. You can reject cookies, we give you rejecting
                cookies on our website. If you reject cookies, you may still
                visit the Site, but may not be able to use some areas of the
                Site and/or the Services.
                <br />
                We do not collect Personal Information in this way, but if
                you’ve provided us with Personal Information, we may associate
                that information with the information that is collected
                automatically. Automatic data collection may be performed on our
                behalf by our services providers.
              </p>
            </div>
            <div className="col-12">
              <p>
                We may use your Personal Information and Usage Information in a
                manner that is consistent with this Privacy Policy and the
                context of our relationship with you. We will use your Personal
                Information and Usage Information collected through the Site and
                App for the following purposes:
              </p>
              <ul>
                <li>To identify you when you sign into your account;</li>
                <li>
                  To respond to your requests and to provide you with the
                  Services;
                </li>
                <li>
                  To process payments for purchases through the Site and App;
                </li>
                <li>
                  To respond to your inquiries and comments, as well as to
                  contact you about changes to the Site and/or the Services;
                </li>
                <li>
                  To notify you about communications from other users or
                  perspective employers, technical notices, updates, security
                  alerts, support and other administrative notifications.
                </li>
                <li>
                  To send you notices (for example, in the form of emails,
                  mailings, and the like) regarding products or services you are
                  receiving, and for billing and collection purposes;
                </li>
                <li>
                  To send you information we think you may find useful or that
                  you have requested from us;
                </li>
                <li>
                  To display information to advertise Seller’s services on the
                  Site;
                </li>
                <li>
                  To enhance and improve the Site or the Service, such as
                  through personalized features and content;
                </li>
                <li>
                  To allow you to share use of the Service through third-party
                  social media platforms;
                </li>
                <li>
                  To analyze the use of the Site and the Service and the people
                  visiting to improve our content, Site, App and the Service;
                </li>
                <li>
                  To investigate, prevent, or take action regarding illegal
                  activities, suspected fraud, situations involving potential
                  threats to the physical safety of any person, violations of
                  our policies, or as otherwise required by law;
                </li>
                <li>To perform accounting and administrative tasks;</li>
                <li>
                  For any other purposes disclosed at the time the information
                  is collected or to which you consent; and
                </li>
                <li>
                  As otherwise specifically described in this Privacy Policy.
                </li>
              </ul>
              <p>
                Jubal Talents reserves the right to retain and use any
                photograph, audio, video, or other media submitted by you to
                sell Services on the site. We also reserve the right to use your
                feedback and reviews published on the Site. Our use of such
                material is consistent with the restrictions on disclosure of
                Personally Identifiable Information. We further reserve the
                right to monitor, edit, and/or restrict any messages,
                photographs, or media that are posted on the Site and App.
              </p>
            </div>
            <div className="col-12">
              <h2>3. DISCLOSURE OF INFORMATION</h2>
              <p>
                We do not disclose your Personal Information to third parties
                for their direct marketing purposes. We, like many businesses,
                sometimes hire other companies to perform certain
                business-related functions. Examples include mailing
                information, maintaining databases, hosting services, and
                processing payments. When we employ another company to perform a
                function of this nature, we provide them with the information
                that they need to perform their specific function, which may
                include Personal Information. <br />
                If Jubal Talents or all or substantially all of our assets are
                acquired, either by merger, acquisition, bankruptcy, or other
                sale of all or a portion of our assets or the business, we
                reserve the right, as part of this type of transaction or event,
                to transfer or assign your information and other information we
                have collected from users of the Services to third parties.
                Other than to the extent ordered by a bankruptcy or other court,
                the use and disclosure of all transferred user information will
                be subject to this Privacy Policy. However, any information you
                submit or that is collected after this type of transfer may be
                subject to a new privacy policy adopted by the successor entity
                or acquirer, as applicable. <br />
                We may disclose your Personal Information and Usage Information
                to government authorities and to other third parties when
                compelled to do so by government authorities, at our discretion,
                or otherwise as required by law, including but not limited to in
                response to court orders and subpoenas. We may also disclose
                your Personal Information and Usage Information when we have
                reason to believe that someone is or may be causing injury to or
                interference with our rights or property, other users of this
                Site, App, or anyone else that could be harmed by such
                activities.
              </p>
            </div>
            <div className="col-12">
              <h2>
                4. REGISTRATION, ACCOUNT SET UP, BUYING & SELLING SERVICES:
              </h2>
              <p>
                In order to use the Services, you need to provide us with
                Personal Information. You may review, change, or remove the
                Personal Information from your account settings, or by
                contacting us at (Gmail Address). You need to provide this
                information to enable us to provide you with the Services
                provided by the Site. Jubal Talents may contact you
                confidentially in the event we may need additional information
                from you in order for us to provide a particular service such as
                processing requested Services or setting up your Account on the
                Site or App.
                <br />
                Once you register, your username, display name, and any other
                identification information you add or upload to your profile
                and/or service listing is made public and visible on the Site.
                This information includes, without limitation to, media, service
                listing(s), biography/CV, musical gear, devices, equipment,
                ratings, and additional information you may choose. For the
                avoidance of doubt, this information shall not include any
                information shared via the Jubal Talents’ inbox or order pages,
                including direct messages and file attachments. This type of
                information is not public and not subject to disclosure. Jubal
                Talents may contact you confidentially to request more
                information about the services listed in the Jubal Talents
                marketplace, or to ensure compliance with our Terms of Use and
                any applicable laws or regulations.
              </p>
            </div>
            <div className="col-12">
              <h2>
                5. EDIT/REVIEW/TRANSFER/ACCESSIBILITY AND DEACTIVATION OF
                PERSONALLY IDENTIFIABLE INFORMATION
              </h2>
              <p>
                Jubal Talents allows you to review, update, and edit your
                Personal Information after your initial registration to the
                Site. If you request to access the information or to receive a
                copy of the information that has been collected on the Site, it
                will usually take us at least 30 days to comply with such
                request. Jubal Talents, upon request, may also transfer the
                information you provided to us to another organization of your
                choosing, if that transfer is technically feasible. Please email
                (Gmail Address) for these specific requests.
                <br />
                Upon your specific request, Jubal Talents will deactivate your
                account and remove it from our active database. Upon receipt of
                such request, we will deactivate your user account as soon as
                reasonably possible in accordance with our deactivation policy
                and any applicable law. We will maintain a record of information
                in our database that you may have requested us to remove that in
                our discretion is necessary for us to resolve any disputes,
                correct problems, comply with legal or regulations requirements
                or enforce the Terms of Use Agreement. Please email (Gmail
                Address) to begin the deactivation process.
              </p>
            </div>
            <div className="col-12">
              <h2>6. EUROPEAN PRIVACY RIGHTS</h2>
              <p>
                We recognize and will comply with certain data protection laws
                in accordance with the European Union’s General Data Production
                Regulation (“GDPR”). Users who are located in the EU have the
                following rights, including:
                <br />
                Right of Access. You have the right to request from Jubal
                Talents: a “data controller” as defined under the GDPR, the
                Personal Information we gather about you including information
                about the sharing, storage, security, and processing of that
                information. You further have the right to transmit this
                information to another third party.
                <br />
                Right of Correction. You may have the right to access, update,
                and correct inaccuracies in certain of your personal data in our
                custody and control, subject to certain limitations as provided
                by law. You may request to access, update, and correct
                inaccuracies in personal data we have in our custody or control
                by writing to us at (Gmail Address). We may request certain
                personal information for the purposes of verifying your identity
                in order to request certain information records.
                <br />
                Right of Erasure. You have the right to request that your
                personal information be erased from our possession (subject to
                certain limitations under the applicable law). If we are
                required to comply with your request, upon deletion of your
                information you may be prevented from using our Services on the
                website.
                <br />
                Right to Object and Complain. Subject to limitations as provided
                in the GDPR, you have the right to object to how and why your
                Personal Information is used and processed. You further have the
                right to complain to the appropriate supervisor about our
                handling of your personal information.
                <br />
                Right to Restrict Processing. Subject to limitations as provided
                in the GDPR, you have the right to request restriction of how
                and why your Personal Information is used and processed. If you
                have questions about exercising these rights or need assistance,
                please contact us at (Gmail Address)
              </p>
            </div>
            <div className="col-12">
              <h2>7. CALIFORNIA CONSUMER PRIVACY ACT</h2>
              <p>
                Whether or not we are subject to the New York Privacy Act
                (“NYPA”), Jubal Talents strives to meet or exceed those privacy
                protections in regard to the Personal Information of our
                California consumers (“Consumers”). To the extent of any
                conflict between this Section 7 and the rest of our Privacy
                Policy, this Section 7 shall control only with respect to
                Consumers and their Personal Information. Pursuant to NYPA,
                “personal information” includes:
              </p>
              <ul>
                <li>
                  Identifiers such as a real name, alias, online identifier, IP
                  address, email address, account name, or other similar
                  identifiers;
                </li>
                <li>
                  Characteristics of protected classNameifications under federal
                  law;
                </li>
                <li>
                  Commercial information, including records of personal
                  property, products or services purchased, obtained, or
                  considered, or other purchasing or consuming histories or
                  tendencies;
                </li>
                <li>
                  Internet or other electronic network activity information
                  including, but not limited to, browsing history, search
                  history, and information regarding a consumer’s interaction
                  with a website, application, or advertisement;
                </li>
                <li>Geolocation data;</li>
                <li>Audio, electronic, visual, or similar information;</li>
                <li>Professional or employment-related information;</li>
                <li>
                  Education information, defined as information that is not
                  publicly available but personally
                </li>
                <li>
                  Identifiable information (PII) as defined in the Family
                  Educational Rights and Privacy Act (20 U.S.C. section 1232g,
                  34 C.F.R. Part 99); and
                </li>
                <li>
                  Inferences drawn from any of the information identified in
                  this subdivision to create a profile about a consumer
                  reflecting the consumer’s preferences, characteristics,
                  psychological trends, preferences, predispositions, behavior,
                  attitudes, intelligence, abilities, and aptitudes.
                </li>
              </ul>
              <h3>Consumers’ NYPA Rights</h3>
              <h3 className="mt-4 mb-2">Access</h3>
              <p>
                You may request access to the personal information that we have
                collected and maintained about you (along with information
                regarding its use and disclosure) over the past twelve (12)
                months upon appropriate verification. You may make such requests
                no more than twice (2) per every twelve (12) months.
              </p>
              <h3 className="mt-4 mb-2">Deletion</h3>
              <p>
                You have the right to request us to delete personal information
                collected and maintained about you, subject to certain
                exceptions. Once your request is verified and we have determined
                that we are required to delete that information in accordance
                with applicable law, we will delete your personal information
                accordingly. Your request to delete your personal information
                may be denied if it is necessary for us to retain your
                information under one or more of the exceptions listed in the
                NYPA. Please note that a record of your deletion request may be
                kept in order for us to comply with our legal obligations.
              </p>
              <h3 className="mt-4 mb-2">Correction</h3>
              <p>
                You have the right to correct inaccurate personal information
                which we have on file about you.
              </p>
              <h3 className="mt-4 mb-2">Limitation</h3>
              <p>
                You can direct us not to process personal data (for example,
                your social security number, financial account information, your
                precise geolocation data, or your genetic data) in any way other
                than storing it. For example, we must remove personal data from
                a website but not delete it.
              </p>
              <h3 className="mt-4 mb-2">Exercising Your Rights</h3>
              <p>
                To exercise the access and deletion rights described above,
                please submit a request to us by either:
                <br />
                Emailing us at (Gmail Address)
                <br />
                Call us at (Telephone/Mobile Number ) <br />
                Please note that Consumers have a right not to be subjected to
                discriminatory treatment for the exercise of their rights under
                the NYPA.
              </p>
              <h3 className="mt-4 mb-2">Verifying Your Request</h3>
              <p>
                Only you, or a person that you authorize to act on your behalf,
                may make a request related to your personal information under
                NYPA. In the case of access to and deletion of personal
                information, we must be able to verify your request before we
                can fulfill such request. This will require you to provide
                sufficient information for us to reasonably verify that you are
                either the person about whom we collected personal information
                or a person authorized to act on your behalf.
                <br />
                Please note that we may charge a reasonable fee or refuse to act
                on a request if such request is excessive, repetitive, or
                inherently unfounded.
              </p>
            </div>
            <div className="col-12">
              <h2>8. HOW WE PROTECT YOUR INFORMATION</h2>
              <p>
                We take commercially reasonable steps to protect Personal
                Information from loss, misuse, and unauthorized access,
                disclosure, alteration, or destruction. Please understand,
                however, that no security system is impenetrable. We cannot
                guarantee the security of our databases, nor we can guarantee
                that the information you provide will not be intercepted while
                being transmitted to and from us over the Internet. In
                particular, email sent to or from us may not be secure, and you
                should therefore take special care in deciding what information
                you send to us via email.
              </p>
            </div>
            <div className="col-12">
              <h2>9. YOUR CHOICES</h2>
              <p>
                If you wish to stop receiving promotional emails, you may do so
                by emailing (Gmail Address). You may also choose to unsubscribe
                from our emails by following the instructions in the bottom of
                the email. You may also object to the use of your Personal
                Information or limit the way that the information is utilized.
                Please note that while you have the right to object to the use
                and/or the degree of how your information is used, that request
                may severely limit the Service that is provided by this Site.
              </p>
            </div>
            <div className="col-12">
              <h2>10. LINKS</h2>
              <p>
                The Site may contain content, services, advertising, and other
                materials that link to websites operated by third parties. We
                have no control over those other sites, and this Privacy Policy
                does not apply to them. Please refer to the privacy policies of
                those sites for more information on how the operators of those
                sites collect and use your Personal Information.
              </p>
            </div>
            <div className="col-12">
              <h2>11. IMPORTANT NOTICE TO USERS OUTSIDE THE U.S.</h2>
              <p>
                The Site and the Services are operated in the United States. If
                you are located outside of the United States, please be aware
                that any information you provide to us will be transferred to
                the United States. By providing us with any information through
                the Site or the Services, you consent to this transfer and
                accept the terms of this Privacy Policy.
              </p>
            </div>
            <div className="col-12">
              <h2>12. CHANGES TO THIS PRIVACY POLICY</h2>
              <p>
                This Privacy Policy is effective as of the date stated at the
                top of this Privacy Policy. We may change this Privacy Policy
                from time to time and will post any changes on the Site as soon
                as they go into effect. By accessing the Site or using the
                Services after we make any such changes to this Privacy Policy,
                you are deemed to have accepted such changes. Please refer back
                to this Privacy Policy on a regular basis.
              </p>
            </div>
            <div className="col-12">
              <h2>13. DISPUTE RESOLUTIONS/COMPLAINTS</h2>
              <p>
                Any dispute or claim that arises from the use of the Site or
                this Privacy Policy shall be resolved in accordance with the
                Terms of Use Agreement.
              </p>
            </div>
            <div className="col-12">
              <h2>14. HOW TO CONTACT US</h2>
              <p>
                If you have questions about this Privacy Policy, please email us
                at (Gmail Address), with “PRIVACY POLICY” in the subject line,
                or mail us at the following address: (Your Business Address)
              </p>
            </div>
          </div>
        </div>
      </section>
      <HomeFooter />
    </>
  );
}
