import React, { useEffect, useRef, useState } from "react";
import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import FilterDropdown from "../../Components/Modals/FilterDropdown";
import { Link, useLocation } from "react-router-dom";
import VendorLoader from "../../Helper/VendorLoader";
import JubalArtistCard from "../JubalArtist/JubalArtistCard";
import { ApiUrl } from "../../utility/ApiUrl";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { RoutesLink } from "../../utility/RoutesLink";
import { getAddress, getLoginUserCaption } from "../../Helper/Helper";

function FindTalant() {
  const loc = useLocation();
  const userDetails = useSelector((store) => store?.user?.userDetails);

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("Sort by");
  const [talents, setTalents] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortType, setSortType] = useState("");
  const [sortField, setSortField] = useState("");
  const [SuggestedTalents, setSuggestedTalents] = useState([]);
  const [activeTabs, setActiveTabs] = useState([]);
  const [filterdata, setFilterdata] = useState({});
  const [filterState, setFilterState] = useState({
    genre: "",
    city: "",
    hasInst: "",
    search: "",
    sort: "",
    topRated: "",
    topFollower: "",
  });

  const dropdownRef = useRef(null);

  const handleOutside = () => {
    if (dropdownRef.current) {
      dropdownRef.current.classList.remove("active");
    }
  };
  console.log(`${process.env.PUBLIC_URL}/images/defult.png`);

  const filterHandler = (value) => {
    let url;
    let search;
    if (value?.genre) {
      url = `filter=genre`;
      search = `&search=${value?.genre}`;
      // url.push(`genre=${value.genre}`);
    } else if (value?.city) {
      // url.push(`city=${value?.city}`);
      url = `filter=city`;
      search = `&search=${value?.city}`;
    } else if (value?.hasInst) {
      // url.push(`instrument=${value?.instrument}`);
      url = `filter=instrument-name`;
      search = `&search=${value?.hasInst}`;
    } else if (value?.topRated) {
      url = `filter=topRated`;
    } else if (value?.topFollower) {
      url = `filter=topFollower`;
    }
    if (value?.sort) {
      // url.push(`sort=${value?.sort}`);
    }
    if (value?.search) {
      // url.push(`search=${value?.search}`);
    }
    return url + (search ? search : "");
  };

  const getTalents = async (filter) => {
    setLoading(true);
    let filteredArray = [];
    if (filter) {
      filteredArray = filterHandler(filter);
    }

    const queryParams = [];
    queryParams.push(`?limit=20&sortType=${sortType}&sortField=${sortField}`);

    if (
      searchInput != undefined &&
      searchInput != null &&
      searchInput.length > 1
    ) {
      queryParams.push(`search=${searchInput}`);
    }

    if (
      filteredArray &&
      filteredArray != undefined &&
      filteredArray.length > 0
    ) {
      queryParams.push(filteredArray);
    }

    const Api = `${ApiUrl.fillter_user}${queryParams.join("&")}`;

    handleFetchRequest(Api, "GET")
      .then((res) => {
        setLoading(false);
        if (res.status === true) {
          setTalents(res?.data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getSuggestedTalents = async () => {
    handleFetchRequest(ApiUrl.fillter_user, "GET").then((res) => {
      if (res.status === true) {
        setSuggestedTalents(res?.data);
      }
    });
  };

  useEffect(() => {
    if (Object.values(filterState).every((value) => value === "")) {
      getTalents();
    } else {
      getTalents(filterState);
    }
  }, [filterState, searchInput, sortField, sortType]);

  const [isActive, setIsActive] = useState(false);

  const [genre, setGenre] = useState([]);
  const [instruments, setInstruments] = useState([]);

  const fetchGenre = async () => {
    handleFetchRequest(ApiUrl.get_music_type, "GET").then((res) => {
      if (res.status === true) {
        setGenre(res?.data);
      }
    });
  };

  const fetchInstruments = async () => {
    handleFetchRequest(ApiUrl.get_instruments, "POST").then((res) => {
      if (res.status === true) {
        setInstruments(res?.data?.instrument);
      }
    });
  };

  // Function to handle the input change
  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    fetchGenre();
    fetchInstruments();
    getSuggestedTalents();
  }, []);

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <section className="px-0 main-content">
        <div className="max-width-container">
          {/* =========================== */}
          {/* Main feed grid Start */}
          {/* =========================== */}
          <div className="main-feed-grid">
            {/* =========================== */}
            {/*left side content Start */}
            {/* =========================== */}
            <DashboardSidebar />
            <div className="center-content d-block">
              <div className="feed">
                <div className="filter-row position-relative mt-0">
                  <div className="d-flex align-item-center gap-4 flex-wrap flex-lg-nowrap justify-content-between w-100">
                    <div
                      className="d-flex align-items-center passfield-wrapper mb-0 w-100"
                      onClick={handleOutside}
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={18}
                          height={18}
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
                            fill="#818898"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="searchbar"
                        placeholder="What are you looking for?"
                        value={searchInput}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="inner-filter-row flex-sm-nowrap">
                      <div>
                        <FilterDropdown
                          genre={genre}
                          instruments={instruments}
                          setFilterState={setFilterState}
                          topFollower={true}
                          topRated={true}
                          dropdownRef={dropdownRef}
                          filter={filterdata}
                          setFilter={setFilterdata}
                          activeTabs={activeTabs}
                          setActiveTabs={setActiveTabs}
                        />
                      </div>

                      <div
                        className="dropdown dropdown-menu-end"
                        onClick={handleOutside}
                      >
                        <button
                          className="btn btn-light dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="15"
                              viewBox="0 0 19 15"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M18.0104 6.1634C17.713 6.4518 17.2382 6.4445 16.9499 6.1471L14.2383 3.3508V13.5C14.2383 13.9142 13.9025 14.25 13.4883 14.25C13.0741 14.25 12.7383 13.9142 12.7383 13.5V3.3508L10.0267 6.1471C9.7384 6.4445 9.26359 6.4518 8.96619 6.1634C8.66879 5.8751 8.6615 5.40025 8.9499 5.10289L12.9499 0.97789C13.0911 0.83222 13.2854 0.75 13.4883 0.75C13.6912 0.75 13.8855 0.83222 14.0267 0.97789L18.0267 5.10289C18.3151 5.40026 18.3078 5.8751 18.0104 6.1634Z"
                                fill="#AAAAAA"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M10.0104 8.8366C9.71298 8.5482 9.23818 8.5555 8.94988 8.8529L6.23828 11.6492V1.5C6.23828 1.08579 5.90249 0.75 5.48828 0.75C5.07407 0.75 4.73828 1.08579 4.73828 1.5V11.6492L2.02671 8.8529C1.73835 8.5555 1.26353 8.5482 0.966172 8.8366C0.668812 9.1249 0.661502 9.5997 0.949852 9.8971L4.94985 14.0221C5.09111 14.1678 5.28537 14.25 5.48828 14.25C5.69119 14.25 5.88545 14.1678 6.0267 14.0221L10.0267 9.8971C10.3151 9.5997 10.3078 9.1249 10.0104 8.8366Z"
                                fill="#111111"
                              />
                            </svg>
                          </span>
                          {filter}
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              to="#"
                              className="dropdown-item"
                              onClick={() => {
                                setSortField("firstName");
                                setSortType("ASC");
                              }}
                              // onClick={() => handleFilterChange("A to Z")}
                            >
                              A to Z
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setSortField("createdAt");
                                setSortType("DESC");
                              }}
                              // onClick={() => handleFilterChange("Newest")}
                            >
                              Newest
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setSortField("createdAt");
                                setSortType("ASC");
                              }}
                              // onClick={() => handleFilterChange("Oldest")}
                            >
                              Oldest
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <VendorLoader />
                ) : (
                  <div className="instruments-grid talent-grid">
                    {talents &&
                      talents.map((item, index) => {
                        return (
                          <div className="card account-card " key={index}>
                            <div className="card-body">
                              <Link
                                to={
                                  item?.User?.id === userDetails?.id
                                    ? RoutesLink?.MyProfile
                                    : RoutesLink?.profile
                                }
                                state={{
                                  id: item?.User?.id,
                                  eventId: loc.state,
                                }}
                              >
                                <div className="row m-0">
                                  <div className="col-12 p-0">
                                    <img
                                      src={
                                        item?.profilePhoto ||
                                        `${process.env.PUBLIC_URL}/images/defult.jpeg`
                                      }
                                      alt=""
                                      className="artist-img"
                                    />
                                  </div>
                                  <div className="col-12 p-0">
                                    <div className="d-flex justify-content-between align-items-start">
                                      <div className="user-det d-flex gap-2 gap-lg-3">
                                        <Link
                                          to={
                                            item?.User?.id ===
                                            userDetails?.Profile?.userId
                                              ? RoutesLink?.MyProfile
                                              : RoutesLink?.profile
                                          }
                                          state={{
                                            id: item?.User?.id,
                                            eventId: loc.state,
                                          }}
                                        >
                                          {item?.profilePhoto ? (
                                            <div className="user-dp">
                                              <img
                                                src={item?.profilePhoto}
                                                alt=""
                                              />
                                            </div>
                                          ) : (
                                            <span className="user-dp no-img">
                                              {item?.firstName[0]}
                                            </span>
                                          )}
                                        </Link>
                                        <div className="user-ac">
                                          <div className="d-flex gap-2 align-items-center">
                                            <span className="username">
                                              <Link
                                                to={
                                                  item?.User?.id ===
                                                  userDetails?.Profile?.userId
                                                    ? RoutesLink?.MyProfile
                                                    : RoutesLink?.profile
                                                }
                                                state={{
                                                  id: item?.User?.id,
                                                  eventId: loc.state,
                                                }}
                                              >
                                                {item?.firstName}{" "}
                                                {item?.lastName}
                                              </Link>
                                            </span>
                                            <span className="usertype mx-1">
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={13}
                                                  height={13}
                                                  viewBox="0 0 13 13"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="5.74979"
                                                    cy="6.72714"
                                                    r="3.75247"
                                                    fill="white"
                                                  />
                                                  <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                                    fill="#F4336D"
                                                  />
                                                </svg>
                                              </span>
                                              Talent
                                            </span>
                                          </div>
                                          <p>
                                            {getLoginUserCaption(item?.Talent)}
                                          </p>
                                        </div>
                                      </div>
                                      {item?.rating?.count > 0 && (
                                        <span className="rating">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={11}
                                            height={10}
                                            viewBox="0 0 11 10"
                                            fill="none"
                                          >
                                            <path
                                              d="M8.43405 9.29386C8.09885 9.53261 5.94806 8.01921 5.53614 8.01589C5.12421 8.01259 2.94921 9.49117 2.61792 9.24706C2.28663 9.00296 3.06548 6.49567 2.94136 6.10404C2.81724 5.7124 0.734809 4.1067 0.865254 3.7171C0.995727 3.3275 3.62786 3.29131 3.96306 3.05257C4.29826 2.81385 5.18627 0.342897 5.59822 0.346195C6.01012 0.349521 6.85801 2.83444 7.1893 3.07855C7.52059 3.32262 10.1518 3.40119 10.276 3.79283C10.4001 4.18447 8.29198 5.75644 8.16151 6.14604C8.03106 6.53565 8.76925 9.05512 8.43405 9.29386Z"
                                              fill="url(#paint0_linear_1709_1003)"
                                            />
                                            <defs>
                                              <linearGradient
                                                id="paint0_linear_1709_1003"
                                                x1="0.859387"
                                                y1="4.8328"
                                                x2="10.2812"
                                                y2="4.8328"
                                                gradientUnits="userSpaceOnUse"
                                              >
                                                <stop stopColor="#FCD635" />
                                                <stop
                                                  offset={1}
                                                  stopColor="#F7A928"
                                                />
                                              </linearGradient>
                                            </defs>
                                          </svg>
                                          {item?.rating?.count > 0 &&
                                            (typeof item?.rating === "object"
                                              ? item?.rating?.count
                                              : item?.rating?.toFixed(2))}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12 p-0">
                                    <div className="d-flex align-item-center justify-content-between gap-3">
                                      <span className="location">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={18}
                                          height={19}
                                          viewBox="0 0 18 19"
                                          fill="none"
                                        >
                                          <path
                                            d="M9.21289 11.5625C10.4156 11.5625 11.3906 10.5875 11.3906 9.38477C11.3906 8.18204 10.4156 7.20703 9.21289 7.20703C8.01016 7.20703 7.03516 8.18204 7.03516 9.38477C7.03516 10.5875 8.01016 11.5625 9.21289 11.5625Z"
                                            fill="#86889A"
                                          />
                                          <path
                                            d="M16.4714 8.66016H14.9694C14.8063 7.38277 14.2238 6.19566 13.3132 5.28508C12.4026 4.3745 11.2155 3.79196 9.93815 3.62886V2.12695H8.48633V3.62886C7.20894 3.79196 6.02184 4.3745 5.11125 5.28508C4.20067 6.19566 3.61814 7.38277 3.45504 8.66016H1.95312V10.112H3.45504C3.61814 11.3894 4.20067 12.5765 5.11125 13.4871C6.02184 14.3976 7.20894 14.9802 8.48633 15.1433V16.6452H9.93815V15.1433C11.2155 14.9802 12.4026 14.3976 13.3132 13.4871C14.2238 12.5765 14.8063 11.3894 14.9694 10.112H16.4714V8.66016ZM9.21224 13.7415C6.8102 13.7415 4.85677 11.7881 4.85677 9.38607C4.85677 6.98403 6.8102 5.0306 9.21224 5.0306C11.6143 5.0306 13.5677 6.98403 13.5677 9.38607C13.5677 11.7881 11.6143 13.7415 9.21224 13.7415Z"
                                            fill="#86889A"
                                          />
                                        </svg>
                                        <p>
                                          {getAddress(item?.location) ||
                                            "Location"}
                                        </p>
                                      </span>
                                      {item?.count > 0 && (
                                        <span className="followers">
                                          {item?.count} Followers
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FindTalant;
