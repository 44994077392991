import React from "react";
import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import moment from "moment";
import { RoutesLink } from "../../utility/RoutesLink";
import {
  OrderHelperStatus,
  eventStatusTypes,
  getLoginUserCaption,
} from "../../Helper/Helper";
import OrderTimeline from "./OrderTimeline";
import { toast } from "material-react-toastify";
import { Modal } from "reactstrap";
import { SvgIcons } from "../../Components/Svg/SvgIcons";

function SellerOrderDetials() {
  const [OrderData, setOrderData] = useState({});
  const location = useLocation();
  const [OrderUser, setOrderUser] = useState([]);
  const [Selectedreason, setSelectedreason] = useState("");
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [IsLoading, setIsLoading] = useState(false);
  const [callApi, setcallApi] = useState(true);
  const [courierName, setCourierName] = useState("");
  const [courierTrackingId, setCourierTrackingId] = useState("");
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");
  const [orderDetail, setOrderDetail] = useState(null);

  const expectDeliveryDate = moment(OrderData?.expectedDeliveryDate);
  const currentDate = moment();
  const daysCount = expectDeliveryDate.diff(currentDate, "days");

  useEffect(() => {
    console.log("orderDetail", location?.state);
    if (callApi) {
      handleFetchRequest(
        `${ApiUrl.item_detials}${
          location?.state?.orderDetail?.id || location?.state?.OrderId
        }?itemId=${
          location?.state?.orderDetail?.OrderItems[0]?.itemId ||
          location?.state?.itemId
        }`
      ).then((res) => {
        if (res.status == true) {
          setOrderData(res?.data?.OrderItems[0]);
          setOrderUser(res?.data?.user);
          setOrderDetail(res?.data);
          setcallApi(false);
          console.log("ressssss===<<", res?.data);
        }
      });
    }
  }, [callApi]);

  console.log(OrderData?.images?.[0]);

  const OrderUpdate = (id, status, createdAt) => {
    const twoHoursLater = moment(createdAt).add(2, "hours");
    const currentTime = moment();

    // if (currentTime.isBefore(twoHoursLater)) {
    //   toast.info(
    //     `You can accept this order after ${twoHoursLater.format("LLL")}`
    //   );
    //   return;
    // }

    handleFetchRequest(
      `${ApiUrl?.update_order_status}${id}?status=${status}`,
      "POST"
    ).then((res) => {
      if (res.status === true) {
        toast.success(res?.message);
        setcallApi(true);
      }
    });
  };

  const reject = (createdAt) => {
    const twoHoursLater = moment(createdAt).add(2, "hours");
    const currentTime = moment();

    if (currentTime.isBefore(twoHoursLater)) {
      toast.info(
        `You can reject this order after ${twoHoursLater.format("LLL")}`
      );
      return;
    }
    toggle1();
  };

  const RejectOrder = (id, status) => {
    if (Selectedreason.trim().length <= 0) {
      toast.error("please enter your Reason");
      return;
    } else {
      setIsLoading(true);
      const resondata = {
        sellerReason: Selectedreason,
      };
      handleFetchRequest(
        `${ApiUrl?.update_order_status}${id}?status=${status}`,
        "POST",
        resondata
      ).then((res) => {
        if (res.status == true) {
          toggle1();
          setIsLoading(false);
          setcallApi(true);
        } else {
          toast.error(res?.message);
          setIsLoading(false);
        }
      });
    }
  };

  const ShipOrder = (id, status) => {
    const getTodayDate = () => {
      const today = new Date();
      return today.toISOString().split("T")[0]; // Extract YYYY-MM-DD
    };

    // Function to handle order rejection and validate data

    // Check if all fields are filled
    if (!courierName || !courierTrackingId || !expectedDeliveryDate) {
      toast.error("All fields are required.");
      return;
    }

    // Check if the expected delivery date is not less than today
    if (new Date(expectedDeliveryDate) < new Date(getTodayDate())) {
      toast.error("Expected Delivery Date cannot be earlier than today.");
      return;
    }

    const resondata = {
      courierName,
      couriertTrackingId: courierTrackingId,
      expectedDeliveryDate,
    };
    handleFetchRequest(
      `${ApiUrl?.update_order_status}${id}?status=${status}`,
      "POST",
      resondata
    ).then((res) => {
      if (res.status == true) {
        toggle();
        setIsLoading(false);
        setcallApi(true);
      } else {
        toast.error(res?.message);
        setIsLoading(false);
      }
    });
  };

  const orderStatuses = [
    { status: OrderHelperStatus.placed, label: "Placed successfully" },
    { status: OrderHelperStatus.cancelled, label: "Order Cancelled" },
    { status: OrderHelperStatus.confirmed, label: "Order Confirmed" },
    { status: OrderHelperStatus.shipped, label: "Order Shipped" },
    { status: OrderHelperStatus.delivered, label: "Order Delivered" },
    { status: OrderHelperStatus.refund_requested, label: "Refund Requested" },
    { status: OrderHelperStatus.refunded, label: "Refunded" },
    { status: OrderHelperStatus.refundCancelled, label: "Refund Cancelled" },
  ];
  const getOrderTimeline = () => {
    let stopTimeline = false;
    let deliveredReached = false;
    let isCancelled = false;

    return orderStatuses.map((statusItem) => {
      if (stopTimeline) return null; // Stop rendering further steps if the condition is met

      const order = OrderData?.Statuses?.find(
        (order) => order.status === statusItem.status
      );
      const isActive = order ? "active" : ""; // Apply "active" class if the order status exists

      // Check if the previous status is "confirmed" and skip "cancelled" status
      if (isCancelled && statusItem.status === OrderHelperStatus.cancelled) {
        return null; // Skip rendering "cancelled"
      }

      // If "placed" status exists
      if (statusItem.status === OrderHelperStatus.placed && order) {
        return (
          <div key={statusItem.status} className={`step ${isActive}`}>
            <div className="date">
              <h6>Placed successfully</h6>
              {order && <span>{moment(order.createdAt).format("lll")}</span>}
            </div>
          </div>
        );
      }

      // If "confirmed" status exists
      if (statusItem.status === OrderHelperStatus.confirmed && order) {
        isCancelled = true; // If confirmed, it means the order cannot be cancelled
        return (
          <div key={statusItem.status} className={`step ${isActive}`}>
            <div className="date">
              <h6>{statusItem.label}</h6>
              {order && <span>{moment(order.createdAt).format("lll")}</span>}
            </div>
          </div>
        );
      }

      // If "delivered" status exists, check if "refund_requested" comes after
      if (statusItem.status === OrderHelperStatus.delivered && order) {
        deliveredReached = true;
        return (
          <div key={statusItem.status} className={`step ${isActive}`}>
            <div className="date">
              <h6>{statusItem.label}</h6>
              {order && <span>{moment(order.createdAt).format("lll")}</span>}
            </div>
          </div>
        );
      }

      // After "delivered", allow the timeline to continue if "refund_requested" is present
      if (deliveredReached) {
        // Check for "refund_requested" status
        if (statusItem.status === OrderHelperStatus.refund_requested && order) {
          return (
            <div key={statusItem.status} className={`step ${isActive}`}>
              <div className="date">
                <h6>{statusItem.label}</h6>
                {order && <span>{moment(order.createdAt).format("lll")}</span>}
              </div>
            </div>
          );
        }

        // Render "refunded" or "refundCancelled" statuses
        if (
          (statusItem.status === OrderHelperStatus.refunded ||
            statusItem.status === OrderHelperStatus.refundCancelled) &&
          order
        ) {
          return (
            <div key={statusItem.status} className={`step ${isActive}`}>
              <div className="date">
                <h6>{statusItem.label}</h6>
                {order && <span>{moment(order.createdAt).format("lll")}</span>}
              </div>
            </div>
          );
        }

        // Stop the timeline if no refund request or cancellation status is found
        stopTimeline = true;
        return null;
      }

      // Regular rendering for other statuses that haven't been cancelled
      if (order) {
        return (
          <div key={statusItem.status} className={`step ${isActive}`}>
            <div className="date">
              <h6>{statusItem.label}</h6>
              {order && <span>{moment(order.createdAt).format("lll")}</span>}
            </div>
          </div>
        );
      }

      return null; // No rendering if no order found for the status
    });
  };

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <section className="px-0 main-content">
        <div className="max-width-container">
          {/* =========================== */}
          {/* Main feed grid Start */}
          {/* =========================== */}
          <div className="main-feed-grid">
            {/* =========================== */}
            {/*left side content Start */}
            {/* =========================== */}
            <DashboardSidebar />
            {/* =========================== */}
            {/*left side content end */}
            {/* =========================== */}
            {/* =========================== */}
            {/* center content Start */}
            {/* =========================== */}
            <div className="center-content d-block">
              <div className="card tab-card activity-card manage-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="filter-row position-relative">
                        <div className="d-flex align-items-center justify-content-between w-100  flex-wrap row-gap-3">
                          <p>
                            My Orders / {OrderData?.itemDetail?.instrument_name}{" "}
                            ({OrderData?.itemDetail?.brand})
                          </p>
                          <div className="d-flex align-items-center gap-3">
                            <Link
                              to={RoutesLink?.bill}
                              state={{
                                Orderdata: OrderData,
                                userdata: OrderData?.itemDetail?.User,
                                cardDetials: orderDetail?.cardDetail,
                                orderId: orderDetail?.orderId,
                                userDetail: OrderUser,
                              }}
                              className=" btn-white d-flex align-items-center gap-3"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M5.5 14.5H4C3.60218 14.5 3.22064 14.342 2.93934 14.0607C2.65804 13.7794 2.5 13.3978 2.5 13V9.25C2.5 8.85218 2.65804 8.47064 2.93934 8.18934C3.22064 7.90804 3.60218 7.75 4 7.75H16C16.3978 7.75 16.7794 7.90804 17.0607 8.18934C17.342 8.47064 17.5 8.85218 17.5 9.25V13C17.5 13.3978 17.342 13.7794 17.0607 14.0607C16.7794 14.342 16.3978 14.5 16 14.5H14.5"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.5 11.5H5.5V17.5H14.5V11.5Z"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.5 7.75V2.5H14.5V7.75"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              Print invoice
                            </Link>
                            {/* <button className=" btn-white d-flex align-items-center gap-3">
                              Share
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M14.5 17.5C15.7426 17.5 16.75 16.4926 16.75 15.25C16.75 14.0074 15.7426 13 14.5 13C13.2574 13 12.25 14.0074 12.25 15.25C12.25 16.4926 13.2574 17.5 14.5 17.5Z"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.5 12.25C6.74264 12.25 7.75 11.2426 7.75 10C7.75 8.75736 6.74264 7.75 5.5 7.75C4.25736 7.75 3.25 8.75736 3.25 10C3.25 11.2426 4.25736 12.25 5.5 12.25Z"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.44238 11.1328L12.5649 14.1178"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.5 7C15.7426 7 16.75 5.99264 16.75 4.75C16.75 3.50736 15.7426 2.5 14.5 2.5C13.2574 2.5 12.25 3.50736 12.25 4.75C12.25 5.99264 13.2574 7 14.5 7Z"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12.5574 5.88281L7.44238 8.86781"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button> */}
                          </div>
                        </div>
                      </div>
                      <div className="card resource-card my-order-detail-card">
                        <div className="top-head">
                          <div className="user-det-head">
                            <div className="user-det d-flex gap-3">
                              <Link
                                to={RoutesLink?.profile}
                                state={{ id: OrderUser?.id }}
                              >
                                {OrderUser?.profilePhoto ? (
                                  <div className="user-dp">
                                    <img
                                      src={OrderUser?.profilePhoto}
                                      alt=""
                                    ></img>
                                  </div>
                                ) : (
                                  <div className="user-dp no-img">
                                    {OrderUser?.firstName?.[0]?.toUpperCase()}
                                  </div>
                                )}
                              </Link>
                              <div className="user-ac">
                                <div className="d-flex gap-2 align-items-center">
                                  <span className="username">
                                    <Link
                                      to={RoutesLink?.profile}
                                      state={{ id: OrderUser?.id }}
                                    >
                                      {OrderUser?.firstName}{" "}
                                      {OrderUser?.lastName}
                                    </Link>
                                  </span>
                                  <span className="usertype vendor mx-1">
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="13"
                                        height="13"
                                        viewBox="0 0 13 13"
                                        fill="none"
                                      >
                                        <circle
                                          cx="5.74979"
                                          cy="6.72714"
                                          r="3.75247"
                                          fill="white"
                                        ></circle>
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                          fill="#F4336D"
                                        ></path>
                                      </svg>
                                    </span>
                                    Vendor
                                  </span>
                                </div>
                                <p>{getLoginUserCaption(OrderUser)}</p>
                              </div>
                            </div>
                          </div>
                          <h2>
                            Order ID - {location?.state?.orderDetail?.orderId}
                          </h2>
                        </div>
                        <div className="card-body">
                          <div className="ordered-item row">
                            <div className="col-12 p-0 col-md-7">
                              <div className="d-flex align-items-center gap-3 ">
                                <Link
                                  to={RoutesLink?.InstrumentsDetails}
                                  state={{
                                    instrumentdata: OrderData?.itemDetail?.id,
                                  }}
                                >
                                  <img
                                    src={
                                      OrderData?.itemDetail
                                        ?.instrument_image?.[0]
                                    }
                                    alt=""
                                  ></img>
                                </Link>
                                <div className="d-flex flex-column gap-1 gap-md-2 gap-lg-3">
                                  <Link
                                    to={RoutesLink?.InstrumentsDetails}
                                    state={{
                                      instrumentdata: OrderData?.itemDetail?.id,
                                    }}
                                  >
                                    <h3>
                                      {OrderData?.itemDetail?.instrument_name}
                                    </h3>
                                  </Link>
                                  <span>{OrderData?.itemDetail?.brand}</span>
                                  <h3>
                                    $ {OrderData?.itemDetail?.instrument_price}
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0 col-md-5">
                              <div className="d-flex flex-column align-items-end gap-2 gap-md-3">
                                <span className={"badge in-progress"}>
                                  {OrderData?.status}
                                </span>

                                {/* <OrderTimeline timeline={OrderData?.Statuses} /> */}
                                <span>
                                  {moment(orderDetail?.createdAt).format("LLL")}
                                </span>
                              </div>

                              {OrderData?.status ==
                                OrderHelperStatus?.placed && (
                                <div className="d-flex align-items-center gap-2 justify-content-end mt-4">
                                  <button
                                    onClick={() =>
                                      OrderUpdate(
                                        OrderData?.id,
                                        OrderHelperStatus?.confirmed,
                                        orderDetail?.createdAt
                                      )
                                    }
                                    className="btn btn-success"
                                  >
                                    Accept
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() =>
                                      reject(orderDetail?.createdAt)
                                    }
                                  >
                                    Reject
                                  </button>
                                </div>
                              )}

                              {OrderData?.status ==
                                OrderHelperStatus?.confirmed && (
                                <div className="d-flex align-items-center gap-2 justify-content-end mt-4">
                                  <button
                                    onClick={toggle}
                                    className="btn btn-outline-primary"
                                  >
                                    Ship Order
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          {OrderData?.status ==
                            OrderHelperStatus?.refund_requested ||
                            (OrderData?.status == "refundRequested" && (
                              <div className="alert alert-danger" role="alert">
                                <div className="d-flex align-items-center gap-3 gap-md-4 gap-xl-5 ">
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="46"
                                      height="48"
                                      viewBox="0 0 46 48"
                                      fill="none"
                                    >
                                      <path
                                        d="M42.9812 0C42.1513 0 41.3931 0.338109 40.8501 0.881132C40.307 1.42416 39.9689 2.18234 39.9689 3.01224V47.827H4.09863V3.01224C4.09863 1.35244 5.45107 0 7.11088 0H42.9812Z"
                                        fill="#F2F2F3"
                                      />
                                      <path
                                        d="M46.003 3.01224V19.8357H39.9785V3.01224C39.9785 2.18234 40.3166 1.42416 40.8596 0.881132C41.4027 0.338109 42.1609 0 42.9908 0C44.6506 0 46.003 1.35244 46.003 3.01224Z"
                                        fill="#E6E6E6"
                                      />
                                      <path
                                        d="M46.003 3.01224V19.8357H39.9785V3.01224C39.9785 2.18234 40.3166 1.42416 40.8596 0.881132C41.4027 0.338109 42.1609 0 42.9908 0C44.6506 0 46.003 1.35244 46.003 3.01224Z"
                                        fill="#E6E6E6"
                                      />
                                      <path
                                        d="M33.9952 12.4797H10.0816C9.47715 12.4797 8.98535 11.9879 8.98535 11.3834C8.98535 10.7789 9.47715 10.2871 10.0816 10.2871H33.9952C34.5997 10.2871 35.0915 10.7789 35.0915 11.3834C35.0812 11.9879 34.5997 12.4797 33.9952 12.4797ZM33.9952 18.7398H10.0816C9.47715 18.7398 8.98535 18.248 8.98535 17.6435C8.98535 17.039 9.47715 16.5472 10.0816 16.5472H33.9952C34.5997 16.5472 35.0915 17.039 35.0915 17.6435C35.0812 18.2583 34.5997 18.7398 33.9952 18.7398ZM33.9952 25.0102H10.0816C9.47715 25.0102 8.98535 24.5184 8.98535 23.9139C8.98535 23.3094 9.47715 22.8176 10.0816 22.8176H33.9952C34.5997 22.8176 35.0915 23.3094 35.0915 23.9139C35.0812 24.5184 34.5997 25.0102 33.9952 25.0102ZM33.9952 31.2704H10.0816C9.47715 31.2704 8.98535 30.7786 8.98535 30.1741C8.98535 29.5696 9.47715 29.0778 10.0816 29.0778H33.9952C34.5997 29.0778 35.0915 29.5696 35.0915 30.1741C35.0812 30.7786 34.5997 31.2704 33.9952 31.2704ZM23.6367 37.5305H10.0816C9.47715 37.5305 8.98535 37.0387 8.98535 36.4342C8.98535 35.8297 9.47715 35.3379 10.0816 35.3379H23.6367C24.2412 35.3379 24.733 35.8297 24.733 36.4342C24.7228 37.0387 24.231 37.5305 23.6367 37.5305Z"
                                        fill="#502E6D"
                                      />
                                      <path
                                        d="M43.4114 24.8758L3.31989 33.6359C2.86908 33.7384 2.42851 33.4515 2.32606 33.0007L0.0207671 22.4783C-0.0816902 22.0275 0.20519 21.5869 0.656002 21.4845L40.7475 12.7141C41.1983 12.6117 41.6389 12.8985 41.7414 13.3494L44.0466 23.882C44.1491 24.3328 43.8622 24.7733 43.4114 24.8758Z"
                                        fill="#ED1F79"
                                      />
                                      <path
                                        d="M8.14564 23.338C7.57187 23.1946 6.94688 23.1946 6.26042 23.338L3.41211 23.963L4.78504 30.2232L7.63335 29.5982C8.30957 29.4445 8.88333 29.1884 9.34438 28.8195C9.80544 28.4507 10.1333 27.9896 10.328 27.4568C10.5226 26.9241 10.5431 26.34 10.4099 25.7151C10.2665 25.0798 10.0001 24.5573 9.61077 24.1577C9.21119 23.7581 8.72964 23.4815 8.14564 23.338ZM8.90382 27.129C8.80136 27.4568 8.60669 27.7335 8.3403 27.9589C8.07392 28.1843 7.72556 28.338 7.30549 28.4302L5.98379 28.7273L5.13339 24.8442L6.45509 24.5573C6.87516 24.4651 7.25426 24.4651 7.59237 24.5573C7.93047 24.6495 8.21736 24.8237 8.45301 25.0696C8.68866 25.3257 8.84234 25.6433 8.93456 26.0429C9.02677 26.4323 9.01652 26.8011 8.90382 27.129Z"
                                        fill="#F2F2F3"
                                      />
                                      <path
                                        d="M13.0534 25.6845L15.9632 25.0493L15.7173 23.9222L12.8075 24.5575L12.5104 23.1948L15.7992 22.4776L15.5431 21.3096L10.8096 22.3546L12.1825 28.6148L17.0287 27.5492L16.7828 26.3915L13.371 27.1394L13.0534 25.6845Z"
                                        fill="#F2F2F3"
                                      />
                                      <path
                                        d="M21.9568 23.8917L18.0122 20.777L16.8135 21.0332L18.1864 27.3036L19.6208 26.9859L18.7909 23.1848L22.7355 26.3097L23.9343 26.0433L22.5613 19.7832L21.1269 20.0906L21.9568 23.8917Z"
                                        fill="#F2F2F3"
                                      />
                                      <path
                                        d="M25.4953 19.1337L24.0439 19.4512L25.4145 25.7168L26.8659 25.3994L25.4953 19.1337Z"
                                        fill="#F2F2F3"
                                      />
                                      <path
                                        d="M29.2214 22.1503L32.1311 21.5151L31.8852 20.3881L28.9755 21.0233L28.6783 19.6606L31.9672 18.9434L31.7111 17.7754L26.9775 18.8102L28.3505 25.0806L33.1967 24.015L32.9508 22.8573L29.539 23.6052L29.2214 22.1503Z"
                                        fill="#F2F2F3"
                                      />
                                      <path
                                        d="M39.1699 17.6929C38.7703 17.2933 38.2887 17.0166 37.715 16.8732C37.1412 16.7298 36.5162 16.7298 35.8298 16.8732L32.9814 17.4982L34.3544 23.7583L37.2027 23.1333C37.8789 22.9797 38.4527 22.7235 38.9137 22.3547C39.3748 21.9858 39.7026 21.5248 39.8973 20.992C40.092 20.4592 40.1125 19.8752 39.9793 19.2502C39.8358 18.615 39.5592 18.1027 39.1699 17.6929ZM38.4732 20.6641C38.3707 20.992 38.176 21.2686 37.9096 21.494C37.6433 21.7194 37.2949 21.8731 36.8748 21.9653L35.5531 22.2522L34.7027 18.3691L36.0244 18.0822C36.4445 17.99 36.8236 17.99 37.1617 18.0822C37.4998 18.1744 37.7867 18.3486 38.0223 18.5945C38.258 18.8506 38.4117 19.1682 38.5039 19.5678C38.5859 19.9674 38.5756 20.3363 38.4732 20.6641Z"
                                        fill="#F2F2F3"
                                      />
                                    </svg>
                                  </div>
                                  <div>
                                    <h4>Item cancelled by user</h4>
                                    <p>
                                      Your item has been canceled by the user
                                      due to damage
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {/* <div className="alert alert-success" role="alert">
                            <div className="d-flex align-items-center gap-3 gap-md-4 gap-xl-5 ">
                              <div>
                                <svg
                                  width="58"
                                  height="58"
                                  viewBox="0 0 58 58"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="29"
                                    cy="29"
                                    r="28.5"
                                    fill="#D7ED7D"
                                    fill-opacity="0.31"
                                    stroke="#DAE8A2"
                                  />
                                  <path
                                    d="M43.0459 35.5335C44.1204 35.5335 44.9905 36.4036 44.9905 37.4781C44.9905 38.5526 44.1204 39.4256 43.0459 39.4256C44.1204 39.4256 44.9905 40.2956 44.9905 41.3701C44.9905 42.4447 44.1204 43.3147 43.0459 43.3147H34.812C33.3725 43.3147 31.9623 42.9147 30.736 42.1614C29.4834 41.3905 28.0439 40.9993 26.5869 41.011V31.7757C28.2483 31.6472 29.7461 30.7274 30.6017 29.2909C32.3682 26.3332 34.1084 23.1681 34.6485 19.3431C34.7303 18.7738 35.3084 18.4175 35.8574 18.6015C37.4166 19.1241 38.3223 20.6931 38.0589 22.9082C37.7503 25.5034 36.8585 27.7521 36.8585 27.7521H43.0459C44.1204 27.7521 44.9905 28.6222 44.9905 29.6967C44.9905 30.7712 44.1204 31.6442 43.0459 31.6442C44.1204 31.6442 44.9905 32.5143 44.9905 33.5888C44.9905 34.6632 44.1204 35.5335 43.0459 35.5335Z"
                                    fill="#B3DA16"
                                  />
                                  <path
                                    opacity="0.08"
                                    d="M43.0463 35.5351C44.1208 35.5351 44.9909 36.4052 44.9909 37.4797C44.9909 38.5542 44.1208 39.4272 43.0463 39.4272C44.1208 39.4272 44.9909 40.2973 44.9909 41.3718C44.9909 42.4463 44.1208 43.3164 43.0463 43.3164H40.4302C41.5047 43.3164 42.3748 42.4462 42.3748 41.3718C42.3748 40.2973 41.5047 39.4272 40.4302 39.4272C41.5047 39.4272 42.3748 38.5542 42.3748 37.4797C42.3748 36.4052 41.5047 35.5351 40.4302 35.5351C41.5047 35.5351 42.3748 34.6651 42.3748 33.5906C42.3748 32.516 41.5047 31.646 40.4302 31.646C41.5047 31.646 42.3748 30.7729 42.3748 29.6985C42.3748 28.624 41.5047 27.7539 40.4302 27.7539H43.0463C44.1208 27.7539 44.9909 28.624 44.9909 29.6985C44.9909 30.773 44.1208 31.646 43.0463 31.646C44.1208 31.646 44.9909 32.5161 44.9909 33.5906C44.9909 34.665 44.1208 35.5351 43.0463 35.5351Z"
                                    fill="#191E02"
                                  />
                                  <path
                                    d="M28.275 31.2734V41.5175C28.275 42.1626 27.7521 42.6855 27.1071 42.6855H22.8266C22.5041 42.6855 22.2427 42.4241 22.2427 42.1015V30.6894C22.2427 30.3669 22.5041 30.1055 22.8266 30.1055H27.1071C27.7521 30.1055 28.275 30.6283 28.275 31.2734Z"
                                    fill="#90B10C"
                                  />
                                  <path
                                    opacity="0.08"
                                    d="M28.2756 31.2734V41.519C28.2756 42.1643 27.7529 42.6869 27.1076 42.6869H25.8784C26.5237 42.6869 27.0464 42.1643 27.0464 41.519V31.2734C27.0464 30.6281 26.5237 30.1055 25.8784 30.1055H27.1076C27.7529 30.1055 28.2756 30.6281 28.2756 31.2734Z"
                                    fill="black"
                                  />
                                  <path
                                    opacity="0.4"
                                    d="M24.3682 31.5699V33.094C24.3682 33.4109 24.1114 33.6678 23.7945 33.6678C23.4776 33.6678 23.2207 33.4109 23.2207 33.094V31.5699C23.2207 31.253 23.4776 30.9961 23.7945 30.9961C24.1114 30.9962 24.3682 31.253 24.3682 31.5699Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M28.3226 16.8026V27.0367C28.3226 27.6767 27.5104 27.9513 27.1221 27.4426L24.928 24.5684H13.9042C13.1653 24.5684 12.5664 23.9694 12.5664 23.2307V16.8026C12.5664 16.0638 13.1654 15.4648 13.9042 15.4648H26.9849C27.7237 15.4648 28.3226 16.0638 28.3226 16.8026Z"
                                    fill="#FA3131"
                                  />
                                  <path
                                    opacity="0.08"
                                    d="M28.3238 16.8026V27.0367C28.3238 27.6755 27.5111 27.9497 27.1231 27.4414L26.3973 26.4915V16.8026C26.3973 16.0635 25.7987 15.4648 25.0596 15.4648H26.986C27.7251 15.4648 28.3238 16.0635 28.3238 16.8026Z"
                                    fill="black"
                                  />
                                  <path
                                    opacity="0.4"
                                    d="M15.0992 17.5601V19.0842C15.0992 19.4011 14.8423 19.658 14.5254 19.658C14.2085 19.658 13.9517 19.4011 13.9517 19.0842V17.5601C13.9517 17.2432 14.2085 16.9863 14.5254 16.9863C14.8423 16.9863 15.0992 17.2432 15.0992 17.5601Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M18.9851 21.9511L17.8518 20.5685C17.6176 20.2827 17.6594 19.8612 17.945 19.6271C18.2308 19.3927 18.652 19.4345 18.8865 19.7203L19.5516 20.5317L22.0479 18.0358C22.3088 17.7745 22.7328 17.7745 22.9937 18.0358C23.255 18.2969 23.255 18.7205 22.9937 18.9817L19.9754 21.9999C19.6953 22.2799 19.2358 22.2564 18.9851 21.9511Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                              <div>
                                <h4>Refund request submitted</h4>
                                <p>
                                  Your refund amount $345.00 will be initiated
                                  after return pickup
                                </p>
                              </div>
                            </div>
                          </div> */}
                          {/* {console.log("sellerReason", OrderData)} */}

                          {OrderData?.reason == null ? (
                            ""
                          ) : (
                            <div className="alert alert-danger" role="alert">
                              <div className="d-flex align-items-center gap-3 gap-md-4 gap-xl-5 ">
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="46"
                                    height="48"
                                    viewBox="0 0 46 48"
                                    fill="none"
                                  >
                                    <path
                                      d="M42.9812 0C42.1513 0 41.3931 0.338109 40.8501 0.881132C40.307 1.42416 39.9689 2.18234 39.9689 3.01224V47.827H4.09863V3.01224C4.09863 1.35244 5.45107 0 7.11088 0H42.9812Z"
                                      fill="#F2F2F3"
                                    />
                                    <path
                                      d="M46.003 3.01224V19.8357H39.9785V3.01224C39.9785 2.18234 40.3166 1.42416 40.8596 0.881132C41.4027 0.338109 42.1609 0 42.9908 0C44.6506 0 46.003 1.35244 46.003 3.01224Z"
                                      fill="#E6E6E6"
                                    />
                                    <path
                                      d="M46.003 3.01224V19.8357H39.9785V3.01224C39.9785 2.18234 40.3166 1.42416 40.8596 0.881132C41.4027 0.338109 42.1609 0 42.9908 0C44.6506 0 46.003 1.35244 46.003 3.01224Z"
                                      fill="#E6E6E6"
                                    />
                                    <path
                                      d="M33.9952 12.4797H10.0816C9.47715 12.4797 8.98535 11.9879 8.98535 11.3834C8.98535 10.7789 9.47715 10.2871 10.0816 10.2871H33.9952C34.5997 10.2871 35.0915 10.7789 35.0915 11.3834C35.0812 11.9879 34.5997 12.4797 33.9952 12.4797ZM33.9952 18.7398H10.0816C9.47715 18.7398 8.98535 18.248 8.98535 17.6435C8.98535 17.039 9.47715 16.5472 10.0816 16.5472H33.9952C34.5997 16.5472 35.0915 17.039 35.0915 17.6435C35.0812 18.2583 34.5997 18.7398 33.9952 18.7398ZM33.9952 25.0102H10.0816C9.47715 25.0102 8.98535 24.5184 8.98535 23.9139C8.98535 23.3094 9.47715 22.8176 10.0816 22.8176H33.9952C34.5997 22.8176 35.0915 23.3094 35.0915 23.9139C35.0812 24.5184 34.5997 25.0102 33.9952 25.0102ZM33.9952 31.2704H10.0816C9.47715 31.2704 8.98535 30.7786 8.98535 30.1741C8.98535 29.5696 9.47715 29.0778 10.0816 29.0778H33.9952C34.5997 29.0778 35.0915 29.5696 35.0915 30.1741C35.0812 30.7786 34.5997 31.2704 33.9952 31.2704ZM23.6367 37.5305H10.0816C9.47715 37.5305 8.98535 37.0387 8.98535 36.4342C8.98535 35.8297 9.47715 35.3379 10.0816 35.3379H23.6367C24.2412 35.3379 24.733 35.8297 24.733 36.4342C24.7228 37.0387 24.231 37.5305 23.6367 37.5305Z"
                                      fill="#502E6D"
                                    />
                                    <path
                                      d="M43.4114 24.8758L3.31989 33.6359C2.86908 33.7384 2.42851 33.4515 2.32606 33.0007L0.0207671 22.4783C-0.0816902 22.0275 0.20519 21.5869 0.656002 21.4845L40.7475 12.7141C41.1983 12.6117 41.6389 12.8985 41.7414 13.3494L44.0466 23.882C44.1491 24.3328 43.8622 24.7733 43.4114 24.8758Z"
                                      fill="#ED1F79"
                                    />
                                    <path
                                      d="M8.14564 23.338C7.57187 23.1946 6.94688 23.1946 6.26042 23.338L3.41211 23.963L4.78504 30.2232L7.63335 29.5982C8.30957 29.4445 8.88333 29.1884 9.34438 28.8195C9.80544 28.4507 10.1333 27.9896 10.328 27.4568C10.5226 26.9241 10.5431 26.34 10.4099 25.7151C10.2665 25.0798 10.0001 24.5573 9.61077 24.1577C9.21119 23.7581 8.72964 23.4815 8.14564 23.338ZM8.90382 27.129C8.80136 27.4568 8.60669 27.7335 8.3403 27.9589C8.07392 28.1843 7.72556 28.338 7.30549 28.4302L5.98379 28.7273L5.13339 24.8442L6.45509 24.5573C6.87516 24.4651 7.25426 24.4651 7.59237 24.5573C7.93047 24.6495 8.21736 24.8237 8.45301 25.0696C8.68866 25.3257 8.84234 25.6433 8.93456 26.0429C9.02677 26.4323 9.01652 26.8011 8.90382 27.129Z"
                                      fill="#F2F2F3"
                                    />
                                    <path
                                      d="M13.0534 25.6845L15.9632 25.0493L15.7173 23.9222L12.8075 24.5575L12.5104 23.1948L15.7992 22.4776L15.5431 21.3096L10.8096 22.3546L12.1825 28.6148L17.0287 27.5492L16.7828 26.3915L13.371 27.1394L13.0534 25.6845Z"
                                      fill="#F2F2F3"
                                    />
                                    <path
                                      d="M21.9568 23.8917L18.0122 20.777L16.8135 21.0332L18.1864 27.3036L19.6208 26.9859L18.7909 23.1848L22.7355 26.3097L23.9343 26.0433L22.5613 19.7832L21.1269 20.0906L21.9568 23.8917Z"
                                      fill="#F2F2F3"
                                    />
                                    <path
                                      d="M25.4953 19.1337L24.0439 19.4512L25.4145 25.7168L26.8659 25.3994L25.4953 19.1337Z"
                                      fill="#F2F2F3"
                                    />
                                    <path
                                      d="M29.2214 22.1503L32.1311 21.5151L31.8852 20.3881L28.9755 21.0233L28.6783 19.6606L31.9672 18.9434L31.7111 17.7754L26.9775 18.8102L28.3505 25.0806L33.1967 24.015L32.9508 22.8573L29.539 23.6052L29.2214 22.1503Z"
                                      fill="#F2F2F3"
                                    />
                                    <path
                                      d="M39.1699 17.6929C38.7703 17.2933 38.2887 17.0166 37.715 16.8732C37.1412 16.7298 36.5162 16.7298 35.8298 16.8732L32.9814 17.4982L34.3544 23.7583L37.2027 23.1333C37.8789 22.9797 38.4527 22.7235 38.9137 22.3547C39.3748 21.9858 39.7026 21.5248 39.8973 20.992C40.092 20.4592 40.1125 19.8752 39.9793 19.2502C39.8358 18.615 39.5592 18.1027 39.1699 17.6929ZM38.4732 20.6641C38.3707 20.992 38.176 21.2686 37.9096 21.494C37.6433 21.7194 37.2949 21.8731 36.8748 21.9653L35.5531 22.2522L34.7027 18.3691L36.0244 18.0822C36.4445 17.99 36.8236 17.99 37.1617 18.0822C37.4998 18.1744 37.7867 18.3486 38.0223 18.5945C38.258 18.8506 38.4117 19.1682 38.5039 19.5678C38.5859 19.9674 38.5756 20.3363 38.4732 20.6641Z"
                                      fill="#F2F2F3"
                                    />
                                  </svg>
                                </div>
                                <div>
                                  <h4>Item cancelled by user</h4>
                                  <p>
                                    Your item has been canceled by the User due
                                    to {OrderData?.reason}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="order-status">
                            <h2>Order status</h2>
                            <div className="order-status-card">
                              <div className="order-timeline">
                                {getOrderTimeline()}
                              </div>
                            </div>
                          </div>
                          {OrderData?.status == "shipped" && (
                            <p>
                              This package will be delivered in {daysCount}{" "}
                              days.
                            </p>
                          )}
                          <div className="row gap-5 gap-lg-0">
                            <div className="col-12 col-lg-6 p-0 pe-lg-4">
                              <div className="pay ">
                                <h2>Payment mode</h2>
                                <div className=" d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-start gap-3">
                                    <span>
                                      <svg
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="0.1875"
                                          y="0.822266"
                                          width="24.0234"
                                          height="23.8352"
                                          rx="11.9176"
                                          fill="#F2F2F4"
                                        ></rect>
                                        <path
                                          d="M15.4815 9.82227H8.9169C8.51407 9.82227 8.1875 10.1488 8.1875 10.5517V14.9281C8.1875 15.3309 8.51407 15.6575 8.9169 15.6575H15.4815C15.8844 15.6575 16.2109 15.3309 16.2109 14.9281V10.5517C16.2109 10.1488 15.8844 9.82227 15.4815 9.82227Z"
                                          stroke="#343B4E"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          d="M8.1875 12.0098H16.2109"
                                          stroke="#343B4E"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                      </svg>
                                    </span>
                                    <div>
                                      <h5>{orderDetail?.cardDetail?.type}</h5>
                                      <p>
                                        {orderDetail?.cardDetail?.cardNumber
                                          ? `${"***** ".repeat(
                                              3
                                            )} ${orderDetail?.cardDetail.cardNumber.slice(
                                              -4
                                            )}`
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                  <h3>
                                    $
                                    {OrderData?.itemDetail?.selling_price?.toFixed(
                                      2
                                    )}
                                  </h3>
                                </div>
                              </div>
                              {OrderData?.note && (
                                <div className="note ">
                                  <h6>Delivery Instructions</h6>
                                  <p>{OrderData?.note}</p>
                                </div>
                              )}
                            </div>
                            {OrderData?.status ==
                              OrderHelperStatus.refund_requested && (
                              <div>
                                <h2 className="mb-4">Uploaded product image</h2>
                                <div className="product-imgs mt-0 ">
                                  <div className="uplaoded-images-wrapper">
                                    {OrderData?.images?.map((res, index) => (
                                      <div className="uploaded-img" key={index}>
                                        <img src={res} alt="" />
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="col-12 col-lg-6 p-0 pe-lg-4">
                                  {/* <div className="note ">
                                    <h6>Delivery Instructions</h6>
                                    <p>{OrderData?.note}</p>
                                  </div> */}
                                  <div className="pck-add ">
                                    <h6>Exchange address</h6>
                                    <p>
                                      Pickup will happen at the same address
                                    </p>
                                    <div className="address-card bg-white ">
                                      <div className="row">
                                        <div className="col-12 p-0 d-flex justify-content-between align-items-center">
                                          <div className="d-flex w-100 justify-content-between align-items-center">
                                            <span className="type-badge">
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="9"
                                                  height="9"
                                                  viewBox="0 0 9 9"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M16 16H0V15.2727H16V16ZM4.36364 2.18182V14.9091H0.727273V3.27273C0.727273 2.9834 0.842208 2.70592 1.04679 2.50134C1.25138 2.29675 1.52885 2.18182 1.81818 2.18182H4.36364ZM3.63636 13.0909H1.81818V13.8182H3.63636V13.0909ZM3.63636 10.9091H1.81818V11.6364H3.63636V10.9091ZM3.63636 8.72727H1.81818V9.45455H3.63636V8.72727ZM3.63636 6.54545H1.81818V7.27273H3.63636V6.54545ZM3.63636 4.36364H1.81818V5.09091H3.63636V4.36364ZM15.2727 3.27273V14.9091H11.6364V2.18182H14.1818C14.4711 2.18182 14.7486 2.29675 14.9532 2.50134C15.1578 2.70592 15.2727 2.9834 15.2727 3.27273ZM14.1818 13.0909H12.3636V13.8182H14.1818V13.0909ZM14.1818 10.9091H12.3636V11.6364H14.1818V10.9091ZM14.1818 8.72727H12.3636V9.45455H14.1818V8.72727ZM14.1818 6.54545H12.3636V7.27273H14.1818V6.54545ZM14.1818 4.36364H12.3636V5.09091H14.1818V4.36364ZM11.2727 1.09091V14.9091H9.81818V12C9.81818 11.9036 9.77987 11.8111 9.71168 11.7429C9.64348 11.6747 9.55099 11.6364 9.45455 11.6364H6.54545C6.44901 11.6364 6.35652 11.6747 6.28832 11.7429C6.22013 11.8111 6.18182 11.9036 6.18182 12V14.9091H4.72727V1.09091C4.72727 0.801582 4.84221 0.524105 5.04679 0.31952C5.25138 0.114935 5.52885 0 5.81818 0H10.1818C10.4711 0 10.7486 0.114935 10.9532 0.31952C11.1578 0.524105 11.2727 0.801582 11.2727 1.09091ZM7.63636 9.81818H6.18182V10.5455H7.63636V9.81818ZM7.63636 7.63636H6.18182V8.36364H7.63636V7.63636ZM7.63636 5.45455H6.18182V6.18182H7.63636V5.45455ZM7.63636 3.27273H6.18182V4H7.63636V3.27273ZM7.63636 1.09091H6.18182V1.81818H7.63636V1.09091ZM9.81818 9.81818H8.36364V10.5455H9.81818V9.81818ZM9.81818 7.63636H8.36364V8.36364H9.81818V7.63636ZM9.81818 5.45455H8.36364V6.18182H9.81818V5.45455ZM9.81818 3.27273H8.36364V4H9.81818V3.27273ZM9.81818 1.09091H8.36364V1.81818H9.81818V1.09091ZM6.90909 12.3636V14.9091H9.09091V12.3636H6.90909Z"
                                                    fill="black"
                                                  ></path>
                                                </svg>
                                              </span>

                                              {
                                                OrderUser?.shippingAddress[0]
                                                  ?.type
                                              }
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-12 p-0">
                                          <ul>
                                            <li>
                                              <p className="para">
                                                City:{" "}
                                                {
                                                  OrderUser?.shippingAddress[0]
                                                    ?.location?.city
                                                }
                                                ,
                                              </p>
                                              <p className="para">
                                                Address:{" "}
                                                {
                                                  OrderUser?.shippingAddress[0]
                                                    ?.location?.address
                                                }
                                              </p>
                                              <p className="para">
                                                Zip Code:{" "}
                                                {
                                                  OrderUser?.shippingAddress[0]
                                                    ?.zipcode
                                                }
                                              </p>
                                            </li>
                                            <li>
                                              <p className="para">
                                                Mobile:{" "}
                                                {
                                                  OrderUser?.shippingAddress[0]
                                                    ?.countryCode
                                                }{" "}
                                                {
                                                  OrderUser?.shippingAddress[0]
                                                    ?.phone
                                                }
                                              </p>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="refd-src ">
                                    <h6>Refund back to source</h6>
                                    <div className="refd-src-card d-flex align-items-center justify-content-between">
                                      <div className="d-flex align-items-start gap-3">
                                        <span>
                                          <svg
                                            width="25"
                                            height="25"
                                            viewBox="0 0 25 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              x="0.1875"
                                              y="0.822266"
                                              width="24.0234"
                                              height="23.8352"
                                              rx="11.9176"
                                              fill="#F2F2F4"
                                            ></rect>
                                            <path
                                              d="M15.4815 9.82227H8.9169C8.51407 9.82227 8.1875 10.1488 8.1875 10.5517V14.9281C8.1875 15.3309 8.51407 15.6575 8.9169 15.6575H15.4815C15.8844 15.6575 16.2109 15.3309 16.2109 14.9281V10.5517C16.2109 10.1488 15.8844 9.82227 15.4815 9.82227Z"
                                              stroke="#343B4E"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              d="M8.1875 12.0098H16.2109"
                                              stroke="#343B4E"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                          </svg>
                                        </span>
                                        <div>
                                          <h5>
                                            {orderDetail?.cardDetail?.type}
                                          </h5>
                                          <p>
                                            {orderDetail?.cardDetail?.cardNumber
                                              ? `${"***** ".repeat(
                                                  3
                                                )} ${orderDetail?.cardDetail.cardNumber.slice(
                                                  -4
                                                )}`
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                      <h3>$ {OrderData?.price?.toFixed(2)}</h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="col-12 col-lg-6 p-0 ps-lg-4">
                              <div className="pay-desc">
                                <div className="pay-sum">
                                  <h2>Order summary</h2>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Total Amount</p>
                                    <p>
                                      <span>
                                        $ {OrderData?.price?.toFixed(2)}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Service Fee</p>
                                    <p>
                                      <span>$0</span>
                                    </p>
                                  </div>
                                  <hr />
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h5>Payable Amount</h5>
                                    <h5>${OrderData?.price?.toFixed(2)}</h5>
                                  </div>
                                </div>
                              </div>
                              <div className="billing ">
                                <div className="row">
                                  <div className=" p-0 d-flex justify-content-between align-items-center">
                                    <h2 className="mb-3">Delivery address</h2>
                                  </div>
                                </div>
                                <div className="address-card bg-white ">
                                  <div className="row">
                                    <div className="col-12 p-0">
                                      <div className="d-flex">
                                        <span className="type-badge">
                                          <span>
                                            {orderDetail?.user
                                              ?.shippingAddress[0]?.type ===
                                            "home"
                                              ? SvgIcons?.HomeIcon
                                              : SvgIcons?.OfficeIcon}
                                          </span>
                                          {
                                            orderDetail?.user?.billingAddress[0]
                                              ?.type
                                          }
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12 p-0">
                                      <ul>
                                        <li>
                                          <p className="para">
                                            City:{" "}
                                            {
                                              orderDetail?.user
                                                ?.shippingAddress[0]?.location
                                                ?.city
                                            }
                                            ,
                                          </p>
                                          <p className="para">
                                            Address:{" "}
                                            {
                                              orderDetail?.user
                                                ?.shippingAddress[0]?.location
                                                ?.address
                                            }
                                          </p>
                                          <p className="para">
                                            Zip Code:
                                            {
                                              orderDetail?.user
                                                ?.shippingAddress[0]?.zipcode
                                            }
                                          </p>
                                        </li>
                                        <li>
                                          <p className="para">
                                            Mobile:
                                            {
                                              orderDetail?.user
                                                ?.shippingAddress[0]
                                                ?.countryCode
                                            }{" "}
                                            {
                                              orderDetail?.user
                                                ?.shippingAddress[0]?.phone
                                            }
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="billing mt-4">
                                <div className="row">
                                  <div className=" p-0 d-flex justify-content-between align-items-center">
                                    <h2 className="mb-3">Billing address</h2>
                                  </div>
                                </div>
                                <div className="address-card bg-white ">
                                  <div className="row">
                                    <div className="col-12 p-0">
                                      <div className="d-flex">
                                        <span className="type-badge">
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="9"
                                              height="9"
                                              viewBox="0 0 9 9"
                                              fill="none"
                                            >
                                              <path
                                                d="M16 16H0V15.2727H16V16ZM4.36364 2.18182V14.9091H0.727273V3.27273C0.727273 2.9834 0.842208 2.70592 1.04679 2.50134C1.25138 2.29675 1.52885 2.18182 1.81818 2.18182H4.36364ZM3.63636 13.0909H1.81818V13.8182H3.63636V13.0909ZM3.63636 10.9091H1.81818V11.6364H3.63636V10.9091ZM3.63636 8.72727H1.81818V9.45455H3.63636V8.72727ZM3.63636 6.54545H1.81818V7.27273H3.63636V6.54545ZM3.63636 4.36364H1.81818V5.09091H3.63636V4.36364ZM15.2727 3.27273V14.9091H11.6364V2.18182H14.1818C14.4711 2.18182 14.7486 2.29675 14.9532 2.50134C15.1578 2.70592 15.2727 2.9834 15.2727 3.27273ZM14.1818 13.0909H12.3636V13.8182H14.1818V13.0909ZM14.1818 10.9091H12.3636V11.6364H14.1818V10.9091ZM14.1818 8.72727H12.3636V9.45455H14.1818V8.72727ZM14.1818 6.54545H12.3636V7.27273H14.1818V6.54545ZM14.1818 4.36364H12.3636V5.09091H14.1818V4.36364ZM11.2727 1.09091V14.9091H9.81818V12C9.81818 11.9036 9.77987 11.8111 9.71168 11.7429C9.64348 11.6747 9.55099 11.6364 9.45455 11.6364H6.54545C6.44901 11.6364 6.35652 11.6747 6.28832 11.7429C6.22013 11.8111 6.18182 11.9036 6.18182 12V14.9091H4.72727V1.09091C4.72727 0.801582 4.84221 0.524105 5.04679 0.31952C5.25138 0.114935 5.52885 0 5.81818 0H10.1818C10.4711 0 10.7486 0.114935 10.9532 0.31952C11.1578 0.524105 11.2727 0.801582 11.2727 1.09091ZM7.63636 9.81818H6.18182V10.5455H7.63636V9.81818ZM7.63636 7.63636H6.18182V8.36364H7.63636V7.63636ZM7.63636 5.45455H6.18182V6.18182H7.63636V5.45455ZM7.63636 3.27273H6.18182V4H7.63636V3.27273ZM7.63636 1.09091H6.18182V1.81818H7.63636V1.09091ZM9.81818 9.81818H8.36364V10.5455H9.81818V9.81818ZM9.81818 7.63636H8.36364V8.36364H9.81818V7.63636ZM9.81818 5.45455H8.36364V6.18182H9.81818V5.45455ZM9.81818 3.27273H8.36364V4H9.81818V3.27273ZM9.81818 1.09091H8.36364V1.81818H9.81818V1.09091ZM6.90909 12.3636V14.9091H9.09091V12.3636H6.90909Z"
                                                fill="black"
                                              ></path>
                                            </svg>
                                          </span>
                                          {
                                            orderDetail?.user?.billingAddress[0]
                                              ?.type
                                          }
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12 p-0">
                                      <ul>
                                        <li>
                                          <p className="para">
                                            City:{" "}
                                            {
                                              orderDetail?.user
                                                ?.billingAddress[0]?.location
                                                ?.city
                                            }
                                          </p>
                                          <p className="para">
                                            Address:{" "}
                                            {
                                              orderDetail?.user
                                                ?.billingAddress[0]?.location
                                                ?.address
                                            }
                                          </p>
                                          <p className="para">
                                            Zip Code:
                                            {
                                              orderDetail?.user
                                                ?.billingAddress[0]?.zipcode
                                            }
                                          </p>
                                        </li>
                                        <li>
                                          <p className="para">
                                            Mobile:
                                            {
                                              orderDetail?.user
                                                ?.billingAddress[0]?.countryCode
                                            }{" "}
                                            {
                                              orderDetail?.user
                                                ?.billingAddress[0]?.phone
                                            }
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* =========================== */}
            {/*center content end */}
            {/* =========================== */}
          </div>
          {/* =========================== */}
          {/* Main feed grid End */}
          {/* =========================== */}
        </div>

        <Modal
          isOpen={modal1}
          toggle={toggle1}
          className="bd-example-modal-lg-2 modal-dialog  modal-dialog-centered share-modal-dialog share-modal"
          centered
          backdrop="static"
          keyboard={false}
        >
          <div className="modal-header p-4 pb-3 border-0">
            <h3>Why you want to cancel Booking</h3>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggle1()}
            ></button>
          </div>
          <div className="modal-body p-4 ">
            <div className="mb-3 row align-items-center">
              <div className="mb-3 row align-items-center">
                <label className="col-sm-12 col-form-label" htmlFor="fnf2">
                  Remark
                  <span className="text-danger fs-2 ps-1">*</span>
                </label>
                <div className="col-sm-12">
                  <textarea
                    type="text"
                    className="form-control"
                    id="fnf2"
                    placeholder="Add your remark"
                    required
                    onChange={(e) => setSelectedreason(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-center gap-3">
              <div className="col-12 col-md-5">
                <button
                  className="btn btn-primary w-100"
                  onClick={() =>
                    RejectOrder(OrderData?.id, OrderHelperStatus?.cancelled)
                  }
                >
                  Submit
                </button>
              </div>
              <div className="col-12 col-md-5">
                <button
                  onClick={() => toggle1()}
                  className="btn btn-light w-100"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={modal}
          toggle={toggle}
          className="bd-example-modal-lg-2 modal-dialog  modal-dialog-centered share-modal-dialog share-modal"
          centered
          backdrop="static"
          keyboard={false}
        >
          <div className="modal-header p-4 pb-3 border-0">
            <h3>Please enter these details</h3>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div className="modal-body p-4 ">
            <div className="mb-3 row align-items-center">
              <label className="col-sm-12 col-form-label" htmlFor="courierName">
                Courier Name
              </label>
              <div className="col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  id="courierName"
                  placeholder="Courier Name"
                  required
                  onChange={(e) => setCourierName(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-3 row align-items-center">
              <label
                className="col-sm-12 col-form-label"
                htmlFor="courierTrackingId"
              >
                Courier Tracking ID
              </label>
              <div className="col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  id="courierTrackingId"
                  placeholder="Courier Tracking ID"
                  required
                  onChange={(e) => setCourierTrackingId(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-3 row align-items-center">
              <label
                className="col-sm-12 col-form-label"
                htmlFor="expectedDeliveryDate"
              >
                Expected Delivery Date
              </label>
              <div className="col-sm-12">
                <input
                  type="date"
                  className="form-control"
                  id="expectedDeliveryDate"
                  required
                  onChange={(e) => setExpectedDeliveryDate(e.target.value)}
                />
              </div>
            </div>
            <div className="row justify-content-center gap-3">
              <div className="col-12 col-md-5">
                <button
                  className="btn btn-primary w-100"
                  onClick={() =>
                    ShipOrder(OrderData?.id, OrderHelperStatus?.shipped)
                  }
                >
                  Submit
                </button>
              </div>
              <div className="col-12 col-md-5">
                <button
                  onClick={() => toggle1()}
                  className="btn btn-light w-100"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </section>
    </>
  );
}

export default SellerOrderDetials;
