import { useEffect, useState } from "react"; // Importing necessary hooks
import ReactGoogleAutocomplete from "react-google-autocomplete"; // Importing Google Autocomplete component
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"; // Importing Reactstrap components for navigation and tabbing
import { SvgIcons } from "../Svg/SvgIcons"; // Importing SVG icons
import GooglePlaceAutocomplete from "../GooglePlaceAutocomplete"; // Importing custom Google Place Autocomplete component
import { Link } from "react-router-dom"; // Importing Link for navigation

export default function FilterDropdown({
  events, // List of events
  genre, // List of genres
  eventTypes, // List of event types
  nearMe, // Near me location filter
  zipcode, // Zip code filter
  setFilteredEvents, // Function to set filtered events
  setfilterData, // Function to set filter data
  eventfilter, // Current event filter
  getEvents, // Function to fetch events
  filterArray, // Array of filters
  topRated, // Filter for top-rated events
  topFollower, // Filter for top followers
  instruments, // List of instruments
  category, // List of categories
  brands, // List of brands
  setFilterState, // Function to set filter state
  dropdownRef, // Reference for the dropdown element
  activeTabs, // Array of active tab IDs
  setActiveTabs, // Function to set active tab IDs
  filter, // Current filter state
  setFilter, // Function to set filter state
  instrument_condition, // Condition for instruments
  secondaryCategory,
  instrumentNames,
  removeHandler,
  maximumTicketPrice,
}) {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY; // Fetching Google API key from environment variables
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage dropdown visibility

  // Search state variables
  const [genreSearch, setGenreSearch] = useState(""); // State for genre search input
  const [instrumentSearch, setInstrumentSearch] = useState(""); // State for instrument search input
  const [eventSearch, setEventSearch] = useState(""); // State for event search input
  const [brandSearch, setBrandSearch] = useState(""); // State for brand search input
  const [categorySearch, setCategorySearch] = useState(""); // State for category search input
  const [secondaryCategorySearch, setSecondaryCategorySearch] = useState("");
  const [instrumentNameSearch, setInstrumentNameSearch] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(null); // State for storing selected place data
  const [eventPricing, setEventPricing] = useState(null);
  const [rangeValue, setRangeValue] = useState(1);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [maximumTicketValue, setMaximumTicketValue] = useState(null);

  const handleMouseMove = (event) => {
    const slider = event.target;
    const sliderRect = slider.getBoundingClientRect();
    const sliderWidth = sliderRect.width;

    // Calculate the hovered value based on the mouse position on the slider
    const offsetX = event.clientX - sliderRect.left;
    const hoveredValue = Math.round((offsetX / sliderWidth) * 499) + 1;

    setRangeValue(hoveredValue);
    setTooltipVisible(true);
  };
  const handleMouseOut = () => {
    setTooltipVisible(false);
  };

  const cross = SvgIcons?.crossIcon; // Storing cross icon for use in the component

  // Function to toggle the active class for dropdown element
  const toggleDropdown = () => {
    if (dropdownRef?.current) {
      dropdownRef?.current?.classList?.toggle("active");
    }
  };

  // Function to handle filter changes and update state
  const filterChangeHandler = (value, keypair) => {
    setFilter((prev) => ({
      ...prev,
      [keypair]: value, // Update the specific filter key with new value
    }));
    console.log(filter);
  };

  // Function to apply the current filter state
  const searchFilter = () => {
    setFilterState(filter); // Set the filter state
    dropdownRef?.current?.classList?.remove("active"); // Hide the dropdown
  };

  // Function to cancel all filters and reset state
  const cancelFilter = () => {
    setFilter({}); // Reset filter state
    setActiveTabs([]); // Clear active tabs
    setFilterState({}); // Reset filter state
    dropdownRef?.current?.classList?.remove("active"); // Hide the dropdown
  };

  // Function to reset the filters and active tabs
  const reset = (e) => {
    setFilter({}); // Reset filter state
    setActiveTabs([]); // Clear active tabs
    setFilterState({}); // Reset filter state
  };

  // Function to remove a specific filter and update active tabs
  const removeFilterHandler = (tabId, keypair) => {
    setFilter((prev) => {
      const updatedFilter = { ...prev }; // Create a copy of the previous filter state
      delete updatedFilter[keypair]; // Remove the specified filter key
      return updatedFilter; // Return the updated filter state
    });

    setActiveTabs((prev) => prev.filter((tab) => tab !== tabId)); // Remove tabId from active tabs
    removeHandler(tabId, keypair);
  };

  // Function to toggle a tab and update filter state
  const toggleTab = (tabId, filterKey, filterValue) => {
    if (activeTabs.includes(tabId)) {
      return; // If tab is already active, do nothing
    } else {
      setActiveTabs((prev) => [...prev, tabId]); // Add tabId to active tabs
      filterChangeHandler(filterValue, filterKey); // Update filter state with new value
    }
  };

  // Effect hook to handle changes in selected place
  useEffect(() => {
    filterChangeHandler(selectedPlace?.city, "city"); // Update city filter when selected place changes
  }, [selectedPlace]); // Dependency array to re-run effect when selectedPlace changes

  // updated code for multipal category select
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSecondaryCategories, setSelectedSecondaryCategories] =
    useState([]);
  const [instrumentName, setInstrumentName] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState([]);
  // State for selected event types
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);

  const handleEventTypeChange = (eventType) => {
    setSelectedEventTypes((prevSelected) => {
      const newSelected = prevSelected.includes(eventType)
        ? prevSelected.filter((item) => item !== eventType) // Remove if already selected
        : [...prevSelected, eventType]; // Add if not selected

      // Call filterChangeHandler with the updated list of selected event types
      filterChangeHandler(newSelected, "eventType");

      return newSelected; // Return the updated array of selected event types
    });
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevSelected) => {
      const newSelected = prevSelected.includes(category)
        ? prevSelected.filter((item) => item !== category) // Remove if already selected
        : [...prevSelected, category]; // Add if not selected

      filterChangeHandler(newSelected, "primaryCategory");

      return newSelected;
    });
  };

  const handleSecondaryCategoryChange = (category) => {
    setSelectedSecondaryCategories((prevSelected) => {
      const newSelected = prevSelected.includes(category)
        ? prevSelected.filter((item) => item !== category) // Remove if already selected
        : [...prevSelected, category]; // Add if not selected

      filterChangeHandler(newSelected, "secondaryCategory");

      return newSelected;
    });
  };

  const handleInstrumentNameChange = (name) => {
    setInstrumentName((prevSelected) => {
      const newSelected = prevSelected.includes(name)
        ? prevSelected.filter((item) => item !== name) // Remove if already selected
        : [...prevSelected, name]; // Add if not selected

      filterChangeHandler(newSelected, "instrumentName");

      return newSelected;
    });
  };

  const handleInstrumentChange = (conditionValue) => {
    setSelectedConditions((prevSelected) => {
      const newSelected = prevSelected.includes(conditionValue)
        ? prevSelected.filter((item) => item !== conditionValue) // Remove if already selected
        : [...prevSelected, conditionValue]; // Add if not selected
      // Call filterChangeHandler with the updated list of selected conditions
      filterChangeHandler(newSelected, "hasInst"); // Make sure this is a flat array

      return newSelected; // Return the updated array of selected conditions
    });
  };

  const [selectedBrands, setSelectedBrands] = useState([]);
  const handleBrandChange = (brand) => {
    setSelectedBrands((prevSelected) => {
      const newSelected = prevSelected.includes(brand)
        ? prevSelected.filter((item) => item !== brand) // Remove if already selected
        : [...prevSelected, brand]; // Add if not selected

      filterChangeHandler(newSelected, "brand");

      return newSelected;
    });
  };

  // State for selected conditions
  const [selectedConditions, setSelectedConditions] = useState([]);
  const handleConditionChange = (conditionValue) => {
    setSelectedConditions((prevSelected) => {
      const newSelected = prevSelected.includes(conditionValue)
        ? prevSelected.filter((item) => item !== conditionValue) // Remove if already selected
        : [...prevSelected, conditionValue]; // Add if not selected

      filterChangeHandler(newSelected, "condition"); // Make sure this is a flat array

      return newSelected;
    });
  };

  // State for selected genres
  const [selectedGenres, setSelectedGenres] = useState([]);

  const handleGenreChange = (genre) => {
    setSelectedGenres((prevSelected) => {
      const newSelected = prevSelected.includes(genre)
        ? prevSelected.filter((item) => item !== genre) // Remove if already selected
        : [...prevSelected, genre]; // Add if not selected

      // Call filterChangeHandler with the updated list of selected genres
      filterChangeHandler(newSelected, "genre");

      return newSelected; // Return the updated array of selected genres
    });
  };

  return (
    <div className="filter-dropdown">
      <span
        //  className="btn"
        onClick={toggleDropdown}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="12"
          viewBox="0 0 21 12"
          fill="none"
        >
          <path
            d="M8.07872 11.8284H12.6918V9.86084H8.07872V11.8284ZM0.00585938 0.0231934V1.99072H20.7646V0.0231934H0.00585938ZM3.46566 6.90955H17.3048V4.94202H3.46566V6.90955Z"
            fill="#818898"
          ></path>
        </svg>{" "}
        Filter by
        {activeTabs?.length > 0 && (
          <span className="selected-num">( {activeTabs?.length} )</span>
        )}
      </span>

      <div
        className={`filter-dropdown-body`}
        ref={dropdownRef}
        // onClick={handleClickInside}
      >
        <div className="row">
          <div className="col-12 p-0 d-flex align-items-center justify-content-between">
            <h4>Filter by</h4>
            <button
              className="btn btn-link p-0"
              onClick={reset}
              disabled={activeTabs?.length < 1}
            >
              Reset filter
            </button>
          </div>

          <Nav className="gap-2 gap-lg-3 nav">
            {topRated && (
              <NavItem>
                <NavLink
                  onClick={() => toggleTab(9, "topRated", true)}
                  className={
                    activeTabs.includes(9) ? "option active" : "option"
                  }
                >
                  Top Rated
                  {activeTabs.includes(9) && (
                    <span onClick={() => removeFilterHandler(9, "topRated")}>
                      {cross}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            )}
            {topFollower && (
              <NavItem>
                <NavLink
                  onClick={() => toggleTab(10, "topFollower", true)}
                  className={
                    activeTabs.includes(10) ? "option active" : "option"
                  }
                >
                  Top Followers
                  {activeTabs.includes(10) && (
                    <span
                      onClick={() => removeFilterHandler(10, "topFollower")}
                    >
                      {cross}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            )}
            {genre && (
              <NavItem>
                <NavLink
                  onClick={() => toggleTab(1, "genre", genreSearch)}
                  className={
                    activeTabs.includes(1) ? "option active" : "option"
                  }
                >
                  Genre
                  {activeTabs.includes(1) && (
                    <span onClick={() => removeFilterHandler(1, "genre")}>
                      {cross}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            )}
            {eventTypes && (
              <NavItem>
                <NavLink
                  onClick={() => toggleTab(2, "eventType", eventSearch)}
                  className={
                    activeTabs.includes(2) ? "option active" : "option"
                  }
                >
                  Event Type
                  {activeTabs.includes(2) && (
                    <span onClick={() => removeFilterHandler(2, "eventType")}>
                      {cross}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink
                onClick={() => toggleTab(3, "city", eventSearch)}
                className={activeTabs.includes(3) ? "option active" : "option"}
              >
                City
                {activeTabs.includes(3) && (
                  <span onClick={() => removeFilterHandler(3, "city")}>
                    {cross}
                  </span>
                )}
              </NavLink>
            </NavItem>

            {instruments && (
              <NavItem>
                <NavLink
                  onClick={() => toggleTab(6, "instrument", instrumentSearch)}
                  className={
                    activeTabs.includes(6) ? "option active" : "option"
                  }
                >
                  Instrument
                  {activeTabs.includes(6) && (
                    <span onClick={() => removeFilterHandler(6, "instrument")}>
                      {cross}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            )}
            {category && (
              <NavItem>
                <NavLink
                  onClick={() => toggleTab(7, "category", categorySearch)}
                  className={
                    activeTabs.includes(7) ? "option active" : "option"
                  }
                >
                  Primary category
                  {activeTabs.includes(7) && (
                    <span onClick={() => removeFilterHandler(7, "category")}>
                      {cross}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            )}
            {secondaryCategory && (
              <NavItem>
                <NavLink
                  onClick={() =>
                    toggleTab(13, "secondaryCategory", secondaryCategorySearch)
                  }
                  className={
                    activeTabs.includes(13) ? "option active" : "option"
                  }
                >
                  Secondary category
                  {activeTabs.includes(13) && (
                    <span
                      onClick={() =>
                        removeFilterHandler(13, "secondaryCategory")
                      }
                    >
                      {cross}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            )}
            {instrumentNames && (
              <NavItem>
                <NavLink
                  onClick={() =>
                    toggleTab(14, "instrumentName", instrumentNameSearch)
                  }
                  className={
                    activeTabs.includes(14) ? "option active" : "option"
                  }
                >
                  Instrument
                  {activeTabs.includes(14) && (
                    <span
                      onClick={() => removeFilterHandler(14, "instrumentName")}
                    >
                      {cross}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            )}
            {brands && (
              <NavItem>
                <NavLink
                  onClick={() => toggleTab(8, "brand", brandSearch)}
                  className={
                    activeTabs.includes(8) ? "option active" : "option"
                  }
                >
                  Brand
                  {activeTabs.includes(8) && (
                    <span onClick={() => removeFilterHandler(8, "brand")}>
                      {cross}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            )}

            {nearMe && (
              <NavItem>
                <NavLink
                  onClick={() => toggleTab(11, "nearMe", true)}
                  className={
                    activeTabs.includes(11) ? "option active" : "option"
                  }
                >
                  Near Me
                  {activeTabs.includes(11) && (
                    <span onClick={() => removeFilterHandler(11, "nearMe")}>
                      {cross}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            )}

            {instrument_condition && (
              <NavItem>
                <NavLink
                  onClick={() => toggleTab(12, "condition", true)}
                  className={
                    activeTabs.includes(12) ? "option active" : "option"
                  }
                >
                  Instrument condition
                  {activeTabs.includes(12) && (
                    <span onClick={() => removeFilterHandler(12, "condition")}>
                      {cross}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            )}
            {eventTypes && (
              <NavItem>
                <NavLink
                  onClick={() => toggleTab(15, "eventPricing", eventPricing)}
                  className={
                    activeTabs.includes(15) ? "option active" : "option"
                  }
                >
                  Price
                  {activeTabs.includes(15) && (
                    <span
                      onClick={() => removeFilterHandler(15, "eventPricing")}
                    >
                      {cross}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            )}

            {eventTypes && (
              <NavItem>
                <NavLink
                  onClick={() => toggleTab(16, "eventDate")}
                  className={
                    activeTabs.includes(16) ? "option active" : "option"
                  }
                  id="date"
                >
                  Date
                  {activeTabs.includes(16) && (
                    <span onClick={() => removeFilterHandler(16, "eventDate")}>
                      {cross}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={activeTabs[activeTabs.length - 1]}>
            <TabPane tabId={1}>
              <div className="col-12 p-0">
                <form action="">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="d-flex align-items-center passfield-wrapper mb-0">
                        <div className="icon">{SvgIcons?.searchBarIcon}</div>
                        <input
                          type="text"
                          className="form-control"
                          id="searchbar"
                          placeholder="What are you looking for?"
                          value={genreSearch}
                          onChange={(e) => setGenreSearch(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-12 p-0">
                      <div className="opt-wrapper">
                        {genre
                          ?.filter((gen) =>
                            gen?.type
                              .toLowerCase()
                              .includes(genreSearch.toLowerCase())
                          )
                          ?.sort((a, b) => a.type.localeCompare(b.type)) // Sort A to Z
                          ?.map((gen) => (
                            <div
                              className="form-check gap-3 d-flex align-items-center justify-content-between"
                              key={gen?.id}
                            >
                              <label
                                className="form-check-label"
                                htmlFor={gen?.id}
                              >
                                {gen?.type}
                              </label>
                              <input
                                className="form-check-input type-1"
                                type="checkbox" // Changed to checkbox for multi-selection
                                value={gen?.type}
                                id={gen?.id}
                                checked={selectedGenres.includes(gen?.type)} // Check if this genre is selected
                                onChange={() => handleGenreChange(gen?.type)} // Handle checkbox change
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </TabPane>
            <TabPane tabId={2}>
              <div className="col-12 p-0">
                <form action="">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="d-flex align-items-center passfield-wrapper mb-0">
                        <div className="icon">{SvgIcons?.searchBarIcon}</div>
                        <input
                          type="text"
                          className="form-control"
                          id="searchbar"
                          placeholder="What are you looking for?"
                          value={eventSearch}
                          onChange={(e) => setEventSearch(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-12 p-0">
                      <div className="opt-wrapper">
                        {eventTypes
                          ?.filter((evt) =>
                            evt?.type
                              .toLowerCase()
                              .includes(eventSearch.toLowerCase())
                          )
                          ?.sort((a, b) => a.type.localeCompare(b.type)) // Sort A to Z
                          ?.map((evt) => (
                            <div
                              className="form-check gap-3 d-flex align-items-center justify-content-between"
                              key={evt?.id}
                            >
                              <label
                                className="form-check-label"
                                htmlFor={evt?.id}
                              >
                                {evt?.type}
                              </label>
                              <input
                                className="form-check-input type-1"
                                type="checkbox" // Changed to checkbox for multi-selection
                                value={evt?.type}
                                id={evt?.id}
                                checked={selectedEventTypes.includes(evt?.type)} // Check if this type is selected
                                onChange={() =>
                                  handleEventTypeChange(evt?.type)
                                } // Handle checkbox change
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </TabPane>
            <TabPane tabId={3}>
              <div className="col-12 p-0">
                <div className="d-flex align-items-center passfield-wrapper mb-0">
                  <div className="icon">{SvgIcons?.searchBarIcon}</div>
                  <GooglePlaceAutocomplete
                    selectedPlace={selectedPlace}
                    setSelectedPlace={setSelectedPlace}
                    placeholder="Search for a city"
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tabId={6}>
              <div className="col-12 p-0">
                <form action="">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="d-flex align-items-center passfield-wrapper mb-0">
                        <div className="icon">{SvgIcons?.searchBarIcon}</div>
                        <input
                          type="text"
                          className="form-control"
                          id="searchbar"
                          placeholder="What are you looking for?"
                          value={instrumentSearch}
                          onChange={(e) => setInstrumentSearch(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-12 p-0">
                      <div className="opt-wrapper">
                        {instruments
                          ?.filter((instrument) =>
                            instrument?.instrumentName
                              ?.toLowerCase()
                              ?.includes(instrumentSearch.toLowerCase())
                          )
                          ?.sort((a, b) =>
                            a.instrumentName.localeCompare(b.instrumentName)
                          ) // Sort A to Z
                          ?.map((instrument) => (
                            <div
                              className="form-check gap-3 d-flex align-items-center justify-content-between"
                              key={instrument?.id}
                            >
                              <label
                                className="form-check-label "
                                for={instrument?.id}
                              >
                                {instrument?.instrumentName}
                              </label>
                              <input
                                className="form-check-input type-1"
                                type="checkbox"
                                name="instrument"
                                value={instrument?.instrumentName}
                                id={instrument?.id}
                                // onChange={(e) =>
                                //   filterChangeHandler(e.target.value, "hasInst")
                                // }
                                onChange={() =>
                                  handleInstrumentChange(
                                    instrument?.instrumentName
                                  )
                                }
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </TabPane>
            <TabPane tabId={7}>
              <div className="col-12 p-0">
                <form action="">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="d-flex align-items-center passfield-wrapper mb-0">
                        <div className="icon">{SvgIcons?.searchBarIcon}</div>
                        <input
                          type="text"
                          className="form-control"
                          id="searchbar"
                          placeholder="What are you looking for?"
                          value={categorySearch}
                          onChange={(e) => setCategorySearch(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-12 p-0">
                      {/* <div className="opt-wrapper">
                        {category
                          ?.filter((cat) =>
                            cat?.category
                              ?.toLowerCase()
                              ?.includes(categorySearch.toLowerCase())
                          )
                          ?.sort((a, b) => a.category.localeCompare(b.category)) // Sort A to Z
                          ?.map((cat) => (
                            <div
                              className="form-check gap-3 d-flex align-items-center justify-content-between"
                              key={cat?.id}
                            >
                              <label
                                className="form-check-label "
                                for={cat?.id}
                              >
                                {cat?.category}
                              </label>
                              <input
                                className="form-check-input type-1"
                                type="radio"
                                name="category"
                                value={cat?.category}
                                id={cat?.id}
                                onChange={(e) =>
                                  filterChangeHandler(
                                    e.target.value,
                                    "primaryCategory"
                                  )
                                }
                              />
                            </div>
                          ))}
                      </div> */}
                      <div className="opt-wrapper">
                        {category
                          ?.filter((cat) =>
                            cat?.category
                              ?.toLowerCase()
                              ?.includes(categorySearch.toLowerCase())
                          )
                          ?.sort((a, b) => a.category.localeCompare(b.category)) // Sort A to Z
                          ?.map((cat) => (
                            <div
                              className="form-check gap-3 d-flex align-items-center justify-content-between"
                              key={cat?.id}
                            >
                              <label
                                className="form-check-label"
                                htmlFor={cat?.id}
                              >
                                {cat?.category}
                              </label>
                              <input
                                className="form-check-input type-1"
                                type="checkbox" // Change to checkbox for multiple selections
                                name="category"
                                value={cat?.category}
                                id={cat?.id}
                                checked={selectedCategories.includes(
                                  cat?.category
                                )} // Check if selected
                                onChange={() =>
                                  handleCategoryChange(cat?.category)
                                } // Handle change
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </TabPane>
            <TabPane tabId={13}>
              <div className="col-12 p-0">
                <form action="">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="d-flex align-items-center passfield-wrapper mb-0">
                        <div className="icon">{SvgIcons?.searchBarIcon}</div>
                        <input
                          type="text"
                          className="form-control"
                          id="searchbar"
                          placeholder="What are you looking for?"
                          value={secondaryCategorySearch}
                          onChange={(e) =>
                            setSecondaryCategorySearch(e.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div className="col-12 p-0">
                      <div className="opt-wrapper">
                        {secondaryCategory
                          ?.filter((cat) =>
                            cat?.category
                              ?.toLowerCase()
                              ?.includes(secondaryCategorySearch.toLowerCase())
                          )
                          ?.sort((a, b) => a.category.localeCompare(b.category)) // Sort A to Z
                          ?.map((cat) => (
                            <div
                              className="form-check gap-3 d-flex align-items-center justify-content-between"
                              key={cat?.id}
                            >
                              <label
                                className="form-check-label"
                                htmlFor={cat?.id}
                              >
                                {cat?.category}
                              </label>
                              <input
                                className="form-check-input type-1"
                                type="checkbox" // Change to checkbox for multiple selections
                                name="category"
                                value={cat?.category}
                                id={cat?.id}
                                checked={selectedSecondaryCategories.includes(
                                  cat?.category
                                )} // Check if selected
                                onChange={() =>
                                  handleSecondaryCategoryChange(cat?.category)
                                } // Handle change
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </TabPane>
            <TabPane tabId={14}>
              <div className="col-12 p-0">
                <form action="">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="d-flex align-items-center passfield-wrapper mb-0">
                        <div className="icon">{SvgIcons?.searchBarIcon}</div>
                        <input
                          type="text"
                          className="form-control"
                          id="searchbar"
                          placeholder="What are you looking for?"
                          value={setInstrumentNameSearch}
                          onChange={(e) =>
                            setInstrumentNameSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div className="col-12 p-0">
                      <div className="opt-wrapper">
                        {instrumentNames
                          ?.filter((cat) =>
                            cat?.instrumentName
                              ?.toLowerCase()
                              ?.includes(instrumentNameSearch.toLowerCase())
                          )
                          ?.sort((a, b) =>
                            a.instrumentName.localeCompare(b.instrumentName)
                          ) // Sort A to Z
                          ?.map((cat) => (
                            <div
                              className="form-check gap-3 d-flex align-items-center justify-content-between"
                              key={cat?.id}
                            >
                              <label
                                className="form-check-label"
                                htmlFor={cat?.id}
                              >
                                {cat?.instrumentName}
                              </label>
                              <input
                                className="form-check-input type-1"
                                type="checkbox" // Change to checkbox for multiple selections
                                name="category"
                                value={cat?.instrumentName}
                                id={cat?.id}
                                checked={instrumentName.includes(
                                  cat?.instrumentName
                                )} // Check if selected
                                onChange={() =>
                                  handleInstrumentNameChange(
                                    cat?.instrumentName
                                  )
                                } // Handle change
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </TabPane>
            <TabPane tabId={8}>
              <div className="col-12 p-0">
                <form action="">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="d-flex align-items-center passfield-wrapper mb-0">
                        <div className="icon">{SvgIcons?.searchBarIcon}</div>
                        <input
                          type="text"
                          className="form-control"
                          id="searchbar"
                          placeholder="What are you looking for?"
                          value={brandSearch}
                          onChange={(e) => setBrandSearch(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-12 p-0">
                      <div className="opt-wrapper">
                        {brands
                          ?.filter((brand) =>
                            brand?.brand
                              .toLowerCase()
                              .includes(brandSearch.toLowerCase())
                          )
                          ?.sort((a, b) => a.brand.localeCompare(b.brand)) // Sort A to Z
                          ?.map((brand) => (
                            <div
                              className="form-check gap-3 d-flex align-items-center justify-content-between"
                              key={brand?.id}
                            >
                              <label
                                className="form-check-label"
                                htmlFor={brand?.id}
                              >
                                {brand?.brand}
                              </label>
                              <input
                                className="form-check-input type-1"
                                type="checkbox" // Change to checkbox for multiple selections
                                name="brand"
                                value={brand?.brand}
                                id={brand?.id}
                                checked={selectedBrands.includes(brand?.brand)} // Check if selected
                                onChange={() => handleBrandChange(brand?.brand)} // Handle change
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </TabPane>
            <TabPane tabId={12}>
              <div className="col-12 p-0">
                <form action="">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="d-flex align-items-center passfield-wrapper mb-0">
                        <div className="icon">{SvgIcons?.searchBarIcon}</div>
                        <input
                          type="text"
                          className="form-control"
                          id="searchbar"
                          placeholder="What are you looking for?"
                          // value={brandSearch}
                          // onChange={(e) => setBrandSearch(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-12 p-0">
                      <div className="opt-wrapper">
                        {instrument_condition?.map((condition) => (
                          <div
                            className="form-check gap-3 d-flex align-items-center justify-content-between"
                            key={condition?.id}
                          >
                            <label
                              className="form-check-label"
                              htmlFor={condition?.id}
                            >
                              {condition?.label}
                            </label>
                            <input
                              className="form-check-input type-1"
                              type="checkbox" // Change to checkbox for multiple selections
                              name="condition"
                              value={condition?.value}
                              id={condition?.id}
                              checked={selectedConditions.includes(
                                condition?.value
                              )} // Check if selected
                              onChange={() =>
                                handleConditionChange(condition?.value)
                              } // Handle change
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </TabPane>
            <TabPane tabId={15}>
              <div className="col-12 p-0">
                <form action="">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="opt-wrapper">
                        <div className="form-check gap-3 d-flex align-items-center justify-content-between">
                          <label className="form-check-label" htmlFor="free">
                            Free
                          </label>
                          <input
                            className="form-check-input type-1"
                            type="radio" // Change to checkbox for multiple selections
                            name="eventPricing"
                            value="free"
                            id="free"
                            // checked={eventPricing == "free"} // Check if selected
                            onChange={() => {
                              filterChangeHandler("free", "eventPricing");
                              setEventPricing("free");
                            }} // Handle change
                          />
                        </div>
                        <div className="form-check gap-3 d-flex align-items-center justify-content-between">
                          <label className="form-check-label" htmlFor="paid">
                            Paid
                          </label>
                          <input
                            className="form-check-input type-1"
                            type="radio" // Change to checkbox for multiple selections
                            name="eventPricing"
                            value="paid"
                            id="paid"
                            // checked={eventPricing == "paid"} // Check if selected
                            onChange={() => {
                              filterChangeHandler("paid", "eventPricing");
                              setEventPricing("paid");
                            }} // Handle change
                          />
                        </div>
                        {eventPricing === "paid" && (
                          <div className="form-check gap-3 d-flex align-items-center justify-content-between">
                            <span>1</span>
                            <input
                              type="range"
                              className="form-range"
                              min="1"
                              max="500"
                              step="1"
                              onClick={(e) => filterChangeHandler(e.target.value , "TicketMaxPrice" )}
                              onMouseMove={handleMouseMove}
                              onMouseOut={handleMouseOut}
                            />
                            <span>
                              {tooltipVisible ? rangeValue : maximumTicketPrice}
                            </span>
                            {/* )} */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </TabPane>

            <TabPane tabId={16}>
              <div className="col-12 p-0">
                <form action="">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="d-flex align-items-center passfield-wrapper mb-0">
                        {/* <div className="icon">{SvgIcons?.searchBarIcon}</div> */}
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) =>
                            filterChangeHandler(e.target.value, "eventDate")
                          }

                          // id="searchbar"
                          // placeholder="What are you looking for?"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </TabPane>
          </TabContent>
          <div className="col-12 col-md-4">
            <button
              type="button"
              className="btn btn-light w-100"
              onClick={cancelFilter}
            >
              Cancel
            </button>
          </div>
          <div className="col-12 col-md-4">
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={searchFilter}
              disabled={activeTabs?.length < 1}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
