import { Link } from "react-router-dom";
import { RoutesLink } from "../utility/RoutesLink";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="max-width-container">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between">
            <div className="coprightsec  order-2 order-sm-1 d-flex align-items-center justify-content-center">
              <p className="text-jt-dark">© 2024 Jubaltalents.com</p>
            </div>
            <nav className="navbar order-1 order-sm-2">
              <div className="container-fluid justify-content-center">
                <ul className="navbar-nav flex-row flex-wrap justify-content-center">
                  <li className="nav-item">
                    <Link
                      className="nav-link text-jt-dark"
                      to={RoutesLink?.about_us}
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link text-jt-dark"
                      to={RoutesLink?.privacy_policy}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link text-jt-dark"
                      to={RoutesLink?.terms_conditions}
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link text-jt-dark"
                      to={RoutesLink?.contact}
                    >
                      Help & Support
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={RoutesLink.faq} className="nav-link text-jt-dark">
                      FAQ
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
