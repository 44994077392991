import { motion } from "framer-motion";
const AppLink = () => {
  return (
    <>
      <section className="app-link py-0">
        <div className="max-width-container parallax-bg">
          <div className="container h-100 d-flex justify-content-center align-items-end">
            <motion.div
              className="app-link-card"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "circOut" }}
              viewport={{ once: true }}
              // Add stagger if you have multiple items inside app-link-card
              // variants={{
              //   hidden: { opacity: 0, y: 50 },
              //   visible: { opacity: 1, y: 0 }
              // }}
              // initial="hidden"
              // animate="visible"
            >
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  <h5 className="h3">
                    Build up the music for a positive and uplifting conclusion
                  </h5>
                  <p className="para">Download the Android and IOS</p>
                </div>
                <div className="col-12">
                  <div className="row justify-content-center">
                    <div className="col-3">
                      <a href="#">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/logos/play_store.png`}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="col-3">
                      <a href="#">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/logos/app_store.png`}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AppLink;
