import React, { useEffect, useState } from "react";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import moment from "moment";
import { SvgIcons } from "../../Components/Svg/SvgIcons";
import { Link } from "react-router-dom";

function CommentActivity() {
  const [AllCommentsPost, setAllCommentsPost] = useState([]);
  const [filterOption, setFilterOption] = useState("Newest");

  useEffect(() => {
    handleFetchRequest(ApiUrl.my_comments).then((response) => {
      if (response.status === true) {
        setAllCommentsPost(response?.data?.allComment);
      }
    });
  }, []);

  const handleFilterChange = (option) => {
    setFilterOption(option);
  };

  const getSortedComments = () => {
    return [...AllCommentsPost].sort((a, b) => {
      if (filterOption === "Newest") {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else if (filterOption === "Oldest") {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
      return 0;
    });
  };

  return (
    <div
      className="tab-pane fade"
      id="comment-tab-pane"
      role="tabpanel"
      aria-labelledby="comment-tab"
      tabIndex="0"
    >
      <div className="filter-row">
        <div className="d-flex align-items-center justify-content-between">
          <p>Post Comments</p>
          <div className="inner-filter-row">
            <div>
              <div className="dropdown dropdown-menu-end">
                <span className="selected"></span>
                <button
                  className="btn btn-light dropdown-toggle d-flex align-items-center gap-3"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="12"
                      viewBox="0 0 21 12"
                      fill="none"
                    >
                      <path
                        d="M8.07872 11.8284H12.6918V9.86084H8.07872V11.8284ZM0.00585938 0.0231934V1.99072H20.7646V0.0231934H0.00585938ZM3.46566 6.90955H17.3048V4.94202H3.46566V6.90955Z"
                        fill="#818898"
                      ></path>
                    </svg>
                  </span>
                  Filter by
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className={
                        filterOption === "Newest"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      to="#"
                      onClick={() => handleFilterChange("Newest")}
                    >
                      Newest
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        filterOption === "Oldest"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      to="#"
                      onClick={() => handleFilterChange("Oldest")}
                    >
                      Oldest
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row shared-row">
        {getSortedComments().map((data, index) => {
          return (
            <div className="col-12 p-0" key={index}>
              <div className="col-12 p-0">
                <div className="comment-area">
                  <div className="user-det d-flex gap-3">
                    <div className="user-dp">
                      <img src={data?.User?.profilePhoto} alt="" />
                    </div>
                    <div className="d-flex flex-column w-100 gap-2 gap-md-3">
                      <div className="d-flex justify-content-between w-100">
                        <div className="user-ac">
                          <div className="d-flex gap-1 gap-md-2 align-items-baseline flex-wrap">
                            <span className="username">
                              <a to="#">
                                {data?.User?.firstName} {data?.User?.lastName}
                              </Link>
                            </span>
                            <span className="post-time">
                              {moment(data?.createdAt).format("LLLL")}
                            </span>
                            <span className="usertype mx-1">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={11}
                                  height={11}
                                  viewBox="0 0 11 11"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M10.3071 5.17529L9.62945 4.33449C9.52278 4.19645 9.45376 4.03331 9.43493 3.85135L9.31572 2.78466C9.29153 2.57167 9.19584 2.37317 9.04426 2.22159C8.89268 2.07002 8.69419 1.97432 8.48119 1.95014L7.41451 1.83092C7.22627 1.8121 7.06313 1.73053 6.92509 1.62386L6.08429 0.946229C5.91967 0.815097 5.7012 0.742188 5.47516 0.742188C5.24911 0.742188 5.03065 0.815097 4.86602 0.946229L4.02523 1.62386C3.88718 1.73053 3.72404 1.8121 3.5358 1.83092L2.46912 1.95014C2.25612 1.97432 2.05762 2.07002 1.90604 2.22159C1.75447 2.37317 1.65877 2.57167 1.63459 2.78466L1.51537 3.85135C1.49654 4.03331 1.42752 4.19645 1.32085 4.33449L0.643219 5.17529C0.512087 5.33991 0.439178 5.55838 0.439178 5.78443C0.439178 6.01047 0.512087 6.22894 0.643219 6.39357L1.32085 7.23436C1.42752 7.3724 1.49654 7.53554 1.51537 7.7175L1.63459 8.78419C1.65877 8.99718 1.75447 9.19568 1.90604 9.34726C2.05762 9.49883 2.25612 9.59453 2.46912 9.61871L3.5358 9.73792C3.72404 9.75675 3.88718 9.83832 4.02523 9.94499L4.86602 10.6226C5.03065 10.7538 5.24911 10.8267 5.47516 10.8267C5.7012 10.8267 5.91967 10.7538 6.08429 10.6226L6.92509 9.94499C7.06313 9.83832 7.22627 9.75675 7.41451 9.73792L8.48119 9.61871C8.69419 9.59453 8.89268 9.49883 9.04426 9.34726C9.19584 9.19568 9.29153 8.99718 9.31572 8.78419L9.43493 7.7175C9.45376 7.53554 9.52278 7.3724 9.62945 7.23436L10.3071 6.39357C10.4382 6.22894 10.5111 6.01047 10.5111 5.78443C10.5111 5.55838 10.4382 5.33991 10.3071 5.17529Z"
                                    fill="#FFA100"
                                  />
                                </svg>
                              </span>
                              {data?.User?.userType}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="user-comment">
                        <p>{data?.commentText}</p>
                      </div>
                      <div className="thumbnail-view">
                        <img src={data?.postThumbnail} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div> */}

      <div className="row shared-row">
        {getSortedComments()?.map((comment) => (
          <div key={comment.id} className="col-12 p-0">
            <div className="col-12 p-0">
              <div className="comment-area">
                <div className="user-det d-flex gap-3">
                  <div className="user-dp">
                    <img src={comment.User.profilePhoto} alt="" />
                  </div>
                  <div className="d-flex flex-column flex-grow-1 gap-2 gap-md-3">
                    <div className="d-flex justify-content-between w-100">
                      <div className="user-ac">
                        <div className="d-flex gap-1 gap-md-2 align-items-baseline flex-wrap">
                          <span className="username">
                            <Link to="#">
                              {comment.User.firstName} {comment.User.lastName}
                            </Link>
                          </span>
                          <span className="post-time">
                            {moment(comment?.createdAt).format("LLLL")}
                          </span>
                          <span
                            className={
                              comment.User.type === 3
                                ? "usertype mx-1"
                                : "usertype vendor mx-1"
                            }
                          >
                            <span>{SvgIcons?.talentIcon}</span>
                            {comment.User.type === 3 ? "Talent" : "Vendor"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <p>{comment.comment}</p>
                  </div>
                  <div className="post-tumbnail">
                    {/* <img src={comment.post.postImageUrl} alt="" /> */}

                    {comment.post?.mediaType === "video" ? (
                      <video
                        src={comment.post?.postImageUrl}
                        autoPlay
                        muted
                        className="w-100"
                      />
                    ) : (
                      <img
                        src={comment.post?.postImageUrl}
                        alt={comment.post?.title}
                        className="w-100"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {comment.reply.map((reply) => (
              <div key={reply.id} className="replyed ps-3 ps-lg-5">
                <div className="comment-area">
                  <div className="user-det d-flex gap-3">
                    <div className="user-dp">
                      <img src={reply.User.profilePhoto} alt="" />
                    </div>
                    <div className="d-flex flex-column flex-grow-1 gap-3">
                      <div className="d-flex justify-content-between w-100">
                        <div className="user-ac">
                          <div className="d-flex gap-2 align-items-end">
                            <span className="username">
                              <Link to="#">
                                {reply.User.firstName} {reply.User.lastName}
                              </Link>
                            </span>
                            <span className="post-time">
                              {" "}
                              {moment(reply?.createdAt).format("LLLL")}
                            </span>
                            <span
                              className={
                                comment.User.type === 3
                                  ? "usertype mx-1"
                                  : "usertype vendor mx-1"
                              }
                            >
                              <span>{SvgIcons?.talentIcon}</span>
                              {reply.User.type === 3 ? "Talent" : "Vendor"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p>{reply.comment}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CommentActivity;
