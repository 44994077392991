import React from "react"; // Importing React
import PropTypes from "prop-types"; // Importing PropTypes for prop type validation
import { Modal, ModalBody, ModalFooter } from "reactstrap"; // Importing modal components from reactstrap
import { confirmable, createConfirmation } from "react-confirm"; // Importing confirmation utilities

// ConfirmDataCheck component definition
const ConfirmDataCheck = (props) => {
  const {
    proceedLabel, // Label for the proceed button
    cancelLabel, // Label for the cancel button
    title, // Title of the modal (currently unused)
    confirmation, // Confirmation message to be displayed
    show, // Boolean indicating if the modal should be shown
    proceed, // Function to call when a button is clicked
    enableEscape = true, // Boolean to enable escape key to close modal
  } = props;

  return (
    <div className="static-modal">
      <Modal
        isOpen={show} // Controls the visibility of the modal
        centered // Centers the modal on the screen
        hidden={() => proceed(false)} // Callback to execute when the modal is hidden
        backdrop={enableEscape ? true : "static"} // Set backdrop behavior based on enableEscape
        keyboard={enableEscape} // Enables keyboard close based on enableEscape
        className="modal-confirm modal-dialog-end m-0 mx-auto deletemodal" // Custom classes for styling
      >
        {/* <ModalHeader>{title}</ModalHeader> */}{" "}
        {/* Commented out title header */}
        <ModalBody className="text-center">
          {" "}
          {/* Modal body with centered text */}
          <h4>Are you sure?</h4> {/* Header for the confirmation question */}
          <p>{confirmation}</p>{" "}
          {/* Confirmation message displayed in the modal */}
        </ModalBody>
        <ModalFooter className="d-flex align-items-center mx-auto justify-content-between border-0">
          {" "}
          {/* Modal footer with button layout */}
          <div className="d-flex align-items-center gap-3 justify-content-center">
            {" "}
            {/* Flex container for buttons */}
            <button
              type="button"
              className="btn btn-light" // Button for canceling the action
              onClick={() => proceed(false)} // Calls proceed with false to indicate cancellation
            >
              {cancelLabel} {/* Cancel button label */}
            </button>
            <button
              onClick={() => proceed(true)} // Calls proceed with true to indicate confirmation
              type="button"
              className="btn btn-primary" // Button for confirming the action
            >
              {proceedLabel} {/* Proceed button label */}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmDataCheck; // Exporting the ConfirmDataCheck component

// PropTypes validation for the ConfirmDataCheck component
ConfirmDataCheck.propTypes = {
  okLabbel: PropTypes.string, // Prop for the label of the confirm button (note: typo in prop name)
  cancelLabel: PropTypes.string, // Prop for the label of the cancel button
  title: PropTypes.string, // Prop for the modal title
  confirmation: PropTypes.string, // Prop for the confirmation message
  show: PropTypes.bool, // Prop indicating if the modal should be shown
  proceed: PropTypes.func, // Prop for the function to call when proceed button is clicked
  enableEscape: PropTypes.bool, // Prop for enabling escape key to close modal
};

// Function to create a confirmation dialog
export const confirm = (
  confirmation, // Confirmation message
  proceedLabel = "Yes", // Default label for the proceed button
  cancelLabel = "No", // Default label for the cancel button
  options = {} // Additional options for customization
) => {
  // Create a confirmation dialog using ConfirmDataCheck component
  return createConfirmation(confirmable(ConfirmDataCheck))({
    confirmation, // Pass the confirmation message
    proceedLabel, // Pass the proceed button label
    cancelLabel, // Pass the cancel button label
    ...options, // Spread any additional options
  });
};
