export const ApiUrl = {
  sign_up: "J0001/api/v1/registration",
  login: "J0001/api/v1/login",
  forgot_password: "J0001/api/v1/forget-reset",
  get_user_check: "J0001/api/v1/get-user",
  get_services: "J0001/api/v1/admin/get-service",
  get_categories: "J0001/api/v1/admin/get-catagory",
  get_instruments: "J0003/api/v1/instruments/get",
  get_music_type: "J0005/api/v1/music-types",
  create_type: "J0001/api/v1/create-type/",
  create_post: "J0002/api/v1/post/create",
  edit_post: "J0002/api/v1/post/edit/",
  get_post: "J0002/api/v1/post/my-post",
  get_event: "J0005/api/v1/event/list",
  uplode_post_image: "J0002/api/v1/post/upload/",
  get_posts_comment: "J0002/api/v1/post/detail/",
  get_posts_likes: "J0002/api/v1/post/likes/",
  get_user_detials: "J0001/api/v1/users/get/",
  get_user_posts: "J0002/api/v1/my-posts?userId=",
  user_post: "J0002/api/v1/user-posts?userId=",
  get_my_saved_post: "J0002/api/v1/post/saved-post",
  get_address: "J0001/api/v1/get-address",
  get_delete_Address: "J0001/api/v1/delete-address/",
  post_like_comment: "J0002/api/v1/post/like",
  post_save: "J0002/api/v1/post/save/",
  post_remove_save: "J0002/api/v1/post/remove-saved/",
  remove_tag: "J0002/api/v1/post/tag-remove/",
  auth_check: "J0001/api/v1/auth-check",
  my_likes: "J0002/api/v1/post/my-liked",
  my_comments: "J0002/api/v1/my-comments",
  taged_posts: "J0002/api/v1/post/taged-post",
  myFollowing: "J0001/api/v1/following/",
  hire_telent: "J0005/api/v1/booking/create",
  backline_service: "J0005/api/v1/backline/get-list",
  get_hire_booking_detials: "J0005/api/v1/booking/get/",
  get_usre_list: "J0001/api/v1/follower/",
  Follow_user: "J0001/api/v1/follow/",
  unfollow_user: "J0001/api/v1/unfollow/",
  post_comment: "J0002/api/v1/post/comment",
  comment_detials: "J0002/api/v1/comment-detail/",
  create_address: "J0001/api/v1/create-address",
  edit_address: "J0001/api/v1/edit-address/",
  set_profile: "J0001/api/v1/set-profile",
  get_all_bookings: "J0005/api/v1/booking/get-list",
  change_password: "J0001/api/v1/change-password",
  add_Signature: "J0005/api/v1/booking/upload-signature/",
  add_review: "J0005/api/v1/review/add",
  get_review: "J0005/api/v1/review/get",
  get_chat_status: "J0006/api/v1/chat",
  search_User: "J0001/api/v1/filter-search?search=",
  event_search: "J0001/api/v1/filter-search?",
  search_add: "J0001/api/v1/add-search",
  get_previous_search: "J0001/api/v1/get-search",
  get_instruments_like: "J0003/api/v1/instrument/like/",
  get_instruments_saved: "J0003/api/v1/save/instrument/",
  event_like: "J0005/api/v1/event/like/",
  event_save: "J0005/api/v1/save-event/",
  get_single_event_detials: "J0005/api/v1/event/",
  my_events: "J0005/api/v1/my-event",
  get_user: "J0001/api/v1/get-user",
  notification: "J0006/api/v1/get-notification",
  shared_posts: "J0002/api/v1/post/sharedMe",
  saved_instreuments: "J0003/api/v1/save/instruments",
  get_cart_data: "J0004/api/v1/cart/get",
  remove_from_cart: "J0004/api/v1/cart/remove/",
  add_cart_data: "J0004/api/v1/cart/add",
  get_music: "J0003/api/v1/music/get?userId=",
  instruement_categories: "J0003/api/v1/categories?",
  instrument_name: "J0003/api/v1/instrument-name/get",
  get_brands: "J0003/api/v1/brands",
  get_features: "J0003/api/v1/features",
  get_specifications: "J0003/api/v1/specifications",
  add_instrument_image: "J0003/api/v1/instrument-image/",
  add_instrument: "J0003/api/v1/instruments",
  edit_instrument: "J0003/api/v1/instruments/",
  add_banner_image: "J0001/api/v1/upload-image",
  get_all_talents: "J0001/api/v1/users/list?type=3",
  get_user_reports: "J0001/api/v1/user-chart?",
  upload_music: "J0003/api/v1/music/add",
  fillter_user: "J0001/api/v1/filter-search",
  switch_user: "J0001/api/v1/switch-user/",
  delete_music: "J0003/api/v1/music/delete/",
  get_purchased_instruments: "J0003/api/v1/get-purchased-instrument?userId=",
  get_instruments_by_id: "J0003/api/v1/instruments/",
  share_a_post: "J0002/api/v1/post/share/",
  checkFollowing: "J0001/api/v1/check-follower?",
  delete_instruement: "J0003/api/v1/instruments/",
  delete_event: "J0005/api/v1/event/delete/",
  globle_search: "J0001/api/v1/globalSearch?",
  event_type: "J0005/api/v1/event-types",
  entertainment_types: "J0005/api/v1/entertainment-types",
  music_type: "J0005/api/v1/music-types",
  create_event: "J0005/api/v1/event/create",
  delete_post: "J0002/api/v1/post/delete/",
  my_shared_instruments: "J0003/api/v1/my-shared-instrument",
  my_liked_event: "J0005/api/v1/liked-event",
  my_saved_event: "J0005/api/v1/saved-event",
  my_shared_event: "J0005/api/v1/my-shared-event",
  my_liked_instrument: "J0003/api/v1/instrument/get-likes?userId=",
  my_saved_instreuments: "/J0003/api/v1/save/instruments",
  archived_posts: "J0002/api/v1/my-posts?isDeleted=true",
  arcive_a_post: "J0002/api/v1/post/archive/",
  check_calender: "J0001/api/v1/get-calender",
  performance_types: "J0005/api/v1/performance-types",
  edit_booking: "J0005/api/v1/booking/edit/",
  create_calender: "/J0001/api/v1/create-calender",
  create_chat: "J0006/api/v1/chat/create",
  markAsRead: "J0006/api/v1/seen-notification",
  deleteNotification: "J0006/api/v1/delete-notification",
  get_order: "J0004/api/v1/order/get-all",
  order_detials: "/J0004/api/v1/order/",
  get_zipcode: "J0003/api/v1/get-zipcode",
  share_instrument: "J0003/api/v1/share/instrument/",
  report: "J0001/api/v1/report/",
  share_event: "J0005/api/v1/share/event/",
  report_post: "J0002/api/v1/report/post/",
  create_checkout: "J0004/api/v1/create-checkout",
  post_detail: "J0002/api/v1/post/get-detail/",
  get_nearby: "J0001/api/v1/nearby?",
  edit_event: "J0005/api/v1/event/update/",
  create_talent_event: "J0005/api/v1/talent/event/create",
  delete_talent_event: "J0005/api/v1/talent/event/delete/",
  edit_talent_event: "J0005/api/v1/talent/event/update/",
  filter_search: "J0001/api/v1/filterSearch",
  delete_calendar: "J0001/api/v1/delete-calender/",
  report_user: "J0001/api/v1/report/",
  orderItemList: "J0004/api/v1/order-item/get-all",
  notifyArtist: "J0006/api/v1/send-notification",
  getInstrumentName: "J0003/api/v1/instrument-name/get",
  cancel_order: "J0004/api/v1/order/refund",
  update_order_status: "J0004/api/v1/update-status/",
  create_card: "J0001/api/v1/create-card",
  get_card: "J0001/api/v1/get-card",
  item_detials: "J0004/api/v1/order/",
  order_review: "/J0004/api/v1/order/review/",
  get_plan: "J0001/api/v1/get/plan",
  create_plan: "J0001/api/v1/subscribe/plan",
  my_plans: "J0001/api/v1/myPlans",
  remove_instrument_image: "J0003/api/v1/remove-image/",
  social_google_login: "J0001/api/v1/social-login",
  download_sheet: "J0004/api/v1/order-report",
  logout: "J0001/api/v1/log-out?deviceId=",
  favourite_artist: "J0001/api/v1/favourite/",
  save_a_artist: "J0001/api/v1/save/",
  get_favourite_artist: "J0001/api/v1/my-favourite",
  create_payment: "J0005/api/v1/booking/create-payment",
  setting: "J0003/api/v1/get-setting",
  create_payment_intent: "J0004/api/v1/create-payment-intent",
  buy_free_event: "J0004/api/v1/create-free-checkout",
  //fedex api
  get_rate: "J0004/api/v1/get-quote",
  resend_otp: "J0001/api/v1/resend",
  sharemodalFollowing: "J0001/api/v1/following/",
  my_comment_on_post: "J0002/api/v1/user/comments",
  return_reasons: "J0004/api/v1/reason/get?type=",
  single_post_detials: "J0002/api/v1/post/get-detail/",
};
