import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { getLoginUserCaption, setIsLocationDetails } from "../../Helper/Helper";
import { toast } from "material-react-toastify";
import GooglePlaceAutocomplete from "../../Components/GooglePlaceAutocomplete";
import { Spinner } from "reactstrap";
import Select from "react-select";
import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardLayout from "../../Components/Layout/DashboardLayout";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import { RoutesLink } from "../../utility/RoutesLink";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PreventEnterKey from "../../Helper/PreventEnterKey";
import { SvgIcons } from "../../Components/Svg/SvgIcons";
import { object } from "yup";

function EditPost() {
  const [imageUrl, setImageUrl] = useState({
    image_url: "",
    image_view: "",
  });
  const [Place, setPlace] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedSpecificFriends, setselectedSpecificFriends] = useState([]);
  //setSelectedTagsName is data that we are getting form api
  const [selectedTagsName, setSelectedTagsName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDraft, setIsLoadingDraft] = useState(false);
  const [isOpenFriendModal, setisOpenFriendModal] = useState(false);
  const [Address, setAddress] = useState("");
  const [PreviewToggle, setPreviewToggle] = useState(false);
  const [selectedgenre, setSelectedgenre] = useState(null);
  //setAllGenre is the key from where we are getting genra data
  const [AllGenre, setAllGenre] = useState([]);
  const [selectedOption, setSelectedOption] = useState(3);
  const [PostDetials, setPostDetials] = useState({});
  const [isOpenPreviewModal, setisOpenPreviewModal] = useState({
    isOpen: false,
    previewState: false,
  });
  const [mode, setMode] = useState("");
  const userDetails = useSelector((store) => store?.user?.userDetails);
  const navigate = useNavigate();
  // console.log("LocationData......", JSON.parse(selectedPlace));
  const location = useLocation();
  const [sendSelectedData, setSendSelectedData] = useState(null);
  const [sendSpecificFriendsdata, setSendSpecificFriendsdata] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm({});

  useEffect(() => {
    // console.log("location?.state?.data?.id", location?.state?.data);
    handleFetchRequest(ApiUrl.post_detail + location?.state?.data?.id).then(
      (res) => {
        if (res.status === true) {
          setPostDetials(res?.data);
          console.log("post detials", res?.data);
          setImageUrl({
            image_url: "",
            image_view: res?.data?.postImageUrl,
          });
        }
      }
    );
  }, [""]);

  const handleOptionChange = (event) => {
    setSelectedOption(event?.target?.value);
  };

  useEffect(() => {
    handleFetchRequest(ApiUrl?.get_music_type).then((response) => {
      if (response.status === true) {
        // console.log("first", response.data);
        let getOnlycategories = response?.data?.map((res) => {
          return {
            value: res.type,
            id: res.id,
            label: res.type,
          };
        });
        // setPrimaryCategorie(getOnlycategories);
        setAllGenre(getOnlycategories);
      }
    });
  }, []);

  // useEffect(() => {
  //   handleFetchRequest(ApiUrl?.get_fo)
  // })

  const handlelocation = (e) => {
    setPlace(e);
  };

  useEffect(() => {
    if (PostDetials?.genre) {
      let genreData = JSON.parse(PostDetials?.genre);
      setValue(
        "genra",
        genreData?.map((spec) => ({
          value: spec.name,
          label: spec.name,
          id: spec.id,
        }))
      );
    }
    if (PostDetials?.whoCanView) {
      let SelectedFriendData = PostDetials?.whoCanView;
      let Tags = PostDetials?.taggedUser;
      setValue(
        "selectedFriend",
        SelectedFriendData?.map((item, index) => ({
          label: (
            <div
              className="user-det d-flex align-items-center gap-3"
              key={index}
            >
              {item?.profilePhoto ? (
                <div className="user-dp">
                  <img src={item?.profilePhoto} alt="Profile" />
                </div>
              ) : (
                <div className="user-dp no-img">{item?.firstName?.[0]}</div>
              )}

              <div className="user-ac">
                <div className="d-flex gap-2 align-items-center">
                  <span className="username">
                    <Link to="#">
                      {item?.firstName} {item?.lastName}
                    </Link>
                  </span>
                  {(item?.type == 3 || item?.type == 2) && (
                    <span
                      className={
                        item?.type == 2
                          ? "usertype vendor mx-1"
                          : "usertype mx-1"
                      }
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                        >
                          <circle
                            cx="5.74979"
                            cy="6.72714"
                            r="3.75247"
                            fill="white"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                            fill="#F4336D"
                          />
                        </svg>
                      </span>
                      {item?.type === 3 ? "Talent" : "Vendor"}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ),
          value: item?.id,
        }))
      );
      setValue(
        "Tags",
        Tags?.map((item, index) => ({
          label: (
            <div
              className="user-det d-flex align-items-center gap-3"
              key={index}
            >
              {item?.profilePhoto ? (
                <div className="user-dp">
                  <img src={item?.profilePhoto} alt="Profile" />
                </div>
              ) : (
                <div className="user-dp no-img">{item?.firstName?.[0]}</div>
              )}
              <div className="user-ac">
                <div className="d-flex gap-2 align-items-center">
                  <span className="username">
                    <Link to="#">
                      {item?.firstName} {item?.lastName}
                    </Link>
                  </span>
                  {(item?.type === 3 || item?.type === 2) && (
                    <span
                      className={
                        item?.type === 2
                          ? "usertype vendor mx-1"
                          : "usertype mx-1"
                      }
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                        >
                          <circle
                            cx="5.74979"
                            cy="6.72714"
                            r="3.75247"
                            fill="white"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                            fill="#F4336D"
                          />
                        </svg>
                      </span>
                      {item?.type === 3 ? "Talent" : "Vendor"}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ),
          value: item?.userId,
        }))
      );
    }
  }, [mode, selectedgenre, setValue, PostDetials]);

  // const toggle = () => setCreatePostModal((pre) => !pre);

  const allFields = watch();
  const previewtoggle = () => {
    if (imageUrl?.image_view) {
      setPreviewToggle(true);
    }
  };

  const isOpenFriendModaltoggle = (e) => {
    if (e === "4") {
      setisOpenFriendModal(!isOpenFriendModal);
    }
  };

  const handleSelectTags = (options) => {
    setSelectedTags(options);
  };

  // console.log("selectTage...",selectedTags);

  const handleSelectSpecificfriends = (options) => {
    let data = options?.map((val) => {
      return val?.value?.id;
    });
    setSendSpecificFriendsdata(data);
    setselectedSpecificFriends(options);
  };

  useEffect(() => {
    handleFetchRequest(`${ApiUrl?.myFollowing}${userDetails?.id}`, "GET").then(
      (response) => {
        if (response?.status === true) {
          // setSelectedTagsName(response?.data?.user);
          let getOnlyspecification = response?.data?.user
            ?.filter(
              (res) => res?.following?.type == 2 || res?.following?.type == 3
            )
            ?.map((item, index) => {
              return {
                label: (
                  <div
                    className="user-det d-flex align-items-center gap-3"
                    key={index}
                  >
                    {item?.following?.Profile?.profilePhoto ? (
                      <div className="user-dp">
                        <img
                          src={item?.following?.Profile?.profilePhoto}
                          alt="Profile"
                        />
                      </div>
                    ) : (
                      <div className="user-dp no-img">
                        {item?.following?.Profile?.firstName?.[0]}
                      </div>
                    )}

                    <div className="user-ac">
                      <div className="d-flex gap-2 align-items-center">
                        <span className="username">
                          <Link to="#">
                            {item?.following?.Profile?.firstName}{" "}
                            {item?.following?.Profile?.lastName}
                          </Link>
                        </span>
                        {(item?.following?.type == 3 ||
                          item?.following?.type == 2) && (
                          <span
                            className={
                              item?.following?.type == 2
                                ? "usertype vendor mx-1"
                                : "usertype mx-1"
                            }
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                              >
                                <circle
                                  cx="5.74979"
                                  cy="6.72714"
                                  r="3.75247"
                                  fill="white"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                  fill="#F4336D"
                                />
                              </svg>
                            </span>
                            {item?.following?.type === 3 ? "Talent" : "Vendor"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ),
                value: item?.following?.Profile?.userId,
                //   }))}
              };
            });
          setSelectedTagsName(getOnlyspecification);
          // console.log("tageData.....", response?.data?.user);
        } else {
          alert("Data is Not Found!");
        }
      }
    );
  }, [userDetails]);

  console.log("dddd", JSON.parse(location?.state?.data?.location));
  useEffect(() => {
    // const data = JSON.parse(location?.state?.data?.location);
    // if (data && data.address) {
    //   setAddress(data.address);
    //   console.log("dataaaaa", data?.address);
    // }
    // setAddress(JSON?.parse(location?.state?.data?.location)?.address);
    setImageUrl({
      image_view: location.state?.data?.postImageUrl,
    });
    setSelectedOption(location?.state?.data?.audienceVisibility);
    // const loc = JSON.parse(location?.state?.data?.location);
    // setAddress(loc?.address);
    setValue("post_type", location?.state?.data?.audienceVisibility);
    // setPlace(location?.state?.data?.location);
    setPlace(JSON.parse(location?.state?.data?.location));
    // setSelectedTagsName()
  }, [setValue]);

  const getgenradatamodified = (data) => {
    let array = data?.map((res) => {
      return {
        id: res?.id,
        name: res?.label,
      };
    });
    return JSON.stringify(array); // return the modified array
  };
  const getfrinedsDataModified = (data) => {
    // console.log("getfrinedsDataModified", data);
    // return;
    let array = data?.map((res) => {
      return {
        id: res?.value,
        // name: res?.label,
      };
    });
    return JSON.stringify(array); // return the modified array
  };

  const onSaveDraft = () => {
    const editData = {
      caption: allFields?.caption,
      audienceVisibility: allFields?.post_type,
      location: JSON.stringify(Place),
      genre: getgenradatamodified(allFields?.genra),
      postStatus: "draft",
      // whoCanView: getfrinedsDataModified(data?.selectedFriend),
      // tags: data?.post_type !== 4 ? getfrinedsDataModified(data?.Tags) : [],
      title:
        allFields?.post_type == 1
          ? "Friends"
          : allFields?.post_type == 3
          ? "Public"
          : allFields?.post_type == 4
          ? "Specific friends"
          : "",
      description:
        allFields?.post_type == 1
          ? "Your friends on jubal"
          : allFields?.post_type == 3
          ? "Anyone on or off jubal"
          : allFields?.post_type == 4
          ? "Only show to some friends"
          : "",
    };
    if (
      allFields?.Tags?.length > 0 &&
      (allFields?.post_type == 3 || allFields?.post_type == 1)
    ) {
      editData.tags = getfrinedsDataModified(allFields?.Tags);
    } else if (
      allFields?.post_type == 4 &&
      allFields?.selectedFriend.length < 1
    ) {
      toast.error("Please Select Specific Friends!");
      return;
    } else if (allFields?.post_type == 4) {
      editData.whoCanView = getfrinedsDataModified(allFields?.selectedFriend);
    }
    setIsLoadingDraft(true);
    let formData = new FormData();

    handleFetchRequest(
      ApiUrl?.edit_post + location.state?.data?.id,
      "PUT",
      editData
    ).then((response) => {
      if (response?.status === true) {
        if (typeof imageUrl?.image_url === "object") {
          if (imageUrl?.image_url?.type?.startsWith("video/")) {
            formData.append("postImageUrl", imageUrl?.image_url);
            formData.append("mediaType", "video");
          }
          if (imageUrl?.image_url?.type?.startsWith("image/")) {
            formData.append("postImageUrl", imageUrl?.image_url);
            formData.append("mediaType", "photo");
          }
          handleFetchRequest(
            ApiUrl?.uplode_post_image + location.state?.data?.id,
            "POST",
            null,
            null,
            formData
          ).then((res) => {
            if (res.status == true) {
              setIsLoading(false);
              reset();
              setImageUrl({
                image_view: "",
                image_url: "",
              });
              navigate(RoutesLink?.dashboard);
              toast.success("Post saved in draft");
              setselectedSpecificFriends([]);
              setSelectedTags([]);
              if (isOpenPreviewModal.isOpen === true) {
                previewtoggle();
              }
              setIsLoadingDraft(false);
            }
          });
        } else {
          setIsLoading(false);
          reset();
          setImageUrl({
            image_view: "",
            image_url: "",
          });
          navigate(RoutesLink?.dashboard);
          toast.success("Post saved in draft");
          setselectedSpecificFriends([]);
          setSelectedTags([]);
          if (isOpenPreviewModal.isOpen === true) {
            previewtoggle();
          }
          setIsLoadingDraft(false);
        }
      } else {
        setIsLoading(false);
        toast.error(response?.message);
      }
    });
  };

  const onSubmit = (data) => {
    const editData = {
      caption: data?.caption,
      audienceVisibility: data?.post_type,
      // location: JSON.stringify(Place),
      genre: getgenradatamodified(data?.genra),
      postStatus: "publish",
      // whoCanView: getfrinedsDataModified(data?.selectedFriend),
      // tags: data?.post_type !== 4 ? getfrinedsDataModified(data?.Tags) : [],
      title:
        data?.post_type == 1
          ? "Friends"
          : data?.post_type == 3
          ? "Public"
          : data?.post_type == 4
          ? "Specific friends"
          : "",
      description:
        data?.post_type == 1
          ? "Your friends on jubal"
          : data?.post_type == 3
          ? "Anyone on or off jubal"
          : data?.post_type == 4
          ? "Only show to some friends"
          : "",
    };
    if (Place != location?.state?.data?.location) {
      editData.location = JSON.stringify(Place);
    }
    if (
      data?.Tags?.length > 0 &&
      (data?.post_type == 3 || data?.post_type == 1)
    ) {
      editData.tags = getfrinedsDataModified(data?.Tags);
      editData.whoCanView = [];
    } else if (data?.post_type == 4 && data?.selectedFriend?.length < 1) {
      toast.error("Please Select Specific Friends!");
      return;
    } else if (data?.post_type == 4) {
      editData.whoCanView = getfrinedsDataModified(data?.selectedFriend) || [];
      editData.tags = [];
    }
    console.log(editData);
    setIsLoading(true);
    handleFetchRequest(
      ApiUrl?.edit_post + location.state?.data?.id,
      "PUT",
      editData
    ).then((response) => {
      if (response?.status === true) {
        setIsLoading(false);
        reset();
        setImageUrl({
          image_view: "",
          image_url: "",
        });
        navigate(RoutesLink?.dashboard);
        toast.success("Post published");
        setselectedSpecificFriends([]);
        setSelectedTags([]);
        if (isOpenPreviewModal.isOpen === true) {
          previewtoggle();
        }
      } else {
        setIsLoading(false);
        toast.error(response?.message);
      }
    });
  };

  const handleChangegenre = (selectedGenra) => {
    setValue("genra", selectedGenra);
  };

  const handleChnageFreinds = (selectedFrined) => {
    setValue("selectedFriend", selectedFrined);
    console.log(selectedFrined);
  };

  const handleTags = (selectedTag) => {
    setValue("Tags", selectedTag);
  };
  const imageUploadHandler = (e) => {
    let file = e?.target?.files?.[0];
    let url = URL.createObjectURL(file);

    if (file) {
      setImageUrl({
        image_url: file,
        image_view: url,
      });
    }
  };
  const imageRemoveHandler = () => {
    setImageUrl({
      image_view: "",
      image_url: "",
    });
  };

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <PreventEnterKey />
      <section className="px-0 main-content ">
        <div className="max-width-container">
          <div className="main-feed-grid">
            <DashboardSidebar />
            <div className="center-content d-block create-post">
              {!PreviewToggle ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-12 col-lg-12 p-0 ps-lg-2">
                      <div className="row side-row">
                        <div className="col-12 p-0">
                          <div className="">
                            {imageUrl?.image_view && (
                              <div className="position-relative img-preview">
                                {location?.state?.data?.postStatus ==
                                  "draft" && (
                                  <button
                                    className="btn btn-link"
                                    type="button"
                                    onClick={() => imageRemoveHandler()}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="19"
                                      height="19"
                                      viewBox="0 0 19 19"
                                      fill="none"
                                    >
                                      <path
                                        d="M13.7188 5.12305L4.71875 14.123M4.71875 5.12305L13.7188 14.123"
                                        stroke="black"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                )}

                                {imageUrl?.image_view &&
                                  (location?.state?.data.mediaType ===
                                  "photo" ? (
                                    <img
                                      src={imageUrl?.image_view}
                                      key={imageUrl?.image_view}
                                      alt=""
                                    />
                                  ) : (
                                    location?.state?.data.mediaType ===
                                      "video" && (
                                      <video autoPlay controls>
                                        <source
                                          src={imageUrl?.image_view}
                                          type={"video"}
                                          key={imageUrl.image_url?.name}
                                        />
                                      </video>
                                    )
                                  ))}
                              </div>
                            )}

                            {imageUrl?.image_view ? (
                              <>
                                {/* <div className="position-relative img-preview">
                                  <button
                                    className=" btn btn-link"
                                    onClick={imageRemoveHandler}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="19"
                                      height="19"
                                      viewBox="0 0 19 19"
                                      fill="none"
                                    >
                                      <path
                                        d="M13.7188 5.12305L4.71875 14.123M4.71875 5.12305L13.7188 14.123"
                                        stroke="black"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                  {imageUrl.image_url &&
                                    (imageUrl?.image_url.type.startsWith(
                                      "image/"
                                    ) ? (
                                      <img
                                        src={imageUrl?.image_view}
                                        key={imageUrl.image_url?.name}
                                        alt=""
                                      />
                                    ) : (
                                      imageUrl?.image_url.type.startsWith(
                                        "video/"
                                      ) && (
                                        <video autoPlay controls>
                                          <source
                                            src={imageUrl?.image_view}
                                            type={imageUrl?.image_url?.type}
                                            key={imageUrl.image_url?.name}
                                          />
                                        </video>
                                      )
                                    ))}
                                </div> */}
                              </>
                            ) : (
                              <label for="file" className="form-label w-100">
                                <div className="fileUploadBox d-flex justify-content-center flex-column align-items-center">
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="41"
                                      height="41"
                                      viewBox="0 0 41 41"
                                      fill="none"
                                    >
                                      <path
                                        d="M20.6308 0.755859C9.76922 0.755859 0.944336 9.58074 0.944336 20.4423C0.944336 31.3038 9.76922 40.1287 20.6308 40.1287C31.4923 40.1287 40.3172 31.3038 40.3172 20.4423C40.3172 9.58074 31.4923 0.755859 20.6308 0.755859ZM20.6308 2.54553C30.5259 2.54553 38.5275 10.5481 38.5275 20.4423C38.5275 22.2302 38.259 23.9527 37.7723 25.5804L32.4489 20.257C31.9075 19.7157 31.1907 19.4445 30.474 19.4445C29.7572 19.4445 29.0404 19.7157 28.4991 20.257L23.7627 24.9934L15.447 16.6777C14.9056 16.1363 14.1888 15.8652 13.4721 15.8652C12.7553 15.8652 12.0385 16.1363 11.4971 16.6777L3.3076 24.8672C2.93265 23.4219 2.73996 21.9354 2.73401 20.4423C2.73401 10.5472 10.7356 2.54553 20.6308 2.54553ZM13.4721 17.6441C13.7271 17.6441 13.9821 17.7426 14.1817 17.943L22.4974 26.2587L17.3136 31.4425C17.1506 31.6113 17.0604 31.8373 17.0624 32.0719C17.0645 32.3066 17.1586 32.531 17.3245 32.6969C17.4904 32.8628 17.7148 32.9569 17.9495 32.959C18.1841 32.961 18.4101 32.8708 18.5789 32.7078L29.7644 21.5223C29.8567 21.4276 29.967 21.3523 30.0889 21.3008C30.2107 21.2494 30.3417 21.2229 30.474 21.2229C30.6062 21.2229 30.7372 21.2494 30.8591 21.3008C30.9809 21.3523 31.0913 21.4276 31.1836 21.5223L37.1074 27.4462C34.3844 33.8514 28.0373 38.339 20.6308 38.339C12.9772 38.339 6.47443 33.5427 3.91162 26.7938L12.7616 17.9439C12.962 17.7434 13.217 17.645 13.4721 17.645V17.6441Z"
                                        fill="#CAC8C8"
                                      />
                                      <path
                                        d="M20.6309 6.12524C18.1701 6.12524 16.1567 8.13863 16.1567 10.5994C16.1567 13.0602 18.1701 15.0736 20.6309 15.0736C23.0917 15.0736 25.1051 13.0602 25.1051 10.5994C25.1051 8.13863 23.0917 6.12524 20.6309 6.12524ZM20.6309 7.91492C22.1244 7.91492 23.3154 9.10595 23.3154 10.5994C23.3154 12.0929 22.1244 13.2839 20.6309 13.2839C19.1374 13.2839 17.9464 12.0929 17.9464 10.5994C17.9464 9.10595 19.1374 7.91492 20.6309 7.91492Z"
                                        fill="#CAC8C8"
                                      />
                                    </svg>
                                  </span>

                                  <p>Upload here</p>
                                </div>
                              </label>
                            )}
                            <input
                              type="file"
                              className="form-control d-none"
                              id="file"
                              onChange={(e) => imageUploadHandler(e)}
                              accept="image/*,video/*"
                            />
                          </div>
                        </div>

                        <div className="col-12 p-0">
                          <label htmlFor="title" className="form-label">
                            Select Genre
                          </label>
                          <div className="position-relative">
                            <div className="w-100">
                              <Controller
                                name="genra"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={AllGenre}
                                    onChange={handleChangegenre}
                                    className="selected-tag"
                                    classNamePrefix="react-select"
                                    isMulti
                                    placeholder="Select a Genre"
                                    closeMenuOnSelect={false}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 p-0">
                          <label for="location" className="form-label">
                            Location
                          </label>
                          <div className="position-relative">
                            <div className="d-flex align-items-center passfield-wrapper loctn">
                              <GooglePlaceAutocomplete
                                selectedPlace={Place?.address}
                                setSelectedPlace={(e) => handlelocation(e)}
                                defaultValues={Place?.address}
                              />
                              <span className="visi">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="18"
                                  viewBox="0 0 15 18"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M1.20801 7.81306C1.21954 4.41878 3.98049 1.67653 7.37477 1.68802C10.769 1.69959 13.5113 4.46055 13.4998 7.85482V7.92442C13.458 10.1308 12.226 12.1702 10.7157 13.7641C9.8519 14.661 8.8873 15.4551 7.8411 16.1305C7.56136 16.3725 7.14642 16.3725 6.86667 16.1305C5.30704 15.1154 3.93821 13.8337 2.82278 12.3442C1.82862 11.0452 1.26417 9.46869 1.20801 7.83394V7.81306Z"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M7.35267 10.2686C8.8842 10.2686 10.1257 9.02702 10.1257 7.49549C10.1257 5.96396 8.8842 4.72241 7.35267 4.72241C5.82114 4.72241 4.57959 5.96396 4.57959 7.49549C4.57959 9.02702 5.82114 10.2686 7.35267 10.2686Z"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              {/* </Autocomplete> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          <h3>Who can see your post</h3>
                        </div>
                        <div className="col-12 p-0">
                          <div className="post-opt">
                            <div className="form-check form-switch d-flex align-items-center p-0">
                              <label
                                className="form-check-label w-100"
                                for="public"
                              >
                                <div>
                                  <h4>Public</h4>
                                  <p>Anyone on or off jubal</p>
                                </div>
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                id="3"
                                value="3"
                                {...register("post_type", {
                                  required: "Please select option",
                                })}
                                // defaultChecked={
                                //   watch("post_type") == 3 ? true : false
                                // }
                                onChange={handleOptionChange}
                                checked={selectedOption == 3}
                              />
                            </div>
                          </div>
                          <div className="post-opt">
                            <div className="form-check form-switch d-flex align-items-center p-0">
                              <label
                                className="form-check-label w-100"
                                for="friends"
                              >
                                <div>
                                  <h4>Friends</h4>
                                  <p>Your friends on jubal</p>
                                </div>
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                id="1"
                                name="post_type"
                                value="1"
                                {...register("post_type", {
                                  required: "Please select option",
                                })}
                                onChange={handleOptionChange}
                                checked={selectedOption == 1}
                                // defaultChecked={
                                //   watch("post_type") == 1 ? true : false
                                // }
                              />
                            </div>
                          </div>
                          <div className="post-opt">
                            <div className="form-check form-switch d-flex align-items-center p-0">
                              <label
                                className="form-check-label w-100"
                                for="spec_friend"
                              >
                                <div>
                                  <h4>Specific friends</h4>
                                  <p>Only show to some friends</p>
                                </div>
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                id="4"
                                name="post_type"
                                value="4"
                                {...register("post_type", {
                                  required: "Please select option",
                                })}
                                onChange={handleOptionChange}
                                checked={selectedOption == 4}
                                // defaultChecked={
                                //   watch("post_type") == 4 ? true : false
                                // }
                                onClick={(e) =>
                                  isOpenFriendModaltoggle(e.target.value)
                                }
                              />
                            </div>

                            {selectedOption == 4 && (
                              <Controller
                                name="selectedFriend"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={selectedTagsName}
                                    onChange={handleChnageFreinds}
                                    className="selected-tag"
                                    classNamePrefix="react-select"
                                    isMulti
                                    placeholder="Select friends"
                                    closeMenuOnSelect={false}
                                  />
                                )}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-12 p-0 position-relative">
                          <textarea
                            rows="4"
                            defaultValue={location?.state?.data?.caption}
                            placeholder="Add caption"
                            {...register("caption")}
                          ></textarea>
                        </div>
                        {(selectedOption == 3 || selectedOption == 1) && (
                          <>
                            <div className="col-12 p-0">
                              <label for="searchfrnds">Tag your Friends</label>
                              <div className="d-flex align-items-center passfield-wrapper preview-src-icon">
                                <span className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
                                      fill="#818898"
                                    />
                                  </svg>
                                </span>

                                <Controller
                                  name="Tags"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      classNames="selected-account"
                                      {...field}
                                      options={selectedTagsName}
                                      onChange={handleTags}
                                      className=" w-100 px-2 custom-select"
                                      classNamePrefix="select"
                                      isMulti
                                      placeholder="Select Your friends"
                                      closeMenuOnSelect={false}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div className="col-12 col-md-4 p-0 pe-md-3">
                          <button
                            type="button"
                            className="btn btn-light btn-back w-100"
                            onClick={() => previewtoggle()}
                          >
                            Preview
                          </button>
                        </div>
                        <div className="col-12 col-md-4 p-0 pe-md-3">
                          <button
                            type="submit"
                            disabled={isLoading}
                            className="btn btn-primary w-100"
                          >
                            {isLoading ? <Spinner /> : "Publish"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <div className="modal-content create-post post-preview">
                  <div className="modal-body p-0">
                    <div className="card post-card">
                      <div className="card-body">
                        <div className="user-det-head d-flex justify-content-between align-items-start">
                          <div className="user-det d-flex gap-3">
                            {userDetails?.Profile?.profilePhoto ? (
                              <div className="user-dp">
                                <img
                                  src={userDetails?.Profile?.profilePhoto}
                                  alt="Profile"
                                />
                              </div>
                            ) : (
                              <div className="user-dp no-img">
                                {userDetails?.Profile?.firstName?.[0]}
                              </div>
                            )}
                            <div className="user-ac">
                              <div className="d-flex gap-2 align-items-center">
                                <span className="username">
                                  <Link to="#">
                                    {userDetails?.Profile?.firstName}{" "}
                                    {userDetails?.Profile?.lastName}
                                  </Link>
                                </span>
                                {userDetails.type === 2 && (
                                  <span className="usertype vendor mx-1">
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={13}
                                        height={13}
                                        viewBox="0 0 13 13"
                                        fill="none"
                                      >
                                        <circle
                                          cx="5.74979"
                                          cy="6.72714"
                                          r="3.75247"
                                          fill="white"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    Vendor
                                  </span>
                                )}
                                {userDetails.type === 3 && (
                                  <span className="usertype mx-1">
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={13}
                                        height={13}
                                        viewBox="0 0 13 13"
                                        fill="none"
                                      >
                                        <circle
                                          cx="5.74979"
                                          cy="6.72714"
                                          r="3.75247"
                                          fill="white"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                          fill="#F4336D"
                                        />
                                      </svg>
                                    </span>
                                    Talent
                                  </span>
                                )}
                                {/* <span className="ms-2 follow"> Follow</span> */}
                              </div>
                              <p>{getLoginUserCaption(PostDetials?.User)}</p>
                            </div>
                          </div>
                        </div>

                        <div className="content">
                          {PostDetials && (
                            <>
                              {imageUrl?.image_view && (
                                <div className="position-relative">
                                  {imageUrl?.image_view &&
                                    (location?.state?.data.mediaType ===
                                    "photo" ? (
                                      <img
                                        src={imageUrl?.image_view}
                                        key={imageUrl?.image_view}
                                        alt=""
                                        className=""
                                      />
                                    ) : (
                                      location?.state?.data.mediaType ===
                                        "video" && (
                                        <video autoPlay controls>
                                          <source
                                            src={imageUrl?.image_view}
                                            type={"video"}
                                            key={imageUrl.image_url?.name}
                                          />
                                        </video>
                                      )
                                    ))}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div className="user-activity d-flex justify-content-between">
                          <div className="d-flex">
                            <span className="liked">
                              {PostDetials?.Likes} likes
                            </span>
                            <span className="commented">
                              {PostDetials?.Comments} Comments
                            </span>
                            <span className="shared">
                              {PostDetials?.shareCount} Shared
                            </span>
                          </div>
                          <div className="d-flex user-activity">
                            <span
                              className={
                                PostDetials?.isLikedByMe
                                  ? "like activated"
                                  : "like"
                              }
                            >
                              {SvgIcons?.likeIcon}
                            </span>
                            <span //
                              className={
                                PostDetials?.isSavedByMe
                                  ? "save activated"
                                  : "save"
                              }
                            >
                              {SvgIcons?.saveIcon}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(selectedOption == 3 || selectedOption == 1) && (
                      <>
                        <div className="col-12 p-0">
                          <label for="searchfrnds">Tag your Friends</label>
                          <div className="d-flex align-items-center passfield-wrapper preview-src-icon">
                            <span className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
                                  fill="#818898"
                                />
                              </svg>
                            </span>

                            <Controller
                              name="Tags"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  classNames="selected-account"
                                  {...field}
                                  options={selectedTagsName}
                                  onChange={handleTags}
                                  className=" w-100 px-2 custom-select"
                                  classNamePrefix="select"
                                  isMulti
                                  placeholder="Select Your friends"
                                  closeMenuOnSelect={false}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="modal-footer border-0 gap-4">
                    {PostDetials?.postStatus == "draft" && (
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => onSaveDraft()}
                        disabled={isLoadingDraft}
                      >
                        {isLoadingDraft ? <Spinner /> : "Save Draft"}
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setPreviewToggle(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EditPost;
