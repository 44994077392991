import { json, useLocation, useNavigate } from "react-router-dom"; // Import hooks for navigation and location
import Footer from "../../../Components/Footer"; // Import the Footer component
import Header from "../../../Components/Header"; // Import the Header component
import { CountryCode } from "../../../utility/CountryCode"; // Import country codes utility (not used in the provided code)
import { RoutesLink } from "../../../utility/RoutesLink"; // Import routes link utility
import { useEffect, useState } from "react"; // Import React hooks
import { useForm } from "react-hook-form"; // Import form handling library
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper"; // Import API request helper function
import { ApiUrl } from "../../../utility/ApiUrl"; // Import API URL utility

const TalentMainSignUp = () => {
  const navigate = useNavigate(); // Hook to programmatically navigate
  const location = useLocation(); // Hook to access location object
  const [usertype, setUsertype] = useState(3); // State to hold the user type (default is talent)
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const {
    register, // Register function to register input fields
    handleSubmit, // Function to handle form submission
    formState: { errors }, // Form state, including validation errors
  } = useForm(); // Initialize form handling with react-hook-form

  // Function to generate a random 12-character string
  function generateRandomString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Possible characters
    let randomString = "";
    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length); // Get random index
      randomString += characters.charAt(randomIndex); // Append random character to the string
    }
    return randomString; // Return the generated random string
  }

  // Function to handle form submission
  const onSubmit = (data) => {
    let randomString = generateRandomString(); // Generate a random string for device ID and token
    // Prepare data to submit to the API
    let submitData = {
      email: location.state.email, // Get email from location state
      phone: location.state.phone_no, // Get phone number from location state
      password: location.state.password, // Get password from location state
      username: location.state.username, // Get username from location state
      type: location?.state?.usertype, // User type from location state
      deviceId: `website-12345`, // Randomly generated device ID
      deviceToken: randomString, // Randomly generated device token for FCM
      firstName: location?.state?.first_name, // First name from location state
      lastName: location?.state?.last_name, // Last name from location state
      gender: location?.state?.gender, // Gender from location state
      socialurls: JSON.stringify(location?.state?.socialUrl), // Social URLs for talent and vendor
      artistBandName: location?.state?.band_name, // Artist or band name for vendor and talent
      location: JSON.stringify(location?.state?.location), // Serialize location data
    };

    // Additional fields for talents
    if (location?.state?.usertype === 3) {
      submitData = {
        ...submitData,
        catagory: location?.state?.category_service_data, // Category service data for talent
        instrument: location?.state?.instrumentData?.instrument, // Instrument data for talent
        genre: location?.state?.genreData, // Genre data for talent
      };
    } else {
      // Additional fields for vendors
      submitData = {
        ...submitData,
        services: location?.state?.category_service_data, // Services for vendor
      };
    }

    // Send a POST request to the sign-up API
    handleFetchRequest(ApiUrl?.sign_up, "POST", submitData)
      .then((response) => {
        // Handle the API response
        if (response?.status === true) {
          // If signup is successful, navigate to OTP submission page
          navigate(RoutesLink?.otp_submit, {
            state: {
              deviceToken: randomString, // Pass the device token to the next page
              password: data?.password, // Pass the password to the next page
              username: data?.username, // Pass the username to the next page
            },
          });
        } else {
          // Handle failure (additional error handling can be implemented here)
        }
      })
      .catch((err) => {
        // Log any errors that occur during the API call
        console.log("This is Error......", err);
      });
  };

  // Function to toggle password visibility
  const handleShowPassword = () => {
    setShowPassword((pre) => !pre); // Toggle the state for showing/hiding password
  };
  return (
    <>
      <Header />
      <section className="signin navbar-spc">
        <div className="max-width-container h-100">
          <div className="row  h-100 justify-content-between justify-content-lg-center">
            <div className="col-12 col-md-5 col-lg-5 col-xl-6 p-0 d-none d-md-flex align-items-center justify-content-center position-relative">
              <div className="img-box">
                <img src="images/sign_in/frame.png" alt="" className="frame" />
                <img
                  src="images/sign_in/sign-img2.jpg"
                  alt=""
                  className=" content-img"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-6 p-0 d-flex justify-content-center align-items-center">
              <div className="card signin-card">
                <div className="card-body">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="Talent-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Talent-tab"
                      tabindex="0"
                    >
                      <div className="row tab-descp">
                        <div className="col-12 p-0">
                          {location?.state?.usertype === 2 ? (
                            <>
                              <h4>Vendor</h4>
                              <p>
                                Become a Jubal Vendor and unlock all the
                                features and benefits of the Jubal Community.
                                Sell tickets, find and hire talent, promote your
                                artist, create events, and more.
                              </p>
                            </>
                          ) : (
                            <>
                              <h4>Talent Community</h4>
                              <p>
                                If you would like to proceed with signing up as
                                a Jubal Talent, you will be part of the Jubal
                                Talents community granting you access to
                                performance and networking opportunities
                              </p>
                            </>
                          )}
                        </div>
                      </div>

                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row ">
                          <div className="col-12 p-0">
                            <label for="fname" className="form-label">
                              Username
                            </label>
                            <div className="d-flex align-items-center passfield-wrapper usricon">
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="20"
                                  viewBox="0 0 21 20"
                                  fill="none"
                                >
                                  <path
                                    d="M10.0075 10.9793C12.1463 10.9793 13.8616 9.24287 13.8616 7.12525C13.8616 5.00763 12.1463 3.25 10.0075 3.25C7.86874 3.25 6.15346 4.98645 6.15346 7.10407C6.15346 9.2217 7.86874 10.9793 10.0075 10.9793ZM10.0075 4.26646C11.5746 4.26646 12.8451 5.53703 12.8451 7.10407C12.8451 8.67111 11.5746 9.94169 10.0075 9.94169C8.44049 9.94169 7.16992 8.69229 7.16992 7.12525C7.16992 5.55821 8.44049 4.26646 10.0075 4.26646ZM3.31585 17.0145H16.7416C17.0169 17.0145 17.2498 16.7816 17.2498 16.5063C17.2498 13.8381 15.0687 11.657 12.4004 11.657H7.65697C4.98877 11.657 2.80762 13.8381 2.80762 16.5063C2.80762 16.7816 3.04056 17.0145 3.31585 17.0145ZM7.65697 12.6734H12.4004C14.3487 12.6734 15.9369 14.1134 16.191 15.9981H3.86643C4.12054 14.1134 5.70876 12.6734 7.65697 12.6734Z"
                                    fill="#818898"
                                  />
                                </svg>
                              </span>

                              <input
                                type="text"
                                className="form-control"
                                id="fname"
                                placeholder=" Username"
                                {...register("username", {
                                  required: "Please enter username",
                                })}
                              />
                              <div className="form-text mt-2 text-danger">
                                {errors?.username?.message}
                              </div>
                            </div>
                          </div>

                          <div className="col-12 p-0">
                            <label for="lname" className="form-label">
                              password
                            </label>
                            <div className="d-flex align-items-center passfield-wrapper">
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="20"
                                  viewBox="0 0 21 20"
                                  fill="none"
                                >
                                  <path
                                    d="M14.55 3.25L8.97495 8.8246C8.36983 8.64116 7.73016 8.60195 7.10717 8.71012C6.48417 8.81829 5.89516 9.07083 5.38729 9.44752C4.87943 9.82421 4.46682 10.3146 4.1825 10.8794C3.89819 11.4442 3.75007 12.0677 3.75 12.7C3.75 13.7741 4.1767 14.8043 4.93622 15.5638C5.69574 16.3233 6.72587 16.75 7.8 16.75C8.43233 16.7499 9.05585 16.6017 9.62062 16.3173C10.1854 16.033 10.6757 15.6203 11.0524 15.1124C11.429 14.6045 11.6815 14.0154 11.7896 13.3924C11.8977 12.7694 11.8585 12.1297 11.6749 11.5246L12.75 10.45V9.55H13.65L17.25 5.95V3.25H14.55ZM16.35 5.57695L13.2774 8.65H11.85V10.0769L11.0387 10.8878L10.6571 11.2694L10.8137 11.7856C10.9041 12.0835 10.95 12.3909 10.95 12.7C10.95 14.437 9.53655 15.85 7.8 15.85C6.06345 15.85 4.65 14.437 4.65 12.7C4.65 10.963 6.06345 9.55 7.8 9.55C8.1087 9.55 8.4165 9.5959 8.71395 9.6859L9.22965 9.8425L9.61125 9.4609L14.9226 4.15H16.35V5.57695Z"
                                    fill="#818898"
                                  />
                                  <path
                                    d="M6.9 14.5002C7.39706 14.5002 7.8 14.0973 7.8 13.6002C7.8 13.1031 7.39706 12.7002 6.9 12.7002C6.40294 12.7002 6 13.1031 6 13.6002C6 14.0973 6.40294 14.5002 6.9 14.5002Z"
                                    fill="#818898"
                                  />
                                  <path
                                    d="M12.5908 7.59111L15.2908 4.89111L15.609 5.20926L12.909 7.90926L12.5908 7.59111Z"
                                    fill="#818898"
                                  />
                                </svg>
                              </span>
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                {...register("password", {
                                  required: "Please enter password",
                                })}
                              />
                              <span className="visi">
                                {showPassword ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    onClick={handleShowPassword}
                                  >
                                    <path
                                      d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                      stroke="#818898"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                      stroke="#818898"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    onClick={handleShowPassword}
                                  >
                                    <path
                                      d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                      stroke="#818898"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                      stroke="#818898"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M3 3L18 17"
                                      stroke="#818898"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="col-12 px-4">
                            <button className="btn btn-primary w-100">
                              Continue
                            </button>
                          </div>
                          {/* <!-- <div className="col-12 px-4">
                        <button className="btn btn-light w-100">
                          Back
                        </button>
                      </div> --> */}
                          <div className="col-12 p-0">
                            <p className="pripoly text-center">
                              By signing up, you are creating a Jubal Talent
                              account and agree to Jubal Talent
                              <a href="#">Terms</a>and
                              <a href="#">Privacy Policy</a>
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TalentMainSignUp;
