import React, { useEffect, useState } from "react";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import MobileHeader from "../../Components/Layout/MobileHeader";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { useForm } from "react-hook-form";
import { toast } from "material-react-toastify";

function UserPrefrence() {
  const [callApi, setCallApi] = useState(true);
  const [UserDetials, setUserDetials] = useState({});
  const [listData, setListData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [InstrumentData, setInstrumentData] = useState([]);
  const [GenraData, setGenraData] = useState([]);

  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      selectedItems: [], // Initial default values
    },
  });

  useEffect(() => {
    handleFetchRequest(ApiUrl?.auth_check).then((res) => {
      if (res.status === true) {
        const talentData = res?.data?.Talent;
        if (talentData) {
          // Parse the JSON strings to arrays

          const categories = JSON.parse(talentData.catagory || "[]");
          const instruments = JSON.parse(talentData.instrument || "[]");
          const genres = JSON.parse(talentData.genre || "[]");

          console.log("instreument----", talentData.genre);

          // Set parsed data to the form
          setSelectedData(categories);
          setValue("selectedItems", categories);
          setValue2("Instreument_1", instruments);
          setValue3("music_2", genres);
        }
        setUserDetials(res.data);
      }
    });
    if (callApi) {
      handleFetchRequest(ApiUrl?.get_categories).then((response) => {
        if (response?.status === true) {
          setListData(response?.data);
        } else {
        }
      });

      handleFetchRequest(`${ApiUrl.auth_check}`).then((res) => {
        if (res.status === true) {
          setUserDetials(res.data);
          console.log("resss", res?.data);
          setCallApi(false);
        }
      });
    }
    handleFetchRequest(ApiUrl?.get_instruments, "POST").then((response) => {
      if (response?.status === true) {
        setInstrumentData(response.data?.instrument);
        // console.log("ressssss===>>>>", response.data?.instrument);
      } else {
      }
    });
    handleFetchRequest(ApiUrl?.get_music_type, "GET").then((response) => {
      if (response?.status === true) {
        setGenraData(response?.data);
      } else {
      }
    });
  }, [callApi]);

  const addHandler = (id) => {
    const currentValues = getValues("selectedItems");
    if (currentValues.includes(id)) {
      setValue(
        "selectedItems",
        currentValues.filter((item) => item !== id)
      );
    } else {
      setValue("selectedItems", [...currentValues, id]);
    }
  };
  const onSubmit = (data) => {
    console.log("Selected items:", data.selectedItems);
    // You can now use data.selectedItems for further processing, like sending to an API.
    const personalDetails = {
      catagory: JSON.stringify(data.selectedItems),
    };

    handleFetchRequest(ApiUrl?.set_profile, "PUT", personalDetails).then(
      (response) => {
        if (response?.status === true) {
          toast.success(response?.message);
        } else {
          toast.error(response?.message);
        }
      }
    );
  };

  const checkHandler = (id) => {
    const currentValues = getValues("selectedItems") || [];
    return currentValues.includes(id);
  };
  // Form 2
  const {
    register: registerForm2,
    handleSubmit: handleSubmitForm2,
    formState: { errors: errorsForm2 },
    setValue: setValue2,
  } = useForm({
    defaultValues: {
      Instreument_1: [], // Initial default values
    },
  });

  const onInstrumentSubmit = (data) => {
    // You can now use data.selectedItems for further processing, like sending to an API.
    const personalDetails = {
      instrument: JSON.stringify(data.Instreument_1),
    };

    handleFetchRequest(ApiUrl?.set_profile, "PUT", personalDetails).then(
      (response) => {
        if (response?.status === true) {
          toast.success(response?.message);
        } else {
          toast.error(response?.message);
        }
      }
    );
  };

  const {
    register: registerForm3,
    handleSubmit: handleSubmitForm3,
    formState: { errors: errorsForm3 },
    setValue: setValue3,
  } = useForm();

  const onMusicSubmit = (data) => {
    // You can now use data.selectedItems for further processing, like sending to an API.
    const personalDetails = {
      genre: JSON.stringify(data.music_2),
    };
    handleFetchRequest(ApiUrl?.set_profile, "PUT", personalDetails).then(
      (response) => {
        if (response?.status === true) {
          toast.success(response?.message);
        } else {
          toast.error(response?.message);
        }
      }
    );
  };

  const checkInstrumentHandler = (id) => {
    const currentValues = getValues("selectedInstrument") || [];
    return currentValues.includes(id);
  };

  const addInstrumentHandler = (id) => {
    const currentValues = getValues("selectedInstrument");
    if (currentValues.includes(id)) {
      setValue(
        "selectedInstrument",
        currentValues.filter((item) => item !== id)
      );
    } else {
      setValue("selectedInstrument", [...currentValues, id]);
    }
  };

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <section className="px-0 main-content">
        <div className="max-width-container">
          {/* =========================== */}
          {/* Main feed grid Start */}
          {/* =========================== */}
          <div className="main-feed-grid">
            {/* =========================== */}
            {/*left side content Start */}
            {/* =========================== */}
            <DashboardSidebar />
            {/* =========================== */}
            {/*left side content end */}
            {/* =========================== */}
            {/* =========================== */}
            {/* center content Start */}
            {/* =========================== */}
            <div className="center-content d-block">
              <div>
                <div className="card tab-card activity-card">
                  <div className="card-body pb-0 position-relative">
                    <div className="divideder position-absolute" />
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="activity-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#activity-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="activity-tab-pane"
                          aria-selected="true"
                        >
                          Primary Category
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="equi-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#equi-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="equi-tab-pane"
                          aria-selected="false"
                          tabIndex={-1}
                        >
                          Primary musical instruments
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="event-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#event-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="event-tab-pane"
                          aria-selected="false"
                          tabIndex={-1}
                        >
                          Music genre
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade active show"
                        id="activity-tab-pane"
                        role="tabpanel"
                        aria-labelledby="like-tab"
                        tabIndex={0}
                      >
                        <div className="card signin-card border-0 w-100">
                          <div className="card-body">
                            <div className="row tab-descp">
                              <div className="col-12 p-0 cat-detl">
                                <h4>
                                  We’d like to know about your Primary Category
                                </h4>
                                <p>Your music primary preferences</p>
                              </div>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="row">
                                {listData?.map((data, index) => (
                                  <div
                                    key={index}
                                    className="form-check d-flex align-items-center justify-content-between"
                                  >
                                    <label
                                      className="form-check-label"
                                      htmlFor={`musicPro-${index}`}
                                    >
                                      {data?.catagory}
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={data?.catagory}
                                      id={`musicPro-${index}`}
                                      {...register("selectedItems")}
                                      onChange={() =>
                                        addHandler(data?.catagory)
                                      }
                                    />
                                  </div>
                                ))}
                                <div className="col-12 px-0">
                                  <div className="row">
                                    <div className="col-12 col-md-5 col-lg-4">
                                      <button
                                        type="submit"
                                        className="btn btn-primary w-100"
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="equi-tab-pane"
                        role="tabpanel"
                        aria-labelledby="comment-tab"
                        tabIndex={0}
                      >
                        <div className="card signin-card border-0 w-100">
                          <div className="card-body">
                            <div className="row tab-descp">
                              <div className="col-12 p-0 cat-detl">
                                <h4>
                                  What is your primary musical instruments?
                                </h4>
                                <p>Your music primary preferences</p>
                              </div>
                            </div>
                            <form
                              onSubmit={handleSubmitForm2(onInstrumentSubmit)}
                            >
                              <div className="row">
                                {InstrumentData &&
                                  InstrumentData.map((data, index) => {
                                    return (
                                      <div class="col-12 p-0" key={data.id}>
                                        <div class="form-check d-flex align-items-center justify-content-between">
                                          <label
                                            class="form-check-label"
                                            for={`instrument-${index}`}
                                          >
                                            {data?.instrument_name}
                                          </label>
                                          {/* <input
                                            class="form-check-input"
                                            type="checkbox"
                                            value={data?.id}
                                            id={`instrument-${index}`}
                                            {...register("selectedInstrument")}
                                            onChange={(event) =>
                                              addInstrumentHandler(
                                                event,
                                                data?.id
                                              )
                                            }
                                          /> */}

                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={data?.instrument_name}
                                            id={`Instreument_${1}`}
                                            // {...register("selectedInstrument")}
                                            // defaultValue="true"
                                            {...registerForm2(
                                              `Instreument_${1}`,
                                              {
                                                // required: "Please fill the visibilty!",
                                              }
                                            )}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                              <div className="col-12 px-0">
                                <div className="row">
                                  <div className="col-12 col-md-5 col-lg-4 ">
                                    <button className="btn btn-primary w-100">
                                      Update
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="event-tab-pane"
                        role="tabpanel"
                        aria-labelledby="review-tab"
                        tabIndex={0}
                      >
                        <div className="card signin-card border-0 w-100">
                          <div className="card-body">
                            <div className="row tab-descp">
                              <div className="col-12 p-0 cat-detl">
                                <h4>
                                  In which other music genres are you proficient
                                </h4>
                                <p>Your music primary preferences</p>
                              </div>
                            </div>
                            <form onSubmit={handleSubmitForm3(onMusicSubmit)}>
                              <div className="row">
                                <div className="col-12 p-0">
                                  <div className="d-flex align-items-center passfield-wrapper">
                                    <span className="icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={18}
                                        height={18}
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
                                          fill="#818898"
                                        />
                                      </svg>
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="searchbar"
                                      placeholder="What are you looking for?"
                                    />
                                    <span className="visi">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={21}
                                        height={12}
                                        viewBox="0 0 21 12"
                                        fill="none"
                                      >
                                        <path
                                          d="M8.31407 11.9026H12.9271V9.93506H8.31407V11.9026ZM0.241211 0.0974121V2.06494H21V0.0974121H0.241211ZM3.70101 6.98376H17.5402V5.01624H3.70101V6.98376Z"
                                          fill="#818898"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                </div>

                                {GenraData &&
                                  GenraData.map((data, index) => {
                                    return (
                                      <div class="col-12 p-0" key={data.id}>
                                        <div class="form-check d-flex align-items-center justify-content-between">
                                          <label
                                            class="form-check-label"
                                            for={`music-${index}`}
                                          >
                                            {data?.type}
                                          </label>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={data?.type}
                                            id={`music_2`}
                                            // {...register("selectedInstrument")}
                                            // defaultValue="true"
                                            {...registerForm3(`music_2`, {
                                              // required: "Please fill the visibilty!",
                                            })}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                <div className="col-12 px-0">
                                  <div className="row">
                                    <div className="col-12 col-md-5 col-lg-4 ">
                                      <button className="btn btn-primary w-100">
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* =========================== */}
            {/*center content end */}
            {/* =========================== */}
          </div>
          {/* =========================== */}
          {/* Main feed grid End */}
          {/* =========================== */}
        </div>
      </section>
    </>
  );
}

export default UserPrefrence;
