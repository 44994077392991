import React, { useEffect, useMemo, useState } from "react"; // Import necessary React hooks
import { Link } from "react-router-dom"; // Import Link component for navigation
import { RoutesLink } from "../../utility/RoutesLink"; // Utility for defining application routes
import { calculateHours, getLoginUserCaption } from "../../Helper/Helper"; // Helper functions for calculating time and getting user captions
import { SvgIcons } from "../Svg/SvgIcons"; // Component for SVG icons
import ShareModal from "../Modals/ShareModal"; // Modal component for sharing events
import { useSelector } from "react-redux"; // Hook to access Redux store
import ShareEventModal from "../Modals/ShareEventModal";
import moment from "moment";

function DahboardEventCard({ event, LikeHandler, savePost, onfollwuser }) {
  // State to hold event data
  const [EventData, setEventData] = useState([]);

  // Accessing user details from Redux store
  const userDetail = useSelector((store) => store?.user?.userDetails);

  // State to manage the modal visibility for sharing
  const [modal, setModal] = useState(false);

  // Function to toggle the modal visibility
  const toggle = () => setModal(!modal);

  // Effect to set the event data when the component mounts or the event prop changes
  useEffect(() => {
    setEventData(event); // Set event data from props
  }, [event]); // Dependency array to re-run effect when event changes

  // Memoized value to calculate the duration since the event was created
  const duration = useMemo(
    () => calculateHours(event?.createdAt), // Call calculateHours with event's createdAt timestamp
    [event?.updatedAt] // Dependency array for recalculating duration when event updates
  );
  return (
    <>
      <div className="card post-card event-post">
        <div className="card-body">
          <div className="user-det-head d-flex justify-content-between align-items-start">
            <div className="user-det d-flex gap-3">
              {event?.User?.profilePhoto ? (
                <Link
                  to={
                    event?.User?.id === userDetail?.Profile?.userId
                      ? RoutesLink?.MyProfile
                      : RoutesLink?.profile
                  }
                  state={{ id: event?.User?.id }}
                  className="user-dp"
                >
                  <img alt="" src={event?.User?.profilePhoto} />
                </Link>
              ) : (
                <Link
                  to={
                    event?.User?.id === userDetail?.Profile?.userId
                      ? RoutesLink?.MyProfile
                      : RoutesLink?.profile
                  }
                  state={{ id: event?.User?.id }}
                  className="user-dp no-img"
                >
                  {event?.User?.firstName[0]}
                </Link>
              )}

              <div className="user-ac">
                <div className="d-flex gap-2 align-items-center">
                  <span className="username">
                    <Link
                      to={
                        event?.User?.id === userDetail?.Profile?.userId
                          ? RoutesLink?.MyProfile
                          : RoutesLink?.profile
                      }
                      state={{ id: event?.User?.id }}
                    >
                      {event?.User?.firstName} {event?.User?.lastName}
                    </Link>
                  </span>
                  {(event?.User?.type == 3 || event?.User?.type == 2) && (
                    <span
                      className={
                        event?.User?.type == 3
                          ? "usertype talent mx-1"
                          : "usertype vendor mx-1"
                      }
                    >
                      <span>
                        <svg
                          fill="none"
                          height="13"
                          viewBox="0 0 13 13"
                          width="13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="5.74979"
                            cy="6.72714"
                            fill="white"
                            r="3.75247"
                          />
                          <path
                            clipRule="evenodd"
                            d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                            fill="#F4336D"
                            fillRule="evenodd"
                          />
                        </svg>
                      </span>
                      {event?.User?.type == 3 ? "Talent" : "Vendor"}
                    </span>
                  )}
                  {/* <span
                    className="ms-2 follow"
                    onClick={() =>
                      onfollwuser(EventData, EventData.isFollowedByMe, "POST")
                    }
                  >
                    {console.log("EventData===>>>", EventData.isFollowedByMe)}
                    {EventData?.isFollowedByMe ? "Unfollow" : "Follow"}
                  </span> */}
                </div>
                <p>{getLoginUserCaption(event?.User)}</p>
              </div>
            </div>
            <div className="post-det d-flex align-items-center gap-1 gap-1">
              <span className="post-time">
                {" "}
                {moment(
                  event?.eventTimeLocation?.start_date,
                  "DD-MM-YYYY"
                ).format(" Do MMM YY")}{" "}
                {/* {event?.eventTimeLocation?.start_time}  */}
                {" - "}
                {moment(
                  event?.eventTimeLocation?.end_date,
                  "DD-MM-YYYY"
                ).format(" Do MMM YY")}{" "}
              </span>
              <div className="dropdown dropdown-menu-end">
                <Link
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  to="#"
                  role="button"
                >
                  <svg
                    fill="none"
                    height="15"
                    viewBox="0 0 31 15"
                    width="31"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3859 7.59648C10.3859 6.26906 9.3098 5.19297 7.98237 5.19297C6.65495 5.19297 5.57886 6.26906 5.57886 7.59648C5.57886 8.92391 6.65495 10 7.98237 10C9.3098 10 10.3859 8.92391 10.3859 7.59648Z"
                      fill="#343B4E"
                    />
                    <path
                      d="M18.193 7.59648C18.193 6.26906 17.1169 5.19297 15.7895 5.19297C14.4621 5.19297 13.386 6.26906 13.386 7.59648C13.386 8.92391 14.4621 10 15.7895 10C17.1169 10 18.193 8.92391 18.193 7.59648Z"
                      fill="#343B4E"
                    />
                    <path
                      d="M25.9999 7.59648C25.9999 6.26906 24.9238 5.19297 23.5964 5.19297C22.269 5.19297 21.1929 6.26906 21.1929 7.59648C21.1929 8.92391 22.269 10 23.5964 10C24.9238 10 25.9999 8.92391 25.9999 7.59648Z"
                      fill="#343B4E"
                    />
                  </svg>
                </Link>
                <ul className="dropdown-menu post-dropdown">
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={() => toggle(event?.id)}
                    >
                      Share Event
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="dropdown-item" to="#">
                      Report Event
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <Link
            to={`${RoutesLink?.EventDetails}/${event.id}`}
            state={event?.id}
            className="content event-cover"
          >
            <img alt="" className="w-100" src={event?.eventImage} />
          </Link>
          <Link
            to={`${RoutesLink?.EventDetails}/${event.id}`}
            state={event?.id}
          >
            <div className="event-caption">
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between gap-2">
                  <span className="event-type">{event?.eventType}</span>
                  <span
                    className="event-type public-event"
                    style={{ textTransform: "capitalize" }}
                  >
                    {event?.visibilityType}
                  </span>
                </div>
                <div className="d-flex justify-content-between gap-4">
                  <h5>
                    {/* {EventData?.entertainmentType}: */}
                    {event?.eventName?.substring(0, 250)}
                    {/* <button className="btn btn-link p-0 m-0">
                      {EventData?.eventDescription?.length > 250 &&
                        "show more..."}
                    </button> */}
                  </h5>
                  {event?.tickets?.price ? (
                    <h5>$ {event?.tickets?.price}</h5>
                  ) : (
                    <h5 className="text-md-end free">Free</h5>
                  )}
                </div>
                <p>
                  <span>
                    <svg
                      fill="none"
                      height="15"
                      viewBox="0 0 13 15"
                      width="13"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clipRule="evenodd"
                        d="M1 6.94651C1.01023 3.93422 3.46047 1.50057 6.47276 1.51077C9.48505 1.52104 11.9187 3.97128 11.9085 6.98357V7.04534C11.8714 9.00342 10.7781 10.8133 9.43769 12.2278C8.67112 13.0238 7.81507 13.7285 6.88662 14.3279C6.63835 14.5427 6.27011 14.5427 6.02184 14.3279C4.63774 13.427 3.42295 12.2896 2.43305 10.9677C1.55077 9.81495 1.04984 8.41581 1 6.96504V6.94651Z"
                        fillRule="evenodd"
                        stroke="#ADADAD"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M6.45319 9.1261C7.81236 9.1261 8.91419 8.02427 8.91419 6.6651C8.91419 5.30593 7.81236 4.2041 6.45319 4.2041C5.09401 4.2041 3.99219 5.30593 3.99219 6.6651C3.99219 8.02427 5.09401 9.1261 6.45319 9.1261Z"
                        stroke="#ADADAD"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </span>
                  {event?.eventTimeLocation?.location}
                </p>
              </div>
            </div>
          </Link>
          {/* <div className="user-activity d-flex justify-content-between">
          <div className="d-flex">
            <span
              className="liked d-none d-lg-block"
              data-bs-target="#likes"
              data-bs-toggle="modal"
            >
              {EventData?.like} likes
            </span>
            <span
              aria-controls="offcanvaslikes"
              className="liked d-lg-none"
              data-bs-target="#offcanvaslikes"
              data-bs-toggle="offcanvas"
              type="button"
            >
              {EventData?.like} likes
            </span>
          </div>
          <div className="d-flex user-activity">
            <Link
              onClick={() =>
                LikeHandler(EventData, EventData?.isLikedByMe, "POST")
              }
              className={
                EventData?.isLikedByMe ? "like activated" : "like"
              }
            >
              {SvgIcons?.likeIcon}
            </Link>
            <Link
              onClick={() => savePost(EventData, EventData?.isLikedByMe)}
              className={
                EventData?.isSavedByMe ? "save activated" : "save"
              }
            >
              {SvgIcons?.saveIcon}
            </Link>
          </div>
        </div> */}
        </div>
      </div>
      <ShareEventModal
        modal={modal}
        toggle={() => toggle()}
        postId={event?.id}
        type="event"
      />
    </>
  );
}

export default DahboardEventCard;
