import React from "react";
import "./helper.css";

function SkulLoader() {
  return (
    <>
      <div className="row">
        <div className="col-lg-3"></div>
        <div className="Loader col-lg-6">
          <div className="wrapper">
            <div className="wrapper-cell">
              <div className="text">
                <div className=""></div>
                <div className="text-line"> </div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
              </div>
            </div>
            <div className="Image-wapper">
              <div className="image"></div>
            </div>
          </div>
        </div>
        <div className="Loader col-lg-3">
          <div className="wrapper">
            <div className="wrapper-cell">
              <div className="text">
                <div className=""></div>
                <div className="text-line"> </div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
              </div>
            </div>
            <div className="Image-wapper">
              <div className="image"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SkulLoader;
