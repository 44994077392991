import { useState, useEffect } from "react";
import GooglePlaceAutocomplete from "../Components/GooglePlaceAutocomplete";
import { CountryCode } from "../utility/CountryCode";
import { useForm } from "react-hook-form";
import { toast } from "material-react-toastify";
import { handleFetchRequest } from "../ApiHelper/ApiHelper";
import { ApiUrl } from "../utility/ApiUrl";
import { Spinner } from "reactstrap";
import { setIsLocationDetails } from "../Helper/Helper";

function AddNewaddress({
  close,
  isEdit,
  AddressEditData,
  setAddressEditData,
  setIsEdit,
}) {
  console.log("is edit", AddressEditData);

  // State variables
  const [selectedPlace, setSelectedPlace] = useState({}); // Holds the selected address details
  const [isLoading, setIsLoading] = useState(false); // Loading state for API requests
  const [addressType, setAddressType] = useState(""); // Holds the type of address
  const [customAddressType, setCustomAddressType] = useState(""); // Holds a custom address type
  const [existingAddressTypes, setExistingAddressTypes] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues
  } = useForm({
    // Default form values from AddressEditData if in edit mode
    defaultValues: {
      fullname: AddressEditData?.name || "",
      email: AddressEditData?.email || "",
      contact: AddressEditData?.phone || "",
      AddType: AddressEditData?.type || "home",
    },
  });

  // Effect to set initial values when in edit mode
  useEffect(() => {
    if (isEdit && AddressEditData) {
      console.log(AddressEditData);
      // Set address type based on the existing address data
      setAddressType(
        AddressEditData?.type === "home" || AddressEditData?.type === "office"
          ? AddressEditData?.type
          : "other"
      );
      setCustomAddressType(AddressEditData?.type);
      // Populate form fields with existing data
      setValue("fullname", AddressEditData?.name);
      setValue("email", AddressEditData?.email);
      setValue("contact", AddressEditData?.phone);
      setValue("countryCode", AddressEditData?.countryCode);
      // Set initial value of AddType from AddressEditData
      setValue(
        "AddType",
        AddressEditData?.type === "home" || AddressEditData?.type === "office"
          ? AddressEditData?.type
          : "other"
      );
      setSelectedPlace(AddressEditData?.location); // Set selected place with existing location
      setValue("fax", AddressEditData?.fax);
      setValue("office_phone", AddressEditData?.office_phone);
      setValue(
        "office_phone_country_code",
        AddressEditData?.office_phone_country_code
      );
      setValue("recovery_email", AddressEditData?.recovery_email);
    }
  }, [isEdit, AddressEditData, setValue]);

  // Effect to fetch existing address types when component mounts
  useEffect(() => {
    handleFetchRequest(ApiUrl?.get_address).then((res) => {
      if (res.status === true) {
        const types = res.data.map((addr) => addr?.type?.toLowerCase());
        setExistingAddressTypes(types);
      }
    });
  }, []);

  // Function to get available address types
  const getAvailableAddressTypes = () => {
    const hasHome = existingAddressTypes.includes("home");
    const hasOffice = existingAddressTypes.includes("office");

    // If editing, include the current address type
    if (isEdit && AddressEditData) {
      const currentType = AddressEditData.addressType.toLowerCase();
      return [
        !hasHome || currentType === "home"
          ? { value: "home", label: "Home" }
          : null,
        !hasOffice || currentType === "office"
          ? { value: "office", label: "Office" }
          : null,
        { value: "other", label: "Other" },
      ].filter(Boolean);
    }

    // console.log("getAvailableAddressTypes", getAvailableAddressTypes());

    // For new addresses
    return [
      !hasHome ? { value: "home", label: "Home" } : null,
      !hasOffice ? { value: "office", label: "Office" } : null,
      { value: "other", label: "Other" },
    ].filter(Boolean);
  };

  // Function to handle form cancellation
  const handleCancel = async () => {
    // Reset form fields and clear address edit data
    setValue("fullname", "");
    setValue("email", "");
    setValue("contact", "");
    setValue("countryCode", "");
    setValue("AddType", "");
    setSelectedPlace("");
    setAddressEditData(null);
  };

  // Function to handle form submission
  const onSubmit = (data) => {
    // Update selectedPlace with additional form data
    selectedPlace.city = data?.city;
    selectedPlace.country = data?.country;
    selectedPlace.state = data?.state;
    selectedPlace.postal_code = data?.zipcode;

    const location = {
      postal_code: "",
    };

    // Extract postal code from address components
    selectedPlace?.address_components?.forEach((component) => {
      const component_types = component.types;
      if (component_types.includes("postal_code")) {
        location.postal_code = component.long_name;
      }
    });

    // Prepare address data for API request
    const updateAddress = {
      name: data?.fullname,
      location: selectedPlace,
      phone: data?.contact,
      countryCode: data?.countryCode,
      // Set type to customAddressType if 'other' is selected
      type: data?.AddType === "other" ? customAddressType : data?.AddType,
      ...(selectedPlace?.postal_code !== "" && {
        zipcode: selectedPlace?.postal_code,
      }),
      email: data?.email,
      fax: data?.fax,
      office_phone: data?.office_phone,
      office_phone_country_code: data?.office_phone_country_code,
      recovery_email: data?.recovery_email,
    };

    setIsLoading(true); // Set loading state to true
    // Check if in edit mode to determine API endpoint
    if (isEdit) {
      handleFetchRequest(
        ApiUrl?.edit_address + AddressEditData?.id,
        "POST",
        updateAddress
      ).then((response) => {
        if (response?.status === true) {
          setIsLoading(false); // Reset loading state
          toast.success(response?.message); // Show success message
          setAddressEditData(null); // Clear address edit data
          setIsEdit(false); // Exit edit mode
          reset(); // Reset form fields
          close(); // Close the address form
        } else {
          setIsLoading(false); // Reset loading state
          toast.error(response?.message); // Show error message
        }
      });
    } else {
      handleFetchRequest(ApiUrl?.create_address, "POST", updateAddress).then(
        (response) => {
          if (response?.status === true) {
            setIsLoading(false); // Reset loading state
            toast.success(response?.message); // Show success message
            reset(); // Reset form fields
            close(); // Close the address form
          } else {
            setIsLoading(false); // Reset loading state
            toast.error(response?.message); // Show error message
          }
        }
      );
    }
  };

  console.log(selectedPlace);
  // Effect to update form values based on selectedPlace changes
  useEffect(() => {
    if (selectedPlace) {
      setValue("city", selectedPlace?.city);
      setValue("country", selectedPlace?.country);
      setValue("state", selectedPlace?.state);
      setValue("zipcode", selectedPlace?.postal_code);
    }
  }, [selectedPlace]);

  // Add this function to check existing address types
  // const getExistingAddressTypes = () => {
  //   return getAddress.map((addr) => addr.addressType.toLowerCase());
  // };

  return (
    <div className="personal-details-form ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row gap-xxl-5">
          <div className="col-12 p-0">
            {isEdit ? <h4>Edit Address</h4> : <h4>Add new Address</h4>}
            <p className="para">
              Add common address that you want to use for collection and/or
              delivery here.
            </p>
          </div>

          <div className="col-12 p-0">
            <label htmlFor="fullname" className="form-label">
              Full Name
              <span className="text-danger fs-2 ps-1">*</span>
            </label>
            <div className="d-flex align-items-center passfield-wrapper">
              <input
                type="text"
                className="form-control"
                id="fullname"
                placeholder="Full Name"
                {...register("fullname", {
                  required: "Full Name is required",
                })}
              />
            </div>
            {errors.fullname && (
              <span className="form-text text-danger">
                {errors.fullname.message}
              </span>
            )}
          </div>

          <div className="col-12 p-0">
            <label htmlFor="email" className="form-label">
              Email Address
              <span className="text-danger fs-2 ps-1">*</span>
            </label>
            <div className="d-flex align-items-center passfield-wrapper">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email address"
                {...register("email", {
                  required: "Email is required",
                  validate: {
                    isValidEmail: value => {
                      // Regular expression for email validation
                      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      return emailPattern.test(value) || "Please enter a valid email address";
                    },
                  },
                })}
              />
              <span className="visi">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={15}
                  viewBox="0 0 16 15"
                  fill="none"
                >
                  <path
                    d="M0.106201 11.9364V14.406C0.106201 14.6335 0.291159 14.8122 0.526561 14.8122H3.08235C3.19164 14.8122 3.30093 14.7716 3.3766 14.6903L12.5572 5.82752L9.40455 2.78119L0.232309 11.644C0.148237 11.7252 0.106201 11.8227 0.106201 11.9364ZM14.9953 3.47169C15.3232 3.15487 15.3232 2.64309 14.9953 2.32627L13.028 0.425358C12.7002 0.108539 12.1705 0.108539 11.8426 0.425358L10.3041 1.91197L13.4568 4.9583L14.9953 3.47169Z"
                    fill="#86889A"
                  />
                </svg>
              </span>
            </div>
            {errors.email && (
              <span className="form-text text-danger">
                {errors.email.message}
              </span>
            )}
          </div>

          <div className="col-12 p-0">
            <label htmlFor="contno" className="form-label">
              Contact Number
              <span className="text-danger fs-2 ps-1">*</span>
            </label>
            <div className="numfield-wrapper">
              <select
                className="form-select nopre-drop position-relative z-3"
                {...register("countryCode", { required: true })}
                id="phone_country_code"
              >
                <option key="+1" value="+1" selected>
                  US (+1)
                </option>
                {CountryCode?.map((data) => (
                  <option key={data?.dial_code} value={data?.dial_code}>
                    {data?.code} ({data?.dial_code})
                  </option>
                ))}
              </select>
              <input
                type="text"
                className="form-control"
                id="contact"
                placeholder="Contact Number"
                {...register("contact", {
                  required: "Contact Number is required",
                  pattern: {
                    value: /^\d{10}$/, // Regular expression to allow only numbers
                    message: "Phone number must be 10 digits",
                  },
                })}
              />
            </div>
            {errors.contact && (
              <span className="form-text text-danger">
                {errors.contact.message}
              </span>
            )}
          </div>

          <div className="col-12 p-0">
            <label htmlFor="office" className="form-label">
              Office Number
            </label>
            <div className="numfield-wrapper">
              <select
                className="form-select nopre-drop position-relative z-3"
                id="office_phone_country_code"
                {...register("office_phone_country_code", { required: true })}
              >
                <option key="+1" value="+1" selected>
                  US (+1)
                </option>
                {CountryCode?.map((data) => (
                  <option key={data?.dial_code} value={data?.dial_code}>
                    {data?.code} ({data?.dial_code})
                  </option>
                ))}
              </select>
              <input
                type="text"
                className="form-control"
                id="office_phone"
                placeholder="Office Number"
                {...register("office_phone", {
                  // required: "Contact Number is required",
                  validate: {
                    isValidPhone: value => {
                      // If the value is empty, return true (no validation needed)
                      if (!value) return true;
                      
                      // Regular expression to allow only numbers
                      const pattern = /^\d{10}$/;
                      return pattern.test(value) || "Phone number must be 10 digits";
                    },
                  },
                })}
              />
            </div>
            {errors.office_phone && (
              <span className="form-text text-danger">
                {errors.office_phone.message}
              </span>
            )}
          </div>

          <div className="col-12 p-0">
            <label htmlFor="loc" className="form-label">
              Location (City/State/Zip Code)
              <span className="text-danger fs-2 ps-1">*</span>
            </label>
            <div className="d-flex align-items-center passfield-wrapper">
              <GooglePlaceAutocomplete
                selectedPlace={selectedPlace}
                setSelectedPlace={setSelectedPlace}
                defaultValues={AddressEditData?.location?.address}
              />
              {console.log(
                "AddressEditData?.location?.address",
                AddressEditData
              )}

              <span className="visi">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={15}
                  height={17}
                  viewBox="0 0 15 17"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.21484 7.71052C1.22638 4.31624 3.98733 1.57399 7.3816 1.58549C10.7759 1.59705 13.5181 4.35801 13.5066 7.75228V7.82188C13.4648 10.0283 12.2329 12.0676 10.7225 13.6615C9.85873 14.5585 8.89414 15.3526 7.84794 16.028C7.5682 16.27 7.15325 16.27 6.87351 16.028C5.31388 15.0128 3.94504 13.7312 2.82962 12.2416C1.83545 10.9427 1.27101 9.36615 1.21484 7.7314V7.71052Z"
                    stroke="#818898"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.35902 10.1658C8.89055 10.1658 10.1321 8.92424 10.1321 7.39271C10.1321 5.86118 8.89055 4.61963 7.35902 4.61963C5.82749 4.61963 4.58594 5.86118 4.58594 7.39271C4.58594 8.92424 5.82749 10.1658 7.35902 10.1658Z"
                    stroke="#818898"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div className="col-12 p-0">
            <label htmlFor="text" className="form-label">
              City
              <span className="text-danger fs-2 ps-1">*</span>
            </label>
            <div className="d-flex align-items-center passfield-wrapper">
              <input
                type="text"
                className="form-control"
                id="city"
                placeholder="City"
                {...register("city", {
                  required: "City is required",
                })}
              />
              <span className="visi">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={15}
                  viewBox="0 0 16 15"
                  fill="none"
                >
                  <path
                    d="M0.106201 11.9364V14.406C0.106201 14.6335 0.291159 14.8122 0.526561 14.8122H3.08235C3.19164 14.8122 3.30093 14.7716 3.3766 14.6903L12.5572 5.82752L9.40455 2.78119L0.232309 11.644C0.148237 11.7252 0.106201 11.8227 0.106201 11.9364ZM14.9953 3.47169C15.3232 3.15487 15.3232 2.64309 14.9953 2.32627L13.028 0.425358C12.7002 0.108539 12.1705 0.108539 11.8426 0.425358L10.3041 1.91197L13.4568 4.9583L14.9953 3.47169Z"
                    fill="#86889A"
                  />
                </svg>
              </span>
            </div>
            {errors.city && (
              <span className="form-text text-danger">
                {errors.city.message}
              </span>
            )}
          </div>

          <div className="col-12 p-0">
            <label htmlFor="text" className="form-label">
              State
              <span className="text-danger fs-2 ps-1">*</span>
            </label>
            <div className="d-flex align-items-center passfield-wrapper">
              <input
                type="text"
                className="form-control"
                id="state"
                placeholder="State"
                {...register("state", {
                  required: "State is required",
                })}
              />
              <span className="visi">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={15}
                  viewBox="0 0 16 15"
                  fill="none"
                >
                  <path
                    d="M0.106201 11.9364V14.406C0.106201 14.6335 0.291159 14.8122 0.526561 14.8122H3.08235C3.19164 14.8122 3.30093 14.7716 3.3766 14.6903L12.5572 5.82752L9.40455 2.78119L0.232309 11.644C0.148237 11.7252 0.106201 11.8227 0.106201 11.9364ZM14.9953 3.47169C15.3232 3.15487 15.3232 2.64309 14.9953 2.32627L13.028 0.425358C12.7002 0.108539 12.1705 0.108539 11.8426 0.425358L10.3041 1.91197L13.4568 4.9583L14.9953 3.47169Z"
                    fill="#86889A"
                  />
                </svg>
              </span>
            </div>
            {errors.state && (
              <span className="form-text text-danger">
                {errors.state.message}
              </span>
            )}
          </div>

          <div className="col-12 p-0">
            <label htmlFor="text" className="form-label">
              Country
              <span className="text-danger fs-2 ps-1">*</span>
            </label>
            <div className="d-flex align-items-center passfield-wrapper">
              <input
                type="text"
                className="form-control"
                id="country"
                placeholder="Country"
                {...register("country", {
                  required: "Country is required",
                })}
              />
              <span className="visi">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={15}
                  viewBox="0 0 16 15"
                  fill="none"
                >
                  <path
                    d="M0.106201 11.9364V14.406C0.106201 14.6335 0.291159 14.8122 0.526561 14.8122H3.08235C3.19164 14.8122 3.30093 14.7716 3.3766 14.6903L12.5572 5.82752L9.40455 2.78119L0.232309 11.644C0.148237 11.7252 0.106201 11.8227 0.106201 11.9364ZM14.9953 3.47169C15.3232 3.15487 15.3232 2.64309 14.9953 2.32627L13.028 0.425358C12.7002 0.108539 12.1705 0.108539 11.8426 0.425358L10.3041 1.91197L13.4568 4.9583L14.9953 3.47169Z"
                    fill="#86889A"
                  />
                </svg>
              </span>
            </div>
            {errors.country && (
              <span className="form-text text-danger">
                {errors.country.message}
              </span>
            )}
          </div>

          <div className="col-12 p-0">
            <label htmlFor="text" className="form-label">
              Zipcode
              <span className="text-danger fs-2 ps-1">*</span>
            </label>
            <div className="d-flex align-items-center passfield-wrapper">
              <input
                type="text"
                className="form-control"
                id="city"
                placeholder="Zipcode"
                {...register("zipcode", {
                  required: "Zipcode is required",
                })}
              />
              <span className="visi">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={15}
                  viewBox="0 0 16 15"
                  fill="none"
                >
                  <path
                    d="M0.106201 11.9364V14.406C0.106201 14.6335 0.291159 14.8122 0.526561 14.8122H3.08235C3.19164 14.8122 3.30093 14.7716 3.3766 14.6903L12.5572 5.82752L9.40455 2.78119L0.232309 11.644C0.148237 11.7252 0.106201 11.8227 0.106201 11.9364ZM14.9953 3.47169C15.3232 3.15487 15.3232 2.64309 14.9953 2.32627L13.028 0.425358C12.7002 0.108539 12.1705 0.108539 11.8426 0.425358L10.3041 1.91197L13.4568 4.9583L14.9953 3.47169Z"
                    fill="#86889A"
                  />
                </svg>
              </span>
            </div>
            {errors.zipcode && (
              <span className="form-text text-danger">
                {errors.zipcode.message}
              </span>
            )}
          </div>

          <div className="col-12 p-0">
            <label htmlFor="fax" className="form-label">
              Fax Number
            </label>
            <div className="d-flex align-items-center passfield-wrapper">
              <input
                type="text"
                className="form-control"
                id="fax"
                placeholder="Fax number"
                {...register("fax", {
                  // required: "Fax number is required",
                  pattern: {
                    value: /^[0-9]+$/, // Regular expression to allow only numbers
                    message: "Only numbers are allowed",
                  },
                })}
              />
              <span className="visi">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={15}
                  viewBox="0 0 16 15"
                  fill="none"
                >
                  <path
                    d="M0.106201 11.9364V14.406C0.106201 14.6335 0.291159 14.8122 0.526561 14.8122H3.08235C3.19164 14.8122 3.30093 14.7716 3.3766 14.6903L12.5572 5.82752L9.40455 2.78119L0.232309 11.644C0.148237 11.7252 0.106201 11.8227 0.106201 11.9364ZM14.9953 3.47169C15.3232 3.15487 15.3232 2.64309 14.9953 2.32627L13.028 0.425358C12.7002 0.108539 12.1705 0.108539 11.8426 0.425358L10.3041 1.91197L13.4568 4.9583L14.9953 3.47169Z"
                    fill="#86889A"
                  />
                </svg>
              </span>
            </div>
            {errors.fax && (
              <span className="form-text text-danger">
                {errors.fax.message}
              </span>
            )}
          </div>

          <div className="col-12 p-0">
            <label htmlFor="email" className="form-label">
              Recovery Email Address
            </label>
            <div className="d-flex align-items-center passfield-wrapper">
              <input
                type="email"
                className="form-control"
                id="recovery_email"
                placeholder="Recovery Email address"
                {...register("recovery_email", {
                  validate: {
                    isValidEmail: value => {
                      // If the value is empty, return true (no validation needed)
                      if (!value) return true;
                      
                      // Regular expression for email validation
                      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      if (!emailPattern.test(value)) {
                        return "Please enter a valid email address";
                      }
                      
                      // Compare recovery email with the main email
                      const email = getValues("email"); 
                      if (value === email) {
                        return "Recovery email cannot be the same as the main email address";
                      }
                      
                      return true; // Valid email and not the same as the main email
                    },
                  },
                })}
              />
              <span className="visi">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={15}
                  viewBox="0 0 16 15"
                  fill="none"
                >
                  <path
                    d="M0.106201 11.9364V14.406C0.106201 14.6335 0.291159 14.8122 0.526561 14.8122H3.08235C3.19164 14.8122 3.30093 14.7716 3.3766 14.6903L12.5572 5.82752L9.40455 2.78119L0.232309 11.644C0.148237 11.7252 0.106201 11.8227 0.106201 11.9364ZM14.9953 3.47169C15.3232 3.15487 15.3232 2.64309 14.9953 2.32627L13.028 0.425358C12.7002 0.108539 12.1705 0.108539 11.8426 0.425358L10.3041 1.91197L13.4568 4.9583L14.9953 3.47169Z"
                    fill="#86889A"
                  />
                </svg>
              </span>
            </div>
            {errors.recovery_email && (
              <span className="form-text text-danger">
                {errors.recovery_email.message}
              </span>
            )}
          </div>

          <div className="col-12 p-0">
            <label htmlFor="address type" className="form-label">
              Address Type
              <span className="text-danger fs-2 ps-1">*</span>
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register("AddType", { required: true })}
              onChange={(e) => {
                setAddressType(e.target.value);
              }}
            >
              <option value="">Select Address Type</option>
              {getAvailableAddressTypes().map((type) => {
                console.log("tttt", type);
                return (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                );
              })}
            </select>
          </div>

          {addressType === "other" && (
            <div className="col-12 p-0">
              <label htmlFor="custom_address_type" className="form-label">
                Specify Address Type
                <span className="text-danger fs-2 ps-1">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                {...register("CustomAddType", { required: true })}
                value={customAddressType}
                onChange={(e) => setCustomAddressType(e.target.value)}
                placeholder="Enter custom address type"
                defaultValue={customAddressType}
              />
              {console.log("customAddressType", customAddressType)}
              <div className="form-text mt-2">
                {errors.CustomAddType && (
                  <span className="text-danger ">
                    Custom address type is required
                  </span>
                )}
              </div>
            </div>
          )}

          <div className="col-12 d-flex gap-4 p-0 mt-2 mt-md-3 mt-xl-5">
            <button type="submit" disabled={isLoading} className="btn btn-primary w-100 ">
              {isLoading ? <Spinner /> : isEdit ? "Update" : "Add"}
            </button>
            <button
              type="button"
              onClick={() => {
                handleCancel();
                close(false);
              }}
              className="btn btn-primary w-100"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddNewaddress;
