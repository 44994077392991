import { motion, useAnimation } from "framer-motion";

const WhoAreWeSection = () => {
  const controls = useAnimation();

  const animationProps = {
    h4: {
      initial: { y: 100, opacity: 0 },
      animate: {
        y: 0,
        opacity: 1,
        transition: { duration: 1, ease: "circOut" },
      },
    },
    bgImg: {
      initial: { opacity: 0 },
      animate: { opacity: 1, transition: { duration: 0.5 } },
    },
    wrvCard: {
      initial: { y: 100, opacity: 0 },
      animate: {
        y: 0,
        opacity: 1,
        transition: { duration: 1, ease: "circOut", staggerChildren: 0.5 },
      },
    },
  };

  return (
    <>
      <section className="wrv">
        <div className="max-width-container position-relative">
          <div className="bg-grd-crc1"></div>
          <div className="bg-grd-crc2"></div>
          <div className="bg-grd-crc3"></div>
          <motion.h4
            className="h3"
            initial={animationProps.h4.initial}
            animate={animationProps.h4.animate}
          >
            Welcome to Jubal Talent, the world's largest music community with
            musicians around the world
          </motion.h4>
        </div>
        <motion.div
          className="max-width-container wrv-bg-img"
          initial={animationProps.bgImg.initial}
          animate={animationProps.bgImg.animate}
        >
          <div className="row align-items-center h-100">
            <div className="col-12 col-lg-6 col-xl-5">
              <div className="wrv-card">
                <div className="row">
                  <div className="col-12 p-0">
                    <h1 className="h3">
                      Who are
                      <span className="position-relative lined-txt">we? </span>
                    </h1>
                    <p className="para">
                      LinkedIn began in co-founder Rod & jimmy in 2024 and was
                      officially launched on March 7, 2004.
                    </p>
                  </div>
                  <div className="col-12 p-0">
                    <h1 className="h3">
                      what's our
                      <span className="position-relative lined-txt">
                        vision?
                        <span className="position-absolute">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="18"
                            viewBox="0 0 171 18"
                            fill="none"
                          >
                            <path
                              d="M1.97119 15.634C14.4613 13.9352 26.8911 11.5526 39.3516 9.55822C63.6966 5.6616 88.082 3.33426 112.622 2.55322C128.327 2.05335 144.357 1.18854 160.064 2.48174C162.988 2.7225 165.781 3.2919 168.635 4.05429"
                              stroke="#F4336C"
                              strokeWidth="3"
                              strokeLinecap="round"
                            />
                          </svg>
                        </span>
                      </span>
                    </h1>
                    <p className="para pe-lg-4">
                      We're crafting Jubal Talents, not just as a gig-finding
                      platform, but as a comprehensive hub for musicians.
                      Picture this – a space that offers consistent income, job
                      opportunities spanning the globe, and a collaborative
                      community where musicians can truly thrive.
                    </p>
                    <div className="row gap-4">
                      <div className="col-12 col-md-7 p-0">
                        <a
                          href="sign_in.html"
                          className="btn btn-primary w-100 revo-btn"
                        >
                          Join the Revolution
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </section>
    </>
  );
};
export default WhoAreWeSection;
