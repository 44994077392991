import React, { useState } from "react";
import { getAddress, getLoginUserCaption } from "../../Helper/Helper";
import { Link } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import { useSelector } from "react-redux";
import FollowersModal from "../../Components/Modals/FollowersModal";

function JubalArtistCard({ item, index, userDetail, filterState }) {
  const [followersModalVisible, setfollowersModalVisible] = useState(false);
  const handleFollwersClick = (data) => {
    setfollowersModalVisible(true);
  };
  const closeModals = () => {
    setfollowersModalVisible(false);
  };
  return (
    <>
      <div className="card account-card " key={index}>
        <div className="card-body">
          <div className="row m-0">
            <div className="col-12 p-0">
              <img
                src={
                  item?.profilePhoto ||
                  `${process.env.PUBLIC_URL}/images/defult.jpeg`
                }
                alt=""
                className="artist-img"
              />
            </div>
            <div className="col-12 p-0">
              <div className="d-flex justify-content-between align-items-start">
                <div className="user-det d-flex gap-2 gap-lg-3">
                  <Link
                    to={
                      item?.User?.id === userDetail?.Profile?.userId
                        ? RoutesLink?.MyProfile
                        : `${RoutesLink?.profile}/${item?.User?.id}` // Include user ID in the URL path
                    }
                    state={{ id: item?.User?.id }} // Optionally pass the ID in state as well
                  >
                    {item?.profilePhoto ? (
                      <div className="user-dp">
                        <img src={item?.profilePhoto} alt="" />
                      </div>
                    ) : (
                      <span className="user-dp no-img">
                        {item?.firstName?.[0]}
                      </span>
                    )}
                  </Link>

                  <div className="user-ac">
                    <div className="d-flex gap-2 align-items-center">
                      <span className="username">
                        <Link
                          to={
                            item?.User?.id === userDetail?.Profile?.userId
                              ? RoutesLink?.MyProfile
                              : `${RoutesLink?.profile}/${item?.User?.id}` // Include user ID in the URL path
                          }
                          state={{ id: item?.User?.id }}
                        >
                          {item?.firstName} {item?.lastName}
                        </Link>
                      </span>
                      <span className="usertype mx-1">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={13}
                            height={13}
                            viewBox="0 0 13 13"
                            fill="none"
                          >
                            <circle
                              cx="5.74979"
                              cy="6.72714"
                              r="3.75247"
                              fill="white"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                              fill="#F4336D"
                            />
                          </svg>
                        </span>
                        Talent
                      </span>
                    </div>
                    {/* <p>{getLoginUserCaption(item?.Talent)}</p> */}
                    <p>
                      {/* Genre */}
                      {(() => {
                        const genreArray = Array.isArray(
                          item?.User?.Talent?.genre
                        )
                          ? item.User.Talent.genre
                          : JSON.parse(item?.User?.Talent?.genre || "[]");

                        // If filterState.genre exists, display it, otherwise fallback to the first value
                        const genreToDisplay =
                          filterState.genre &&
                          genreArray.includes(filterState.genre)
                            ? filterState.genre
                            : genreArray[0]; // Fallback to the first value in the array

                        return <span>{genreToDisplay}</span>;
                      })()}

                      {", "}

                      {/* Instrument */}
                      {(() => {
                        const instrumentArray = Array.isArray(
                          item?.User?.Talent?.instrument
                        )
                          ? item.User.Talent.instrument
                          : JSON.parse(item?.User?.Talent?.instrument || "[]");

                        // If filterState.hasInst exists, display it, otherwise fallback to the first value
                        const instrumentToDisplay =
                          filterState.hasInst &&
                          instrumentArray.includes(filterState.hasInst)
                            ? filterState.hasInst
                            : instrumentArray[0]; // Fallback to the first value in the array

                        return <span>{instrumentToDisplay}</span>;
                      })()}
                    </p>
                  </div>
                </div>
                {item?.rating > 0 && (
                  <span className="rating">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={11}
                      height={10}
                      viewBox="0 0 11 10"
                      fill="none"
                    >
                      <path
                        d="M8.43405 9.29386C8.09885 9.53261 5.94806 8.01921 5.53614 8.01589C5.12421 8.01259 2.94921 9.49117 2.61792 9.24706C2.28663 9.00296 3.06548 6.49567 2.94136 6.10404C2.81724 5.7124 0.734809 4.1067 0.865254 3.7171C0.995727 3.3275 3.62786 3.29131 3.96306 3.05257C4.29826 2.81385 5.18627 0.342897 5.59822 0.346195C6.01012 0.349521 6.85801 2.83444 7.1893 3.07855C7.52059 3.32262 10.1518 3.40119 10.276 3.79283C10.4001 4.18447 8.29198 5.75644 8.16151 6.14604C8.03106 6.53565 8.76925 9.05512 8.43405 9.29386Z"
                        fill="url(#paint0_linear_1709_1003)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1709_1003"
                          x1="0.859387"
                          y1="4.8328"
                          x2="10.2812"
                          y2="4.8328"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FCD635" />
                          <stop offset={1} stopColor="#F7A928" />
                        </linearGradient>
                      </defs>
                    </svg>
                    {item?.rating > 0 && item?.rating?.toFixed(1)}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 p-0">
              <div className="d-flex align-item-center justify-content-between gap-3">
                <span className="location">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={19}
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      d="M9.21289 11.5625C10.4156 11.5625 11.3906 10.5875 11.3906 9.38477C11.3906 8.18204 10.4156 7.20703 9.21289 7.20703C8.01016 7.20703 7.03516 8.18204 7.03516 9.38477C7.03516 10.5875 8.01016 11.5625 9.21289 11.5625Z"
                      fill="#86889A"
                    />
                    <path
                      d="M16.4714 8.66016H14.9694C14.8063 7.38277 14.2238 6.19566 13.3132 5.28508C12.4026 4.3745 11.2155 3.79196 9.93815 3.62886V2.12695H8.48633V3.62886C7.20894 3.79196 6.02184 4.3745 5.11125 5.28508C4.20067 6.19566 3.61814 7.38277 3.45504 8.66016H1.95312V10.112H3.45504C3.61814 11.3894 4.20067 12.5765 5.11125 13.4871C6.02184 14.3976 7.20894 14.9802 8.48633 15.1433V16.6452H9.93815V15.1433C11.2155 14.9802 12.4026 14.3976 13.3132 13.4871C14.2238 12.5765 14.8063 11.3894 14.9694 10.112H16.4714V8.66016ZM9.21224 13.7415C6.8102 13.7415 4.85677 11.7881 4.85677 9.38607C4.85677 6.98403 6.8102 5.0306 9.21224 5.0306C11.6143 5.0306 13.5677 6.98403 13.5677 9.38607C13.5677 11.7881 11.6143 13.7415 9.21224 13.7415Z"
                      fill="#86889A"
                    />
                  </svg>
                  <p>{getAddress(item?.location) || "Location"}</p>
                </span>
                {item?.followerCount > 0 && (
                  <span
                    className="followers"
                    onClick={() => handleFollwersClick()}
                  >
                    {item?.followerCount} Followers
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* </Link> */}
        </div>
      </div>
      {followersModalVisible && (
        <FollowersModal
          isOpen={followersModalVisible}
          onRequestClose={closeModals}
          postData={item?.User?.id}
          id={item?.User?.id}
        />
      )}
    </>
  );
}

export default JubalArtistCard;
