import React, { useEffect, useState } from "react";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { Link } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import { toast } from "material-react-toastify";

function TaggedAcitivity() {
  const [AllTaggedPost, setAllTaggedPost] = useState([]);
  // const [postUrl, setPostUrl] = useState("");
  const [callApi, setCallApi] = useState(true);

  useEffect(() => {
    if (callApi) {
      handleFetchRequest(ApiUrl.taged_posts).then((response) => {
        if (response.status === true) {
          setAllTaggedPost(response?.data?.posts);
          setCallApi(false);
        }
      });
    }
  }, [callApi]);
  const removeTag = (id) => {
    handleFetchRequest(ApiUrl?.remove_tag + id).then((res) => {
      if (res?.status === true) {
        toast.success(res?.message);
        setCallApi(true);
      } else {
        toast?.error(res?.message);
      }
    });
  };

  return (
    <div
      className="tab-pane fade"
      id="tagged-tab-pane"
      role="tabpanel"
      aria-labelledby="tagged-tab"
      tabIndex={0}
    >
      <div className="filter-row">
        <p>Tagged Post</p>
      </div>
      <div className="row shared-row">
        {AllTaggedPost && AllTaggedPost.length === 0 ? (
          <div className="col-12 p-0">
            <p>No data found</p>
          </div>
        ) : (
          AllTaggedPost &&
          AllTaggedPost.map((res, index) => {
            return (
              <div className="col-12 p-0" key={index}>
                <div className="comment-area">
                  <div className="user-det d-flex gap-3">
                    {res?.User?.profilePhoto ? (
                      <div className="user-dp">
                        <img src={res?.User?.profilePhoto} alt="" />
                      </div>
                    ) : (
                      <div className="user-dp no-img">
                        {res?.User?.firstName?.[0]}
                      </div>
                    )}

                    <div className="d-flex flex-column flex-grow-1 gap-2 gap-md-3">
                      <div className="d-flex justify-content-between w-100">
                        <div className="user-ac">
                          <div className="d-flex gap-1 gap-md-2 align-items-baseline flex-wrap">
                            <span className="username">
                              <Link
                                to={RoutesLink?.profile}
                                state={res?.User?.id}
                              >
                                {res?.User?.firstName} {res?.User?.lastName}
                              </Link>
                            </span>
                            {/* <span className="post-time"> </span> */}
                            <span className="usertype mx-1">
                              {res?.User?.type === 3 && (
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={11}
                                    height={11}
                                    viewBox="0 0 11 11"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M10.3071 5.17529L9.62945 4.33449C9.52278 4.19645 9.45376 4.03331 9.43493 3.85135L9.31572 2.78466C9.29153 2.57167 9.19584 2.37317 9.04426 2.22159C8.89268 2.07002 8.69419 1.97432 8.48119 1.95014L7.41451 1.83092C7.22627 1.8121 7.06313 1.73053 6.92509 1.62386L6.08429 0.946198C5.73918 0.670115 5.24976 0.670115 4.90466 0.946198L4.06386 1.62386C3.92582 1.73053 3.76268 1.79955 3.58072 1.81837L2.51403 1.93759C2.07481 1.98778 1.7297 2.33289 1.67951 2.77211L1.56029 3.8388C1.54147 4.02704 1.4599 4.19018 1.35323 4.32822L0.675568 5.16902C0.399485 5.51412 0.399485 6.00354 0.675568 6.34864L1.35323 7.18944C1.4599 7.32749 1.52892 7.49062 1.54774 7.67259L1.66696 8.73927C1.71715 9.1785 2.06226 9.5236 2.50148 9.5738L3.56817 9.69302C3.75641 9.71184 3.91955 9.79341 4.05759 9.90008L4.89839 10.5777C5.24349 10.8538 5.73291 10.8538 6.07801 10.5777L6.91881 9.90008C7.05686 9.79341 7.22 9.72439 7.40196 9.70556L8.46864 9.58635C8.90787 9.53615 9.25297 9.19105 9.30317 8.75182L9.42239 7.68514C9.44121 7.4969 9.52278 7.33376 9.62945 7.19572L10.3071 6.35492C10.5832 6.00981 10.5832 5.52039 10.3071 5.17529ZM4.55328 8.27495L2.35717 6.07884L3.29836 5.13764L4.55328 6.39257L7.69059 3.25526L8.63178 4.22782L4.55328 8.27495Z"
                                      fill="#F4336D"
                                    />
                                  </svg>
                                  Talent
                                </span>
                              )}
                            </span>
                            <p>mentioned</p>
                            <span className="username">
                              <Link to="#">You</Link>
                            </span>
                            <span className="usertype mx-1">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={11}
                                  height={11}
                                  viewBox="0 0 11 11"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M10.3071 5.17529L9.62945 4.33449C9.52278 4.19645 9.45376 4.03331 9.43493 3.85135L9.31572 2.78466C9.29153 2.57167 9.19584 2.37317 9.04426 2.22159C8.89268 2.07002 8.69419 1.97432 8.48119 1.95014L7.41451 1.83092C7.22627 1.8121 7.06313 1.73053 6.92509 1.62386L6.08429 0.946198C5.73918 0.670115 5.24976 0.670115 4.90466 0.946198L4.06386 1.62386C3.92582 1.73053 3.76268 1.79955 3.58072 1.81837L2.51403 1.93759C2.07481 1.98778 1.7297 2.33289 1.67951 2.77211L1.56029 3.8388C1.54147 4.02704 1.4599 4.19018 1.35323 4.32822L0.675568 5.16902C0.399485 5.51412 0.399485 6.00354 0.675568 6.34864L1.35323 7.18944C1.4599 7.32749 1.52892 7.49062 1.54774 7.67259L1.66696 8.73927C1.71715 9.1785 2.06226 9.5236 2.50148 9.5738L3.56817 9.69302C3.75641 9.71184 3.91955 9.79341 4.05759 9.90008L4.89839 10.5777C5.24349 10.8538 5.73291 10.8538 6.07801 10.5777L6.91881 9.90008C7.05686 9.79341 7.22 9.72439 7.40196 9.70556L8.46864 9.58635C8.90787 9.53615 9.25297 9.19105 9.30317 8.75182L9.42239 7.68514C9.44121 7.4969 9.52278 7.33376 9.62945 7.19572L10.3071 6.35492C10.5832 6.00981 10.5832 5.52039 10.3071 5.17529ZM4.55328 8.27495L2.35717 6.07884L3.29836 5.13764L4.55328 6.39257L7.69059 3.25526L8.63178 4.22782L4.55328 8.27495Z"
                                    fill="#F4336D"
                                  />
                                </svg>
                              </span>
                              Talent
                            </span>
                          </div>
                        </div>
                      </div>
                      <p>{res?.caption}</p>
                    </div>
                    {res?.mediaType === "photo" ? (
                      <div className="post-tumbnail">
                        <img src={res?.postImageUrl} alt="" />
                      </div>
                    ) : (
                      <div className="post-tumbnail">
                        <video autoPlay muted src={res?.postImageUrl} alt="" />
                      </div>
                    )}

                    <div className="dropdown dropdown-menu-end">
                      <Link
                        to="#"
                        className="action-btn"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={4}
                          height={13}
                          viewBox="0 0 4 13"
                          fill="none"
                        >
                          <path
                            d="M1.25 1.49023C1.25 1.90445 1.58579 2.24023 2 2.24023C2.41421 2.24023 2.75 1.90445 2.75 1.49023C2.75 1.07602 2.41421 0.740234 2 0.740234C1.58579 0.740234 1.25 1.07602 1.25 1.49023Z"
                            stroke="#86889A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1.25 6.39063C1.25 6.80484 1.58579 7.14063 2 7.14063C2.41421 7.14063 2.75 6.80484 2.75 6.39063C2.75 5.97641 2.41421 5.64062 2 5.64062C1.58579 5.64062 1.25 5.97641 1.25 6.39063Z"
                            stroke="#86889A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1.25 11.291C1.25 11.7052 1.58579 12.041 2 12.041C2.41421 12.041 2.75 11.7052 2.75 11.291C2.75 10.8768 2.41421 10.541 2 10.541C1.58579 10.541 1.25 10.8768 1.25 11.291Z"
                            stroke="#86889A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            to={RoutesLink?.profile}
                            state={res?.User?.id}
                          >
                            View Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={() => removeTag(res?.id)}
                          >
                            Remove Tag
                          </Link>
                        </li>
                        {/* <li>
                          <a className="dropdown-item">
                            Unfollow {res?.User?.firstName}
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default TaggedAcitivity;
