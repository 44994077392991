import {
  CHECK_USER_LOGGED_IN,
  USER_LOGGED_IN_DETAILS,
  FETCH_USER_REQUEST,
  USER_TYPE,
  CART_DATA,
  CONVERSION_RATE,
  NOTIFICATION_DETAIL,
  PROFILE_IMAGE,
  SAVE_INSTRUMENTS_LIKES,
  SAVE_EVENTS_LIKES,
  SAVE_TALENTS,
} from "../types";

export const profileImage = (payload) => {
  return {
    type: PROFILE_IMAGE,
    payload,
  };
};

export const checkUserLoggedIn = (payload) => {
  return {
    type: CHECK_USER_LOGGED_IN,
    payload,
  };
};

export const noticationDetail = (payload) => {
  return {
    type: NOTIFICATION_DETAIL,
    payload,
  };
};

export const conversionRate = (payload) => {
  return {
    type: CONVERSION_RATE,
    payload,
  };
};

export const cartData = (payload) => {
  return {
    type: CART_DATA,
    payload,
  };
};

export const setUsertype = (payload) => {
  return {
    type: USER_TYPE,
    payload,
  };
};

export const setUserLoggedInDetails = (payload) => {
  return {
    type: USER_LOGGED_IN_DETAILS,
    payload,
  };
};

export const fetchUserRequest = () => {
  return {
    type: FETCH_USER_REQUEST,
  };
};

export const SaveInstrument_Likes = (payload) => {
  return {
    type: SAVE_INSTRUMENTS_LIKES,
    payload,
  };
};
export const SaveEvent_Likes = (payload) => {
  return {
    type: SAVE_EVENTS_LIKES,
    payload,
  };
};

export const Save_talents = (payload) => {
  return {
    type: SAVE_TALENTS,
    payload,
  };
};
