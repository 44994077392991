import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  addDoc,
  serverTimestamp,
  getdoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { firebaseDB } from "../../firebase.js";
import moment from "moment";
import { SvgIcons } from "../../Components/Svg/SvgIcons.js";
import EmojiPicker from "emoji-picker-react";
import { Link } from "react-router-dom";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper.js";
import { ApiUrl } from "../../utility/ApiUrl.js";

// Function to get or create a chat room by checking existing chat room IDs between sender and receiver
const getChatRoomById = async (senderId, reciverId) => {
  let finalChatRoom = "";
  // Define possible chat room names in both sender-receiver and receiver-sender orders
  const chatRooms = [
    `chat-${senderId}-${reciverId}`,
    `chat-${reciverId}-${senderId}`,
  ];
  // Iterate through the chat room names to find an existing one
  for (let chatRoom of chatRooms) {
    const messagesRef = collection(
      firebaseDB,
      "Jubal-chat",
      "chat-doc",
      chatRoom
    );
    const q = query(messagesRef);

    const docs = await getDocs(messagesRef);
    if (!docs.empty) {
      finalChatRoom = chatRoom;
      break; // Exit loop if an existing chat room is found
    }
  }
  // If no existing chat room is found, use the first generated name
  if (!finalChatRoom) {
    finalChatRoom = chatRooms[0];
  }
  return finalChatRoom;
};

const ChatMessage = ({
  chatclickData,
  handleChatIconClick,
  isChatboxVisible,
}) => {
  // State to store messages for the chat
  const [messages, setMessages] = useState([]);
  // State for the message input field
  const [messageInput, setMessageInput] = useState("");
  // Get user details from the Redux store
  const userDetails = useSelector((store) => store?.user?.userDetails);
  // State to control visibility of the emoji picker
  const [showPicker, setShowPicker] = useState(false);
  // State to prevent multiple sending actions
  const [isSending, setIsSending] = useState(false);
  // State to group messages by date
  const [groupedMessages, setGroupedMessages] = useState({});

  // Function to handle selecting an emoji and appending it to the message input
  const onEmojiClick = (emojiData) => {
    setMessageInput((prevInput) => prevInput + emojiData.emoji);
  };

  // Group messages by date for display
  useEffect(() => {
    const groupByDate = messages.reduce((groups, message) => {
      const date = new Date(message?.createdAt?.seconds * 1000); // Convert timestamp to milliseconds
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });

      if (!groups[formattedDate]) {
        groups[formattedDate] = [];
      }

      groups[formattedDate].push(message);
      return groups;
    }, {});

    setGroupedMessages(groupByDate);
  }, [messages]);

  // Function to sort chat messages by their ID in ascending order
  const sortChats = (arr) => {
    return arr.sort((a, b) => a?.id - b?.id);
  };

  // Fetch the chat room data when a chat is clicked
  const getRoomData = async () => {
    if (!chatclickData) {
      return; // Exit if no chat data is provided
    }
    const chatData = {
      sender_id: userDetails?.Profile?.userId,
      reciever_id: chatclickData?.id,
    };

    // Get or create the chat room
    const room = await getChatRoomById(
      chatData.sender_id,
      chatData.reciever_id
    );

    const messagesRef = collection(firebaseDB, "Jubal-chat", "chat-doc", room);
    const q = query(messagesRef);

    // Listen for real-time updates to the chat room
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedMessages = [];
      querySnapshot.forEach((doc) => {
        fetchedMessages.push({ id: doc.id, ...doc.data() });
      });
      // Sort the fetched messages and update state
      const shaortedList = sortChats(fetchedMessages);
      setMessages(shaortedList);
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  };

  // Fetch chat room data whenever the clicked chat changes
  useEffect(() => {
    getRoomData();
  }, [chatclickData]);

  // Function to handle sending a message
  const sendMessage = async (event) => {
    event.preventDefault();

    // Prevent sending empty messages
    if (messageInput.trim() === "") {
      return;
    }

    // Prevent sending multiple messages at the same time
    if (isSending) {
      return;
    }

    setIsSending(true); // Set sending state to true

    const chatData = {
      sender_id: userDetails?.Profile?.userId,
      receiver_id: chatclickData?.id,
    };

    try {
      // Get or create the chat room
      const room = await getChatRoomById(
        chatData.sender_id,
        chatData.receiver_id
      );
      const messagesRef = collection(
        firebaseDB,
        "Jubal-chat",
        "chat-doc",
        room
      );

      // Create a new message object
      const obj = {
        sender_id: chatData.sender_id,
        receiver_id: chatData.receiver_id,
        msg: messageInput,
        date: moment().format("DD-MM-YYYY"),
        time: moment().format("hh:mm A"),
        isSeen: false,
        createdAt: serverTimestamp(),
        id: messages.length + 1, // Consider using Firebase-generated ID
      };

      // Add the new message to Firestore
      await addDoc(messagesRef, obj);

      setMessageInput(""); // Clear the input field
      setShowPicker(false); // Hide the emoji picker

      // Notify the receiver about the new message
      const data = {
        userId: chatData.receiver_id,
        message: messageInput,
        body: {
          receiver_id: chatData.receiver_id,
          sender_id: chatData.sender_id,
          sender: {
            id: chatData.sender_id,
            fistName: userDetails?.Profile?.firstName,
            lastName: userDetails?.Profile?.lastName,
          },
        },
      };
      handleFetchRequest(ApiUrl?.notifyArtist, "POST", data).then(
        (response) => {
          if (response.status == true) {
            // Handle successful notification (if needed)
          }
        }
      );
    } catch (error) {
      console.error("Error sending message: ", error);
    } finally {
      setIsSending(false); // Reset sending state
    }
  };

  return (
    <>
      <div className={`chatbox ${isChatboxVisible ? "showbox" : ""}`}>
        {chatclickData == null ? (
          <div className="d-none chat-default d-lg-flex h-100 align-items-center justify-content-center flex-column">
            <span>{SvgIcons.chatIcon}</span>
            <h4 className="">Let’s talk here!</h4>
            <p>
              We're excited to have you here! share your thoughts with your
              friends!
            </p>
          </div>
        ) : (
          <div className="modal-dialog-scrollable">
            <div className="modal-content">
              <div className="msg-head">
                <div className="d-flex justify-content-between">
                  <div className="align-items-center d-flex gap-2">
                    <span className="chat-icon" onClick={handleChatIconClick}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width={18}
                        height={18}
                        x={0}
                        y={0}
                        viewBox="0 0 128 128"
                        style={{
                          enableBackground: "new 0 0 512 512",
                        }}
                        xmlSpace="preserve"
                      >
                        <g>
                          <path
                            d="M84 108a3.988 3.988 0 0 1-2.828-1.172l-40-40a3.997 3.997 0 0 1 0-5.656l40-40c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656L49.656 64l37.172 37.172a3.997 3.997 0 0 1 0 5.656A3.988 3.988 0 0 1 84 108z"
                            fill="#000000"
                            opacity={1}
                            data-original="#000000"
                          />
                        </g>
                      </svg>
                    </span>

                    <div className="user-det d-flex gap-3 gap-lg-4 align-items-center">
                      {chatclickData?.profile_photo ? (
                        <div className="user-dp">
                          <img src={chatclickData?.profile_photo} alt="" />
                        </div>
                      ) : (
                        <div className="user-dp no-img">
                          {chatclickData?.first_name?.[0]}
                        </div>
                      )}

                      <div className="user-ac">
                        <div className="d-flex gap-2 align-items-center">
                          <span className="username">
                            <Link
                              to="/profile"
                              state={{ id: chatclickData?.id }}
                            >
                              {chatclickData?.first_name}{" "}
                              {chatclickData?.last_name}
                            </Link>
                          </span>
                          {/* <span className="usertype vendor mx-1">
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={13}
                                                  height={13}
                                                  viewBox="0 0 13 13"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="5.74979"
                                                    cy="6.72714"
                                                    r="3.75247"
                                                    fill="white"
                                                  />
                                                  <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                                    fill="currentColor"
                                                  ></path>
                                                </svg>
                                              </span>
                                              vendor
                                            </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" text-end">
                    <div className="dropdown dropdown-menu-end">
                      <Link
                        className="dropdown-toggle"
                        to="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M10 16C10.4142 16 10.75 15.6642 10.75 15.25C10.75 14.8358 10.4142 14.5 10 14.5C9.58579 14.5 9.25 14.8358 9.25 15.25C9.25 15.6642 9.58579 16 10 16Z"
                            stroke="#86889A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 10.75C10.4142 10.75 10.75 10.4142 10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10C9.25 10.4142 9.58579 10.75 10 10.75Z"
                            stroke="#86889A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 5.5C10.4142 5.5 10.75 5.16421 10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75C9.25 5.16421 9.58579 5.5 10 5.5Z"
                            stroke="#86889A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Link>
                      <ul
                        className="dropdown-menu post-dropdown"
                        style={{
                          position: "absolute",
                          inset: "0px 0px auto auto",
                          margin: 0,
                          transform: "translate(0px, 25px)",
                        }}
                        data-popper-placement="bottom-end"
                      >
                        <li>
                          <Link className="dropdown-item" to="#">
                            Select message
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            Report this message
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ height: "100%", overflow: "auto" }}>
                {/* {messages.map((chat) => (
                  <div key={chat.id} className="msg-body p-0">
                    <ul>
                      <li
                        className={
                          userDetails?.Profile?.userId == chat?.sender_id
                            ? "repaly"
                            : "sender"
                        }
                      >
                        <p>{chat?.msg}</p>
                        <span className="time">
                          {chat?.date} - {chat?.time}
                        </span>
                      </li>
                    </ul>
                  </div>
                ))} */}

                {console.log("groupedMessages", groupedMessages)}

                {Object.keys(groupedMessages).map((date) => (
                  <div key={date} className="msg-body">
                    <ul className="message">
                      <li>
                        <div className="divider">
                          <h6>{date}</h6>
                        </div>
                      </li>
                      {groupedMessages[date].map((msg) => (
                        <li
                          className={
                            userDetails?.Profile?.userId == msg?.sender_id
                              ? "repaly"
                              : "sender"
                          }
                          key={msg.id}
                        >
                          <p>{msg?.msg}</p>
                          <span className="time">{msg?.time}</span>
                        </li>

                        // <div key={msg.id} className="message">
                        //   <p>
                        //     <strong>{msg.time}:</strong> {msg.msg}
                        //   </p>
                        // </div>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              {/* Input field and button to send message */}

              <div className="send-box">
                <form onSubmit={sendMessage}>
                  <div className="input-group msg-field">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Type a message..."
                      value={messageInput}
                      onFocus={() => setShowPicker(false)}
                      onChange={(e) => setMessageInput(e.target.value)}
                    />
                    <button
                      onClick={() => setShowPicker(!showPicker)}
                      className="btn link"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={21}
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M18.75 8.8457H11.25V1.3457C11.25 1.01418 11.1183 0.69624 10.8839 0.46182C10.6495 0.227399 10.3315 0.0957031 10 0.0957031C9.66848 0.0957031 9.35054 0.227399 9.11612 0.46182C8.8817 0.69624 8.75 1.01418 8.75 1.3457V8.8457H1.25C0.918479 8.8457 0.600537 8.9774 0.366117 9.21182C0.131696 9.44624 0 9.76418 0 10.0957C0 10.4272 0.131696 10.7452 0.366117 10.9796C0.600537 11.214 0.918479 11.3457 1.25 11.3457H8.75V18.8457C8.75 19.1772 8.8817 19.4952 9.11612 19.7296C9.35054 19.964 9.66848 20.0957 10 20.0957C10.3315 20.0957 10.6495 19.964 10.8839 19.7296C11.1183 19.4952 11.25 19.1772 11.25 18.8457V11.3457H18.75C19.0815 11.3457 19.3995 11.214 19.6339 10.9796C19.8683 10.7452 20 10.4272 20 10.0957C20 9.76418 19.8683 9.44624 19.6339 9.21182C19.3995 8.9774 19.0815 8.8457 18.75 8.8457Z"
                          fill="#858799"
                        />
                      </svg>
                    </button>
                    <button
                      type="submit"
                      className="btn link"
                      disabled={isSending} // Disable button while sending
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={29}
                        height={28}
                        viewBox="0 0 29 28"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M27.9062 8.68509C27.9513 8.9935 27.7759 9.29182 27.4844 9.40237L12.5771 15.0578C12.3044 15.1612 11.9961 15.0747 11.8171 14.8445L6.26916 7.71226C6.10805 7.50515 6.08396 7.22258 6.20764 6.99121C6.33135 6.75977 6.57966 6.62287 6.84137 6.64176L27.2966 8.11862C27.6076 8.14108 27.861 8.37665 27.9062 8.68509ZM8.2342 8.07445L12.5646 13.6414L24.2003 9.22721L8.2342 8.07445Z"
                          fill="#858799"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M27.8032 8.41679C27.974 8.67753 27.9405 9.02195 27.7228 9.24496L13.3942 23.9177C13.2108 24.1055 12.9342 24.1677 12.6881 24.0765C12.4421 23.9854 12.2727 23.758 12.2559 23.4961L11.6771 14.4787C11.6584 14.1877 11.8318 13.9185 12.1044 13.815L27.0118 8.15968C27.3033 8.04913 27.6324 8.15605 27.8032 8.41679ZM24.6703 10.4691L13.0345 14.8833L13.4862 21.9217L24.6703 10.4691Z"
                          fill="#858799"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
                {showPicker && <EmojiPicker onEmojiClick={onEmojiClick} />}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatMessage;
