import ReactOwlCarousel from "react-owl-carousel";
import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import DashboardLayout from "../../Components/Layout/DashboardLayout";
// import ArtistImg from "/images/popular-artist2.png";
import { Link, useLocation } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import { useEffect, useRef, useState } from "react";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import JubalArtistCard from "./JubalArtistCard";
import VendorLoader from "../../Helper/VendorLoader";
import { useSelector } from "react-redux";
import { SvgIcons } from "../../Components/Svg/SvgIcons";
import { toast } from "material-react-toastify";
import FilterDropdown from "../../Components/Modals/FilterDropdown";

const JubalArtist = () => {
  // State variables for managing different aspects of the component
  const [loading, setLoading] = useState(false); // Indicates whether data is being loaded
  const [filter, setFilter] = useState("Sort by"); // Default filter option
  const [talents, setTalents] = useState([]); // Array to store talent data
  const [searchInput, setSearchInput] = useState(""); // User input for search field
  const [sortType, setSortType] = useState(""); // Sorting type (e.g., ascending/descending)
  const [sortField, setSortField] = useState(""); // Field by which to sort the data
  const [SuggestedTalents, setSuggestedTalents] = useState([]); // Suggested talents data
  const [searchTerm, setSearchTerm] = useState(null); // Term used for search filtering
  const [activeTabs, setActiveTabs] = useState([]); // State for managing active tabs
  const [filterdata, setFilterdata] = useState({}); // Stores filter data
  const [showMore, setShowMore] = useState(6); // Number of items to show initially for talents
  const [newArtistShowMore, setNewArtistShowMore] = useState(6); // Number of items to show initially for new artists
  const [suggestedShowMore, setSuggestedShowMore] = useState(6); // Number of items to show initially for suggested talents
  const [filterState, setFilterState] = useState({
    // Filter criteria state
    genre: "",
    city: "",
    hasInst: "",
    search: "",
    sort: "",
    topRated: "",
    topFollower: "",
    nearMe: "",
  });
  const [nearbyTalents, setNearbyTalents] = useState([]); // Array to store nearby talents
  const [newNearbyTalents, setNewNearbyTalents] = useState([]); // Array to store newly fetched nearby talents

  // Reference for the dropdown element
  const dropdownRef = useRef(null);

  // Function to handle clicks outside of the dropdown
  const handleOutside = () => {
    if (dropdownRef.current) {
      dropdownRef.current.classList.remove("active"); // Remove the "active" class when clicking outside
    }
  };

  // Function to handle changes in the search input field
  const handleChange = (event) => {
    setSearchTerm(event.target.value); // Update the search term state based on user input
  };

  // Function to handle filtering based on the selected filter criteria
  const filterHandler = (value) => {
    let url;
    let search;

    // Check for different filter criteria and update URL and search parameters accordingly
    if (value?.genre) {
      url = `filter=genre`;
      search = `&search=${value?.genre}`;
    } else if (value?.city) {
      url = `filter=city`;
      search = `&search=${value?.city}`;
    } else if (value?.hasInst) {
      url = `filter=instrument-name`;
      search = `&search=${value?.hasInst}`;
    } else if (value?.topRated) {
      url = `filter=highestReview`;
    } else if (value?.topFollower) {
      url = `filter=topFollower`;
    }

    // Return the processed filter object
    return value;
  };

  // Function to fetch talents based on filter criteria
  const getTalents = async (filter) => {
    setLoading(true); // Set loading to true while data is being fetched

    // Check if "near me" filter is active
    if (filterState?.nearMe === true) {
      // Check if the browser supports geolocation
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            // Fetch nearby talents using the user's current location
            handleFetchRequest(
              `${ApiUrl.get_nearby}lat=${latitude}&lng=${longitude}&radius=300&search=`
            ).then((response) => {
              if (response.status === true) {
                console.log(
                  "Nearby talents fetched successfully:",
                  response?.status
                );
                setTalents(response?.data?.users); // Set the nearby talents data
                setLoading(false); // Set loading to false after data is fetched
              }
            });
          },
          (error) => {
            console.error("Error fetching location:", error);
            setLoading(false); // Set loading to false if location fetching fails
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        setLoading(false); // Set loading to false if geolocation is unavailable
      }
    } else {
      // Apply other filters
      const filteredObject = await filterHandler(filter);
      console.log("filteredobject", filteredObject);

      // Define request payload for fetching talents
      let bodyData = {
        limit: 20, // Limit the number of results to 20
        page: 1, // Specify the page number
      };

      // Apply sorting field and type if set
      if (sortField) {
        bodyData.sortField = sortField;
      }
      if (sortType) {
        bodyData.sortType = sortType;
      }

      // Add search term if specified
      if (searchTerm) {
        bodyData.search = searchTerm;
      }

      // Add other filters to the request payload based on filter criteria
      if (filteredObject?.topRated === true) {
        bodyData["topRated"] = true;
        bodyData["sortField"] = "rating"; // Sort by rating for top-rated filter
      }
      if (filteredObject?.topFollower === true) {
        bodyData["topFollower"] = true;
      }
      if (filteredObject?.genre) {
        bodyData.genre = filteredObject.genre;
      }
      if (filteredObject?.city?.trim()) {
        bodyData.city = filteredObject.city;
      }
      if (filteredObject?.hasInst) {
        bodyData["instrument-name"] = filteredObject.hasInst;
      }

      const options = {
        data: bodyData, // Include request data in the options
      };
      console.log(options);

      // Make the API request to fetch talents based on the filter
      const delayDebounceFn = setTimeout(() => {
      handleFetchRequest(ApiUrl.filter_search, "POST", options?.data).then(
        (res) => {
          if (res.status === true) {
            setLoading(false); // Set loading to false if data is successfully fetched
            setTalents(res?.data?.data); // Set the fetched talents data
          } else {
            setLoading(false); // Set loading to false if the request fails
          }
        }
      );
    }, 300); // 300 ms debounce delay
  
    return () => clearTimeout(delayDebounceFn);
    }
  };

  // Function to get suggested talents
  const getSuggestedTalents = async () => {
    // Fetch suggested talents data from the API
    handleFetchRequest(ApiUrl.fillter_user, "GET").then((res) => {
      if (res.status === true) {
        // Filter talents based on criteria and update state
        setSuggestedTalents(
          res?.data?.filter(
            (talent) =>
              talent?.User?.type === 3 && // User type is 3
              !talent?.isFollowedByMe && // User is not followed by the current user
              talent?.User?.id !== userDetails?.id // User is not the current user
          )
        );
      }
    });
  };

  // Effect hook to fetch talents based on filter state changes
  useEffect(() => {
    // If filter state is empty, get all talents
    if (Object.values(filterState).every((value) => value === "")) {
      getTalents();
    } else {
      // Otherwise, get talents based on the current filter state
      getTalents(filterState);
    }
  }, [filterState, searchTerm, sortField, sortType]); // Dependencies for the effect

  // State variables for genre and instruments
  const [genre, setGenre] = useState([]);
  const [instruments, setInstruments] = useState([]);

  // Function to fetch genres
  const fetchGenre = async () => {
    handleFetchRequest(ApiUrl.get_music_type, "GET").then((res) => {
      if (res.status === true) {
        // Update genre state with fetched data
        setGenre(res?.data);
      }
    });
  };

  // Function to fetch instruments
  const fetchInstruments = async () => {
    handleFetchRequest(ApiUrl.getInstrumentName).then((res) => {
      if (res.status == true) {
        // Update instruments state with fetched data
        setInstruments(res?.data?.instruments);
      }
    });
  };

  // Get user details from the Redux store
  const userDetails = useSelector((store) => store?.user?.userDetails);

  // Function to fetch nearby talents
  const getNearbyTalents = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const response = await handleFetchRequest(
              `${ApiUrl.get_nearby}lat=${latitude}&lng=${longitude}&radius=300&search=`
            );

            if (response.status === true) {
              console.log("fffffff", response?.data);
              // Filter talents and update state
              setNearbyTalents(response?.data?.users);
            }
          } catch (error) {
            console.error("Error fetching talents:", error);
          }
        },
        (error) => {
          console.error("Error fetching location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  // Function to fetch new nearby talents based on creation date
  const getNewNearbyTalents = async () => {
    handleFetchRequest(
      `${ApiUrl.get_nearby}lat=28.1223&lng=73.23231&radius=300&sortField=createdAt&search=`
    ).then((response) => {
      if (response.status === true) {
        // Filter new nearby talents and update state
        setNewNearbyTalents(
          response?.data?.users?.filter(
            (talent) =>
              talent?.User?.type === 3 &&
              !talent?.isFollowedByMe &&
              talent?.User?.id !== userDetails?.id
          )
        );
      }
    });
  };

  // Effect hook to fetch data on component mount
  useEffect(() => {
    fetchGenre();
    fetchInstruments();
    getNearbyTalents();
    getSuggestedTalents();
    getNewNearbyTalents();
  }, []); // Run once on component mount

  // Function to handle follow/unfollow click for suggested talents
  const handleFollowClick = (id) => {
    // Update the isFollowedByMe property for the clicked talent
    setSuggestedTalents((prevTalents) =>
      prevTalents.map((t) =>
        t?.User?.id === id ? { ...t, isFollowedByMe: !t.isFollowedByMe } : t
      )
    );

    // Send follow request to the server
    handleFetchRequest(`${ApiUrl.Follow_user}${id}`).then((response) => {
      if (response.status === true) {
        // Display success message
        // toast.success(response?.message);
      }
    });
  };

  // Function to reset filter data
  const reset = () => {
    setFilterdata({});
    setActiveTabs([]);
    setFilterState({});
  };

  // Function to remove a specific filter
  const removeFilterHandler = (tabId, keypair) => {
    // Update filter data and remove the specified filter
    setFilterdata((prev) => {
      const updatedFilter = { ...prev };
      delete updatedFilter[keypair];
      return updatedFilter;
    });

    // Update filter state and remove the specified filter
    setFilterState((prev) => {
      const updatedFilter = { ...prev };
      delete updatedFilter[keypair];
      return updatedFilter;
    });

    // Remove the tab from active tabs
    setActiveTabs((prev) => prev.filter((tab) => tab !== tabId));
  };

  // Function to handle follow/unfollow click for nearby talents
  const handleTalentFollowClick = (talentId) => {
    // Update the isFollowedByMe property for the clicked talent
    setNearbyTalents((prevTalents) =>
      prevTalents.map((t) =>
        t?.User?.id === talentId
          ? { ...t, isFollowedByMe: !t.isFollowedByMe }
          : t
      )
    );

    // Send follow request to the server
    handleFetchRequest(`${ApiUrl.Follow_user}${talentId}`).then((response) => {
      if (response.status === true) {
        // Display success message
        // toast.success(response?.message);
      }
    });
  };

  // Function to handle follow/unfollow click for new nearby talents
  const handleNewTalentFollowClick = (talentId) => {
    // Update the isFollowedByMe property for the clicked talent
    setNewNearbyTalents((prevTalents) =>
      prevTalents.map((t) =>
        t?.User?.id === talentId
          ? { ...t, isFollowedByMe: !t.isFollowedByMe }
          : t
      )
    );

    // Send follow request to the server
    handleFetchRequest(`${ApiUrl.Follow_user}${talentId}`).then((response) => {
      if (response.status === true) {
        // Display success message
        // toast.success(response?.message);
      }
    });
  };

  return (
    <>
      <MobileHeader
        // artistFillter={artistFillter}
        // setartistFillter={setartistFillter}
        isPage={"Talents"}
      />
      <MobileBottomNav />
      <DashboardLayout>
        <DashboardSidebar />
        <div className="center-content">
          <div className="feed">
            <div className="filter-row position-relative mt-0">
              <div className="d-flex align-item-center gap-4 flex-wrap flex-lg-nowrap justify-content-between w-100">
                {/* <div
                  className="d-flex align-items-center passfield-wrapper mb-0 w-100"
                  onClick={handleOutside}
                >
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={18}
                      height={18}
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
                        fill="#818898"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="searchbar"
                    placeholder="What are you looking for?"
                    value={searchTerm}
                    onChange={handleChange}
                  />
                </div> */}

                <div
                  className="input-group search-group"
                  onClick={handleOutside}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search jubal artists"
                    value={searchTerm}
                    onChange={handleChange}
                  />
                  <button
                    className="btn btn-secondary"
                    type="button"
                    id="button-addon2"
                  >
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={18}
                        height={18}
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
                          fill="#343B4E"
                        />
                      </svg>
                    </span>
                  </button>
                </div>

                <div className="inner-filter-row flex-sm-nowrap">
                  <div>
                    <FilterDropdown
                      genre={genre}
                      instruments={instruments}
                      setFilterState={setFilterState}
                      topFollower={true}
                      topRated={true}
                      dropdownRef={dropdownRef}
                      filter={filterdata}
                      setFilter={setFilterdata}
                      activeTabs={activeTabs}
                      setActiveTabs={setActiveTabs}
                      nearMe={true}
                      removeHandler={removeFilterHandler}
                    />
                  </div>

                  <div
                    className="dropdown dropdown-menu-end"
                    onClick={handleOutside}
                  >
                    <button
                      className="btn btn-light dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="15"
                          viewBox="0 0 19 15"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.0104 6.1634C17.713 6.4518 17.2382 6.4445 16.9499 6.1471L14.2383 3.3508V13.5C14.2383 13.9142 13.9025 14.25 13.4883 14.25C13.0741 14.25 12.7383 13.9142 12.7383 13.5V3.3508L10.0267 6.1471C9.7384 6.4445 9.26359 6.4518 8.96619 6.1634C8.66879 5.8751 8.6615 5.40025 8.9499 5.10289L12.9499 0.97789C13.0911 0.83222 13.2854 0.75 13.4883 0.75C13.6912 0.75 13.8855 0.83222 14.0267 0.97789L18.0267 5.10289C18.3151 5.40026 18.3078 5.8751 18.0104 6.1634Z"
                            fill="#AAAAAA"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.0104 8.8366C9.71298 8.5482 9.23818 8.5555 8.94988 8.8529L6.23828 11.6492V1.5C6.23828 1.08579 5.90249 0.75 5.48828 0.75C5.07407 0.75 4.73828 1.08579 4.73828 1.5V11.6492L2.02671 8.8529C1.73835 8.5555 1.26353 8.5482 0.966172 8.8366C0.668812 9.1249 0.661502 9.5997 0.949852 9.8971L4.94985 14.0221C5.09111 14.1678 5.28537 14.25 5.48828 14.25C5.69119 14.25 5.88545 14.1678 6.0267 14.0221L10.0267 9.8971C10.3151 9.5997 10.3078 9.1249 10.0104 8.8366Z"
                            fill="#111111"
                          />
                        </svg>
                      </span>
                      {" " + filter}
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          to="#"
                          className={
                            filter === "A to Z"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          onClick={() => {
                            setFilter("A to Z");
                            setSortField("firstName");
                            setSortType("ASC");
                          }}
                          // onClick={() => handleFilterChange("A to Z")}
                        >
                          A to Z
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            filter === "Newest"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to="#"
                          onClick={() => {
                            setFilter("Newest");
                            setSortField("createdAt");
                            setSortType("DESC");
                          }}
                          // onClick={() => handleFilterChange("Newest")}
                        >
                          Newest
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            filter === "Oldest"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to="#"
                          onClick={() => {
                            setFilter("Oldest");
                            setSortField("createdAt");
                            setSortType("ASC");
                          }}
                          // onClick={() => handleFilterChange("Oldest")}
                        >
                          Oldest
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {Object.entries(filterState).some(
              ([key, value]) =>
                value !== undefined &&
                value !== null &&
                value !== "" &&
                (typeof value === "string"
                  ? value.trim() !== ""
                  : value !== false)
            ) && (
              <div className="d-flex  mt-2 mb-4 justify-content-between align-items-center result-desc">
                <div className="d-flex  align-items-center gap-3 flex-wrap">
                  <span className="fltr-rs">
                    {/* {talents?.length > 1 && <> */}
                    Filter results:{" "}
                    <span className="num">{talents?.length}</span>
                    {/* </>} */}
                  </span>
                  {Object.entries(filterState).map(
                    ([key, value]) =>
                      value !== undefined &&
                      value !== null &&
                      value !== "" &&
                      (typeof value === "string"
                        ? value.trim() !== ""
                        : value !== false) && (
                        <span key={key} className="keyword">
                          {
                            key === "city"
                            ? "City"
                            : key === "topFollower"
                            ? "Top followers"
                            : key === "topRated"
                            ? "Top rated"
                            : key === "genre"
                            ? "Genre"
                            : key === "hasInst"
                            ? "Instrument"
                            : key === "nearMe"
                            ? "Near me"
                            : 
                          key
                          }
                          {/* {key} */}
                          {/* : <span className="num">20</span> */}
                          {/* <span onClick={() => removeFilterHandler(1, key)}>{SvgIcons?.crossIcon}</span> */}
                          <span
                          style={{cursor: "pointer"}}
                            onClick={() =>
                              removeFilterHandler(
                                key === "city"
                                  ? 3
                                  : key === "topFollower"
                                  ? 10
                                  : key === "topRated"
                                  ? 9
                                  : key === "genre"
                                  ? 1
                                  : key === "hasInst"
                                  ? 6
                                  : key === "nearMe"
                                  ? 11
                                  : "",
                                key
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                            >
                              <path
                                d="M0.232911 0.223116C0.0898854 0.366021 0.0095379 0.559815 0.0095379 0.761882C0.0095379 0.963948 0.0898854 1.15774 0.232911 1.30065L2.9306 3.99524L0.232911 6.68983C0.160044 6.76012 0.101923 6.84421 0.0619392 6.93718C0.0219553 7.03015 0.000909077 7.13015 2.88057e-05 7.23133C-0.000851466 7.33252 0.0184516 7.43286 0.056812 7.52651C0.0951724 7.62017 0.151822 7.70525 0.223455 7.7768C0.295087 7.84835 0.380269 7.90494 0.474029 7.94325C0.56779 7.98157 0.668251 8.00085 0.769551 7.99997C0.870852 7.99909 0.970962 7.97807 1.06404 7.93813C1.15712 7.89819 1.24131 7.84014 1.31168 7.76736L4.00937 5.07277L6.70707 7.76736C6.85096 7.90617 7.04367 7.98298 7.24371 7.98124C7.44374 7.97951 7.63509 7.89936 7.77654 7.75807C7.918 7.61678 7.99823 7.42565 7.99997 7.22585C8.00171 7.02604 7.92481 6.83355 7.78584 6.68983L5.08815 3.99524L7.78584 1.30065C7.92481 1.15692 8.00171 0.96443 7.99997 0.764624C7.99823 0.564818 7.918 0.373688 7.77654 0.232399C7.63509 0.0911098 7.44374 0.0109662 7.24371 0.00922999C7.04367 0.00749373 6.85096 0.0843039 6.70707 0.223116L4.00937 2.91771L1.31168 0.223116C1.16861 0.0802551 0.974596 0 0.772297 0C0.569997 0 0.37598 0.0802551 0.232911 0.223116Z"
                                fill="#86889A"
                              ></path>
                            </svg>
                          </span>
                        </span>
                      )
                  )}
                </div>
                <button onClick={reset} className="btn btn-link">
                  Clear Filters
                </button>
              </div>
            )}

            {loading ? (
              <VendorLoader />
            ) : (
              <div className="instruments-grid" onClick={handleOutside}>
                {talents &&
                  talents?.map((item, index) => {
                    return (
                      <JubalArtistCard
                        item={item}
                        key={index}
                        filterState={filterState}
                        // userDetail={userDetail}
                      />
                    );
                  })}

              </div>
              
            )}
                              {talents?.length < 1 && loading === false && <span className="form-text text-center">No talents found</span>}
          </div>
          {/* ========================== = */}
          {/*right side content Start */}
          {/* =========================== */}
          <div className="side-content-right">
            <div className="card featured-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 card-head border-0">
                    <div className="d-flex justify-content-between">
                      <h5>Near by Talents</h5>
                      <Link
                        to="/jubal-artist"
                        onClick={() =>
                          showMore === 6 ? setShowMore(10) : setShowMore(6)
                        }
                      >
                        {showMore === 6 ? "Show more" : "Show less"}
                      </Link>
                    </div>
                  </div>

                  {nearbyTalents
                    ?.slice(0, Math.min(showMore, nearbyTalents.length))
                    ?.map((talent) => (
                      <div className="col-12 px-0" key={talent.id}>
                        <div className="user-det-head">
                          <div className="user-det d-flex justify-content-between">
                            <div className="d-flex gap-3">
                              <Link
                                to={
                                  talent?.User?.id === userDetails?.id
                                    ? RoutesLink?.MyProfile
                                    : RoutesLink?.profile
                                }
                                state={{ id: talent?.User?.id }}
                              >
                                {talent?.profilePhoto ? (
                                  <span className="user-dp">
                                    <img
                                      src={talent?.profilePhoto}
                                      alt={talent?.firstName}
                                    />
                                  </span>
                                ) : (
                                  <span className="user-dp no-img">
                                    {talent?.firstName.toUpperCase()[0]}
                                  </span>
                                )}
                              </Link>
                              <div className="user-ac">
                                <div className="d-flex gap-2 align-items-center">
                                  <span className="username">
                                    <Link
                                      to={
                                        talent?.User?.id === userDetails?.id
                                          ? RoutesLink?.MyProfile
                                          : RoutesLink?.profile
                                      }
                                      state={{ id: talent?.User?.id }}
                                    >
                                      {talent?.firstName} {talent?.lastName}
                                    </Link>
                                  </span>
                                  <span className="usertype mx-1">
                                    <span>{SvgIcons?.talentIcon}</span>
                                    Talent
                                  </span>
                                </div>
                                <p>
                                  {JSON.parse(
                                    talent?.User?.Talent?.catagory
                                  )?.[0]?.substring(0, 20)}
                                  {JSON.parse(
                                    talent?.User?.Talent?.catagory
                                  )?.[0]?.length > 20
                                    ? "..."
                                    : ""}
                                  <span className="common-frnds ms-2">
                                    {talent?.commanFriends?.length} common
                                    friends
                                  </span>
                                </p>
                                <p className="distance">
                                  {talent?.distance < 1000
                                    ? `${talent?.distance.toFixed(0)} m`
                                    : `${(talent?.distance / 1000).toFixed(
                                        2
                                      )} km`}{" "}
                                  from you
                                </p>
                              </div>
                            </div>
                            <span
                              className="ms-2 follow align-self-center"
                              onClick={() =>
                                handleTalentFollowClick(talent?.userId)
                              }
                            >
                              {talent?.isFollowedByMe ? " Unfollow" : "Follow"}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  {nearbyTalents?.length < 1 && (
                    <p className="form-text">No artist found</p>
                  )}
                </div>
              </div>
            </div>

            <div className="card featured-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 card-head border-0">
                    <div className="d-flex justify-content-between">
                      <h5>New Artist near me</h5>
                      <Link
                        to="/jubal-artist"
                        onClick={() =>
                          newArtistShowMore === 6
                            ? setNewArtistShowMore(10)
                            : setNewArtistShowMore(6)
                        }
                      >
                        {newArtistShowMore === 6 ? "Show more" : "Show less"}
                      </Link>
                    </div>
                  </div>
                  {newNearbyTalents
                    ?.slice(2, newArtistShowMore)
                    ?.map((talent) => (
                      <div className="col-12 px-0" key={talent.id}>
                        <div className="user-det-head">
                          <div className="user-det d-flex justify-content-between">
                            <div className="d-flex gap-3">
                              <Link
                                to={
                                  talent?.User?.id === userDetails?.id
                                    ? RoutesLink?.MyProfile
                                    : RoutesLink?.profile
                                }
                                state={{ id: talent?.User?.id }}
                              >
                                {talent?.profilePhoto ? (
                                  <span className="user-dp">
                                    <img
                                      src={talent?.profilePhoto}
                                      alt={talent?.firstName}
                                    />
                                  </span>
                                ) : (
                                  <span className="user-dp no-img">
                                    {" "}
                                    {talent?.firstName.toUpperCase()[0]}
                                  </span>
                                )}
                              </Link>
                              <div className="user-ac">
                                <div className="d-flex gap-2 align-items-center">
                                  <span className="username">
                                    <Link
                                      to={
                                        talent?.User?.id === userDetails?.id
                                          ? RoutesLink?.MyProfile
                                          : RoutesLink?.profile
                                      }
                                      state={{ id: talent?.User?.id }}
                                    >
                                      {talent?.firstName} {talent?.lastName}
                                    </Link>
                                  </span>
                                  <span className="usertype mx-1">
                                    <span>{SvgIcons?.talentIcon}</span>
                                    Talent
                                  </span>
                                </div>
                                <p>
                                  {JSON.parse(
                                    talent?.User?.Talent?.catagory
                                  )?.[0]?.substring(0, 20)}
                                  {JSON.parse(
                                    talent?.User?.Talent?.catagory
                                  )?.[0]?.length > 20
                                    ? "..."
                                    : ""}
                                  <span className="common-frnds ms-2">
                                    {talent?.commanFriends?.length} common
                                    friends
                                  </span>
                                </p>
                                {/* <p>
                                  {talent?.Talent?.catagory &&
                                    (Array.isArray(talent?.Talent?.catagory) ? (
                                      talent?.Talent?.catagory[0]
                                    ) : (
                                      <span>
                                        {
                                          JSON.parse(
                                            talent?.Talent?.catagory
                                          )[0]
                                        }
                                      </span>
                                    ))}
                                </p> */}

                                <p className="distance">
                                  {talent?.distance < 1000
                                    ? `${talent?.distance.toFixed(0)} m`
                                    : `${(talent?.distance / 1000).toFixed(
                                        2
                                      )} km`}{" "}
                                  from you
                                </p>
                              </div>
                            </div>
                            <span
                              className="ms-2 follow align-self-center"
                              onClick={() =>
                                handleNewTalentFollowClick(talent?.userId)
                              }
                            >
                              {talent?.isFollowedByMe ? " Unfollow" : "Follow"}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  {nearbyTalents?.length < 1 && (
                    <p className="form-text">No any artist found</p>
                  )}
                </div>
              </div>
            </div>

            <div className="card featured-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 card-head border-0">
                    <div className="d-flex justify-content-between">
                      <h5>Suggested Talents</h5>
                      <Link
                        to="/jubal-artist"
                        onClick={() =>
                          suggestedShowMore === 6
                            ? setSuggestedShowMore(10)
                            : setSuggestedShowMore(6)
                        }
                      >
                        {suggestedShowMore === 6 ? "Show more" : "Show less"}
                      </Link>
                    </div>
                  </div>
                  {SuggestedTalents?.slice(2, suggestedShowMore) // Limit to the first 4 talents
                    .map((talent) => (
                      <div className="col-12 px-0" key={talent.id}>
                        <div className="user-det-head">
                          <div className="user-det d-flex justify-content-between">
                            <div className="d-flex gap-3">
                              <Link
                                to={
                                  talent?.User?.id === userDetails?.id
                                    ? RoutesLink?.MyProfile
                                    : RoutesLink?.profile
                                }
                                state={{ id: talent?.User?.id }}
                              >
                                {talent?.profilePhoto ? (
                                  <span className="user-dp">
                                    <img
                                      src={talent?.profilePhoto}
                                      alt={talent?.firstName}
                                    />
                                  </span>
                                ) : (
                                  <span className="user-dp no-img">
                                    {" "}
                                    {talent?.firstName?.toUpperCase()?.[0]}
                                  </span>
                                )}
                              </Link>
                              <div className="user-ac">
                                <div className="d-flex gap-2 align-items-center">
                                  <span className="username">
                                    <Link
                                      to={
                                        talent?.User?.id === userDetails?.id
                                          ? RoutesLink?.MyProfile
                                          : RoutesLink?.profile
                                      }
                                      state={{ id: talent?.User?.id }}
                                    >
                                      {talent?.firstName} {talent?.lastName}
                                    </Link>
                                  </span>
                                  <span className="usertype mx-1">
                                    <span>{SvgIcons?.talentIcon}</span>
                                    Talent
                                  </span>
                                </div>
                                <p>
                                  {talent?.Talent?.catagory &&
                                    (Array.isArray(talent?.Talent?.catagory) ? (
                                      talent?.Talent?.catagory[0]
                                    ) : (
                                      <span>
                                        {
                                          JSON.parse(
                                            talent?.Talent?.catagory
                                          )[0]
                                        }
                                      </span>
                                    ))}
                                </p>
                              </div>
                            </div>
                            <span
                              className="ms-2 follow align-self-center"
                              onClick={() => handleFollowClick(talent?.userId)}
                            >
                              {talent?.isFollowedByMe ? " Unfollow" : "Follow"}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {/* =========================== */}
          {/*right side content end */}
          {/* =========================== */}
        </div>
      </DashboardLayout>
    </>
  );
};

export default JubalArtist;
