import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import MobileHeader from "../../Components/Layout/MobileHeader";
import EventsDeleted from "./EventsDeleted";
import InstruementsDeleted from "./InstrumentsDeleted";
import PostDeleted from "./PostDeleted";

export default function Deleted() {
  return (
    <>
      {/* Mobile-specific components */}
      <MobileHeader />
      <MobileBottomNav />

      {/* Main content section */}
      <section className="px-0 main-content">
        <div className="max-width-container">
          {/* Main feed grid */}
          <div className="main-feed-grid">
            {/* Left side content - Dashboard Sidebar */}
            <DashboardSidebar />

            {/* Center content */}
            <div className="center-content d-block">
              <div>
                <div className="card tab-card activity-card">
                  <div className="card-body pb-0 position-relative">
                    {/* Divider */}
                    <div className="divideder position-absolute" />

                    {/* Tab navigation */}
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {/* Performance tab */}
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="activity-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#activity-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="activity-tab-pane"
                          aria-selected="true"
                        >
                          Performance
                        </button>
                      </li>

                      {/* Instruments / Equipments tab */}
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="equi-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#equi-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="equi-tab-pane"
                          aria-selected="false"
                          tabIndex={-1}
                        >
                          Instruments / Equipments
                        </button>
                      </li>

                      {/* Events tab */}
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="event-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#event-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="event-tab-pane"
                          aria-selected="false"
                          tabIndex={-1}
                        >
                          Events
                        </button>
                      </li>
                    </ul>

                    {/* Tab content */}
                    <div className="tab-content" id="myTabContent">
                      {/* Performance tab content */}
                      <div
                        className="tab-pane fade active show"
                        id="activity-tab-pane"
                        role="tabpanel"
                        aria-labelledby="like-tab"
                        tabIndex={0}
                      >
                        <PostDeleted />
                      </div>

                      {/* Instruments / Equipments tab content */}
                      <div
                        className="tab-pane fade"
                        id="equi-tab-pane"
                        role="tabpanel"
                        aria-labelledby="comment-tab"
                        tabIndex={0}
                      >
                        <InstruementsDeleted />
                      </div>

                      {/* Events tab content */}
                      <div
                        className="tab-pane fade"
                        id="event-tab-pane"
                        role="tabpanel"
                        aria-labelledby="review-tab"
                        tabIndex={0}
                      >
                        <EventsDeleted />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
