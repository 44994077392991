import { useEffect, useState } from "react";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import MobileHeader from "../../Components/Layout/MobileHeader";
import { SvgIcons } from "../../Components/Svg/SvgIcons";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { Link } from "react-router-dom";
import moment from "moment";
import { RoutesLink } from "../../utility/RoutesLink";

export default function Invoice() {
  const [myPlans, setMyPlans] = useState({});
  const [loading, setLoading] = useState(true);

  const getPlans = async () => {
    await handleFetchRequest(ApiUrl?.my_plans).then((response) => {
      if (response.status == true) {
        setMyPlans(response?.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getPlans();
    console.log(myPlans);
  }, []);

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <section className="px-0 main-content">
        <div className="max-width-container">
          {/* =========================== */}
          {/* Main feed grid Start */}
          {/* =========================== */}
          <div className="main-feed-grid">
            {/* =========================== */}
            {/*left side content Start */}
            {/* =========================== */}
            <DashboardSidebar />
            {/* =========================== */}
            {/*left side content end */}
            {/* =========================== */}
            {/* =========================== */}
            {/* center content Start */}
            {/* =========================== */}
            <div className="center-content d-block">
              <div className="card tab-card activity-card manage-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="filter-row position-relative gap-1">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <p>Plan order and invoice</p>
                        </div>
                        <p className="desc">
                          Review your plan order history, invoices, and more.
                        </p>
                      </div>
                      <div className="filter-row position-relative">
                        <div className="d-flex align-items-center justify-content-between w-100 flex-wrap gap-2">
                          <div className="d-flex align-items-center gap-2 gap-lg-4 flex-wrap">
                            <p>Orders</p>
                          </div>

                          {/* <div className="inner-filter-row">
                            <div className="dropdown dropdown-menu-end">
                              <button
                                className="btn btn-light dropdown-toggle d-flex align-items-center gap-2"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {SvgIcons?.filtericon}
                                Filter
                                <span className="selected-num">( 0 )</span>
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <a className="dropdown-item" href="#">
                                    A to Z
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    Newest
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    Oldest
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item clr-all" href="#">
                                    Clear all filter
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="card resource-card my-order-card">
                        <div className="card-body">
                          {myPlans?.activePlan ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Plan Items</th>
                                    <th scope="col">Plan Name</th>
                                    <th scope="col">Date&amp;Time</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Order number</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <img
                                        src={`${process.env.PUBLIC_URL}/images/plan.svg`}
                                        alt=""
                                      />
                                    </td>
                                    <td>
                                      <Link to="#">
                                        {myPlans?.activePlan?.Plan?.planName}
                                      </Link>
                                      <p>Billed monthly</p>
                                    </td>
                                    <td>
                                      {moment(
                                        myPlans?.activePlan?.updatedAt
                                      ).format("LLL")}
                                    </td>
                                    <td>${myPlans?.activePlan?.Plan?.price}</td>
                                    <td>{myPlans?.activePlan?.Plan?.id}</td>
                                    <td>
                                      <Link
                                        to={RoutesLink.plan_invoice}
                                        state={myPlans}
                                      >
                                        {" "}
                                        View invoice
                                      </Link>
                                    </td>
                                  </tr>
                                  {myPlans?.previousPlans?.length > 0 &&
                                    myPlans?.previousPlans?.map(
                                      (res, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <img
                                                src={`${process.env.PUBLIC_URL}/images/plan.svg`}
                                                alt=""
                                              />
                                              <Link to="#">
                                                {res?.plan?.planName}
                                              </Link>
                                              <p>Billed monthly</p>
                                            </td>
                                            <td>
                                              {moment(res?.updatedAt).format(
                                                "LLL"
                                              )}
                                            </td>
                                            <td>${res?.plan?.price}</td>
                                            <td>{res?.plan?.id}</td>
                                            <td>
                                              <Link
                                                to={RoutesLink.plan_invoice}
                                                state={myPlans}
                                              >
                                                {" "}
                                                View invoice
                                              </Link>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="text-center">No plan found</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* =========================== */}
            {/*center content end */}
            {/* =========================== */}
          </div>
          {/* =========================== */}
          {/* Main feed grid End */}
          {/* =========================== */}
        </div>
      </section>
    </>
  );
}
