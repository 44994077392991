import React, { useMemo, useState } from "react"; // Import React and necessary hooks
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"; // Import dropdown components from Reactstrap
import { Link } from "react-router-dom"; // Import Link for navigation
import { RoutesLink } from "../../utility/RoutesLink"; // Import routing links
import { calculateHours, getLoginUserCaption } from "../../Helper/Helper"; // Import helper functions for time calculation and captions
import moment from "moment"; // Import moment for date handling
import ShareModal from "../Modals/ShareModal"; // Import ShareModal component
import { useSelector } from "react-redux"; // Import useSelector for accessing Redux store
import VideoPlayer from "../Modals/VideoPlayer"; // Import VideoPlayer component
import { SvgIcons } from "../Svg/SvgIcons"; // Import SVG icons

function DashboardPostCard({
  data,
  index,
  LikeHandler,
  savePost,
  onLikeClick,
  onCommentClick,
  onfollwuser,
  commentPost,
  likeComment,
  reportPost,
  reportUser,
  showOtherOption = true,
}) {
  // State hooks for managing component state
  const [dropdownOpen, setDropdownOpen] = useState(false); // Dropdown state
  const toggledropdown = () => setDropdownOpen((prevState) => !prevState); // Function to toggle dropdown open/close
  const [CommentData, setCommentData] = useState(""); // State for comment input
  const [modal, setModal] = useState(false); // State for share modal visibility
  const [selectedSharePost, setselectedSharePost] = useState(null); // State for selected post to share
  const toggle = () => setModal(!modal); // Function to toggle share modal
  const userDetail = useSelector((store) => store?.user?.userDetails); // Get user details from Redux store

  // Memoized calculations for post duration and latest comment duration
  const duration = useMemo(
    () => calculateHours(data?.createdAt),
    [data?.updatedAt]
  );
  const CommentDuration = useMemo(
    () => calculateHours(data?.latestComment?.createdAt),
    [data?.latestComment?.createdAt]
  );

  // Function to handle sharing of the post
  const SharePost = (data) => {
    setModal(!modal); // Open/close the share modal
    setselectedSharePost(data); // Set the selected post for sharing
  };

  // State for active video playback
  const [activeVideoId, setActiveVideoId] = useState(null);
  const handlePlay = (id) => {
    setActiveVideoId(id); // Set the active video to play
  };

  // State for caption expansion
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded); // Toggle the expanded/collapsed state of the caption
  };

  // Define maximum length for caption truncation
  const MAX_LENGTH = 250;
  const caption = data?.caption || ""; // Get caption from post data
  const shouldTruncate = caption.length > MAX_LENGTH; // Check if caption exceeds max length for truncation

  return (
    <div className="card post-card" key={index}>
      <div className="card-body">
        <div className="user-det-head d-flex justify-content-between align-items-start">
          <div className="user-det d-flex gap-3">
            <Link
              to={
                data?.User?.id === userDetail?.Profile?.userId
                  ? RoutesLink?.MyProfile
                  : RoutesLink?.profile
              }
              state={{ id: data?.User?.id }}
            >
              {data?.User?.profilePhoto ? (
                <div className="user-dp">
                  <img src={data?.User?.profilePhoto} alt="" />
                </div>
              ) : (
                <div className="user-dp no-img">
                  {data?.User?.firstName?.[0]}
                </div>
              )}
            </Link>
            <div className="user-ac">
              <div className="d-flex gap-2 align-items-center">
                <span className="username">
                  <Link
                    to={
                      data?.User?.id === userDetail?.Profile?.userId
                        ? RoutesLink?.MyProfile
                        : RoutesLink?.profile
                    }
                    state={{ id: data?.User?.id }}
                  >
                    {data?.User?.firstName} {data?.User?.lastName}
                  </Link>
                </span>
                <span
                  className={
                    data?.User?.type === 3
                      ? "usertype talent mx-1"
                      : "usertype vendor mx-1"
                  }
                >
                  <span>
                    {(data?.User?.type === 3 || data?.User?.type === 2) &&
                      SvgIcons?.talentIcon}
                  </span>
                  {data?.User?.type === 3
                    ? "Talent"
                    : data?.User?.type === 2
                    ? "Vendor"
                    : ""}
                </span>
                {data?.User?.id === userDetail?.id ? (
                  ""
                ) : (
                  <span
                    className="ms-2 follow"
                    onClick={() =>
                      onfollwuser(data, data?.isFollowedByMe, "POST")
                    }
                  >
                    {" "}
                    {data?.isFollowedByMe ? "Unfollow" : "Follow"}
                  </span>
                )}
              </div>
              <p>{getLoginUserCaption(data?.User)}</p>
            </div>
          </div>
          <div className="post-det d-flex align-items-center gap-1 gap-1">
            <span className="post-time">{duration}</span>
            {showOtherOption == false ? (
              ""
            ) : (
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggledropdown}
                className="dropdown dropdown-menu-end "
              >
                <DropdownToggle
                  className="dropdown-toggle p-0"
                  style={{ backgroundColor: "transparent", border: 0 }}
                >
                  {" "}
                  <svg
                    width="31"
                    height="15"
                    viewBox="0 0 31 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3859 7.59648C10.3859 6.26906 9.3098 5.19297 7.98237 5.19297C6.65495 5.19297 5.57886 6.26906 5.57886 7.59648C5.57886 8.92391 6.65495 10 7.98237 10C9.3098 10 10.3859 8.92391 10.3859 7.59648Z"
                      fill="#343B4E"
                    />
                    <path
                      d="M18.193 7.59648C18.193 6.26906 17.1169 5.19297 15.7895 5.19297C14.4621 5.19297 13.386 6.26906 13.386 7.59648C13.386 8.92391 14.4621 10 15.7895 10C17.1169 10 18.193 8.92391 18.193 7.59648Z"
                      fill="#343B4E"
                    />
                    <path
                      d="M25.9999 7.59648C25.9999 6.26906 24.9238 5.19297 23.5964 5.19297C22.269 5.19297 21.1929 6.26906 21.1929 7.59648C21.1929 8.92391 22.269 10 23.5964 10C24.9238 10 25.9999 8.92391 25.9999 7.59648Z"
                      fill="#343B4E"
                    />
                  </svg>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu post-dropdown"
                  // container="body"
                >
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => savePost(data, data?.isSavedByMe)}
                  >
                    {data?.isSavedByMe === true ? "Unsave Post" : "Save post"}
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => SharePost(data?.id)}
                  >
                    Share post
                  </DropdownItem>
                  {data?.User?.id != userDetail?.id && (
                    <DropdownItem
                      className="dropdown-item"
                      // onClick={function noRefCheck() {}}
                      onClick={() =>
                        onfollwuser(data, data?.User?.isFollowedByMe, "POST")
                      }
                    >
                      {data?.isFollowedByMe
                        ? "Unfollow artist"
                        : "Follow artist"}
                    </DropdownItem>
                  )}
                  {data?.isReportedByMe == true ||
                  data?.User?.id == userDetail?.id ? (
                    ""
                  ) : (
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() =>
                        reportPost(data, data?.isReportedByMe, "POST")
                      }
                    >
                      Report post
                    </DropdownItem>
                  )}
                  {data?.isUserReportedByMe == true ||
                  data?.User?.id == userDetail?.id ? (
                    ""
                  ) : (
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() =>
                        reportUser(data, data?.isUserReportedByMe, "POST")
                      }
                    >
                      Report User
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        </div>
        {data?.caption?.length > 0 && (
          <div className="post-caption">
            <p>
              {isExpanded ? caption : caption.substring(0, MAX_LENGTH)}
              {shouldTruncate && !isExpanded ? "..." : ""}
            </p>
            {shouldTruncate && (
              <button className="btn btn-link p-0 m-0" onClick={handleToggle}>
                {isExpanded ? "Show Less" : "Show More"}
              </button>
            )}
          </div>
        )}

        {data?.mediaType === "photo" ? (
          <div className="content">
            <img src={data?.postImageUrl} alt="" className="w-100" />
          </div>
        ) : (
          <>
            {/* <VideoPlayer src={data?.postImageUrl} /> */}

            <VideoPlayer
              key={index}
              id={index}
              src={data?.postImageUrl}
              isPlaying={activeVideoId == index}
              onPlay={handlePlay}
            />
          </>
        )}
        <div className="user-activity d-flex justify-content-between">
          <div className="d-flex">
            <Link
              to="#"
              className="liked d-lg-block"
              onClick={() => onLikeClick(data)}
            >
              {data?.Likes} likes
            </Link>
            <Link
              to="#"
              className="commented d-lg-block"
              onClick={() => onCommentClick(data)}
            >
              {data?.Comments} Comments
            </Link>

            <span className="shared">{data?.shareCount} Shared</span>
          </div>
          <div className="d-flex user-activity">
            <button
              type="button"
              onClick={() => LikeHandler(data, data?.isLikedByMe, "POST")}
              className={
                data?.isLikedByMe === true
                  ? "like activated bg-transparent"
                  : "like bg-transparent"
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="none"
              >
                <path
                  d="M17.6325 6.26746C17.2495 5.88422 16.7946 5.5802 16.294 5.37278C15.7935 5.16535 15.2569 5.05859 14.715 5.05859C14.1732 5.05859 13.6366 5.16535 13.136 5.37278C12.6354 5.5802 12.1806 5.88422 11.7975 6.26746L11.0025 7.06246L10.2075 6.26746C9.43376 5.49369 8.38431 5.05899 7.29003 5.05899C6.19576 5.05899 5.1463 5.49369 4.37253 6.26746C3.59876 7.04123 3.16406 8.09069 3.16406 9.18496C3.16406 10.2792 3.59876 11.3287 4.37253 12.1025L5.16753 12.8975L11.0025 18.7325L16.8375 12.8975L17.6325 12.1025C18.0158 11.7194 18.3198 11.2646 18.5272 10.764C18.7346 10.2634 18.8414 9.72683 18.8414 9.18496C18.8414 8.6431 18.7346 8.10654 18.5272 7.60595C18.3198 7.10535 18.0158 6.65053 17.6325 6.26746V6.26746Z"
                  stroke="#86889A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button
              type="button"
              onClick={() => savePost(data, data?.isSavedByMe)}
              className={
                data?.isSavedByMe === true
                  ? "save activated bg-transparent "
                  : "save bg-transparent"
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="none"
              >
                <path
                  d="M16.25 18.5605L11 14.8105L5.75 18.5605V6.56055C5.75 6.16272 5.90804 5.78119 6.18934 5.49989C6.47064 5.21858 6.85218 5.06055 7.25 5.06055H14.75C15.1478 5.06055 15.5294 5.21858 15.8107 5.49989C16.092 5.78119 16.25 6.16272 16.25 6.56055V18.5605Z"
                  stroke="#86889A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        {showOtherOption == false ? (
          ""
        ) : (
          <div className="comment-input">
            <input
              type="text"
              className="form-control"
              placeholder="Add your comment"
              value={CommentData}
              onChange={(e) => {
                setCommentData(e.target.value);
              }}
            />
            <button
              onClick={() => {
                // Trim CommentData before sending
                const trimmedCommentData = CommentData.trim();

                // Check if trimmedCommentData is not empty before posting
                if (trimmedCommentData !== "") {
                  commentPost(
                    data?.id,
                    trimmedCommentData,
                    "POST",
                    setCommentData
                  );
                }
              }}
              disabled={CommentData.trim() === ""}
              className="btn btn-white"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M17.5 3.3125L9.25 11.5625"
                  stroke="#86889A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.5 3.3125L12.25 18.3125L9.25 11.5625L2.5 8.5625L17.5 3.3125Z"
                  stroke="#86889A"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        )}

        {data?.latestComment && (
          <div className="comment-area">
            <div className="user-det d-flex gap-3">
              {data?.latestComment?.user?.Profile?.profilePhoto ? (
                <Link
                  className="user-dp"
                  to={
                    data?.latestComment?.user?.id ===
                    userDetail?.Profile?.userId
                      ? RoutesLink?.MyProfile
                      : RoutesLink?.profile
                  }
                  state={{ id: data?.latestComment?.user?.id }}
                >
                  <img
                    src={data?.latestComment?.user?.Profile?.profilePhoto}
                    alt="Selena"
                  />
                </Link>
              ) : (
                <Link
                  className="user-dp no-img"
                  to={
                    data?.latestComment?.user?.id ===
                    userDetail?.Profile?.userId
                      ? RoutesLink?.MyProfile
                      : RoutesLink?.profile
                  }
                  state={{ id: data?.latestComment?.user?.id }}
                >
                  {data?.latestComment?.user?.Profile?.firstName?.[0]}
                </Link>
              )}
              <div className="d-flex flex-column flex-grow-1 gap-2 gap-md-3">
                <div className="d-flex justify-content-between w-100">
                  <div className="user-ac">
                    <div className="d-flex gap-2 align-items-center">
                      <span className="username">
                        <Link
                          to={
                            data?.latestComment?.user?.id ===
                            userDetail?.Profile?.userId
                              ? RoutesLink?.MyProfile
                              : RoutesLink?.profile
                          }
                          state={{ id: data?.latestComment?.user?.id }}
                        >
                          {data?.latestComment?.user?.Profile?.firstName}{" "}
                          {data?.latestComment?.user?.Profile?.firstName}
                        </Link>
                      </span>
                      {data?.latestComment?.user?.type === 3 && (
                        <span className="usertype mx-1">
                          <span>{SvgIcons?.talentIcon}</span>
                          Talent
                        </span>
                      )}
                    </div>
                  </div>

                  <a
                    onClick={() =>
                      likeComment(
                        data,
                        data?.latestComment?.isLikedByMe,
                        "POST"
                      )
                    }
                    className={
                      data?.latestComment?.isLikedByMe
                        ? "like activated"
                        : "like"
                    }
                  >
                    {SvgIcons?.likeIcon}
                  </a>
                </div>
                <p className="two-line-ellipsis">
                  {data?.latestComment?.comment}
                </p>
                <div className="d-flex gap-4">
                  <span className="liked">
                    {data?.latestComment?.commentLikes} likes
                  </span>
                  <span
                    className="commented"
                    onClick={() => onCommentClick(data)}
                  >
                    {data?.latestComment?.commentReplies} Reply
                  </span>
                  <span className="shared">{CommentDuration} ago</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ShareModal
        modal={modal}
        toggle={(val) => toggle(val)}
        postId={selectedSharePost}
        type={"post"}
      />
    </div>
  );
}

export default DashboardPostCard;
