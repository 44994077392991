import React from "react";

// Functional component to display a loading banner
function BannerLoader() {
  return (
    <>
      <div className="Loader">
        {" "}
        {/* Main loader container */}
        <div className="wrapper">
          {" "}
          {/* Wrapper for the loader */}
          <div className="Image-wapper">
            {" "}
            {/* Container for the image placeholder */}
            <div className="image" style={{ height: "200px" }}></div>{" "}
            {/* Placeholder for the image with a fixed height */}
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerLoader;
