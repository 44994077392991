export const CHECK_USER_LOGGED_IN = "CHECK_USER_LOGGED_IN";
export const USER_LOGGED_IN_DETAILS = "USER_LOGGED_IN_DETAILS";
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const USER_TYPE = "USER_TYPE";
export const CART_DATA = "CART_DATA";
export const CONVERSION_RATE = "CONVERSION_RATE";
export const NOTIFICATION_DETAIL = "NOTIFICATION_DETAIL";
export const PROFILE_IMAGE = "PROFILE_IMAGE";
export const SAVE_INSTRUMENTS_LIKES = "SAVE_INSTRUMENTS_LIKES";
export const SAVE_EVENTS_LIKES = "SAVE_EVENTS_LIKES";
export const OPEN_CART_MODAL = "OPEN_CART_MODAL";
export const CLOSE_CART_MODAL = "CLOSE_CART_MODAL";
export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const SET_CART_DATA = "SET_CART_DATA";
export const CLEAR_CART_DATA = "CLEAR_CART_DATA";
export const SAVE_TALENTS = "SAVE_TALENTS";
