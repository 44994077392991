// // src/components/SquarePaymentForm.js
// import React, { useEffect, useState } from "react";
// import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
// import { ApiUrl } from "../../utility/ApiUrl";
// import { useLocation, useNavigate } from "react-router-dom";
// import { toast } from "material-react-toastify";
// import { RoutesLink } from "../../utility/RoutesLink";

// const HiringPayment = () => {
//   const location = useLocation();
//   const [card, setCard] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   useEffect(() => {
//     const initializeSquarePayment = async () => {
//       try {
//         const payments = window.Square.payments(
//           "sandbox-sq0idb-T_G-6votRDRzncYZCqZb1w",
//           "LQJ4PZYJ257G"
//         );

//         // Updated styles with valid CSS properties for Square Payment form
//         const cardStyles = {
//           input: {
//             color: "#333", // Text color
//             fontSize: "16px", // Font size for input text
//             fontFamily: "Arial", // Set the font family
//           },
//         };

//         // Initialize the card instance with styles
//         const cardInstance = await payments.card({
//           style: cardStyles,
//           // collectPostalCode: false,
//         });

//         // Attach the card instance to the DOM element
//         await cardInstance.attach("#card-container");

//         // Set the card instance state
//         setCard(cardInstance);
//       } catch (error) {
//         console.error("Square Payment form initialization failed:", error);
//       }
//     };

//     initializeSquarePayment();
//   }, []);

//   const checkoutHandler = (paymentId, card) => {
//     setLoading(true);
//     const bookingdata = {
//       cardDetail: {
//         cardNumber: card?.last4,
//         brand: card?.cardBrand,
//         type: card?.cardType,
//       },
//       amount: location.state.amount,
//       bookingId: location.state.bookingId,
//       transactionId: paymentId,
//       // remark: "Paid to talent",
//       amountWithCommsion: location.state.commsion,
//       paidTo: "admin", //admin : if vendor want to send amount commission will deduct // talent: if talent to reveive amount
//     };

//     handleFetchRequest(ApiUrl.create_payment, "POST", bookingdata).then(
//       (res) => {
//         if (res.status == true) {
//           toast.success(res?.message);
//           let options2 = {
//             vendorBookingCompleted: true,
//           };
//           handleFetchRequest(
//             ApiUrl?.edit_booking + location.state.bookingId,
//             "POST",
//             options2
//           ).then((response) => {
//             if (response.status == true) {
//               toast.success("Booking completed by you");
//               navigate(-1);
//             }
//           });
//         } else {
//           toast.error(res?.message);
//         }
//       }
//     );
//   };
//   const handlePayment = async () => {
//     if (!card) return;
//     setLoading(true);

//     try {
//       const result = await card.tokenize();
//       if (result.status === "OK") {
//         console.log("Payment token:", result.token);
//         // Send payment token, name, and email to the server
//         const paymentData = {
//           token: result.token,
//           // name: formData.name,
//           // email: formData.email,
//           amount: location?.state?.amount, // Example amount in cents ($10.00)
//         };
//         handleFetchRequest(
//           ApiUrl.create_payment_intent,
//           "POST",
//           paymentData
//         ).then(async (res) => {
//           if (res.status === true) {
//             checkoutHandler(
//               res?.data?.paymentId,
//               res?.data?.paymentDetail?.cardDetails?.card
//             );
//           } else {
//             alert(`Payment failed: ${res.error}`);
//           }
//         });
//       }
//     } catch (error) {
//       console.error("Payment failed:", error);
//     }

//     //payment

//     setLoading(false);
//   };

//   return (
//     <div className="row justify-content-center  w-100 align-items-center h-100vh">
//       <div className="col-12 col-md-6 col-lg-5">
//         <div className="card getway-card">
//           <div className="card-body">
//             <h2 className="pb-4 pb-lg-5 text-center fs-3 fw-bold">
//               Complete Your Payment
//             </h2>
//             <div id="card-container"></div>{" "}
//             {/* The card form will render here */}
//             <button type="button" onClick={handlePayment} disabled={loading}>
//               {loading ? "Processing..." : "Pay Now"}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HiringPayment;

import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "material-react-toastify";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { RoutesLink } from "../../utility/RoutesLink";

const HiringPayment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",

      card: cardElement,
    });

    if (error) {
      setPaymentStatus(error.message);
    } else {
      // Send paymentMethod.id to your server for further processing.
      // const response = await fetch("/create-payment-intent", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      setLoading(true);
      const paymentData = {
        token: paymentMethod.id,
        amount:
          location?.state?.amount +
          (location.state.Commision * location.state.amount) / (100).toFixed(2), // Example amount in cents ($10.00) }),
        paymentGateway: "stripe",
      };
      handleFetchRequest(
        ApiUrl.create_payment_intent,
        "POST",
        paymentData
      ).then(async (res) => {
        console.log("rwssssssss", res);
        if (res?.data?.status == "succeeded") {
          checkoutHandler(
            res?.data?.paymentId,
            res?.data?.paymentDetail?.cardDetails?.card
          );
        } else {
          alert(`Payment failed: ${res.error}`);
        }
      });

      // const paymentResult = await response.json();
    }
  };
  const checkoutHandler = (paymentId, card) => {
    setLoading(true);
    const bookingdata = {
      cardDetail: {
        cardNumber: card?.last4,
        brand: card?.cardBrand,
        type: card?.cardType,
      },
      amount: location.state.amount,
      bookingId: location.state.bookingId,
      transactionId: paymentId,
      // remark: "Paid to talent",
      amountWithCommsion: location.state.commsion,
      paidTo: "admin", //admin : if vendor want to send amount commission will deduct // talent: if talent to reveive amount
    };

    handleFetchRequest(ApiUrl.create_payment, "POST", bookingdata).then(
      (res) => {
        if (res.status == true) {
          toast.success(res?.message);
          let options2 = {
            vendorBookingCompleted: true,
          };
          handleFetchRequest(
            ApiUrl?.edit_booking + location.state.bookingId,
            "POST",
            options2
          ).then((response) => {
            if (response.status == true) {
              toast.success("Booking completed by you");
              navigate(-1);
            }
          });
        } else {
          toast.error(res?.message);
        }
      }
    );
  };
  return (
    <div className="row justify-content-center align-items-center h-100vh ">
      <div className="col-12 col-md-8">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-6">
                <form
                  onSubmit={handleSubmit}
                  className="d-flex h-100 flex-column justify-content-between"
                >
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          color: "#424770",
                          "::placeholder": {
                            color: "#aab7c4",
                          },
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                  />
                  <button
                    type="submit"
                    // disabled={!stripe}
                    disabled={!stripe || loading}
                    className="btn btn-primary w-75 d-block mx-auto mt-5"
                  >
                    Pay
                  </button>
                  {/* <p>{paymentStatus}</p> */}
                </form>
              </div>
              <div className="pay-sum col-lg-6">
                <h3>Order summary</h3>

                <div className="d-flex align-items-center justify-content-between">
                  <p>Budget</p>
                  <p>${location?.state?.amount?.toFixed(2)}</p>{" "}
                  {/* Display amount with 2 decimals */}
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex">
                    <p>Service Fee</p>
                  </div>

                  <p>
                    <span>
                      {/* Calculate commission percentage (amount / commission * 100) */}
                      $
                      {location?.state?.amount && location?.state?.Commision
                        ? (
                            (location.state.Commision * location.state.amount) /
                            100
                          ).toFixed(2)
                        : "N/A"}{" "}
                      {/* Show 'N/A' if values are missing */}
                    </span>
                  </p>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <h5>Payable Amount</h5>
                  <h5>
                    $
                    {location?.state?.amount +
                      (location.state.Commision * location.state.amount) /
                        (100).toFixed(2)}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HiringPayment;
