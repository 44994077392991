import React from "react";
import "./helper.css";

function VendorLoader() {
  return (
    <>
      <div className="row">
        <div className="Loader col-lg-6">
          <div className="wrapper">
            <div className="wrapper-cell">
              <div className="text d-flex" style={{ padding: "20px" }}>
                <div
                  className="thumbnail-image"
                  style={{ height: "40px", width: "40px", borderRadius: "50%" }}
                ></div>
                <div>
                  <div className="text-line"> </div>
                  <div className="text-line"></div>
                </div>
              </div>
            </div>
            <div className="Image-wapper" style={{ margin: "15px" }}>
              <div className="image" style={{ height: "250px" }}></div>
            </div>
            <div className="text">
              <div className="text-line"> </div>
              <div className="text-line"></div>
            </div>
          </div>
        </div>
        <div className="Loader col-lg-6">
          <div className="wrapper">
            <div className="wrapper-cell">
              <div className="text d-flex" style={{ padding: "20px" }}>
                <div
                  className="thumbnail-image"
                  style={{ height: "40px", width: "40px", borderRadius: "50%" }}
                ></div>
                <div>
                  <div className="text-line"> </div>
                  <div className="text-line"></div>
                </div>
              </div>
            </div>
            <div className="Image-wapper" style={{ margin: "15px" }}>
              <div className="image" style={{ height: "250px" }}></div>
            </div>
            <div className="text">
              <div className="text-line"> </div>
              <div className="text-line"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VendorLoader;
