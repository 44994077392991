import Header from "../Components/Header";
import { useEffect, useState } from "react";
import ReactOwlCarousel from "react-owl-carousel";
import HomeFooter from "../Components/HomeFooter";
import { handleFetchRequest } from "../ApiHelper/ApiHelper";
import { ApiUrl } from "../utility/ApiUrl";
import { Modal, ModalBody } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { SvgIcons } from "../Components/Svg/SvgIcons";
import { RoutesLink } from "../utility/RoutesLink";
import MangeRequestLoader from "../Helper/MangeRequestLoader";
import { useSelector } from "react-redux";
import { toast } from "material-react-toastify";

const Subscription = () => {
  const [plans, setPlans] = useState([]);
  const [modal, setModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const toggle = () => setModal(!modal);
  const toggleShareModal = () => setShareModal(!shareModal);
  const navigate = useNavigate();
  const userDetails = useSelector((store) => store?.user?.userDetails);

  const getPlans = async () => {
    await handleFetchRequest(ApiUrl?.get_plan).then((response) => {
      if (response.status == true) {
        setPlans(response?.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getPlans();
  }, []);

  const createPlan = (plan) => {
    // if(userDetails?.isPlanPurchased === true) {
    //   toast?.info("You have already an active plan");
    //   return;
    // }
    if (userDetails?.type === 2) {
      navigate(RoutesLink?.plan_checkout, {
        state: { plan },
      });
    } else {
      toast?.error("Only vendor can subscribe to the plan");
    }
  };

  if (loading) {
    return <MangeRequestLoader />;
  }

  return (
    <>
      <Header />
      <section className="subscription">
        <div className="max-width-container position-relative">
          <div className="subscription-wrapper">
            <h4 className="h3">Plans &amp; Pricing</h4>
            <div className="d-flex flex-column flex-md-row gap-md-5 align-items-center justify-content-between">
              <p className="para">
                Whether your time-saving automation needs are large or small,
                we’re here to help you scale.
              </p>
              <div className="tabs">
                <input
                  type="radio"
                  id="radio-1"
                  name="tabs"
                  defaultChecked="true"
                  checked
                />
                <label className="tab" htmlFor="radio-1">
                  MONTHLY
                </label>
                <input type="radio" id="radio-2" name="tabs" />
                <label className="tab" htmlFor="radio-2">
                  YEARLY
                </label>
                <span className="glider" />
              </div>
            </div>
            <div className="pricing-slider">
              <ReactOwlCarousel items={3} margin={40}>
                <div className="pricing-card dark">
                  <div>
                    <h5>
                      <span>Save More</span>With Goodplans.
                    </h5>
                    <ul>
                      <li className="m-0">
                        Choose a plan and get onboard in minutes. Then get $100
                        credits for your next payment.
                      </li>
                    </ul>
                    {/* ../public/images/pc-img.png */}
                    <img src="images/pc-img.png" alt="" />
                  </div>
                </div>
                {plans?.map((plan, index) => (
                  <div className="pricing-card" key={index}>
                    <div>
                      <h5>{plan?.planName}</h5>
                      <p>What You’ll Get</p>
                      <ul>
                        {plan?.features?.map((feature, index) => (
                          <li key={index}>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM10.001 16.413L6.288 12.708L7.7 11.292L9.999 13.587L15.293 8.293L16.707 9.707L10.001 16.413Z"
                                  fill="#7133F4"
                                />
                              </svg>
                            </span>
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h6>
                        ${plan?.price}
                        <span>/{plan?.expiryInMonths}months</span>
                      </h6>
                      <button
                        className="btn btn-purple w-100"
                        onClick={() => createPlan(plan)}
                      >
                        Choose
                      </button>
                    </div>
                  </div>
                ))}
              </ReactOwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <section className="get-started">
        <div className="max-width-container">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-6 col-mx-5">
              <h6>Ready to get started?</h6>
              <p>
                Timperdiet gravida scelerisque odio nunc. Eget felis, odio
                bibendum quis eget sit lorem donec diam. Volutpat sed orci
                turpis sit dolor
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <div className="row gap-3 gap-lg-4 justify-content-lg-center align-items-center h-100">
                <div className="col-12  col-sm-5 p-0">
                  <button
                    className="btn-white w-100"
                    onClick={() => setShareModal(!shareModal)}
                    // data-bs-toggle="modal"
                    // data-bs-target="#share"
                  >
                    Share with Friend
                  </button>
                </div>
                <div className="col-12 col-sm-5 p-0">
                  <button
                    className="btn btn-black w-100"
                    // data-bs-toggle="modal"
                    // data-bs-target="#getapp"
                    onClick={() => setModal(!modal)}
                  >
                    Get the App
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HomeFooter />
      <Modal
        className="modal-dialog modal-dialog-centered get-app-dialog"
        toggle={toggle}
        isOpen={modal}
        // contentClassName="modal-content create-post comment-modal share-modal"
      >
        <ModalBody>
          <div className="row ">
            <div className="col-12 col-md-4 col-lg-4 p-0 pe-lg-4">
              <div className="img-box">
                <img
                  src={`${process.env.PUBLIC_URL}/images/appQR.png`}
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-8 p-0 ps-md-4">
              <div className="d-flex h-100 flex-column align-items-start justify-content-center">
                <h2>Downloading the app is super simple...</h2>
                <p>
                  Simply scan the QR code, or enter your mobile number below to
                  get a download link via SMS
                </p>
                <div className="mail-wrapper">
                  <label htmlFor="g-mail" className="form-label">
                    Email Address
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="g-mail"
                      aria-describedby="basic-addon3 basic-addon4"
                      placeholder="email@gmail.com"
                    />
                    <button className="btn btn-purple" type="button">
                      Get Link
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        className="modal-dialog modal-dialog-centered share-friends-dialog"
        toggle={toggleShareModal}
        isOpen={shareModal}
      >
        <div className="modal-content border-0">
          <div className="modal-body  ">
            <img
              src={`${process.env.PUBLIC_URL}/images/share.png`}
              alt=""
              className="full-img d-none d-lg-block"
            />

            <div className="side-col">
              <div className="d-flex h-100 flex-column align-items-start justify-content-center">
                <h2>Tell your friend about Jubal Talents</h2>
                <p>
                  Send a link to your friends, or share directly to your social
                  channels.
                </p>
                <div className="mail-wrapper">
                  <label for="g-mail" className="form-label">
                    Email Address
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="g-mail"
                      aria-describedby="basic-addon3 basic-addon4"
                      placeholder="email@gmail.com"
                    />
                    <button className="btn btn-purple" type="button">
                      Get Link
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center p-0 gap-3 gap-lg-4 social-links">
                  <Link to="javascript:void(0)">{SvgIcons?.instagramIcon}</Link>
                  <Link to="javascript:void(0)">{SvgIcons?.facebookIcon}</Link>
                  <Link to="javascript:void(0)">{SvgIcons?.emailIcon}</Link>
                  <Link to="javascript:void(0)">{SvgIcons?.whatsappIcon}</Link>
                  <Link to="javascript:void(0)">{SvgIcons?.twitterIcon}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Subscription;
