/* eslint-disable no-undef */
import CommonRoutes from "./Routes/CommonRoutes";
import "./App.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserLoggedInDetails } from "./store/actions/UserActions";
import Cookies from "js-cookie";
import withNetworkDetection from "./withNetworkDetection"; // Import the HOC
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase";
import { toast } from "material-react-toastify";

function App() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  useEffect(() => {
    const userGetData = Cookies.get("jubal_web_data");
    if (userGetData) {
      const userData = JSON.parse(userGetData);
      dispatch(setUserLoggedInDetails(userData));
    }
  }, [dispatch]);

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    });
  }
  // useing firebase for sending messages
  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log("payy", payload);
      toast.info(
        `${payload?.notification.title}: ${payload?.notification.body}`,
        {
          position: "bottom-left",
          autoClose: 5000, // Close after 5 seconds
        }
      );
    });
  }, []);

  return (
    <>
      <CommonRoutes />
    </>
  );
}

export default withNetworkDetection(App); // Wrap the App component with the HOC
