import moment from "moment";
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import generatePDF from "react-to-pdf";

function PlanInvoiceBill() {
  const location = useLocation();
  const pdfRef = useRef(null);
  console.log("user", location?.state);
  return (
    <div
      style={{
        maxWidth: 800,
        margin: "auto",
        padding: 16,
        border: "1px solid #eee",
        fontSize: 16,
        lineHeight: 24,
        fontFamily: '"Inter", sans-serif',
        color: "#555",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <table style={{ fontSize: 12, lineHeight: 20 }} ref={pdfRef}>
        <thead>
          <tr>
            <td style={{ padding: "0 16px 18px 0px", lineHeight: "normal" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src="/images/logos/jubal_logo.png"
                  height={"30px"}
                  alt=""
                ></img>

                <h1
                  style={{
                    color: "#1A1C21",
                    fontSize: 22,
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "20px",
                    display: "inline-block",
                    paddingLeft: "8px",
                    verticalAlign: "baseline",
                  }}
                >
                  Jubal Talents
                </h1>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <table
                style={{
                  backgroundColor: "#FFF",
                  padding: "20px 16px",
                  width: "100%",
                  borderRadius: 12,
                  fontSize: 12,
                  lineHeight: 20,
                  tableLayout: "fixed",
                }}
              >
                <tbody style={{ padding: "20px 16px" }}>
                  <tr>
                    <td
                      style={{
                        verticalAlign: "top",
                        width: "30%",
                        paddingRight: 20,
                        paddingBottom: 35,
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 700,
                          lineHeight: "20px",
                          color: "#1A1C21",
                        }}
                      >
                        {location?.state?.user?.Profile?.firstName}{" "}
                        {location?.state?.user?.Profile?.lastName}
                      </p>

                      <p style={{ color: "#5E6470", lineHeight: "20px" }}>
                        {
                          JSON.parse(location?.state?.user?.Profile?.location)
                            ?.city
                        }
                        ,{" "}
                        {
                          JSON.parse(location?.state?.user?.Profile?.location)
                            ?.state
                        }
                        ,{" "}
                        {
                          JSON.parse(location?.state?.user?.Profile?.location)
                            ?.country
                        }
                      </p>
                      <p style={{ color: "#5E6470", lineHeight: "20px" }}>
                        {location?.state?.user?.countryCode}{" "}
                        {location?.state?.user?.phone}
                      </p>
                      <p style={{ color: "#5E6470", lineHeight: "20px" }}>
                        {location?.state?.user?.email}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ paddingBottom: 13 }}>
                      <p
                        style={{
                          color: "#5E6470",
                          lineHeight: "normal",
                          lineHeight: "normal",
                        }}
                      >
                        Service{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: 700,
                          lineHeight: "normal",
                          color: "#1A1C21",
                          lineHeight: "normal",
                        }}
                      >
                        Subscription Service
                      </p>
                    </td>
                    <td style={{ textAlign: "center", paddingBottom: 13 }}>
                      <p
                        style={{
                          color: "#5E6470",
                          lineHeight: "normal",
                          lineHeight: "normal",
                        }}
                      >
                        Invoice number
                      </p>
                      <p
                        style={{
                          fontWeight: 700,
                          lineHeight: "normal",
                          color: "#1A1C21",
                          lineHeight: "normal",
                        }}
                      >
                        #{location?.state?.activePlan?.planId}
                      </p>
                    </td>
                    <td style={{ textAlign: "end", paddingBottom: 13 }}>
                      <p
                        style={{
                          color: "#5E6470",
                          lineHeight: "normal",
                          lineHeight: "normal",
                        }}
                      >
                        Invoice date
                      </p>
                      <p
                        style={{
                          fontWeight: 700,
                          lineHeight: "normal",
                          color: "#1A1C21",
                          lineHeight: "normal",
                        }}
                      >
                        {moment(location?.state?.activePlan?.createdAt).format(
                          "ll"
                        )}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <table style={{ width: "100%", borderSpacing: 0 }}>
                        <thead>
                          <tr style={{ textTransform: "uppercase" }}>
                            <td
                              style={{
                                padding: "8px 0",
                                borderBlock: "1px solid #D7DAE0",
                                lineHeight: "normal",
                              }}
                            >
                              Item Detail
                            </td>
                            <td
                              style={{
                                padding: "8px 0",
                                borderBlock: "1px solid #D7DAE0",
                                width: 40,
                                lineHeight: "normal",
                              }}
                            >
                              Qty
                            </td>
                            <td
                              style={{
                                padding: "8px 0",
                                borderBlock: "1px solid #D7DAE0",
                                textAlign: "end",
                                width: 100,
                                lineHeight: "normal",
                              }}
                            >
                              Rate {"  "}
                            </td>
                            <td
                              style={{
                                padding: "8px 0",
                                borderBlock: "1px solid #D7DAE0",
                                textAlign: "end",
                                width: 120,
                                lineHeight: "normal",
                              }}
                            >
                              Amount
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ paddingBlock: 12 }}>
                              <p
                                style={{
                                  fontWeight: 700,
                                  color: "#1A1C21",
                                  lineHeight: "normal",
                                }}
                              >
                                {location?.state?.activePlan?.Plan?.planName}
                              </p>
                            </td>
                            <td style={{ paddingBlock: 12 }}>
                              <p
                                style={{
                                  fontWeight: 700,
                                  color: "#1A1C21",
                                  padding: "8px 24px 8px 0",
                                  borderBlock: "1px solid #D7DAE0",
                                  textAlign: "end",
                                  width: 100,
                                  lineHeight: "normal",
                                }}
                              >
                                1
                              </p>
                            </td>
                            <td style={{ paddingBlock: 12, textAlign: "end" }}>
                              <p
                                style={{
                                  fontWeight: 700,
                                  color: "#1A1C21",
                                  lineHeight: "normal",
                                }}
                              >
                                $ {location?.state?.activePlan?.Plan?.price}
                              </p>
                            </td>
                            <td style={{ paddingBlock: 12, textAlign: "end" }}>
                              <p
                                style={{
                                  fontWeight: 700,
                                  color: "#1A1C21",
                                  lineHeight: "normal",
                                }}
                              >
                                $ {location?.state?.activePlan?.Plan?.price}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td
                              style={{
                                padding: "12px 0",
                                borderTop: "1px solid #D7DAE0",
                              }}
                            />
                            <td
                              style={{ borderTop: "1px solid #D7DAE0" }}
                              colSpan={3}
                            >
                              <table
                                style={{ width: "100%", borderSpacing: 0 }}
                              >
                                <tbody>
                                  <tr>
                                    <th
                                      style={{
                                        paddingTop: 12,
                                        textAlign: "start",
                                        color: "#1A1C21",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      Subtotal
                                    </th>
                                    <td
                                      style={{
                                        paddingTop: 12,
                                        textAlign: "end",
                                        color: "#1A1C21",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      ${" "}
                                      {location?.state?.activePlan?.Plan?.price}
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <th
                                      style={{
                                        padding: "12px 0 30px 0",
                                        textAlign: "start",
                                        color: "#1A1C21",
                                        borderTop: "1px solid #D7DAE0",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      Total Price (1)
                                    </th>
                                    <th
                                      style={{
                                        padding: "12px 0 30px 0",
                                        textAlign: "end",
                                        color: "#1A1C21",
                                        borderTop: "1px solid #D7DAE0",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      ${" "}
                                      {(location?.state?.activePlan?.Plan?.price).toFixed(
                                        2
                                      )}
                                    </th>
                                  </tr>
                                </tfoot>
                              </table>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td style={{ paddingTop: 30 }}>
              <p
                style={{
                  display: "flex",
                  gap: "0 13px",
                  lineHeight: "normal",
                }}
              >
                <span
                  style={{
                    color: "#1A1C21",
                    fontWeight: 700,
                    lineHeight: "normal",
                  }}
                >
                  Jubal Talent
                </span>
              </p>
              <p style={{ color: "#1A1C21", lineHeight: "normal" }}>
                Any questions, contact customer service at{" "}
                <a
                  href="mailto:support@mealshift.co.uk"
                  style={{ color: "#000" }}
                >
                  support@jubaltalent.com
                </a>
                .
              </p>
            </td>
            <button
              onClick={() => {
                generatePDF(pdfRef, {
                  filename: "bill.pdf",
                });
              }}
              className="btn btn-light end-0"
            >
              Download
            </button>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default PlanInvoiceBill;
