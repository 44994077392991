import React from "react";

function ProfileImageLoader() {
  return (
    <>
    <div className="head">
      <div className="image-skeleton"></div></div>
    </>
  );
}

export default ProfileImageLoader;
