import { useForm } from "react-hook-form";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { ApiUrl } from "../../utility/ApiUrl";
import { Link, useNavigate } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import Cookies from "js-cookie";
import { toast } from "material-react-toastify";
import { Provider, useDispatch } from "react-redux";
import { setUserLoggedInDetails } from "../../store/actions/UserActions";
import { CountryCode } from "../../utility/CountryCode";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getToken } from "firebase/messaging";
import { signInWithPopup } from "firebase/auth";
import { auth, messaging, provider } from "../../firebase";

const SignInMobileNumber = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [LoginType, setLoginType] = useState(true);
  const [FcmToken, setFcmToken] = useState("");
  const [ShowOtp, setShowOtp] = useState(false);
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [CountryCodeinput, SetCountryCodeinput] = useState("");
  const [otpValue, setOtpValue] = useState("");

  const schema = yup.object().shape({
    identifier: yup
      .string()
      .test(
        "is-email-or-username",
        "Invalid email or username",
        (value, context) => {
          const validationResult = validateEmailOrUsername(value);
          if (validationResult.isUsername) {
            context.parent.isUsername = true;
          } else {
            context.parent.isUsername = false;
          }
          return validationResult.isValid;
        }
      )
      .required("This field is required"),
  });

  const validateEmailOrUsername = (value) => {
    const emailPattern = /^(?!@)[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const usernamePattern = /^[a-zA-Z0-9@]{3,16}$/;
    if (value.startsWith("@")) {
      return {
        isValid: usernamePattern.test(value),
        isUsername: true,
      };
    }
    return {
      isValid: emailPattern.test(value) || usernamePattern.test(value),
      isUsername: !emailPattern.test(value),
    };
  };

  useEffect(() => {
    const getMessagingToken = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          const token = await getToken(messaging, {
            vapidKey:
              "BAbAvbRFKOI6MInjYj-nX8Y3Rx55Rq72uaW3_cp0tEBO5bAJPvKTLrK_mztp3eP4liPGVWKDWK3gqqoQelc2xHU",
          });
          if (token) setFcmToken(token);
        } else {
          console.log("Permission not granted for notifications");
        }
      } catch (error) {
        console.error("Error getting token:", error);
      }
    };
    getMessagingToken();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const {
    register: registerForm2,
    handleSubmit: handleSubmitForm2,
    formState: { errors: errorsForm2 },
  } = useForm();

  function generateRandomString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }

  const onSubmit = (data) => {
    alert("");
    setIsLoading(true);
    const loginData = {
      phone: "7687987654",
      countryCode: "+1",
      deviceId: `website-12345`,
      deviceToken: FcmToken || generateRandomString(),
      otp: data?.otp,
    };
    handleFetchRequest(ApiUrl?.login, "POST", loginData)
      .then((response) => {
        setIsLoading(false);
        if (response?.status === true) {
          Cookies.set("jubal_web_access", response.data?.accessToken);
          Cookies.set("jubal_web_refresh", response.data?.refreshToken);
          Cookies.set("jubal_web_data", JSON?.stringify(response?.data));
          dispatch(setUserLoggedInDetails(response?.data));
          navigate(RoutesLink?.dashboard);
          toast.success(`Welcome, ${response?.data?.username}!`);
        } else {
          toast.error(response?.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("An error occurred. Please try again.");
        console.error("Login error:", error);
      });
  };

  const onMobileNumberSubmit = (data) => {
    setPhoneNumber(data?.phone);
    SetCountryCodeinput(data?.country_code);
    const loginData = {
      deviceId: `website-12345`,
      deviceToken: FcmToken || generateRandomString(),
      phone: data?.phone,
      countryCode: data?.country_code,
    };
    handleFetchRequest(ApiUrl?.login, "POST", loginData).then((response) => {
      if (response?.status === true) {
        setShowOtp(true);
        setIsLoading(false);
        toast.success(response?.message);
        toast.info(response?.data?.otp);
      } else {
        setIsLoading(false);
        toast.error(response?.message);
      }
    });
  };

  const googleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const displayName = result?.user?.providerData[0]?.displayName;
      const nameParts = displayName.split(" ");
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(" ");
      const googleLoginData = {
        email: result?.user?.providerData[0]?.email,
        type: "1",
        deviceId: `website-12345`,
        deviceToken: FcmToken,
        firstName: firstName,
        lastName: lastName,
      };
      handleFetchRequest(
        ApiUrl?.social_google_login,
        "POST",
        googleLoginData
      ).then((response) => {
        if (response.status === true) {
          setIsLoading(false);
          toast.success(response?.message);
          Cookies.set("jubal_web_access", response.data?.accessToken);
          Cookies.set("jubal_web_refresh", response.data?.refreshToken);
          Cookies.set("jubal_web_data", JSON?.stringify(response?.data));
          dispatch(setUserLoggedInDetails(response?.data));
          navigate(RoutesLink?.dashboard);
        } else {
          setIsLoading(false);
          toast.error(response?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (window.AppleID) {
      window.AppleID.auth.init({
        clientId: "com.jubal.talent",
        scope: "jeetsharma753@gmail.com",
        redirectURI: "https://yourdomain.com/callback",
        usePopup: true,
      });
    }
  }, []);

  const handleAppleLogin = () => {
    if (window.AppleID) {
      window.AppleID.auth.signIn().then(
        (response) => {
          console.log("Apple Login Success:", response);
        },
        (error) => {
          console.error("Apple Login Error:", error);
        }
      );
    } else {
      console.error("AppleID SDK not loaded");
    }
  };

  const submitOtp = () => {
    setIsLoading(true);
    const loginData = {
      phone: PhoneNumber,
      countryCode: CountryCodeinput,
      deviceId: `website-12345`,
      deviceToken: FcmToken || generateRandomString(),
      otp: otpValue,
    };
    handleFetchRequest(ApiUrl?.login, "POST", loginData)
      .then((response) => {
        setIsLoading(false);
        if (response?.status === true) {
          Cookies.set("jubal_web_access", response.data?.accessToken);
          Cookies.set("jubal_web_refresh", response.data?.refreshToken);
          Cookies.set("jubal_web_data", JSON?.stringify(response?.data));
          dispatch(setUserLoggedInDetails(response?.data));
          navigate(RoutesLink?.dashboard);
          toast.success(`Welcome, ${response?.data?.username}!`);
        } else {
          toast.error(response?.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("An error occurred. Please try again.");
        console.error("Login error:", error);
      });
  };
  return (
    <>
      <Header />
      <section className="signin navbar-spc">
        <div className="max-width-container h-100">
          <div className="row h-100 justify-content-between justify-content-lg-center">
            <div className="col-12 col-md-5 col-lg-5 col-xl-6 p-0 d-none d-md-flex align-items-center justify-content-center position-relative">
              <div className="img-box">
                <img src="images/sign_in/frame.png" alt="" className="frame" />
                <img
                  src="images/sign_in/sign-img.png"
                  alt=""
                  className=" content-img"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-6 p-0 d-flex justify-content-center align-items-center">
              <div className="card signin-card">
                <div className="card-body">
                  <h3 className="text-jt-dark">Sign in to continue with us</h3>

                  {ShowOtp == false ? (
                    <form onSubmit={handleSubmitForm2(onMobileNumberSubmit)}>
                      <div className="row">
                        <div className="col-12 p-0">
                          <label htmlFor="contno" className="form-label">
                            Contact Number
                          </label>
                          <div className="numfield-wrapper">
                            <select
                              className="form-select nopre-drop position-relative z-3"
                              {...registerForm2("country_code", {
                                required: "Please enter country code",
                              })}
                            >
                              <option value="+1">US (+1)</option>
                              {CountryCode?.map((data, index) => (
                                <option key={index} value={data?.dial_code}>
                                  {data?.code} ({data?.dial_code})
                                </option>
                              ))}
                            </select>

                            <input
                              type="text"
                              className="form-control"
                              id="phone"
                              placeholder="Contact Number"
                              {...registerForm2("phone", {
                                required: "Please enter Phone Number",
                                minLength: {
                                  value: 10,
                                  message:
                                    "Phone number must be 10 digits long",
                                },
                                maxLength: {
                                  value: 10,
                                  message:
                                    "Phone number must be 10 digits long",
                                },
                                pattern: {
                                  value: /^[0-9]{10}$/,
                                  message: "Phone number must contain digits",
                                },
                              })}
                            />
                          </div>
                          <div class="form-text text-danger mt-2">
                            <span> {errorsForm2?.phone?.message}</span>
                          </div>
                        </div>

                        <div className="col-12 px-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            {isLoading ? <Spinner /> : "Sign in"}
                          </button>
                        </div>
                        <div className="col-12 p-0">
                          <Link
                            to={RoutesLink?.forgot_password}
                            className="forgopass d-flex justify-content-center"
                          >
                            Forgot your password?
                          </Link>
                        </div>
                        <div className="col-12 p-0 d-flex align-items-center justify-content-center gap-4">
                          <hr className="hr m-0" />
                          <p className="or">OR</p>
                          <hr className="hr m-0" />
                        </div>
                        <div className="col-12 p-0 d-flex align-items-center justify-content-center somdlogo-wrapper">
                          <Link
                            to="#"
                            onClick={() => toast?.info("Comming soon")}
                            className="somdlogo d-flex align-items-center justify-content-center"
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="100%"
                                height="100%"
                                viewBox="0 0 23 23"
                                fill="none"
                              >
                                <path
                                  d="M13.9385 4.93145L13.9473 4.92174C14.2651 4.57139 14.5063 4.1657 14.6582 3.72843C14.7505 3.46293 14.8088 3.18867 14.8325 2.91151C14.0555 3.0818 13.3527 3.48389 12.8294 4.06214L13.9385 4.93145ZM13.9385 4.93145L13.9302 4.94162M13.9385 4.93145L13.9302 4.94162M13.9302 4.94162C13.6454 5.29078 13.2789 5.57439 12.8579 5.76888C12.5837 5.89557 12.2916 5.98203 11.9915 6.02589C12.0091 5.74948 12.0632 5.47549 12.1528 5.21041C12.2952 4.78972 12.5248 4.39915 12.8293 4.0623L13.9302 4.94162ZM16.2327 12.0731H16.2327L16.2327 12.0796C16.2362 12.9721 16.513 13.8421 17.0262 14.5821C17.4491 15.1919 18.0166 15.6908 18.6773 16.0407C18.3979 16.7672 18.027 17.4595 17.5721 18.1022C17.1692 18.6573 16.7769 19.1688 16.3474 19.5466C15.9233 19.9196 15.5001 20.1292 15.035 20.1395C14.534 20.1505 14.1999 20.014 13.7537 19.8317L13.7365 19.8247C13.2698 19.6341 12.7169 19.4152 11.8835 19.4152C11.0542 19.4152 10.5135 19.6198 10.0467 19.8112C10.0232 19.8208 9.9999 19.8304 9.97689 19.8399C9.55788 20.0122 9.22541 20.1489 8.72209 20.1672C8.27229 20.1835 7.83846 19.9722 7.39391 19.5697C6.94756 19.1656 6.54474 18.6178 6.1615 18.0634L6.15646 18.0561L6.15116 18.049C5.35391 16.9787 4.64895 15.4409 4.34894 13.8385C4.04834 12.2329 4.16491 10.6265 4.92327 9.36532L4.92333 9.36535L4.92726 9.35857C5.27827 8.75355 5.7888 8.24323 6.41119 7.87941C7.03249 7.51624 7.74307 7.31211 8.47375 7.28838C8.992 7.28168 9.50419 7.44792 10.0362 7.64829C10.0953 7.67056 10.1556 7.6936 10.2165 7.71686C10.4169 7.79344 10.6238 7.87247 10.8169 7.93471C11.0696 8.01615 11.3477 8.086 11.6248 8.086C11.9114 8.086 12.2132 8.00037 12.4841 7.90858C12.6392 7.856 12.8197 7.78816 13 7.7204C13.1252 7.67333 13.2503 7.6263 13.3667 7.58445C13.9856 7.36203 14.6157 7.18757 15.2464 7.24944L15.2621 7.25098L15.2779 7.25153C15.8972 7.27294 16.5019 7.4321 17.0437 7.71532C17.4272 7.91576 17.7713 8.17418 18.0635 8.47879C17.5803 8.84601 17.1724 9.30062 16.8646 9.82C16.458 10.506 16.24 11.2815 16.2327 12.0731Z"
                                  fill="#020617"
                                  stroke="#020617"
                                />
                              </svg>
                            </span>
                          </Link>

                          <button
                            onClick={() => googleLogin()}
                            type="button"
                            className="somdlogo d-flex align-items-center justify-content-center"
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="100%"
                                height="100%"
                                viewBox="0 0 23 23"
                                fill="none"
                              >
                                <path
                                  d="M15.7976 12.4668H11.9554V10.9519H18.2485C18.2799 11.2013 18.2953 11.441 18.2953 11.6712C18.2953 12.9738 18.0106 14.1187 17.4524 15.119C16.8956 16.117 16.1036 16.8966 15.0654 17.4624C14.0295 18.0269 12.8307 18.3159 11.4554 18.3159C10.4761 18.3159 9.54769 18.1358 8.66624 17.7766C7.77721 17.4143 7.01821 16.9282 6.38313 16.3207C5.74823 15.7134 5.24233 14.9897 4.86592 14.1447C4.49304 13.3075 4.30664 12.4275 4.30664 11.4998C4.30664 10.5721 4.49304 9.69198 4.86592 8.85485C5.24233 8.00977 5.74823 7.28609 6.38313 6.6788C7.01821 6.07133 7.77721 5.58526 8.66623 5.22296C9.54769 4.86374 10.4761 4.68359 11.4554 4.68359C13.1339 4.68359 14.584 5.16197 15.8253 6.11732L14.4877 7.34773C13.6363 6.69889 12.6176 6.37952 11.4554 6.37952C10.5145 6.37952 9.63739 6.60731 8.83319 7.06393C8.03224 7.51871 7.39322 8.13992 6.92341 8.92284C6.45111 9.7099 6.21475 10.5723 6.21475 11.4998C6.21475 12.4273 6.45111 13.2896 6.92341 14.0767C7.39322 14.8596 8.03224 15.4808 8.83319 15.9356C9.63738 16.3922 10.5145 16.62 11.4554 16.62C12.077 16.62 12.6595 16.5379 13.1988 16.3683C13.7203 16.2043 14.1719 15.9924 14.5395 15.7231C14.8861 15.4692 15.1908 15.1781 15.4519 14.8499C15.7042 14.5327 15.9025 14.2173 16.0344 13.9038C16.1562 13.6143 16.2433 13.3295 16.2906 13.0504L16.3895 12.4668H15.7976Z"
                                  fill="#020617"
                                  stroke="#020617"
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <>
                      <h3 className="text-jt-dark">Enter Otp to continue</h3>
                      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                      <div className="row">
                        <div className="col-12 p-0">
                          <label for="fname" className="form-label">
                            Enter Otp
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="fname"
                            placeholder="otp"
                            value={otpValue}
                            onChange={(e) => setOtpValue(e.target.value)}
                          />
                        </div>
                        <div class="col-12 px-4">
                          <span
                            class="resend"
                            //   onClick={() =>
                            //     toast.info(location?.state?.otp_submit)
                            //   }
                          >
                            Resend code
                          </span>
                        </div>
                        <div class="col-12 px-4">
                          <button
                            type="button"
                            onClick={() => submitOtp()}
                            className="btn btn-primary w-100"
                          >
                            Continue
                          </button>
                        </div>
                        <div className="col-12 p-0">
                          <p className="pripoly text-center">
                            By signing up, you are creating a Jubal Talent
                            account and agree to Jubal Talent
                            <a href="#">Terms</a>and
                            <a href="#">Privacy Policy</a>
                          </p>
                        </div>
                      </div>
                      {/* </form> */}
                    </>
                  )}

                  <div className="pt-4">
                    <div className="col-12 px-4 py-2">
                      <Link
                        class="btn btn-light w-100"
                        to={RoutesLink?.sign_in}
                      >
                        Sign in with Email Address
                      </Link>
                    </div>
                    <div className="col-12 px-4 py-2">
                      <Link
                        to={RoutesLink?.talent_sign_up}
                        className="btn btn-purple w-100"
                      >
                        Sign up with Talent or Vendor
                      </Link>
                    </div>

                    <div className="col-12 px-4 py-2">
                      <Link
                        to={RoutesLink?.fan_main_sign_up}
                        className="btn btn-black w-100"
                      >
                        Not a talent? Join us as a Fan
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default SignInMobileNumber;
