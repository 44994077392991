import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import EventRequestDetail from "../../DashboardComponents/ManageRequest/EventRequestDetail";
import { useSelector } from "react-redux";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { toast } from "material-react-toastify";
import { ApiUrl } from "../../utility/ApiUrl";
import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import EventRequestDetail from "./EventRequestDetail";
import AgreementModal from "../../Components/Modals/AgreementModal";
import { Modal } from "reactstrap";
import RatingModal from "../../Components/Modals/RatingModal";

export default function RequestDetailPage() {
  const { id } = useParams();
  const location = useLocation();
  // const userInfo = useSelector((state) => state);
  const userInfo = useSelector((store) => store?.user?.userDetails);
  console.log("sttt", userInfo);
  const [bookingDetail, setBookingDetail] = useState({});

  const rating = bookingDetail?.Reviews?.length
    ? bookingDetail.Reviews.reduce((sum, review) => sum + review.rating, 0) /
      bookingDetail.Reviews.length
    : 0;

  const fetchBookingDetail = async () => {
    handleFetchRequest(ApiUrl?.get_hire_booking_detials + id).then((res) => {
      if (res?.status == true) {
        console.log("ress", res);
        setBookingDetail(res.data);
      } else {
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    fetchBookingDetail();
  }, []);

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />

      <section className="px-0 main-content">
        <div className="max-width-container">
          <div className="main-feed-grid">
            <DashboardSidebar />
            <EventRequestDetail bookingDetail={bookingDetail} rating={rating} />
            <AgreementModal />
          </div>
        </div>
      </section>
    </>
  );
}
