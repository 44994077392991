import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, ModalBody } from "react-bootstrap";
import Cropper from "react-cropper"; // Importing the Cropper library for image cropping
import "cropperjs/dist/cropper.css"; // Importing Cropper's CSS for styling
import "bootstrap/dist/css/bootstrap.min.css"; // Importing Bootstrap CSS for modal styling
import { Spinner } from "reactstrap";

// ImageCropper component to handle image cropping in a modal
const ImageCropper = ({
  show, // Boolean to control modal visibility
  handleClose, // Function to handle closing the modal
  image, // Image source URL for cropping
  onCrop, // Callback function to pass the cropped image
  width, // Desired cropped image width
  height, // Desired cropped image height
  ratio, // Aspect ratio for cropping
  customClass,
}) => {
  const cropperRef = useRef(null); // Ref to access the Cropper instance
  const [aspectRatio, setAspectRatio] = useState(1.3); // State to store aspect ratio for cropping
  const [src, setSrc] = useState(image); // State to store the image source for Cropper

  // Update the image source whenever the passed image prop changes
  useEffect(() => {
    setSrc(image);
  }, [image]);

  // Function to get the cropped image data and convert it to a file
  const getCropData = () => {
    if (cropperRef.current && cropperRef.current.cropper) {
      cropperRef.current.cropper
        .getCroppedCanvas({
          width: width, // Desired width of the cropped image
          height: height, // Desired height of the cropped image
        })
        .toBlob((blob) => {
          // Convert the cropped canvas to a Blob
          const file = new File([blob], "cropped_image.png", {
            type: "image/png", // Set the file type to PNG
          });
          onCrop(file); // Call the onCrop function to pass the cropped image
          handleClose(); // Close the modal after cropping
        }, "image/png");
    }
  };

  // Function to rotate the image 90 degrees

  return (
    <Modal show={show} size="xl">
      {" "}
      {/* Show modal if 'show' is true */}
      <div className="modal-content create-post post-preview">
        <div className="modal-body p-0">
          <div className="w-full h-full relative overflow-y-auto flex flex-col justify-between">
            <div className="">
              <ul className="d-flex align-items-center w-100 justify-content-between px-3 mb-3">
                <div>
                  {/* Close button to trigger handleClose */}
                  <button
                    onClick={() => handleClose()}
                    className="btn btn-close"
                  ></button>
                </div>
              </ul>
              <div className="mx-3">
                {image && (
                  // Cropper component to display and crop the image
                  <Cropper
                    src={src} // Image source for cropping
                    style={{ height: "400px", width: "100%" }} // Set cropper dimensions
                    aspectRatio={ratio} // Set aspect ratio for the crop box
                    guides={false} // Show grid guides inside the crop box
                    ref={cropperRef} // Assign Cropper instance to cropperRef
                    cropBoxResizable={false} // Disable resizing of the crop box
                    zoomable={true} // Enable zooming in the cropper
                    scalable={true} // Enable scaling of the cropper
                    key={aspectRatio} // Re-render the Cropper when aspectRatio changes
                    background={false} // Disable background of the cropper
                    autoCropArea={1} // Set auto crop area to full size
                    viewMode={1} // Restrict crop box within the container
                    dragMode="move" // Enable dragging the image within the cropper
                    // cropperCircleOverlay="true"
                    className={customClass}
                  />
                )}
              </div>
            </div>
            <div className="flex justify-between items-center p-3">
              {/* Button to save the cropped image */}
              <button
                onClick={(e) => getCropData(e)} // Call getCropData on click
                type="button"
                className="btn btn-primary d-block mx-auto w-25"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImageCropper;
