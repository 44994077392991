import React, { useEffect, useState } from "react";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import MobileHeader from "../../Components/Layout/MobileHeader";
import DashboardPostCard from "../../Components/Layout/DashboardPostCard";
import { useLocation, useParams } from "react-router-dom";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import CommentModal from "../../Components/Modals/CommentModal";
import LikesModal from "../../Components/Modals/LikesModal";

const SinglePost = () => {
  const location = useLocation(); // Hook to get the current location object
  const { id } = useParams(); // Get event ID from URL parameters

  const [postDetials, setPostDetials] = useState("");
  const [likeModalVisible, setLikeModalVisible] = useState(false);
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [currentPostdata, setcurrentPostdata] = useState(null);
  // Effect to fetch event details when the component mounts or when 'id' or 'location' changes
  useEffect(() => {
    // console.log("eventId", location?.state);
    let postId = id || location?.state; // Get event ID from URL or location state

    // Fetch event details from API
    handleFetchRequest(`${ApiUrl.single_post_detials}${postId}`)
      .then((response) => {
        if (response.status) {
          setPostDetials(response?.data); // Set fetched event details to state
        }
      })
      .catch((error) => {
        console.error("Error fetching event details:", error); // Log error
      });
  }, [id, location]); // Dependencies to run effect when 'id' or 'location' changes

  //   const LikeHandler = (data, like, type) => {
  //     const newData = DataMapHandler(
  //       postDetials,
  //       data?.id,
  //       "isLikedByMe",
  //       like,
  //       "Likes",
  //       like ? Number(data?.Likes) - 1 : Number(data?.Likes) + 1
  //     );
  //     setPostDetials(newData);

  //     const LikesData = {
  //       type: type,
  //       postId: data?.id,
  //     };

  //     handleFetchRequest(ApiUrl.post_like_comment, "POST", LikesData).then(
  //       (response) => {
  //         if (response.status !== true) {
  //           const newData = DataMapHandler(
  //             postDetials,
  //             data?.id,
  //             "isLikedByMe",
  //             like,
  //             "Likes",
  //             like ? Number(data?.Likes) - 1 : Number(data?.Likes) + 1
  //           );
  //           setPostDetials(newData);
  //         }
  //       }
  //     );
  //   };

  const LikeHandler = async (currentDetails) => {
    // Optimistically update the state immediately
    const newLikeStatus = currentDetails.isLikedByMe === true ? false : true; // Toggle like status
    const likeCountChange = currentDetails.isLikedByMe === true ? -1 : +1; // Adjust like count
    setPostDetials((prevDetails) => ({
      ...prevDetails,
      isLikedByMe: newLikeStatus, // Update like status in state
      likesCount: prevDetails.likesCount + likeCountChange, // Update like count in state
    }));
    // let apiUrl = ApiUrl?.post_like_comment + currentDetails?.id;
    // if (currentDetails.isLikedByMe === true) {
    //   apiUrl += "?type=remove"; // Add the 'type=remove' parameter if the current like status is true
    // }
    // const LikesData = {
    //   type: type,
    //   postId: data?.id,
    // };

    // Make the API call in the background
    // handleFetchRequest(apiUrl, "POST", LikesData).then((response) => {
    //   // If the API call fails, revert the state back
    //   if (!response.status) {
    //     // Revert state back to original if API fails
    //     setPostDetials((prevDetails) => ({
    //       ...prevDetails,
    //       isLikedByMe: currentDetails.isLikedByMe, // Revert like status
    //       likesCount: prevDetails.likesCount - likeCountChange, // Revert like count
    //     }));
    //   }
    // });
  };

  // Function to save/unsave posts
  const savePost = (data, save) => {
    // Optimistically update the state immediately
    const newLikeStatus = data.isSavedByMe === true ? false : true; // Toggle saved status

    setPostDetials((prevDetails) => ({
      ...prevDetails,
      isSavedByMe: newLikeStatus, // Update saved status in state
    }));

    // Determine the API endpoint based on the save flag
    const API = save
      ? `${ApiUrl.post_remove_save}${data?.id}`
      : `${ApiUrl.post_save}${data?.id}`;

    // Make the API call in the background
    handleFetchRequest(API).then((response) => {
      // If the API call fails, revert the state back
      if (!response.status) {
        // Revert the state back to original if the API call fails
        setPostDetials((prevDetails) => ({
          ...prevDetails,
          isSavedByMe: data.isSavedByMe, // Revert saved status
        }));
      }
    });
  };

  // Function to add comments to posts
  const commentPost = (id, comment, type, setCommentData) => {
    // const data = {
    //   postId: id,
    //   comment: comment,
    // };
    // handleFetchRequest(`${ApiUrl?.post_comment}`, type, data).then(
    //   (response) => {
    //     if (response.status === true) {
    //       const newData = DataMapHandler(
    //         postDetials,
    //         id,
    //         null,
    //         null,
    //         "Comments",
    //         Number(response?.data?.commentCount)
    //       );
    //       setPostDetials(newData);
    //       setCommentData("");
    //     }
    //   }
    // );
  };

  // Functions to handle modal visibility
  const handleLikeClick = (data) => {
    setcurrentPostdata(data);
    setLikeModalVisible(true);
  };

  const handleCommentClick = (data) => {
    setcurrentPostdata(data);
    setCommentModalVisible(true);
  };

  const closeModals = () => {
    setLikeModalVisible(false);
    setCommentModalVisible(false);
  };

  // Function to follow/unfollow a user
  const handleFollowClick = (postDetails) => {
    // Optimistically update the state immediately
    setPostDetials((prevDetails) => ({
      ...prevDetails,
      isFollowedByMe: !postDetails.isFollowedByMe, // Toggle follow status
    }));

    // Make the API call in the background
    handleFetchRequest(`${ApiUrl.Follow_user}${postDetails?.User?.id}`).then(
      (response) => {
        if (response.status === true) {
          // Update state with the follow status from the response
          setPostDetials((prevDetails) => ({
            ...prevDetails,
            isFollowedByMe: response.message === "FOLLOWED", // Set follow status based on response
          }));
        } else {
          // Revert the state if the API call fails
          setPostDetials((prevDetails) => ({
            ...prevDetails,
            isFollowedByMe: postDetails.isFollowedByMe, // Revert to original follow status
          }));
        }
      }
    );
  };

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />

      <section className="px-0 main-content">
        <div className="max-width-container">
          <div className="main-feed-grid">
            <DashboardSidebar />

            <div className="center-content d-block">
              <DashboardPostCard
                data={postDetials}
                index={postDetials?.id}
                LikeHandler={LikeHandler}
                savePost={savePost}
                onLikeClick={handleLikeClick}
                onCommentClick={handleCommentClick}
                onfollwuser={handleFollowClick}
                commentPost={commentPost}
                // likeComment={likeComment}
                // reportPost={reportPost}
                // reportUser={reportUser}
                showOtherOption={false}
              />
            </div>
          </div>
        </div>
      </section>
      {/* Render modals */}
      <CommentModal
        isOpen={commentModalVisible}
        onRequestClose={closeModals}
        PostId={currentPostdata?.id}
        postData={currentPostdata}
        commentPost={commentPost}
        likePost={LikeHandler}
        savePost={savePost}
      />

      <LikesModal
        isOpen={likeModalVisible}
        onRequestClose={closeModals}
        postData={currentPostdata}
        likePost={LikeHandler}
        savePost={savePost}
        handleFollowClick={handleFollowClick}
      />
    </>
  );
};

export default SinglePost;
