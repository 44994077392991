import { Modal, ModalBody, ModalHeader } from "reactstrap"; // Importing necessary components from reactstrap
import { ApiUrl } from "../../utility/ApiUrl"; // Importing API URL constants
import { useEffect, useMemo, useRef, useState } from "react"; // Importing React hooks
import { handleFetchRequest } from "../../ApiHelper/ApiHelper"; // Importing the API fetch helper function
import { calculateHours, getLoginUserCaption } from "../../Helper/Helper"; // Importing helper functions
import { SvgIcons } from "../Svg/SvgIcons"; // Importing SVG icons
import { Link } from "react-router-dom"; // Importing Link component for navigation
import { RoutesLink } from "../../utility/RoutesLink"; // Importing routes constants
import { useSelector } from "react-redux"; // Importing useSelector hook from Redux

const CommentModal = ({
  isOpen, // Boolean indicating if the modal is open
  onRequestClose, // Function to close the modal
  PostId, // ID of the post for which comments are displayed
  postData, // Data of the post containing comments
  likePost, // Function to handle liking a post
  savePost, // Function to handle saving a post
}) => {
  const [CommentList, setCommentList] = useState([]); // State for storing the list of comments
  const [CommentData, setCommentData] = useState(""); // State for storing input comment text
  const [likeState, setlikeState] = useState(null); // State to track like state
  const [subCommentActivity, setSubCommentActivity] = useState(false); // State to manage sub-comment visibility
  const [ReplayingPerson, setReplayingPerson] = useState(null); // State to track the person being replied to
  const [IsLoading, setIsLoading] = useState(true); // Loading state for comments
  const inputRef = useRef(null); // Reference for the comment input field
  const [visibleReplies, setVisibleReplies] = useState({}); // State to manage visibility of replies

  const userDetails = useSelector((store) => store?.user?.userDetails); // Fetching user details from Redux store
  const [expandedCommentIndex, setExpandedCommentIndex] = useState(null);
  const [showToggleButton, setShowToggleButton] = useState([]);

  const commentRefs = useRef([]);

  // const toggleText = (index) => {
  //   setExpandedCommentIndex(expandedCommentIndex === index ? null : index);
  // };

  // Effect to fetch comments when the modal opens
  useEffect(() => {
    if (isOpen) {
      handleFetchRequest(`${ApiUrl.get_posts_comment}${postData?.id}`).then(
        (response) => {
          if (response.status === true) {
            setCommentList(response.data?.comment); // Set fetched comments to state
            setIsLoading(false); // Set loading state to false
          }
        }
      );
    }
  }, [isOpen, postData, IsLoading]); // Dependencies for the useEffect hook

  // Function to fetch reply data for a specific comment
  const getReplayData = (replyIds) => {
    setSubCommentActivity(true); // Activate sub-comment activity
    handleFetchRequest(ApiUrl?.comment_detials + replyIds).then((res) => {
      if (res.status === true) {
        const currentIndex = CommentList.findIndex(
          (item) => item?.id == replyIds // Find the index of the comment being replied to
        );
        if (currentIndex > -1) {
          let currentComment = CommentList[currentIndex]; // Get the current comment
          currentComment["CommentReplay"] = res?.data?.comment; // Set the replies for the comment
          let comment_list = [...CommentList]; // Create a copy of the comment list
          comment_list[currentIndex] = currentComment; // Update the comment list with the new replies
          setCommentList(comment_list); // Update state with new comment list
        }
      }
    });
  };

  // Function to handle liking a post
  const like = async () => {
    const isLiked = postData?.isLikedByMe; // Check if the post is liked by the user
    postData.isLikedByMe = !isLiked; // Toggle like state
    postData.Likes += isLiked ? -1 : 1; // Update like count
    await likePost(postData, postData?.isLikedByMe, "POST"); // Call like post function
  };

  // Function to handle saving a post
  const save = async () => {
    const isSave = postData?.isSavedByMe; // Check if the post is saved by the user
    postData.isSavedByMe = !isSave; // Toggle save state
    await savePost(postData, postData?.isSavedByMe); // Call save post function
  };

  // Function to submit a comment
  const PostComment = () => {
    if (ReplayingPerson) {
      // If replying to a comment
      const data = {
        commentId: ReplayingPerson?.id, // ID of the comment being replied to
        comment: CommentData, // Comment text
      };
      handleFetchRequest(`${ApiUrl?.post_comment}`, "POST", data).then(
        (response) => {
          if (response.status === true) {
            setCommentData(""); // Clear the input field
            setIsLoading(true); // Set loading state to true
            setReplayingPerson(null); // Reset replying person
          }
        }
      );
    } else {
      // If posting a new comment
      const data = {
        postId: postData?.id, // ID of the post
        comment: CommentData, // Comment text
      };

      handleFetchRequest(`${ApiUrl?.post_comment}`, "POST", data).then(
        (response) => {
          if (response.status === true) {
            setCommentData(""); // Clear the input field
            setIsLoading(true); // Set loading state to true
          }
        }
      );
    }
  };

  // Function to map and update comment data based on conditions
  const DataMapHandler = (Arr, id, object, condition, objLabel, objCount) => {
    const newData = Arr.map((item) => {
      if (item?.id === id) {
        return {
          ...item,
          [objLabel]: objCount, // Update the specified label with the new count
          [object]: !condition, // Toggle the specified object
        };
      } else {
        return item; // Return the original item if no changes are needed
      }
    });
    return newData; // Return the updated array
  };

  // Function to handle liking a comment
  const LikeHandler = (data, like, type) => {
    const newData = DataMapHandler(
      CommentList,
      data?.id,
      "isLikedByMe", // Object to toggle
      like, // Current like state
      "Likes", // Label to update
      like ? Number(data?.Likes) - 1 : Number(data?.Likes) + 1 // Update like count
    );
    setCommentList(newData); // Update comment list state
    const LikesData = {
      type: "COMMENT",
      commentId: data?.id, // ID of the comment being liked
    };
    handleFetchRequest(ApiUrl.post_like_comment, type, LikesData).then(
      (response) => {
        if (response.status === false) {
          const newData = DataMapHandler(
            CommentList,
            data?.id,
            "isLikedByMe", // Object to toggle
            like, // Current like state
            "Likes", // Label to update
            like ? Number(data?.Likes) - 1 : Number(data?.Likes) + 1 // Update like count
          );
          setCommentList(newData); // Update comment list state
          setIsLoading(true); // Set loading state to false
        } else {
          setIsLoading(false);
        }
      }
    );
  };

  // Function to handle liking a reply comment
  const replyLikeHandler = (data, replyComment, like, type) => {
    data.isLikedByMe = !data.isLikedByMe; // Toggle like state for the reply comment

    const updatedCommentList = CommentList.map((comment) => {
      if (comment.id === data.id) {
        return {
          ...comment,
          isLikedByMe: !comment.isLikedByMe, // Toggle like state
          Likes: data.Likes + (comment?.isLikedByMe ? -1 : 1), // Update like count
        };
      }
      return comment; // Return original comment if no changes are needed
    });

    setCommentList(updatedCommentList); // Update comment list state
    const LikesData = {
      type: "COMMENT",
      commentId: data?.id, // ID of the reply comment
    };

    handleFetchRequest(ApiUrl.post_like_comment, "POST", LikesData).then(
      (response) => {
        if (response.status === true) {
          // Handle successful like response if needed
        }
      }
    );
  };

  // Calculate the duration since the post was created
  const duration = useMemo(
    () => calculateHours(postData?.createdAt),
    [postData?.updatedAt]
  );

  // Function to prepare for replying to a comment
  const commentReply = (data) => {
    inputRef.current.focus(); // Focus on the input field
    setReplayingPerson(data); // Set the person being replied to
  };

  // Function to hide replies for a comment
  const hideReply = (replyId) => {
    setSubCommentActivity(false); // Deactivate sub-comment activity
    setCommentList((prevCommentList) =>
      prevCommentList.map(
        (comment) =>
          comment.id === replyId ? { ...comment, CommentReplay: [] } : comment // Clear replies for the comment
      )
    );
    setVisibleReplies((prevState) => ({
      ...prevState,
      [replyId]: false, // Ensure the reply is hidden
    }));
  };

  const [overflowingIndexes, setOverflowingIndexes] = useState([]);

  useEffect(() => {
    const newOverflowingIndexes = CommentList.map((comment, index) => {
      const commentHeight = commentRefs.current[index]?.scrollHeight;
      const lineHeight = parseFloat(
        getComputedStyle(commentRefs.current[index]).lineHeight
      );
      const maxHeight = lineHeight * 2; // Two lines height
      return commentHeight > maxHeight; // Determine if comment overflows
    });
    setOverflowingIndexes(newOverflowingIndexes);
  }, [CommentList]);

  const toggleText = (index) => {
    setExpandedCommentIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  return (
    <>
      <>
        <div
        // className="modal fade"
        // id="likes"
        // tabIndex={-1}
        // aria-labelledby="likes"
        // aria-hidden="true"
        >
          <Modal
            isOpen={isOpen}
            contentLabel="Comments"
            overlayClassName="modal-overlay"
            className="modal-dialog modal-dialog-centered comments-post-dialog modal-dialog-scrollable"
            toggle={onRequestClose}
            // contentClassName="create-post comment-modal"
          >
            {/* <ModalHeader toggle={onRequestClose} className="center">
              <h3 className="text-center">Comments</h3>
            </ModalHeader> */}
            <div className="modal-content create-post comment-modal">
              <div className="modal-body p-0">
                <div className="row">
                  <div className="col-5 p-0 pe-2 d-none d-lg-block">
                    <div className="user-det-head d-flex justify-content-between align-items-start">
                      <div className="user-det d-flex gap-3">
                        <Link
                          to={
                            postData?.User?.id === userDetails?.id
                              ? RoutesLink?.MyProfile
                              : RoutesLink?.profile
                          }
                          state={{ id: postData?.User?.id }}
                          className="user-dp"
                        >
                          <img src={postData?.User?.profilePhoto} alt="" />
                        </Link>
                        <div className="user-ac">
                          <div className="d-flex gap-2 align-items-center">
                            <span className="username">
                              <Link
                                to={
                                  postData?.User?.id === userDetails?.id
                                    ? RoutesLink?.MyProfile
                                    : RoutesLink?.profile
                                }
                                state={{ id: postData?.User?.id }}
                              >
                                {postData?.User?.firstName}{" "}
                                {postData?.User?.lastName}
                              </Link>
                            </span>
                            {postData?.User?.type === 3 && (
                              <span className="usertype mx-1">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={13}
                                    height={13}
                                    viewBox="0 0 13 13"
                                    fill="none"
                                  >
                                    <circle
                                      cx="5.74979"
                                      cy="6.72714"
                                      r="3.75247"
                                      fill="white"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                      fill="#F4336D"
                                    />
                                  </svg>
                                </span>
                                Talent
                              </span>
                            )}
                            {/* <span className="ms-2 follow"> Follow</span> */}
                          </div>
                          <p>{getLoginUserCaption(postData?.User)}</p>
                        </div>
                      </div>
                      <div className="post-det d-flex align-items-center">
                        <span className="post-time">{duration}</span>
                      </div>
                    </div>
                    <div className="img-placeholder  d-flex justify-content-between align-items-start">
                      {postData?.mediaType === "photo" ? (
                        <div className="content">
                          <img
                            src={postData?.postImageUrl}
                            alt=""
                            className="w-100"
                          />
                        </div>
                      ) : (
                        <video width="100%" height="auto" autoPlay muted loop>
                          <source src={postData?.postImageUrl} />
                        </video>
                      )}
                    </div>
                    <div className="user-activity d-flex justify-content-between align-items-center">
                      <div className="d-flex">
                        <span className="liked">{postData?.Likes} likes</span>
                        {/* <span className="shared">4 Shared</span> */}
                      </div>
                      <div className="d-flex user-activity">
                        <span
                          className={
                            postData?.isLikedByMe ? "like activated" : "like"
                          }
                          onClick={() => like()}
                          // onClick={() =>
                          //   LikeHandler(postData, postData?.isLikedByMe, "POST")
                          // }
                        >
                          {SvgIcons.likeIcon}
                        </span>
                        <span
                          className={
                            postData?.isSavedByMe ? "save activated" : "save"
                          }
                          onClick={() => save()}
                        >
                          {SvgIcons.saveIcon}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-7 p-0 ps-lg-4 ps-xl-5">
                    <div className="row side-row px-1">
                      <div className="col-12 p-0">
                        <div className=" d-flex align-items-start justify-content-between">
                          <h2>{postData?.Comments} Comments</h2>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={onRequestClose}
                          ></button>
                        </div>
                        <div className="scrollable comment-scrollable">
                          {CommentList && CommentList.length === 0 ? (
                            <p className="text-center">No Comment Found</p>
                          ) : (
                            CommentList.map((res, index) => {
                              return (
                                <div
                                  // className="scrollable comment-scrollable"
                                  key={index}
                                >
                                  <div className="comment-area">
                                    <div className="user-det d-flex gap-3">
                                      {res?.User?.profilePhoto ? (
                                        <Link
                                          to={
                                            res?.User?.id === userDetails?.id
                                              ? RoutesLink?.MyProfile
                                              : RoutesLink?.profile
                                          }
                                          state={{ id: res?.User?.id }}
                                          className="user-dp"
                                        >
                                          <img
                                            src={res?.User?.profilePhoto}
                                            alt=""
                                          />
                                        </Link>
                                      ) : (
                                        <Link
                                          to={
                                            res?.User?.id === userDetails?.id
                                              ? RoutesLink?.MyProfile
                                              : RoutesLink?.profile
                                          }
                                          state={{ id: res?.User?.id }}
                                          className="user-dp no-img"
                                        >
                                          {res?.User?.firstName?.[0]}
                                        </Link>
                                      )}

                                      <div className="d-flex flex-column w-100 gap-3">
                                        <div className="d-flex justify-content-between w-100">
                                          <div className="user-ac">
                                            <div className="d-flex gap-2 align-items-center">
                                              <span className="username">
                                                <Link
                                                  to={
                                                    res?.User?.id ===
                                                    userDetails?.id
                                                      ? RoutesLink?.MyProfile
                                                      : RoutesLink?.profile
                                                  }
                                                  state={{ id: res?.User?.id }}
                                                >
                                                  {`${res?.User?.firstName}  ${res?.User?.lastName}`}
                                                </Link>
                                              </span>
                                              {res?.User?.type === 2 && (
                                                <span className="usertype vendor mx-1">
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width={13}
                                                      height={13}
                                                      viewBox="0 0 13 13"
                                                      fill="none"
                                                    >
                                                      <circle
                                                        cx="5.74979"
                                                        cy="6.72714"
                                                        r="3.75247"
                                                        fill="white"
                                                      />
                                                      <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                                        fill="currentColor"
                                                      />
                                                    </svg>
                                                  </span>
                                                  Vendor
                                                </span>
                                              )}

                                              {res?.User?.type === 3 && (
                                                <span className="usertype mx-1">
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width={13}
                                                      height={13}
                                                      viewBox="0 0 13 13"
                                                      fill="none"
                                                    >
                                                      <circle
                                                        cx="5.74979"
                                                        cy="6.72714"
                                                        r="3.75247"
                                                        fill="white"
                                                      />
                                                      <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                                        fill="#F4336D"
                                                      />
                                                    </svg>
                                                  </span>
                                                  Talent
                                                </span>
                                              )}
                                              <span className="post-time">
                                                {calculateHours(res?.createdAt)}
                                              </span>
                                            </div>
                                          </div>
                                          <a
                                            onClick={() =>
                                              LikeHandler(
                                                res,
                                                res?.isLikedByMe,
                                                "POST"
                                              )
                                            }
                                            className={
                                              res?.isLikedByMe
                                                ? "like activated"
                                                : "like"
                                            }
                                          >
                                            {SvgIcons?.likeIcon}
                                          </a>
                                        </div>
                                        <p
                                          ref={(el) =>
                                            (commentRefs.current[index] = el)
                                          }
                                          // className={`comment-text ${
                                          //   expandedCommentIndex === index
                                          //     ? "expanded"
                                          //     : ""
                                          // }`}
                                          style={{
                                            maxHeight:
                                              expandedCommentIndex === index
                                                ? "none"
                                                : "2.6em", // Adjust for two lines
                                            overflow: "hidden",
                                          }}
                                        >
                                          {res?.comment}
                                        </p>

                                        {overflowingIndexes[index] && (
                                          <span
                                            className="commented"
                                            onClick={() => toggleText(index)}
                                          >
                                            {expandedCommentIndex === index
                                              ? "Show less"
                                              : "Show more"}
                                          </span>
                                        )}
                                        <div className="d-flex gap-4">
                                          <span className="liked">
                                            {res?.Likes} likes
                                          </span>
                                          <span
                                            className="commented"
                                            onClick={() => commentReply(res)}
                                          >
                                            {res?.reply?.length} Reply
                                          </span>
                                          {res?.reply?.length > 0 && (
                                            <span
                                              className="commented"
                                              onClick={() =>
                                                getReplayData(res?.id)
                                              }
                                            >
                                              View Reply
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="replyed ps-3 ps-lg-5">
                                    {res?.CommentReplay &&
                                      res?.CommentReplay.length > 0 && (
                                        <div className="d-flex align-items-center gap-3 post-caption">
                                          <span className="icon">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="21"
                                              height="15"
                                              viewBox="0 0 21 15"
                                              fill="none"
                                            >
                                              <path
                                                d="M20.6166 8.04004L15.1558 3.17807C14.921 3.00091 14.6014 2.89298 14.2603 2.87562C13.9191 2.85826 13.5813 2.93274 13.3135 3.08433C13.0458 3.23592 12.8677 3.45356 12.8145 3.69421C12.7612 3.93485 12.8368 4.18096 13.0261 4.38384L16.7121 7.67053H4.53456C4.17248 7.67053 3.82524 7.56808 3.56922 7.38572C3.31319 7.20336 3.16936 6.95603 3.16936 6.69814V1.83616C3.16936 1.57827 3.02553 1.33094 2.7695 1.14858C2.51348 0.966218 2.16623 0.86377 1.80416 0.86377C1.44209 0.86377 1.09485 0.966218 0.838822 1.14858C0.582798 1.33094 0.438965 1.57827 0.438965 1.83616V6.69814C0.438965 7.47182 0.870464 8.21382 1.63854 8.7609C2.40661 9.30798 3.44834 9.61532 4.53456 9.61532H16.7121L13.0261 12.902C12.8029 13.1035 12.7006 13.3597 12.7415 13.6147C12.7824 13.8698 12.9632 14.1029 13.2445 14.2634C13.4847 14.4006 13.783 14.476 14.0909 14.4773C14.296 14.4766 14.4982 14.4431 14.6826 14.3791C14.8669 14.3151 15.0287 14.2224 15.1558 14.1078L20.6166 9.24581C20.807 9.07414 20.9106 8.86173 20.9106 8.64293C20.9106 8.42412 20.807 8.21171 20.6166 8.04004Z"
                                                fill="#86889A"
                                              />
                                            </svg>
                                          </span>
                                          <p
                                            className="para"
                                            onClick={() => hideReply(res?.id)}
                                          >
                                            Hide Reply
                                          </p>
                                        </div>
                                      )}
                                    {res?.CommentReplay &&
                                      res?.CommentReplay.length > 0 &&
                                      res?.CommentReplay.map((comment) => (
                                        <div className="comment-area">
                                          <div className="user-det d-flex gap-3">
                                            <div className="user-dp">
                                              <img
                                                src={
                                                  comment?.User?.profilePhoto
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div className="d-flex flex-column w-100 gap-3">
                                              <div className="d-flex justify-content-between w-100">
                                                <div className="user-ac">
                                                  <div className="d-flex gap-2 align-items-center">
                                                    <span className="username">
                                                      <Link to="#">
                                                        {
                                                          comment?.User
                                                            ?.firstName
                                                        }

                                                        {
                                                          comment?.User
                                                            ?.lastName
                                                        }
                                                      </Link>
                                                    </span>
                                                    {comment?.User?.type ===
                                                      2 && (
                                                      <span className="usertype vendor mx-1">
                                                        <span>
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={13}
                                                            height={13}
                                                            viewBox="0 0 13 13"
                                                            fill="none"
                                                          >
                                                            <circle
                                                              cx="5.74979"
                                                              cy="6.72714"
                                                              r="3.75247"
                                                              fill="white"
                                                            />
                                                            <path
                                                              fillRule="evenodd"
                                                              clipRule="evenodd"
                                                              d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                                              fill="currentColor"
                                                            />
                                                          </svg>
                                                        </span>
                                                        Vendor
                                                      </span>
                                                    )}
                                                    {comment?.User?.type ===
                                                      3 && (
                                                      <span className="usertype mx-1">
                                                        <span>
                                                          {SvgIcons?.talentIcon}
                                                        </span>
                                                        Talent
                                                      </span>
                                                    )}
                                                    <span className="post-time">
                                                      {calculateHours(
                                                        comment?.createdAt
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                                <span>
                                                  <Link
                                                    onClick={() =>
                                                      replyLikeHandler(
                                                        comment,
                                                        res?.Likes,
                                                        res?.isLikedByMe,
                                                        "POST"
                                                      )
                                                    }
                                                    className={
                                                      comment?.isLikedByMe
                                                        ? "like activated"
                                                        : "like"
                                                    }
                                                  >
                                                    {SvgIcons?.likeIcon}
                                                  </Link>
                                                </span>
                                              </div>
                                              <p className="two-line-ellipsis">
                                                {comment?.comment}
                                              </p>
                                              <div className="d-flex gap-4">
                                                <span className="liked">
                                                  {comment?.Likes} likes
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                      <div className="col-12 p-0 position-relative">
                        {ReplayingPerson && (
                          <p> Replying to {ReplayingPerson?.User?.firstName}</p>
                        )}
                        <textarea
                          rows={3}
                          placeholder="Add Comment"
                          defaultValue={""}
                          value={CommentData}
                          onChange={(e) => {
                            setCommentData(e.target.value);
                          }}
                          ref={inputRef}
                        />
                        {!CommentData ? (
                          <span className="emoji position-absolute">
                            {SvgIcons?.EmojiIcon}
                          </span>
                        ) : (
                          <button
                            className="btn btn-white"
                            style={{
                              fontSize: "14px",
                              position: "absolute",
                              right: 18,
                              bottom: 20,
                            }}
                            onClick={() => {
                              PostComment();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                            >
                              <path
                                d="M17.5 3.3125L9.25 11.5625"
                                stroke="#86889A"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M17.5 3.3125L12.25 18.3125L9.25 11.5625L2.5 8.5625L17.5 3.3125Z"
                                stroke="#86889A"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </>
    </>
  );
};
export default CommentModal;
