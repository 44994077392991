import React, { useEffect, useState } from "react";
import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import { Link, useLocation } from "react-router-dom";
import ChatMessage from "./ChatMessage";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { useSelector } from "react-redux";
import { getLoginUserCaption } from "../../Helper/Helper";

function Chat() {
  const location = useLocation(); // Access the current location state
  const [ChatList, setChatList] = useState([]); // State for the list of chat items
  const [filteredChatList, setFilteredChatList] = useState([]); // State for filtered chat list
  const [selectedChatData, setSelectedChatData] = useState(null); // State for selected chat data
  const [searchInput, setSearchInput] = useState(""); // State for search input
  const userDetails = useSelector((store) => store?.user?.userDetails); // Get user details from Redux store
  const [selectedChat, setSelectedChat] = useState(null); // State for selected chat
  const [isChatboxVisible, setIsChatboxVisible] = useState(false); // State to show/hide the chatbox

  // Function to handle showing the chatbox
  const handleUserNameClick = () => {
    setIsChatboxVisible(true); // Set chatbox visibility to true
  };

  // Function to handle hiding the chatbox
  const handleChatIconClick = () => {
    setIsChatboxVisible(false); // Set chatbox visibility to false
  };

  // useEffect to load initial chat data and chat status
  useEffect(() => {
    if (location?.state?.id) {
      // Check if there is an ID in the location state
      const data = {
        talentId: location?.state?.id, // Talent ID from location state
        vendorId: userDetails && userDetails?.id, // Vendor ID from user details
      };
      // Create a new chat session via API
      handleFetchRequest(ApiUrl?.create_chat, "POST", data).then((res) => {
        if (res.status == true) {
          setSelectedChat(res?.data); // Set the selected chat
          getMessage(res?.data); // Fetch messages for the selected chat
        }
      });
    }

    // Fetch chat status to get the list of chats
    handleFetchRequest(`${ApiUrl?.get_chat_status}`).then((res) => {
      if (res.status === true) {
        setChatList(res?.data?.chat); // Update ChatList state with chat data
        setFilteredChatList(res?.data?.chat); // Initialize the filtered list with all chats
      }
    });
    setSelectedChatData(null); // Reset selected chat data
  }, [userDetails]); // Depend on userDetails to re-run when it changes

  // useEffect to filter the chat list based on the search input
  useEffect(() => {
    if (searchInput === "") {
      setFilteredChatList(ChatList); // Show all chats if search input is empty
    } else {
      setFilteredChatList(
        ChatList.filter(
          (chat) =>
            (userDetails?.id == chat?.talent?.id
              ? chat?.vendor?.Profile?.firstName // Vendor's first name if user is talent
              : chat?.talent?.Profile?.firstName
            ) // Talent's first name if user is vendor
              ?.toLowerCase()
              .includes(searchInput.toLowerCase()) // Filter based on search input
        )
      );
    }
  }, [searchInput, ChatList, userDetails]); // Depend on searchInput, ChatList, and userDetails

  // Function to handle search input change
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value); // Update search input state
  };

  // Function to get message details of the selected chat
  const getMessage = (item) => {
    const obj = {
      id: userDetails?.id == item?.talentId ? item?.vendorId : item?.talentId, // Set ID based on user type
      first_name:
        userDetails?.id == item?.talentId
          ? item?.vendor?.Profile?.firstName // Vendor's first name if user is talent
          : item?.talent?.Profile?.firstName, // Talent's first name if user is vendor
      last_name:
        userDetails?.id == item?.talentId
          ? item?.vendor?.Profile?.lastName // Vendor's last name if user is talent
          : item?.talent?.Profile?.lastName, // Talent's last name if user is vendor
      profile_photo:
        userDetails?.id == item?.talentId
          ? item?.vendor?.Profile?.profilePhoto // Vendor's profile photo if user is talent
          : item?.talent?.Profile?.profilePhoto, // Talent's profile photo if user is vendor
    };

    setSelectedChatData(obj); // Update the selected chat data state
  };

  // Function to handle chat item click
  const handleChatClick = (res) => {
    setSelectedChat(res); // Set the selected chat
    getMessage(res); // Fetch message details for the selected chat
    handleUserNameClick(); // Show the chatbox
  };

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />

      <section className="px-0 main-content">
        <div className="max-width-container">
          <div className="main-feed-grid">
            <DashboardSidebar />
            <div className="center-content d-block">
              <div className="card tab-card activity-card manage-card">
                <div className="card-body p-0 position-relative">
                  <div className="chat-wrapper">
                    <div className="row">
                      <div className="col-12 p-0">
                        <div className="chat-area">
                          {/* chatlist */}
                          <div className="chatlist">
                            <div className="chat-header">
                              <div className="msg-search">
                                <div className="d-flex align-items-center passfield-wrapper position-relative">
                                  <span className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={18}
                                      height={18}
                                      viewBox="0 0 18 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
                                        fill="#818898"
                                      />
                                    </svg>
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="searchbar"
                                    placeholder="Search..."
                                    value={searchInput}
                                    onChange={handleSearchInputChange}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* chat-lists */}
                            <div className="chat-lists">
                              <div className="chat-list">
                                {filteredChatList &&
                                  filteredChatList.map((res, index) => (
                                    <div
                                      key={index}
                                      className={`user-name ${
                                        selectedChat?.id === res?.id
                                          ? "active"
                                          : ""
                                      }`} // Add active class conditionally
                                      onClick={() => handleChatClick(res)}
                                    >
                                      <div className="d-flex align-items-center gap-2 justify-content-between">
                                        <div className="user-det d-flex gap-2 gap-lg-3 align-items-center">
                                          {/* <div className="user-dp"> */}
                                          {userDetails?.id ==
                                          res?.talent?.id ? (
                                            res?.vendor?.Profile
                                              ?.profilePhoto ? (
                                              <div
                                                className="user-dp"
                                                onClick={() => getMessage(res)}
                                              >
                                                <img
                                                  src={
                                                    res?.vendor?.Profile
                                                      ?.profilePhoto
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                            ) : (
                                              <div className="user-dp no-img">
                                                {
                                                  res?.vendor?.Profile
                                                    ?.firstName?.[0]
                                                }
                                              </div>
                                            )
                                          ) : res?.talent?.Profile
                                              ?.profilePhoto ? (
                                            <div
                                              className="user-dp"
                                              onClick={() => getMessage(res)}
                                            >
                                              <img
                                                src={
                                                  res?.talent?.Profile
                                                    ?.profilePhoto
                                                }
                                                alt=""
                                              />
                                            </div>
                                          ) : (
                                            <div className="user-dp no-img">
                                              {
                                                res?.talent?.Profile
                                                  ?.firstName?.[0]
                                              }
                                            </div>
                                          )}
                                          {/* </div> */}
                                          <div className="user-ac">
                                            <div className="d-flex gap-2 align-items-center">
                                              <span className="username">
                                                <Link
                                                  onClick={() =>
                                                    getMessage(res)
                                                  }
                                                >
                                                  {userDetails?.id ==
                                                  res?.talent?.id
                                                    ? res?.vendor?.Profile
                                                        ?.firstName
                                                    : res?.talent?.Profile
                                                        ?.firstName}
                                                </Link>
                                              </span>
                                            </div>
                                            <p className="p-0">
                                              {userDetails?.type == 3
                                                ? getLoginUserCaption(
                                                    res?.vendor
                                                  )
                                                : getLoginUserCaption(
                                                    res?.talent
                                                  )}
                                            </p>
                                          </div>
                                        </div>
                                        {/* <span className="notif"> 1 </span> */}
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between">
                                        {/* <p>Hey, How are you</p> */}
                                        {/* <span className="timeby">
                                            {" "}
                                            5 min{" "}
                                          </span> */}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`chatbox ${
                              isChatboxVisible ? "showbox" : ""
                            }`}
                          >
                            <ChatMessage
                              chatclickData={selectedChatData}
                              handleChatIconClick={handleChatIconClick}
                              isChatboxVisible={isChatboxVisible}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Chat;
