import { Link, Routes } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { RoutesLink } from "../../utility/RoutesLink";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ApiUrl } from "../../utility/ApiUrl";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { CountryCode } from "../../utility/CountryCode";
import { toast } from "material-react-toastify";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Spinner } from "reactstrap";

// ForgotPassword component handles the user flow for resetting a password
const ForgotPassword = () => {
  const navigate = useNavigate(); // For navigation between routes
  const location = useLocation(); // To access current route information
  const [UserDetails, setUserDetails] = useState({}); // State to store user details
  const [ConfirmUser, setConfirmUser] = useState(false); // To check if the user is confirmed
  const [randomString, setRandomString] = useState(""); // Random string for generating device token
  const [identifier, setIdentifier] = useState(""); // Stores user's identifier (email or username)
  const [showPassword, setShowPassword] = useState(false); // For toggling password visibility
  const [otp, setOtp] = useState(null); // OTP received for verification
  const [loading, setLoading] = useState(false); // Loading state for initial form submission
  const [isLoading, setIsLoading] = useState(false); // Loading state for password reset submission

  // Validation schema for the identifier form (email or username)
  const identifierSchema = yup.object().shape({
    identifier: yup
      .string()
      .test(
        "is-email-or-username",
        "Invalid email or username",
        (value) => validateEmailOrUsername(value) // Custom test function for validation
      )
      .required("This field is required"), // Field is mandatory
  });

  // Validation schema for OTP and new password submission
  const otpPasswordSchema = yup.object().shape({
    Otp: yup
      .string()
      .required("Please enter OTP") // Field is mandatory
      .max(8, "Max 8 characters are allowed"), // Maximum length validation
    password: yup
      .string()
      .required("Please enter password") // Field is mandatory
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?=.{8,})/,
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one special character" // Password complexity rules
      ),
  });

  // React Hook Form setup for identifier submission form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(identifierSchema),
  });

  // React Hook Form setup for OTP and password reset form
  const {
    register: registerReset,
    handleSubmit: handleSubmitReset,
    formState: { errors: errorsReset },
    watch,
  } = useForm({
    resolver: yupResolver(otpPasswordSchema),
  });

  // Function to validate whether the input is an email or a username
  const validateEmailOrUsername = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for validating email
    const usernamePattern = /^[a-zA-Z0-9]{3,16}$/; // Regex for validating username

    // Returns true if input matches either pattern
    return emailPattern.test(value) || usernamePattern.test(value);
  };

  // Function to generate a random 12-character string for device token
  function generateRandomString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // State to toggle visibility of the confirm password field

  // Watch the password input for validation
  const password = watch("password");

  // Runs on component mount to generate the initial random string
  useEffect(() => {
    setRandomString(generateRandomString());
  }, []);

  // Toggles the visibility of the password field
  const handleShowPassword = () => {
    setShowPassword((pre) => !pre);
  };

  // Handles the form submission for the identifier (email or username)
  const onSubmit = (data) => {
    const containsAt = data?.identifier?.includes("@"); // Checks if identifier is an email
    let SubmitOTP = {};
    let submitData = {
      ...(containsAt
        ? { email: data?.identifier }
        : { username: data?.identifier }), // Submit email or username based on input
    };
    setLoading(true); // Set loading state to true
    // Make API request to check if the user exists
    handleFetchRequest(ApiUrl?.get_user, "POST", submitData).then(
      (response) => {
        if (response.data === null) {
          // If no user found, show error message
          toast.error("No User Found");
          setLoading(false);
        } else {
          // If user is found, proceed to confirm user
          setConfirmUser(true);
          setUserDetails(response?.data);
          setIdentifier(data.identifier); // Store the identifier in state
          SubmitOTP = {
            type: response?.data?.type,
            deviceToken: randomString, // FCM
            ...(containsAt
              ? { email: data?.identifier }
              : { username: data?.identifier }), // Submit email or username
            deviceId: `website-12345`,
          };
          // Send OTP to the user's email or phone
          handleFetchRequest(ApiUrl?.forgot_password, "POST", SubmitOTP).then(
            (res) => {
              if (res.status === true) {
                setLoading(false);
                toast.info(res?.data?.otp); // Show OTP for demonstration (usually not done in production)
                setOtp(res?.data?.otp); // Store the received OTP in state
              }
            }
          );
        }
      }
    );
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((pre) => !pre);
  };

  // Handles the form submission for resetting the password
  const onSubmitReset = (data) => {
    if (data?.Otp != otp) {
      // If the entered OTP is incorrect, show error message
      toast?.error("Please enter the correct OTP");
      return;
    }
    setIsLoading(true); // Set loading state to true
    const containsAt = identifier.includes("@"); // Check if identifier is an email
    let SubmitOTP = {
      type: UserDetails?.type,
      password: data.password,
      deviceToken: randomString, // FCM
      ...(containsAt ? { email: identifier } : { username: identifier }), // Submit email or username
      otp: data.Otp,
      deviceId: `website-12345`,
    };
    // Make API request to reset the password
    handleFetchRequest(ApiUrl?.forgot_password, "POST", SubmitOTP).then(
      (res) => {
        if (res.status === true) {
          toast.success(res?.message); // Show success message
          navigate(RoutesLink?.sign_in); // Navigate to sign-in page
          setIsLoading(false);
        } else {
          toast.error(res?.message); // Show error message if request fails
          setIsLoading(false);
        }
      }
    );
  };

  return (
    <>
      <Header />
      <section className="signin navbar-spc">
        <div className="max-width-container h-100">
          <div className="row h-100 justify-content-between justify-content-lg-center">
            <div className="col-12 col-md-5 col-lg-5 col-xl-6 p-0 d-none d-md-flex align-items-center justify-content-center position-relative">
              <div className="img-box">
                <img src="images/sign_in/frame.png" alt="" className="frame" />
                <img
                  src="images/sign_in/sign-img.png"
                  alt=""
                  className=" content-img"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-6 p-0 d-flex justify-content-center align-items-center">
              <div className="card signin-card">
                <div className="card-body">
                  <h3 className="text-jt-dark">Forgot your password</h3>

                  {!ConfirmUser ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-12 p-0">
                          <label for="Username" className="form-label">
                            Username / Email Address
                          </label>
                          <div className="d-flex align-items-center passfield-wrapper usricon">
                            <span className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                              >
                                <path
                                  d="M10.0075 10.9793C12.1463 10.9793 13.8616 9.24287 13.8616 7.12525C13.8616 5.00763 12.1463 3.25 10.0075 3.25C7.86874 3.25 6.15346 4.98645 6.15346 7.10407C6.15346 9.2217 7.86874 10.9793 10.0075 10.9793ZM10.0075 4.26646C11.5746 4.26646 12.8451 5.53703 12.8451 7.10407C12.8451 8.67111 11.5746 9.94169 10.0075 9.94169C8.44049 9.94169 7.16992 8.69229 7.16992 7.12525C7.16992 5.55821 8.44049 4.26646 10.0075 4.26646ZM3.31585 17.0145H16.7416C17.0169 17.0145 17.2498 16.7816 17.2498 16.5063C17.2498 13.8381 15.0687 11.657 12.4004 11.657H7.65697C4.98877 11.657 2.80762 13.8381 2.80762 16.5063C2.80762 16.7816 3.04056 17.0145 3.31585 17.0145ZM7.65697 12.6734H12.4004C14.3487 12.6734 15.9369 14.1134 16.191 15.9981H3.86643C4.12054 14.1134 5.70876 12.6734 7.65697 12.6734Z"
                                  fill="#818898"
                                />
                              </svg>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              id="Email"
                              placeholder="Email"
                              {...register("identifier")}
                            />
                          </div>
                          <div class="form-text text-danger mt-2">
                            {errors?.identifier?.message}
                          </div>
                          <div class="col-12 py-3">
                            <button
                              class="btn btn-primary w-100"
                              disabled={loading}
                            >
                              {loading ? <Spinner /> : "Continue"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <form onSubmit={handleSubmitReset(onSubmitReset)}>
                      <div className="row">
                        <div className="col-12 p-0">
                          <label for="Otp" className="form-label">
                            Please Enter OTP
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="Otp"
                            required
                            placeholder="Please Enter Otp"
                            {...registerReset("Otp", {
                              required: "Please enter otp",
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message: "Otp must contain digits",
                              },
                            })}
                          />
                          <div className="form-text mt-2">
                            {errorsReset?.Otp?.message}
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          <label for="password" className="form-label">
                            Enter New Password
                          </label>
                          <div className="d-flex align-items-center passfield-wrapper usricon">
                            <span className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                              >
                                <path
                                  d="M14.55 3.25L8.97495 8.8246C8.36983 8.64116 7.73016 8.60195 7.10717 8.71012C6.48417 8.81829 5.89516 9.07083 5.38729 9.44752C4.87943 9.82421 4.46682 10.3146 4.1825 10.8794C3.89819 11.4442 3.75007 12.0677 3.75 12.7C3.75 13.7741 4.1767 14.8043 4.93622 15.5638C5.69574 16.3233 6.72587 16.75 7.8 16.75C8.43233 16.7499 9.05585 16.6017 9.62062 16.3173C10.1854 16.033 10.6757 15.6203 11.0524 15.1124C11.429 14.6045 11.6815 14.0154 11.7896 13.3924C11.8977 12.7694 11.8585 12.1297 11.6749 11.5246L12.75 10.45V9.55H13.65L17.25 5.95V3.25H14.55ZM16.35 5.57695L13.2774 8.65H11.85V10.0769L11.0387 10.8878L10.6571 11.2694L10.8137 11.7856C10.9041 12.0835 10.95 12.3909 10.95 12.7C10.95 14.437 9.53655 15.85 7.8 15.85C6.06345 15.85 4.65 14.437 4.65 12.7C4.65 10.963 6.06345 9.55 7.8 9.55C8.1087 9.55 8.4165 9.5959 8.71395 9.6859L9.22965 9.8425L9.61125 9.4609L14.9226 4.15H16.35V5.57695Z"
                                  fill="#818898"
                                />
                                <path
                                  d="M6.9 14.5002C7.39706 14.5002 7.8 14.0973 7.8 13.6002C7.8 13.1031 7.39706 12.7002 6.9 12.7002C6.40294 12.7002 6 13.1031 6 13.6002C6 14.0973 6.40294 14.5002 6.9 14.5002Z"
                                  fill="#818898"
                                />
                                <path
                                  d="M12.5908 7.59111L15.2908 4.89111L15.609 5.20926L12.909 7.90926L12.5908 7.59111Z"
                                  fill="#818898"
                                />
                              </svg>
                            </span>
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              id="password"
                              required
                              placeholder="Password"
                              {...registerReset("password")}
                            />
                            <span className="visi">
                              {showPassword ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="20"
                                  viewBox="0 0 21 20"
                                  fill="none"
                                  onClick={handleShowPassword}
                                >
                                  <path
                                    d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="20"
                                  viewBox="0 0 21 20"
                                  fill="none"
                                  onClick={handleShowPassword}
                                >
                                  <path
                                    d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M3 3L18 17"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </span>
                          </div>
                          <div class="form-text text-danger mt-2">
                            {errorsReset?.password?.message}
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          <label for="cpassword" className="form-label">
                            Confirm Password
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <div className="d-flex align-items-center passfield-wrapper usricon">
                            <span className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                              >
                                <path
                                  d="M14.55 3.25L8.97495 8.8246C8.36983 8.64116 7.73016 8.60195 7.10717 8.71012C6.48417 8.81829 5.89516 9.07083 5.38729 9.44752C4.87943 9.82421 4.46682 10.3146 4.1825 10.8794C3.89819 11.4442 3.75007 12.0677 3.75 12.7C3.75 13.7741 4.1767 14.8043 4.93622 15.5638C5.69574 16.3233 6.72587 16.75 7.8 16.75C8.43233 16.7499 9.05585 16.6017 9.62062 16.3173C10.1854 16.033 10.6757 15.6203 11.0524 15.1124C11.429 14.6045 11.6815 14.0154 11.7896 13.3924C11.8977 12.7694 11.8585 12.1297 11.6749 11.5246L12.75 10.45V9.55H13.65L17.25 5.95V3.25H14.55ZM16.35 5.57695L13.2774 8.65H11.85V10.0769L11.0387 10.8878L10.6571 11.2694L10.8137 11.7856C10.9041 12.0835 10.95 12.3909 10.95 12.7C10.95 14.437 9.53655 15.85 7.8 15.85C6.06345 15.85 4.65 14.437 4.65 12.7C4.65 10.963 6.06345 9.55 7.8 9.55C8.1087 9.55 8.4165 9.5959 8.71395 9.6859L9.22965 9.8425L9.61125 9.4609L14.9226 4.15H16.35V5.57695Z"
                                  fill="#818898"
                                />
                                <path
                                  d="M6.9 14.5002C7.39706 14.5002 7.8 14.0973 7.8 13.6002C7.8 13.1031 7.39706 12.7002 6.9 12.7002C6.40294 12.7002 6 13.1031 6 13.6002C6 14.0973 6.40294 14.5002 6.9 14.5002Z"
                                  fill="#818898"
                                />
                                <path
                                  d="M12.5908 7.59111L15.2908 4.89111L15.609 5.20926L12.909 7.90926L12.5908 7.59111Z"
                                  fill="#818898"
                                />
                              </svg>
                            </span>
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              className="form-control"
                              id="cpassword"
                              placeholder="Password"
                              {...register("cpassword", {
                                required: "Please confirm your password",
                                validate: (value) => {
                                  console.log("Confirm Password Input:", value); // Log confirm password input
                                  return (
                                    value === password ||
                                    "Passwords do not match"
                                  );
                                },
                              })}
                            />

                            <span className="visi">
                              {showConfirmPassword ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="20"
                                  viewBox="0 0 21 20"
                                  fill="none"
                                  onClick={handleShowConfirmPassword}
                                >
                                  <path
                                    d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="20"
                                  viewBox="0 0 21 20"
                                  fill="none"
                                  onClick={handleShowConfirmPassword}
                                >
                                  <path
                                    d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M3 3L18 17"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </span>
                          </div>
                          <div className="form-text mt-2 text-danger">
                            {errorsReset?.cpassword?.message}
                          </div>
                        </div>
                        <div className="col-12 px-4">
                          <span
                            className="resend"
                            onClick={handleSubmit(onSubmit)}
                          >
                            Resend code
                          </span>
                        </div>
                        <div class="col-12 px-4">
                          <button class="btn btn-primary w-100">
                            {isLoading ? <Spinner /> : "Continue"}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}

                  {/* <div class="col-12 p-0">
                    <p class="pripoly text-center">
                      By signing up, you are creating a Jubal Talent account and
                      agree to Jubal Talent
                      <Link to={RoutesLink?.terms_conditions}>Terms</Link>and
                      <Link to={RoutesLink?.privacy_policy}>Privacy Policy</Link>
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ForgotPassword;
