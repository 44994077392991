/**
 * The ScrollToTop component in JavaScript React scrolls the window to the top whenever the pathname
 * changes.
 * @returns The ScrollToTop component is returning null.
 */

// Import useEffect hook from React for side effects
import { useEffect } from "react";
// Import useLocation hook from react-router-dom to get the current location
import { useLocation } from "react-router-dom";
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    // Scroll the window to the top-left corner (0, 0) when pathname changes
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// Export the ScrollToTop component for use in other parts of the application
export default ScrollToTop;
