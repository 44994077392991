export const FanQuestions = [
  {
    number: "01",
    id: "101",
    question: "What can I do as a fan on the platforms?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "02",
    id: "102",
    question:
      " Where can I get notifications about upcoming events held by my favourite artists?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "03",
    id: "103",
    question: "Can I buy music instruments from this platform?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem  venenatis urna.",
  },
  {
    number: "04",
    id: "104",
    question: "Does the Jubal platform provide privacy confirmation?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem  venenatis urna.",
  },
  {
    number: "05",
    id: "105",
    question: "From where can I report an issue?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "06",
    id: "106",
    question: "How can I save my favourite posts?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "07",
    id: "107",
    question: "From where can I buy tickets of the event?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec  diam. Volutpat sed orci turpis sit dolor est a   pretium eget. Vitae turpis orci vel tellus cursus   lorem vestibulum quis eu. Ut commodo, eget lorem  venenatis urna.",
  },
];

export const ArtistQuestions = [
  {
    number: "01",
    id: "201",
    question: "From where can I create my own artist account?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "02",
    id: "202",
    question: " Where can I list my music instrument items for sale?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "03",
    id: "203",
    question: "How can I collaborate with other artists?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem  venenatis urna.",
  },
  {
    number: "04",
    id: "204",
    question: "Is there any option for tracking performance of my posts?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem  venenatis urna.",
  },
  {
    number: "05",
    id: "205",
    question: "Where can I manage my events as an artist?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "06",
    id: "206",
    question: "Is there any virtual assistant to help schedule calendars?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "07",
    id: "207",
    question: "How can I enhance my artist profile?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec  diam. Volutpat sed orci turpis sit dolor est a   pretium eget. Vitae turpis orci vel tellus cursus   lorem vestibulum quis eu. Ut commodo, eget lorem  venenatis urna.",
  },
  {
    number: "08",
    id: "208",
    question: "From where can I manage my availability for bookings?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec  diam. Volutpat sed orci turpis sit dolor est a   pretium eget. Vitae turpis orci vel tellus cursus   lorem vestibulum quis eu. Ut commodo, eget lorem  venenatis urna.",
  },
  {
    number: "09",
    id: "209",
    question:
      "What benefits does Jubal Talent provide if I log in as an artist?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec  diam. Volutpat sed orci turpis sit dolor est a   pretium eget. Vitae turpis orci vel tellus cursus   lorem vestibulum quis eu. Ut commodo, eget lorem  venenatis urna.",
  },
];

export const VendorQuestions = [
  {
    number: "01",
    id: "301",
    question: "How can I be a vendor on the Jubal Talents ?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "02",
    id: "302",
    question: "What are the benefits of being a vendor on Jubal Talents?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "03",
    id: "303",
    question: "How can I book an artist for my event?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem  venenatis urna.",
  },
  {
    number: "04",
    id: "304",
    question: "From where can I buy an instrument if needed for my event?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem  venenatis urna.",
  },
  {
    number: "05",
    id: "305",
    question: "How can I transact money to the artist?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "06",
    id: "306",
    question: "How can I issue tickets to the fans of my events?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
];

export const EventQuestions = [
  {
    number: "01",
    id: "401",
    question: "From where can I find upcoming events on Jubal Talents?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "02",
    id: "402",
    question: "From where can I purchase tickets?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "03",
    id: "403",
    question: "How can I manage my upcoming events as an artist?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem  venenatis urna.",
  },
  {
    number: "04",
    id: "404",
    question: "How can I cancel the event as a vendor?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem  venenatis urna.",
  },
  {
    number: "05",
    id: "405",
    question: "From where can I gather feedback about the event?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "06",
    id: "406",
    question: "Can I rehire the talent I previously booked for an event?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
];

export const InstrumentQuestions = [
  {
    number: "01",
    id: "501",
    question: "How can I browse and purchase instruments on Jubal Instruments?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
  {
    number: "02",
    id: "502",
    question:
      "How do I list my musical instruments for sale on Jubal Instruments?",
    answer:
      "Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.",
  },
];
