const GbpSection = () => {
  return (
    <>
      <section className="gbp">
        <div className="max-width-container">
          <div className="row">
            <div className="col-12 p-0">
              <div className="row align-items-center">
                <div className="col-12 col-md-5 col-lg-6 d-flex justify-content-center p-0 order-2 order-md-1">
                  <div className="img-box">
                    <img src="images/side-img7.png" alt="" />
                  </div>
                </div>
                <div className="col-12 col-md-7 col-lg-6 order-1 order-md-2">
                  <div className="d-flex flex-column align-items-start">
                    <h3 className="h3">
                      Jubal Talents
                      <span className="keyw">Groundbreaking Platform</span>
                      designed to uplift musicians on a global scale
                    </h3>
                    <p className="para">
                      designed to uplift musicians on a global scale. Each one
                      of us has walked the challenging path of a music career,
                      facing its uncertainties and triumphs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default GbpSection;
