import React, { useEffect, useState } from "react";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";

function SharedAcitivity() {
  const [AllsharedPost, setAllsharedPost] = useState([]);
  const [filterOption, setFilterOption] = useState("Newest");

  useEffect(() => {
    handleFetchRequest(ApiUrl.shared_posts).then((response) => {
      if (response.status === true) {
        setAllsharedPost(response?.data?.posts);
        // setisLoading(false);
        console.log("setAllsharedPost", response?.data);
      }
    });
  }, [""]);

  const handleFilterChange = (option) => {
    setFilterOption(option);
  };

  const getSortedComments = () => {
    return [...AllsharedPost].sort((a, b) => {
      if (filterOption === "Newest") {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else if (filterOption === "Oldest") {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
      return 0;
    });
  };

  return (
    <div
      className="tab-pane fade "
      id="shared-tab-pane"
      role="tabpanel"
      aria-labelledby="shared-tab"
      tabIndex={0}
    >
      <div className="filter-row">
        <div className="d-flex align-items-center justify-content-between">
          <p>Shared Post</p>
          <div className="inner-filter-row">
            <div>
              <div className="dropdown dropdown-menu-end">
                <span className="selected"></span>
                <button
                  className="btn btn-light dropdown-toggle d-flex align-items-center gap-3"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="12"
                      viewBox="0 0 21 12"
                      fill="none"
                    >
                      <path
                        d="M8.07872 11.8284H12.6918V9.86084H8.07872V11.8284ZM0.00585938 0.0231934V1.99072H20.7646V0.0231934H0.00585938ZM3.46566 6.90955H17.3048V4.94202H3.46566V6.90955Z"
                        fill="#818898"
                      ></path>
                    </svg>
                  </span>
                  Filter by
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className={
                        filterOption === "Newest"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      href="#"
                      onClick={() => handleFilterChange("Newest")}
                    >
                      Newest
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        filterOption === "Oldest"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      href="#"
                      onClick={() => handleFilterChange("Oldest")}
                    >
                      Oldest
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row shared-row">
        {AllsharedPost && AllsharedPost.length === 0 ? (
          <div className="col-12 p-0">
            <p>No data found</p>
          </div>
        ) : (
          AllsharedPost &&
          getSortedComments().map((res, index) => {
            return (
              <div className="col-12 p-0" key={index}>
                <div className="comment-area">
                  <div className="user-det d-flex gap-3">
                    <div className="user-dp">
                      <img src={res?.sender?.Profile?.profilePhoto} alt="" />
                    </div>
                    <div className="d-flex flex-column flex-grow-1 gap-2 gap-md-3">
                      <div className="d-flex justify-content-between w-100">
                        <div className="user-ac">
                          <div className="d-flex gap-1 gap-md-2 align-items-baseline flex-wrap">
                            <span className="username">
                              <a href="#">
                                {res?.sender?.Profile?.firstName}{" "}
                                {res?.sender?.Profile?.lastName}
                              </a>
                            </span>

                            <span className="usertype mx-1">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={11}
                                  height={11}
                                  viewBox="0 0 11 11"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M10.3071 5.17529L9.62945 4.33449C9.52278 4.19645 9.45376 4.03331 9.43493 3.85135L9.31572 2.78466C9.29153 2.57167 9.19584 2.37317 9.04426 2.22159C8.89268 2.07002 8.69419 1.97432 8.48119 1.95014L7.41451 1.83092C7.22627 1.8121 7.06313 1.73053 6.92509 1.62386L6.08429 0.946198C5.73918 0.670115 5.24976 0.670115 4.90466 0.946198L4.06386 1.62386C3.92582 1.73053 3.76268 1.79955 3.58072 1.81837L2.51403 1.93759C2.07481 1.98778 1.7297 2.33289 1.67951 2.77211L1.56029 3.8388C1.54147 4.02704 1.4599 4.19018 1.35323 4.32822L0.675568 5.16902C0.399485 5.51412 0.399485 6.00354 0.675568 6.34864L1.35323 7.18944C1.4599 7.32749 1.52892 7.49062 1.54774 7.67259L1.66696 8.73927C1.71715 9.1785 2.06226 9.5236 2.50148 9.5738L3.56817 9.69302C3.75641 9.71184 3.91955 9.79341 4.05759 9.90008L4.89839 10.5777C5.24349 10.8538 5.73291 10.8538 6.07801 10.5777L6.91881 9.90008C7.05686 9.79341 7.22 9.72439 7.40196 9.70556L8.46864 9.58635C8.90787 9.53615 9.25297 9.19105 9.30317 8.75182L9.42239 7.68514C9.44121 7.4969 9.52278 7.33376 9.62945 7.19572L10.3071 6.35492C10.5832 6.00981 10.5832 5.52039 10.3071 5.17529ZM4.55328 8.27495L2.35717 6.07884L3.29836 5.13764L4.55328 6.39257L7.69059 3.25526L8.63178 4.22782L4.55328 8.27495Z"
                                    fill="#F4336D"
                                  />
                                </svg>
                              </span>
                              {res?.sender?.type === 2 ? "Talent" : "Vendor"}
                            </span>
                            <p>Shared a post with</p>
                            <span className="username">
                              <a href="#">You</a>
                            </span>
                            <span className="usertype mx-1">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={11}
                                  height={11}
                                  viewBox="0 0 11 11"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M10.3071 5.17529L9.62945 4.33449C9.52278 4.19645 9.45376 4.03331 9.43493 3.85135L9.31572 2.78466C9.29153 2.57167 9.19584 2.37317 9.04426 2.22159C8.89268 2.07002 8.69419 1.97432 8.48119 1.95014L7.41451 1.83092C7.22627 1.8121 7.06313 1.73053 6.92509 1.62386L6.08429 0.946198C5.73918 0.670115 5.24976 0.670115 4.90466 0.946198L4.06386 1.62386C3.92582 1.73053 3.76268 1.79955 3.58072 1.81837L2.51403 1.93759C2.07481 1.98778 1.7297 2.33289 1.67951 2.77211L1.56029 3.8388C1.54147 4.02704 1.4599 4.19018 1.35323 4.32822L0.675568 5.16902C0.399485 5.51412 0.399485 6.00354 0.675568 6.34864L1.35323 7.18944C1.4599 7.32749 1.52892 7.49062 1.54774 7.67259L1.66696 8.73927C1.71715 9.1785 2.06226 9.5236 2.50148 9.5738L3.56817 9.69302C3.75641 9.71184 3.91955 9.79341 4.05759 9.90008L4.89839 10.5777C5.24349 10.8538 5.73291 10.8538 6.07801 10.5777L6.91881 9.90008C7.05686 9.79341 7.22 9.72439 7.40196 9.70556L8.46864 9.58635C8.90787 9.53615 9.25297 9.19105 9.30317 8.75182L9.42239 7.68514C9.44121 7.4969 9.52278 7.33376 9.62945 7.19572L10.3071 6.35492C10.5832 6.00981 10.5832 5.52039 10.3071 5.17529ZM4.55328 8.27495L2.35717 6.07884L3.29836 5.13764L4.55328 6.39257L7.69059 3.25526L8.63178 4.22782L4.55328 8.27495Z"
                                    fill="#F4336D"
                                  />
                                </svg>
                              </span>
                              Talent
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <p>
                          Etiam ultricies ligula lorem vulputate velit hendrerit,
                          libero viverra felis ad aptent aenean
                        </p> */}
                    </div>
                    <div className="post-tumbnail">
                      {/* <img src={res?.Post?.postImageUrl} alt="" /> */}
                      {res.Post?.mediaType === "video" ? (
                        <video
                          src={res.Post?.postImageUrl}
                          autoPlay
                          muted
                          className="w-100"
                        />
                      ) : (
                        <img
                          src={res.Post?.postImageUrl}
                          alt={res.Post?.title}
                          className="w-100"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default SharedAcitivity;
