import { useDispatch } from "react-redux"; // Import the useDispatch hook to interact with Redux store
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook for programmatic navigation

// Define the EmptyCart component
const EmptyCart = ({ closeCartModal }) => {
  const navigate = useNavigate(); // Initialize the navigate function for routing
  const dispatch = useDispatch(); // Initialize the dispatch function to send actions to Redux

  // Function to handle the shop now button click
  const shopNow = () => {
    navigate("/instruments"); // Navigate to the instruments page
    dispatch(closeCartModal()); // Dispatch the action to close the cart modal
  };

  // Render the empty cart UI
  return (
    <div className="d-flex flex-column justify-content-center h-100 gap-4 align-items-center empty-cart">
      <div className="">
        <img src="images/empty-cart.svg" alt="Empty Cart" />
        {/* Image representing an empty cart */}
      </div>
      <div className="">
        <h2>Your basket is empty :(</h2>
        {/* Message indicating the cart is empty */}
        <p>Looks like you haven’t added anything to your cart yet!</p>
        {/* Instruction for the user */}
      </div>
      <div className="">
        <button className="btn btn-primary" onClick={shopNow}>
          {/* Button to navigate to the instruments page */}
          Shop now
        </button>
      </div>
    </div>
  );
};

// Export the EmptyCart component as the default export
export default EmptyCart;
