import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DashboardLayout from "../../Components/Layout/DashboardLayout";
import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import EventLoader from "../../Helper/EventLoader";
import DahboardEventCard from "../../Components/Layout/DahboardEventCard";
import { RoutesLink } from "../../utility/RoutesLink";
import ReactOwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import FilterDropdown from "../../Components/Modals/FilterDropdown";
import moment from "moment";

export default function EventsPage() {
  const [createPostModal, setCreatePostModal] = useState(false);
  const postToggle = () => setCreatePostModal(!createPostModal);

  const [loading, setLoading] = useState(true);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [filter, setFilter] = useState("Sort by");
  const [searchTerm, setSearchTerm] = useState(null);
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [nearbyEvents, setNearbyEvents] = useState([]);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [activeTabs, setActiveTabs] = useState([]);
  const [filterdata, setFilterdata] = useState({});
  const [maximumTicketPrice, setMaximumTicketPrice] = useState(null);
  const sortBy = {};
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("");
  const [eventfilter, setEventfilter] = useState({
    filterType: "",
    filtersubType: "",
  });
  // State for pagination
  const [page, setPage] = useState({
    totalpage: 0,
    page: 1,
  });

  const [filterState, setFilterState] = useState({
    genre: "",
    city: "",
    eventType: "",
    nearMe: "",
  });

  const filterArray = [
    {
      label: "Genre",
      value: "musicType",
    },
    {
      label: "Event Type",
      value: "EventType",
    },
    {
      label: "City & Zip code",
      value: "zipcode",
    },
  ];

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.log(error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const handleChange = (event) => {
    setTimeout(() => {
      setSearchTerm(event.target.value);
    }, 0.5);
  };

  const filterHandler = (value) => {
    console.log("VVVVVVVVVVVVVVVVVv", value);
    console.log(value);
    let url;
    let search;
    if (value?.eventType) {
      url = `?filter=genre`;
      search = `&search=${value?.genre}`;
      // url.push(`genre=${value.genre}`);
    } else if (value?.city) {
      // url.push(`city=${value?.city}`);
      url = `?filter=city`;
      search = `&search=${value?.city}`;
    } else if (value?.genre) {
      // url.push(`instrument=${value?.instrument}`);
      url = `?filter=instrument`;
      search = `&search=${value?.hasInst}`;
    } else {
      search = `?search=${searchTerm}`;
    }
    return value;
  };

  useEffect(() => {
    getEvents(filterState);
  }, [filterState, searchTerm, sortField, sortType]);

  const getEvents = async (filter, page) => {
    setLoading(true);
    if (filterState?.nearMe === true) {
      handleFetchRequest(
        `${ApiUrl.get_nearby}lat=28.1223&lng=73.23231&radius=300&search=`
      ).then((response) => {
        if (response.status === true) {
          setFilteredEvents(response?.data?.events);
          setLoading(false);
        }
      });
    } else {
      console.log(filter)
      const filteredObject = filterHandler(filter);
      console.log(filteredObject)

      let bodyData = {
        where: {
          visibilityType: "public",
          eventStatus: 3,
        },
        limit: 5,
        pageNumber: page, // Set the page number here
        showDeleted: false,
      };

      if (sortField) {
        bodyData.sortField = sortField;
      }

      if (sortType) {
        bodyData.sortType = sortType;
      }

      if (searchTerm) {
        bodyData.where.eventName = searchTerm;
      }
      console.log(filteredObject)

      if (filteredObject?.eventType) {
        bodyData.where["eventType"] = filteredObject.eventType;
      }
      if (filteredObject?.city) {
        bodyData.where["city"] = filteredObject.city;
      }
      if (filteredObject?.genre) {
        bodyData.where["musicType"] = filteredObject.genre;
      }
      if (filteredObject?.eventPricing) {
        bodyData.where["eventPricing"] = filteredObject.eventPricing;
      }
      if(filteredObject?.eventPricing == "paid") {
        bodyData.maxPrice = filteredObject?.TicketMaxPrice;
        bodyData.minPrice = 1;
      }
      if(filteredObject?.eventDate) {
        bodyData.eventDate = moment(filteredObject.eventDate).format('DD-MM-YYYY');
      }

      const options = {
        data: bodyData,
      };

      console.log(options);
      const delayDebounceFn = setTimeout(() => {
      handleFetchRequest(ApiUrl.get_event, "POST", options?.data).then(
        (res) => {
          if (res.status === true) {
            setLoading(false);
            setFilteredEvents((prevEvents) =>
              page === 1
                ? res?.data?.event
                : [...prevEvents, ...res?.data?.event]
            );
            setPage((prev) => ({
              ...prev,
              totalpage: res.data.totalPages, // Update total pages
            }));
            console.log("rrrrrrr", res?.data?.totalPages);
            setMaximumTicketPrice(res?.data?.maximumTicketPrice);
          } else {
            setLoading(false);
          }
        }
      );
    }, 300); // 300 ms debounce delay
  
    return () => clearTimeout(delayDebounceFn);
    }
  };

  const loadMoreEvents = () => {
    console.log("page.page < page.totalpage", page.page, page.totalpage);
    if (page.page < page.totalpage && !loading) {
      setPage((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
      console.log("gfgggg");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 200
      ) {
        loadMoreEvents();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page.page, loading]);

  useEffect(() => {
    getEvents(filterState, page.page);
  }, [filterState, searchTerm, page.page]);

  const dropdownRef = useRef(null);

  const handleOutside = () => {
    if (dropdownRef.current) {
      dropdownRef.current.classList.remove("active");
    }
  };

  const [genre, setGenre] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [zipcode, setZipcode] = useState([]);

  const fetchEvents = async () => {
    let bodyData = {
      where: {
        visibilityType: "public",
        eventStatus: 3,
      },
      limit: 5,
      showDeleted: false,
    };
    handleFetchRequest(ApiUrl.get_event, "POST", bodyData).then((res) => {
      if (res.status === true) {
        setEvents(res?.data?.event);
      }
    });
  };

  const fetchGenre = async () => {
    setLoading(true);
    handleFetchRequest(ApiUrl.get_music_type, "GET").then((res) => {
      if (res.status === true) {
        setLoading(false);
        setGenre(res?.data);
      }
    });
  };

  const fetchEventTypes = async () => {
    setLoading(true);
    handleFetchRequest(ApiUrl.event_type, "GET").then((res) => {
      if (res.status === true) {
        setLoading(false);
        setEventTypes(res?.data);
      }
    });
  };

  const fetchZipcode = async () => {
    setLoading(true);
    handleFetchRequest(ApiUrl.get_zipcode, "GET").then((res) => {
      if (res.status === true) {
        setLoading(false);
        setZipcode(res?.data.zipcode);
      }
    });
  };

  const DataMapHandler = (Arr, id, object, condition, objLabel, objCount) => {
    console.log("eventdata", Arr, id, object, condition, objLabel, objCount);
    const newData = Arr.map((item) => {
      if (item?.User?.id === id) {
        return {
          ...item,
          [objLabel]: objCount,
          [object]: !condition,
        };
      } else {
        return item;
      }
    });
    return newData;
  };

  const LikeHandler = async (data, like, type) => {
    console.log(data);
    console.log(like);
    const updatedLikeCount = like
      ? Number(data?.likes) - 1
      : Number(data?.likes) + 1;

    const newData = DataMapHandler(
      filteredEvents,
      data?.id,
      "isLikedByMe",
      like,
      "like",
      updatedLikeCount
    );
    setFilteredEvents(newData);
    console.log("Faffafsa", filteredEvents);
    handleFetchRequest(ApiUrl.event_like + data.id, "GET").then((response) => {
      if (response.status !== true) {
        const newData = DataMapHandler(
          filteredEvents,
          data?.id,
          "isLikedByMe",
          like,
          "like",
          like ? Number(data?.like) - 1 : Number(data?.like) + 1
        );
        setFilteredEvents(newData);
      }
    });
  };

  const savePost = (data, save) => {
    handleFetchRequest(ApiUrl.event_save + data.id).then((response) => {
      if (response.status === true) {
        const newData = DataMapHandler(
          filteredEvents,
          data?.id,
          "isSavedByMe",
          save
        );
        setFilteredEvents(newData);
      }
    });
  };

  const handleFollowClick = (data) => {
    // Ensure the follow/unfollow request is handled properly
    handleFetchRequest(`${ApiUrl.Follow_user}${data?.User?.id}`)
      .then((response) => {
        if (response.status === true) {
          // Toggle the follow state based on the response
          const isFollowing = response?.message === "FOLLOWED"; // Adjust based on actual response structure
          const updatedData = {
            ...data,
            isFollowedByMe: isFollowing, // Update the follow state in the object
          };

          // Update the state to reflect the new follow/unfollow state
          setFilteredEvents((prevState) =>
            prevState.map((user) =>
              user.User.id === updatedData.User.id ? updatedData : user
            )
          );
        }
      })
      .catch((error) => {
        console.error("Error following/unfollowing user:", error);
      });
  };

  const [searchInput, setSearchInput] = useState("");

  // Function to handle the input change
  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const getNearbyEvents = async () => {
    handleFetchRequest(
      `${ApiUrl.get_nearby}lat=28.1223&lng=73.23231&radius=300&search=`
    ).then((response) => {
      if (response.status === true) {
        setNearbyEvents(response?.data?.events);
      }
    });
  };

  const removeFilterHandler = (tabId, keypair) => {
    // console.log(tabId);
    console.log(keypair);
    setFilterdata((prev) => {
      const updatedFilter = { ...prev };
      delete updatedFilter[keypair];
      return updatedFilter;
    });

    setFilterState((prev) => {
      const updatedFilter = { ...prev };
      delete updatedFilter[keypair];
      return updatedFilter;
    });

    setActiveTabs((prev) => prev.filter((tab) => tab !== tabId));
  };

  const reset = () => {
    setFilterdata({});
    setActiveTabs([]);
    setFilterState({});
  };

  useEffect(() => {
    fetchGenre();
    fetchEventTypes();
    fetchZipcode();
    fetchEvents();
    getNearbyEvents();
  }, []);


  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <DashboardLayout>
        <DashboardSidebar postToggle={postToggle} />

        <div className="center-content">
          <div className="feed">
            <div className="filter-row position-relative mt-0">
              <div className="d-flex align-item-center gap-4 flex-wrap flex-lg-nowrap justify-content-between w-100">
                <div
                  className="input-group search-group"
                  onClick={handleOutside}
                >
                  <input
                    type="text"
                    className="form-control"
                    id="searchbar"
                    placeholder="Search Events"
                    onChange={handleChange}
                  />
                  <button
                    className="btn btn-secondary"
                    type="button"
                    id="button-addon2"
                  >
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={18}
                        height={18}
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
                          fill="#343B4E"
                        />
                      </svg>
                    </span>
                  </button>
                </div>

                <div className="inner-filter-row flex-sm-nowrap">
                  <div>
                    <FilterDropdown
                      events={filteredEvents}
                      genre={genre}
                      eventTypes={eventTypes}
                      setFilteredEvents={setFilteredEvents}
                      setfilterData={setEventfilter}
                      eventfilter={eventfilter}
                      getEvents={getEvents}
                      zipcode={zipcode}
                      filterArray={filterArray}
                      setFilterState={setFilterState}
                      dropdownRef={dropdownRef}
                      filter={filterdata}
                      setFilter={setFilterdata}
                      activeTabs={activeTabs}
                      setActiveTabs={setActiveTabs}
                      nearMe={true}
                      removeHandler={removeFilterHandler}
                      maximumTicketPrice={maximumTicketPrice}
                    />
                  </div>

                  <div
                    className="dropdown dropdown-menu-end"
                    onClick={handleOutside}
                  >
                    <button
                      className="btn btn-light dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="15"
                          viewBox="0 0 19 15"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.0104 6.1634C17.713 6.4518 17.2382 6.4445 16.9499 6.1471L14.2383 3.3508V13.5C14.2383 13.9142 13.9025 14.25 13.4883 14.25C13.0741 14.25 12.7383 13.9142 12.7383 13.5V3.3508L10.0267 6.1471C9.7384 6.4445 9.26359 6.4518 8.96619 6.1634C8.66879 5.8751 8.6615 5.40025 8.9499 5.10289L12.9499 0.97789C13.0911 0.83222 13.2854 0.75 13.4883 0.75C13.6912 0.75 13.8855 0.83222 14.0267 0.97789L18.0267 5.10289C18.3151 5.40026 18.3078 5.8751 18.0104 6.1634Z"
                            fill="#AAAAAA"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.0104 8.8366C9.71298 8.5482 9.23818 8.5555 8.94988 8.8529L6.23828 11.6492V1.5C6.23828 1.08579 5.90249 0.75 5.48828 0.75C5.07407 0.75 4.73828 1.08579 4.73828 1.5V11.6492L2.02671 8.8529C1.73835 8.5555 1.26353 8.5482 0.966172 8.8366C0.668812 9.1249 0.661502 9.5997 0.949852 9.8971L4.94985 14.0221C5.09111 14.1678 5.28537 14.25 5.48828 14.25C5.69119 14.25 5.88545 14.1678 6.0267 14.0221L10.0267 9.8971C10.3151 9.5997 10.3078 9.1249 10.0104 8.8366Z"
                            fill="#111111"
                          />
                        </svg>
                      </span>
                      {" " + filter}
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          to="#"
                          className={
                            filter === "A to Z"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          // onClick={() => handleFilterChange("A to Z")}
                          onClick={() => {
                            // setSortBy("sortField", "eventName", "A to Z")
                            setSortField("eventName");
                            setSortType("ASC");
                            setFilter("A to Z");
                          }}
                        >
                          A to Z
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            filter === "Newest"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to="#"
                          // onClick={() => handleFilterChange("Newest")}
                          onClick={
                            () => {
                              setFilter("Newest");
                              setSortField("eventTimeLocation.start_date");
                              setSortType("DESC");
                            }
                            // setSortBy("sortType", "DESC", "Newest")
                          }
                        >
                          Newest
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            filter === "Oldest"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to="#"
                          // onClick={() => handleFilterChange("Oldest")}
                          onClick={
                            async () => {
                              setFilter("Oldest");
                              setSortField("eventTimeLocation.start_date");
                              setSortType("ASC");
                            }
                            // await setSortBy("sortType", "ASC", "Oldest")
                          }
                        >
                          Oldest
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            filter === "Price high.."
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to="#"
                          // onClick={() =>
                          //   handleFilterChange("Price High to Low")
                          // }
                          onClick={
                            () => {
                              setFilter("Price high..");
                              setSortField("eventPricing.price");
                              setSortType("DESC");
                            }
                            // setSortBy("sortType", "DESC", "Price High to Low")
                          }
                        >
                          Price High to Low
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            filter === "Price low.."
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to="#"
                          // onClick={() =>
                          //   handleFilterChange("Price Low to High")
                          // }
                          onClick={
                            () => {
                              setFilter("Price low..");
                              setSortField("eventPricing.price");
                              setSortType("ASC");
                            }
                            // setSortBy("sortType", "DESC", "Price Low to High")
                          }
                        >
                          Price Low to High
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {Object.entries(filterState).some(
              ([key, value]) =>
                value !== undefined &&
                value !== null &&
                value !== "" &&
                (typeof value === "string"
                  ? value.trim() !== ""
                  : value !== false)
            ) && (
              <div className="d-flex mt-2 mb-4 justify-content-between align-items-center result-desc">
                <div className="d-flex  align-items-center gap-3 flex-wrap">
                  <span className="fltr-rs">
                    Filter results:{" "}
                    <span className="num">{filteredEvents?.length}</span>
                  </span>
                  {Object.entries(filterState).map(
                    ([key, value]) =>
                      value !== undefined &&
                      value !== null &&
                      value !== "" &&
                      (typeof value === "string"
                        ? value.trim() !== ""
                        : value !== false) && (
                        <span key={key} className="keyword">
                          {/* {key} */}
                          { key === "city"
                                  ? "City"
                                  : key === "eventType"
                                  ? "Event type"
                                  : key === "genre"
                                  ? "Genre"
                                  : key === "nearMe"
                                  ? "Near me"
                                  : key === "eventPricing" 
                                  ? "Price"
                                  : key === "TicketMaxPrice" 
                                  ? `$1 - $${filterState?.TicketMaxPrice}`
                                  : key === "eventDate" ? 
                                  moment(filterState?.eventDate).format('DD MMM, YYYY')
                                  : 
                                key}
                          
                          {/* : <span className="num">20</span> */}
                          <span
                          style={{cursor: "pointer"}}
                            onClick={() =>
                              removeFilterHandler(
                                key === "city"
                                  ? 3
                                  : key === "eventType"
                                  ? 2
                                  : key === "genre"
                                  ? 1
                                  : key === "nearMe"
                                  ? 11
                                  : key === "eventPricing" ? 
                                  15
                                  : key === "eventDate" ? 
                                  16
                                  : "",
                                key
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                            >
                              <path
                                d="M0.232911 0.223116C0.0898854 0.366021 0.0095379 0.559815 0.0095379 0.761882C0.0095379 0.963948 0.0898854 1.15774 0.232911 1.30065L2.9306 3.99524L0.232911 6.68983C0.160044 6.76012 0.101923 6.84421 0.0619392 6.93718C0.0219553 7.03015 0.000909077 7.13015 2.88057e-05 7.23133C-0.000851466 7.33252 0.0184516 7.43286 0.056812 7.52651C0.0951724 7.62017 0.151822 7.70525 0.223455 7.7768C0.295087 7.84835 0.380269 7.90494 0.474029 7.94325C0.56779 7.98157 0.668251 8.00085 0.769551 7.99997C0.870852 7.99909 0.970962 7.97807 1.06404 7.93813C1.15712 7.89819 1.24131 7.84014 1.31168 7.76736L4.00937 5.07277L6.70707 7.76736C6.85096 7.90617 7.04367 7.98298 7.24371 7.98124C7.44374 7.97951 7.63509 7.89936 7.77654 7.75807C7.918 7.61678 7.99823 7.42565 7.99997 7.22585C8.00171 7.02604 7.92481 6.83355 7.78584 6.68983L5.08815 3.99524L7.78584 1.30065C7.92481 1.15692 8.00171 0.96443 7.99997 0.764624C7.99823 0.564818 7.918 0.373688 7.77654 0.232399C7.63509 0.0911098 7.44374 0.0109662 7.24371 0.00922999C7.04367 0.00749373 6.85096 0.0843039 6.70707 0.223116L4.00937 2.91771L1.31168 0.223116C1.16861 0.0802551 0.974596 0 0.772297 0C0.569997 0 0.37598 0.0802551 0.232911 0.223116Z"
                                fill="#86889A"
                              ></path>
                            </svg>
                          </span>
                        </span>
                      )
                  )}
                </div>
                <button onClick={reset} className="btn btn-link">
                  Clear Filters
                </button>
              </div>
            )}

            {loading && <EventLoader />}
            <div onClick={handleOutside}>
              {filteredEvents?.map((evt) => {
                return (
                  <DahboardEventCard
                    event={evt}
                    LikeHandler={LikeHandler}
                    savePost={savePost}
                    onfollwuser={handleFollowClick}
                  />
                );
              })}
              {filteredEvents?.length < 1 && !loading && (
                <p className="form-text text-center">No events found!</p>
              )}
            </div>
          </div>
          <div className="side-content-right">
            <div className="card featured-card event-card-side">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 card-head border-0">
                    <div className="d-flex justify-content-between">
                      <h5>Near by Events</h5>
                      <Link to={RoutesLink.allEvents}>Show more</Link>
                    </div>
                  </div>
                  {/* {console.log("AllEvents", AllEvents)} */}
                  {nearbyEvents &&
                    nearbyEvents.slice(0, 3).map((res, index) => {
                      return (
                        <div className="col-12 px-0" key={index}>
                          <Link
                            to={RoutesLink?.EventDetails}
                            state={res?.id}
                            className="event-info"
                          >
                            <div className="d-flex align-items-center gap-2 gap-md-3">
                              <img src={res?.eventImage} alt="" />
                              <div>
                                <span>{res?.eventName}</span>
                                <h6>{res?.eventDescription}</h6>
                                <p>{res?.eventTimeLocation?.location}</p>
                                <p className="distance">
                                  {res?.distance < 1000
                                    ? `${res?.distance.toFixed(0)} m`
                                    : `${(res?.distance / 1000).toFixed(
                                        2
                                      )} km`}{" "}
                                  from you
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  {nearbyEvents?.length < 1 && (
                    <p className="form-text">No event found!</p>
                  )}
                </div>
              </div>
            </div>

            <div className="card featured-card event-card-side">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 card-head border-0">
                    <div className="d-flex justify-content-between">
                      <h5>Jubal Events</h5>
                      <Link to={RoutesLink.allEvents}>Show more</Link>
                    </div>
                  </div>
                  {/* {console.log("AllEvents", AllEvents)} */}
                  {events &&
                    events.slice(0, 3).map((res, index) => {
                      return (
                        <div className="col-12 px-0" key={index}>
                          <Link
                            to={RoutesLink?.EventDetails}
                            state={res?.id}
                            className="event-info"
                            key={index}
                          >
                            <div className="d-flex align-items-center gap-2 gap-md-3">
                              <img
                                src={res?.eventImage}
                                className="Event-image"
                                style={{ borderRadius: 5 }}
                                alt=""
                              />
                              <div>
                                <span>{res.eventName}</span>
                                <h6>{res.eventDescription}</h6>
                                <p>{res?.eventTimeLocation?.location}</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            {/* <div className="card featured-card instrument-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 card-head border-0">
                    <div className="d-flex justify-content-between">
                      <h5>Jubal Instruments</h5>
                      <Link to={RoutesLink?.Instruments}>Show more</Link>
                    </div>
                  </div>

                  <div className="col-12 p-0">
                    <div className="top-instruments">
                      <ReactOwlCarousel items={2}>
                        {AllInstrumentsRedux?.map((data, index) => (
                          <div className="top-items" key={index}>
                            <img src={data?.instrument_image} alt="" />
                            <div>
                              <h2>{data?.instrument_name}</h2>
                              <p>{data?.instrument_category}</p>
                            </div>
                          </div>
                        ))}
                      </ReactOwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* </div> */}
      </DashboardLayout>
    </>
  );
}
