import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";

export default function AddTalentModal({
  toggleTalentModal, // Function to toggle the visibility of the talent modal
  privatePerformers, // Array of private performers (current state)
  setPrivatePerformers, // Function to update the state of private performers
}) {
  const [performanceTypes, setPerformanceTypes] = useState([]); // State to hold performance types
  const [selectedImage, setSelectedImage] = useState(""); // State to hold the selected image URL for preview

  // Logging current privatePerformers for debugging purposes
  console.log(privatePerformers);

  // Setting up the form with react-hook-form
  const {
    register, // Function to register input fields
    handleSubmit, // Function to handle form submission
    setValue, // Function to set values in the form
    formState: { errors }, // Object to manage form errors
    reset, // Function to reset the form fields
  } = useForm({});

  // Effect to fetch performance types from the API when the component mounts
  useEffect(() => {
    handleFetchRequest(ApiUrl.performance_types).then((res) => {
      if (res.status === true) {
        setPerformanceTypes(res?.data); // Update performance types in state
      }
    });
  }, []); // Empty dependency array means this effect runs only once

  // Function to handle image file upload
  const imageUploadHandler = (e) => {
    const file = e.target.files[0]; // Get the first file from the input
    let url = URL.createObjectURL(file); // Create a temporary URL for the uploaded image
    if (file) {
      setValue("image", file); // Set the file in the form data
      setSelectedImage(url); // Update selectedImage state for preview
    }
  };

  // Function to remove the selected image
  const imageRemoveHandler = () => {
    setValue("image", ""); // Clear the image field in the form
    setSelectedImage(""); // Clear the selected image state
  };

  // Function to handle form submission
  const onSubmit = (data) => {
    // Update privatePerformers state with new performer data
    setPrivatePerformers((prev) => [
      ...prev,
      {
        firstName: data.firstName, // First name from form data
        lastName: data.lastName, // Last name from form data
        mobile: data.phone, // Phone number from form data
        primaryCategory: data.type, // Performance type from form data
        image: data.image, // Image file from form data
      },
    ]);

    // Logging the updated performers list for debugging
    console.log("performers", privatePerformers);

    reset(); // Reset form fields to their initial state
    toggleTalentModal(); // Close the modal
  };

  return (
    <div className="modal-content">
      <div className="modal-body ">
        <div className="personal-details-form">
          <form>
            <div className="row ">
              <div className="col-12 p-0">
                <h4>Add Talent Details</h4>
              </div>
              <div className="col-12 p-0">
                {selectedImage ? (
                  <div className="position-relative img-preview">
                    <button
                      className="btn btn-link"
                      onClick={imageRemoveHandler}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                      >
                        <path
                          d="M13.7188 5.12305L4.71875 14.123M4.71875 5.12305L13.7188 14.123"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <img src={selectedImage} key={selectedImage} alt="" />
                    {/* {s.image_url &&
                   (imageUrl?.image_url.type.startsWith(
                     "image/"
                   ) ? (
                     <img
                       src={imageUrl?.image_view}
                       key={imageUrl.image_url?.name}
                       alt=""
                     />
                   ) : (
                     imageUrl?.image_url.type.startsWith(
                       "video/"
                     ) && (
                       <video autoPlay controls>
                         <source
                           src={imageUrl?.image_view}
                           type={imageUrl?.image_url?.type}
                           key={imageUrl.image_url?.name}
                         />
                       </video>
                     )
                   ))} */}
                  </div>
                ) : (
                  <>
                    <label for="talentfile" className="form-label w-100">
                      <div className="fileUploadBox d-flex justify-content-center flex-column align-items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="41"
                            height="41"
                            viewBox="0 0 41 41"
                            fill="none"
                          >
                            <path
                              d="M20.6308 0.755859C9.76922 0.755859 0.944336 9.58074 0.944336 20.4423C0.944336 31.3038 9.76922 40.1287 20.6308 40.1287C31.4923 40.1287 40.3172 31.3038 40.3172 20.4423C40.3172 9.58074 31.4923 0.755859 20.6308 0.755859ZM20.6308 2.54553C30.5259 2.54553 38.5275 10.5481 38.5275 20.4423C38.5275 22.2302 38.259 23.9527 37.7723 25.5804L32.4489 20.257C31.9075 19.7157 31.1907 19.4445 30.474 19.4445C29.7572 19.4445 29.0404 19.7157 28.4991 20.257L23.7627 24.9934L15.447 16.6777C14.9056 16.1363 14.1888 15.8652 13.4721 15.8652C12.7553 15.8652 12.0385 16.1363 11.4971 16.6777L3.3076 24.8672C2.93265 23.4219 2.73996 21.9354 2.73401 20.4423C2.73401 10.5472 10.7356 2.54553 20.6308 2.54553ZM13.4721 17.6441C13.7271 17.6441 13.9821 17.7426 14.1817 17.943L22.4974 26.2587L17.3136 31.4425C17.1506 31.6113 17.0604 31.8373 17.0624 32.0719C17.0645 32.3066 17.1586 32.531 17.3245 32.6969C17.4904 32.8628 17.7148 32.9569 17.9495 32.959C18.1841 32.961 18.4101 32.8708 18.5789 32.7078L29.7644 21.5223C29.8567 21.4276 29.967 21.3523 30.0889 21.3008C30.2107 21.2494 30.3417 21.2229 30.474 21.2229C30.6062 21.2229 30.7372 21.2494 30.8591 21.3008C30.9809 21.3523 31.0913 21.4276 31.1836 21.5223L37.1074 27.4462C34.3844 33.8514 28.0373 38.339 20.6308 38.339C12.9772 38.339 6.47443 33.5427 3.91162 26.7938L12.7616 17.9439C12.962 17.7434 13.217 17.645 13.4721 17.645V17.6441Z"
                              fill="#CAC8C8"
                            />
                            <path
                              d="M20.6309 6.12524C18.1701 6.12524 16.1567 8.13863 16.1567 10.5994C16.1567 13.0602 18.1701 15.0736 20.6309 15.0736C23.0917 15.0736 25.1051 13.0602 25.1051 10.5994C25.1051 8.13863 23.0917 6.12524 20.6309 6.12524ZM20.6309 7.91492C22.1244 7.91492 23.3154 9.10595 23.3154 10.5994C23.3154 12.0929 22.1244 13.2839 20.6309 13.2839C19.1374 13.2839 17.9464 12.0929 17.9464 10.5994C17.9464 9.10595 19.1374 7.91492 20.6309 7.91492Z"
                              fill="#CAC8C8"
                            />
                          </svg>
                        </span>

                        <p>Upload here</p>
                      </div>
                    </label>
                    <input
                      type="file"
                      className="form-control d-none"
                      id="talentfile"
                      onChange={(e) => imageUploadHandler(e)}
                      accept="image/*"
                    />
                  </>
                )}
              </div>
              <div className="col-12 p-0">
                <label for="firstName" className="form-label">
                  First Name
                  <span className="text-danger fs-2 ps-1">*</span>
                </label>
                <div className="d-flex align-items-center passfield-wrapper">
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder="First Name"
                    {...register("firstName", {
                      required: "First Name is required",
                    })}
                  />
                </div>
                {errors.firstName && (
                  <span className="form-text text-danger">
                    {errors.firstName.message}
                  </span>
                )}
              </div>

              <div className="col-12 p-0">
                <label for="lastName" className="form-label">
                  Last Name
                  <span className="text-danger fs-2 ps-1">*</span>
                </label>
                <div className="d-flex align-items-center passfield-wrapper">
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder="Last Name"
                    {...register("lastName", {
                      required: "Last Name is required",
                    })}
                  />
                </div>
                {errors.lastName && (
                  <span className="form-text text-danger">
                    {errors.lastName.message}
                  </span>
                )}
              </div>

              <div className="col-12 p-0">
                <label for="phone" className="form-label">
                  Phone Number
                  <span className="text-danger fs-2 ps-1">*</span>
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="Contact Number"
                  {...register("phone", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Invalid phone number",
                    },
                  })}
                />
                {errors.phone && (
                  <span className="form-text text-danger">
                    {errors.phone.message}
                  </span>
                )}
              </div>

              <div className="col-12 p-0">
                <label for="type" className="form-label">
                  Performance Type
                  <span className="text-danger fs-2 ps-1">*</span>
                </label>
                <select
                  id="type"
                  className="form-select"
                  aria-label="Default select example"
                  {...register("type", {
                    required: "Category is required",
                  })}
                >
                  {performanceTypes &&
                    performanceTypes.map((res, index) => {
                      return (
                        <option key={index} value={res?.type}>
                          {res?.type}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="modal-footer border-0">
        <div className="row w-100 gap-3">
          <div className="col-12 col-md-5 p-0 mt-2 mt-md-3">
            <button
              type="button"
              onClick={handleSubmit(onSubmit)}
              className="btn btn-primary w-100"
            >
              Add
            </button>
          </div>
          <div className="col-12 col-md-5 p-0 mt-2 mt-md-3">
            <Link
              to="#"
              className="btn btn-light w-100"
              onClick={toggleTalentModal}
            >
              cancel
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
