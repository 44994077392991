/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useRef, useState } from "react";
import { ApiUrl } from "../../utility/ApiUrl";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { Link } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import {
  DashboardFilters,
  EventFilters,
  InstrumentFillter,
  TalentFillters,
  TalentFilters,
} from "../../Helper/Helper";
import SearchBarLoader from "../../Helper/SearchBarLoader";
import { useSelector } from "react-redux";
import { removeClass } from "rsuite/esm/DOMHelper";

function DashboardSearchbar({ data, isPage }) {
  //all states
  const [isVisible, setDivVisible] = useState(false);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const [previousSearch, setPreviousSearch] = useState([]);
  const [Loading, setLoading] = useState(false);
  const userDetails = useSelector((store) => store?.user?.userDetails);
  const [searchdata, setSearchData] = useState({
    Vendor: [],
    talent: [],
    instruments: [],
    events: [],
  });
  const [SearchValue, setSearchValue] = useState("");

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      !inputRef.current.contains(event.target)
    ) {
      setDivVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFocus = () => {
    setDivVisible(true);
  };
  //search data funcation
  useEffect(() => {
    if (!SearchValue) return; // Avoids making requests if SearchValue is empty
  
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      handleFetchRequest(
        `${ApiUrl.globle_search}search=${SearchValue}`,
        "GET"
      ).then((response) => {
        console.log("ressss", response);
        if (response.status) {
          setSearchData(response.data);
          setLoading(false);
        }
      });
    }, 300); // 300 ms debounce delay
  
    return () => clearTimeout(delayDebounceFn); // Clear timeout if SearchValue changes
  }, [SearchValue]);
  

  // insiate the funcation

  useEffect(() => {
    handleFetchRequest(`${ApiUrl.get_previous_search}`).then((response) => {
      if (response.status) {
        setPreviousSearch(response?.data);
      }
    });
  }, []);

  const removeClass = () => {};

  return (
    <>
      <>
        <span className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
              fill="#818898"
            />
          </svg>
        </span>
        <input
          type="text"
          className="form-control dark"
          id="searchbar3"
          ref={inputRef}
          placeholder="What are you looking for?"
          // onChange={() => setSearchValue()}
          onChange={(e) => setSearchValue(e.target.value)}
          onFocus={handleFocus}
        />
        {/* <span className="visi">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="12"
            viewBox="0 0 21 12"
            fill="none"
          >
            <path
              d="M8.31407 11.9026H12.9271V9.93506H8.31407V11.9026ZM0.241211 0.0974121V2.06494H21V0.0974121H0.241211ZM3.70101 6.98376H17.5402V5.01624H3.70101V6.98376Z"
              fill="#818898"
            />
          </svg>
        </span> */}
        <div
          ref={wrapperRef}
          className={`popular-search position-absolute z-2 ${
            isVisible ? "showit" : ""
          }`}
        >
          <div className="row gap-3">
            <div className="col-12 p-0">
              <div className="row result-row">
                {searchdata &&
                searchdata?.instruments?.length === 0 &&
                searchdata?.talent?.length === 0 &&
                searchdata?.events?.length === 0 ? (
                  <>
                    <p className="mt-4 mb-3">Previous Search</p>
                    {previousSearch?.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="user-det d-flex align-items-center gap-3 py-2"
                        >
                          {data?.detail?.Profile?.profilePhoto ? (
                            <div className="user-dp">
                              <img
                                src={data?.detail?.Profile?.profilePhoto}
                                width=""
                              />
                            </div>
                          ) : (
                            <div className="user-dp no-img">
                              {data?.detail?.Profile?.firstName?.[0]}
                            </div>
                          )}

                          <div className="user-ac">
                            <div className="d-flex gap-2 align-items-center">
                              <span className="username">
                                <Link
                                  to={RoutesLink?.profile}
                                  // state={data?.value}
                                  state={{ id: data?.value }}
                                  onClick={() => removeClass()}
                                >
                                  {data?.detail?.Profile?.firstName}{" "}
                                  {data?.detail?.Profile?.lastName}
                                </Link>
                              </span>
                              <span
                                className={
                                  data?.detail?.type === 3
                                    ? "usertype mx-1"
                                    : data?.detail?.type === 2
                                    ? "usertype vendor mx-1"
                                    : ""
                                }
                              >
                                <span>
                                  {data?.detail?.type === 3 ||
                                    (data?.detail?.type === 2 && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="13"
                                        height="13"
                                        viewBox="0 0 13 13"
                                        fill="none"
                                      >
                                        <circle
                                          cx="5.74979"
                                          cy="6.72714"
                                          r="3.75247"
                                          fill="white"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                          fill="#F4336D"
                                        />
                                      </svg>
                                    ))}
                                </span>
                                {data?.detail?.type === 3
                                  ? "Talent"
                                  : data?.detail?.type === 2
                                  ? "Vendor"
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : Loading ? (
                  <SearchBarLoader />
                ) : (
                  <div className="container">
                    <div className="row">
                      {/* Talents Section */}
                      {searchdata && searchdata.talent?.some(talent => talent?.type === 3) > 0 && (
                        <>
                          <div className="col-12 p-0">
                            <p className="mt-4 mb-3">Talents</p>
                          </div>
                          <div className="col-12 p-0 border-bottom pb-4 d-flex flex-column gap-3">
                            {searchdata.talent.map(
                              (talent) =>
                                talent?.type == 3 && (
                                  <div
                                    className="user-det-head"
                                    key={talent.id}
                                  >
                                    {/* <div className="user-det-head" key={talent.id}> */}
                                    <div className="user-det d-flex justify-content-between align-items-center">
                                      <div className="d-flex gap-3">
                                        {talent?.Profile?.profilePhoto ? (
                                          <div className="user-dp">
                                            <img
                                              src={talent.Profile?.profilePhoto}
                                              alt={
                                                talent.Profile?.artistBandName
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <div className="user-dp no-img">
                                            {
                                              talent?.Profile?.firstName.toUpperCase()?.[0]
                                            }
                                          </div>
                                        )}

                                        <div className="user-ac">
                                          <div className="d-flex gap-2 align-items-center">
                                            <span className="username">
                                              <Link
                                                to={
                                                  talent?.Profile?.userId ===
                                                  userDetails?.id
                                                    ? RoutesLink?.MyProfile
                                                    : RoutesLink?.profile
                                                }
                                                state={{
                                                  id: talent?.Profile?.userId,
                                                }}
                                                // state={talent?.Profile?.userId}
                                                onClick={() =>
                                                  setDivVisible(false)
                                                }
                                              >
                                                {talent.Profile?.firstName}{" "}
                                                {talent.Profile?.lastName}
                                              </Link>
                                            </span>

                                            <span className="usertype mx-1">
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={13}
                                                  height={13}
                                                  viewBox="0 0 13 13"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="5.74979"
                                                    cy="6.72714"
                                                    r="3.75247"
                                                    fill="white"
                                                  />
                                                  <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                                    fill="#F4336D"
                                                  />
                                                </svg>
                                              </span>
                                              Talent
                                            </span>
                                          </div>
                                          <p className="m-0">{talent.about}</p>
                                        </div>
                                      </div>
                                    </div>
                                    {/* </div> */}
                                  </div>
                                )
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="row">
                      {/* Vendors Section */}
                      {searchdata && searchdata?.talent?.some(talent => talent?.type === 2) > 0 && (
                        <>
                          <div className="col-12 p-0">
                            <p className="mt-4 mb-3">Vendors</p>
                          </div>
                          <div className="col-12 p-0 border-bottom pb-4 d-flex flex-column gap-3">
                            {searchdata.talent.map(
                              (talent) =>
                                talent?.type == 2 && (
                                  <div
                                    className="user-det-head"
                                    key={talent.id}
                                  >
                                    {/* <div className="user-det-head" key={talent.id}> */}
                                    <div className="user-det d-flex justify-content-between align-items-center">
                                      <div className="d-flex gap-3">
                                        {talent?.Profile?.profilePhoto ? (
                                          <div className="user-dp">
                                            <img
                                              src={talent.Profile?.profilePhoto}
                                              alt={
                                                talent.Profile?.artistBandName
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <div className="user-dp no-img">
                                            {
                                              talent?.Profile?.firstName.toUpperCase()?.[0]
                                            }
                                          </div>
                                        )}

                                        <div className="user-ac">
                                          <div className="d-flex gap-2 align-items-center">
                                            <span className="username">
                                              <Link
                                                to={
                                                  talent?.Profile?.userId ===
                                                  userDetails?.id
                                                    ? RoutesLink?.MyProfile
                                                    : RoutesLink?.profile
                                                }
                                                state={{
                                                  id: talent?.Profile?.userId,
                                                }}
                                                // state={talent?.Profile?.userId}
                                                onClick={() =>
                                                  setDivVisible(false)
                                                }
                                              >
                                                {talent.Profile?.firstName}{" "}
                                                {talent.Profile?.lastName}
                                              </Link>
                                            </span>

                                            <span className="usertype vendor mx-1">
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={13}
                                                  height={13}
                                                  viewBox="0 0 13 13"
                                                  fill="none"
                                                >
                                                  <circle
                                                    cx="5.74979"
                                                    cy="6.72714"
                                                    r="3.75247"
                                                    fill="white"
                                                  />
                                                  <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                                    fill="#F4336D"
                                                  />
                                                </svg>
                                              </span>
                                              Vendor
                                            </span>
                                          </div>
                                          <p className="m-0">{talent.about}</p>
                                        </div>
                                      </div>
                                    </div>
                                    {/* </div> */}
                                  </div>
                                )
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="row">
                      {/* Instruments Section */}
                      {searchdata && searchdata?.instruments.length > 0 && (
                        <>
                          <div className="col-12 p-0">
                            <p className="mt-4 mb-3">Instruments</p>
                          </div>
                          <div className="col-12 p-0 border-bottom pb-4 d-flex flex-column gap-3">
                            {searchdata?.instruments?.map((instrument) => (
                              <div
                                className="user-det-head"
                                key={instrument.instrument_name}
                              >
                                <div
                                  className="user-det-head"
                                  key={instrument.instrument_name}
                                >
                                  <div className="user-det d-flex justify-content-between align-items-center">
                                    <div className="d-flex gap-3">
                                      {instrument?.instrument_image ? (
                                        <div className="user-dp">
                                          <img
                                            src={
                                              instrument
                                                ?.instrument_image?.[0] ||
                                              instrument.instrument_image ||
                                              "default-image-url"
                                            }
                                            alt={instrument.instrument_name}
                                          />
                                        </div>
                                      ) : (
                                        <div className="user-dp no-img">
                                          {instrument?.instrument_name[0]}
                                        </div>
                                      )}

                                      <div className="user-ac">
                                        <div className="d-flex gap-2 align-items-center">
                                          <span className="username">
                                            <Link
                                              to={
                                                RoutesLink?.InstrumentsDetails
                                              }
                                              state={{
                                                instrumentdata: instrument?.id,
                                              }}
                                              onClick={() =>
                                                setDivVisible(false)
                                              }
                                            >
                                              {instrument.instrument_name}
                                            </Link>
                                          </span>
                                          {/* <span className="usertype mx-1">
                                            <span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={13}
                                                height={13}
                                                viewBox="0 0 13 13"
                                                fill="none"
                                              >
                                                <circle
                                                  cx="5.74979"
                                                  cy="6.72714"
                                                  r="3.75247"
                                                  fill="white"
                                                />
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                                  fill="#F4336D"
                                                />
                                              </svg>
                                            </span>
                                            Instrument
                                          </span> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row">
                      {/* Instruments Section */}

                      {searchdata && searchdata?.events?.length > 0 && (
                        <>
                          <div className="col-12 p-0">
                            <p className="mt-4 mb-3">Events</p>
                          </div>
                          <div className="col-12 p-0 border-bottom pb-4 d-flex flex-column gap-3">
                            {searchdata.events.map((event) => (
                              <div
                                className="user-det-head"
                                key={event.eventName}
                              >
                                <div
                                  className="user-det-head"
                                  key={event?.eventName}
                                >
                                  <div className="user-det d-flex justify-content-between align-items-center">
                                    <div className="d-flex gap-3">
                                      <div className="user-dp">
                                        <img
                                          src={event.eventImage}
                                          alt={event.eventName}
                                        />
                                      </div>
                                      <div className="user-ac">
                                        <div className="d-flex gap-2 align-items-center">
                                          <span className="username">
                                            <Link
                                              to={RoutesLink?.EventDetails}
                                              state={event?.id}
                                              onClick={() =>
                                                setDivVisible(false)
                                              }
                                            >
                                              {event.eventName}
                                            </Link>
                                          </span>
                                          {/* <span className="usertype mx-1">
                                            <span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={13}
                                                height={13}
                                                viewBox="0 0 13 13"
                                                fill="none"
                                              >
                                                <circle
                                                  cx="5.74979"
                                                  cy="6.72714"
                                                  r="3.75247"
                                                  fill="white"
                                                />
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                                  fill="#F4336D"
                                                />
                                              </svg>
                                            </span>
                                            Events
                                          </span> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default DashboardSearchbar;
