import React from "react";
import { Header } from "rsuite";
import HeroSection from "../Components/HeroSection";
import HomeFooter from "../Components/HomeFooter";

function Nointernet() {
  return (
    <>
      <div
        className="d-flex justify-content-center 
                align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="text-center">
          <h1>404</h1>
          <h2>No internet available</h2>
          <p>Sorry, you are not connected to the internet.</p>
        </div>
      </div>
    </>
  );
}

export default Nointernet;
