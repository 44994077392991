import "./helper.css";

export default function PostLoader() {
  return (
    <div className="card-body">
      <div className="user-det-head d-flex justify-content-between align-items-start">
        <div className="Loader col-12 p-0">
          <div className="wrapper">
            <div className="wrapper-cell">
              <div className="text">
                <div className=""></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
              </div>
            </div>
            <div className="Image-wapper">
              <div className="image"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
