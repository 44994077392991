import Slider from "react-slick";
const ChooseUsSection = () => {
  const settings = {
    slidesToScroll: 1,
    slidesToShow: 3,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    dots: false,
    arrows: false,
    vertical: true,
    draggable: true,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const testimonials = [
    {
      name: "Leland Hagenes IV",
      location: "Borders",
      image: "images/testimonials/leland.png",
      feedback:
        "My other favourite template is the furnishing growth animation template which wows the viewers every time. Thank you Jubal",
    },
    {
      name: "Linda Kozey",
      location: "country",
      image: "images/testimonials/linda.png",
      feedback:
        "My other favourite template is the furnishing growth animation template which wows the viewers every time. Thank you Jubal",
    },
    {
      name: "Amanda Schinner",
      location: "Berkshire",
      image: "images/testimonials/amanda.png",
      feedback:
        "My other favourite template is the furnishing growth animation template which wows the viewers every time. Thank you Jubal",
    },
    // Add more testimonials as needed
  ];

  return (
    <>
      <section className="wcu">
        <div className="max-width-container">
          <div className="container">
            <div className="row text-center">
              <div className="col-12 p-0">
                <h3 className="h3">
                  Why choose?
                  <span className="keyw"> Jubal Talents</span>
                </h3>
                <p className="para">
                  It emerged from our own experiences within the music industry.
                  We've faced the struggles, and we've celebrated the successes.
                  Jubal Talents is our response to the need for a platform that
                  addresses the unique challenges musicians face in their
                  careers
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="max-width-container testimonials-container py-0 position-relative">
          <div className="blur-effect upper position-absolute start-0 end-0"></div>
          <div className="blur-effect lower position-absolute start-0 end-0"></div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 px-4">
              <Slider {...settings} className="testimonials testimonials-1">
                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/vernon.png" alt="" />
                    <div>
                      <h6>Vernon Lindgren</h6>
                      <h6>Ethiopia</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>

                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/louise.png" alt="" />
                    <div>
                      <h6>Louis Bergnaum</h6>
                      <h6>Morocco</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>

                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/terence.png" alt="" />
                    <div>
                      <h6>Terence Denesik</h6>
                      <h6>Tuvalu</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>
                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/donnie.png" alt="" />
                    <div>
                      <h6>Donnie Rodriguez</h6>
                      <h6>Belgium</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>
              </Slider>
            </div>

            <div className="col-lg-4 col-md-6 col-xl-3 px-4 d-none d-md-block">
              <Slider {...settings} className="testimonials testimonials-3">
                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/vernon.png" alt="" />
                    <div>
                      <h6>Vernon Lindgren</h6>
                      <h6>Ethiopia</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>

                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/louise.png" alt="" />
                    <div>
                      <h6>Louis Bergnaum</h6>
                      <h6>Morocco</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>

                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/terence.png" alt="" />
                    <div>
                      <h6>Terence Denesik</h6>
                      <h6>Tuvalu</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>
                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/donnie.png" alt="" />
                    <div>
                      <h6>Donnie Rodriguez</h6>
                      <h6>Belgium</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>
              </Slider>
            </div>

            <div className="col-lg-4 col-xl-3 px-4 d-none d-lg-block">
              <Slider {...settings} className="testimonials testimonials-3">
                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/vernon.png" alt="" />
                    <div>
                      <h6>Vernon Lindgren</h6>
                      <h6>Ethiopia</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>

                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/louise.png" alt="" />
                    <div>
                      <h6>Louis Bergnaum</h6>
                      <h6>Morocco</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>

                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/terence.png" alt="" />
                    <div>
                      <h6>Terence Denesik</h6>
                      <h6>Tuvalu</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>
                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/donnie.png" alt="" />
                    <div>
                      <h6>Donnie Rodriguez</h6>
                      <h6>Belgium</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>
              </Slider>
            </div>

            <div className="col-xl-3 px-4 d-none d-xl-block">
              <Slider {...settings} className="testimonials testimonials-4">
                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/vernon.png" alt="" />
                    <div>
                      <h6>Vernon Lindgren</h6>
                      <h6>Ethiopia</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>

                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/louise.png" alt="" />
                    <div>
                      <h6>Louis Bergnaum</h6>
                      <h6>Morocco</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>

                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/terence.png" alt="" />
                    <div>
                      <h6>Terence Denesik</h6>
                      <h6>Tuvalu</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>
                <div className="testimonials-card">
                  <div className="card-header d-flex align-items-center gap-4">
                    <img src="images/testimonials/donnie.png" alt="" />
                    <div>
                      <h6>Donnie Rodriguez</h6>
                      <h6>Belgium</h6>
                    </div>
                  </div>
                  <p className="pe-xxl-5">
                    My other favourite template is the furnishing growth
                    animation template which wows the viewers every time. Thank
                    you Jubal
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ChooseUsSection;
