import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import { ApiUrl } from "../../utility/ApiUrl";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { useSelector } from "react-redux";

function InstrumentLikes({ id }) {
  const userDetails = useSelector((store) => store?.user?.userDetails);
  console.log(userDetails);

  const [sortOption, setSortOption] = useState("Newest");
  const [MyLikedInstrument, setMyLikedInstrument] = useState([]);

  useEffect(() => {
    if (userDetails?.id) {
      handleFetchRequest(
        `${ApiUrl.my_liked_instrument}${userDetails?.id}`
      ).then((res) => {
        if (res.status === true) {
          console.log("daaaaaa===>>", res?.data?.likes);
          let likesData = res?.data?.likes || [];
          setMyLikedInstrument(likesData);
        }
      });
    }
  }, [userDetails?.id, sortOption]);

  const [filterOption, setFilterOption] = useState("Newest");

  const handleFilterChange = (option) => {
    setFilterOption(option);
  };

  const getSortedComments = () => {
    return [...MyLikedInstrument].sort((a, b) => {
      if (filterOption === "Newest") {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else if (filterOption === "Oldest") {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
      return 0;
    });
  };

  // const sortPosts = (posts, option) => {
  //   return posts.sort((a, b) => {
  //     const dateA = new Date(a?.Post?.createdAt);
  //     const dateB = new Date(b?.Post?.createdAt);

  //     if (option === "Newest") {
  //       return dateB - dateA;
  //     } else if (option === "Oldest") {
  //       return dateA - dateB;
  //     }
  //     return 0;
  //   });
  // };

  // const handleSortChange = (option) => {
  //   setSortOption(option);
  // };

  return (
    <div
      className="tab-pane fade active show"
      id="like-tab-pane2"
      role="tabpanel"
      aria-labelledby="like-tab"
      tabIndex={0}
    >
      <div>
        <div className="filter-row">
          <div className="d-flex align-items-center justify-content-between">
            <p>Instrument / Equipments likes</p>
            {MyLikedInstrument?.length > 0 && (
              <div className="inner-filter-row">
                <div>
                  <div className="dropdown dropdown-menu-end">
                    <span className="selected"></span>
                    <button
                      className="btn btn-light dropdown-toggle d-flex align-items-center gap-3"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={21}
                          height={12}
                          viewBox="0 0 21 12"
                          fill="none"
                        >
                          <path
                            d="M8.07872 11.8284H12.6918V9.86084H8.07872V11.8284ZM0.00585938 0.0231934V1.99072H20.7646V0.0231934H0.00585938ZM3.46566 6.90955H17.3048V4.94202H3.46566V6.90955Z"
                            fill="#818898"
                          />
                        </svg>
                      </span>
                      Filter by
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          to="#"
                          className={
                            filterOption === "Newest"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          onClick={() => handleFilterChange("Newest")}
                        >
                          Newest
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className={
                            filterOption === "Oldest"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          onClick={() => handleFilterChange("Oldest")}
                        >
                          Oldest
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <span className="selected-opt">
              {sortOption}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={12}
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M1.06994 0.969544C0.882466 1.15707 0.77715 1.41138 0.77715 1.67654C0.77715 1.94171 0.882466 2.19602 1.06994 2.38354L4.60594 5.91954L1.06994 9.45554C0.974427 9.54779 0.898244 9.65814 0.845835 9.78014C0.793426 9.90214 0.76584 10.0334 0.764686 10.1661C0.763532 10.2989 0.788834 10.4306 0.839115 10.5535C0.889396 10.6764 0.963649 10.788 1.05754 10.8819C1.15143 10.9758 1.26309 11.0501 1.38598 11.1004C1.50888 11.1506 1.64056 11.1759 1.77334 11.1748C1.90612 11.1736 2.03734 11.1461 2.15934 11.0936C2.28135 11.0412 2.39169 10.9651 2.48394 10.8695L6.01994 7.33354L9.55594 10.8695C9.74454 11.0517 9.99714 11.1525 10.2593 11.1502C10.5215 11.1479 10.7723 11.0428 10.9578 10.8574C11.1432 10.672 11.2483 10.4211 11.2506 10.1589C11.2529 9.89675 11.1521 9.64415 10.9699 9.45554L7.43394 5.91954L10.9699 2.38354C11.1521 2.19494 11.2529 1.94234 11.2506 1.68014C11.2483 1.41795 11.1432 1.16713 10.9578 0.981726C10.7723 0.796318 10.5215 0.691148 10.2593 0.68887C9.99714 0.686592 9.74454 0.787386 9.55594 0.969544L6.01994 4.50554L2.48394 0.969544C2.29641 0.782073 2.0421 0.676758 1.77694 0.676758C1.51177 0.676758 1.25746 0.782073 1.06994 0.969544Z"
                    fill="#86889A"
                  />
                </svg>
              </span>
            </span> */}
              </div>
            )}
          </div>
        </div>
        <div className="grid-gallery">
          {MyLikedInstrument && MyLikedInstrument.length === 0 ? (
            <p className="form-text">No Data Found</p>
          ) : (
            getSortedComments()?.map((res, index) => {
              return (
                res?.Instrument?.instrument_image && (
                  <div className="post-content2" key={index}>
                    <Link
                      to={RoutesLink?.InstrumentsDetails}
                      state={{
                        instrumentdata: res?.Instrument?.id,
                        AllInstruments: res?.Instrument,
                      }}
                    >
                      <img src={res?.Instrument?.instrument_image} alt="" />
                      <div className="bottom">
                        <p>{res?.Instrument?.instrument_name}</p>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={9}
                            height={10}
                            viewBox="0 0 9 10"
                            fill="none"
                          >
                            <path
                              d="M8.90641 2.53335C8.79804 2.06653 8.57776 1.6392 8.26796 1.29485C7.95816 0.950497 7.56991 0.701402 7.1427 0.572904C6.62694 0.432872 5.62765 0.382861 4.49943 1.49811C3.37581 0.387862 2.38113 0.432872 1.86538 0.572904C1.43787 0.700208 1.04904 0.948209 0.738437 1.29168C0.427832 1.63516 0.206536 2.06185 0.0970572 2.52835C-0.0428971 3.13366 -0.0313082 3.7686 0.130624 4.36741C0.292556 4.96622 0.598943 5.50713 1.01806 5.93413L4.16327 9.34991C4.207 9.39837 4.25935 9.43667 4.31714 9.46246C4.37494 9.48826 4.43696 9.50101 4.49943 9.49994C4.6209 9.49806 4.73676 9.44413 4.82178 9.34991L7.96699 5.93913C8.38935 5.51427 8.69923 4.97432 8.86443 4.37539C9.02963 3.77647 9.04413 3.14042 8.90641 2.53335ZM7.31308 5.22897L4.49943 8.28966L1.67197 5.22897C1.36714 4.92799 1.14225 4.54371 1.02076 4.1162C0.89926 3.68869 0.885605 3.23361 0.981216 2.79841C1.04852 2.50261 1.18595 2.2311 1.37984 2.01088C1.57373 1.79065 1.81733 1.62939 2.08641 1.54313C2.19635 1.5132 2.30925 1.49809 2.42258 1.49811C2.75885 1.53026 3.08576 1.63534 3.38396 1.80715C3.68216 1.97896 3.94559 2.21399 4.15866 2.49834C4.20107 2.54801 4.25232 2.58781 4.30932 2.61532C4.36631 2.64283 4.42786 2.65748 4.49022 2.65838C4.55336 2.65816 4.61579 2.64384 4.67362 2.61631C4.73145 2.58878 4.78345 2.54862 4.82639 2.49834C5.52635 1.69816 6.27235 1.35308 6.92166 1.52812C7.1914 1.61581 7.43493 1.77955 7.62745 2.00269C7.81997 2.22583 7.9546 2.50039 8.01765 2.79841C8.1116 3.23494 8.09578 3.69086 7.97182 4.11847C7.84787 4.54607 7.62035 4.92962 7.31308 5.22897Z"
                              fill="#86889A"
                            />
                          </svg>
                          ${res?.Instrument?.selling_price}
                        </span>
                      </div>
                    </Link>
                  </div>
                )
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default InstrumentLikes;
