import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getLoginUserCaption } from "../../Helper/Helper";

function UserProfileCard({ userName, userData, id, data }) {
  return (
    <>
      <div className="user-profile-card mt-0">
        <div className="user-det-head">
          <Link>
            <div className="user-det d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <div className="user-dp">{userName?.toUpperCase()?.[0]}</div>
                <div className="user-ac">
                  <div className="d-flex gap-2 align-items-center">
                    <span className="username">
                      <h5>{userName && userName}</h5>
                    </span>
                    {data?.type === 2 || data?.type === 3 ? (
                      <span
                        className={
                          data?.type === 2
                            ? "usertype vendor mx-1"
                            : "usertype mx-1"
                        }
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={13}
                            height={13}
                            viewBox="0 0 13 13"
                            fill="none"
                          >
                            <circle
                              cx="5.74979"
                              cy="6.72714"
                              r="3.75247"
                              fill="white"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        {data?.type === 2 ? "Vendor" : "Talent"}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="d-flex gap-2 align-items-center" />
                  <p className="m-0">{getLoginUserCaption(data)}</p>
                </div>
              </div>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={21}
                  height={21}
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <path
                    d="M16.2188 6.2832L7.96875 14.5332L4.21875 10.7832"
                    stroke="#90B10C"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default UserProfileCard;
