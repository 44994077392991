import { Modal, Spinner } from "reactstrap";
import Select from "react-select";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import MobileHeader from "../../Components/Layout/MobileHeader";
import PreventEnterKey from "../../Helper/PreventEnterKey";
import { Controller, useForm } from "react-hook-form";
import GooglePlaceAutocomplete from "../../Components/GooglePlaceAutocomplete";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { toast } from "material-react-toastify";
import { getLoginUserCaption } from "../../Helper/Helper";
import { SvgIcons } from "../../Components/Svg/SvgIcons";
import { RoutesLink } from "../../utility/RoutesLink";
import { useSelector } from "react-redux";
import AddTalentModal from "../../Components/Modals/AddTalentModal";
import ImageCropper from "../../Helper/ImageCropper";
import { array } from "yup";

const convertTimeToMinutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

function CreateInstrument() {
  const location = useLocation();
  const navigate = useNavigate();

  const eventData = location?.state;
  const [isEdit, setIsEdit] = useState(false);
  const userData = useSelector((store) => store?.user?.userDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [EventType, setEventType] = useState([]);
  const [Entertainment, setEntertainment] = useState([]);
  const [Music, setmusic] = useState([]);
  const [ImageErrorState, setImageErrorState] = useState("");
  const [visibilityType, setVisibilityType] = useState("public");
  const [announceEvent, setAnnounceEvent] = useState(false);
  const [TypeOfEvent, setTypeOfEvent] = useState("Free");
  const [eventLoading, setEventLoading] = useState(false);
  const [eventStatus, setEventStatus] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [talentModal, setTalentModal] = useState(false);
  const [privatePerformers, setPrivatePerformers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const toggleTalentModal = () => {
    setTalentModal(!talentModal);
  };

  console.log("privatePerformers==>>>", location?.state);
  const [imageUrl, setImageUrl] = useState({
    image_url: "",
    image_view: "",
  });

  const musicOptions = Music?.map((res) => ({
    value: res?.type,
    label: res?.type,
  }));

  const handleRemovePerformer = (indexToRemove) => {
    setPrivatePerformers((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [minDate, setMinDate] = useState("");
  const [minTime, setMinTime] = useState("");

  useEffect(() => {
    const now = new Date();
    const formattedDate = now.toISOString().split("T")[0];
    const formattedTime = now.toTimeString().split(" ")[0].substring(0, 5);

    setMinDate(formattedDate);
    setMinTime(formattedTime);
  }, []);

  useEffect(() => {
    handleFetchRequest(ApiUrl.event_type).then((res) => {
      if (res.status === true) {
        setEventType(res?.data);
      }
    });
    handleFetchRequest(ApiUrl.entertainment_types).then((res) => {
      if (res.status === true) {
        setEntertainment(res?.data);
      }
    });
    handleFetchRequest(ApiUrl.music_type).then((res) => {
      if (res.status === true) {
        setmusic(res?.data);
      }
    });
    if (eventData) {
      setIsEdit(true);
      console.log(isEdit);
    }
  }, []);

  const handleFocus = (e) => {
    e.target.showPicker();
  };

  const imageRemoveHandler = () => {
    setImageUrl({
      image_view: "",
      image_url: "",
    });
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setSelectedImage("");
    setShowModal(false);
  };
  const handleCrop = (image) => {
    setShowModal(false);
    let url = URL.createObjectURL(image);
    if (url) {
      setImageUrl({
        image_url: image,
        image_view: url,
      });
    }
  };

  const imageUploadHandler = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result); // This will trigger the useEffect in ImageCropper
      handleShow(); // Display the modal
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = (data) => {
    // Parse date and time with AM/PM consideration
    const startDateTime = moment(
      `${data.start_date} ${data.start_time}`,
      "DD-MM-YYYY hh:mm A"
    );
    const endDateTime = moment(
      `${data.end_date} ${data.end_time}`,
      "DD-MM-YYYY hh:mm A"
    );

    // Check if the event duration is at least 1 hour
    const duration = moment.duration(endDateTime.diff(startDateTime));
    const durationInHours = duration.asHours();

    console.log("durationInHours", durationInHours, duration);

    if (durationInHours < 1) {
      toast.error("Event must be at least 1 hour long.");
      setEventLoading(false);
      return; // Exit the function early to prevent submission
    }

    if (endDateTime.isBefore(startDateTime)) {
      toast.error("End date and time must be after start date and time.");
      setEventLoading(false);
      return; // Exit the function early to prevent submission
    }

    setEventLoading(true);

    const formData = new FormData();
    if (isEdit) {
      // Existing event data logic

      formData.append("eventName", data.fname);
      formData.append("eventDescription", data?.info);
      if (selectedPlace !== "" && selectedPlace != null) {
        formData.append("eventTimeLocation[location]", selectedPlace?.address);
        formData.append("eventTimeLocation[latitude]", selectedPlace?.lat);
        formData.append("eventTimeLocation[longitude]", selectedPlace?.lng);
        formData.append("eventTimeLocation[state]", selectedPlace?.state);
        formData.append("latitude", selectedPlace?.lat);
        formData.append("longitude", selectedPlace?.lng);
      } else {
        formData.append(
          "eventTimeLocation[location]",
          eventData?.eventTimeLocation?.location
        );
        formData.append(
          "eventTimeLocation[latitude]",
          eventData?.eventTimeLocation?.latitude
        );
        formData.append(
          "eventTimeLocation[longitude]",
          eventData?.eventTimeLocation?.longitude
        );
        formData.append(
          "eventTimeLocation[state]",
          eventData?.eventTimeLocation?.state
        );
        formData.append("latitude", eventData?.eventTimeLocation?.latitude);
        formData.append("longitude", eventData?.eventTimeLocation?.longitude);
      }

      // ... (Rest of your formData setup)

      handleFetchRequest(
        `${ApiUrl?.edit_event}${eventData?.id}`,
        "PUT",
        null,
        null,
        formData
      ).then((response) => {
        if (response?.status === true) {
          if (eventStatus == 0) {
            toast.success("Event saved in draft");
          } else if (eventStatus == 2) {
            toast.success("Event published");
          }
          setIsLoading(false);
          reset();
          navigate(RoutesLink.MyProfile, { state: { id: userData.id } });
          setEventLoading(false);
        } else {
          toast.error("Event Can't Be Created!");
          setEventLoading(false);
        }
      });
    } else {
      // New event data logic
      formData.append("eventName", data.fname);
      formData.append("eventDescription", data?.info);
      formData.append(
        "eventTimeLocation[start_date]",
        moment(data?.start_date).format("DD-MM-YYYY")
      );
      formData.append(
        "eventTimeLocation[end_date]",
        moment(data.end_date).format("DD-MM-YYYY")
      );
      formData.append("eventTimeLocation[location]", selectedPlace?.address);
      formData.append("eventTimeLocation[latitude]", selectedPlace?.lat);
      formData.append("eventTimeLocation[longitude]", selectedPlace?.lng);
      formData.append("eventTimeLocation[state]", selectedPlace?.state);
      formData.append("eventStatus", eventStatus);
      formData.append("musicType", data.music);
      formData.append("eventType", data?.type);

      formData.append(
        "eventTimeLocation[start_time]",
        moment(data.start_time, "hh:mm A").format("hh:mm A")
      );
      formData.append(
        "eventTimeLocation[end_time]",
        moment(data.end_time, "hh:mm A").format("hh:mm A")
      );

      // ... (Rest of your formData setup)

      handleFetchRequest(
        `${ApiUrl?.create_event}`,
        "POST",
        null,
        null,
        formData
      ).then((response) => {
        if (response?.status === true) {
          if (eventStatus == 0) {
            toast.success("Event saved in draft");
          } else if (eventStatus == 2) {
            toast.success("Event published");
          }

          setIsLoading(false);
          reset();
          navigate(RoutesLink.MyProfile, { state: { id: userData.id } });
          setEventLoading(false);
        } else {
          toast.error("Event Can't Be Created!");
          setEventLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    if (eventData?.eventPricing?.type == "Paid") {
      setTypeOfEvent("Paid");
    }
    if (eventData?.visibilityType == "public") {
      setVisibilityType("public");
    }
    if (eventData?.announceEvent == true) {
      setAnnounceEvent(true);
    }
    if (eventData?.eventImage) {
      setImageUrl({
        image_view: eventData?.eventImage,
        image_url: "",
      });
    }
    if (eventData?.eventPrivatePerformers?.length > 0) {
      setPrivatePerformers(eventData.eventPrivatePerformers);
    }
    if (isEdit && eventData) {
      setValue("fname", location?.state?.eventName);
      setValue("price", eventData?.tickets?.price);
      setValue("info", eventData?.eventDescription);
      // setValue("entertemenet", eventData?.entertainmentType);
      setValue("type", eventData?.eventType);
      setValue("music", eventData?.musicType);
      setValue("Eventtype", eventData?.eventPricing?.type);
      setValue(
        "start_date",
        moment(eventData?.eventTimeLocation?.start_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )
      );
      setValue(
        "start_time",
        moment(eventData?.eventTimeLocation?.start_time, ["h:mm A"]).format(
          "HH:mm"
        )
      );
      setValue(
        "end_date",
        moment(eventData?.eventTimeLocation?.end_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        )
      );
      setValue(
        "end_time",
        moment(eventData?.eventTimeLocation?.end_time, ["h:mm A"]).format(
          "HH:mm"
        )
      );
      setValue("budget", eventData?.tickets?.quantity);
      if (location.state?.visibilityType) {
        setVisibilityType(location.state.visibilityType);
      }
    }
  }, [isEdit, eventData, setValue]);

  const today = new Date().toISOString().split("T")[0];
  const currentTime = new Date().toLocaleTimeString("it-IT").slice(0, 5);

  const selectedStartDate = watch("start_date");
  const selectedStartTime = watch("start_time");

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <PreventEnterKey />
      <section className="px-0 main-content">
        <div className="max-width-container">
          {/* =========================== */}
          {/* Main feed grid Start */}
          {/* =========================== */}
          <div className="main-feed-grid">
            {/* =========================== */}
            {/*left side content Start */}
            {/* =========================== */}
            <DashboardSidebar />
            {/* =========================== */}
            {/*left side content end */}
            {/* =========================== */}
            {/* =========================== */}
            {/* center content Start */}
            {/* =========================== */}
            <div className="center-content d-block">
              <div className="row">
                <div className="col-12 p-0">
                  <div className="user-det-head-wrapper">
                    <div className="user-det-head d-flex justify-content-between align-items-center">
                      <div className="user-det d-flex gap-3 gap-lg-4 align-items-center">
                        <div className="user-dp">
                          <img src={userData?.Profile?.profilePhoto} alt="" />
                        </div>
                        <div className="user-ac">
                          <div className="d-flex gap-2 align-items-center">
                            <span className="username">
                              <Link to="#" onClick={() => navigate(-1)}>
                                {userData?.Profile?.firstName}{" "}
                                {userData?.Profile?.lastName}
                              </Link>
                            </span>
                            <span className="usertype vendor mx-1">
                              <span>{SvgIcons?.talentIcon}</span>
                              Vendor
                            </span>
                          </div>
                          <p>
                            {" "}
                            <p>{getLoginUserCaption(userData)}</p>
                          </p>
                        </div>
                      </div>
                      <div className="btn-wrapper">
                        <button
                          className="btn btn-light rounded-circle"
                          onClick={() => navigate(-1)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              d="M7.05762 6.00164L11.7712 1.28784C11.8428 1.21874 11.8998 1.13609 11.9391 1.0447C11.9783 0.953313 11.999 0.855022 11.9999 0.755563C12.0007 0.656104 11.9818 0.557469 11.9441 0.465413C11.9065 0.373357 11.8508 0.289723 11.7805 0.219393C11.7102 0.149062 11.6265 0.0934424 11.5345 0.0557793C11.4424 0.0181162 11.3438 -0.000835989 11.2443 2.82821e-05C11.1449 0.000892553 11.0466 0.0215562 10.9552 0.0608134C10.8638 0.100071 10.7812 0.157135 10.7121 0.228678L5.99827 4.94229L1.28466 0.228678C1.14367 0.0907862 0.953989 0.0140576 0.756783 0.0151437C0.559576 0.0162297 0.370754 0.0950426 0.231293 0.234478C0.0918327 0.373914 0.0129868 0.562723 0.0118659 0.759929C0.010745 0.957135 0.0874397 1.14683 0.225306 1.28784L4.9391 6.00164L0.225306 10.7154C0.154478 10.7847 0.0980952 10.8673 0.0594229 10.9586C0.0207506 11.0498 0.000556963 11.1477 1.13597e-05 11.2468C-0.000534243 11.3459 0.0185791 11.4441 0.0562444 11.5357C0.0939097 11.6274 0.149378 11.7106 0.21944 11.7807C0.289501 11.8507 0.372762 11.9062 0.464402 11.9438C0.556042 11.9815 0.654241 12.0006 0.753312 12C0.852383 11.9994 0.950358 11.9792 1.04156 11.9405C1.13277 11.9018 1.2154 11.8454 1.28466 11.7746L5.99827 7.06099L10.7121 11.7746C10.8583 11.9209 11.0501 11.9941 11.2416 11.9941C11.4332 11.9941 11.6252 11.9209 11.7712 11.7746C11.9117 11.6341 11.9905 11.4436 11.9905 11.245C11.9905 11.0464 11.9117 10.8559 11.7712 10.7154L7.05762 6.00164Z"
                              fill="#343B4E"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {isLoading === true ? (
                  <div className="col-lg-12">
                    <div className="loading-overlay">
                      <Spinner color="light" />
                    </div>
                  </div>
                ) : (
                  <div className="col-12  p-0 pt-4">
                    <div className="card event-card">
                      <div className="card-body   ">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="hiring-form"
                        >
                          <div className="row justify-content-center">
                            <div className="col-12 p-0">
                              <h4>Create Event</h4>
                            </div>
                            <div className="col-12 p-0">
                              <div className="form-check">
                                {/* <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="visibiltyType"
                                  {...register("visibiltyType", {
                                    onChange: (event) => {
                                      // Sync state with form
                                      setVisibilityType(
                                        event.target.checked
                                          ? "public"
                                          : "private"
                                      );
                                    },
                                  })}
                                  checked={visibilityType === "public"} // Controlled by state
                                /> */}
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="visibiltyType"
                                  {...register("visibiltyType", {
                                    onChange: (event) => {
                                      setVisibilityType(
                                        event.target.checked
                                          ? "public"
                                          : "private"
                                      );
                                    },
                                  })}
                                  checked={visibilityType === "public"} // Controlled by state
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="visibiltyType"
                                >
                                  Make this event Public
                                </label>
                                <p className="para">
                                  All Jubal Talents memeber can see your event
                                </p>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 p-0 pe-md-3">
                              <label htmlFor="Eventtype" className="form-label">
                                Event Type
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <select
                                className="form-select"
                                id="Eventtype"
                                {...register("Eventtype", {
                                  required: "Event Type is required",
                                })}
                                onChange={(event) => {
                                  if (event.target.value === "Paid") {
                                    setTypeOfEvent("Paid");
                                  } else {
                                    setTypeOfEvent("Free"); // Optionally handle other cases
                                  }
                                }}
                              >
                                <option value="">Select Event Type</option>
                                <option value="Paid">Paid</option>
                                <option value="Free">Free</option>
                              </select>
                              {errors.Eventtype && (
                                <span className="form-text text-danger">
                                  {errors.Eventtype.message}
                                </span>
                              )}
                            </div>
                            <div className="col-12 col-md-6 p-0 ps-md-3">
                              <label htmlFor="fname" className="form-label">
                                Event title
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="fname"
                                placeholder="Event Title"
                                {...register("fname", {
                                  required: "Event Title is required",
                                })}
                              />
                              {errors.fname && (
                                <span className="form-text text-danger">
                                  {errors.fname.message}
                                </span>
                              )}
                            </div>
                            <div className="col-12 col-md-6 p-0 pe-md-3">
                              <label htmlFor="type" className="form-label">
                                What type of event are you hosting?
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <select
                                className="form-select"
                                id="type"
                                {...register("type", {
                                  required: "Event Type is required",
                                })}
                                value={watch("type") || ""}
                              >
                                <option value="">Select Event Type</option>
                                {EventType &&
                                  EventType.map((res, index) => {
                                    return (
                                      <option value={res?.type} key={index}>
                                        {res?.type}
                                      </option>
                                    );
                                  })}
                              </select>
                              {errors.type && (
                                <span className="form-text text-danger">
                                  {errors.type.message}
                                </span>
                              )}
                            </div>
                            {/* <div className="col-12 col-md-6 p-0">
                              <label
                                htmlFor="entertemenet"
                                className="form-label"
                              >
                                What type of entertainment would you like?
                              </label>
                              <select
                                className="form-select"
                                id="entertemenet"
                                {...register("entertemenet", {
                                  required: "Event Type is required",
                                })}
                                value={watch("entertemenet") || ""}
                              >
                                <option value="">Select Event Type</option>
                                {Entertainment &&
                                  Entertainment.map((res) => {
                                    return (
                                      <option value={res?.type}>
                                        {res?.type}
                                      </option>
                                    );
                                  })}
                              </select>
                              {errors.entertemenet && (
                                <span className="form-text text-danger">
                                  {errors.entertemenet.message}
                                </span>
                              )}
                            </div> */}
                            <div className="col-12 col-md-6 p-0 ps-md-3">
                              <label htmlFor="music" className="form-label">
                                What type of instrument genre are you looking
                                for?
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              {/* <select
                                className="form-select"
                                id="music"
                                {...register("music", {
                                  required: "Event Type is required",
                                })}
                                value={watch("music") || ""}
                              >
                                <option value="">Select Music Type</option>
                                {Music &&
                                  Music.map((res) => {
                                    return (
                                      <option value={res?.type}>
                                        {res?.type}
                                      </option>
                                    );
                                  })}
                              </select> */}
                              <Controller
                                name="music"
                                control={control}
                                rules={{ required: "Music type is required" }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={musicOptions}
                                    isMulti
                                    placeholder="Select Music Type"
                                    className="selected-tag"
                                    classNamePrefix="react-select"
                                    onChange={(selectedOptions) => {
                                      const selectedValues = selectedOptions
                                        .map((option) => option.value)
                                        ?.join(",");
                                      field.onChange(selectedValues);
                                    }}
                                    value={
                                      watch("music")
                                        ? musicOptions.filter((option) =>
                                            watch("music").includes(
                                              option.value
                                            )
                                          )
                                        : []
                                    }
                                  />
                                )}
                              />
                              {errors.music && (
                                <span className="form-text text-danger">
                                  {errors.music.message}
                                </span>
                              )}
                            </div>
                            {visibilityType === "private" && (
                              <div className="col-12 p-0">
                                <label
                                  htmlFor="addTalent"
                                  className="form-label"
                                >
                                  Add Talent to your event?
                                </label>
                                <input
                                  id="addTalent"
                                  placeholder="Add Talent"
                                  className="form-control"
                                  onFocus={(e) => {
                                    toggleTalentModal();
                                    e.target.blur();
                                  }}
                                />
                              </div>
                            )}

                            {visibilityType === "private" &&
                              Array.isArray(privatePerformers) &&
                              privatePerformers?.length >= 0 && (
                                <div className="d-flex align-items-center gap-3 flex-wrap">
                                  {privatePerformers &&
                                    privatePerformers?.map((item, index) => (
                                      <div key={item.id}>
                                        <div className="user-det-head border rounded-pill p-2  pe-4">
                                          <div className="user-det d-flex   justify-content-between">
                                            <div className="d-flex gap-3 align-items-center">
                                              <Link
                                                to={`/profile/${item?.User?.id}`}
                                              >
                                                <span className="user-dp no-img">
                                                  {" "}
                                                  {
                                                    item?.firstName?.toUpperCase()[0]
                                                  }
                                                </span>
                                              </Link>
                                              <div className="user-ac">
                                                <div className="d-flex gap-3 align-items-center">
                                                  <span className="username">
                                                    <Link to="#">
                                                      {item?.firstName}{" "}
                                                      {item?.lastName}
                                                    </Link>
                                                  </span>

                                                  <span
                                                    className="pointer"
                                                    onClick={() =>
                                                      handleRemovePerformer(
                                                        index
                                                      )
                                                    }
                                                  >
                                                    {SvgIcons?.crossIcon}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              )}
                            <div className="col-12 p-0">
                              <label htmlFor="location" className="form-label">
                                Event Location
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <GooglePlaceAutocomplete
                                selectedPlace={selectedPlace}
                                setSelectedPlace={setSelectedPlace}
                                defaultValues={
                                  eventData?.eventTimeLocation?.location
                                }
                              />
                              {errors.location && (
                                <span className="form-text text-danger">
                                  {errors.location.message}
                                </span>
                              )}
                            </div>

                            <div className="col-12 col-md-6 p-0 pe-md-3">
                              <label
                                htmlFor="start_date"
                                className="form-label"
                              >
                                Start Date
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="start_date"
                                min={minDate}
                                // max={today} // Set max to today
                                placeholder="Start Date"
                                onFocus={handleFocus}
                                disabled={
                                  eventData?.eventTimeLocation?.start_date &&
                                  eventData?.eventStatus == 0
                                    ? false // Enable the input if both conditions are met
                                    : true // Otherwise, disable the input
                                }
                                {...register("start_date", {
                                  required: "Start date is required",
                                })}
                              />
                              {errors.start_date && (
                                <span className="form-text text-danger">
                                  {errors.start_date.message}
                                </span>
                              )}
                            </div>

                            <div className="col-12 col-md-6 p-0 ps-md-3">
                              <label
                                htmlFor="start_time"
                                className="form-label"
                              >
                                Start Time
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <input
                                type="time"
                                className="form-control"
                                id="start_time"
                                placeholder="Start time"
                                min={
                                  selectedStartDate === today
                                    ? currentTime
                                    : undefined
                                } // Set min time if start date is today
                                onFocus={handleFocus}
                                {...register("start_time", {
                                  required: "Start time is required",
                                })}
                                disabled={
                                  eventData?.eventTimeLocation?.start_time &&
                                  eventData?.eventStatus == 0
                                    ? false // Enable the input if both conditions are met
                                    : true // Otherwise, disable the input
                                }
                              />

                              {errors.start_time && (
                                <span className="form-text text-danger">
                                  {errors.start_time.message}
                                </span>
                              )}
                            </div>

                            <div className="col-12 col-md-6 p-0 pe-md-3">
                              <label htmlFor="end_date" className="form-label">
                                End Date
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="end_date"
                                placeholder="End Date"
                                min={selectedStartDate || minDate} // Set minimum to selectedStartDate if selected, otherwise today
                                onFocus={handleFocus}
                                {...register("end_date", {
                                  required: "End date is required",
                                  validate: (value) =>
                                    !selectedStartDate ||
                                    value >= selectedStartDate ||
                                    "End date must be after start date",
                                })}
                                disabled={
                                  eventData?.eventTimeLocation?.end_date &&
                                  eventData?.eventStatus == 0
                                    ? false // Enable the input if both conditions are met
                                    : true // Otherwise, disable the input
                                }
                              />
                              {errors.end_date && (
                                <span className="form-text text-danger">
                                  {errors.end_date.message}
                                </span>
                              )}
                            </div>

                            <div className="col-12 col-md-6 p-0 ps-md-3">
                              <label htmlFor="end_time" className="form-label">
                                End Time
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <input
                                type="time"
                                className="form-control"
                                id="end_time"
                                placeholder="End Time"
                                min={
                                  selectedStartDate === watch("end_date")
                                    ? selectedStartTime
                                    : undefined
                                }
                                onFocus={handleFocus}
                                {...register("end_time", {
                                  required: "End time is required",
                                  validate: (value) => {
                                    if (
                                      selectedStartDate === watch("end_date")
                                    ) {
                                      // Convert times to minutes for comparison
                                      const startMinutes =
                                        convertTimeToMinutes(selectedStartTime);
                                      const endMinutes =
                                        convertTimeToMinutes(value);
                                      // Check if difference is at least 60 minutes (1 hour)
                                      const timeDifference =
                                        endMinutes - startMinutes;

                                      if (timeDifference < 60) {
                                        toast.error(
                                          "Event must be at least 1 hour long"
                                        );
                                        return "Event must be at least 1 hour long";
                                      }
                                    }
                                    return true;
                                  },
                                })}
                                disabled={
                                  eventData?.eventTimeLocation?.end_time &&
                                  eventData?.eventStatus == 0
                                    ? false // Enable the input if both conditions are met
                                    : true // Otherwise, disable the input
                                }
                              />
                              {/* {console.log(
                                " eventData?.eventStatus",
                                eventData?.eventTimeLocation?.end_time &&
                                  eventData?.eventStatus == 0
                              )} */}
                              {errors.end_time && (
                                <span className="form-text text-danger">
                                  {errors.end_time.message}
                                </span>
                              )}
                            </div>
                            <div
                              className={
                                TypeOfEvent === "Paid"
                                  ? "col-12 p-0 col-md-6 pe-md-3"
                                  : "col-12 p-0"
                              }
                            >
                              <label htmlFor="budget" className="form-label">
                                How many people are you expecting?
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="budget"
                                placeholder="Number of People"
                                {...register("budget", {
                                  required: "Number is required",
                                  pattern: {
                                    value: /^[0-9]+$/,
                                    message: "Budget should be a number",
                                  },
                                })}
                                onWheel={(event) => {
                                  event.preventDefault();
                                }}
                              />
                              {errors.budget && (
                                <span className="form-text text-danger">
                                  {errors.budget.message}
                                </span>
                              )}
                            </div>
                            {TypeOfEvent === "Paid" && (
                              <div className="col-12 col-md-6 ps-md-3 p-0">
                                <label htmlFor="price" className="form-label">
                                  Ticket Price for(Each)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="price"
                                  placeholder="Enter ticket price"
                                  {...register("price", {
                                    pattern: {
                                      value: /^[0-9]+$/,
                                      message: "price should be a number",
                                    },
                                    required:
                                      TypeOfEvent === "Paid"
                                        ? "Ticket price is required"
                                        : false,
                                  })}
                                />
                                {errors.price && (
                                  <span className="form-text text-danger">
                                    {errors.price.message}
                                  </span>
                                )}
                              </div>
                            )}
                            <div className="col-12 p-0">
                              <label htmlFor="info" className="form-label">
                                Additional Information
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="info"
                                rows={3}
                                placeholder="Type here..."
                                {...register("info", {
                                  required:
                                    "Additional information is required",
                                })}
                              />
                              {errors.info && (
                                <span className="form-text text-danger">
                                  {errors.info.message}
                                </span>
                              )}
                            </div>
                            {/* {eventData?.eventImage ?
                            <div className="col-12  p-0">
                              <div className="position-relative img-preview">
                              <img
                                          src={eventData?.eventImage}
                                          alt=""
                                        />
                              </div>
                              </div>
                            : */}
                            <div className="col-12  p-0">
                              {imageUrl?.image_view ? (
                                <>
                                  <div className="position-relative img-preview">
                                    <button
                                      className="btn btn-link"
                                      onClick={imageRemoveHandler}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19"
                                        height="19"
                                        viewBox="0 0 19 19"
                                        fill="none"
                                      >
                                        <path
                                          d="M13.7188 5.12305L4.71875 14.123M4.71875 5.12305L13.7188 14.123"
                                          stroke="black"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </button>

                                    {/* {imageUrl.image_url &&
                                      (imageUrl?.image_url?.type?.startsWith(
                                        "image/"
                                      ) ? (
                                        <img
                                          src={imageUrl?.image_view}
                                          key={imageUrl.image_url?.name}
                                          alt=""
                                        />
                                      ) : (
                                        imageUrl?.image_url?.type?.startsWith(
                                          "video/"
                                        ) && (
                                          <video
                                            autoPlay
                                            controls
                                            muted
                                            loop={true}
                                          >
                                            <source
                                              src={imageUrl?.image_view}
                                              type={imageUrl?.image_url?.type}
                                              key={imageUrl.image_url?.name}
                                            />
                                          </video>
                                        )
                                      ))} */}
                                    <img
                                      src={imageUrl?.image_view}
                                      key={imageUrl.image_url?.name}
                                      alt=""
                                    />
                                  </div>
                                </>
                              ) : (
                                <label
                                  for="file"
                                  className="form-label w-100 p-0"
                                >
                                  <div className="fileUploadBox d-flex justify-content-center flex-column align-items-center">
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="41"
                                        height="41"
                                        viewBox="0 0 41 41"
                                        fill="none"
                                      >
                                        <path
                                          d="M20.6308 0.755859C9.76922 0.755859 0.944336 9.58074 0.944336 20.4423C0.944336 31.3038 9.76922 40.1287 20.6308 40.1287C31.4923 40.1287 40.3172 31.3038 40.3172 20.4423C40.3172 9.58074 31.4923 0.755859 20.6308 0.755859ZM20.6308 2.54553C30.5259 2.54553 38.5275 10.5481 38.5275 20.4423C38.5275 22.2302 38.259 23.9527 37.7723 25.5804L32.4489 20.257C31.9075 19.7157 31.1907 19.4445 30.474 19.4445C29.7572 19.4445 29.0404 19.7157 28.4991 20.257L23.7627 24.9934L15.447 16.6777C14.9056 16.1363 14.1888 15.8652 13.4721 15.8652C12.7553 15.8652 12.0385 16.1363 11.4971 16.6777L3.3076 24.8672C2.93265 23.4219 2.73996 21.9354 2.73401 20.4423C2.73401 10.5472 10.7356 2.54553 20.6308 2.54553ZM13.4721 17.6441C13.7271 17.6441 13.9821 17.7426 14.1817 17.943L22.4974 26.2587L17.3136 31.4425C17.1506 31.6113 17.0604 31.8373 17.0624 32.0719C17.0645 32.3066 17.1586 32.531 17.3245 32.6969C17.4904 32.8628 17.7148 32.9569 17.9495 32.959C18.1841 32.961 18.4101 32.8708 18.5789 32.7078L29.7644 21.5223C29.8567 21.4276 29.967 21.3523 30.0889 21.3008C30.2107 21.2494 30.3417 21.2229 30.474 21.2229C30.6062 21.2229 30.7372 21.2494 30.8591 21.3008C30.9809 21.3523 31.0913 21.4276 31.1836 21.5223L37.1074 27.4462C34.3844 33.8514 28.0373 38.339 20.6308 38.339C12.9772 38.339 6.47443 33.5427 3.91162 26.7938L12.7616 17.9439C12.962 17.7434 13.217 17.645 13.4721 17.645V17.6441Z"
                                          fill="#CAC8C8"
                                        />
                                        <path
                                          d="M20.6309 6.12524C18.1701 6.12524 16.1567 8.13863 16.1567 10.5994C16.1567 13.0602 18.1701 15.0736 20.6309 15.0736C23.0917 15.0736 25.1051 13.0602 25.1051 10.5994C25.1051 8.13863 23.0917 6.12524 20.6309 6.12524ZM20.6309 7.91492C22.1244 7.91492 23.3154 9.10595 23.3154 10.5994C23.3154 12.0929 22.1244 13.2839 20.6309 13.2839C19.1374 13.2839 17.9464 12.0929 17.9464 10.5994C17.9464 9.10595 19.1374 7.91492 20.6309 7.91492Z"
                                          fill="#CAC8C8"
                                        />
                                      </svg>
                                    </span>

                                    <p>
                                      Upload here
                                      <span className="text-danger fs-2 ps-1">
                                        *
                                      </span>
                                    </p>
                                  </div>
                                </label>
                              )}
                              <input
                                type="file"
                                className="form-control d-none"
                                id="file"
                                onChange={imageUploadHandler}
                                accept="image/*"
                              />
                            </div>
                            <p>{ImageErrorState}</p>
                            {/* <div className="col-12 p-0">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="visibiltyType"
                                  {...register("visibiltyType", {
                                    onChange: (event) => {
                                      // Sync state with form
                                      setVisibilityType(
                                        event.target.checked
                                          ? "public"
                                          : "private"
                                      );
                                    },
                                  })}
                                  checked={visibilityType === "public"} // Controlled by state
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="visibiltyType"
                                >
                                  Make this event Public
                                </label>
                                <p className="para">
                                  All Jubal Talents memeber can see your event
                                </p>
                              </div>
                            </div> */}
                            <div className="col-12 p-0">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="announceEvent"
                                  {...register("announceEvent", {
                                    onChange: (event) =>
                                      setAnnounceEvent(event.target.checked), // Sync state with form
                                  })}
                                  checked={announceEvent} // Controlled by state
                                />

                                <label
                                  className="form-check-label"
                                  htmlFor="announceEvent"
                                >
                                  Announce this event to all talents
                                </label>
                              </div>
                            </div>
                            {isEdit ? (
                              <div className="col-12 p-0">
                                <div className="row justify-content-center">
                                  {" "}
                                  {eventData?.eventStatus != 3 &&
                                    eventData?.eventStatus != 1 && (
                                      <div className="col-12 col-md-4">
                                        {" "}
                                        <button
                                          type="submit"
                                          className="btn btn-light w-100"
                                          onClick={() => setEventStatus(0)}
                                          disabled={eventLoading}
                                        >
                                          {eventStatus == 1 && eventLoading ? (
                                            <Spinner />
                                          ) : (
                                            "Save Draft"
                                          )}
                                        </button>
                                      </div>
                                    )}
                                  <div className="col-12 col-md-4">
                                    <button
                                      type="submit"
                                      className="btn btn-primary w-100"
                                      onClick={() => setEventStatus(3)}
                                      disabled={eventLoading}
                                    >
                                      {eventStatus == 3 && eventLoading ? (
                                        <Spinner />
                                      ) : (
                                        "Publish Now"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="col-12 p-0">
                                <div className="row justify-content-center">
                                  <div className="col-12 col-md-4">
                                    {" "}
                                    <button
                                      type="submit"
                                      className="btn btn-light w-100"
                                      onClick={() => setEventStatus(0)}
                                      disabled={imageUrl?.image_view == ""}
                                    >
                                      {eventStatus == 1 && eventLoading ? (
                                        <Spinner />
                                      ) : (
                                        "Save Draft"
                                      )}
                                    </button>
                                  </div>
                                  <div className="col-12 col-md-4">
                                    <button
                                      type="submit"
                                      className="btn btn-primary w-100"
                                      onClick={() => setEventStatus(3)}
                                      disabled={imageUrl?.image_view == ""}
                                    >
                                      {eventStatus == 3 && eventLoading ? (
                                        <Spinner />
                                      ) : (
                                        "Publish Now"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* =========================== */}
            {/*center content end */}
            {/* =========================== */}
          </div>
          {/* =========================== */}
          {/* Main feed grid End */}
          {/* =========================== */}
        </div>
      </section>
      {talentModal && (
        <Modal
          isOpen={true}
          toggle={toggleTalentModal}
          className="modal-dialog modal-dialog-centered addressmodal modal-dialog-scrollable modal-fullscreen-sm-down"
        >
          <AddTalentModal
            toggleTalentModal={toggleTalentModal}
            privatePerformers={privatePerformers}
            setPrivatePerformers={setPrivatePerformers}
          />
        </Modal>
      )}
      <ImageCropper
        show={showModal}
        handleClose={handleClose}
        image={selectedImage}
        onCrop={handleCrop}
        width="627"
        height="330"
        ratio=" 1.5385"
      />
    </>
  );
}

export default CreateInstrument;
