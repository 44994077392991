import { useEffect, useState } from "react";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";

function SavedActivity() {
  const [AllSavedPost, setAllSavedPost] = useState([]);

  const [sortOption, setSortOption] = useState("Newest");

  useEffect(() => {
    handleFetchRequest(ApiUrl.get_my_saved_post).then((response) => {
      if (response.status === true) {
        let likesData = response?.data?.posts || [];
        likesData = sortPosts(likesData, sortOption);
        setAllSavedPost(likesData);
        // setisLoading(false);
        console.log("ress....Save", response.data?.posts);

        // Sort the data based on the initial sorting option
      }
    });
  }, [sortOption]);

  const sortPosts = (posts, option) => {
    console.log("[osss]", posts, option);
    return posts.sort((a, b) => {
      const dateA = new Date(a?.Post?.createdAt);
      const dateB = new Date(b?.Post?.createdAt);

      if (option === "Newest") {
        return dateB - dateA;
      } else if (option === "Oldest") {
        return dateA - dateB;
      }
      return 0;
    });
  };

  const handleSortChange = (option) => {
    setSortOption(option);
  };

  return (
    <div
      className="tab-pane fade"
      id="saved-tab-pane"
      role="tabpanel"
      aria-labelledby="saved-tab"
      tabIndex={0}
    >
      <div className="filter-row">
        <div className="d-flex align-items-center justify-content-between">
          <p>Performance Saves</p>
          <div className="inner-filter-row">
            <div>
              <div className="dropdown dropdown-menu-end">
                <span className="selected"></span>
                <button
                  className="btn btn-light dropdown-toggle d-flex align-items-center gap-3"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={21}
                      height={12}
                      viewBox="0 0 21 12"
                      fill="none"
                    >
                      <path
                        d="M8.07872 11.8284H12.6918V9.86084H8.07872V11.8284ZM0.00585938 0.0231934V1.99072H20.7646V0.0231934H0.00585938ZM3.46566 6.90955H17.3048V4.94202H3.46566V6.90955Z"
                        fill="#818898"
                      />
                    </svg>
                  </span>
                  Filter by
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className={
                        sortOption === "Newest"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      href="#"
                      onClick={() => handleSortChange("Newest")}
                    >
                      Newest
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        sortOption === "Oldest"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      href="#"
                      onClick={() => handleSortChange("Oldest")}
                    >
                      Oldest
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <span className="selected-opt">
            {sortOption}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={12}
                height={12}
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M1.06994 0.969544C0.882466 1.15707 0.77715 1.41138 0.77715 1.67654C0.77715 1.94171 0.882466 2.19602 1.06994 2.38354L4.60594 5.91954L1.06994 9.45554C0.974427 9.54779 0.898244 9.65814 0.845835 9.78014C0.793426 9.90214 0.76584 10.0334 0.764686 10.1661C0.763532 10.2989 0.788834 10.4306 0.839115 10.5535C0.889396 10.6764 0.963649 10.788 1.05754 10.8819C1.15143 10.9758 1.26309 11.0501 1.38598 11.1004C1.50888 11.1506 1.64056 11.1759 1.77334 11.1748C1.90612 11.1736 2.03734 11.1461 2.15934 11.0936C2.28135 11.0412 2.39169 10.9651 2.48394 10.8695L6.01994 7.33354L9.55594 10.8695C9.74454 11.0517 9.99714 11.1525 10.2593 11.1502C10.5215 11.1479 10.7723 11.0428 10.9578 10.8574C11.1432 10.672 11.2483 10.4211 11.2506 10.1589C11.2529 9.89675 11.1521 9.64415 10.9699 9.45554L7.43394 5.91954L10.9699 2.38354C11.1521 2.19494 11.2529 1.94234 11.2506 1.68014C11.2483 1.41795 11.1432 1.16713 10.9578 0.981726C10.7723 0.796318 10.5215 0.691148 10.2593 0.68887C9.99714 0.686592 9.74454 0.787386 9.55594 0.969544L6.01994 4.50554L2.48394 0.969544C2.29641 0.782073 2.0421 0.676758 1.77694 0.676758C1.51177 0.676758 1.25746 0.782073 1.06994 0.969544Z"
                  fill="#86889A"
                />
              </svg>
            </span>
          </span> */}
          </div>
        </div>
      </div>

      <div className="grid-gallery">
        {AllSavedPost &&
          AllSavedPost.map((data, index) => {
            return (
              <div className="post-content position-relative" key={index}>
                <p className="likes position-absolute">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 16 18"
                      fill="none"
                    >
                      <path
                        d="M1.99298 17.5705C1.72356 17.5705 1.46518 17.4686 1.27467 17.2872C1.08416 17.1058 0.97713 16.8598 0.97713 16.6033L0.824752 2.42449C0.81252 2.13661 0.860188 1.84926 0.965015 1.57898C1.06984 1.3087 1.22976 1.06081 1.43557 0.849583C1.64138 0.638354 1.88902 0.467949 2.16425 0.348168C2.43948 0.228387 2.73687 0.161594 3.03931 0.15163L12.7813 0.103271C13.0842 0.108307 13.383 0.170096 13.6607 0.285108C13.9385 0.40012 14.1897 0.566102 14.4001 0.773573C14.6105 0.981045 14.7758 1.22594 14.8868 1.49427C14.9977 1.76261 15.0521 2.04912 15.0467 2.33745L15.1889 16.5162C15.1906 16.6857 15.1456 16.8527 15.0582 17.0003C14.9708 17.148 14.8443 17.2713 14.6911 17.3577C14.5367 17.4426 14.3615 17.4873 14.1832 17.4873C14.0049 17.4873 13.8297 17.4426 13.6753 17.3577L7.88492 14.3014L2.51107 17.4254C2.35193 17.51 2.1749 17.5596 1.99298 17.5705ZM7.84429 12.2026C8.0208 12.2029 8.19478 12.2427 8.35221 12.3187L13.1369 14.843L13.015 2.3181C13.015 2.12467 12.8829 1.98926 12.8016 1.99894L3.04947 2.08598C2.9682 2.08598 2.85646 2.21171 2.85646 2.40515L2.97836 14.8817L7.3262 12.338C7.48411 12.252 7.66235 12.2054 7.84429 12.2026Z"
                        fill="white"
                      ></path>
                    </svg>
                  </span>
                  234
                </p>
                {data?.Post?.mediaType === "photo" ? (
                  <img src={data?.Post?.postImageUrl} alt="" />
                ) : (
                  data?.Post?.mediaType === "video" && (
                    <video autoPlay controls muted loop={true}>
                      <source src={data?.Post?.postImageUrl} />
                    </video>
                  )
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default SavedActivity;
