import React from "react";

function ProfileDetialsLoader() {
  return (
    <div className="row">
      <div className="col-lg-3"></div>
      <div className="Loader col-lg-9">
        <div className="wrapper">
          <div className="Image-wapper">
            <div className="image" style={{ height: "220px" }}></div>
            <div className="image profileImage"></div>
          </div>
          <div className="prifileabout text-center">
            <div className="text" style={{ width: "40%", margin: "0 auto" }}>
              <div className="text-line"> </div>
              <div className="text-line"></div>
              <div className="text-line"></div>
              <div className="text-line"></div>
              <div className="text-line"></div>
            </div>
          </div>
          <div className="wrapper-cell">
            <div className="text">
              <div className=""></div>
              <div className="text-line"> </div>
              <div className="text-line"></div>
              <div className="text-line"></div>
              <div className="text-line"></div>
              <div className="text-line"></div>
              <div className="text-line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileDetialsLoader;
