import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AgreementModal from "../../Components/Modals/AgreementModal";
import { Modal } from "reactstrap";
import {
  bookingStatusTypes,
  getLoginUserCaption,
  notificationTypes,
} from "../../Helper/Helper";
import { Button } from "bootstrap/dist/js/bootstrap.bundle";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { toast } from "material-react-toastify";
import RatingModal from "../../Components/Modals/RatingModal";
import CheckBacklineService from "../../Components/Modals/CheckBacklineService";
import { SvgIcons } from "../../Components/Svg/SvgIcons";
import AgreementDownloadFile from "./AgreementDownloadFile";
import { RoutesLink } from "../../utility/RoutesLink";
import moment from "moment";
import { confirm } from "../../Components/Modals/ConfirmDataCheck";

function EventRequestDetail({ bookingDetail, rating }) {
  const navigate = useNavigate();
  const userInfo = useSelector((store) => store?.user?.userDetails);
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const [backlineService, setBacklineService] = useState(false);
  const [Selectedreason, setSelectedreason] = useState("");
  const [agreementModal, setAgreementModal] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [Commsion, setCommsion] = useState("");

  const startDateString = `${bookingDetail?.performanceDetail?.date}T${bookingDetail?.performanceDetail?.startTime}:00`;
  const endDateString = `${bookingDetail?.performanceDetail?.date}T${bookingDetail?.performanceDetail?.endTime}:00`;

  const startTime = new Date(startDateString);
  const endTime = new Date(endDateString);

  const duration = endTime - startTime;

  const hours = Math.floor(duration / 3600000);

  const toggleAgreementModal = () => {
    setAgreementModal(!agreementModal);
  };
  const handleShowBacklineService = () => {
    setBacklineService((pre) => !pre);
  };
  // Returns the booking status text for vendors
  const getBookingStatusVendorText = (
    status,
    isCompletedByVendor,
    isCompletedByTalent
  ) => {
    switch (String(status)) {
      case bookingStatusTypes.pending:
        return (
          <span className="badge rounded-pill text-bg-warning">
            Hiring request sent
          </span>
        );
      case bookingStatusTypes.acceptedByTalent:
        return (
          <span className="badge rounded-pill text-bg-primary">
            Agreement signed by Talent
          </span>
        );
      case bookingStatusTypes.cancelled:
        return (
          <span className="badge rounded-pill text-bg-danger">Cancelled</span>
        );
      case bookingStatusTypes.approved:
        if (isCompletedByVendor && !isCompletedByTalent) {
          return (
            <span className="badge rounded-pill text-bg-success">
              Booking completed by you
            </span>
          );
        }
        if (!isCompletedByVendor && isCompletedByTalent) {
          return (
            <span className="badge rounded-pill text-bg-primary">
              Booking completed by talent
            </span>
          );
        }
        return (
          <span className="badge rounded-pill text-bg-success">
            Booking Confirmed
          </span>
        );
      case bookingStatusTypes.completed:
        return (
          <span className="badge rounded-pill text-bg-success">Completed</span>
        );
      case bookingStatusTypes.draft:
        return (
          <span className="badge rounded-pill text-bg-warning">Draft</span>
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    if (bookingDetail?.talent?.id) {
      handleFetchRequest(
        `${ApiUrl.get_user_detials}${bookingDetail?.talent?.id}`
      ).then((res) => {
        if (res.status === true) {
          setUserDetails(res.data);
        }
      });
    }
  }, [bookingDetail]);

  useEffect(() => {
    handleFetchRequest(ApiUrl.setting).then((res) => {
      if (res.status == true) {
        console.log("resss =====>>>", res?.data);
        setCommsion(res?.data?.bookingCommission);
      }
    });
  });

  const getBookingStatusTalentText = (
    status,
    isCompletedByVendor,
    isCompletedByTalent
  ) => {
    switch (String(status)) {
      case bookingStatusTypes.pending:
        return (
          <span className="badge rounded-pill text-bg-warning">
            Hiring request received
          </span>
        );
      case bookingStatusTypes.acceptedByTalent:
        return (
          <span className="badge rounded-pill text-bg-success">
            Agreement signed by Talent
          </span>
        );
      case bookingStatusTypes.cancelled:
        return (
          <span className="badge rounded-pill text-bg-danger">Cancelled</span>
        );
      case bookingStatusTypes.approved:
        if (isCompletedByVendor && !isCompletedByTalent) {
          return (
            <span className="badge rounded-pill text-bg-success">
              Booking completed by Vendor
            </span>
          );
        }
        if (!isCompletedByVendor && isCompletedByTalent) {
          return (
            <span className="badge rounded-pill text-bg-success">
              Booking completed by you
            </span>
          );
        }
        return (
          <span className="badge rounded-pill text-bg-success">
            Booking Confirmed
          </span>
        );
      case bookingStatusTypes.completed:
        return (
          <span className="badge rounded-pill text-bg-success">Completed</span>
        );
      default:
        return "";
    }
  };

  const RejectBooking = (id, status, usertype) => {
    let options2 = {
      data: {
        // Provide default values, even if conditions don't match
      },
    };
    if (userInfo?.type == 2) {
      options2.vendorReason = Selectedreason;
      options2.status = 2;
    }
    if (userInfo?.type == 3) {
      options2.talentReason = Selectedreason;
      options2.status = 2;
    }
    handleFetchRequest(
      ApiUrl?.edit_booking + bookingDetail?.id,
      "POST",
      options2
    ).then((response) => {
      if (response.status == true) {
        toast.error("Booking Rejected");
        window?.location?.reload(true);
      }
    });
  };

  const [ratingModal, setRatingModal] = useState(false);

  const toggleRatingModal = () => {
    setRatingModal(!ratingModal);
  };

  const notifyArtist = (title, id) => {
    const data = {
      userId: id,
      message: `Event planner is waiting for your reply for event ${title}`,
      body: {
        data: {
          id: bookingDetail?.id,
          type: notificationTypes?.get_booking,
        },
        screen: notificationTypes?.get_booking,
      },
    };
    handleFetchRequest(ApiUrl?.notifyArtist, "POST", data).then((response) => {
      if (response.status == true) {
        toast.success(response.message);
      }
    });
  };

  const HandleCompliteBooking = async (id, amount, bookingapidata) => {
    if (
      await confirm(
        "You need to complete the payment first to complete the booking, Are you sure want to complete the payment"
      )
    ) {
      console.log("first", userInfo?.type);
      if (userInfo?.type == 2) {
        navigate(RoutesLink.hiring_payment, {
          state: {
            amount: bookingDetail?.budget,
            Commision: Commsion,
            bookingId: bookingDetail?.id,
          },
        });
        return;
      } else {
      }
      let options2 = {};

      if (userInfo?.type == 2) {
        options2.vendorBookingCompleted = true;
        // options2.status = 3;
      } else if (
        userInfo?.type == 3 &&
        bookingapidata?.BookingPayments[0]?.paidTo == "admin"
      ) {
        options2.talentBookingCompleted = true;
      } else {
        toast.error(
          "Payment is pending from vendor side, you can only mark the booking completed when vendor payment done"
        );
      }
      handleFetchRequest(
        ApiUrl?.edit_booking + bookingDetail?.id,
        "POST",
        options2
      ).then((response) => {
        if (response.status == true) {
          toast.success("Booking completed by you");
          navigate(-1);
        }
      });
    }
  };

  const HandleTalentCompliteBooking = async (id, amount, bookingapidata) => {
    console.log("BookingPayments", bookingapidata);
    if (await confirm(" Are you sure want to complete the payment")) {
      let options2 = {};
      if (userInfo?.type == 3 && bookingapidata?.BookingPayments.length > 0) {
        options2.talentBookingCompleted = true;
        handleFetchRequest(
          ApiUrl?.edit_booking + bookingDetail?.id,
          "POST",
          options2
        ).then((response) => {
          if (response.status == true) {
            toast.success("Booking completed by you");
            navigate(-1);
          }
        });
      } else {
        toast.error(
          "Payment is pending from vendor side, you can only mark the booking completed when vendor payment done"
        );
      }
    }
  };
  return (
    <>
      <div className="center-content d-block">
        <div>
          <div className="card tab-card activity-card manage-card">
            <div className="card-body  position-relative">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/magane_request">Event request</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {bookingDetail.Event?.eventType}
                  </li>
                </ol>
              </nav>

              <div className="request-card card event-card confirm-card  ">
                <div className="head">
                  {userInfo?.type == 2 ? (
                    <div className="d-flex gap-2 gap-lg-3 align-items-center flex-wrap">
                      <div className="user-det d-flex gap-3 gap-lg-4 align-items-center">
                        <div className="user-dp">
                          <img
                            src={bookingDetail?.talent?.profilePhoto}
                            alt=""
                          />
                        </div>
                        <div className="user-ac">
                          <div className="d-flex gap-2 align-items-center">
                            <span className="username">
                              <Link
                                to={RoutesLink?.profile}
                                state={{ id: bookingDetail?.id }}
                              >
                                {bookingDetail?.talent?.firstName}{" "}
                                {bookingDetail?.talent?.lastName}
                              </Link>
                            </span>
                            <span className="usertype mx-1">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={13}
                                  height={13}
                                  viewBox="0 0 13 13"
                                  fill="none"
                                >
                                  <circle
                                    cx="5.74979"
                                    cy="6.72714"
                                    r="3.75247"
                                    fill="white"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                    fill="#F4336D"
                                  />
                                </svg>
                              </span>
                              Talent
                            </span>
                          </div>
                          <p className="d-lg-none">
                            {getLoginUserCaption(bookingDetail?.vendor)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex gap-2 gap-lg-3 align-items-center flex-wrap">
                      <div className="user-det d-flex gap-3 gap-lg-4 align-items-center">
                        <div className="user-dp">
                          <img
                            src={bookingDetail?.vendor?.profilePhoto}
                            alt=""
                          />
                        </div>
                        <div className="user-ac">
                          <div className="d-flex gap-2 align-items-center">
                            <span className="username">
                              <Link
                                to={RoutesLink?.profile}
                                state={{ id: bookingDetail?.id }}
                              >
                                {bookingDetail?.vendor?.firstName}{" "}
                                {bookingDetail?.vendor?.lastName}
                              </Link>
                            </span>
                            <span className="usertype vendor mx-1">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={13}
                                  height={13}
                                  viewBox="0 0 13 13"
                                  fill="none"
                                >
                                  <circle
                                    cx="5.74979"
                                    cy="6.72714"
                                    r="3.75247"
                                    fill="white"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              Vendor
                            </span>
                          </div>
                          <p className="d-lg-none">
                            {getLoginUserCaption(bookingDetail?.vendor)}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {userInfo?.type == 2
                    ? getBookingStatusVendorText(
                        bookingDetail?.status,
                        bookingDetail?.vendorBookingCompleted,
                        bookingDetail?.talentBookingCompleted
                      )
                    : getBookingStatusTalentText(
                        bookingDetail?.status,
                        bookingDetail?.vendorBookingCompleted,
                        bookingDetail?.talentBookingCompleted
                      )}
                </div>

                <div className="card-body">
                  <div className="row desc-header">
                    <div className="col-12 col-lg-9 p-0 order-1 order-md-1">
                      <div className="d-flex align-items-center gap-4">
                        <img
                          src={bookingDetail?.Event?.eventImage}
                          className="event-cover"
                          alt=""
                        />
                        <div className="d-flex flex-column gap-2">
                          <h4>{bookingDetail?.Event?.eventName}</h4>
                          <p>{bookingDetail?.Event?.eventType}</p>
                          <span className="location">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="17"
                              viewBox="0 0 15 17"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1 7.27926C1.01186 3.7879 3.85178 0.967216 7.34313 0.979041C10.8345 0.99094 13.6552 3.83086 13.6434 7.32221V7.39381C13.6004 9.6633 12.3332 11.761 10.7796 13.4005C9.89111 14.3231 8.89893 15.1399 7.82281 15.8346C7.53506 16.0835 7.10825 16.0835 6.82051 15.8346C5.21627 14.7904 3.80829 13.4721 2.66096 11.94C1.63836 10.6039 1.05777 8.98224 1 7.30074V7.27926Z"
                                stroke="#343B4E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M7.32111 9.42738C8.44014 9.42738 9.3473 8.52022 9.3473 7.40119C9.3473 6.28215 8.44014 5.375 7.32111 5.375C6.20208 5.375 5.29492 6.28215 5.29492 7.40119C5.29492 8.52022 6.20208 9.42738 7.32111 9.42738Z"
                                stroke="#343B4E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            {bookingDetail?.Event?.eventTimeLocation?.location}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-3 p-0 text-md-end order-2 order-md-2">
                      <div className="d-flex flex-column gap-2 align-items-end">
                        {userInfo?.type == 2 && (
                          <Link
                            className="btn btn-light rounded-circle px-3 py-2"
                            to={RoutesLink?.hireTalent}
                            state={{
                              id: userDetails?.Profile,
                              eventId: {
                                eventId: bookingDetail?.eventId,
                                id: userDetails?.Profile?.userId,
                              },
                              evendData: bookingDetail,
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95515 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43314 1.6506L12.3494 4.56687L13.7725 3.14373Z"
                                fill="#343B4E"
                              ></path>
                            </svg>
                          </Link>
                        )}
                        <p>
                          {bookingDetail?.Event?.eventTimeLocation?.start_date}
                        </p>
                        <Link
                          className="btn btn-link pe-0"
                          to={RoutesLink?.EventDetails}
                          state={bookingDetail?.Event?.id}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="18"
                            viewBox="0 0 10 18"
                            fill="none"
                          >
                            <path
                              d="M9.58151 8.00733L2.22391 0.398358C2.10306 0.272132 1.95929 0.171944 1.80088 0.103572C1.64247 0.0352012 1.47257 0 1.30096 0C1.12935 0 0.959445 0.0352012 0.801037 0.103572C0.642629 0.171944 0.498855 0.272132 0.37801 0.398358C0.135897 0.650683 0 0.992012 0 1.3478C0 1.70358 0.135897 2.04491 0.37801 2.29724L6.81266 9.03084L0.37801 15.6971C0.135897 15.9494 0 16.2908 0 16.6465C0 17.0023 0.135897 17.3437 0.37801 17.596C0.498402 17.7232 0.641973 17.8245 0.800402 17.8938C0.958832 17.9632 1.12897 17.9993 1.30096 18C1.47295 17.9993 1.64309 17.9632 1.80152 17.8938C1.95995 17.8245 2.10352 17.7232 2.22391 17.596L9.58151 9.98701C9.71346 9.8609 9.81876 9.70784 9.89079 9.53749C9.96282 9.36713 10 9.18316 10 8.99717C10 8.81119 9.96282 8.62722 9.89079 8.45686C9.81876 8.2865 9.71346 8.13344 9.58151 8.00733Z"
                              fill="#86889A"
                            ></path>
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {bookingDetail?.BookingPayments?.some(
                    (payment) => payment.paidTo === "admin"
                  ) && (
                    <div className="alert alert-success" role="alert">
                      <div className="d-flex align-items-center gap-3 gap-md-4 gap-xl-5 ">
                        <div>
                          <svg
                            width="58"
                            height="58"
                            viewBox="0 0 58 58"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="29"
                              cy="29"
                              r="28.5"
                              fill="#D7ED7D"
                              fill-opacity="0.31"
                              stroke="#DAE8A2"
                            />
                            <path
                              d="M43.0459 35.5335C44.1204 35.5335 44.9905 36.4036 44.9905 37.4781C44.9905 38.5526 44.1204 39.4256 43.0459 39.4256C44.1204 39.4256 44.9905 40.2956 44.9905 41.3701C44.9905 42.4447 44.1204 43.3147 43.0459 43.3147H34.812C33.3725 43.3147 31.9623 42.9147 30.736 42.1614C29.4834 41.3905 28.0439 40.9993 26.5869 41.011V31.7757C28.2483 31.6472 29.7461 30.7274 30.6017 29.2909C32.3682 26.3332 34.1084 23.1681 34.6485 19.3431C34.7303 18.7738 35.3084 18.4175 35.8574 18.6015C37.4166 19.1241 38.3223 20.6931 38.0589 22.9082C37.7503 25.5034 36.8585 27.7521 36.8585 27.7521H43.0459C44.1204 27.7521 44.9905 28.6222 44.9905 29.6967C44.9905 30.7712 44.1204 31.6442 43.0459 31.6442C44.1204 31.6442 44.9905 32.5143 44.9905 33.5888C44.9905 34.6632 44.1204 35.5335 43.0459 35.5335Z"
                              fill="#B3DA16"
                            />
                            <path
                              opacity="0.08"
                              d="M43.0463 35.5351C44.1208 35.5351 44.9909 36.4052 44.9909 37.4797C44.9909 38.5542 44.1208 39.4272 43.0463 39.4272C44.1208 39.4272 44.9909 40.2973 44.9909 41.3718C44.9909 42.4463 44.1208 43.3164 43.0463 43.3164H40.4302C41.5047 43.3164 42.3748 42.4462 42.3748 41.3718C42.3748 40.2973 41.5047 39.4272 40.4302 39.4272C41.5047 39.4272 42.3748 38.5542 42.3748 37.4797C42.3748 36.4052 41.5047 35.5351 40.4302 35.5351C41.5047 35.5351 42.3748 34.6651 42.3748 33.5906C42.3748 32.516 41.5047 31.646 40.4302 31.646C41.5047 31.646 42.3748 30.7729 42.3748 29.6985C42.3748 28.624 41.5047 27.7539 40.4302 27.7539H43.0463C44.1208 27.7539 44.9909 28.624 44.9909 29.6985C44.9909 30.773 44.1208 31.646 43.0463 31.646C44.1208 31.646 44.9909 32.5161 44.9909 33.5906C44.9909 34.665 44.1208 35.5351 43.0463 35.5351Z"
                              fill="#191E02"
                            />
                            <path
                              d="M28.275 31.2734V41.5175C28.275 42.1626 27.7521 42.6855 27.1071 42.6855H22.8266C22.5041 42.6855 22.2427 42.4241 22.2427 42.1015V30.6894C22.2427 30.3669 22.5041 30.1055 22.8266 30.1055H27.1071C27.7521 30.1055 28.275 30.6283 28.275 31.2734Z"
                              fill="#90B10C"
                            />
                            <path
                              opacity="0.08"
                              d="M28.2756 31.2734V41.519C28.2756 42.1643 27.7529 42.6869 27.1076 42.6869H25.8784C26.5237 42.6869 27.0464 42.1643 27.0464 41.519V31.2734C27.0464 30.6281 26.5237 30.1055 25.8784 30.1055H27.1076C27.7529 30.1055 28.2756 30.6281 28.2756 31.2734Z"
                              fill="black"
                            />
                            <path
                              opacity="0.4"
                              d="M24.3682 31.5699V33.094C24.3682 33.4109 24.1114 33.6678 23.7945 33.6678C23.4776 33.6678 23.2207 33.4109 23.2207 33.094V31.5699C23.2207 31.253 23.4776 30.9961 23.7945 30.9961C24.1114 30.9962 24.3682 31.253 24.3682 31.5699Z"
                              fill="white"
                            />
                            <path
                              d="M28.3226 16.8026V27.0367C28.3226 27.6767 27.5104 27.9513 27.1221 27.4426L24.928 24.5684H13.9042C13.1653 24.5684 12.5664 23.9694 12.5664 23.2307V16.8026C12.5664 16.0638 13.1654 15.4648 13.9042 15.4648H26.9849C27.7237 15.4648 28.3226 16.0638 28.3226 16.8026Z"
                              fill="#FA3131"
                            />
                            <path
                              opacity="0.08"
                              d="M28.3238 16.8026V27.0367C28.3238 27.6755 27.5111 27.9497 27.1231 27.4414L26.3973 26.4915V16.8026C26.3973 16.0635 25.7987 15.4648 25.0596 15.4648H26.986C27.7251 15.4648 28.3238 16.0635 28.3238 16.8026Z"
                              fill="black"
                            />
                            <path
                              opacity="0.4"
                              d="M15.0992 17.5601V19.0842C15.0992 19.4011 14.8423 19.658 14.5254 19.658C14.2085 19.658 13.9517 19.4011 13.9517 19.0842V17.5601C13.9517 17.2432 14.2085 16.9863 14.5254 16.9863C14.8423 16.9863 15.0992 17.2432 15.0992 17.5601Z"
                              fill="white"
                            />
                            <path
                              d="M18.9851 21.9511L17.8518 20.5685C17.6176 20.2827 17.6594 19.8612 17.945 19.6271C18.2308 19.3927 18.652 19.4345 18.8865 19.7203L19.5516 20.5317L22.0479 18.0358C22.3088 17.7745 22.7328 17.7745 22.9937 18.0358C23.255 18.2969 23.255 18.7205 22.9937 18.9817L19.9754 21.9999C19.6953 22.2799 19.2358 22.2564 18.9851 21.9511Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        {/* console.log("bookingDetail",bookingDetail) */}
                        <div>
                          <h4>Your amount has been paid</h4>
                          <p>
                            Your amount ${" "}
                            {
                              bookingDetail?.BookingPayments?.find(
                                (payment) => payment.paidTo === "admin"
                              )?.amount
                            }{" "}
                            will be initiated to talent in a few days
                          </p>
                          <span>
                            {moment(
                              bookingDetail?.BookingPayments?.createdAt
                            ).format("lll")}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-6 p-0">
                      <div className="d-flex flex-column">
                        <p>Contact name</p>
                        <span>{bookingDetail?.contactFullName}</span>
                      </div>
                    </div>
                    <div className="col-6 p-0 text-end">
                      <div className="d-flex flex-column">
                        <p>Title of event</p>
                        <span>{bookingDetail?.Event?.eventName}</span>
                      </div>
                    </div>
                    <div className="col-6 p-0">
                      <div className="d-flex flex-column">
                        <p>Organisation name</p>
                        <span>{bookingDetail?.contactOrganisation}</span>
                      </div>
                    </div>
                    <div className="col-6 p-0 text-end">
                      <div className="d-flex flex-column">
                        <p>Type of performance</p>
                        <span>{bookingDetail?.performanceType}</span>
                      </div>
                    </div>
                    <div className="col-6 p-0">
                      <div className="d-flex flex-column">
                        {/* <p>Thursday. 12 July, 2024</p> */}
                        <p>Date & Time</p>
                        <span>
                          {bookingDetail?.performanceDetail?.date},{" "}
                          {bookingDetail?.performanceDetail?.startTime} -{" "}
                          {bookingDetail?.performanceDetail?.endTime}
                        </span>
                      </div>
                    </div>
                    <div className="col-6 p-0 text-end">
                      <div className="d-flex flex-column">
                        <p>Duration</p>
                        {/* <span>2 hours</span> */}
                        <span>{hours} hours</span>
                      </div>
                    </div>
                    <div className="col-6 p-0">
                      <div className="d-flex flex-column">
                        <p>Budget</p>
                        <span>${bookingDetail?.budget}</span>
                      </div>
                    </div>
                    <div className="col-6 p-0 text-end">
                      <div className="d-flex flex-column">
                        <p>Travel Required</p>
                        <span>
                          {bookingDetail?.anyTravelRequired ? "Yes" : "No"}
                        </span>
                      </div>
                    </div>
                    {bookingDetail?.isSoundCheck == true && (
                      <div className="col-12 p-0">
                        <div className="d-flex flex-column">
                          <p>Sound Check Time</p>
                          <span>{bookingDetail?.soundCheckTime?.time}</span>
                        </div>
                      </div>
                    )}
                    <div className="col-12 p-0">
                      <div className="d-flex flex-column">
                        <p>Location</p>
                        <span>
                          {bookingDetail?.Event?.eventTimeLocation?.location}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 p-0">
                      <div className="d-flex flex-column">
                        <p>Additional information</p>
                        <span>
                          {bookingDetail?.additionalInfo}
                          {/* {location?.state?.formdata?.location?.address} */}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 p-0">
                      <div className="d-flex">
                        <p>Included backline services</p>
                        <Link
                          to="#"
                          onClick={handleShowBacklineService}
                          className="check-btn"
                        >
                          Check services
                        </Link>
                      </div>
                      <CheckBacklineService
                        open={backlineService}
                        toggle={handleShowBacklineService}
                        backline_serviceData={bookingDetail?.backlineService}
                      />
                    </div>
                    {(bookingDetail?.status === 3 ||
                      bookingDetail?.status === 1 ||
                      bookingDetail?.status === 4 ||
                      bookingDetail?.status == 2 ||
                      (bookingDetail?.vendorBookingCompleted === true &&
                        bookingDetail?.talentBookingCompleted === true)) && (
                      <>
                        <div className="col-12 col-md-6 p-0">
                          <div className="d-flex flex-column">
                            <p>Agreement</p>
                            <span className="d-flex align-items-center gap-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                              >
                                <circle
                                  cx="14"
                                  cy="14"
                                  r="14"
                                  fill="#86889A"
                                  fillOpacity="0.11"
                                />
                                <path
                                  d="M19.431 8.09347V18.5417H8.25781V10.3537L11.2004 7.41113H18.77C19.1325 7.41113 19.431 7.73098 19.431 8.09347Z"
                                  fill="#EFF2F3"
                                />
                                <path
                                  d="M10.5802 10.3537H8.27734L11.1986 7.41113L11.1773 9.71401C11.1986 10.0765 10.9214 10.3537 10.5802 10.3537Z"
                                  fill="#DADEDE"
                                />
                                <path
                                  d="M14.9298 13.3817C14.3114 12.7847 13.8636 12.1023 13.8636 12.1023C14.0342 11.8465 14.4394 10.3752 13.821 9.94872C13.2026 9.52226 12.8828 10.3112 12.8828 10.3112C12.6056 11.2921 13.2666 12.1876 13.2666 12.1876L12.5203 13.8295C12.435 13.8295 10.0682 14.7464 10.8784 15.8765C11.71 17.0066 12.8615 14.2773 12.8615 14.2773C13.3092 14.128 14.6739 13.9361 14.6739 13.9361C15.207 14.6611 15.8467 14.8956 15.8467 14.8956C16.8275 15.1515 16.9341 14.3412 16.9341 14.3412C16.9555 13.2751 14.9298 13.3817 14.9298 13.3817ZM11.2836 15.4927C11.2622 15.4927 11.2622 15.4714 11.2622 15.4714C11.1343 15.1728 12.1152 14.5971 12.1152 14.5971C12.1152 14.5971 11.5821 15.6206 11.2836 15.4927ZM13.5011 10.3325C13.7783 10.5884 13.5438 11.4626 13.5438 11.4626C13.5438 11.4626 13.224 10.5884 13.5011 10.3325ZM13.2453 13.6376L13.6291 12.6994L14.2261 13.4244L13.2453 13.6376ZM16.4011 14.3199C16.2305 14.5971 15.5268 14.0001 15.4629 13.9361C15.5695 13.9361 16.4864 14.0001 16.4011 14.3199ZM19.4289 17.8595V20.3757C19.4289 20.7381 19.1304 21.058 18.7466 21.058H8.91671C8.55422 21.058 8.23438 20.7595 8.23438 20.3757V17.8595H19.4289Z"
                                  fill="#FA1E07"
                                />
                                <path
                                  d="M12.9682 18.968C12.9682 19.1386 12.9042 19.2665 12.7976 19.3731C12.691 19.4584 12.5204 19.501 12.3072 19.501H12.1366V20.1194H11.8594V18.4775H12.3498C12.563 18.4775 12.7123 18.5202 12.8189 18.6055C12.9255 18.6908 12.9682 18.7974 12.9682 18.968ZM12.1579 19.2878H12.3072C12.4351 19.2878 12.5417 19.2665 12.6057 19.2238C12.6696 19.1812 12.7123 19.0959 12.7123 18.9893C12.7123 18.904 12.691 18.8187 12.627 18.7761C12.563 18.7334 12.4777 18.7121 12.3498 18.7121H12.1579V19.2878ZM14.6527 19.2878C14.6527 19.565 14.5674 19.7782 14.4181 19.9062C14.2689 20.0554 14.0556 20.1194 13.7571 20.1194H13.288V18.4775H13.7998C14.0556 18.4775 14.2689 18.5415 14.4181 18.6908C14.5674 18.8187 14.6527 19.0319 14.6527 19.2878ZM14.3542 19.2878C14.3542 18.8827 14.1623 18.6908 13.7998 18.6908H13.5652V19.8849H13.7571C14.1623 19.9062 14.3542 19.6929 14.3542 19.2878ZM15.2711 20.1194H14.9939V18.4775H15.9321V18.7121H15.2711V19.2238H15.8894V19.4584H15.2711V20.1194Z"
                                  fill="#EFF2F3"
                                />
                              </svg>
                              SERVICE AGREEMENT.PDF
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 p-0 text-md-end">
                          <div className="d-flex flex-column align-items-md-end">
                            <p>Download</p>
                            <span
                              //  onClick={() => toggleAgreementModal()}
                              onClick={async () => {
                                await toggleAgreementModal();
                              }}
                            >
                              <button className="btn btn-link p-0">
                                {SvgIcons?.aggrimentdownload}
                              </button>
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    {userInfo &&
                      userInfo?.type == 2 &&
                      bookingDetail?.status == 3 &&
                      bookingDetail?.vendorBookingCompleted == false && (
                        <>
                          <div className="row p-0">
                            <div className="col-12 col-md-4 col-lg-3 p-0 pe-3">
                              <Link
                                type="button"
                                className="btn btn-primary w-100"
                                to={RoutesLink.Chat}
                                state={{
                                  id:
                                    userInfo?.type == 2
                                      ? bookingDetail?.talentId
                                      : bookingDetail?.vendorId,
                                }}
                              >
                                Chat Now
                              </Link>
                            </div>
                            <div className="col-12 col-md-4 col-lg-3  p-0">
                              <button
                                type="button"
                                className="btn btn-primary w-100"
                                onClick={() =>
                                  HandleCompliteBooking(
                                    bookingDetail?.id,
                                    bookingDetail?.budget,
                                    bookingDetail
                                  )
                                }
                              >
                                Complete
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    {userInfo.type == 3 &&
                      bookingDetail?.status == 3 &&
                      bookingDetail?.talentBookingCompleted == false && (
                        <>
                          <div className="row p-0">
                            <div className="col-12 col-md-4 col-lg-3 p-0 pe-3">
                              <Link
                                type="button"
                                className="btn btn-primary w-100"
                                to={RoutesLink.Chat}
                                state={{
                                  id:
                                    userInfo?.type == 2
                                      ? bookingDetail?.talentId
                                      : bookingDetail?.vendorId,
                                }}
                              >
                                Chat Now
                              </Link>
                            </div>
                            <div className="col-12 col-md-4 col-lg-3  p-0">
                              <button
                                type="button"
                                className="btn btn-primary w-100"
                                onClick={() =>
                                  HandleTalentCompliteBooking(
                                    bookingDetail?.id,
                                    bookingDetail?.budget,
                                    bookingDetail
                                  )
                                }
                              >
                                Complete
                              </button>
                            </div>
                          </div>
                        </>
                      )}

                    {bookingDetail?.Reviews?.map((review) => {
                      // Check if the usertype is 3 and the review is added by a vendor
                      if (
                        userInfo?.type === 3 &&
                        review?.addedBy === "vendor"
                      ) {
                        return (
                          <div key={review.id} className="review-section">
                            {/* Content to display for usertype 3 when addedBy is vendor */}
                            <p>{`Vendor's Review (Rating: ${review?.rating})`}</p>
                            <p>{review?.review || "No review provided"}</p>
                          </div>
                        );
                      }

                      // Check if the usertype is 2 and the review is added by a talent
                      if (
                        userInfo?.type === 2 &&
                        review?.addedBy === "talent"
                      ) {
                        return (
                          <div key={review.id} className="review-section">
                            {/* Content to display for usertype 2 when addedBy is talent */}
                            <p>{`Talent's Review (Rating: ${review?.rating})`}</p>
                            <p>{review?.review || "No review provided"}</p>
                          </div>
                        );
                      }

                      return null; // Return null if conditions don't match
                    })}

                    {/* Conditionally display the "Rate this Artist" section */}
                    {bookingDetail?.status === 4 &&
                      userInfo?.type === 2 &&
                      bookingDetail?.vendorBookingCompleted === true &&
                      bookingDetail?.talentBookingCompleted === true &&
                      moment(
                        bookingDetail?.Event?.eventTimeLocation?.end_date,
                        "DD-MM-YYYY"
                      )?.isAfter(moment()) && (
                        <div className="col-12 p-0">
                          <div className="d-flex flex-column">
                            <p>Rate this Artist</p>
                            <span
                              className="d-flex align-items-center gap-2"
                              onClick={() => {
                                const hasRated = bookingDetail?.Reviews?.some(
                                  (review) =>
                                    review.addedBy === "vendor" &&
                                    userInfo?.type === 2
                                );
                                if (!hasRated) {
                                  toggleRatingModal(); // Open the modal if the user hasn't rated yet
                                }
                              }}
                            >
                              {Array.from({ length: 5 }, (_, index) => (
                                <svg
                                  key={index}
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={index < rating ? "active" : ""}
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M10 2.5L12.3175 7.195L17.5 7.9525L13.75 11.605L14.635 16.765L10 14.3275L5.365 16.765L6.25 11.605L2.5 7.9525L7.6825 7.195L10 2.5Z"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              ))}
                              <p>
                                {bookingDetail?.Reviews?.length
                                  ? bookingDetail.Reviews.reduce(
                                      (sum, review) => sum + review.rating,
                                      0
                                    ) / bookingDetail.Reviews.length
                                  : ""}
                              </p>
                            </span>
                          </div>
                        </div>
                      )}

                    {bookingDetail?.status === 4 &&
                      userInfo?.type === 3 &&
                      bookingDetail?.vendorBookingCompleted === true &&
                      bookingDetail?.talentBookingCompleted === true &&
                      moment(
                        bookingDetail?.Event?.eventTimeLocation?.end_date,
                        "DD-MM-YYYY"
                      )?.isAfter(moment()) && (
                        <div className="col-12 p-0">
                          <div className="d-flex flex-column">
                            <p>Rate this Artist</p>
                            <span
                              className="d-flex align-items-center gap-2"
                              onClick={() => {
                                const hasRated = bookingDetail?.Reviews?.some(
                                  (review) =>
                                    review.addedBy === "talent" &&
                                    userInfo?.type === 3
                                );
                                if (!hasRated) {
                                  toggleRatingModal(); // Open the modal if the user hasn't rated yet
                                }
                              }}
                            >
                              {Array.from({ length: 5 }, (_, index) => (
                                <svg
                                  key={index}
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={index < rating ? "active" : ""}
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M10 2.5L12.3175 7.195L17.5 7.9525L13.75 11.605L14.635 16.765L10 14.3275L5.365 16.765L6.25 11.605L2.5 7.9525L7.6825 7.195L10 2.5Z"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              ))}
                              <p>
                                {bookingDetail?.Reviews?.length
                                  ? bookingDetail.Reviews.reduce(
                                      (sum, review) => sum + review.rating,
                                      0
                                    ) / bookingDetail.Reviews.length
                                  : ""}
                              </p>
                            </span>
                          </div>
                        </div>
                      )}
                  </div>

                  {console.log("ffff", bookingDetail)}

                  {userInfo?.type == 3 && bookingDetail?.status == 1 && (
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-3 mt-3  p-0">
                        <Link
                          type="button"
                          className="btn btn-primary w-100"
                          to={RoutesLink.Chat}
                          state={{
                            id:
                              userInfo?.type == 2
                                ? bookingDetail?.talentId
                                : bookingDetail?.vendorId,
                          }}
                        >
                          Chat Now
                        </Link>
                      </div>
                    </div>
                  )}

                  {userInfo?.type == 2 && bookingDetail?.status == 0 && (
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-3 p-0 pe-3">
                        <button
                          onClick={() =>
                            notifyArtist(
                              bookingDetail?.Event?.eventName,
                              bookingDetail?.talentId
                            )
                          }
                          className="btn btn-light w-100"
                        >
                          Notify Artist
                        </button>
                      </div>
                      <div className="col-12 col-md-4 col-lg-3  p-0">
                        <Link
                          type="button"
                          className="btn btn-primary w-100"
                          to={RoutesLink.Chat}
                          state={{
                            id:
                              userInfo?.type == 2
                                ? bookingDetail?.talentId
                                : bookingDetail?.vendorId,
                          }}
                        >
                          Chat Now
                        </Link>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    {userInfo?.type == 2 && bookingDetail?.status == 1 && (
                      <>
                        <div className="col-12 p-0">
                          <div className="d-flex flex-column">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                onChange={() => toggleAgreementModal()}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                I Agree to confirm the artist and concert
                                contract agreement
                              </label>
                            </div>
                            <Link
                              to="#"
                              className="aggrement"
                              // data-bs-toggle="modal"
                              // data-bs-target="#agreement"
                              onClick={() => toggleAgreementModal()}
                            >
                              Read Agreement
                            </Link>
                          </div>
                        </div>
                        {/* <div className="row mt-4"> */}
                        <div className="col-12 col-md-4 col-lg-3 p-0 pe-3">
                          <Link
                            type="button"
                            className="btn btn-primary w-100"
                            to={RoutesLink.Chat}
                            state={{
                              id:
                                userInfo?.type == 2
                                  ? bookingDetail?.talentId
                                  : bookingDetail?.vendorId,
                            }}
                          >
                            Chat Now
                          </Link>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 p-0 pe-3">
                          <button
                            type="button"
                            className="btn btn-primary w-100"
                            data-bs-toggle="modal"
                            data-bs-target=".bd-example-modal-lg-2"
                            onClick={() =>
                              RejectBooking(
                                bookingDetail?.id,
                                bookingDetail?.status
                              )
                            }
                          >
                            Reject
                          </button>
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                      </>
                    )}
                  </div>
                  {userInfo?.type == 3 && bookingDetail?.status == 0 && (
                    <>
                      <div className="col-12 p-0">
                        <div className="d-flex flex-column">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              checked={false}
                              id="flexCheckDefault"
                              readOnly
                              onClick={toggleAgreementModal}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              I Agree to confirm the artist and concert contract
                              agreement
                            </label>
                          </div>
                          <Link
                            to="#"
                            className="aggrement"
                            // data-bs-toggle="modal"
                            // data-bs-target="#agreement"
                            onClick={() => toggleAgreementModal()}
                          >
                            Read Agreement
                          </Link>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-4 col-lg-3 p-0 pe-3">
                            <Link
                              type="button"
                              className="btn btn-primary w-100"
                              to={RoutesLink.Chat}
                              state={{
                                id:
                                  userInfo?.type == 2
                                    ? bookingDetail?.talentId
                                    : bookingDetail?.vendorId,
                              }}
                            >
                              Chat Now
                            </Link>
                          </div>
                          <div className="col-12 col-md-4 col-lg-3  p-0">
                            <button
                              type="button"
                              className="btn btn-primary w-100"
                              data-bs-toggle="modal"
                              data-bs-target=".bd-example-modal-lg-2"
                              onClick={() =>
                                RejectBooking(
                                  bookingDetail?.id,
                                  bookingDetail?.status,
                                  3
                                )
                              }
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AgreementModal isOpen={agreementModal} toggle={toggleAgreementModal} />
      <RatingModal
        open={ratingModal}
        toggle={toggleRatingModal}
        getUserData={bookingDetail}
      />
      {/* //reject request modal */}
      <div
        className="modal fade bd-example-modal-lg-2"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered share-modal-dialog share-modal">
          <div className="modal-content modal-content create-post comment-modal share-modal">
            <div className="modal-header p-0 pb-4 border-0">
              <h3>Why you want to cancel Booking</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body p-0">
              <div className="mb-3 row align-items-center">
                <div className="mb-3 row align-items-center">
                  <label className="col-sm-12 col-form-label" htmlFor="fnf2">
                    Remark
                  </label>
                  <div className="col-sm-12">
                    <textarea
                      type="text"
                      className="form-control"
                      id="fnf2"
                      placeholder="Add your remark"
                      onChange={(e) => setSelectedreason(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center gap-3">
                <div className="col-12 col-md-5">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => RejectBooking(bookingDetail?.id)}
                  >
                    Submit
                  </button>
                </div>
                <div className="col-12 col-md-5">
                  <button
                    onClick={() => toggle1()}
                    className="btn btn-light w-100"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AgreementModal isOpen={agreementModal} toggle={toggleAgreementModal} />
    </>
  );
}

export default EventRequestDetail;
