import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RoutesLink } from "../utility/RoutesLink";
import Homepage from "../Pages/HomePage";
import AboutPage from "../Pages/AboutPage";
import FanMainSignUp from "../Pages/Auth/FanMainSignUp";
import TalentSignUp1 from "../Pages/Auth/TalentSignUp/TalentSignUp1";
import TalentSignUpS2 from "../Pages/Auth/TalentSignUp/TalentSignUpS2";
import TalentSignUpS3 from "../Pages/Auth/TalentSignUp/TalentSignUpS3";
import TalentSignUpS1 from "../Pages/Auth/TalentSignUp/TalentSignUpS1";
import TalentSignUpS4 from "../Pages/Auth/TalentSignUp/TalentSignUpS4";
import TalentSignUpS5 from "../Pages/Auth/TalentSignUp/TalentSignUpS5";
import MainSignIn from "../Pages/Auth/MainSignIn";
import Dashboard from "../Pages/Dashboard/Dashboard";
import TalentMainSignUp from "../Pages/Auth/TalentSignUp/TalentMainSignUp";
import OtpSubmit from "../Pages/Auth/OtpSubmit";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import Profile from "../Pages/Profle/Profile";
import PersonalDetails from "../Pages/Profle/PersonalDetails";
import Activity from "../Pages/Profle/Activity";
import AllEvents from "../Pages/Event/AllEvents";
import HireTalent from "../Pages/HireTalent/HireTalent";
import ConfirmHiring from "../Pages/HireTalent/ConfirmHiring";
import Chat from "../Pages/Chat/Chat";
import ManageRequest from "../Pages/Manage request/ManageRequest";
import RequestDetails from "../Pages/Manage request/RequestDetails";
import Instruments from "../Pages/Instruments/Instruments";
import InstrumentsDetails from "../Pages/Instruments/InstrumentsDetails";
import EventDetials from "../Pages/Event/EventDetials";
import CreateInstrument from "../Pages/Instrument/CreateInstrument";
import JubalArtist from "../Pages/JubalArtist/JubalArtist";
import MyProfile from "../Pages/Profle/MyProfile";
import UserPrefrence from "../Pages/Profle/UserPrefrence";
import ArtistsDashboard from "../Pages/Profle/ArtistDashboard/ArtistsDashboard";
import ManageEvent from "../Pages/Profle/ManageEvents/ManageEvent";
import CreateNewPost from "../Pages/Post/CreateNewPost";
import Subscription from "../Pages/Subscription";
import Faq from "../Pages/Faq";
import Contact from "../Pages/Contact";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TermsConditions from "../Pages/TermsConditions";
import Deleted from "../Pages/Deleted/Deleted";
import PageNotFound from "../Pages/PageNotFound/PageNotFound";
import EditPost from "../Pages/Post/EditPost";
import AddEvent from "../Pages/Profle/AddEvent";
import FindTalant from "../Pages/Manage request/FindTalant";
import ScrollToTop from "../Helper/ScrollToTop";
import { AnimatePresence, motion } from "framer-motion";
import Checkout from "../Pages/Checkout/Checkout";
import OrderSuccess from "../Pages/Checkout/OrderSuccess";
import MyOrder from "../Pages/Orders/MyOrder";
import OrderDetails from "../Pages/Orders/OrderDetails";
import Return from "../Pages/Cancellation/Return";
import SellerOrderDetials from "../Pages/Orders/SellerOrderDetials";
import PlanCheckout from "../Pages/Plans/PlanCheckout";
import ActivePlan from "../Pages/Plans/ActivePlan";
import Invoice from "../Pages/Plans/Invoice";
import ProtectedRoute from "./ProtectedRoute";
import UnProtectedRoute from "./UnProtectedRoute";
import TicketBill from "../Pages/bill/TicketBill";
import Bill from "../Pages/bill/bill";
import SquarePaymentForm from "../Pages/PaymentGetway/SquarePaymentForm";
import HiringPayment from "../Pages/PaymentGetway/HiringPayment";
import Stripe from "../Pages/PaymentGetway/Stripe/Stripe";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import EventOrderSuccess from "../Pages/Checkout/EventOrderSuccess";
import FanOtpSubmit from "../Pages/Auth/FanOtpSubmit";
import FanProfilePicture from "../Pages/Auth/FanProfilePicture";
import PlanInvoiceBill from "../Pages/Plans/PlanInvoiceBill";
import SignInMobileNumber from "../Pages/Auth/SignInMobileNumber";
import SinglePost from "../Pages/Post/SinglePost";

const stripePromise = loadStripe("pk_test_AdjIGAzK2UFISynZ2Rsowlga");

const CommonRoutes = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <AnimatePresence mode="wait">
        <Routes>
          <Route
            path={RoutesLink.Homepage}
            element={
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                {" "}
                <Homepage />
              </motion.div>
            }
          />
          <Route path={RoutesLink.about_us} element={<AboutPage />} />
          <Route
            path={RoutesLink.subscription}
            element={
              // <ProtectedRoute>
              <Subscription />
              // </ProtectedRoute>
            }
          />
          <Route path={RoutesLink.faq} element={<Faq />} />
          <Route path={RoutesLink.contact} element={<Contact />} />
          <Route
            path={RoutesLink?.privacy_policy}
            element={<PrivacyPolicy />}
          />
          <Route
            path={RoutesLink?.terms_conditions}
            element={<TermsConditions />}
          />
          <Route
            path={RoutesLink.fan_main_sign_up}
            element={
              <UnProtectedRoute>
                <FanMainSignUp />
              </UnProtectedRoute>
            }
          />
          <Route path={RoutesLink.talent_sign_up} element={<TalentSignUp1 />} />
          <Route
            path={RoutesLink.talent_sign_up_s1}
            element={<TalentSignUpS1 />}
          />
          <Route
            path={RoutesLink.talent_sign_up_s2}
            element={<TalentSignUpS2 />}
          />
          <Route
            path={RoutesLink.talent_sign_up_s3}
            element={<TalentSignUpS3 />}
          />
          <Route
            path={RoutesLink.talent_sign_up_s4}
            element={<TalentSignUpS4 />}
          />
          <Route
            path={RoutesLink.talent_sign_up_s5}
            element={<TalentSignUpS5 />}
          />
          <Route path={RoutesLink.sign_in} element={<MainSignIn />} />
          <Route
            path={RoutesLink.sign_up_mobile_number}
            element={
              <UnProtectedRoute>
                <SignInMobileNumber />
              </UnProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.dashboard}
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.talent_main_sign_up}
            element={<TalentMainSignUp />}
          />
          <Route path={RoutesLink.otp_submit} element={<OtpSubmit />} />
          <Route path={RoutesLink.fan_otp_submit} element={<FanOtpSubmit />} />
          <Route
            path={RoutesLink.fan_profile_picture}
            element={<FanProfilePicture />}
          />
          <Route
            path={RoutesLink.forgot_password}
            element={
              <UnProtectedRoute>
                <ForgotPassword />
              </UnProtectedRoute>
            }
          />
          {/* <Route path={RoutesLink.performance} element={<Performance />} /> */}
          <Route
            path={RoutesLink.profile}
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${RoutesLink.profile}/:id`}
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.MyProfile}
            element={
              <ProtectedRoute>
                <MyProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.persnalDetials}
            element={
              <ProtectedRoute>
                <PersonalDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.Activity}
            element={
              <ProtectedRoute>
                <Activity />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.allEvents}
            element={
              <ProtectedRoute>
                <AllEvents />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${RoutesLink?.EventDetails}/:id`}
            element={
              <ProtectedRoute>
                <EventDetials />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${RoutesLink?.EventDetails}/:id`}
            element={
              <ProtectedRoute>
                <EventDetials />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.hireTalent}
            element={
              <ProtectedRoute>
                <HireTalent />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.ConfirmHiring}
            element={
              <ProtectedRoute>
                <ConfirmHiring />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.ManageRequest}
            element={
              <ProtectedRoute>
                <ManageRequest />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${RoutesLink.RequestDetails}/:id`}
            element={
              <ProtectedRoute>
                <RequestDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.Chat}
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.Instruments}
            element={
              <ProtectedRoute>
                <Instruments />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.InstrumentsDetails}
            element={
              <ProtectedRoute>
                <InstrumentsDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${RoutesLink.InstrumentsDetails}/:id`}
            element={
              <ProtectedRoute>
                <InstrumentsDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.AddInstrument}
            element={
              <ProtectedRoute>
                <CreateInstrument />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.JubalArtist}
            element={
              <ProtectedRoute>
                <JubalArtist />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.userPrefrence}
            element={
              <ProtectedRoute>
                <UserPrefrence />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.AddEvent}
            element={
              <ProtectedRoute>
                <AddEvent />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.artistDashboard}
            element={
              <ProtectedRoute>
                <ArtistsDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.manageEvents}
            element={
              <ProtectedRoute>
                <ManageEvent />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink.create_post}
            element={
              <ProtectedRoute>
                <CreateNewPost />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${RoutesLink?.single_post}/:id`}
            element={
              <ProtectedRoute>
                <SinglePost />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink?.deleted}
            element={
              <ProtectedRoute>
                <Deleted />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink?.FindTalent}
            element={
              <ProtectedRoute>
                <FindTalant />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink?.edit_post}
            element={
              <ProtectedRoute>
                <EditPost />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink?.checkout}
            element={
              <ProtectedRoute>
                <Checkout />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink?.orderSuccess}
            element={
              <ProtectedRoute>
                <OrderSuccess />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink?.myOrder}
            element={
              <ProtectedRoute>
                <MyOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink?.orderDetail}
            element={
              <ProtectedRoute>
                <OrderDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink?.return}
            element={
              <ProtectedRoute>
                <Return />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink?.plan_checkout}
            element={
              <ProtectedRoute>
                <Elements stripe={stripePromise}>
                  <PlanCheckout />
                </Elements>
              </ProtectedRoute>
            }
          />
          <Route path={RoutesLink?.active_plan} element={<ActivePlan />} />
          <Route
            path={RoutesLink?.order_and_invoice}
            element={
              <ProtectedRoute>
                <Invoice />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesLink?.plan_invoice}
            element={<PlanInvoiceBill />}
          />
          <Route path={RoutesLink?.bill} element={<Bill />} />
          <Route path={RoutesLink?.ticket_bill} element={<TicketBill />} />
          <Route
            path={RoutesLink?.sellerOrder}
            element={
              <ProtectedRoute>
                <SellerOrderDetials />
              </ProtectedRoute>
            }
          />
          <Route path={RoutesLink?.payment} element={<SquarePaymentForm />} />{" "}
          <Route
            path={RoutesLink?.stripe}
            element={
              <Elements stripe={stripePromise}>
                <Stripe />
              </Elements>
            }
          />{" "}
          <Route
            path={RoutesLink?.hiring_payment}
            element={
              <Elements stripe={stripePromise}>
                <HiringPayment />
              </Elements>
            }
          />
          <Route
            path={RoutesLink?.Event_order_success}
            element={<EventOrderSuccess />}
          ></Route>
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <PageNotFound />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  );
};
export default CommonRoutes;
