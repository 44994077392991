import { Link } from "react-router-dom"; // Import Link for navigation
import Footer from "../../Components/Footer"; // Import Footer component
import Header from "../../Components/Header"; // Import Header component
import { RoutesLink } from "../../utility/RoutesLink"; // Import route links
import { useLocation, useNavigate } from "react-router-dom"; // Import hooks for location and navigation
import { useForm } from "react-hook-form"; // Import form handling library
import { ApiUrl } from "../../utility/ApiUrl"; // Import API URLs
import { handleFetchRequest } from "../../ApiHelper/ApiHelper"; // Import function for API requests
import { CountryCode } from "../../utility/CountryCode"; // Import country code data
import { toast } from "material-react-toastify"; // Import toast for notifications
import { useState } from "react"; // Import React hooks
import * as yup from "yup"; // Import Yup for validation
import { yupResolver } from "@hookform/resolvers/yup"; // Import resolver for integrating Yup with react-hook-form
import { Spinner } from "reactstrap"; // Import Spinner component for loading indication

const FanMainSignUp = () => {
  const navigate = useNavigate(); // Hook for navigation
  const location = useLocation(); // Hook for accessing location state
  const [showPassword, setShowPassword] = useState(false); // State to show/hide password
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to show/hide confirm password
  const [isLoading, setIsLoading] = useState(false); // State for loading indication
  const [emailExist, setEmailExist] = useState(false); // State to check if the email already exists
  const [showExistValueStatus, setShowExistValueStatus] = useState({
    username: "",
    email: "",
  });
  // Form validation schema using Yup
  const schema = yup.object().shape({
    first_name: yup
      .string()
      .matches(
        /^[a-zA-ZÀ-ÖØ-öø-ÿ '-]+$/,
        "First name can only contain letters, spaces, hyphens, and apostrophes."
      )
      .required("First name is required"),
    last_name: yup
      .string()
      .matches(
        /^[a-zA-ZÀ-ÖØ-öø-ÿ '-]+$/,
        "Last name can only contain letters, spaces, hyphens, and apostrophes."
      )
      .required("Last name is required"),
    email: yup
      .string()
      .email("Invalid email")
      .required("Email is required")
      .test(
        "check-email-exists",
        "User already exists with this email",
        async (value) => {
          if (!value || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            // Skip the check if the email format is invalid
            return true;
          }
          const emailExists = await checkUser(value); // Check if email exists
          return !emailExists; // Validate if email doesn't exist
        }
      ),
    phone: yup
      .string()
      .matches(/^\d{10}$/, "Invalid phone number")
      .required("Phone number is required"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?=.{8,})/,
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one special character"
      ),
    cpassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"), // Confirm password validation
  });

  // Form setup with react-hook-form
  const {
    register, // Register input fields
    handleSubmit, // Handle form submission
    watch, // Watch form field values
    formState: { errors }, // Access form errors
  } = useForm({
    resolver: yupResolver(schema), // Integrate Yup validation
  });

  const password = watch("password"); // Watch password field for real-time validation

  // Function to generate a random string for device ID and token
  function generateRandomString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
    return randomString; // Return the generated string
  }

  // Function to check if username or email is already taken
  const usernameChangeHandler = (value, submitKey) => {
    if (value.length > 1) {
      handleFetchRequest(ApiUrl.get_user_check, "POST", {
        [submitKey]: value,
      }).then((res) => {
        if (res.status == true) {
          if (res.data == null) {
            setShowExistValueStatus((prevState) => {
              return {
                ...prevState,
                [submitKey]: "Available",
              };
            });
          } else {
            setShowExistValueStatus((prevState) => {
              return {
                ...prevState,
                [submitKey]: "Already Taken",
              };
            });
          }
        }
      });
    } else {
      setShowExistValueStatus({ ...showExistValueStatus, username: "" });
    }
  };

  // Function to handle form submission
  const onSubmit = (data) => {
    if (
      showExistValueStatus?.email != "Already Taken" &&
      showExistValueStatus?.username != "Already Taken"
    ) {
      let randomString = generateRandomString(); // Generate random string for device token
      let submitData = {
        email: data.email,
        phone: data?.phone,
        password: data?.password,
        username: data?.username,
        type: 1, // "0 = admin , 1 = fans , 2 = vendor , 3 = talent",
        deviceId: `website-12345`, // Device ID with prefix
        countryCode: data?.country_code,
        deviceToken: randomString, // Random string as FCM token
        firstName: data?.first_name,
        lastName: data.last_name,
        age: data?.age,
      };
      console.log("deviceId", submitData);
      setIsLoading(true); // Set loading state to true
      handleFetchRequest(ApiUrl?.sign_up, "POST", submitData).then(
        (response) => {
          if (response?.status === true) {
            navigate(RoutesLink.fan_otp_submit, {
              state: {
                deviceToken: randomString, // Pass device token to next page
                password: data?.password,
                email: data?.email,
                otp_submit: response?.data?.otp, // Pass OTP for verification
                deviceId: submitData?.deviceId,
              },
            });
            toast.success(response?.message); // Show success toast
            toast.success(response?.data.otp); // Show OTP for debugging
            setIsLoading(false); // Reset loading state
          } else {
            toast.error(response?.message); // Show error message if submission fails
            setIsLoading(false); // Reset loading state
          }
        }
      );
    }
  };

  // Function to toggle password visibility
  const handleShowPassword = () => {
    setShowPassword((pre) => !pre);
  };

  // Function to toggle confirm password visibility
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((pre) => !pre);
  };

  // Function to check if email exists in the system
  const checkUser = async (mail) => {
    const options = {
      data: {
        email: mail, // Email to check
      },
    };

    await handleFetchRequest(
      ApiUrl?.get_user_check,
      "POST",
      options?.data
    ).then((response) => {
      if (response?.status === true) {
        setEmailExist(true); // Set emailExist to true if user already exists
      }
      return true;
    });
  };

  return (
    <>
      <Header />
      <section className="signin navbar-spc">
        <div className="max-width-container h-100">
          <div className="row h-100 justify-content-between justify-content-lg-center">
            <div className="col-12 col-md-5 col-lg-5 col-xl-6 p-0 d-none d-md-flex align-items-center justify-content-center position-relative">
              <div className="img-box">
                <img src="images/sign_in/frame.png" alt="" className="frame" />
                <img
                  src="images/sign_in/sign-img.png"
                  alt=""
                  className=" content-img"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-6 p-0 d-flex justify-content-center align-items-center">
              <div className="card signin-card">
                <div className="card-body">
                  <h3 className="text-jt-dark">Sign up to continue as Fan</h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-12 p-0">
                        <label for="fname" className="form-label">
                          First Name
                          <span className="text-danger fs-2 ps-1">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="fname"
                          placeholder="First Name"
                          {...register("first_name")}
                        />
                        <div className="form-text mt-2 text-danger">
                          {errors.first_name && errors?.first_name?.message}
                        </div>
                      </div>
                      <div className="col-12 p-0">
                        <label for="lname" className="form-label">
                          Last name
                          <span className="text-danger fs-2 ps-1">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="lname"
                          placeholder="Last Name"
                          {...register("last_name")}
                        />
                        <div className="form-text mt-2 text-danger">
                          {errors.last_name && errors?.last_name?.message}
                        </div>
                      </div>
                      <div className="col-12 p-0">
                        <label htmlFor="e-mail" className="form-label">
                          Email Address
                          <span className="text-danger fs-2 ps-1">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="e-mail"
                          disabled={location?.state?.email}
                          placeholder="email@gmail.com"
                          {...register("email", {
                            required: "Please enter email",
                            value: location?.state?.email,
                            onChange: (e) => {
                              // Convert the email to lowercase
                              const lowercaseEmail =
                                e.target.value.toLowerCase();
                              e.target.value = lowercaseEmail;
                              usernameChangeHandler(lowercaseEmail, "email");
                            },
                          })}
                          readOnly={location?.state?.email}
                        />
                        <div className="form-text mt-2 text-danger">
                          {showExistValueStatus?.email === "Available" ? (
                            <span className="text-success">
                              {showExistValueStatus?.email}
                            </span>
                          ) : showExistValueStatus?.email ===
                            "Already Taken" ? (
                            <span className="text-danger">
                              {showExistValueStatus?.email}
                            </span>
                          ) : (
                            ""
                          )}
                          {errors?.email?.message}
                        </div>
                      </div>
                      <div className="col-12 p-0">
                        <label for="contno" className="form-label">
                          Contact Number
                          <span className="text-danger fs-2 ps-1">*</span>
                        </label>
                        <div className="numfield-wrapper">
                          <select
                            className="form-select nopre-drop position-relative z-3"
                            {...register("country_code", {
                              required: "Please enter country code",
                            })}
                          >
                            <option value="+1">US (+1)</option>
                            {CountryCode?.map((data, index) => (
                              <option key={index} value={data?.dial_code}>
                                {data?.code} ({data?.dial_code})
                              </option>
                            ))}
                          </select>

                          <input
                            type="text"
                            class="form-control"
                            id="contno"
                            placeholder="Contact Number"
                            // {...register("phone")}
                            {...register("phone", {
                              required: "Please enter Phone Number",
                              value: location?.state?.phone,
                              maxLength: {
                                value: 10,
                                message: "Phone number must be 10 digits",
                              },
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message:
                                  "Phone number must contain exactly 10 digits",
                              },
                              onChange: (e) => {
                                const phoneNumber = e.target.value;
                                usernameChangeHandler(phoneNumber, "phone");
                              },
                            })}
                          />
                          <div className="form-text mt-2 text-danger position-realtive">
                            {showExistValueStatus?.phone === "Available" ? (
                              <span className="text-success">
                                {showExistValueStatus?.phone}
                              </span>
                            ) : showExistValueStatus?.phone ===
                              "Already Taken" ? (
                              <span className="text-danger">
                                {showExistValueStatus?.phone}
                              </span>
                            ) : (
                              ""
                            )}
                            {errors?.phone?.message}
                          </div>
                          {/* <div className="form-text mt-2 text-danger">
                            {errors.phone && errors?.phone?.message}
                          </div> */}
                        </div>
                      </div>

                      <div className="col-12 p-0">
                        <label for="gender" className="form-label">
                          Age Group
                          <span className="text-danger fs-2 ps-1">*</span>
                        </label>
                        <select
                          className="form-select"
                          id="age"
                          {...register("age", {
                            required: "Please select age",
                            value: location?.state?.age,
                          })}
                        >
                          <option disabled>Please Select your age</option>
                          <option value="0-18">Below 18</option>
                          <option value="18-24">18-24</option>
                          <option value="25-34">25-34</option>
                          <option value="25-34">35-44</option>
                          <option value="25-34">45-54</option>
                          <option value="55-100">55 and above</option>
                        </select>
                        <div className="form-text mt-2 text-danger">
                          {errors?.age?.message}
                        </div>
                      </div>

                      <div className="col-12 p-0">
                        <label for="e-mail" className="form-label">
                          Username
                          <span className="text-danger fs-2 ps-1">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          placeholder="Username"
                          defaultValue="@"
                          {...register("username", {
                            required: "Please enter Username",
                            onChange: (e) => {
                              usernameChangeHandler(e.target.value, "username");
                              const value = e.target.value;
                              if (!value.startsWith("@")) {
                                e.target.value = "@" + value.replace(/^@+/, "");
                              }
                            },
                            minLength: {
                              value: 3,
                              message:
                                "Username must be at least 3 characters long",
                            },
                            maxLength: {
                              value: 20,
                              message: "Username must not exceed 20 characters",
                            },
                            pattern: {
                              value: /^[a-zA-Z0-9._@-]+$/,
                              message:
                                "Username can only contain letters, numbers, underscores, dots, and hyphens",
                            },
                          })}
                        />
                        <div className="form-text mt-2 text-danger">
                          {showExistValueStatus?.username == "Available" &&
                          !errors?.username ? (
                            <span className="text-success">
                              {showExistValueStatus?.username}
                            </span>
                          ) : showExistValueStatus?.username ==
                            "Already Taken" ? (
                            <span className="text-danger">
                              {showExistValueStatus?.username}
                            </span>
                          ) : (
                            ""
                          )}
                          {errors?.username?.message}
                        </div>
                      </div>

                      <div className="col-12 p-0">
                        <label for="password" className="form-label">
                          Password
                          <span className="text-danger fs-2 ps-1">*</span>
                        </label>
                        <div className="d-flex align-items-center passfield-wrapper">
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                            >
                              <path
                                d="M14.55 3.25L8.97495 8.8246C8.36983 8.64116 7.73016 8.60195 7.10717 8.71012C6.48417 8.81829 5.89516 9.07083 5.38729 9.44752C4.87943 9.82421 4.46682 10.3146 4.1825 10.8794C3.89819 11.4442 3.75007 12.0677 3.75 12.7C3.75 13.7741 4.1767 14.8043 4.93622 15.5638C5.69574 16.3233 6.72587 16.75 7.8 16.75C8.43233 16.7499 9.05585 16.6017 9.62062 16.3173C10.1854 16.033 10.6757 15.6203 11.0524 15.1124C11.429 14.6045 11.6815 14.0154 11.7896 13.3924C11.8977 12.7694 11.8585 12.1297 11.6749 11.5246L12.75 10.45V9.55H13.65L17.25 5.95V3.25H14.55ZM16.35 5.57695L13.2774 8.65H11.85V10.0769L11.0387 10.8878L10.6571 11.2694L10.8137 11.7856C10.9041 12.0835 10.95 12.3909 10.95 12.7C10.95 14.437 9.53655 15.85 7.8 15.85C6.06345 15.85 4.65 14.437 4.65 12.7C4.65 10.963 6.06345 9.55 7.8 9.55C8.1087 9.55 8.4165 9.5959 8.71395 9.6859L9.22965 9.8425L9.61125 9.4609L14.9226 4.15H16.35V5.57695Z"
                                fill="#818898"
                              />
                              <path
                                d="M6.9 14.5002C7.39706 14.5002 7.8 14.0973 7.8 13.6002C7.8 13.1031 7.39706 12.7002 6.9 12.7002C6.40294 12.7002 6 13.1031 6 13.6002C6 14.0973 6.40294 14.5002 6.9 14.5002Z"
                                fill="#818898"
                              />
                              <path
                                d="M12.5908 7.59111L15.2908 4.89111L15.609 5.20926L12.909 7.90926L12.5908 7.59111Z"
                                fill="#818898"
                              />
                            </svg>
                          </span>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            {...register("password")}
                          />

                          <span className="visi">
                            {showPassword ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                onClick={handleShowPassword}
                              >
                                <path
                                  d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                  stroke="#818898"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                  stroke="#818898"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                onClick={handleShowPassword}
                              >
                                <path
                                  d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                  stroke="#818898"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                  stroke="#818898"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M3 3L18 17"
                                  stroke="#818898"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </span>
                        </div>
                        <div className="form-text mt-2 text-danger">
                          {errors.password && errors?.password?.message}
                        </div>
                      </div>

                      <div className="col-12 p-0">
                        <label for="cpassword" className="form-label">
                          Confirm Password
                          <span className="text-danger fs-2 ps-1">*</span>
                        </label>
                        <div className="d-flex align-items-center passfield-wrapper">
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                            >
                              <path
                                d="M14.55 3.25L8.97495 8.8246C8.36983 8.64116 7.73016 8.60195 7.10717 8.71012C6.48417 8.81829 5.89516 9.07083 5.38729 9.44752C4.87943 9.82421 4.46682 10.3146 4.1825 10.8794C3.89819 11.4442 3.75007 12.0677 3.75 12.7C3.75 13.7741 4.1767 14.8043 4.93622 15.5638C5.69574 16.3233 6.72587 16.75 7.8 16.75C8.43233 16.7499 9.05585 16.6017 9.62062 16.3173C10.1854 16.033 10.6757 15.6203 11.0524 15.1124C11.429 14.6045 11.6815 14.0154 11.7896 13.3924C11.8977 12.7694 11.8585 12.1297 11.6749 11.5246L12.75 10.45V9.55H13.65L17.25 5.95V3.25H14.55ZM16.35 5.57695L13.2774 8.65H11.85V10.0769L11.0387 10.8878L10.6571 11.2694L10.8137 11.7856C10.9041 12.0835 10.95 12.3909 10.95 12.7C10.95 14.437 9.53655 15.85 7.8 15.85C6.06345 15.85 4.65 14.437 4.65 12.7C4.65 10.963 6.06345 9.55 7.8 9.55C8.1087 9.55 8.4165 9.5959 8.71395 9.6859L9.22965 9.8425L9.61125 9.4609L14.9226 4.15H16.35V5.57695Z"
                                fill="#818898"
                              />
                              <path
                                d="M6.9 14.5002C7.39706 14.5002 7.8 14.0973 7.8 13.6002C7.8 13.1031 7.39706 12.7002 6.9 12.7002C6.40294 12.7002 6 13.1031 6 13.6002C6 14.0973 6.40294 14.5002 6.9 14.5002Z"
                                fill="#818898"
                              />
                              <path
                                d="M12.5908 7.59111L15.2908 4.89111L15.609 5.20926L12.909 7.90926L12.5908 7.59111Z"
                                fill="#818898"
                              />
                            </svg>
                          </span>
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control"
                            id="cpassword"
                            placeholder="Confirm Password"
                            {...register("cpassword")}
                          />

                          <span className="visi">
                            {showConfirmPassword ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                onClick={handleShowConfirmPassword}
                              >
                                <path
                                  d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                  stroke="#818898"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                  stroke="#818898"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                onClick={handleShowConfirmPassword}
                              >
                                <path
                                  d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                  stroke="#818898"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                  stroke="#818898"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M3 3L18 17"
                                  stroke="#818898"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </span>
                        </div>
                        <div className="form-text mt-2 text-danger">
                          {errors.cpassword && errors?.cpassword?.message}
                        </div>
                      </div>

                      <div className="col-12 px-4">
                        <button
                          type="submit"
                          // href="../pages/Dashboard/dashboard.html"
                          className="btn btn-primary w-100"
                        >
                          {isLoading ? <Spinner /> : "Continue"}
                        </button>
                      </div>
                      <div className="form-text mt-2">
                        {isLoading && (
                          <p
                            className="form-text"
                            style={{
                              color: "#f4336c",
                              fontSize: "10px",
                            }}
                          >
                            We sent a code to your email. Check your inbox and
                            verify to continue.
                          </p>
                        )}
                      </div>
                      <div className="col-12 d-flex justify-content-center align-items-center mt-3">
                        <p className="text-jt-secondary">
                          Already have an account?
                        </p>
                        <Link
                          to={RoutesLink?.sign_in}
                          className="text-primary ms-2"
                        >
                          Sign In
                        </Link>
                      </div>

                      <div className="col-12 p-0">
                        <p className="pripoly text-center">
                          By signing up, you are creating a Jubal Talent account
                          and agree to Jubal Talent
                          <Link to={RoutesLink?.terms_conditions}>Terms</Link>
                          and
                          <Link to={RoutesLink?.privacy_policy}>
                            Privacy Policy
                          </Link>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default FanMainSignUp;
