import React from "react";

function MangeRequestLoader() {
  return (
    <>
      <div className="row">
        <div className="Loader col-lg-12">
          <div className="wrapper">
            <div className="wrapper-cell">
              <div className="text">
                <div className=""></div>
                <div className="text-line"> </div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="Loader col-lg-12">
          <div className="wrapper">
            <div className="wrapper-cell">
              <div className="text">
                <div className=""></div>
                <div className="text-line"> </div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="Loader col-lg-12">
          <div className="wrapper">
            <div className="wrapper-cell">
              <div className="text">
                <div className=""></div>
                <div className="text-line"> </div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MangeRequestLoader;
