import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import EventRequest from "../../DashboardComponents/ManageRequest/EventRequest";
import { useSelector } from "react-redux";
import { ApiUrl } from "../../utility/ApiUrl";
import { toast } from "material-react-toastify";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import EventLoader from "../../Helper/EventLoader";
import EventRequest from "./EventRequest";
import { RoutesLink } from "../../utility/RoutesLink";
import TalentEventRequest from "./TalentEventRequest";
import { OrderHelperStatus } from "../../Helper/Helper";
// import InstrumentRequest from "../../DashboardComponents/ManageRequest/InstrumentRequest";

export default function ManageRequestPage() {
  const userInfo = useSelector((store) => store?.user?.userDetails);
  // const  = useSelector((state) => state?.auth?.userData);

  console.log("userInfo", userInfo);

  const [bookingList, setBookingList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("All");
  const [AllInstrumentRequest, setAllInstrumentRequest] = useState([]);
  const [TalentBookingList, setTalentBookingList] = useState([]);
  const [eventStatus, setEventStatus] = useState(null);
  const [sortBy, setSortBy] = useState(null);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.type === 2) {
        getEvent();
      } else if (userInfo.type === 3) {
        getBookings();
      }
    }
    instruments();
  }, [userInfo, eventStatus, sortBy]);

  const getEvent = async () => {
    const data = {
      booking: "false",
      limit: 10,
      pageNumber: 1,
      showDeleted: false,
      where: {
        createdById: await userInfo?.id,
        // eventStatus: ["3", "1"],
      },
    };
    if (eventStatus !== null) {
      data.where.eventStatus = eventStatus;
    }
    handleFetchRequest(ApiUrl.get_event, "POST", data).then((response) => {
      if (response.status === true) {
        setBookingList(response.data?.event);
        setLoading(false);
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    });
  };

  const getBookings = async () => {
    const data = {
      limit: 10,
      pageNumber: 1,
      showDeleted: false,
      where: { talentId: await userInfo?.id },
    };

    if (eventStatus !== null) {
      data.where["$Event.eventStatus$"] = eventStatus;
    }

    handleFetchRequest(ApiUrl.get_all_bookings, "POST", data).then(
      (response) => {
        if (response.status === true) {
          setTalentBookingList(response.data?.booking);
          setLoading(false);
        } else {
          toast.error(response.message);
          setLoading(false);
        }
      }
    );
  };

  const instruments = () => {
    let url = `${ApiUrl?.get_purchased_instruments}${userInfo?.id}`;
    if (sortBy !== null) {
      url = `${ApiUrl?.get_purchased_instruments}${userInfo?.id}&status=${sortBy}`;
    }
    handleFetchRequest(url).then((res) => {
      if (res.status === true) {
        setAllInstrumentRequest(res?.data);
      }
    });
  };

  const DownloadReportHandler = () => {
    // fetch(ApiUrl?.download_sheet, {
    //   method: "GET",
    // })
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob);
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", "fileorders.xls");
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   })
    //   .catch((error) => {
    //     console.error("Error downloading the file:", error);
    //   });
  };

  if (loading) {
    return <EventLoader />;
  }

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <section className="px-0 main-content">
        <div className="max-width-container">
          {/* =========================== */}
          {/* Main feed grid Start */}
          {/* =========================== */}
          <div className="main-feed-grid">
            {/* =========================== */}
            {/*left side content Start */}
            {/* =========================== */}
            <DashboardSidebar />
            <div className="center-content d-block">
              <div>
                <div className="card tab-card activity-card manage-card">
                  <div className="card-body  position-relative">
                    <div className="divideder position-absolute"></div>
                    <ul
                      className="nav nav-tabs justify-content-center"
                      id="myTab"
                      role="tablist"
                    >
                      {/* <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="activity-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#activity-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="activity-tab-pane"
                    aria-selected="false"
                  >
                    Performance
                  </button>
                </li> */}
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="equi-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#equi-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="equi-tab-pane"
                          aria-selected="true"
                          tabIndex="-1"
                        >
                          Events
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="event-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#event-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="event-tab-pane"
                          aria-selected="false"
                          tabIndex="-1"
                        >
                          Equipments
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade active show"
                        id="equi-tab-pane"
                        role="tabpanel"
                        aria-labelledby="comment-tab"
                        tabIndex="0"
                      >
                        <div className="filter-row">
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Event Requests</p>
                            <div className="inner-filter-row">
                              <div>
                                <div className="dropdown">
                                  <button
                                    className="btn btn-light dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <span>{/* <FilterIcon /> */}</span>
                                    Filter by
                                  </button>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <Link
                                        className={
                                          filter === "All"
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        to="#"
                                        onClick={() => {
                                          setEventStatus(null);
                                          setFilter("All");
                                        }}
                                      >
                                        All
                                      </Link>
                                    </li>
                                    {/* <li>
                                      <Link
                                        className={
                                          filter === "Hiring request received"
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        to="#"
                                        onClick={() => {
                                          setEventStatus(0);
                                          setFilter("Hiring request received");
                                        }}
                                      >
                                        Hiring request received
                                      </Link>
                                    </li> */}
                                    <li>
                                      <Link
                                        className={
                                          filter === "Published"
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        to="#"
                                        onClick={() => {
                                          setEventStatus(3);
                                          setFilter("Published");
                                        }}
                                      >
                                        Published
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className={
                                          filter === "Closed"
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        to="#"
                                        onClick={() => {
                                          setEventStatus(1);
                                          setFilter("Closed");
                                        }}
                                      >
                                        Closed
                                      </Link>
                                    </li>
                                    {/* <li>
                                      <Link
                                        className={
                                          filter === "Completed"
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        to="#"
                                        onClick={() => {
                                          setEventStatus(4);
                                          setFilter("Completed");
                                        }}
                                      >
                                        Completed
                                      </Link>
                                    </li> */}
                                    {/* <li>
                                      <Link
                                        className={
                                          filter === "Cancelled"
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        to="#"
                                        onClick={() => {
                                          setEventStatus(2);
                                          setFilter("Cancelled");
                                        }}
                                      >
                                        Cancelled
                                      </Link>
                                    </li> */}
                                  </ul>
                                </div>
                              </div>

                              <span className="selected-opt">
                                {filter + " "}
                                <span>{/* <SelectedFilterIcon /> */}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row request-card-row">
                          {userInfo?.type === 2 && bookingList?.length > 0 ? (
                            bookingList?.map((list, index) => (
                              <EventRequest key={index} list={list} />
                            ))
                          ) : userInfo?.type === 2 ? (
                            <p className="text-center">No booking found</p>
                          ) : (
                            ""
                          )}
                          {userInfo?.type === 3 &&
                          TalentBookingList?.length > 0 ? (
                            TalentBookingList?.map((list, index) => (
                              <TalentEventRequest list={list} key={index} />
                            ))
                          ) : userInfo?.type === 3 ? (
                            <p className="text-center">No booking found</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="event-tab-pane"
                        role="tabpanel"
                        aria-labelledby="review-tab"
                        tabIndex="0"
                      >
                        <div className="filter-row">
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Equipment Requests</p>
                            <div className="inner-filter-row">
                              <div>
                                <a
                                  href="https://dev.zidni.academy/J0004/api/v1/order-report"
                                  className="btn btn-light"
                                  target="_blank"
                                  // onClick={DownloadReportHandler}
                                >
                                  Download Excel Sheet
                                </a>
                              </div>
                              <div>
                                <div className="dropdown">
                                  <button
                                    className="btn btn-light dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Filter by
                                  </button>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <span
                                        className="dropdown-item"
                                        onClick={() => setSortBy(null)}
                                      >
                                        All
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.placed === sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(OrderHelperStatus?.placed)
                                        }
                                      >
                                        Placed
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.confirmed ===
                                          sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(
                                            OrderHelperStatus?.confirmed
                                          )
                                        }
                                      >
                                        Confirmed
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.cancelled ===
                                          sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(
                                            OrderHelperStatus?.cancelled
                                          )
                                        }
                                      >
                                        Cancelled
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.shipped === sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(OrderHelperStatus?.shipped)
                                        }
                                      >
                                        Shipped
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.delivered ===
                                          sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(
                                            OrderHelperStatus?.delivered
                                          )
                                        }
                                      >
                                        Delivered
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.refund === sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(OrderHelperStatus?.refund)
                                        }
                                      >
                                        Refund Requested
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.refunded === sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(OrderHelperStatus?.refunded)
                                        }
                                      >
                                        Refunded
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className={
                                          OrderHelperStatus?.refundCancelled ===
                                          sortBy
                                            ? "dropdown-item active"
                                            : "dropdown-item"
                                        }
                                        onClick={() =>
                                          setSortBy(
                                            OrderHelperStatus?.refundCancelled
                                          )
                                        }
                                      >
                                        Refund Cancelled
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <span className="selected-opt">
                                {sortBy != null && sortBy != undefined
                                  ? sortBy
                                  : "All"}
                                <span>{/* <SelectedFilterIcon /> */}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row vocals-wrapper ">
                          {loading ? (
                            <EventLoader />
                          ) : (
                            AllInstrumentRequest &&
                            AllInstrumentRequest.map((res, index) => {
                              return (
                                <div className="col-12 px-0" key={index}>
                                  <div className=" vocals-row">
                                    <img
                                      src={
                                        res?.Instrument?.instrument_image?.[0]
                                      }
                                      alt=""
                                      className="cover-img"
                                      loading={"lazy"}
                                    />

                                    <div className="d-flex flex-column flex-md-row gap-1 align-items-md-center justify-content-between w-100">
                                      <div
                                        className="d-flex flex-column gap-1 gap-md-2 gap-lg-3"
                                        to={RoutesLink?.InstrumentsDetails}
                                        state={{
                                          instrumentdata: res.instrumentId,
                                        }}
                                        previewlistener="true"
                                      >
                                        <h3 className="m-0">
                                          {res?.Instrument?.instrument_name}
                                        </h3>

                                        <span>{res?.Instrument?.brand}</span>
                                        <h4>
                                          $ {res?.Instrument?.instrument_price}
                                        </h4>
                                      </div>
                                      <div className="d-flex align-items-start flex-md-column align-items-sm-end justify-content-end gap-3">
                                        <span
                                          className={`badge ${
                                            res?.status ===
                                            OrderHelperStatus?.cancelled
                                              ? "cancelled"
                                              : res?.status ===
                                                OrderHelperStatus?.placed
                                              ? "pending"
                                              : res?.status ===
                                                OrderHelperStatus?.confirmed
                                              ? "in-progress"
                                              : res?.status ===
                                                OrderHelperStatus?.delivered
                                              ? "delivered"
                                              : res?.status ===
                                                OrderHelperStatus?.shipped
                                              ? "delivered"
                                              : res?.status ===
                                                  OrderHelperStatus?.refund_requested ||
                                                "refundRequested"
                                              ? "refunded"
                                              : res?.status ===
                                                OrderHelperStatus?.refunded
                                              ? "refunded"
                                              : res?.status ===
                                                OrderHelperStatus?.refundCancelled
                                              ? "cancelled"
                                              : ""
                                          }`}
                                        >
                                          {
                                            res?.orderDetail?.OrderItems[0]
                                              ?.status
                                          }
                                        </span>

                                        <Link
                                          to={RoutesLink?.sellerOrder}
                                          state={res}
                                          className="btn btn-link pe-0"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="18"
                                            viewBox="0 0 10 18"
                                            fill="none"
                                          >
                                            <path
                                              d="M9.58151 8.00733L2.22391 0.398358C2.10306 0.272132 1.95929 0.171944 1.80088 0.103572C1.64247 0.0352012 1.47257 0 1.30096 0C1.12935 0 0.959445 0.0352012 0.801037 0.103572C0.642629 0.171944 0.498855 0.272132 0.37801 0.398358C0.135897 0.650683 0 0.992012 0 1.3478C0 1.70358 0.135897 2.04491 0.37801 2.29724L6.81266 9.03084L0.37801 15.6971C0.135897 15.9494 0 16.2908 0 16.6465C0 17.0023 0.135897 17.3437 0.37801 17.596C0.498402 17.7232 0.641973 17.8245 0.800402 17.8938C0.958832 17.9632 1.12897 17.9993 1.30096 18C1.47295 17.9993 1.64309 17.9632 1.80152 17.8938C1.95995 17.8245 2.10352 17.7232 2.22391 17.596L9.58151 9.98701C9.71346 9.8609 9.81876 9.70784 9.89079 9.53749C9.96282 9.36713 10 9.18316 10 8.99717C10 8.81119 9.96282 8.62722 9.89079 8.45686C9.81876 8.2865 9.71346 8.13344 9.58151 8.00733Z"
                                              fill="#86889A"
                                            ></path>
                                          </svg>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          )}
                          {AllInstrumentRequest?.length < 1 && (
                            <p className="text-center">No instrument found</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
