import React, { lazy, useEffect, useState } from "react";
import MobileHeader from "../../Components/Layout/MobileHeader";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { toast } from "material-react-toastify";
import { useSelector } from "react-redux";
import { Modal, Spinner } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import PreventEnterKey from "../../Helper/PreventEnterKey";
import EventLoader from "../../Helper/EventLoader";
import ImageCropper from "../../Helper/ImageCropper";
import GooglePlaceAutocomplete from "../../Components/GooglePlaceAutocomplete";

function CreateInstrument() {
  const [PrimaryCategorie, setPrimaryCategorie] = useState([]);
  const [SecCategorie, setSecCategorie] = useState([]);
  const [AllInstruments, setAllInstruments] = useState([]);
  const [Brands, setBrands] = useState([]);
  const [Features, setFeatures] = useState([]);
  const [Specifications, setSpecifications] = useState([]);
  const [ImageErrorState, setImageErrorState] = useState("");
  const userDetails = useSelector((store) => store?.user?.userDetails);
  const [IsLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [instrumentName, setinstruementName] = useState(null);
  const [instrumentNameModal, setInstrumentNameModal] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const [imageUrl, setImageUrl] = useState({
    image_url: "",
    image_view: "",
  });

  const options = [
    { value: "new", label: "New" },
    { value: "like_new", label: "Like New" },
    { value: "slightly_used", label: "Slightly Used" },
    { value: "used", label: "Used" },
    { value: "need_repair", label: "Need Repair" },
  ];

  // console.log("first", selectedPlace);
  const [mode, setMode] = useState("");
  const [instrumentImages, setInstrumentImages] = useState([]);
  const [croppedImages, setCroppedImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [editImages, setEditImages] = useState([]);
  const [removeImages, setRemoveImages] = useState([]);

  // useEffect to fetch categories, brands, features, and specifications when the component mounts
  useEffect(() => {
    getCategories();
    getBrands();
    getFeatures();
    getSpecifications();
  }, [""]);

  // Destructuring properties from useForm hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm();

  // Extracting instrument data from the location state, if available
  let instrumentData = location?.state?.instrumentdata;
  console.log(instrumentData);

  // Toggles the state for showing/hiding the instrument name modal
  const toggleInstrumentNameModal = () => {
    setInstrumentNameModal(!instrumentNameModal);
  };

  // Handles the file upload for images
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    if (editImages?.length + instrumentImages.length + files.length > 10) {
      toast.info("You can only upload up to 10 images.");
      return;
    }

    const newImages = [...instrumentImages, ...files].slice(0, 10);
    setInstrumentImages(newImages);

    // If there are files, show the cropper for the first image
    if (files.length > 0) {
      handleShowCropper(files[0], newImages.length - files.length);
    }
  };

  // Reads the uploaded image and displays it in a modal cropper
  const handleShowCropper = (image, index) => {
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
      setCurrentImageIndex(index);
      setShowModal(true);
    };
    reader.readAsDataURL(image);
  };

  // Handles cropping of the image and updates the cropped images state
  const handleCrop = (croppedImage) => {
    let url = URL.createObjectURL(croppedImage);
    if (url) {
      setCroppedImages((prev) => {
        const updatedImages = [...prev];
        updatedImages[currentImageIndex] = {
          image_file: croppedImage,
          image_url: url,
        };
        return updatedImages;
      });
    }
    setShowModal(false);
  };

  // Removes an image from the instrument images list
  const handleRemoveImage = (index) => {
    setInstrumentImages((prevData) => prevData.filter((_, i) => i !== index));
    setCroppedImages((prevData) => prevData.filter((_, i) => i !== index));
  };

  // Removes an image from the edit images list and tracks it for deletion
  const handleRemoveEditImage = (index) => {
    setEditImages((prevEditImages) => {
      const updatedImages = [...prevEditImages];
      const removedImage = updatedImages.splice(index, 1)[0]; // Remove the image
      setRemoveImages((prevRemoveImages) => [
        ...prevRemoveImages,
        removedImage,
      ]); // Track removed image
      return updatedImages; // Update state
    });
  };

  // Fetches the primary categories from the API
  const getCategories = () => {
    handleFetchRequest(
      `${ApiUrl?.instruement_categories}type=primary`,
      "GET"
    ).then((response) => {
      if (response?.status === true) {
        // setPrimaryCategorie(response?.data?.categories);
        let getOnlycategories = response.data.categories.map((res) => {
          return {
            value: res.category,
            id: res.id,
            label: res.category,
          };
        });
        setPrimaryCategorie(getOnlycategories);
      } else {
        toast("Data is Not Found!");
      }
    });
  };
  // Fetches brands from the API
  const getBrands = () => {
    handleFetchRequest(`${ApiUrl?.get_brands}`, "GET").then((response) => {
      if (response?.status === true) {
        setBrands(response?.data?.brands);
      } else {
        toast("Data is Not Found!");
      }
    });
  };

  // Fetches features from the API
  const getFeatures = () => {
    handleFetchRequest(`${ApiUrl?.get_features}`, "GET").then((response) => {
      if (response?.status === true) {
        // setFeatures(response?.data?.features);
        let getOnlyFetures = response.data.features.map((res) => {
          return {
            value: res.feature,
            id: res.id,
            label: res.feature,
          };
        });
        setFeatures(getOnlyFetures);
        console.log("setFeatures", Features);
      } else {
        toast("Data is Not Found!");
      }
    });
  };
  // Fetches specifications from the API
  const getSpecifications = () => {
    handleFetchRequest(`${ApiUrl?.get_specifications}`, "GET").then(
      (response) => {
        if (response?.status === true) {
          let getOnlyspecification = response.data.specifications.map((res) => {
            return {
              value: res.specification,
              id: res.id,
              label: res.specification,
            };
          });
          setSpecifications(getOnlyspecification);
        } else {
          toast("Data is Not Found!");
        }
      }
    );
  };
  // Handles primary category change and fetches secondary categories
  const handlePrimaryCategoryChange = (selectedOption) => {
    setValue("primaryCategory", selectedOption); // Update react-hook-form state
    if (selectedOption) {
      handleFetchRequest(
        `${ApiUrl?.instruement_categories}type=secondary&category_id=${selectedOption?.id}`,
        "GET"
      ).then((response) => {
        if (response?.status === true) {
          setSecCategorie(response?.data?.categories);
        } else {
          toast.error("Data is Not Found!");
        }
      });
    }
  };
  // Handles secondary category change and fetches available instruments
  const handleSecandoryCategoryChange = (selectedOption) => {
    setValue("secondaryCategory", selectedOption); // Update react-hook-form state
    if (selectedOption) {
      handleFetchRequest(
        `${ApiUrl?.instrument_name}?categoryId=${selectedOption?.id}`,

        "GET"
      ).then((response) => {
        if (response?.status === true) {
          setAllInstruments([
            ...response?.data?.instruments,
            { id: "sadfjlsit78999sd", instrumentName: "Other" },
          ]);
        } else {
          toast.error("Data is Not Found!");
        }
      });
    }
  };

  // Prefills form values based on instrument data when editing an existing instrument
  useEffect(
    () => {
      if (instrumentData) {
        console.log("instrumentData", instrumentData);
        setValue("primaryCategory", {
          value: instrumentData.primaryCategory,
          id: instrumentData.primary_category_id,
          label: instrumentData.primaryCategory,
        });
        setValue("secondaryCategory", {
          value: instrumentData.secondaryCategory,
          id: instrumentData.secondary_category_id,
          label: instrumentData.secondaryCategory,
        });
        setValue("instrumentName", {
          value: instrumentData.instrument_name,
          label: instrumentData.instrument_name,
        });
        setValue("price", instrumentData.instrument_price);
        setValue("brand", {
          value: instrumentData.brand,
          label: instrumentData.brand,
        });
        setValue("info", instrumentData.about);
        setValue(
          "features",
          instrumentData.features.map((feature) => ({
            value: feature,
            label: feature,
          }))
        );
        setValue(
          "Specifications",
          instrumentData.specification.map((spec) => ({
            value: spec,
            label: spec,
          }))
        );
        setValue("quantity", instrumentData?.quantity);
        setValue("condition", instrumentData?.condition);
        // setImageUrl({

        //   image_url: instrumentData.instrument_image,
        //   image_view: instrumentData.instrument_image,
        // });
        setEditImages(instrumentData?.instrument_image);
        setValue("zipcode", JSON.parse(instrumentData?.location).postal_code);
      }
    },
    []
    //  [mode, instrumentData, setValue]
  );
  // Handles form submission for adding or editing an instrument
  const onSubmit = (data) => {
    if (location?.state?.instrumentdata) {
      // Edit Post
      if (croppedImages?.length > 0 || editImages?.length > 0) {
        if (removeImages?.length > 0) {
          removeImages.forEach((image) => {
            const imageData = {
              image: image, // Send image to be removed
            };

            handleFetchRequest(
              `${ApiUrl?.remove_instrument_image}${location?.state?.instrumentdata?.id}`,
              "PUT",
              imageData
            ).then((response) => {
              if (response?.status === true) {
                toast?.success(response?.message);
              } else {
                toast("Data is Not Found!");
              }
            });
          });
        }

        // Prepare the data to be updated
        const instrumentdata = {
          instrument_name: instrumentName
            ? instrumentName
            : data?.instrumentName?.value,
          features: data.features.map((i) => i.value),
          primaryCategory: data?.primaryCategory?.value,
          secondaryCategory: data?.secondaryCategory?.label,
          specification: data.Specifications.map((i) => i.value),
          instrument_price: data?.price,
          brand: data?.brand?.label,
          userId: userDetails?.Profile?.id,
          about: data?.info,
          quantity: data?.quantity,
          primary_category_id: data?.primaryCategory?.id,
          secondary_category_id: data?.secondaryCategory?.id,
          condition: data?.condition,
          zipcode: data?.zipcode,
          // Add location data if available
          ...(selectedPlace && {
            location: JSON.stringify(selectedPlace),
            longitude: selectedPlace?.lng,
            latitude: selectedPlace?.lat,
          }),
          status: mode === "save" ? "pending" : "draft",
        };

        // Only include images that were not removed
        const orderData = new FormData();
        croppedImages.forEach((image, index) => {
          orderData.append(`file${index}`, image?.image_file);
        });
        editImages.forEach((image, index) => {
          // Handle any editImages still present after removal
          orderData.append(
            `file${croppedImages.length + index}`,
            image?.image_file
          );
        });

        handleFetchRequest(
          ApiUrl.edit_instrument + location?.state?.instrumentdata?.id,
          "PUT",
          instrumentdata
        ).then((res) => {
          if (res.status === true) {
            // If there are new or edited images, update them
            handleFetchRequest(
              `${ApiUrl?.add_instrument_image}${res?.data?.id}`,
              "PUT",
              null,
              null,
              orderData
            ).then((response) => {
              if (response?.status === true) {
                toast.success("Instrument updated successfully!");
                setIsLoading(false);
                navigate(RoutesLink?.MyProfile);
              } else {
                toast.error("Data is Not Found!");
              }
            });
          }
        });
      } else {
        setImageErrorState("Please Upload a Picture");
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      if (croppedImages?.length > 0) {
        setImageErrorState("");
        const instrumentdata = {
          instrument_name: instrumentName
            ? instrumentName
            : data?.instrumentName?.instrumentName,
          features: data.features.map((i) => i.value),
          primaryCategory: data?.primaryCategory?.value,
          secondaryCategory: data?.secondaryCategory?.label,
          specification: data.Specifications.map((i) => i.value),
          instrument_price: data?.price,
          brand: data?.brand?.label,
          userId: userDetails?.Profile?.id,
          about: data?.info,
          quantity: data?.quantity,
          primary_category_id: data?.primaryCategory?.id,
          secondary_category_id: data?.secondaryCategory?.id,
          condition: data?.condition,
          location: JSON.stringify(selectedPlace),
          longitude: selectedPlace?.lng,
          latitude: selectedPlace?.lat,
          status: mode === "save" ? "pending" : "draft",
        };
        console.log(instrumentdata);

        handleFetchRequest(ApiUrl.add_instrument, "POST", instrumentdata).then(
          (res) => {
            if (res.status === true) {
              toast.success("Instrument Added Successfully!");
              // const formData = new FormData();
              // formData.append("postImageUrl", imageUrl?.image_url);
              const orderData = new FormData();
              croppedImages.forEach((image, index) => {
                orderData.append(`file${index}`, image?.image_file);
                console.log("dtaaaa", orderData);
              });
              handleFetchRequest(
                `${ApiUrl?.add_instrument_image}${res?.data?.id}`,
                "PUT",
                null,
                null,
                orderData
              ).then((response) => {
                if (response?.status === true) {
                  toast.success("Instrument Added");
                  setIsLoading(false);
                  navigate(RoutesLink?.MyProfile);
                } else {
                  toast.error("Data is Not Found!");
                }
              });
            }
          }
        );
      } else {
        setImageErrorState("Please Upload a Picture");
        setIsLoading(false);
      }
    }
  };
  const handleClose = () => {
    setSelectedImage("");
    setShowModal(false);
  };

  return (
    <>
      <MobileHeader />
      <MobileBottomNav />
      <PreventEnterKey />
      <section className="px-0 main-content">
        <div className="max-width-container">
          {/* =========================== */}
          {/* Main feed grid Start */}
          {/* =========================== */}
          <div className="main-feed-grid">
            {/* =========================== */}
            {/*left side content Start */}
            {/* =========================== */}
            <DashboardSidebar />
            {/* =========================== */}
            {/*left side content end */}
            {/* =========================== */}
            {/* =========================== */}
            {/* center content Start */}
            {/* =========================== */}
            <div className="center-content d-block">
              <div className="row">
                {location?.state?.instrumentdata && (
                  <div className="col-12 p-0 pb-lg-3">
                    <div className="user-det-head-wrapper">
                      <div className="user-det-head d-flex justify-content-between align-items-center">
                        <div className="user-det d-flex gap-3 gap-lg-4 align-items-center">
                          <div className="user-dp p-0">
                            <img
                              src={instrumentData?.instrument_image?.[0]}
                              alt=""
                            />
                          </div>
                          <div className="user-ac">
                            <div className="d-flex gap-2 align-items-center mb-2">
                              <span className="username">
                                <Link to="#">
                                  {instrumentData?.instrument_name}
                                </Link>
                              </span>
                            </div>
                            <p>{instrumentData?.primaryCategory}</p>
                          </div>
                        </div>

                        <div className="btn-wrapper">
                          <button
                            className="btn btn-light rounded-circle"
                            onClick={() => navigate(-1)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <path
                                d="M7.05762 6.00164L11.7712 1.28784C11.8428 1.21874 11.8998 1.13609 11.9391 1.0447C11.9783 0.953313 11.999 0.855022 11.9999 0.755563C12.0007 0.656104 11.9818 0.557469 11.9441 0.465413C11.9065 0.373357 11.8508 0.289723 11.7805 0.219393C11.7102 0.149062 11.6265 0.0934424 11.5345 0.0557793C11.4424 0.0181162 11.3438 -0.000835989 11.2443 2.82821e-05C11.1449 0.000892553 11.0466 0.0215562 10.9552 0.0608134C10.8638 0.100071 10.7812 0.157135 10.7121 0.228678L5.99827 4.94229L1.28466 0.228678C1.14367 0.0907862 0.953989 0.0140576 0.756783 0.0151437C0.559576 0.0162297 0.370754 0.0950426 0.231293 0.234478C0.0918327 0.373914 0.0129868 0.562723 0.0118659 0.759929C0.010745 0.957135 0.0874397 1.14683 0.225306 1.28784L4.9391 6.00164L0.225306 10.7154C0.154478 10.7847 0.0980952 10.8673 0.0594229 10.9586C0.0207506 11.0498 0.000556963 11.1477 1.13597e-05 11.2468C-0.000534243 11.3459 0.0185791 11.4441 0.0562444 11.5357C0.0939097 11.6274 0.149378 11.7106 0.21944 11.7807C0.289501 11.8507 0.372762 11.9062 0.464402 11.9438C0.556042 11.9815 0.654241 12.0006 0.753312 12C0.852383 11.9994 0.950358 11.9792 1.04156 11.9405C1.13277 11.9018 1.2154 11.8454 1.28466 11.7746L5.99827 7.06099L10.7121 11.7746C10.8583 11.9209 11.0501 11.9941 11.2416 11.9941C11.4332 11.9941 11.6252 11.9209 11.7712 11.7746C11.9117 11.6341 11.9905 11.4436 11.9905 11.245C11.9905 11.0464 11.9117 10.8559 11.7712 10.7154L7.05762 6.00164Z"
                                fill="#343B4E"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {IsLoading === true ? (
                  <div className="col-lg-12">
                    <div className="loading-overlay">
                      <Spinner color="light" />
                    </div>
                  </div>
                ) : (
                  <div className="col-12  p-0 pt-4">
                    <div className="card event-card">
                      <div className="card-body   ">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="hiring-form"
                        >
                          <div className="row justify-content-center ">
                            <div className="col-12 p-0">
                              <h4>Create your Instrument</h4>
                            </div>
                            <div className="col-12 col-lg-6 p-0 pe-lg-3">
                              <label
                                htmlFor="organization"
                                className="form-label"
                              >
                                Instrument primary category
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <Controller
                                name="primaryCategory"
                                control={control}
                                rules={{
                                  required: "Primary category is required",
                                }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={PrimaryCategorie}
                                    onChange={handlePrimaryCategoryChange}
                                    className="selected-tag"
                                    classNamePrefix="react-select"
                                    placeholder="Select category"
                                  />
                                )}
                              />
                              {errors.primaryCategory && (
                                <div className="invalid-feedback d-block">
                                  {errors.primaryCategory.message}
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-lg-6 p-0 ps-lg-3">
                              <label
                                htmlFor="secondaryCategory"
                                className="form-label"
                              >
                                Instrument secondary category
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <Controller
                                name="secondaryCategory"
                                control={control}
                                rules={{
                                  required: "Secondary category is required",
                                }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={SecCategorie.map((category) => ({
                                      ...category,
                                      label: `${category.category}`,
                                    }))}
                                    onChange={handleSecandoryCategoryChange}
                                    className="selected-tag"
                                    classNamePrefix="react-select"
                                    placeholder="Select secondary category"
                                  />
                                )}
                              />
                              {errors.secondaryCategory && (
                                <div className="invalid-feedback d-block">
                                  {errors.secondaryCategory.message}
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-lg-6 p-0 pe-lg-3">
                              <label htmlFor="fname" className="form-label">
                                Instrument name
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <Controller
                                name="instrumentName"
                                control={control}
                                rules={{
                                  required: "Instrument name is required",
                                }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={AllInstruments?.map(
                                      (category) => ({
                                        ...category,
                                        label: `${category.instrumentName}`,
                                      })
                                    )}
                                    className="selected-tag"
                                    classNamePrefix="react-select"
                                    placeholder="Select Instrument name"
                                    onChange={(selectedOption) => {
                                      field.onChange(selectedOption);
                                      if (
                                        selectedOption.instrumentName ===
                                        "Other"
                                      ) {
                                        toggleInstrumentNameModal();
                                      } else {
                                        setinstruementName(null);
                                      }
                                    }}
                                    value={
                                      field.value?.instrumentName === "Other"
                                        ? {
                                            label: instrumentName,
                                            value: instrumentName,
                                          }
                                        : field.value
                                    }
                                  />
                                )}
                              />
                              {errors.instrumentName && (
                                <div className="invalid-feedback d-block">
                                  {errors.instrumentName.message}
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-lg-6 p-0 ps-lg-3">
                              <label htmlFor="lname" className="form-label">
                                Add price
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control`}
                                id="price"
                                placeholder="Price"
                                {...register("price", {
                                  required: "Price is required",

                                  pattern: {
                                    value: /^\d+$/,
                                    message: "price should be a number",
                                  },
                                })}
                              />
                              {errors.price && (
                                <div className="form-text text-danger">
                                  {errors.price.message}
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-lg-6 p-0 pe-lg-3">
                              <label htmlFor="brand" className="form-label">
                                Add Brand of Instrument
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <Controller
                                name="brand"
                                control={control}
                                rules={{ required: "Brand is required" }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    className="selected-tag"
                                    options={Brands.map((brandmap) => ({
                                      ...brandmap,
                                      label: `${brandmap.brand}`,
                                    }))}
                                    classNamePrefix="react-select"
                                    placeholder="Select brand"
                                  />
                                )}
                              />
                              {errors.brand && (
                                <div className="invalid-feedback d-block">
                                  {errors.brand.message}
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-lg-6 p-0 ps-lg-3">
                              <label htmlFor="lname" className="form-label">
                                Add Quantity
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control`}
                                id="quantity"
                                placeholder="Please enter Quantity"
                                {...register("quantity", {
                                  required: "Quantity is required",
                                  min: {
                                    value: 1, // Minimum value
                                    message: "Quantity must be at least 1", // Custom error message
                                  },
                                  pattern: {
                                    value: /^\d+$/,
                                    message: "quantity should be a number",
                                  },
                                  max: {
                                    value: 100,
                                    message: "Quantity max can be at 100",
                                  },
                                })}
                              />
                              {errors.quantity && (
                                <div className="form-text text-danger">
                                  {errors.quantity.message}
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-lg-6 p-0 pe-lg-3">
                              <label htmlFor="location" className="form-label">
                                Location
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <GooglePlaceAutocomplete
                                selectedPlace={selectedPlace}
                                setSelectedPlace={setSelectedPlace}
                                defaultValues={
                                  location?.state?.instrumentdata?.location &&
                                  JSON?.parse(
                                    location?.state?.instrumentdata?.location
                                  )?.address
                                }
                              />
                            </div>

                            <div className="col-12 col-lg-6 p-0 pe-lg-3">
                              <label htmlFor="location" className="form-label">
                                Zip Code
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control`}
                                value={
                                  selectedPlace?.postal_code != "" &&
                                  selectedPlace?.postal_code
                                }
                                id="zipcode"
                                placeholder="Please enter zipcode"
                                {...register("zipcode", {
                                  required: "zipcode is required",
                                  min: {
                                    value: 1, // Minimum value
                                    message: "zipcode must be at least 5", // Custom error message
                                  },
                                  pattern: {
                                    value: /^\d+$/,
                                    message: "Zip code must contain digits",
                                  },
                                })}
                              />
                              {errors.zipcode && (
                                <div className="form-text text-danger">
                                  {errors.zipcode.message}
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-lg-12 p-0 ps-lg-3">
                              <label htmlFor="condition" className="form-label">
                                Instrument Condition
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <Select
                                options={options}
                                className="selected-tag"
                                placeholder="Select instrument condition"
                                value={options.find(
                                  (option) =>
                                    option.value === watch("condition")
                                )}
                                {...register("condition", {
                                  required: "Condition is required",
                                })}
                                classNamePrefix="react-select"
                                onChange={(selectedOption) => {
                                  setValue("condition", selectedOption?.value);
                                }}
                              />
                              {errors.condition && (
                                <div className="invalid-feedback">
                                  {errors.condition.message}
                                </div>
                              )}
                            </div>
                            <div className="col-12 p-0">
                              <label htmlFor="info" className="form-label">
                                Additional Information
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <textarea
                                className={`form-control`}
                                id="info"
                                rows={5}
                                placeholder="Type here...."
                                {...register("info", {
                                  required:
                                    "Additional Information is required",
                                })}
                              />
                              {errors.info && (
                                <div className="invalid-feedback d-block">
                                  {errors.info.message}
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-lg-6 p-0 pe-lg-3">
                              <label htmlFor="features" className="form-label">
                                Add Features
                              </label>
                              <Controller
                                name="features"
                                control={control}
                                // rules={{ required: "Features are required" }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={Features}
                                    isMulti
                                    className="selected-tag"
                                    classNamePrefix="react-select"
                                    placeholder="Select features"
                                  />
                                )}
                              />
                              {errors.features && (
                                <div className="invalid-feedback d-block">
                                  {errors.features.message}
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-lg-6 p-0 ps-lg-3">
                              <label
                                htmlFor="Specifications"
                                className="form-label"
                              >
                                Add Specifications
                              </label>
                              <Controller
                                name="Specifications"
                                control={control}
                                // rules={{
                                //   required: "Specifications are required",
                                // }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={Specifications}
                                    isMulti
                                    className="selected-tag"
                                    classNamePrefix="react-select"
                                    placeholder="Select Specifications"
                                  />
                                )}
                              />
                              {errors.Specifications && (
                                <div className="invalid-feedback d-block">
                                  {errors.Specifications.message}
                                </div>
                              )}
                            </div>
                            <div className="col-12  p-0">
                              {/* <div className="position-relative img-preview">
                                    <button
                                      className="btn btn-link"
                                      onClick={imageRemoveHandler}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19"
                                        height="19"
                                        viewBox="0 0 19 19"
                                        fill="none"
                                      >
                                        <path
                                          d="M13.7188 5.12305L4.71875 14.123M4.71875 5.12305L13.7188 14.123"
                                          stroke="black"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </button>

                                    {imageUrl?.image_url && (
                                      <img
                                        src={imageUrl?.image_view}
                                        key={imageUrl?.image_url?.name}
                                        alt=""
                                      />
                                    )}
                                  </div> */}
                              <label
                                for="file"
                                className="form-label w-100 p-0"
                              >
                                <div className="fileUploadBox d-flex justify-content-center flex-column align-items-center">
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="41"
                                      height="41"
                                      viewBox="0 0 41 41"
                                      fill="none"
                                    >
                                      <path
                                        d="M20.6308 0.755859C9.76922 0.755859 0.944336 9.58074 0.944336 20.4423C0.944336 31.3038 9.76922 40.1287 20.6308 40.1287C31.4923 40.1287 40.3172 31.3038 40.3172 20.4423C40.3172 9.58074 31.4923 0.755859 20.6308 0.755859ZM20.6308 2.54553C30.5259 2.54553 38.5275 10.5481 38.5275 20.4423C38.5275 22.2302 38.259 23.9527 37.7723 25.5804L32.4489 20.257C31.9075 19.7157 31.1907 19.4445 30.474 19.4445C29.7572 19.4445 29.0404 19.7157 28.4991 20.257L23.7627 24.9934L15.447 16.6777C14.9056 16.1363 14.1888 15.8652 13.4721 15.8652C12.7553 15.8652 12.0385 16.1363 11.4971 16.6777L3.3076 24.8672C2.93265 23.4219 2.73996 21.9354 2.73401 20.4423C2.73401 10.5472 10.7356 2.54553 20.6308 2.54553ZM13.4721 17.6441C13.7271 17.6441 13.9821 17.7426 14.1817 17.943L22.4974 26.2587L17.3136 31.4425C17.1506 31.6113 17.0604 31.8373 17.0624 32.0719C17.0645 32.3066 17.1586 32.531 17.3245 32.6969C17.4904 32.8628 17.7148 32.9569 17.9495 32.959C18.1841 32.961 18.4101 32.8708 18.5789 32.7078L29.7644 21.5223C29.8567 21.4276 29.967 21.3523 30.0889 21.3008C30.2107 21.2494 30.3417 21.2229 30.474 21.2229C30.6062 21.2229 30.7372 21.2494 30.8591 21.3008C30.9809 21.3523 31.0913 21.4276 31.1836 21.5223L37.1074 27.4462C34.3844 33.8514 28.0373 38.339 20.6308 38.339C12.9772 38.339 6.47443 33.5427 3.91162 26.7938L12.7616 17.9439C12.962 17.7434 13.217 17.645 13.4721 17.645V17.6441Z"
                                        fill="#CAC8C8"
                                      />
                                      <path
                                        d="M20.6309 6.12524C18.1701 6.12524 16.1567 8.13863 16.1567 10.5994C16.1567 13.0602 18.1701 15.0736 20.6309 15.0736C23.0917 15.0736 25.1051 13.0602 25.1051 10.5994C25.1051 8.13863 23.0917 6.12524 20.6309 6.12524ZM20.6309 7.91492C22.1244 7.91492 23.3154 9.10595 23.3154 10.5994C23.3154 12.0929 22.1244 13.2839 20.6309 13.2839C19.1374 13.2839 17.9464 12.0929 17.9464 10.5994C17.9464 9.10595 19.1374 7.91492 20.6309 7.91492Z"
                                        fill="#CAC8C8"
                                      />
                                    </svg>
                                  </span>

                                  <p>
                                    Upload here{" "}
                                    <span className="text-danger fs-2 ps-1">
                                      *
                                    </span>{" "}
                                  </p>
                                </div>
                              </label>
                              <input
                                type="file"
                                className="form-control d-none"
                                id="file"
                                onChange={handleFileUpload}
                                accept="image/*"
                              />
                              {(croppedImages.length > 0 ||
                                editImages?.length > 0) && (
                                <div className="uplaoded-images-wrapper mt-3 mt-lg-4">
                                  {editImages?.map((file, index) => (
                                    <div className="uploaded-img" key={index}>
                                      <img
                                        src={file}
                                        alt={`cropped-${index}`}
                                      />
                                      <button
                                        onClick={() =>
                                          handleRemoveEditImage(index)
                                        }
                                        type="button"
                                        className="btn btn-link"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          viewBox="0 0 19 19"
                                          fill="none"
                                        >
                                          <path
                                            d="M13.7188 5.12305L4.71875 14.123M4.71875 5.12305L13.7188 14.123"
                                            stroke="black"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  ))}
                                  {croppedImages.map((file, index) => (
                                    <div className="uploaded-img" key={index}>
                                      <img
                                        src={file.image_url}
                                        alt={`cropped-${index}`}
                                      />
                                      <button
                                        onClick={() => handleRemoveImage(index)}
                                        type="button"
                                        className="btn btn-link"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          viewBox="0 0 19 19"
                                          fill="none"
                                        >
                                          <path
                                            d="M13.7188 5.12305L4.71875 14.123M4.71875 5.12305L13.7188 14.123"
                                            stroke="black"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                            <p className="form-text text-danger">
                              {ImageErrorState}
                            </p>
                            <div className="col-12 col-md-4 mx-3">
                              <button
                                onClick={() => setMode("draft")}
                                className="btn btn-primary w-100"
                              >
                                Draft
                              </button>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                              <button
                                onClick={() => setMode("save")}
                                className="btn btn-primary w-100"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* =========================== */}
            {/*center content end */}
            {/* =========================== */}
          </div>
          {/* =========================== */}
          {/* Main feed grid End */}
          {/* =========================== */}
        </div>
      </section>
      <ImageCropper
        show={showModal}
        handleClose={handleClose}
        image={selectedImage}
        onCrop={handleCrop}
        width="305"
        height="259"
        ratio="0.9756"
      />
      {instrumentNameModal && (
        <Modal
          isOpen={true}
          toggle={toggleInstrumentNameModal}
          className="modal-dialog modal-dialog-centered addressmodal modal-dialog-scrollable modal-fullscreen-sm-down"
        >
          <div className="modal-content">
            <div className="modal-body ">
              <div className="personal-details-form">
                <form>
                  <div className="row ">
                    <div className="col-12 p-0">
                      <h4>Add Instrument Name</h4>
                    </div>
                    <div className="col-12 p-0">
                      <label for="name" className="form-label">
                        Instrument Name
                      </label>
                      <div className="d-flex align-items-center passfield-wrapper">
                        <input
                          type="text"
                          className="form-control"
                          id="insName"
                          placeholder="Instrument Name"
                          onChange={(e) => setinstruementName(e.target.value)}
                        />
                      </div>
                      {errors.name && (
                        <span className="form-text text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div className="row w-100 gap-3">
                <div className="col-12 col-md-5 p-0 mt-2 mt-md-3">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={toggleInstrumentNameModal}
                  >
                    Add
                  </button>
                </div>
                <div className="col-12 col-md-5 p-0 mt-2 mt-md-3">
                  <Link
                    to="#"
                    className="btn btn-light w-100"
                    onClick={() => {
                      toggleInstrumentNameModal();
                      setinstruementName(null);
                    }}
                  >
                    cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default CreateInstrument;
