import React, { useMemo, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import LikesModal from "../../Components/Modals/LikesModal";
import CommentModal from "../../Components/Modals/CommentModal";
import { calculateHours, getLoginUserCaption } from "../../Helper/Helper";
import ShareModal from "../../Components/Modals/ShareModal";
import { useSelector } from "react-redux";
import { SvgIcons } from "../../Components/Svg/SvgIcons";
import VideoPlayer from "../../Components/Modals/VideoPlayer";

function PerformanceCard({
  data,
  key,
  LikeHandler,
  savePost,
  onLikeClick,
  onCommentClick,
  onfollwuser,
  commentPost,
  Myprofile,
  deletePost,
  reportPost,
  scrollToTop,
  ArcivePost,
}) {
  const userDetail = useSelector((store) => store?.user?.userDetails);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [commentsModal, setcommentsModal] = useState(false);
  const Commentstoggle = () => setcommentsModal(!commentsModal);
  const [CommentData, setCommentData] = useState("");
  const duration = useMemo(
    () => calculateHours(data?.createdAt),
    [data?.updatedAt]
  );
  const SharePost = () => {
    setModal(!modal);
  };
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdowntoogle = () => setDropdownOpen((prevState) => !prevState);

  // State for caption expansion
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded); // Toggle the expanded/collapsed state of the caption
  };

  const [activeVideoId, setActiveVideoId] = useState(null);
  const handlePlay = (id) => {
    setActiveVideoId(id); // Set the active video to play
  };

  // Define maximum length for caption truncation
  const MAX_LENGTH = 250;
  const caption = data?.caption || ""; // Get caption from post data
  const shouldTruncate = caption.length > MAX_LENGTH; // Check if caption exceeds max length for truncation

  return (
    <div className="card post-card">
      <div className="card-body">
        <div className="user-det-head d-flex justify-content-between align-items-start">
          <div className="user-det d-flex gap-3">
            <div className="user-dp" onClick={scrollToTop}>
              <img src={data?.User?.profilePhoto} alt="" />
            </div>
            <div className="user-ac">
              <div className="d-flex gap-2 align-items-center">
                <span className="username">
                  <Link
                    to={
                      data?.User?.id === userDetail?.id
                        ? RoutesLink?.MyProfile
                        : RoutesLink?.profile
                    }
                    state={{ id: data?.User?.id }}
                    onClick={scrollToTop}
                  >
                    {data?.User?.firstName} {data?.User?.lastName}
                  </Link>
                </span>
                <span className="usertype mx-1">
                  <span>
                    {data?.User?.type === 3 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                      >
                        <circle
                          cx="5.74979"
                          cy="6.72714"
                          r="3.75247"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                          fill="#F4336D"
                        />
                      </svg>
                    )}
                  </span>
                  {data?.User?.type === 3 && "Talent"}
                </span>
                {/* {Myprofile ? (
                ""
              ) : (
                <span
                  className="ms-2 follow"
                  onClick={() =>
                    onfollwuser(data, data?.User?.isFollowedByMe, "POST")
                  }
                >
                  {" "}
                  {data?.isFollowedRequestByMe
                    ? "Following"
                    : data?.isFollowedByMe
                    ? "Unfollow"
                    : "Follow"}
                </span>
              )} */}
              </div>
              <p>{getLoginUserCaption(data?.User)}</p>
            </div>
          </div>
          <div className="post-det d-flex align-items-center gap-1 gap-1">
            <span className="post-time">{duration}</span>
            {Myprofile ? (
              <Dropdown isOpen={dropdownOpen} toggle={dropdowntoogle}>
                <DropdownToggle
                  className="cutome-link link"
                  style={{ backgroundColor: "transparent", border: 0 }}
                >
                  {" "}
                  <svg
                    width="31"
                    height="15"
                    viewBox="0 0 31 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3859 7.59648C10.3859 6.26906 9.3098 5.19297 7.98237 5.19297C6.65495 5.19297 5.57886 6.26906 5.57886 7.59648C5.57886 8.92391 6.65495 10 7.98237 10C9.3098 10 10.3859 8.92391 10.3859 7.59648Z"
                      fill="#343B4E"
                    />
                    <path
                      d="M18.193 7.59648C18.193 6.26906 17.1169 5.19297 15.7895 5.19297C14.4621 5.19297 13.386 6.26906 13.386 7.59648C13.386 8.92391 14.4621 10 15.7895 10C17.1169 10 18.193 8.92391 18.193 7.59648Z"
                      fill="#343B4E"
                    />
                    <path
                      d="M25.9999 7.59648C25.9999 6.26906 24.9238 5.19297 23.5964 5.19297C22.269 5.19297 21.1929 6.26906 21.1929 7.59648C21.1929 8.92391 22.269 10 23.5964 10C24.9238 10 25.9999 8.92391 25.9999 7.59648Z"
                      fill="#343B4E"
                    />
                  </svg>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown dropdown-menu-end"
                  container="body"
                >
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => savePost(data, data?.isSavedByMe)}
                  >
                    {data?.isSavedByMe === true ? "Unsave Post" : "Save post"}
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => SharePost()}
                  >
                    Share post
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => {
                      navigate(RoutesLink?.edit_post, {
                        state: { data, isEdit: "true" },
                      });
                    }}
                  >
                    Edit Post
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => ArcivePost(data?.id)}
                  >
                    Archive post
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => deletePost(data?.id)}
                  >
                    Delete post
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : (
              <Dropdown isOpen={dropdownOpen} toggle={dropdowntoogle}>
                <DropdownToggle
                  className=""
                  style={{ backgroundColor: "transparent", border: 0 }}
                >
                  {" "}
                  <svg
                    width="31"
                    height="15"
                    viewBox="0 0 31 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3859 7.59648C10.3859 6.26906 9.3098 5.19297 7.98237 5.19297C6.65495 5.19297 5.57886 6.26906 5.57886 7.59648C5.57886 8.92391 6.65495 10 7.98237 10C9.3098 10 10.3859 8.92391 10.3859 7.59648Z"
                      fill="#343B4E"
                    />
                    <path
                      d="M18.193 7.59648C18.193 6.26906 17.1169 5.19297 15.7895 5.19297C14.4621 5.19297 13.386 6.26906 13.386 7.59648C13.386 8.92391 14.4621 10 15.7895 10C17.1169 10 18.193 8.92391 18.193 7.59648Z"
                      fill="#343B4E"
                    />
                    <path
                      d="M25.9999 7.59648C25.9999 6.26906 24.9238 5.19297 23.5964 5.19297C22.269 5.19297 21.1929 6.26906 21.1929 7.59648C21.1929 8.92391 22.269 10 23.5964 10C24.9238 10 25.9999 8.92391 25.9999 7.59648Z"
                      fill="#343B4E"
                    />
                  </svg>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown dropdown-menu-end"
                  // container="body"
                >
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => savePost(data, data?.isSavedByMe)}
                  >
                    {data?.isSavedByMe === true ? "Unsave Post" : "Save post"}
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => SharePost(data?.id)}
                  >
                    Share post
                  </DropdownItem>
                  {data?.isReportedByMe == true ? (
                    ""
                  ) : (
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() =>
                        reportPost(data, data?.User?.isReportedByMe, "POST")
                      }
                    >
                      Report post
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        </div>
        {data?.caption?.length > 0 && (
          <div className="post-caption">
            <p>
              {isExpanded ? caption : caption.substring(0, MAX_LENGTH)}
              {shouldTruncate && !isExpanded ? "..." : ""}
            </p>
            {shouldTruncate && (
              <button className="btn btn-link p-0 m-0" onClick={handleToggle}>
                {isExpanded ? "Show Less" : "Show More"}
              </button>
            )}
          </div>
        )}
        {data?.mediaType === "photo" ? (
          <div className="content">
            <img src={data?.postImageUrl} alt="" className="w-100" />
          </div>
        ) : (
          // <video width="100%" height="auto" autoPlay controls muted loop={true}>
          //   <source src={data?.postImageUrl} />
          // </video>
          <VideoPlayer
            key={key}
            id={key}
            src={data?.postImageUrl}
            isPlaying={activeVideoId == key}
            onPlay={handlePlay}
          />
        )}
        <div className="user-activity d-flex justify-content-between">
          <div className="d-flex">
            <span
              className="liked d-none d-lg-block"
              onClick={() => onLikeClick(data)}
            >
              {data?.Likes} likes
            </span>
            <span
              className="liked d-lg-none"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvaslikes"
              aria-controls="offcanvaslikes"
            >
              {data?.Likes} likes
            </span>
            <Link
              to={
                data?.User?.id === userDetail?.Profile?.userId
                  ? RoutesLink?.MyProfile
                  : RoutesLink?.profile
              }
              state={{ id: data?.User?.id }}
              className="commented d-none d-lg-block"
              onClick={() => onCommentClick(data)}
            >
              {data?.Comments} Comments
            </Link>
            <span
              className="commented d-lg-none"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasComments"
              aria-controls="offcanvasComments"
            >
              {data?.Comments} Comments
            </span>
            {/* <span className="shared">4 Shared</span> */}
          </div>
          <div className="d-flex user-activity">
            {/* <span onClick={() => SharePost()} className="d-lg-block">
           <SvgIcons.
          </span> */}
            {/* <span
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas_share"
              aria-controls="offcanvas_share"
              className="d-block d-lg-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="21"
                viewBox="0 0 24 21"
                fill="none"
              >
                <path
                  d="M0.907848 20.9298C0.81599 20.9303 0.724685 20.9156 0.637637 20.8863C0.455595 20.8272 0.298067 20.7098 0.189369 20.5522C0.0806702 20.3947 0.026844 20.2058 0.0361984 20.0146C0.0361984 19.8839 0.933997 7.22751 13.6252 6.23383V1.68381C13.6251 1.51052 13.6766 1.34111 13.7732 1.19723C13.8697 1.05335 14.007 0.941524 14.1675 0.876037C14.3279 0.810549 14.5042 0.794373 14.6739 0.829574C14.8436 0.864774 14.9989 0.949754 15.1201 1.07366L23.7233 9.85989C23.883 10.0228 23.9724 10.2419 23.9724 10.47C23.9724 10.6982 23.883 10.9173 23.7233 11.0802L15.1201 19.8664C14.9989 19.9903 14.8436 20.0753 14.6739 20.1105C14.5042 20.1457 14.3279 20.1295 14.1675 20.064C14.007 19.9986 13.8697 19.8867 13.7732 19.7429C13.6766 19.599 13.6251 19.4296 13.6252 19.2563V14.7934C5.15278 15.1159 1.67926 20.4591 1.64439 20.5245C1.56575 20.6487 1.45699 20.751 1.32821 20.8218C1.19944 20.8927 1.05483 20.9299 0.907848 20.9298ZM15.3685 3.81936V7.04882C15.3687 7.2748 15.281 7.49201 15.1241 7.65463C14.9672 7.81724 14.7532 7.91256 14.5274 7.92047C6.31643 8.22119 3.37897 13.6908 2.32428 17.2079C4.5034 15.3251 8.3866 13.024 14.4446 13.024H14.4838C14.715 13.024 14.9367 13.1158 15.1001 13.2793C15.2636 13.4427 15.3554 13.6644 15.3554 13.8956V17.1251L21.8928 10.4744L15.3685 3.81936Z"
                  fill="#86889A"
                />
              </svg>
            </span> */}
            <a
              onClick={() => LikeHandler(data, data?.isLikedByMe, "POST")}
              className={data?.isLikedByMe === true ? "like activated" : "like"}
            >
              <span>{SvgIcons?.likeIcon}</span>
            </a>
            <span
              onClick={() => savePost(data, data?.isSavedByMe)}
              className={data?.isSavedByMe === true ? "save activated" : "save"}
            >
              {SvgIcons?.saveIcon}
            </span>
          </div>
        </div>
        <div className="comment-input">
          <input
            type="text"
            className="form-control"
            placeholder="Add your comment"
            value={CommentData}
            onChange={(e) => {
              setCommentData(e.target.value);
            }}
          />
          <button
            disabled={CommentData?.length < 1}
            onClick={() => {
              commentPost(data?.id, CommentData, "POST", setCommentData);
            }}
            className="btn btn-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M17.5 3.3125L9.25 11.5625"
                stroke="#86889A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5 3.3125L12.25 18.3125L9.25 11.5625L2.5 8.5625L17.5 3.3125Z"
                stroke="#86889A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <ShareModal modal={modal} toggle={(val) => toggle(val)} />
      </div>
    </div>
  );
}

export default PerformanceCard;
