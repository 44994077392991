import { getApp, getApps, initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
import { toast } from "material-react-toastify";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCkhbE_MWjK6veEJIV3K2DmKY-5YIjHYHg",
  authDomain: "jubal-talents-a3f0b.firebaseapp.com",
  projectId: "jubal-talents-a3f0b",
  storageBucket: "jubal-talents-a3f0b.appspot.com",
  messagingSenderId: "101477363836",
  appId: "1:101477363836:web:00751d9a4e2ad5a4edd9f9",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase app (check if it has already been initialized)
let firebaseApp;
if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig); // Initialize if no app exists
} else {
  firebaseApp = getApp(); // Use existing app
}
export const messaging = getMessaging(firebaseApp);

export const auth = getAuth(app);
export const storage = getStorage();
export const provider = new GoogleAuthProvider();
export const db = getFirestore();
export const firebaseDB = getFirestore(app);

export { signInWithPopup };
// export const messaging = getMessaging(app);
