import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import { ApiUrl } from "../../../utility/ApiUrl";
import { useEffect, useState } from "react";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { RoutesLink } from "../../../utility/RoutesLink";

const TalentSignUpS3 = () => {
  // Hook for programmatic navigation
  const navigate = useNavigate();
  // Hook to get the current location object
  const location = useLocation();

  // State variables for managing data
  const [listData, setListData] = useState([]); // List of music types
  const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering
  const [selectedData, setSelectedData] = useState([]); // Selected genre data

  console.log("selcted", selectedData); // Log selected data for debugging

  // Effect to fetch music types based on user type from the location state
  useEffect(() => {
    setSelectedData(location?.state?.genreData); // Set previously selected genre data
    console.log(location?.state); // Log location state for debugging

    // If the user type is 3, fetch music types
    if (location?.state?.usertype === 3) {
      handleFetchRequest(ApiUrl?.get_music_type, "GET").then((response) => {
        if (response?.status === true) {
          setListData(response?.data); // Update listData with fetched music types
        } else {
          // Handle error case if needed
        }
      });
    }
  }, []); // Empty dependency array means this runs once after the initial render

  // Handler for adding/removing selected genres
  const addHandler = (event, id) => {
    if (event.target.checked) {
      // If checkbox is checked, add the id to selectedData
      setSelectedData((preSelect) => [...(preSelect || []), id]);
    } else {
      // If unchecked, remove the id from selectedData
      let newData = (selectedData || [])?.filter((data) => {
        return data !== id; // Filter out the unchecked id
      });
      setSelectedData(newData); // Update the selectedData state
    }
  };

  // Function to check if a specific genre is already selected
  const checkHandler = (data) => {
    if (Array.isArray(selectedData)) {
      const checkedData = selectedData.find(
        (selectId) => (selectId === data?.catagory ? true : false) // Check if the category matches
      );
      return checkedData; // Return the checked status
    } else {
      console.log("Error is Empty"); // Log an error if selectedData is not an array
    }
  };

  return (
    <>
      <Header />
      <section className="signin navbar-spc">
        <div className="max-width-container h-100">
          <div className="row h-100 justify-content-between justify-content-lg-center">
            <div className="col-12 col-md-5 col-lg-5 col-xl-6 p-0 d-none d-md-flex align-items-center justify-content-center position-relative">
              <div className="img-box">
                <img src="images/sign_in/frame.png" alt="" className="frame" />
                <img
                  src="images/sign_in/sign-img2.jpg"
                  alt=""
                  className=" content-img"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-6 p-0 d-flex justify-content-center align-items-center">
              <div className="card signin-card">
                <div className="card-body">
                  <div className="tab-content" id="myTabContent">
                    {location?.state?.usertype === 3 && (
                      <div>
                        <div className="row tab-descp">
                          <div className="col-12 p-0">
                            <h4>Talent Community</h4>
                            <p>
                              If you would like to proceed with signing up as a
                              Jubal Talent, you will be part of the Jubal
                              Talents community granting you access to
                              performance and networking opportunities
                            </p>
                          </div>
                          <div className="col-12 p-0 cat-detl">
                            <h4>
                              In which other music genres are you proficient
                            </h4>
                            <p>Your primary music instrument preferences</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 p-0">
                            <div className="d-flex align-items-center passfield-wrapper">
                              <span className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
                                    fill="#818898"
                                  />
                                </svg>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                id="searchbar"
                                placeholder="Search music genre"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                              <span className="visi">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="12"
                                  viewBox="0 0 21 12"
                                  fill="none"
                                >
                                  <path
                                    d="M8.31407 11.9026H12.9271V9.93506H8.31407V11.9026ZM0.241211 0.0974121V2.06494H21V0.0974121H0.241211ZM3.70101 6.98376H17.5402V5.01624H3.70101V6.98376Z"
                                    fill="#818898"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>

                          <div
                            className="col-12 p-0"
                            style={{ height: "400px", overflow: "auto" }}
                          >
                            {(() => {
                              // Filter and sort the music list, and store it in a variable
                              const filteredMusic = listData
                                ?.filter((music) =>
                                  music?.type
                                    ?.toLowerCase()
                                    ?.includes(searchTerm.toLowerCase())
                                )
                                ?.sort((a, b) => a.type.localeCompare(b.type));

                              // If there are no results, display "No results found"
                              if (filteredMusic.length === 0) {
                                return (
                                  <p className="text-center mt-3">
                                    No results found
                                  </p>
                                );
                              }

                              // If there are results, map over them and render each item
                              return filteredMusic.map((data, index) => (
                                <div className="col-12 p-0" key={data.id}>
                                  <div className="form-check d-flex align-items-center justify-content-between">
                                    <label
                                      className="form-check-label"
                                      htmlFor={`clrock-${index}`}
                                    >
                                      {data?.type}
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`clrock-${index}`}
                                      onChange={(event) =>
                                        addHandler(event, data?.type)
                                      }
                                      checked={checkHandler(data)}
                                    />
                                  </div>
                                </div>
                              ));
                            })()}
                          </div>

                          <div className="col-12 px-0">
                            <div className="row">
                              <div className="col-6 ps-0 pe-4">
                                <Link
                                  onClick={() => navigate(-1)}
                                  // to={-1}
                                  // state={{ ...location?.state }}
                                  state={{ back: location?.state }}
                                  href="talent_sign_up s2.html"
                                  className="btn btn-light btn-back w-100"
                                >
                                  Back
                                </Link>
                              </div>
                              <div className="col-6 ps-4 pe-0">
                                <Link
                                  to={RoutesLink?.talent_sign_up_s4}
                                  state={{
                                    ...location?.state,
                                    genreData: selectedData,
                                  }}
                                  className="btn btn-primary w-100"
                                >
                                  Continue
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {location?.state?.usertype === 2 && (
                      <div>
                        <div className="row tab-descp">
                          <div className="col-12 p-0">
                            <h4>Vendor</h4>
                            <p>
                              Become a Jubal Vendor and unlock all the features
                              and benefits of the Jubal Community. Sell tickets,
                              find and hire talent, promote your artist, create
                              events, and more.
                            </p>
                          </div>

                          <div className="col-12 p-0 cat-detl">
                            <h4>Finalize your account</h4>
                            <p>
                              Let’s share something related to your talent
                              priorities
                            </p>
                          </div>
                        </div>

                        <form action="">
                          <div className="row">
                            <div className="col-12 p-0">
                              <label for="file" className="form-label w-100">
                                <div className="fileUploadBox d-flex justify-content-center flex-column align-items-center">
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="41"
                                      height="41"
                                      viewBox="0 0 41 41"
                                      fill="none"
                                    >
                                      <path
                                        d="M20.6308 0.755859C9.76922 0.755859 0.944336 9.58074 0.944336 20.4423C0.944336 31.3038 9.76922 40.1287 20.6308 40.1287C31.4923 40.1287 40.3172 31.3038 40.3172 20.4423C40.3172 9.58074 31.4923 0.755859 20.6308 0.755859ZM20.6308 2.54553C30.5259 2.54553 38.5275 10.5481 38.5275 20.4423C38.5275 22.2302 38.259 23.9527 37.7723 25.5804L32.4489 20.257C31.9075 19.7157 31.1907 19.4445 30.474 19.4445C29.7572 19.4445 29.0404 19.7157 28.4991 20.257L23.7627 24.9934L15.447 16.6777C14.9056 16.1363 14.1888 15.8652 13.4721 15.8652C12.7553 15.8652 12.0385 16.1363 11.4971 16.6777L3.3076 24.8672C2.93265 23.4219 2.73996 21.9354 2.73401 20.4423C2.73401 10.5472 10.7356 2.54553 20.6308 2.54553ZM13.4721 17.6441C13.7271 17.6441 13.9821 17.7426 14.1817 17.943L22.4974 26.2587L17.3136 31.4425C17.1506 31.6113 17.0604 31.8373 17.0624 32.0719C17.0645 32.3066 17.1586 32.531 17.3245 32.6969C17.4904 32.8628 17.7148 32.9569 17.9495 32.959C18.1841 32.961 18.4101 32.8708 18.5789 32.7078L29.7644 21.5223C29.8567 21.4276 29.967 21.3523 30.0889 21.3008C30.2107 21.2494 30.3417 21.2229 30.474 21.2229C30.6062 21.2229 30.7372 21.2494 30.8591 21.3008C30.9809 21.3523 31.0913 21.4276 31.1836 21.5223L37.1074 27.4462C34.3844 33.8514 28.0373 38.339 20.6308 38.339C12.9772 38.339 6.47443 33.5427 3.91162 26.7938L12.7616 17.9439C12.962 17.7434 13.217 17.645 13.4721 17.645V17.6441Z"
                                        fill="#CAC8C8"
                                      />
                                      <path
                                        d="M20.6309 6.12524C18.1701 6.12524 16.1567 8.13863 16.1567 10.5994C16.1567 13.0602 18.1701 15.0736 20.6309 15.0736C23.0917 15.0736 25.1051 13.0602 25.1051 10.5994C25.1051 8.13863 23.0917 6.12524 20.6309 6.12524ZM20.6309 7.91492C22.1244 7.91492 23.3154 9.10595 23.3154 10.5994C23.3154 12.0929 22.1244 13.2839 20.6309 13.2839C19.1374 13.2839 17.9464 12.0929 17.9464 10.5994C17.9464 9.10595 19.1374 7.91492 20.6309 7.91492Z"
                                        fill="#CAC8C8"
                                      />
                                    </svg>
                                  </span>

                                  <p>Upload here</p>
                                </div>
                              </label>
                              <input
                                type="file"
                                className="form-control d-none"
                                id="file"
                              />
                            </div>

                            <div className="col-12 px-4">
                              <button className="btn btn-primary w-100">
                                Upload company logo
                              </button>
                            </div>
                            <div className="col-12 px-4">
                              <a
                                href="talent_sign_up s2.html"
                                className="btn btn-light btn-back w-100"
                              >
                                Back
                              </a>
                            </div>

                            {/* <!--<div className="col-12 px-0">
                          <div className="row">
                            <div className="col-6 ps-0 pe-4">
                              <a
                                href="talent_sign_up s2.html"
                                className="btn btn-light btn-back w-100"
                              >
                                Back
                              </a>
                            </div>
                            <div className="col-6 ps-4 pe-0">
                              <a
                                href="talent_sign_up s4.html"
                                className="btn btn-primary w-100"
                              >Continue</a
                              >
                            </div>
                          </div>
                        </div> --> */}
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TalentSignUpS3;
