import { useEffect, useState } from "react"; // Import necessary hooks from React
import { handleFetchRequest } from "../../ApiHelper/ApiHelper"; // Import the API helper function
import { ApiUrl } from "../../utility/ApiUrl"; // Import API URL constants
import { useSelector } from "react-redux"; // Import useSelector to access Redux store
import { Link } from "react-router-dom"; // Import Link for routing

export default function InstrumentsDeleted() {
  // Get user details from the Redux store
  const userDetails = useSelector((store) => store?.user?.userDetails);

  // State to hold deleted instruments
  const [deletedInstruments, setDeletedInstruments] = useState([]);

  // Effect to fetch deleted instruments when the component mounts or userDetails changes
  useEffect(() => {
    // Check if user ID exists
    if (userDetails?.id) {
      // Set options to filter instruments created by the user and show deleted instruments
      const options = {
        where: {
          userId: userDetails?.id, // Filter by user ID
        },
        showDeleted: true, // Include deleted instruments
      };

      // Log the API call details for debugging
      console.log("API Call: ", ApiUrl?.get_instruments, options);

      // Fetch instruments from the API
      handleFetchRequest(ApiUrl?.get_instruments, "POST", options).then(
        (response) => {
          let likesData = response?.data?.instrument || []; // Extract instrument data
          setDeletedInstruments(likesData); // Update state with fetched instruments
        }
      );
    }
  }, [userDetails]); // Dependency array: re-run effect if userDetails changes

  return (
    <>
      <h3 className="mt-4 mb-4">Deleted Instruments</h3>
      <div className="row vocals-wrapper ">
        {deletedInstruments?.map((item, index) => (
          <div className="col-12 px-0" key={index}>
            <div className=" vocals-row">
              <img src={item?.instrument_image} alt="" className="cover-img" />
              <div className="d-flex flex-column flex-md-row gap-1 align-items-md-center justify-content-between w-100">
                <Link
                  to="#"
                  className="d-flex flex-column gap-1 gap-md-2 gap-lg-3 no-bg"
                >
                  <h3>{item?.instrument_name}</h3>
                  <span>{item?.primaryCategory}</span>
                  <h4>$ {item?.selling_price}</h4>
                </Link>
              </div>
            </div>
          </div>
        ))}
        {deletedInstruments?.length < 1 && (
          <p className="form-text"> No instrument found</p>
        )}
      </div>
    </>
  );
}
