import { SvgIcons } from "../Components/Svg/SvgIcons";

// Define an object representing different types of social links
export const socialLinkTypes = {
  instagram: "instagram",
  facebook: "facebook",
  x: "x", // Twitter is now referred to as "X"
  tikTok: "tikTok",
  linkedin: "linkedin",
  spotify: "spotify",
  youtube: "youtube",
  music: "music", // Apple Music link
};

// Define an array of social links, including their titles, values, types, and icons
export const SocialLinks = [
  // Each object contains information for a specific social link
  {
    title: "https://instagram.com/@jhon", // The link to Instagram profile
    value: "instagram", // The value to identify this link type
    type: socialLinkTypes.instagram, // Using the defined social link type
    icon: SvgIcons.socialInstagram, // Icon for Instagram
  },
  {
    title: "https://facebook.com/@jhon", // The link to Facebook profile
    value: "facebook", // Value for Facebook link
    type: socialLinkTypes.facebook, // Facebook link type
    icon: SvgIcons.socialFacebook, // Icon for Facebook
  },
  {
    title: "https://tikTok.com/@john", // The link to TikTok profile
    value: "tikTok", // Value for TikTok link
    type: socialLinkTypes.tikTok, // TikTok link type
    icon: SvgIcons.socailTiktok, // Icon for TikTok
  },
  {
    title: "https://twitter.com/@john", // The link to Twitter profile
    value: "twitter", // Value for Twitter link
    type: socialLinkTypes.x, // Using "X" for Twitter link type
    icon: SvgIcons.socialTwitter, // Icon for Twitter
  },
  {
    title: "https://music.apple.com/artist/id", // The link to Apple Music artist page
    value: "music", // Value for music link
    type: socialLinkTypes.music, // Music link type
    icon: SvgIcons.socailMusic, // Icon for Music
  },
  {
    title: "https://www.linkedin.com/in/username", // The link to LinkedIn profile
    value: "linkedin", // Value for LinkedIn link
    type: socialLinkTypes.linkedin, // LinkedIn link type
    icon: SvgIcons.socialLinkedIn, // Icon for LinkedIn
  },
  {
    title: "https://www.youtube.com/@channel", // The link to YouTube channel
    value: "youtube", // Value for YouTube link
    type: socialLinkTypes.youtube, // YouTube link type
    icon: SvgIcons.socialYoutube, // Icon for YouTube
  },
  {
    title: "https://open.spotify.com/playlist/id", // The link to Spotify playlist
    value: "spotify", // Value for Spotify link
    type: socialLinkTypes.spotify, // Spotify link type
    icon: SvgIcons.socailSpotify, // Icon for Spotify
  },
];

// Function to validate social links based on type and URL
export const validateSocialLink = (type, url) => {
  let regex; // Declare a variable to hold the regex pattern
  // Determine the regex pattern based on the social link type
  switch (type) {
    case socialLinkTypes.instagram:
      regex = /^(https?:\/\/)?(www\.)?instagram.com\/[A-Za-z0-9._-]+\/?$/; // Instagram URL pattern
      break;
    case socialLinkTypes.facebook:
      regex = /^(https?:\/\/)?(www\.)?facebook.com\/[A-Za-z0-9._-]+\/?$/; // Facebook URL pattern
      break;
    case socialLinkTypes.tikTok:
      regex = /^(https?:\/\/)?(www\.)?tiktok.com\/@[\w.-]+\/?$/; // TikTok URL pattern
      break;
    case socialLinkTypes.x: // Twitter (now X)
      regex = /^(https?:\/\/)?(www\.)?twitter.com\/[A-Za-z0-9_]+\/?$/; // Twitter URL pattern
      break;
    case socialLinkTypes.linkedin:
      regex = /^(https?:\/\/)?(www\.)?linkedin.com\/in\/[A-Za-z0-9_-]+\/?$/; // LinkedIn URL pattern
      break;
    case socialLinkTypes.youtube:
      regex =
        /^(https?:\/\/)?(www\.)?youtube.com\/(c|channel|user|@)[A-Za-z0-9_-]+\/?$/; // YouTube URL pattern
      break;
    case socialLinkTypes.spotify:
      regex =
        /^(https?:\/\/)?(open\.)?spotify.com\/(playlist|track|artist)\/[A-Za-z0-9_-]+\/?$/; // Spotify URL pattern
      break;
    case socialLinkTypes.music:
      regex = /^(https?:\/\/)?(music\.)?apple.com\/[A-Za-z0-9\/_-]+\/?$/; // Apple Music URL pattern
      break;
    default:
      return true; // No validation for unknown platforms; return true
  }
  // Test the URL against the regex pattern and return the result
  return regex.test(url);
};
