import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { RoutesLink } from "../../utility/RoutesLink";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ApiUrl } from "../../utility/ApiUrl";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { CountryCode } from "../../utility/CountryCode";
import { toast } from "material-react-toastify";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setUserLoggedInDetails } from "../../store/actions/UserActions";

const OtpSubmit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation(); // Get location state passed from the previous screen
  console.log(location?.state); // Debugging to check location state values

  // Hook form setup for handling form submission and validation
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Handler function for OTP submission
  const onSubmit = (data) => {
    // Check if the entered OTP matches the one provided in location state
    if (data?.otp == location?.state?.otp_submit) {
      let submitData = {
        email: location?.state?.email, // User's email address from location state
        username: location?.state?.username, // User's username from location state
        password: location?.state?.password, // User's password from location state
        type: location?.state?.type, // User type: "0 = admin, 1 = fans, 2 = vendor, 3 = talent"
        deviceId: location?.state?.deviceId, // Device ID for authentication
        otp: data?.otp, // The OTP entered by the user
        deviceToken: location?.state?.deviceToken, // FCM token for push notifications
      };

      // Send OTP submission data to the login API endpoint
      handleFetchRequest(ApiUrl?.login, "POST", submitData).then((response) => {
        if (response?.status === true) {
          // If login is successful, set the required cookies for authentication
          Cookies.set("jubal_web_access", response.data?.accessToken);
          Cookies.set("jubal_web_refresh", response.data?.refreshToken);
          Cookies.set("jubal_web_data", JSON?.stringify(response?.data));
          Cookies.set("jubal_device_id", location?.state?.deviceId);

          // Update Redux state with user details
          dispatch(setUserLoggedInDetails(response?.data));

          // Navigate to the dashboard after successful login
          navigate(RoutesLink?.dashboard);

          // Show a success toast notification
          toast.success(response?.message);
        } else {
          // Show an error toast if the login attempt fails
          toast.error(response?.message);
        }
      });
    } else {
      // Show an error toast if the entered OTP is incorrect
      toast?.error("Please enter correct otp");
    }
  };
  return (
    <>
      <Header />
      <section className="signin navbar-spc">
        <div className="max-width-container h-100">
          <div className="row h-100 justify-content-between justify-content-lg-center">
            <div className="col-12 col-md-5 col-lg-5 col-xl-6 p-0 d-none d-md-flex align-items-center justify-content-center position-relative">
              <div className="img-box">
                <img src="images/sign_in/frame.png" alt="" className="frame" />
                <img
                  src="images/sign_in/sign-img.png"
                  alt=""
                  className=" content-img"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-6 p-0 d-flex justify-content-center align-items-center">
              <div className="card signin-card">
                <div className="card-body">
                  <h3 className="text-jt-dark">Enter Otp to continue</h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-12 p-0">
                        <label for="fname" className="form-label">
                          Enter Otp
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="fname"
                          placeholder="otp"
                          {...register("otp", {
                            required: "Please enter otp",
                          })}
                        />
                      </div>
                      <div class="col-12 px-4">
                        <span
                          class="resend"
                          onClick={() =>
                            toast.info(location?.state?.otp_submit)
                          }
                        >
                          Resend code
                        </span>
                      </div>
                      <div class="col-12 px-4">
                        <button
                          type="submit"
                          // href="../pages/Dashboard/dashboard.html"
                          className="btn btn-primary w-100"
                        >
                          Continue
                        </button>
                      </div>
                      {/* <div className="col-12 px-4">
                      <Link to={RoutesLink?.sign_in}>
                        Already have and account Sign in
                      </Link>
                    </div> */}

                      <div className="col-12 p-0">
                        <p className="pripoly text-center">
                          By signing up, you are creating a Jubal Talent account
                          and agree to Jubal Talent
                          <a href="#">Terms</a>and
                          <a href="#">Privacy Policy</a>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default OtpSubmit;
