// src/components/SquarePaymentForm.js
import React, { useEffect, useState } from "react";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "material-react-toastify";
import { RoutesLink } from "../../utility/RoutesLink";

const SquarePaymentForm = () => {
  const location = useLocation();
  const [card, setCard] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const initializeSquarePayment = async () => {
      try {
        const payments = window.Square.payments(
          "sandbox-sq0idb-T_G-6votRDRzncYZCqZb1w",
          "LQJ4PZYJ257G"
        );

        // Updated styles with valid CSS properties for Square Payment form
        const cardStyles = {
          input: {
            color: "#333", // Text color
            fontSize: "16px", // Font size for input text
            fontFamily: "Arial", // Set the font family
          },
        };

        // Initialize the card instance with styles
        const cardInstance = await payments.card({
          style: cardStyles,
          // collectPostalCode: false,
          // postal_code: "12345",
        });

        // Attach the card instance to the DOM element
        await cardInstance.attach("#card-container");

        // Set the card instance state
        setCard(cardInstance);
      } catch (error) {
        console.error("Square Payment form initialization failed:", error);
      }
    };

    initializeSquarePayment();
  }, []);

  const checkoutHandler = (paymentId, card) => {
    setLoading(true);
    const checkoutData = {
      cardDetail: {
        cardNumber: card?.last4,
        brand: card?.cardBrand,
        type: card?.cardType,
      },
      paymentId: paymentId,
      // itemId: location.state.removeNotificationIds,
      itemId: location.state.shippingDetails.map((id) => ({
        id: id.itemId,
        shipping_fees: id.shippingFee, // You might want to make this dynamic
      })),
      delivery_instruction: location?.state?.note,
    };
    handleFetchRequest(
      `${ApiUrl.create_checkout}?addressId=${location?.state?.shippingDefaultAddress?.id}&billingAddressId=${location?.state?.billingDefaultAddress?.id}`,
      "POST",
      checkoutData
    ).then((response) => {
      if (response.status === true) {
        toast?.success("Order completed successfully");
        navigate(RoutesLink?.orderSuccess, {
          state: response?.data,
          replace: true,
        });
        setLoading(false);
      }
    });
  };
  const handlePayment = async () => {
    if (!card) return;
    setLoading(true);

    try {
      const result = await card.tokenize();
      if (result.status === "OK") {
        console.log("Payment token:", result.token);
        // Send payment token, name, and email to the server
        const paymentData = {
          token: result.token,
          // name: formData.name,
          // email: formData.email,
          amount: location?.state?.amount, // Example amount in cents ($10.00)
        };
        handleFetchRequest(
          ApiUrl.create_payment_intent,
          "POST",
          paymentData
        ).then(async (res) => {
          if (res.status === true) {
            console.log("ress", res);

            checkoutHandler(
              res?.data?.paymentId,
              res?.data?.paymentDetail?.cardDetails?.card
            );
          } else {
            alert(`Payment failed: ${res.error}`);
          }
        });
      }
    } catch (error) {
      console.error("Payment failed:", error);
    }

    //payment

    // setLoading(false);
  };

  return (
    <div className="row justify-content-center  w-100 align-items-center h-100vh">
      <div className="col-12 col-md-6 col-lg-5">
        <div className="card getway-card">
          <div className="card-body">
            <h2 className="pb-4 pb-lg-5 text-center fs-3 fw-bold">
              Complete Your Payment
            </h2>
            <div id="card-container"></div>{" "}
            {/* The card form will render here */}
            <button type="button" onClick={handlePayment} disabled={loading}>
              {loading ? "Processing..." : "Pay Now"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SquarePaymentForm;
