import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const HeroSection = () => {
  // gsap.registerPlugin(useGSAP);

  // const container = useRef();

  // useGSAP(
  //   () => {
  //     gsap.from(".hero-section .h1", {
  //       opacity: 0,
  //       duration: 1,
  //       ease: "circ.out",
  //     });

  //     gsap.from(".hero-section .lined-txt", {
  //       color: "black",
  //       duration: 2,
  //     });

  //     gsap.to(".hero-section .lined-txt span", {
  //       opacity: 1,
  //       delay: 1,
  //       duration: 0.5,
  //     });
  //   },
  //   { scope: container }
  // );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "circOut" }}
    >
      <div>
        <section className="hero-section d-flex align-items-center">
          <div className="max-width-container position-relative">
            <div className="bg-grd-crc1"></div>
            <div className="bg-grd-crc2"></div>
            <div className="bg-grd-crc3"></div>
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-11 col-lg-6">
                <h1 className="h1">
                  Empowering Musicians
                  <span className="position-relative lined-txt">
                    Worldwide
                    <span className="position-absolute">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="18"
                        viewBox="0 0 212 18"
                        fill="none"
                      >
                        <path
                          d="M1.93066 15.6467C17.503 13.9479 33 11.5653 48.5354 9.57092C78.888 5.67429 109.291 3.34696 139.886 2.56591C159.468 2.06604 179.453 1.20124 199.036 2.49443C202.682 2.7352 206.164 3.3046 209.722 4.06699"
                          stroke="#F4336C"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                      </svg>
                    </span>
                  </span>
                </h1>
                <p className="para">
                  Let's build a future where talent knows no boundaries.
                  Together, we can transform challenges into opportunities for
                  musicians around the world.
                </p>
                <div className="row justify-content-center justify-content-lg-start align-items-center gap-4">
                  <div className="col-12 col-md-3 col-lg-5 p-0">
                    <Link to="/fan-sign-up" className="btn btn-primary w-100">
                      Join Community
                    </Link>
                  </div>
                  <div className="col-12 col-md-4 col-lg-6 p-0">
                    <Link
                      to="/sign-in"
                      className="btn-white w-100 text-center d-block"
                    >
                      Sign in with Mobile Number
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-7 col-lg-6">
                <div className="img-box">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/main.png`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </motion.div>
  );
};
export default HeroSection;
