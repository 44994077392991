export const RoutesLink = {
  // auth links
  sign_in: "/sign-in",
  sign_up_mobile_number: "/sign-up-mobile-number",
  fan_main_sign_up: "/fan-sign-up",
  talent_sign_up: "/talent-sign-up",
  talent_sign_up_s1: "/talent-sign-up-screen-1",
  talent_sign_up_s2: "/talent-sign-up-screen-2",
  talent_sign_up_s3: "/talent-sign-up-screen-3",
  talent_sign_up_s4: "/talent-sign-up-screen-4",
  talent_sign_up_s5: "/talent-sign-up-screen-5",
  talent_main_sign_up: "/talent-main-sign-up",
  otp_submit: "/otp-submit",
  fan_otp_submit: "/fan-otp-submit",
  forgot_password: "/forgot-password",
  //dashboard links
  dashboard: "/dashboard",
  performance: "/performance",
  subscription: "/subscription",
  faq: "/faq",
  contact: "/contact",
  privacy_policy: "/privacy_policy",
  terms_conditions: "/terms_and_conditions",
  // web links
  Homepage: "/",
  about_us: "/about-us",
  profile: "/profile",
  persnalDetials: "/persnal-detials",
  Activity: "/Activty",
  allEvents: "/all-events",
  hireTalent: "/Hire-Talent",
  ConfirmHiring: "/confirm-hiring",
  ManageRequest: "/manage-request",
  RequestDetails: "/request-details",
  Chat: "/chat",
  Instruments: "/instruments",
  InstrumentsDetails: "/instruments-details",
  EventDetails: "/event-details",
  AddInstrument: "/add-instrument",
  JubalArtist: "/jubal-artist",
  MyProfile: "/my-profile",
  userPrefrence: "/user-prefrence",
  artistDashboard: "/artists-dashboard",
  manageEvents: "/manage-events",
  create_post: "/createpost",
  deleted: "/deleted",
  edit_post: "/edit-post",
  AddEvent: "/add-event",
  FindTalent: "/find-talant",
  checkout: "/checkout",
  orderSuccess: "/order-success",
  myOrder: "/my-order",
  orderDetail: "/order-detail",
  return: "/return",
  returnOrderDetail: "/return-order-detail",
  sellerOrder: "/seller-order",
  plan_checkout: "/plan_checkout",
  active_plan: "/active_plan",
  order_and_invoice: "/order_and_invoice",
  bill: "/bill",
  ticket_bill: "/ticket_bill",
  payment: "/payment",
  stripe: "/Stripe_payment",
  hiring_payment: "/hiring-payment",
  Event_order_success: "/event-order-success",
  fan_profile_picture: "/fan-profile-picture",
  plan_invoice: "/plan-invoice",
  single_post: "single-post",
};
