import { Modal } from "reactstrap";
import { Link } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import { getLoginUserCaption } from "../../Helper/Helper";
import { useEffect, useState } from "react";
import { ApiUrl } from "../../utility/ApiUrl";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";

const FavouriteArtistModal = ({ isOpen, onRequestClose }) => {
  const [artistList, setartistList] = useState([]);
  useEffect(() => {
    if (isOpen) {
      // Effect to fetch the list of favourite artists

      handleFetchRequest(ApiUrl?.get_favourite_artist).then((res) => {
        if (res.status === true) {
          // Check if the response is successful
          setartistList(res?.data?.rows); // Update state with favourite artists data
        }
      });
    }
  }, [isOpen]);
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Likes"
        className="modal-dialog modal-dialog-centered share-modal-dialog"
        toggle={onRequestClose}
      >
        {/* <ModalHeader className="center">
          <h3 className="text-center">Followers Artists</h3>
        </ModalHeader> */}
        <div className="modal-content create-post comment-modal share-modal">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12 p-0">
                <div className="d-flex align-items-start justify-content-between">
                  <h2 className="mt-0">Favourite Artists</h2>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={onRequestClose}
                  ></button>
                </div>

                <div className="featured-card all-likes like-scrollable scrollable p-0 mt-4">
                  <div className="row">
                    {artistList?.length >= 1 &&
                      artistList?.map((res, index) => (
                        <div className="col-12 px-0" key={index}>
                          <div className="user-det d-flex justify-content-between align-items-start">
                            <Link
                              to={RoutesLink?.profile}
                              state={{ id: res?.artistId }}
                              // to={RoutesLink?.profile}
                              // state={res?.follower?.Profile?.id}
                              className="user-det-head"
                            >
                              <div className="d-flex gap-3">
                                {res?.artist?.Profile?.profilePhoto ? (
                                  <div className="user-dp">
                                    <img
                                      src={res?.artist?.Profile?.profilePhoto}
                                      alt=""
                                    />
                                  </div>
                                ) : (
                                  <div className="user-dp no-img">
                                    {res?.artist?.Profile?.firstName[0]}
                                  </div>
                                )}

                                <div className="user-ac">
                                  <div className="d-flex gap-2 align-items-center">
                                    <span className="username">
                                      <Link
                                        to={RoutesLink?.profile}
                                        state={{ id: res?.artistId }}
                                      >
                                        {res?.artist?.Profile?.firstName}{" "}
                                        {res?.artist?.Profile?.lastName}
                                      </Link>
                                    </span>
                                    {(res?.artist?.type == 3 ||
                                      res?.artist?.type == 2) && (
                                      <span
                                        className={
                                          res?.artist?.type === 3
                                            ? "usertype talent mx-1"
                                            : res?.artist?.type === 2
                                            ? "usertype vendor mx-1"
                                            : ""
                                        }
                                      >
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={13}
                                            height={13}
                                            viewBox="0 0 13 13"
                                            fill="none"
                                          >
                                            <circle
                                              cx="5.74979"
                                              cy="6.72714"
                                              r="3.75247"
                                              fill="white"
                                            />
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                              fill="#F4336D"
                                            />
                                          </svg>
                                        </span>
                                        {res?.artist?.type === 3
                                          ? "Talent"
                                          : res?.artist?.type === 2
                                          ? "Vendor"
                                          : ""}
                                      </span>
                                    )}
                                  </div>
                                  <p>{getLoginUserCaption(res?.artist)}</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}
                    {artistList?.length < 1 && (
                      <p className="text-center">No artist found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FavouriteArtistModal;
