// import { useEffect, useRef } from "react";
// import { gsap } from "gsap";
// import { useGSAP } from "@gsap/react";
// import { motion } from 'framer-motion';
// // import { ScrollTrigger } from "gsap/ScrollTrigger";
// // import { ScrollTrigger } from "gsap/ScrollTrigger";

// const MissionSection = () => {
//   // gsap.registerPlugin(useGSAP, ScrollTrigger);
//   // const container = useRef();

//   // useGSAP(() => {
//   //     // gsap.registerPlugin(ScrollTrigger);

//   //     gsap.from(".mission .r-box", {
//   //         opacity: 0,
//   //         xPercent: 20,
//   //         scrollTrigger: {
//   //             trigger: ".mission .r-box",
//   //             scroller: "body",
//   //             start: "top 67%",
//   //             end: "top 67%",
//   //             scrub: 2,
//   //             once: true,
//   //         },
//   //     });

//   //     gsap.from(".mission .l-box", {
//   //         opacity: 0,
//   //         xPercent: -20,
//   //         scrollTrigger: {
//   //             trigger: ".mission .l-box",
//   //             scroller: "body",
//   //             start: "top 67%",
//   //             end: "top 67%",
//   //             scrub: 2,
//   //             once: true,
//   //         },
//   //     },);

//   //     gsap.from(".mission .r-box2", {
//   //         opacity: 0,
//   //         xPercent: 20,
//   //         scrollTrigger: {
//   //             trigger: ".mission .r-box2",
//   //             scroller: "body",
//   //             start: "top 67%",
//   //             end: "top 67%",
//   //             scrub: 2,
//   //             once: true,
//   //         },
//   //     });

//   // }, { scope: container });

//   return (
//     <motion.div
//     initial={{ opacity: 0, x: 20 }}
//     whileInView={{ opacity: 1, x: 0 }}
//     transition={{ duration: 1, ease: "circOut" }}
//     viewport={{ once: true }}
//   >
//       <section class="mission" id="mission">
//         <div class="max-width-container">
//           <div class="container">
//             <div class="row main-row">
//               <div class="col-12 p-0 text-center">
//                 <div class="row">
//                   <div class="col-12 p-0">
//                     <h3 class="h3">More than just a project.</h3>
//                     <h3 class="h3">
//                       A mission to empower
//                       <span class="keyw"> musician globally. </span>
//                     </h3>
//                     <p class="para">
//                       It emerged from our own experiences within the music
//                       industry. We've faced the struggles, and we've celebrated
//                       the successes. Jubal Talents is our response to the need
//                       for a platform that addresses the unique challenges
//                       musicians face in their careers
//                     </p>
//                   </div>
//                 </div>
//               </div>

//               <div class="col-12 p-0">
//                 <div class="row align-items-center">
//                   <div class="col-12 col-md-5 col-lg-6 order-2 order-md-1">
//                     <div class="img-box">
//                       <img
//                         src={`${process.env.PUBLIC_URL}/images/side-img1.png`}
//                         alt=""
//                       />
//                     </div>
//                   </div>
//                   <div class="col-12 col-md-7 col-lg-6 order-1 order-md-2">
//                     <div class="dta-box d-flex flex-column align-items-start r-box">
//                       <span class="dta-badge">Performance Post</span>
//                       <h4 class="h3">Engage with other creative and talent</h4>
//                       <ul>
//                         <li>Post your performance and engage with fans</li>
//                         <li>Share and chat with others</li>
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div class="col-12 p-0">
//                 <div class="row align-items-center justify-content-between">
//                   <div class="col-12 col-md-7 col-lg-5 order-1 order-md-1">
//                     <div class="dta-box d-flex flex-column align-items-start l-box">
//                       <span class="dta-badge">Musical events</span>
//                       <h4 class="h3">
//                         Musical event or GIG’S and hire Talents
//                       </h4>
//                       <ul>
//                         <li>Create Jubal events in just a few clicks</li>
//                         <li>Hire Talents for event and purchase tickets</li>
//                       </ul>
//                     </div>
//                   </div>
//                   <div class="col-12 col-md-5 col-lg-6 order-2 order-md-2">
//                     <img
//                       src={`${process.env.PUBLIC_URL}/images/side-img2.png`}
//                       alt=""
//                     />
//                   </div>
//                 </div>
//               </div>

//               <div class="col-12 p-0">
//                 <div class="row align-items-center justify-content-between">
//                   <div class="col-12 col-md-5 col-lg-6 order-2 order-md-1">
//                     <img
//                       src={`${process.env.PUBLIC_URL}/images/side-img3.png`}
//                       alt=""
//                     />
//                   </div>
//                   <div class="col-12 col-md-7 col-lg-5 order-1 order-md-2">
//                     <div class="dta-box d-flex flex-column align-items-start r-box2">
//                       <span class="dta-badge">Reports and Anayitics</span>
//                       <h4 class="h3">
//                         Buy and sell your instruments or Equipment
//                       </h4>
//                       <ul>
//                         <li>Search and filter instruments and equipments</li>
//                         <li>But your favourite instruments and equipments</li>
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </motion.div>
//   );
// };

// export default MissionSection;


import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const MissionSection = () => {
  // Define motion variants for the animation
  const itemVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0 },
  };

  // Set up intersection observers for different elements
  const { ref: refRBox, inView: inViewRBox } = useInView({ triggerOnce: true });
  const { ref: refLBox, inView: inViewLBox } = useInView({ triggerOnce: true });
  const { ref: refRBox2, inView: inViewRBox2 } = useInView({ triggerOnce: true });

  return (
    <section className="mission" id="mission">
      <div className="max-width-container">
        <div className="container">
          <div className="row main-row">
            <div className="col-12 p-0 text-center">
              <div className="row">
                <div className="col-12 p-0">
                  <h3 className="h3">More than just a project.</h3>
                  <h3 className="h3">
                    A mission to empower
                    <span className="keyw"> musician globally. </span>
                  </h3>
                  <p className="para">
                    It emerged from our own experiences within the music
                    industry. We've faced the struggles, and we've celebrated
                    the successes. Jubal Talents is our response to the need
                    for a platform that addresses the unique challenges
                    musicians face in their careers
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 p-0">
              <div className="row align-items-center">
                <div className="col-12 col-md-5 col-lg-6 order-2 order-md-1">
                  <div className="img-box">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/side-img1.png`}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12 col-md-7 col-lg-6 order-1 order-md-2">
                  <motion.div
                    ref={refRBox}
                    initial="hidden"
                    animate={inViewRBox ? "visible" : "hidden"}
                    variants={itemVariants}
                    transition={{ duration: 1, ease: "circOut" }}
                    className="dta-box d-flex flex-column align-items-start r-box"
                  >
                    <span className="dta-badge">Performance Post</span>
                    <h4 className="h3">Engage with other creative and talent</h4>
                    <ul>
                      <li>Post your performance and engage with fans</li>
                      <li>Share and chat with others</li>
                    </ul>
                  </motion.div>
                </div>
              </div>
            </div>

            <div className="col-12 p-0">
              <div className="row align-items-center justify-content-between">
                <div className="col-12 col-md-7 col-lg-5 order-1 order-md-1">
                  <motion.div
                    ref={refLBox}
                    initial="hidden"
                    animate={inViewLBox ? "visible" : "hidden"}
                    variants={itemVariants}
                    transition={{ duration: 1, ease: "circOut" }}
                    className="dta-box d-flex flex-column align-items-start l-box"
                  >
                    <span className="dta-badge">Musical events</span>
                    <h4 className="h3">
                      Musical event or GIG’S and hire Talents
                    </h4>
                    <ul>
                      <li>Create Jubal events in just a few clicks</li>
                      <li>Hire Talents for event and purchase tickets</li>
                    </ul>
                  </motion.div>
                </div>
                <div className="col-12 col-md-5 col-lg-6 order-2 order-md-2">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/side-img2.png`}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="col-12 p-0">
              <div className="row align-items-center justify-content-between">
                <div className="col-12 col-md-5 col-lg-6 order-2 order-md-1">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/side-img3.png`}
                    alt=""
                  />
                </div>
                <div className="col-12 col-md-7 col-lg-5 order-1 order-md-2">
                  <motion.div
                    ref={refRBox2}
                    initial="hidden"
                    animate={inViewRBox2 ? "visible" : "hidden"}
                    variants={itemVariants}
                    transition={{ duration: 1, ease: "circOut" }}
                    className="dta-box d-flex flex-column align-items-start r-box2"
                  >
                    <span className="dta-badge">Reports and Anayitics</span>
                    <h4 className="h3">
                      Buy and sell your instruments or Equipment
                    </h4>
                    <ul>
                      <li>Search and filter instruments and equipments</li>
                      <li>Buy your favorite instruments and equipments</li>
                    </ul>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionSection;
