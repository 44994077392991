import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { RoutesLink } from "../utility/RoutesLink";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import UsersMenuModal from "./Modals/UsersMenuModal";

const Header = () => {
  const [loadScript, setLoadScript] = useState(true);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [UserMenuModal, setUserMenuModal] = useState(false);
  const UserMenuToggle = () => setUserMenuModal(!UserMenuModal);

  useEffect(() => {
    // const script = document.createElement("script");
    const script1 = document.createElement("script1");
    // script.src = "js/animation.js";
    script1.src = "js/script.js";
    // script.async = true;
    script1.async = true;
    // document.body.appendChild(script);
    document.body.appendChild(script1);
    return () => {
      if (loadScript) {
        // document.body.removeChild(script);
        document.body.removeChild(script1);
        setLoadScript(false);
      }
    };
  }, [loadScript]);

  const userDetails = useSelector((store) => store?.user?.userDetails);
  const Navurl = window.location?.pathname;

  return (
    <>
      <header>
        <div className="max-width-container nav-container">
          <nav className="navbar navbar-expand-lg p-0 navbar-dark">
            <div className="container-fluid">
              <Link to={RoutesLink?.Homepage} className="navbar-brand p-0">
                <div className="brand-wrapper d-flex align-items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logos/jubal_logo.png`}
                    alt="Jubal_logo"
                  />
                  <span> Jubal Talents </span>
                </div>
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-center"
                id="navbar"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link
                      className={
                        Navurl === "/" ? "nav-link active" : "nav-link"
                      }
                      aria-current="page"
                      to={RoutesLink?.Homepage}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        Navurl === "/about-us" ? "nav-link active" : "nav-link"
                      }
                      to={RoutesLink?.about_us}
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={`${RoutesLink?.Homepage}#mission`}
                      className="nav-link"
                      href="#mission"
                    >
                      Features
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to={RoutesLink.subscription}
                      className={
                        Navurl === "/subscription"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Subscription
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        Navurl === "/contact" ? "nav-link active" : "nav-link"
                      }
                      to={RoutesLink.contact}
                    >
                      Contact
                    </Link>
                  </li>
                  <hr className="hr d-lg-none d-block my-4" />

                  {/* <button
                    className="get-app-btn d-none d-lg-block"
                    onClick={() => setModal(!modal)}
                  >
                    Get the App
                  </button>

                  <hr className="hr" />
                  <li className="nav-item mb-4">
                    <Link
                      to={RoutesLink?.sign_in}
                      className="sign-in-btn d-lg-none d-block"
                    >
                      Sign In
                    </Link>
                  </li> */}
                  {userDetails?.Profile?.id ? (
                    <>
                      <li className="nav-item mb-4 d-lg-none d-block">
                        <Link to={RoutesLink?.dashboard} className="dash-btn">
                          Go to Dashboard
                        </Link>
                      </li>
                      <li className="nav-item mb-4 d-lg-none d-block">
                        <Link
                          to="#"
                          onClick={() => setUserMenuModal(!UserMenuModal)}
                        >
                          <div className="position-relative">
                            <div className="user-det-head">
                              <div className="user-det d-flex justify-content-between align-items-center">
                                <div className="d-flex gap-3 align-items-center">
                                  <div className="user-dp no-img">
                                    {
                                      userDetails?.Profile?.firstName?.toUpperCase()[0]
                                    }
                                  </div>
                                  <div className="user-ac">
                                    <div className="d-flex gap-2 align-items-center">
                                      <span className="username text-white">
                                        <h5>
                                          {userDetails?.Profile?.firstName}{" "}
                                          {userDetails?.Profile?.lastName}
                                        </h5>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-item mb-4 d-lg-none d-block">
                        <Link
                          to="#"
                          className="get-app-btn text-center w-100 d-inline-block"
                          onClick={() => setModal(!modal)}
                        >
                          Get the App
                        </Link>
                      </li>
                      <li className="nav-item mb-4 d-lg-none d-block">
                        <Link
                          to={RoutesLink?.sign_in}
                          className="sign-in-btn text-center w-100 d-inline-block"
                        >
                          Sign In
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              {/* {userDetails?.Profile?.id ? (
                <Link
                  to={RoutesLink?.dashboard}
                  className="sign-in-btn d-none d-lg-block"
                >
                  Go to Dashboard
                </Link>
              ) : (
                <Link
                  to={RoutesLink?.sign_in}
                  className="sign-in-btn d-none d-lg-block"
                >
                  Sign In
                </Link>
              )} */}

              {userDetails?.Profile?.id ? (
                <>
                  <Link
                    to={RoutesLink?.dashboard}
                    className="dash-btn d-none d-lg-block"
                  >
                    Go to Dashboard
                  </Link>
                  <Link
                    to="#"
                    className="d-none d-lg-block"
                    onClick={() => setUserMenuModal(!UserMenuModal)}
                  >
                    <div className="position-relative">
                      <div
                        className="user-det-head"
                        // data-bs-toggle="offcanvas"
                        // data-bs-target="#sidemenu"
                        // aria-controls="sidemenu"
                      >
                        <div className="user-det d-flex justify-content-between align-items-center">
                          <div className="d-flex gap-3 align-items-center">
                            <div className="user-dp no-img">
                              {
                                userDetails?.Profile?.firstName?.toUpperCase()[0]
                              }
                            </div>
                            <div className="user-ac">
                              <div className="d-flex gap-2 align-items-center">
                                <span className="username text-white">
                                  <h5>
                                    {userDetails?.Profile?.firstName}{" "}
                                    {userDetails?.Profile?.lastName}
                                  </h5>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="#"
                    className="get-app-btn d-none d-lg-block"
                    onClick={() => setModal(!modal)}
                  >
                    Get the App
                  </Link>
                  <Link
                    to={RoutesLink?.sign_in}
                    className="sign-in-btn d-none d-lg-block"
                  >
                    Sign In
                  </Link>
                </>
              )}
            </div>
          </nav>
        </div>
      </header>
      <Helmet>
        <script src="js/script.js"></script>
        {/* <script src="js/animation.js"></script> */}
      </Helmet>
      <Modal
        className="modal-dialog modal-dialog-centered get-app-dialog"
        toggle={toggle}
        isOpen={modal}
        // contentClassName="modal-content create-post comment-modal share-modal"
      >
        <ModalBody>
          <div className="row ">
            <div className="col-12 col-md-4 col-lg-4 p-0 pe-lg-4">
              <div className="img-box">
                <img
                  src={`${process.env.PUBLIC_URL}/images/appQR.png`}
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-8 p-0 ps-md-4">
              <div className="d-flex h-100 flex-column align-items-start justify-content-center">
                <h2>Downloading the app is super simple...</h2>
                <p>
                  Simply scan the QR code, or enter your mobile number below to
                  get a download link via SMS
                </p>
                <div className="mail-wrapper">
                  <label htmlFor="g-mail" className="form-label">
                    Email Address
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="g-mail"
                      aria-describedby="basic-addon3 basic-addon4"
                      placeholder="email@gmail.com"
                    />
                    <button className="btn btn-purple" type="button">
                      Get Link
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {userDetails?.Profile?.id && (
        <UsersMenuModal
          modal={UserMenuModal}
          UserMenuToggle={(val) => UserMenuToggle(val)}
        />
      )}
    </>
  );
};

export default Header;
