import React, { useEffect, useState, useMemo, memo } from "react"; // Import necessary hooks from React
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap"; // Import Offcanvas components for modal display
import { handleFetchRequest } from "../../ApiHelper/ApiHelper"; // Import function for API requests
import { ApiUrl } from "../../utility/ApiUrl"; // Import API URL constants
import { Helper, notificationTypes } from "../../Helper/Helper"; // Import helper functions and notification types
import { RoutesLink } from "../../utility/RoutesLink"; // Import routing links
import { Link, Route, useNavigate } from "react-router-dom"; // Import routing components and hooks
import moment from "moment"; // Import moment.js for date manipulation
import { useSelector } from "react-redux"; // Import Redux hook for state management
import { toast } from "material-react-toastify"; // Import toast notification
import CommentModal from "./CommentModal"; // Import CommentModal component

function NotificationModal({
  modal, // State to control the visibility of the modal
  toggle, // Function to toggle modal visibility
  UnreadNotification, // Array of unread notifications
  setUnreadNotification, // Function to update unread notifications
}) {
  const [Notification, setNotification] = useState([]); // State for all notifications
  const [AllFollowing, setAllFollowing] = useState([]); // State for users being followed
  const navigate = useNavigate(); // Hook for navigation
  const userDetail = useSelector((store) => store?.user?.userDetails?.id); // Get current user ID from Redux store
  const [selectedTab, setSelectedTab] = useState("all"); // State to track the selected tab (all/unread)
  const [apiCall, setApiCall] = useState(false); // State to trigger API calls
  const [selectedNotifications, setSelectedNotifications] = useState([]); // State for selected notifications
  const [isManagingNotifications, setIsManagingNotifications] = useState(false); // State for managing notifications
  const [commentModalVisible, setCommentModalVisible] = useState(false); // State to control comment modal visibility
  const [selectedPost, setSelectedPost] = useState(""); // State for the currently selected post

  // Effect to fetch notifications and following list
  useEffect(() => {
    if (userDetail) {
      handleFetchRequest(ApiUrl.notification, "GET").then((response) => {
        if (response.status === true) {
          setNotification(response?.data?.notification);
        }
      });
      // Fetch unread notifications
      handleFetchRequest(`${ApiUrl.notification}?seen=0`, "GET").then(
        (response) => {
          if (response.status === true) {
            setUnreadNotification(response?.data?.notification);
          }
        }
      );
      // Fetch following users
      handleFetchRequest(`${ApiUrl.myFollowing}${userDetail}`).then(
        (response) => {
          if (response.status === true) {
            setAllFollowing(response?.data?.user || []); // Ensure a default array is set
          }
        }
      );
    }
  }, [userDetail, apiCall]);
  // Function to fetch post details based on post ID
  const getPostDetail = async (postId) => {
    handleFetchRequest(`${ApiUrl.post_detail}${postId}`).then((response) => {
      if (response.status === true) {
        setSelectedPost(response?.data);
      }
    });
  };
  // Memoized notifications to optimize rendering
  const memoizedNotifications = useMemo(() => {
    return Notification.map((res) => ({
      ...res,
      notificationData: {
        ...res.notificationData,
        fullName: res?.notificationData?.body?.data?.name
          ? res?.notificationData?.body?.data?.name
          : Helper.getFullName({
              firstName:
                res?.notificationData?.body?.sender?.firstName ||
                res?.user?.firstName,
              lastName:
                res?.notificationData?.body?.sender?.lastName ||
                res?.user?.lastName,
            }),
        msg: res?.notificationData?.message,
        image:
          res?.notificationData?.body?.data?.image || res?.user?.profilePhoto,
        helperImage: res?.post?.postImageUrl,
        date: res?.createdAt,
      },
    }));
  }, [Notification]);

  // Memoized unread notifications for optimized rendering
  const memoizedUnreadNotifications = useMemo(() => {
    return UnreadNotification.map((res) => ({
      ...res,
      notificationData: {
        ...res.notificationData,
        fullName: res?.notificationData?.body?.data?.name
          ? res?.notificationData?.body?.data?.name
          : Helper.getFullName({
              firstName:
                res?.notificationData?.body?.sender?.firstName ||
                res?.user?.firstName,
              lastName:
                res?.notificationData?.body?.sender?.lastName ||
                res?.user?.lastName,
            }),
        msg: res?.notificationData?.message,
        image:
          res?.notificationData?.body?.data?.image || res?.user?.profilePhoto,
        helperImage: res?.post?.postImageUrl,
        date: res?.createdAt,
      },
    }));
  }, [UnreadNotification]);
  // Function to follow a user by ID
  const followUser = (userId) => {
    handleFetchRequest(`${ApiUrl.Follow_user}${userId}`).then((res) => {
      if (res.status === true) {
        updateFollowingList(userId);
      }
    });
  };
  // Function to unfollow a user by ID
  const unfollowUser = (userId) => {
    handleFetchRequest(`${ApiUrl.Follow_user}${userId}`).then((res) => {
      if (res.status === true) {
        removeFromFollowingList(userId);
      }
    });
  };
  // Check if the user is being followed
  const CheckFollwong = (id) => {
    return AllFollowing.some((res) => res?.following?.id === id);
  };
  // Add a user to the following list
  const updateFollowingList = (id) => {
    setAllFollowing((prev) => [...prev, { following: { id } }]);
  };
  // Remove a user from the following list
  const removeFromFollowingList = (id) => {
    setAllFollowing((prev) => prev.filter((res) => res.following.id !== id));
  };
  // Redirect based on notification type
  const RedirectNotification = (notificationId, type, id) => {
    // return;
    if (notificationId) {
      // Check the type of notification and navigate accordingly
      if (
        [
          notificationTypes.createBooking,
          notificationTypes.updateBooking,
          notificationTypes.reviewBooking,
        ].includes(type)
      ) {
        navigate(`${RoutesLink?.RequestDetails}/${notificationId}`, {
          state: notificationId,
        });
        return;
      }

      if (notificationTypes.get_my_order === type) {
        navigate(RoutesLink?.orderList);
        return;
      }
      if (notificationTypes.get_instrument_order === type) {
        navigate(RoutesLink?.bookingList, { type: notificationId });
        return;
      }
      if (notificationTypes?.tagPost === type) {
        navigate(RoutesLink?.Activity, {
          state: {
            tag: true,
          },
        });
      }

      // Handle post-related notifications
      if (
        [
          notificationTypes?.likePost,
          notificationTypes?.createComment,
          notificationTypes?.replyComment,
          notificationTypes?.sharePost
        ].includes(type)
      ) {
        getPostDetail(notificationId); // Fetch post details
        setCommentModalVisible(true); // Show comment modal
        return;
      }

      if (notificationTypes.eventList === type) {
        navigate(RoutesLink?.EventDetails, { state: notificationId });
        return;
      }

      // Handle user profile navigation
      if (
        notificationTypes.following === type ||
        notificationTypes.follower == type
      ) {
        navigate(RoutesLink?.profile, { state: { id: notificationId } });
        return;
      }

      // Handle event-related notifications
      if (
        notificationTypes.eventPurchase == type ||
        notificationTypes.eventUpdate == type
      ) {
        navigate(`${RoutesLink?.EventDetails}/${notificationId}`);
        return;
      }

      // Handle instrument updates
      if (notificationTypes.instrumentUpdate === type) {
        navigate(RoutesLink?.InstrumentsDetails, {
          instrumentId: notificationId,
        });
        return;
      }

      // Handle order-related notifications
      if (
        notificationTypes?.orderUpdate === type ||
        type == "instrumentRequest"
      ) {
        navigate(RoutesLink?.sellerOrder, {
          state: { OrderId: id, itemId: notificationId },
        });
        return;
      }
      if (notificationTypes?.createChat === type) {
        navigate(`${RoutesLink?.chat}/${notificationId}`);
        return;
      }
    }
  };

  const handleSelectNotification = (id) => {
    setSelectedNotifications((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((notifId) => notifId !== id)
        : [...prevSelected, id]
    );
  };
  // Function to mark selected notifications as read
  const markAsRead = () => {
    handleFetchRequest(ApiUrl.markAsRead, "GET").then((response) => {
      if (response.status === true) {
        // setNotification((prevNotifications) =>
        //   prevNotifications.filter(
        //     (notification) => !selectedNotifications.includes(notification.id)
        //   )
        // );
        setApiCall(!apiCall);
        toast.success(response?.message);
        // setSelectedNotifications([]); // Clear selected notifications after marking them as read
      }
    });
  };
  // Function to delete selected notifications
  const deleteSelectedNotifications = () => {
    handleFetchRequest(ApiUrl.deleteNotification, "POST", {
      id: selectedNotifications,
    }).then((response) => {
      if (response.status === true) {
        toast.success(response?.message);
        setNotification((prevNotifications) =>
          prevNotifications.filter(
            (notification) => !selectedNotifications.includes(notification.id)
          )
        );
        setSelectedNotifications([]); // Clear selected notifications after deletion
      }
    });
  };
  //funcation to close the open midal
  const closeModals = () => {
    setCommentModalVisible(false);
    setSelectedPost(null);
    setCommentModalVisible(false);
  };

  // if user click from the notication tab it will open a modal and in that modal user can like the post
  const LikeHandler = async (data, like, type) => {
    // Check if data and postId exist
    if (!data?.id) {
      console.error("Invalid post data: missing post ID");
      return;
    }

    // Optimistically update the state immediately
    const newLikeStatus = !like; // Toggle the like status
    const likeCountChange = like ? -1 : 1; // Adjust like count based on current status

    setSelectedPost((prevDetails) => {
      if (!prevDetails) {
        console.error("No previous post details found");
        return prevDetails;
      }

      return {
        ...prevDetails,
        isLikedByMe: newLikeStatus,
        likesCount: prevDetails.likesCount + likeCountChange,
      };
    });

    // Prepare the data for API call
    const LikesData = {
      type: type,
      postId: data.id,
    };

    try {
      // Make the API call in the background
      const response = await handleFetchRequest(
        ApiUrl.post_like_comment,
        "POST",
        LikesData
      );

      // Log the API response for debugging

      // If the API call fails, revert the state back
      if (!response.success) {
        console.error("API request failed:", response.error);

        // Revert the optimistic update
        setSelectedPost((prevDetails) => ({
          ...prevDetails,
          isLikedByMe: like, // Revert to original like status
          likesCount: prevDetails.likesCount - likeCountChange, // Revert like count
        }));
      }
    } catch (error) {
      console.error("API call error:", error);

      // Revert to the original state in case of error
      setSelectedPost((prevDetails) => ({
        ...prevDetails,
        isLikedByMe: like, // Revert to original like status
        likesCount: prevDetails.likesCount - likeCountChange, // Revert like count
      }));
    }
  };
  //if user click from the notication tab it will open a modal and in that modal user can Save the post
  const savePost = async (data, save) => {
    console.log("Post Details:", data, "Save Status:", save);

    // Check if the data and postId exist
    if (!data?.id) {
      console.error("Invalid post data: missing post ID");
      return;
    }

    // Optimistically update the state immediately
    const newSaveStatus = !save; // Toggle the saved status based on current 'save' state

    setSelectedPost((prevDetails) => {
      if (!prevDetails) {
        console.error("No previous post details found");
        return prevDetails;
      }

      return {
        ...prevDetails,
        isSavedByMe: newSaveStatus, // Update UI optimistically
      };
    });

    // Determine which API to call based on the 'save' parameter
    let API;
    if (save) {
      // If the post is already saved, use the unsave API
      API = `${ApiUrl.post_remove_save}${data?.id}`;
    } else {
      // If the post is not saved, use the save API
      API = `${ApiUrl.post_save}${data?.id}`;
    }

    try {
      // Make the API call in the background
      const response = await handleFetchRequest(API);

      // Log the API response for debugging
      console.log("API Response:", response);

      // If the API call fails, revert the optimistic state
      if (!response.success) {
        console.error("API request failed:", response.error);

        // Revert the optimistic update if API fails
        setSelectedPost((prevDetails) => ({
          ...prevDetails,
          isSavedByMe: save, // Revert back to original save status
        }));
      }
    } catch (error) {
      console.error("API call error:", error);

      // Revert to the original state in case of error
      setSelectedPost((prevDetails) => ({
        ...prevDetails,
        isSavedByMe: save, // Revert back to original save status
      }));
    }
  };

  //notifcation click can redirect user acording to the notification type for that you can use this funcation
  const onNotifcationClick = (res) => {
    const notifiyType = res?.notificationData?.body?.data?.type;
    let id = `${res?.notificationData?.body?.sender?.id}`
      ? `${res?.notificationData?.body?.sender?.id}`
      : `${res?.notificationData?.body?.data?.id}`;

    let type = res?.notificationData?.body?.sender?.id
      ? "message"
      : res?.notificationData?.body?.data?.type;

    if (type == "message") {
      navigate(RoutesLink?.Chat, {
        state: {
          id: id,
          first_name: res?.notificationData?.body?.sander?.firstName,
          last_name: res?.notificationData?.body?.sander?.lastName,
        },
      });
      return;
    }

    if (res?.post?.id) {
      RedirectNotification(res?.post?.id, notifiyType);
      return;
    } else if (notifiyType == notificationTypes?.eventPurchase) {
      RedirectNotification(res?.notificationData?.body?.data?.id, notifiyType);
    } else if (res?.notificationData?.body?.screen == "Get Insturment Order") {
      RedirectNotification(
        res?.notificationData?.body?.data?.instrumentId,
        "instrumentRequest",
        res?.notificationData?.body?.data?.id
      );
    } else if (
      res?.notificationData?.body?.screen == "Get My Order" ||
      res?.notificationData?.body?.screen == "Get My Orders"
    ) {
      RedirectNotification(
        res?.notificationData?.body?.data?.instrumentId ||
          res?.notificationData?.body?.data?.itemId,
        "orderDetail",
        res?.notificationData?.body?.data?.id
      );
    } else if (notifiyType === notificationTypes?.orderUpdate) {
      RedirectNotification(
        res?.notificationData?.body?.data?.itemId,
        notifiyType
      );
    } else if (
      notifiyType === notificationTypes?.follower ||
      notificationTypes?.following
    ) {
      RedirectNotification(
        res?.notificationData?.body?.data?.userId,
        notifiyType
      );
    } else {
      RedirectNotification(res?.id, notifiyType);
    }
  };
  return (
    <>
      <Offcanvas
        direction="end"
        className="offcanvas-end notifications"
        toggle={toggle}
        isOpen={modal}
      >
        <OffcanvasHeader toggle={toggle}>
          <h5 className="offcanvas-title" id="cartLabel">
            Notifications
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody className="offcanvas-body">
          <div className="d-flex align-items-center justify-content-between">
            <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#all-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="all-tab-pane"
                  aria-selected="true"
                  onClick={() => setSelectedTab("all")}
                >
                  All
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#request-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="request-tab-pane"
                  aria-selected="false"
                  onClick={() => setSelectedTab("request")}
                >
                  Request
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#unread-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="unread-tab-pane"
                  aria-selected="false"
                  onClick={() => setSelectedTab("unread")}
                >
                  Unread
                </button>
              </li>
            </ul>

            {selectedTab === "all" && memoizedNotifications?.length > 0 && (
              <button
                onClick={() => setIsManagingNotifications((prev) => !prev)}
                className="nav-link p-0 m-0"
                type="button"
              >
                Manage
              </button>
            )}
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="all-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabIndex={0}
            >
              <div className="d-flex justify-content-between">
                <h4>All Notifications</h4>

                {memoizedNotifications?.length > 0 && (
                  <button className="nav-link p-0 m-0" onClick={markAsRead}>
                    Mark as Read
                  </button>
                )}
              </div>
              <span className="type">
                {memoizedNotifications?.length > 0
                  ? "New"
                  : "No any notification"}
              </span>
              <ul>
                {memoizedNotifications &&
                  memoizedNotifications.map((res) => (
                    <li
                      key={res.id}
                      onClick={() =>
                        !isManagingNotifications && onNotifcationClick(res)
                      }
                    >
                      <div className="user-det-head">
                        <div className="user-det d-flex justify-content-between">
                          <div className="d-flex gap-3">
                            {res.notificationData.image ? (
                              <div className="user-dp">
                                <img
                                  src={
                                    Array.isArray(res.notificationData.image)
                                      ? res.notificationData.image[0]
                                      : res.notificationData.image
                                  }
                                  alt=""
                                />
                              </div>
                            ) : (
                              <div className="user-dp no-img">
                                {res?.notificationData?.fullName?.[0]}
                              </div>
                            )}

                            <div className="user-ac">
                              <div className="d-flex gap-lg-2 flex-column">
                                <span className="username">
                                  <Link to="#">
                                    {res.notificationData.fullName}
                                  </Link>
                                </span>
                                <p>{res.notificationData.msg}</p>
                                <span className="date">
                                  {moment(res?.notificationData?.date).format(
                                    "llll"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            {res?.post?.postImageUrl &&
                            res?.post?.mediaType !== "video" ? (
                              <img
                                src={res?.post?.postImageUrl}
                                alt=""
                                className="content-img"
                              />
                            ) : (
                              ""
                              // <video src={res?.post?.postImageUrl} autoPlay muted className="content-img"></video>
                            )}
                            {/* {res?.notificationData?.helperImage && (
                            <img
                              src={res.notificationData?.helperImage}
                              alt=""
                              className="content-img"
                            />
                          )} */}
                            {isManagingNotifications && (
                              <input
                                type="checkbox"
                                className="ms-3"
                                checked={selectedNotifications.includes(res.id)}
                                onChange={() =>
                                  handleSelectNotification(res.id)
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <div
              className="tab-pane fade"
              id="request-tab-pane"
              role="tabpanel"
              aria-labelledby="profile-tab"
              tabIndex={0}
            >
              <h4>Follow Requests</h4>
              <span className="type">
                {memoizedNotifications?.length > 0
                  ? "New"
                  : "No any notification"}
              </span>
              <ul>
                {memoizedNotifications
                  .filter(
                    (res) =>
                      res?.notificationData?.body?.data?.type === "follower"
                  )
                  .map((data) => (
                    <li key={data.id}>
                      <div className="user-det-head">
                        <div className="user-det d-flex align-items-start justify-content-between">
                          <div className="d-flex gap-3">
                            {data?.notificationData?.image ? (
                              <div className="user-dp">
                                <img
                                  src={data?.notificationData?.image}
                                  alt=""
                                />
                              </div>
                            ) : (
                              <div className="user-dp no-img">
                                {data?.notificationData?.fullName?.[0]}
                              </div>
                            )}

                            <div className="user-ac">
                              <div className="d-flex gap-lg-2 flex-column">
                                <span className="username">
                                  <Link to="#">
                                    {data?.notificationData?.fullName}
                                  </Link>
                                </span>
                                <p>{data?.notificationData?.message}</p>
                                <span className="date">
                                  {moment(data?.notificationData?.date).format(
                                    "llll"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            {CheckFollwong(
                              data?.notificationData?.body?.data?.userId
                            ) ? (
                              <button
                                type="button"
                                className="btn btn-light remove-btn"
                                onClick={() =>
                                  unfollowUser(
                                    data?.notificationData?.body?.data?.userId
                                  )
                                }
                              >
                                Following
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-outline-info"
                                onClick={() =>
                                  followUser(
                                    data?.notificationData?.body?.data?.userId
                                  )
                                }
                              >
                                Follow Back
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <div
              className="tab-pane fade"
              id="unread-tab-pane"
              role="tabpanel"
              aria-labelledby="contact-tab"
              tabIndex={0}
            >
              <h4>Unread</h4>
              <span className="type">
                {memoizedNotifications?.length > 0
                  ? "New"
                  : "No any notification"}
              </span>
              <ul>
                {memoizedUnreadNotifications &&
                  memoizedUnreadNotifications.map((res) => (
                    <li
                      key={res.id}
                      onClick={() =>
                        !isManagingNotifications && onNotifcationClick(res)
                      }
                      // onClick={() =>
                      //   !isManagingNotifications &&
                      //   RedirectNotification(
                      //     res.id,
                      //     res?.notificationData?.body?.data?.type
                      //   )
                      // }
                    >
                      {/* {console.log(res)} */}
                      <div className="user-det-head">
                        <div className="user-det d-flex justify-content-between">
                          <div className="d-flex gap-3">
                            <div className="user-dp">
                              <img
                                src={
                                  Array.isArray(res.notificationData.image)
                                    ? res.notificationData.image[0]
                                    : res.notificationData.image
                                }
                                alt=""
                              />
                            </div>
                            <div className="user-ac">
                              <div className="d-flex gap-lg-2 flex-column">
                                <span className="username">
                                  <Link to="#">
                                    {res.notificationData.fullName}
                                  </Link>
                                </span>
                                <p>{res.notificationData.msg}</p>
                                <span className="date">
                                  {moment(res?.notificationData?.date).format(
                                    "llll"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            {res?.notificationData?.helperImage && (
                              <img
                                src={res.notificationData?.helperImage}
                                alt=""
                                className="content-img"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            {isManagingNotifications && (
              <div className="delete-btns">
                <p>Selected Items (0)</p>
                <div className="btn-group">
                  <button
                    onClick={deleteSelectedNotifications}
                    className="btn btn-link"
                    disabled={selectedNotifications.length === 0 ? true : false}
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => setIsManagingNotifications((prev) => !prev)}
                    className="btn btn-link"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </OffcanvasBody>
      </Offcanvas>
      {commentModalVisible && (
        <CommentModal
          isOpen={commentModalVisible}
          onRequestClose={closeModals}
          PostId={selectedPost?.id}
          postData={selectedPost}
          // commentPost={commentPost}
          likePost={LikeHandler}
          savePost={savePost}
        />
      )}
    </>
  );
}

export default NotificationModal;
