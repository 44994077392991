import { Link, Route, useLocation, useNavigate } from "react-router-dom";
// Import necessary hooks and components

import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import { CountryCode } from "../../../utility/CountryCode";
// Utility for country codes (not used in the provided code)

import { RoutesLink } from "../../../utility/RoutesLink";
// Utility for route links

import { useEffect, useState } from "react";
// React hooks for managing state and side-effects

import { useForm } from "react-hook-form";
// React Hook Form for form handling and validation

import GooglePlaceAutocomplete from "../../../Components/GooglePlaceAutocomplete";
// Google Place Autocomplete component for location selection

import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
// Helper for making API requests

import { ApiUrl } from "../../../utility/ApiUrl";
// API URL utility for managing API endpoints

import { setIsLocationDetails } from "../../../Helper/Helper";
// Helper function (not used in the provided code)

const TalentSignUp1 = () => {
  const navigate = useNavigate();
  // Hook to programmatically navigate between pages
  const location = useLocation();
  // Hook to access the location object and retrieve passed state

  const [usertype, setUsertype] = useState(3);
  // State to manage user type (default is 3 for Talent)

  const [showPassword, setShowPassword] = useState(false);
  // State to toggle visibility of the password field

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // State to toggle visibility of the confirm password field

  // const [isLocation, setIsLocation] = useState({});
  // Commented out state for location (not currently used)

  const [StateData, setStateData] = useState({});
  // State to hold general data passed from the previous form/page

  const [locationError, setLocationError] = useState(null);
  // State to track errors related to location input

  console.log("location", location?.state);
  // Log location data for debugging purposes

  const [showExistValueStatus, setShowExistValueStatus] = useState({
    username: "",
    email: "",
  });
  // State to manage whether username or email is available or already taken

  const [selectedPlace, setSelectedPlace] = useState(null);
  // State to store the selected location from Google Places autocomplete

  useEffect(() => {
    console.log("locaaa", location?.state?.type);
    // Log the location type for debugging

    // Check the passed user type and set the usertype state accordingly
    location?.state && location?.state?.type == 3
      ? setUsertype(2) // If the type is 3, set usertype to 2 (Vendor)
      : setUsertype(3); // Otherwise, keep it as Talent (3)

    // setStateData(location.state);
    // Commented out setting StateData (not currently used)
  }, []);

  // React Hook Form setup for form handling
  const {
    register, // Register function to register form inputs
    reset, // Reset function to reset form data
    watch, // Watch function to observe form values
    handleSubmit, // Function to handle form submission
    setValue, // Function to programmatically set form values
    formState: { errors }, // Object containing form validation errors
  } = useForm();

  // Prefill form with data if available from location state
  useEffect(() => {
    if (location?.state) {
      setValue("first_name", location?.state?.Profile?.firstName);
      setValue("last_name", location?.state?.Profile?.lastName);
      setValue("email", location?.state?.email);
      setValue("gender", location?.state?.Profile?.gender);
      setValue("country_code", location?.state?.countryCode);
      setValue("phone", location?.state?.phone);
      setValue("about", location?.state?.Profile?.about);
      setAboutText(location?.state?.Profile?.about);
      setValue("age", location?.state?.Profile?.age);
    }

    // If navigating back to the form, set previous form data
    if (location?.state?.back) {
      setValue("first_name", location?.state?.back?.first_name);
      setValue("last_name", location?.state?.back?.last_name);
      setValue("email", location?.state?.back?.email);
      setValue("gender", location?.state?.back?.gender);
      setValue("country_code", location?.state?.back?.country_code);
      setValue("phone", location?.state?.back?.phone);
      setValue("about", location?.state?.back?.about);
      setValue("username", location?.state?.back?.username);
      setValue("band_name", location?.state?.back?.band_name);
      setValue("password", location?.state?.back?.password);
      setValue("cpassword", location?.state?.back?.cpassword);
      setSelectedPlace(location?.state?.back?.location);
      setUsertype(location?.state?.back?.usertype);
    }
  }, [setValue]);

  // Clear location error when a place is selected
  useEffect(() => {
    if (selectedPlace) {
      setLocationError(null);
    }
  }, [selectedPlace]);

  // Function to check if username or email is already taken
  const usernameChangeHandler = (value, submitKey) => {
    if (value.length > 1) {
      handleFetchRequest(ApiUrl.get_user_check, "POST", {
        [submitKey]: value,
      }).then((res) => {
        if (res.status == true) {
          if (res.data == null) {
            setShowExistValueStatus((prevState) => {
              return {
                ...prevState,
                [submitKey]: "Available",
              };
            });
          } else {
            setShowExistValueStatus((prevState) => {
              return {
                ...prevState,
                [submitKey]: "Already Taken",
              };
            });
          }
        }
      });
    } else {
      setShowExistValueStatus({ ...showExistValueStatus, username: "" });
    }
  };

  // Function to handle form submission
  const onSubmit = (data) => {
    if (
      !selectedPlace &&
      !location?.state?.Profile?.location &&
      !location?.state?.back?.location
    ) {
      setLocationError("Please enter your location");
      return;
    }

    if (showExistValueStatus?.email != "Already Taken") {
      // Navigate to the next signup step, passing along form data
      navigate(RoutesLink?.talent_sign_up_s1, {
        state: {
          ...data,
          usertype: usertype,
          location: selectedPlace,
          userData: location.state,
        },
      });
    }
  };

  // Toggle password visibility
  const handleShowPassword = () => {
    setShowPassword((pre) => !pre);
  };

  // Toggle confirm password visibility
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((pre) => !pre);
  };

  // Watch the password input for validation
  const password = watch("password");

  //about section count limit
  const [aboutText, setAboutText] = useState("");
  const maxCharacters = 500;

  const handleInputChange = (e) => {
    if (e.target.value.length <= maxCharacters) {
      setAboutText(e.target.value);
    }
  };

  return (
    <>
      <Header />
      <section className="signin navbar-spc">
        <div className="max-width-container h-100">
          <div className="row h-100 justify-content-between justify-content-lg-center">
            <div className="col-12 col-md-5 col-lg-5 col-xl-6 p-0 d-none d-md-flex align-items-center justify-content-center position-relative">
              <div className="img-box">
                <img src="images/sign_in/frame.png" alt="" className="frame" />
                <img
                  src="images/sign_in/sign-img2.jpg"
                  alt=""
                  className=" content-img"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5 col-xl-6 p-0 d-flex justify-content-center align-items-center">
              <div class="card signin-card">
                <div class="card-body">
                  {location?.state?.type ? (
                    <ul class="nav nav-pills card-tabs" role="tablist">
                      {location?.state?.Talent == null &&
                      location?.state?.Vendor == null ? (
                        <>
                          <li className="nav-item" role="presentation">
                            <button
                              className={
                                usertype === 3 ? "nav-link active" : "nav-link"
                              }
                              // id="Talent-tab"
                              // data-bs-toggle="tab"
                              // data-bs-target="#Talent-tab-pane"
                              // type="button"
                              // role="tab"
                              // aria-controls="Talent-tab-pane"
                              // aria-selected="true"
                              onClick={() => setUsertype(3)}
                            >
                              Talent
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className={
                                usertype === 2 ? "nav-link active" : "nav-link"
                              }
                              // id="Vendor-tab"
                              // data-bs-toggle="tab"
                              // data-bs-target="#Vendor-tab-pane"
                              // type="button"
                              // role="tab"
                              // aria-controls="Vendor-tab-pane"
                              // aria-selected="false"
                              onClick={() => setUsertype(2)}
                            >
                              Vendor
                            </button>
                          </li>
                        </>
                      ) : (
                        <li className="nav-item">
                          <button
                            className={
                              "nav-link active"
                              // usertype === 2 ? "nav-link active" : "nav-link"
                            }
                            // id="Vendor-tab"
                            // data-bs-toggle="tab"
                            // data-bs-target="#Vendor-tab-pane"
                            // type="button"
                            // role="tab"
                            // aria-controls="Vendor-tab-pane"
                            // aria-selected="false"
                            // onClick={() => setUsertype(2)}
                          >
                            {location?.state?.type == 3 ? "Vendor" : "Talent"}
                          </button>
                        </li>
                      )}
                    </ul>
                  ) : (
                    <ul className="nav nav-pills card-tabs" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className={
                            usertype === 3 ? "nav-link active" : "nav-link"
                          }
                          onClick={() => setUsertype(3)}
                        >
                          Talent
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={
                            usertype === 2 ? "nav-link active" : "nav-link"
                          }
                          onClick={() => setUsertype(2)}
                        >
                          Vendor
                        </button>
                      </li>
                    </ul>
                  )}

                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="Talent-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Talent-tab"
                      tabindex="0"
                    >
                      <div className="row tab-descp">
                        <div className="col-12 p-0">
                          {usertype === 2 ? (
                            <>
                              <h4>Vendor</h4>
                              <p>
                                Become a Jubal Vendor and unlock all the
                                features and benefits of the Jubal Community.
                                Sell tickets, find and hire talent, promote your
                                artist, create events, and more.
                              </p>
                            </>
                          ) : (
                            <>
                              <h4>Talent Community</h4>
                              <p>
                                If you would like to proceed with signing up as
                                a Jubal Talent, you will be part of the Jubal
                                Talents community granting you access to
                                performance and networking opportunities
                              </p>
                            </>
                          )}
                        </div>
                      </div>

                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row ">
                          <div className="col-12 p-0">
                            <label for="first_name" className="form-label">
                              First Name
                              <span className="text-danger fs-2 ps-1">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="first_name"
                              placeholder="First Name"
                              {...register("first_name", {
                                required: "Please enter first name",
                                pattern: {
                                  value: /^[a-zA-ZÀ-ÖØ-öø-ÿ '-]+$/,
                                  message:
                                    "First name can only contain letters, spaces, hyphens, and apostrophes.",
                                },
                              })}
                            />
                            <div className="form-text mt-2 text-danger">
                              {errors?.first_name?.message}
                            </div>
                          </div>
                          <div className="col-12 p-0">
                            <label for="lname" className="form-label">
                              Last name
                              <span className="text-danger fs-2 ps-1">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="lname"
                              placeholder="Last Name"
                              {...register("last_name", {
                                required: "Please enter last name",
                                value: location?.state?.last_name,
                                pattern: {
                                  value: /^[a-zA-ZÀ-ÖØ-öø-ÿ '-]+$/,
                                  message:
                                    "Last name can only contain letters, spaces, hyphens, and apostrophes.",
                                },
                              })}
                            />
                            <div className="form-text mt-2 text-danger">
                              {errors?.last_name?.message}
                            </div>
                          </div>
                          <div className="col-12 p-0">
                            <label htmlFor="e-mail" className="form-label">
                              Email Address
                              <span className="text-danger fs-2 ps-1">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="e-mail"
                              disabled={location?.state?.email}
                              placeholder="email@gmail.com"
                              {...register("email", {
                                required: "Please enter email",
                                value: location?.state?.email,
                                onChange: (e) => {
                                  // Convert the email to lowercase
                                  const lowercaseEmail =
                                    e.target.value.toLowerCase();
                                  e.target.value = lowercaseEmail;
                                  usernameChangeHandler(
                                    lowercaseEmail,
                                    "email"
                                  );
                                },
                              })}
                              readOnly={location?.state?.email}
                            />
                            <div className="form-text mt-2 text-danger">
                              {showExistValueStatus?.email === "Available" ? (
                                <span className="text-success">
                                  {showExistValueStatus?.email}
                                </span>
                              ) : showExistValueStatus?.email ===
                                "Already Taken" ? (
                                <span className="text-danger">
                                  {showExistValueStatus?.email}
                                </span>
                              ) : (
                                ""
                              )}
                              {errors?.email?.message}
                            </div>
                          </div>

                          {usertype === 3 ? (
                            <div className="col-12 p-0">
                              <label for="band" className="form-label">
                                Artist or band name ( If Different ){" "}
                                {/* <span className="text-danger fs-2 ps-1">*</span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="band"
                                placeholder="Artist or band name"
                                {...register("band_name")}
                              />
                            </div>
                          ) : (
                            <div className="col-12 p-0">
                              <label for="company" className="form-label">
                                Company or brand name{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="company"
                                placeholder="Company or brand name"
                                {...register("company_name", {
                                  required: "Please enter band name",
                                  value: location?.state?.company_name,
                                })}
                              />
                            </div>
                          )}
                          <div className="col-12 p-0">
                            <label htmlFor="contno" className="form-label">
                              Contact Number
                              <span className="text-danger fs-2 ps-1">*</span>
                            </label>
                            <div className="numfield-wrapper">
                              <select
                                className="form-select nopre-drop position-relative z-3"
                                {...register("country_code", {
                                  required: "Please enter country code",
                                  value: location?.state?.country_code,
                                })}
                              >
                                <option value="+1">US (+1)</option>
                                {CountryCode?.map((data, index) => (
                                  <option key={index} value={data?.dial_code}>
                                    {data?.code} ({data?.dial_code})
                                  </option>
                                ))}
                              </select>
                              <input
                                type="text"
                                className="form-control"
                                id="contno"
                                placeholder="Contact Number"
                                {...register("phone", {
                                  required: "Please enter Phone Number",
                                  value: location?.state?.phone,
                                  maxLength: {
                                    value: 10,
                                    message: "Phone number must be 10 digits",
                                  },
                                  pattern: {
                                    value: /^[0-9]{10}$/,
                                    message:
                                      "Phone number must contain exactly 10 digits",
                                  },
                                  onChange: (e) => {
                                    const phoneNumber = e.target.value;
                                    usernameChangeHandler(phoneNumber, "phone");
                                  },
                                })}
                              />
                            </div>
                            <div className="form-text mt-2 text-danger">
                              {showExistValueStatus?.phone === "Available" ? (
                                <span className="text-success">
                                  {showExistValueStatus?.phone}
                                </span>
                              ) : showExistValueStatus?.phone ===
                                "Already Taken" ? (
                                <span className="text-danger">
                                  {showExistValueStatus?.phone}
                                </span>
                              ) : (
                                ""
                              )}
                              {errors?.phone?.message}
                            </div>
                          </div>

                          <div className="col-12 p-0">
                            <label for="gender" className="form-label">
                              Gender
                              <span className="text-danger fs-2 ps-1">*</span>
                            </label>
                            <select
                              className="form-select"
                              id="gender"
                              {...register("gender", {
                                required: "Please select gender",
                                value: location?.state?.gender,
                              })}
                            >
                              <option disabled>
                                Please Select your gander
                              </option>
                              <option value="1">Female</option>
                              <option value="0">Male</option>
                              <option value="2">Other</option>
                            </select>
                            <div className="form-text mt-2 text-danger">
                              {errors?.gender?.message}
                            </div>
                          </div>
                          <div className="col-12 p-0">
                            <label for="gender" className="form-label">
                              Age Group
                              <span className="text-danger fs-2 ps-1">*</span>
                            </label>
                            <select
                              className="form-select"
                              id="age"
                              {...register("age", {
                                required: "Please select age",
                                value: location?.state?.age,
                              })}
                            >
                              <option disabled>Please Select your age</option>
                              <option value="0-18">Below 18</option>
                              <option value="18-24">18-24</option>
                              <option value="25-34">25-34</option>
                              <option value="25-34">35-44</option>
                              <option value="25-34">45-54</option>
                              <option value="55-100">55 and above</option>
                            </select>
                            <div className="form-text mt-2 text-danger">
                              {errors?.age?.message}
                            </div>
                          </div>

                          {location?.state?.email ? (
                            ""
                          ) : (
                            <div className="col-12 p-0">
                              <label for="e-mail" className="form-label">
                                Username
                                <span className="text-danger fs-2 ps-1">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="username"
                                placeholder="Username"
                                defaultValue="@"
                                {...register("username", {
                                  required: "Please enter Username",
                                  onChange: (e) => {
                                    usernameChangeHandler(
                                      e.target.value,
                                      "username"
                                    );
                                    const value = e.target.value;
                                    if (!value.startsWith("@")) {
                                      e.target.value =
                                        "@" + value.replace(/^@+/, "");
                                    }
                                  },
                                  minLength: {
                                    value: 3,
                                    message:
                                      "Username must be at least 3 characters long",
                                  },
                                  maxLength: {
                                    value: 20,
                                    message:
                                      "Username must not exceed 20 characters",
                                  },
                                  pattern: {
                                    value: /^[a-zA-Z0-9._@-]+$/,
                                    message:
                                      "Username can only contain letters, numbers, underscores, dots, and hyphens",
                                  },
                                })}
                              />
                              <div className="form-text mt-2 text-danger">
                                {showExistValueStatus?.username ==
                                  "Available" && !errors?.username ? (
                                  <span className="text-success">
                                    {showExistValueStatus?.username}
                                  </span>
                                ) : showExistValueStatus?.username ==
                                  "Already Taken" ? (
                                  <span className="text-danger">
                                    {showExistValueStatus?.username}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {errors?.username?.message}
                              </div>
                            </div>
                          )}

                          {!location?.state?.Profile && (
                            <>
                              <div class="col-12 p-0">
                                <label for="password" class="form-label">
                                  Password
                                  <span className="text-danger fs-2 ps-1">
                                    *
                                  </span>
                                </label>
                                <div className="d-flex align-items-center passfield-wrapper">
                                  <span className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="21"
                                      height="20"
                                      viewBox="0 0 21 20"
                                      fill="none"
                                    >
                                      <path
                                        d="M14.55 3.25L8.97495 8.8246C8.36983 8.64116 7.73016 8.60195 7.10717 8.71012C6.48417 8.81829 5.89516 9.07083 5.38729 9.44752C4.87943 9.82421 4.46682 10.3146 4.1825 10.8794C3.89819 11.4442 3.75007 12.0677 3.75 12.7C3.75 13.7741 4.1767 14.8043 4.93622 15.5638C5.69574 16.3233 6.72587 16.75 7.8 16.75C8.43233 16.7499 9.05585 16.6017 9.62062 16.3173C10.1854 16.033 10.6757 15.6203 11.0524 15.1124C11.429 14.6045 11.6815 14.0154 11.7896 13.3924C11.8977 12.7694 11.8585 12.1297 11.6749 11.5246L12.75 10.45V9.55H13.65L17.25 5.95V3.25H14.55ZM16.35 5.57695L13.2774 8.65H11.85V10.0769L11.0387 10.8878L10.6571 11.2694L10.8137 11.7856C10.9041 12.0835 10.95 12.3909 10.95 12.7C10.95 14.437 9.53655 15.85 7.8 15.85C6.06345 15.85 4.65 14.437 4.65 12.7C4.65 10.963 6.06345 9.55 7.8 9.55C8.1087 9.55 8.4165 9.5959 8.71395 9.6859L9.22965 9.8425L9.61125 9.4609L14.9226 4.15H16.35V5.57695Z"
                                        fill="#818898"
                                      />
                                      <path
                                        d="M6.9 14.5002C7.39706 14.5002 7.8 14.0973 7.8 13.6002C7.8 13.1031 7.39706 12.7002 6.9 12.7002C6.40294 12.7002 6 13.1031 6 13.6002C6 14.0973 6.40294 14.5002 6.9 14.5002Z"
                                        fill="#818898"
                                      />
                                      <path
                                        d="M12.5908 7.59111L15.2908 4.89111L15.609 5.20926L12.909 7.90926L12.5908 7.59111Z"
                                        fill="#818898"
                                      />
                                    </svg>
                                  </span>
                                  <input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    id="password"
                                    placeholder="Password"
                                    {...register("password", {
                                      required: "Please enter password",
                                      pattern: {
                                        value:
                                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?=.{8,})/,
                                        message:
                                          "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one special character",
                                      },
                                    })}
                                  />
                                  <span className="visi">
                                    {showPassword ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="20"
                                        viewBox="0 0 21 20"
                                        fill="none"
                                        onClick={handleShowPassword}
                                      >
                                        <path
                                          d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                          stroke="#818898"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                          stroke="#818898"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="20"
                                        viewBox="0 0 21 20"
                                        fill="none"
                                        onClick={handleShowPassword}
                                      >
                                        <path
                                          d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                          stroke="#818898"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                          stroke="#818898"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M3 3L18 17"
                                          stroke="#818898"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    )}
                                  </span>
                                </div>
                                <div className="form-text mt-2 text-danger">
                                  {errors?.password?.message}
                                </div>
                              </div>

                              <div className="col-12 p-0">
                                <label for="cpassword" className="form-label">
                                  Confirm Password
                                  <span className="text-danger fs-2 ps-1">
                                    *
                                  </span>
                                </label>
                                <div className="d-flex align-items-center passfield-wrapper">
                                  <span className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="21"
                                      height="20"
                                      viewBox="0 0 21 20"
                                      fill="none"
                                    >
                                      <path
                                        d="M14.55 3.25L8.97495 8.8246C8.36983 8.64116 7.73016 8.60195 7.10717 8.71012C6.48417 8.81829 5.89516 9.07083 5.38729 9.44752C4.87943 9.82421 4.46682 10.3146 4.1825 10.8794C3.89819 11.4442 3.75007 12.0677 3.75 12.7C3.75 13.7741 4.1767 14.8043 4.93622 15.5638C5.69574 16.3233 6.72587 16.75 7.8 16.75C8.43233 16.7499 9.05585 16.6017 9.62062 16.3173C10.1854 16.033 10.6757 15.6203 11.0524 15.1124C11.429 14.6045 11.6815 14.0154 11.7896 13.3924C11.8977 12.7694 11.8585 12.1297 11.6749 11.5246L12.75 10.45V9.55H13.65L17.25 5.95V3.25H14.55ZM16.35 5.57695L13.2774 8.65H11.85V10.0769L11.0387 10.8878L10.6571 11.2694L10.8137 11.7856C10.9041 12.0835 10.95 12.3909 10.95 12.7C10.95 14.437 9.53655 15.85 7.8 15.85C6.06345 15.85 4.65 14.437 4.65 12.7C4.65 10.963 6.06345 9.55 7.8 9.55C8.1087 9.55 8.4165 9.5959 8.71395 9.6859L9.22965 9.8425L9.61125 9.4609L14.9226 4.15H16.35V5.57695Z"
                                        fill="#818898"
                                      />
                                      <path
                                        d="M6.9 14.5002C7.39706 14.5002 7.8 14.0973 7.8 13.6002C7.8 13.1031 7.39706 12.7002 6.9 12.7002C6.40294 12.7002 6 13.1031 6 13.6002C6 14.0973 6.40294 14.5002 6.9 14.5002Z"
                                        fill="#818898"
                                      />
                                      <path
                                        d="M12.5908 7.59111L15.2908 4.89111L15.609 5.20926L12.909 7.90926L12.5908 7.59111Z"
                                        fill="#818898"
                                      />
                                    </svg>
                                  </span>
                                  <input
                                    type={
                                      showConfirmPassword ? "text" : "password"
                                    }
                                    className="form-control"
                                    id="cpassword"
                                    placeholder="Password"
                                    {...register("cpassword", {
                                      required: "Please confirm your password",
                                      validate: (value) =>
                                        value === password ||
                                        "Passwords do not match",
                                    })}
                                  />
                                  <span className="visi">
                                    {showConfirmPassword ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="20"
                                        viewBox="0 0 21 20"
                                        fill="none"
                                        onClick={handleShowConfirmPassword}
                                      >
                                        <path
                                          d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                          stroke="#818898"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                          stroke="#818898"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="20"
                                        viewBox="0 0 21 20"
                                        fill="none"
                                        onClick={handleShowConfirmPassword}
                                      >
                                        <path
                                          d="M2.25 10C2.25 10 5.25 4 10.5 4C15.75 4 18.75 10 18.75 10C18.75 10 15.75 16 10.5 16C5.25 16 2.25 10 2.25 10Z"
                                          stroke="#818898"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M10.5 12.25C11.7426 12.25 12.75 11.2426 12.75 10C12.75 8.75736 11.7426 7.75 10.5 7.75C9.25736 7.75 8.25 8.75736 8.25 10C8.25 11.2426 9.25736 12.25 10.5 12.25Z"
                                          stroke="#818898"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M3 3L18 17"
                                          stroke="#818898"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    )}
                                  </span>
                                </div>
                                <div className="form-text mt-2 text-danger">
                                  {errors?.cpassword?.message}
                                </div>
                              </div>
                            </>
                          )}

                          <div className="col-12 p-0">
                            <label for="location" className="form-label">
                              Location
                              <span className="text-danger fs-2 ps-1">*</span>
                            </label>
                            <div className="d-flex align-items-center passfield-wrapper loctn">
                              {/* <input
                                type="text"
                                className="form-control"
                                id="location"
                                placeholder="Location"
                                {...register("location", {
                                  required: "Please enter location",
                                  value: location?.state?.location,
                                })}
                              /> */}
                              <GooglePlaceAutocomplete
                                selectedPlace={selectedPlace}
                                setSelectedPlace={setSelectedPlace}
                                defaultValues={
                                  (location?.state?.Profile?.location &&
                                    JSON.parse(
                                      location?.state?.Profile?.location
                                    )?.address) ||
                                  location?.state?.back?.location?.address
                                }
                              />
                              <span className="visi">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="18"
                                  viewBox="0 0 15 18"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M1.20801 7.81306C1.21954 4.41878 3.98049 1.67653 7.37477 1.68802C10.769 1.69959 13.5113 4.46055 13.4998 7.85482V7.92442C13.458 10.1308 12.226 12.1702 10.7157 13.7641C9.8519 14.661 8.8873 15.4551 7.8411 16.1305C7.56136 16.3725 7.14642 16.3725 6.86667 16.1305C5.30704 15.1154 3.93821 13.8337 2.82278 12.3442C1.82862 11.0452 1.26417 9.46869 1.20801 7.83394V7.81306Z"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M7.35267 10.2686C8.8842 10.2686 10.1257 9.02702 10.1257 7.49549C10.1257 5.96396 8.8842 4.72241 7.35267 4.72241C5.82114 4.72241 4.57959 5.96396 4.57959 7.49549C4.57959 9.02702 5.82114 10.2686 7.35267 10.2686Z"
                                    stroke="#818898"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </div>
                            {locationError && (
                              <div className="form-text mt-2 text-danger">
                                {locationError}
                              </div>
                            )}
                          </div>
                          <div className="col-12 p-0">
                            <label htmlFor="about" className="form-label">
                              About yourself
                              <span className="text-danger fs-2 ps-1">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              id="about"
                              placeholder="Enter about you.."
                              {...register("about", {
                                maxLength: {
                                  value: maxCharacters,
                                  message:
                                    "About must not exceed 500 characters",
                                },
                              })}
                              value={aboutText}
                              onChange={handleInputChange}
                            />
                            <div className="form-text mt-2 text-danger">
                              {errors?.about?.message}
                            </div>
                            <div className="form-text mt-2">
                              {aboutText?.length}/{maxCharacters} characters
                            </div>
                          </div>
                          <div className="col-12 px-4">
                            <button className="btn btn-primary w-100">
                              Continue
                            </button>
                          </div>
                          {/* <!-- <div className="col-12 px-4">
                        <button className="btn btn-light w-100">
                          Back
                        </button>
                      </div> --> */}
                          <div className="col-12 p-0">
                            <p className="pripoly text-center">
                              By signing up, you are creating a Jubal Talent
                              account and agree to Jubal Talent
                              <Link to={RoutesLink?.terms_conditions}>
                                Terms
                              </Link>
                              and
                              <Link to={RoutesLink?.privacy_policy}>
                                Privacy Policy
                              </Link>
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TalentSignUp1;
