import React from "react";
import { Link } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";

function ProfileInstrumentCard({ item, deleteInstument }) {
  return (
    <div className="col-12 px-0">
      <div className=" vocals-row">
        <img src={item?.instrument_image?.[0]} alt="" className="cover-img" />
        <div className="d-flex  gap-1 align-items-md-center justify-content-between w-100">
          <Link
            to={RoutesLink?.InstrumentsDetails}
            state={{
              instrumentdata: item.id,
            }}
            className="d-flex flex-column gap-1 gap-md-2 gap-lg-3"
          >
            <h3>{item?.instrument_name}</h3>
            <span>{item?.primaryCategory}</span>
            <h4>$ {item?.instrument_price}</h4>
          </Link>
          <div className="d-flex align-items-start align-items-sm-center justify-content-end gap-3">
            {/* <div className="d-flex flex-column align-items-md-end"> */}
              <div className="dropdown dropdown-menu-end">
                <a
                  href="#"
                  className="action-btn"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={4}
                    height={13}
                    viewBox="0 0 4 13"
                    fill="none"
                  >
                    <path
                      d="M1.25 1.49023C1.25 1.90445 1.58579 2.24023 2 2.24023C2.41421 2.24023 2.75 1.90445 2.75 1.49023C2.75 1.07602 2.41421 0.740234 2 0.740234C1.58579 0.740234 1.25 1.07602 1.25 1.49023Z"
                      stroke="#86889A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.25 6.39063C1.25 6.80484 1.58579 7.14063 2 7.14063C2.41421 7.14063 2.75 6.80484 2.75 6.39063C2.75 5.97641 2.41421 5.64062 2 5.64062C1.58579 5.64062 1.25 5.97641 1.25 6.39063Z"
                      stroke="#86889A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.25 11.291C1.25 11.7052 1.58579 12.041 2 12.041C2.41421 12.041 2.75 11.7052 2.75 11.291C2.75 10.8768 2.41421 10.541 2 10.541C1.58579 10.541 1.25 10.8768 1.25 11.291Z"
                      stroke="#86889A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item"
                      to={RoutesLink?.AddInstrument}
                      state={{
                        instrumentdata: item,
                      }}
                    >
                      Edit Equipments
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => deleteInstument(item)}
                    >
                      Delete Equipments
                    </a>
                  </li>
                </ul>
              </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileInstrumentCard;
