import { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../Components/Layout/DashboardLayout";
import DashboardSidebar from "../../Components/Layout/DashboardSidebar";
import MobileBottomNav from "../../Components/Layout/MobileBottomNav";
import MobileHeader from "../../Components/Layout/MobileHeader";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import ReactOwlCarousel from "react-owl-carousel";
import { Link, useLocation } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import { useDispatch, useSelector } from "react-redux";
import { SaveInstrument_Likes } from "../../store/actions/UserActions";
import VendorLoader from "../../Helper/VendorLoader";
import FilterDropdown from "../../Components/Modals/FilterDropdown";
import { SvgIcons } from "../../Components/Svg/SvgIcons";

function Instruments() {
  // State variables
  const [instruments, setInstruments] = useState([]);
  const allInstruments = instruments;
  const [loading, setLoading] = useState(true);
  const [createPostModal, setCreatePostModal] = useState(false);
  const postToggle = () => setCreatePostModal(!createPostModal);
  const [filter, setFilter] = useState("Sort by");
  const sortBy = {};
  const [searchTerm, setSearchTerm] = useState(null);
  const [sortType, setSortType] = useState("");
  const [sortField, setSortField] = useState("");
  const AllReduxEvent = useSelector((state) => state?.user?.SaveEvent_Likes);
  const [AllReduxInstrument, setAllReduxInstrument] = useState([]);
  const [activeTabs, setActiveTabs] = useState([]);
  const [filterdata, setFilterdata] = useState({});
  // const AllReduxInstrument = useSelector(
  //   (state) => state?.user?.SaveInstrument_Likes
  // );
  const [filterState, setFilterState] = useState({
    primaryCategory: "",
    brand: "",
    city: "",
    nearMe: "",
    condition: "",
  });

  const instrument_condition = [
    { id: 1, value: "new", label: "New" },
    { id: 2, value: "like_new", label: "Like New" },
    { id: 3, value: "slightly_used", label: "Slightly Used" },
    { id: 4, value: "used", label: "Used" },
    { id: 5, value: "need_repair", label: "Need Repair" },
  ];

  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [nearbyInstruments, setNearbyInstruments] = useState([]);
  const location = useLocation();
  // Redux hooks
  const dispatch = useDispatch();

  const handleChange = async (event) => {
    await setSearchTerm(event.target.value);
  };

  const filterHandler = (value) => {
    console.log("filter value", value);
    let url;
    let search;
    if (value?.brand) {
      url = `?filter=genre`;
      search = `&search=${value?.genre}`;
      // url.push(`genre=${value.genre}`);
    }
    if (value?.genre) {
      // url.push(`instrument=${value?.instrument}`);
      url = `?filter=instrument`;
      search = `&search=${value?.hasInst}`;
    }
    if (value?.city) {
    }
    return value;
  };

  const fetchInstruments = async (filter) => {
    setLoading(true);
    if (filterState?.nearMe === true) {
      handleFetchRequest(
        `${ApiUrl.get_nearby}lat=28.1223&lng=73.23231&radius=300&search=`
      ).then((response) => {
        if (response.status === true) {
          setInstruments(response?.data?.instruments);
          setLoading(false);
        }
      });
    } else {
      const filteredObject = filterHandler(filter);
      console.log(filteredObject);

      let bodyData = {
        limit: 20,
        page: 1,
        search: searchTerm,
        // sortType: sortType,
        // sortField: sortField,
        inStock: true,
        where: {
          status: "published",
        },
      };

      if (filteredObject?.primaryCategory) {
        bodyData.where["primaryCategory"] = filteredObject.primaryCategory;
      }
      if (filteredObject?.instrumentName) {
        bodyData.where["instrument_name"] = filteredObject?.instrumentName;
      }
      if (filteredObject?.secondaryCategory) {
        bodyData.where["secondaryCategory"] = filteredObject.secondaryCategory;
      }
      if (filteredObject?.city) {
        bodyData.where["location"] = filteredObject.city;
      }
      if (filteredObject?.brand) {
        bodyData.where["brand"] = filteredObject.brand;
      }
      if (filteredObject?.condition) {
        bodyData.where["condition"] = filteredObject.condition;
      }

      if (sortType) {
        bodyData.sortType = sortType;
      }
      if (sortField) {
        bodyData.sortField = sortField;
      }
      // Object.keys(sortBy).forEach((key) => {
      //   bodyData[key] = sortBy[key];
      // });
      console.log(bodyData);

      const options = {
        method: "POST",
        data: bodyData,
      };

      const delayDebounceFn = setTimeout(() => {
      handleFetchRequest(ApiUrl.get_instruments, "POST", options?.data).then(
        (res) => {
          if (res.status === true) {
            setLoading(false);
            setInstruments(res?.data?.instrument);
          } else {
            setLoading(false);
          }
        }
      );
    }, 300); // 300 ms debounce delay
  
    return () => clearTimeout(delayDebounceFn);
    }
  };

  useEffect(() => {
    fetchInstruments(filterState);
  }, [filterState, searchTerm, filter, sortType, sortField]);

  const fetchReduxInstruments = async () => {
    let bodyData = {
      limit: 20,
      page: 1,
      where: {
        status: "published",
      },
    };

    handleFetchRequest(ApiUrl.get_instruments, "POST", bodyData).then((res) => {
      if (res.status === true) {
        setAllReduxInstrument(res?.data?.instrument);
      }
    });
  };

  const setSortBy = async (key, value, sortType) => {
    setFilter(sortType);
    for (let prop in sortBy) {
      if (sortBy.hasOwnProperty(prop)) {
        delete sortBy[prop];
      }
    }
    sortBy[key] = await value;
    console.log(sortBy);
    fetchInstruments();
  };

  const [categories, setCategories] = useState([]);
  const [secondaryCategories, setSecondaryCategories] = useState([]);
  const [instrumentNames, setInstrumentNames] = useState([]);
  console.log("caatafdff", categories);
  const [brands, setBrands] = useState([]);

  const fetchCategories = async () => {
    // const ApiUrl = `${Main_URL2}${API_URL.GET_PRIMARY_CATEGORIES}`;
    const options = {
      method: "GET",
    };
    handleFetchRequest(
      `${ApiUrl.instruement_categories}type=primary`,
      "GET",
      options?.data
    ).then((res) => {
      if (res.status === true) {
        setCategories(res?.data?.categories);
      }
    });
  };
  const fetchInstrumentNames = async () => {
    // const ApiUrl = `${Main_URL2}${API_URL.GET_PRIMARY_CATEGORIES}`;
    const options = {
      method: "GET",
    };
    handleFetchRequest(`${ApiUrl.instrument_name}`, "GET", options?.data).then(
      (res) => {
        if (res.status === true) {
          setInstrumentNames(res?.data?.instruments);
        }
      }
    );
  };

  const fetchSecondaryCategories = async () => {
    // const ApiUrl = `${Main_URL2}${API_URL.GET_PRIMARY_CATEGORIES}`;
    const options = {
      method: "GET",
    };
    handleFetchRequest(
      `${ApiUrl.instruement_categories}type=secondary`,
      "GET",
      options?.data
    ).then((res) => {
      if (res.status === true) {
        setSecondaryCategories(res?.data?.categories);
      }
    });
  };

  const fetchBrands = async () => {
    // const ApiUrl = `${Main_URL2}${API_URL.GET_BRANDS}`;
    const options = {
      method: "GET",
    };
    handleFetchRequest(ApiUrl.get_brands, "GET", options?.data).then((res) => {
      if (res.status === true) {
        setBrands(res?.data?.brands);
      }
    });
  };

  const DataMapHandler = (Arr, id, object, condition, objLabel, objCount) => {
    const newData = Arr?.map((item) => {
      if (item?.id === id) {
        return {
          ...item,
          [objLabel]: objCount,
          [object]: !condition,
        };
      }
      return item;
    });
    console.log(newData);
    return newData;
  };

  const GetHandleLike = (data, like) => {
    const updatedLikeCount = like
      ? Number(data?.likes) - 1
      : Number(data?.likes) + 1;

    const newData = DataMapHandler(
      instruments,
      data?.id,
      "isLikedByMe",
      like,
      "likes",
      updatedLikeCount
    );
    setInstruments(newData);
    dispatch(SaveInstrument_Likes(newData));
    handleFetchRequest(`${ApiUrl.get_instruments_like}${data?.id}`).then(
      (response) => {
        if (response.status !== true) {
          const newData = DataMapHandler(
            instruments,
            data?.id,
            "isLikedByMe",
            !like,
            "likes",
            like ? updatedLikeCount + 1 : updatedLikeCount - 1
          );
          setInstruments(newData);
          dispatch(SaveInstrument_Likes(newData));
        }
      }
    );
  };

  const savePost = (data, save) => {
    const newData = DataMapHandler(instruments, data?.id, "isSavedByMe", save);
    setInstruments(newData);
    dispatch(SaveInstrument_Likes(newData));
    handleFetchRequest(`${ApiUrl?.get_instruments_saved}${data.id}`).then(
      (response) => {
        if (response.status !== true) {
          const newData = DataMapHandler(
            instruments,
            data?.id,
            "isSavedByMe",
            save
          );
          setInstruments(newData);
          dispatch(SaveInstrument_Likes(newData));
        }
      }
    );
  };

  const dropdownRef = useRef(null);

  const handleOutside = () => {
    if (dropdownRef.current) {
      dropdownRef.current.classList.remove("active");
    }
  };

  const getNearbyInstruments = async () => {
    handleFetchRequest(
      `${ApiUrl.get_nearby}lat=28.1223&lng=73.23231&radius=300&search=`
    ).then((response) => {
      if (response.status === true) {
        setNearbyInstruments(response?.data?.instruments);
      }
    });
  };

  const removeFilterHandler = (tabId, keypair) => {
    console.log(keypair);
    setFilterdata((prev) => {
      const updatedFilter = { ...prev };
      delete updatedFilter[keypair];
      return updatedFilter;
    });

    setFilterState((prev) => {
      const updatedFilter = { ...prev };
      delete updatedFilter[keypair];
      return updatedFilter;
    });

    setActiveTabs((prev) => prev.filter((tab) => tab !== tabId));
  };

  const reset = () => {
    setFilterdata({});
    setActiveTabs([]);
    setFilterState({});
  };

  useEffect(() => {
    fetchCategories();
    fetchSecondaryCategories();
    fetchInstrumentNames();
    fetchBrands();
    getNearbyInstruments();
    fetchReduxInstruments();
  }, []);

  return (
    <>
      <MobileHeader isPage={"Instruements"} />
      <MobileBottomNav />
      <DashboardLayout>
        <DashboardSidebar postToggle={postToggle} />
        <div className="center-content">
          <div className="feed">
            <div className="filter-row position-relative mt-0">
              <div className="d-flex align-item-center gap-4 flex-wrap flex-lg-nowrap justify-content-between w-100">
                <div
                  className="input-group search-group"
                  onClick={handleOutside}
                >
                  {/* <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={18}
                      height={18}
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
                        fill="#818898"
                      />
                    </svg>
                  </span> */}
                  <input
                    type="text"
                    className="form-control"
                    id="searchbar"
                    placeholder="Search instruments"
                    onChange={handleChange}
                  />
                  <button
                    className="btn btn-secondary"
                    type="button"
                    id="button-addon2"
                  >
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={18}
                        height={18}
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
                          fill="#343B4E"
                        />
                      </svg>
                    </span>
                  </button>
                </div>

                <div className="inner-filter-row flex-sm-nowrap">
                  <div>
                    <FilterDropdown
                      category={categories}
                      instrumentNames={instrumentNames}
                      secondaryCategory={secondaryCategories}
                      brands={brands}
                      setFilterState={setFilterState}
                      dropdownRef={dropdownRef}
                      filter={filterdata}
                      setFilter={setFilterdata}
                      activeTabs={activeTabs}
                      setActiveTabs={setActiveTabs}
                      nearMe={true}
                      instrument_condition={instrument_condition}
                      removeHandler={removeFilterHandler}
                    />
                  </div>
                  {/* <div> */}
                  <div
                    className="dropdown dropdown-menu-end"
                    onClick={handleOutside}
                  >
                    <button
                      className="btn btn-light dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="15"
                          viewBox="0 0 19 15"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.0104 6.1634C17.713 6.4518 17.2382 6.4445 16.9499 6.1471L14.2383 3.3508V13.5C14.2383 13.9142 13.9025 14.25 13.4883 14.25C13.0741 14.25 12.7383 13.9142 12.7383 13.5V3.3508L10.0267 6.1471C9.7384 6.4445 9.26359 6.4518 8.96619 6.1634C8.66879 5.8751 8.6615 5.40025 8.9499 5.10289L12.9499 0.97789C13.0911 0.83222 13.2854 0.75 13.4883 0.75C13.6912 0.75 13.8855 0.83222 14.0267 0.97789L18.0267 5.10289C18.3151 5.40026 18.3078 5.8751 18.0104 6.1634Z"
                            fill="#AAAAAA"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.0104 8.8366C9.71298 8.5482 9.23818 8.5555 8.94988 8.8529L6.23828 11.6492V1.5C6.23828 1.08579 5.90249 0.75 5.48828 0.75C5.07407 0.75 4.73828 1.08579 4.73828 1.5V11.6492L2.02671 8.8529C1.73835 8.5555 1.26353 8.5482 0.966172 8.8366C0.668812 9.1249 0.661502 9.5997 0.949852 9.8971L4.94985 14.0221C5.09111 14.1678 5.28537 14.25 5.48828 14.25C5.69119 14.25 5.88545 14.1678 6.0267 14.0221L10.0267 9.8971C10.3151 9.5997 10.3078 9.1249 10.0104 8.8366Z"
                            fill="#111111"
                          />
                        </svg>
                      </span>
                      {" " + filter}
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          to="#"
                          className={
                            filter === "A to Z"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          // onClick={() => handleFilterChange("A to Z")}
                          onClick={
                            () => {
                              setFilter("A to Z");
                              setSortField("instrument_name");
                              setSortType("ASC");
                            }
                            // setSortBy("sortField", "createdAt", "A to Z")
                          }
                        >
                          A to Z
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            filter === "Newest"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to="#"
                          onClick={
                            () => {
                              setFilter("Newest");
                              setSortField("createdAt");
                              setSortType("DESC");
                            }
                            // setSortBy("sortType", "DESC", "Newest")
                          }
                        >
                          Newest
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            filter === "Oldest"
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to="#"
                          onClick={
                            () => {
                              setFilter("Oldest");
                              setSortField("createdAt");
                              setSortType("ASC");
                            }
                            // setSortBy("sortType", "ASC", "Oldest")
                          }
                        >
                          Oldest
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            filter === "Price high.."
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to="#"
                          onClick={
                            () => {
                              setFilter("Price high..");
                              setSortField("instrument_price");
                              setSortType("DESC");
                            }
                            // setSortBy("sortType", "DESC", "Price High to Low")
                          }
                        >
                          Price High to Low
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            filter === "Price low.."
                              ? "dropdown-item active"
                              : "dropdown-item"
                          }
                          to="#"
                          onClick={
                            () => {
                              setFilter("Price low..");
                              setSortField("instrument_price");
                              setSortType("ASC");
                            }
                            // setSortBy("sortType", "DESC", "Price Low to Hign")
                          }
                        >
                          Price Low to High
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {Object.entries(filterState).some(
              ([key, value]) =>
                value !== undefined &&
                value !== null &&
                value !== "" &&
                (typeof value === "string"
                  ? value.trim() !== ""
                  : value !== false)
            ) && (
              <div className="d-flex mt-2 mb-4 justify-content-between align-items-center result-desc">
                <div className="d-flex  align-items-center gap-3 flex-wrap">
                  <span className="fltr-rs">
                    Filter results:{" "}
                    <span className="num">{instruments?.length}</span>
                  </span>
                  {Object.entries(filterState).map(
                    ([key, value]) =>
                      value !== undefined &&
                      value !== null &&
                      value !== "" &&
                      (typeof value === "string"
                        ? value.trim() !== ""
                        : value !== false) && (
                        <span key={key} className="keyword">
                          {/* {key} */}
                          {key === "city"
                                  ? "City"
                                  : key === "category" || key === "primaryCategory"
                                  ? "Primary category"
                                  : key === "brand"
                                  ? "Brand"
                                  : key === "nearMe"
                                  ? "Nearme"
                                  : key === "condition"
                                  ? "Instrument condition"
                                  : key === "instrumentName"
                                  ? "Instrument Name"
                                  : key === "secondaryCategory"
                                  ? "Secondary Category"
                                  : key
                                }
                          <span
                          style={{cursor: "pointer"}}
                            onClick={() =>
                              removeFilterHandler(
                                key === "city"
                                  ? 3
                                  : key === "category"
                                  ? 7
                                  : key === "brand"
                                  ? 8
                                  : key === "nearMe"
                                  ? 11
                                  : key === "condition"
                                  ? 12
                                  : key === " instrumentName"
                                  ? 14
                                  : key === " secondaryCategory"
                                  ? 13
                                  : "",
                                key
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                            >
                              <path
                                d="M0.232911 0.223116C0.0898854 0.366021 0.0095379 0.559815 0.0095379 0.761882C0.0095379 0.963948 0.0898854 1.15774 0.232911 1.30065L2.9306 3.99524L0.232911 6.68983C0.160044 6.76012 0.101923 6.84421 0.0619392 6.93718C0.0219553 7.03015 0.000909077 7.13015 2.88057e-05 7.23133C-0.000851466 7.33252 0.0184516 7.43286 0.056812 7.52651C0.0951724 7.62017 0.151822 7.70525 0.223455 7.7768C0.295087 7.84835 0.380269 7.90494 0.474029 7.94325C0.56779 7.98157 0.668251 8.00085 0.769551 7.99997C0.870852 7.99909 0.970962 7.97807 1.06404 7.93813C1.15712 7.89819 1.24131 7.84014 1.31168 7.76736L4.00937 5.07277L6.70707 7.76736C6.85096 7.90617 7.04367 7.98298 7.24371 7.98124C7.44374 7.97951 7.63509 7.89936 7.77654 7.75807C7.918 7.61678 7.99823 7.42565 7.99997 7.22585C8.00171 7.02604 7.92481 6.83355 7.78584 6.68983L5.08815 3.99524L7.78584 1.30065C7.92481 1.15692 8.00171 0.96443 7.99997 0.764624C7.99823 0.564818 7.918 0.373688 7.77654 0.232399C7.63509 0.0911098 7.44374 0.0109662 7.24371 0.00922999C7.04367 0.00749373 6.85096 0.0843039 6.70707 0.223116L4.00937 2.91771L1.31168 0.223116C1.16861 0.0802551 0.974596 0 0.772297 0C0.569997 0 0.37598 0.0802551 0.232911 0.223116Z"
                                fill="#86889A"
                              ></path>
                            </svg>
                          </span>
                          {/* {SvgIcons?.crossIcon} */}
                        </span>
                      )
                  )}
                </div>
                <button onClick={reset} className="btn btn-link">
                  Clear Filters
                </button>
              </div>
            )}

            {loading ? (
              <VendorLoader />
            ) : (
              <div className="instruments-grid">
                {instruments && instruments.length === 0 ? (
                  <p>No instrument found</p>
                ) : (
                  instruments &&
                  instruments.map((data, index) => {
                    return (
                      <div className="card equipment-card" key={index}>
                        <div className="card-body">
                          <div className="row m-0">
                            <Link
                              to={RoutesLink?.InstrumentsDetails}
                              state={{
                                instrumentdata: data?.id,
                                AllInstruments: instruments,
                              }}
                              className="col-12 p-0"
                            >
                              <img
                                src={
                                  data?.instrument_image[0] ||
                                  `${process.env.PUBLIC_URL}/images/ins1.png`
                                }
                                alt=""
                                className=""
                              />
                            </Link>

                            <div className="col-12 p-0 ">
                              <div className="user-det d-flex gap-3 align-items-center">
                                {data?.User?.profilePhoto ? (
                                  <Link
                                    to={RoutesLink?.profile}
                                    state={{ id: data?.userId }}
                                    className="user-dp"
                                  >
                                    <img
                                      src={data?.User?.profilePhoto}
                                      alt=""
                                    />
                                  </Link>
                                ) : (
                                  <Link
                                    to={RoutesLink?.profile}
                                    state={{ id: data?.userId }}
                                    className="user-dp no-img"
                                  >
                                    {data?.User?.firstName?.[0]}
                                  </Link>
                                )}

                                <div className="user-ac">
                                  <div className="d-flex gap-2 align-items-center">
                                    <span className="username">
                                      <Link
                                        to={RoutesLink?.profile}
                                        state={{ id: data?.userId }}
                                      >
                                        {data?.User?.firstName}{" "}
                                        {data?.User?.lastName}
                                      </Link>
                                    </span>
                                    {(data?.User?.type === 3 ||
                                      data?.User?.type === 2) && (
                                      <span className="usertype mx-1">
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={13}
                                            height={13}
                                            viewBox="0 0 13 13"
                                            fill="none"
                                          >
                                            <circle
                                              cx="5.74979"
                                              cy="6.72714"
                                              r="3.75247"
                                              fill="white"
                                            />
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M12.479 6.02664L11.6695 5.02233C11.5421 4.85745 11.4597 4.66258 11.4372 4.44523L11.2948 3.1711C11.2659 2.91669 11.1516 2.67959 10.9705 2.49854C10.7895 2.31748 10.5524 2.20318 10.298 2.17429L9.02385 2.03189C8.79901 2.0094 8.60414 1.91197 8.43925 1.78456L7.43494 0.975113C7.02273 0.64534 6.43813 0.64534 6.02591 0.975113L5.0216 1.78456C4.85671 1.91197 4.66185 1.99441 4.4445 2.0169L3.17037 2.1593C2.64573 2.21926 2.23352 2.63148 2.17356 3.15612L2.03115 4.43024C2.00867 4.65509 1.91124 4.84995 1.78382 5.01484L0.974381 6.01915C0.644607 6.43137 0.644607 7.01596 0.974381 7.42818L1.78382 8.43249C1.91124 8.59738 1.99368 8.79224 2.01617 9.00959L2.15857 10.2837C2.21853 10.8084 2.63074 11.2206 3.15538 11.2805L4.42951 11.4229C4.65435 11.4454 4.84922 11.5429 5.01411 11.6703L6.01842 12.4797C6.43063 12.8095 7.01523 12.8095 7.42745 12.4797L8.43176 11.6703C8.59665 11.5429 8.79151 11.4604 9.00886 11.4379L10.283 11.2955C10.8076 11.2356 11.2198 10.8233 11.2798 10.2987L11.4222 9.02458C11.4447 8.79974 11.5421 8.60487 11.6695 8.43999L12.479 7.43568C12.8088 7.02346 12.8088 6.43886 12.479 6.02664ZM5.6062 9.7291L2.983 7.1059L4.10723 5.98167L5.6062 7.48065L9.35363 3.73322L10.4779 4.89492L5.6062 9.7291Z"
                                              fill="#F4336D"
                                            />
                                          </svg>
                                        </span>
                                        {data?.User?.type === 3
                                          ? "Talent"
                                          : "Vendor"}
                                      </span>
                                    )}
                                  </div>
                                  <p>
                                    {/* {
                                      JSON.parse(
                                        data?.User?.Vendor?.services
                                      )[0]
                                    } */}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="d-flex justify-content-between mb-2 mb-md-3 ">
                                <Link
                                  to={RoutesLink?.InstrumentsDetails}
                                  state={{
                                    instrumentdata: data?.id,
                                    AllInstruments: instruments,
                                  }}
                                  className="title"
                                >
                                  {data?.instrument_name}
                                </Link>
                                <h5>${data?.selling_price}</h5>
                              </div>
                              <p>{data?.brand}</p>
                            </div>
                            <div className="col-12 p-0">
                              <div className="user-activity d-flex justify-content-between">
                                <div className="d-flex">
                                  <span
                                    className="liked d-none d-lg-block"
                                    data-bs-toggle="modal"
                                    data-bs-target="#likes"
                                  >
                                    {data?.likes} likes
                                  </span>
                                  <span
                                    className="liked d-lg-none"
                                    type="button"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvaslikes"
                                    aria-controls="offcanvaslikes"
                                  >
                                    {data?.likes} likes
                                  </span>
                                </div>
                                <div className="d-flex user-activity">
                                  <span
                                    className={`like ${
                                      data?.isLikedByMe && "activated"
                                    }`}
                                    onClick={() =>
                                      GetHandleLike(data, data?.isLikedByMe)
                                    }
                                  >
                                    {SvgIcons?.likeIcon}
                                  </span>
                                  <span
                                    className={`save ${
                                      data?.isSavedByMe && "activated"
                                    }`}
                                    onClick={() =>
                                      savePost(data, data?.isSavedByMe)
                                    }
                                  >
                                    {SvgIcons?.saveIcon}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            )}
             {loading == false && instruments?.length < 1 && <span className="form-text text-center">No instrument found</span>}
          </div>

          <div className="side-content-right">
            {/* <div className="card featured-card event-card-side">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 card-head border-0">
                    <div className="d-flex justify-content-between">
                      <h5>Jubal Events</h5>
                      <Link to={RoutesLink.allEvents}>Show more</Link>
                    </div>
                  </div>
                  {AllReduxEvent &&
                    AllReduxEvent.slice(0, 3).map((res, index) => {
                      return (
                        <Link
                          to={RoutesLink?.EventDetails}
                          state={res}
                          className="col-12 px-0"
                          key={index}
                        >
                          <div className="event-card d-flex align-item-center">
                            <div
                              className="event-image"
                              style={{
                                width: "80px",
                                marginRight: 20,
                              }}
                            >
                              <img
                                src={res?.eventImage}
                                className="Event-image"
                                style={{ borderRadius: 5 }}
                                alt=""
                              />
                            </div>
                            <div className="event-info">
                              <span>{res?.eventType}</span>
                              <h6>{res?.eventName}</h6>
                              <p>{res?.eventTimeLocation?.location}</p>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                </div>
              </div>
            </div> */}

            <div className="card featured-card event-card-side">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 card-head border-0">
                    <div className="d-flex justify-content-between">
                      <h5>Near by Instrument</h5>
                      <Link to="/instruments">Show more</Link>
                    </div>
                  </div>
                  {nearbyInstruments?.map((instrument, index) => (
                    // nearbyInstruments?.instrument_image &&
                    <div className="col-12 px-0" key={index}>
                      <Link
                        to={RoutesLink?.InstrumentsDetails}
                        state={{
                          instrumentdata: instrument?.id,
                          AllInstruments: instruments,
                        }}
                        className="event-info"
                      >
                        <div className="d-flex align-items-center gap-2 gap-md-3">
                          <img src={instrument?.instrument_image[0]} alt="" />
                          <div>
                            <span>{instrument.instrument_name}</span>
                            <h6>{instrument.brand}</h6>
                            <p>${instrument?.selling_price}.00</p>
                            <p className="distance">
                              {instrument?.distance < 1000
                                ? `${instrument?.distance.toFixed(0)} m`
                                : `${(instrument?.distance / 1000).toFixed(
                                    2
                                  )} km`}{" "}
                              from you
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                  {nearbyInstruments?.length < 1 && (
                    <p className="form-text">No instrument found</p>
                  )}
                </div>
              </div>
            </div>

            <div className="card featured-card instrument-card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 card-head border-0">
                    <div className="d-flex justify-content-between">
                      <h5>Jubal Instruments</h5>
                      <Link to={RoutesLink?.Instruments}>Show more</Link>
                    </div>
                  </div>

                  <div className="col-12 p-0">
                    <div className="top-instruments">
                      <ReactOwlCarousel
                        items={1.3} // Number of items to display
                        autoplay={true} // Enable auto-play
                        autoplayTimeout={2000} // Delay between slides in milliseconds (2 seconds)
                        autoplaySpeed={1000} // Transition speed for sliding in milliseconds (1 second)
                        loop={true} // Enable infinite loop
                        smartSpeed={1000} // Speed of slide animation in milliseconds (1 second)
                        dots={false} // Hide dots/navigation
                        nav={false} // Hide next/prev buttons
                        animateOut="fadeOut" // Animation for slide-out (optional for smoothness)
                        animateIn="fadeIn" // Animation for slide-in (optional for smoothness)
                        mouseDrag={false} // Disable mouse dragging for smoother autoplay
                        touchDrag={false} // Disable touch dragging for smoother autoplay
                        slideTransition="ease-in-out" // CSS easing function for transition
                      >
                        {AllReduxInstrument &&
                          AllReduxInstrument?.map((data, index) => {
                            return (
                              <div className="top-items me-3" key={index}>
                                <div>
                                  <img
                                    src={
                                      data?.instrument_image[0] ||
                                      `${process.env.PUBLIC_URL}/images/ins1.png`
                                    }
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <div className="d-flex flex-row p-0 align-items-center justify-content-between gap-2">
                                    <Link
                                      to={RoutesLink?.InstrumentsDetails}
                                      state={{
                                        instrumentdata: data?.id,
                                        AllInstruments: instruments,
                                      }}
                                    >
                                      <h2>{data?.instrument_name}</h2>
                                    </Link>
                                    <p>${data?.instrument_price}</p>
                                  </div>
                                  <p>{data?.brand}</p>
                                </div>
                              </div>
                            );
                          })}
                      </ReactOwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default Instruments;
