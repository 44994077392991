import { Link } from "react-router-dom";
import { SvgIcons } from "../../Components/Svg/SvgIcons";
import { useEffect, useState } from "react";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { useSelector } from "react-redux";

export default function ReviewActivities() {
  const [allReviews, setAllReviews] = useState([]);
  const id = useSelector((store) => store?.user?.userDetails.id);
  console.log(id);

  const [sortOption, setSortOption] = useState("Newest");

  useEffect(() => {
    const data = {
      talentId: id,
    };
    handleFetchRequest(ApiUrl.get_review, "POST", data).then((response) => {
      if (response.status === true) {
        let likesData = response?.data?.review || [];
        // likesData = sortPosts(likesData, sortOption);
        setAllReviews(likesData);
        // setisLoading(false);
        console.log("ress....review", response.data?.review);

        // Sort the data based on the initial sorting option
      }
    });
  }, [sortOption, id]);

  const sortPosts = (posts, option) => {
    console.log("[osss]", posts, option);
    return posts.sort((a, b) => {
      const dateA = new Date(a?.Post?.createdAt);
      const dateB = new Date(b?.Post?.createdAt);

      if (option === "Newest") {
        return dateB - dateA;
      } else if (option === "Oldest") {
        return dateA - dateB;
      }
      return 0;
    });
  };

  const handleSortChange = (option) => {
    setSortOption(option);
  };

  return (
    <div
      className="tab-pane fade"
      id="review-tab-pane"
      role="tabpanel"
      aria-labelledby="review-tab"
      tabIndex={0}
    >
      <div className="filter-row">
        <div className="d-flex align-items-center justify-content-between">
          <p>Performance Reviews</p>
          <div className="inner-filter-row">
            {allReviews?.length > 0 && (
              <div>
                <div className="dropdown dropdown-menu-end">
                  <span className="selected"></span>
                  <button
                    className="btn btn-light dropdown-toggle d-flex align-items-center gap-3"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="12"
                        viewBox="0 0 21 12"
                        fill="none"
                      >
                        <path
                          d="M8.07872 11.8284H12.6918V9.86084H8.07872V11.8284ZM0.00585938 0.0231934V1.99072H20.7646V0.0231934H0.00585938ZM3.46566 6.90955H17.3048V4.94202H3.46566V6.90955Z"
                          fill="#818898"
                        ></path>
                      </svg>
                    </span>
                    Filter by
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" href="#">
                        Newest
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" href="#">
                        Oldest
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {/* <span className="selected-opt">
            Most
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M1.06994 0.969544C0.882466 1.15707 0.77715 1.41138 0.77715 1.67654C0.77715 1.94171 0.882466 2.19602 1.06994 2.38354L4.60594 5.91954L1.06994 9.45554C0.974427 9.54779 0.898244 9.65814 0.845835 9.78014C0.793426 9.90214 0.76584 10.0334 0.764686 10.1661C0.763532 10.2989 0.788834 10.4306 0.839115 10.5535C0.889396 10.6764 0.963649 10.788 1.05754 10.8819C1.15143 10.9758 1.26309 11.0501 1.38598 11.1004C1.50888 11.1506 1.64056 11.1759 1.77334 11.1748C1.90612 11.1736 2.03734 11.1461 2.15934 11.0936C2.28135 11.0412 2.39169 10.9651 2.48394 10.8695L6.01994 7.33354L9.55594 10.8695C9.74454 11.0517 9.99714 11.1525 10.2593 11.1502C10.5215 11.1479 10.7723 11.0428 10.9578 10.8574C11.1432 10.672 11.2483 10.4211 11.2506 10.1589C11.2529 9.89675 11.1521 9.64415 10.9699 9.45554L7.43394 5.91954L10.9699 2.38354C11.1521 2.19494 11.2529 1.94234 11.2506 1.68014C11.2483 1.41795 11.1432 1.16713 10.9578 0.981726C10.7723 0.796318 10.5215 0.691148 10.2593 0.68887C9.99714 0.686592 9.74454 0.787386 9.55594 0.969544L6.01994 4.50554L2.48394 0.969544C2.29641 0.782073 2.0421 0.676758 1.77694 0.676758C1.51177 0.676758 1.25746 0.782073 1.06994 0.969544Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </span> */}
          </div>
        </div>
        <div className="row shared-row">
          {allReviews?.map((review) => (
            <div key={review.id} className="col-12 p-0">
              <div className="col-12 p-0">
                <div className="comment-area">
                  <div className="user-det d-flex gap-3">
                    <div className="user-dp">
                      <img
                        src={
                          review?.addedBy === "vendor"
                            ? review?.vendor?.profilePhoto
                            : review?.talent?.profilePhoto
                        }
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column flex-grow-1 gap-2 gap-md-3">
                      <div className="d-flex justify-content-between w-100">
                        <div className="user-ac">
                          <div className="d-flex gap-1 gap-md-2 align-items-baseline flex-wrap">
                            <span className="username">
                              <Link to="#">
                                {review?.addedBy === "vendor"
                                  ? review?.vendor?.firstName
                                  : review?.talent?.firstName}
                              </Link>
                            </span>
                            <span className="post-time">
                              {/* {calculateTimeAgo(review?.createdAt)} */}
                            </span>
                            <span className="usertype mx-1">
                              <span>{SvgIcons?.talentIcon}</span>
                              {review?.addedBy === "vendor"
                                ? "Vendor"
                                : "Talent"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p>{review?.review}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {allReviews?.length < 1 && <p className="form-text">No data found</p>}
      </div>
    </div>
  );
}
