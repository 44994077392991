import React from "react";
import { Navigate } from "react-router-dom";
import { RoutesLink } from "../utility/RoutesLink";
import Cookies from "js-cookie"

const UnProtectedRoute = ({ children }) => {
  const isLoggedIn = Cookies.get("jubal_web_access"); // Check login state from localStorage

  if (!isLoggedIn) {
    // If not logged in, redirect to that page
    return children;
    
  }

  return <Navigate to={RoutesLink.dashboard} replace />; // Render the protected component if logged in
};

export default UnProtectedRoute;
