// Default export for the DashboardLayout component
export default function DashboardLayout({ children }) {
  return (
    <>
      {/* Main content section with padding and main layout styling */}
      <section className="px-0 main-content">
        <div className="max-width-container">
          {/* =========================== 
               Main feed grid Start 
               =========================== */}

          {/* Grid container for the main feed content, where children will be rendered */}
          <div className="main-feed-grid">{children}</div>
        </div>
      </section>
    </>
  );
}
