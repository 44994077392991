import React, { useEffect, useState } from "react";
// import HireingHeader from "../../HireTalent/HireingHeader";/
import { useForm } from "react-hook-form";
import Calendar from "react-calendar";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import GooglePlaceAutocomplete from "../../Components/GooglePlaceAutocomplete";
import { Modal, Spinner } from "reactstrap";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { ApiUrl } from "../../utility/ApiUrl";
import { toast } from "material-react-toastify";
// import EventCard from "../EventCard";
import { RoutesLink } from "../../utility/RoutesLink";
import HireingHeader from "./HireingHeader";
import { useSelector } from "react-redux";
import BacklineService from "../../Components/Modals/BacklineServices";

import ChooseEventModal from "../../Components/Modals/ChooseEventModal";

function ManageEvent() {
  const location = useLocation();
  const userDetails = useSelector((store) => store?.user?.userDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [startselectedHour, setStartSelectedHour] = useState(null);
  const [endselectedHour, setendSelectedHour] = useState(null);
  const [hireTalenetForm, sethireTalenetForm] = useState(false);
  const [EventType, setEventType] = useState([]);
  const [Entertainment, setEntertainment] = useState([]);
  const [userDetials, setUserDetials] = useState(null);
  const [MyEvents, setMyEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [Availibilty, SetAvailibilty] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [PerformanceType, setPerformanceType] = useState([]);
  const [selectedValue, setSelectedValue] = useState("1");
  const [selectedResion, setSelectedResion] = useState("");
  const [MoreInformation, setMoreInformation] = useState("");
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [calendarData, setCalendarData] = useState([]);
  const [dateStartTime, setDateStartTime] = useState(null);
  const [dateEndTime, setDateEndTime] = useState(null);
  const [backline_serviceData, setBacklineServiceData] = useState(null);
  const [soundCheck, setSoundCheck] = useState(false);
  const [backlineService, setBacklineService] = useState(false);
  const [ContinuebacklineService, setContinueBacklineService] = useState(false);
  const [chooseEventModal, setChooseEventModal] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [calendarStartTime, setCalendarStartTime] = useState("");
  const [calendarEndTime, setCalendarEndTime] = useState("");
  const [eventData, setEventData] = useState(null);
  const [evtLocation, setEvtLocation] = useState("");
  const [evtTitle, setEvtTitle] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState({
    image_url: "",
    image_view: "",
  });
  // Extracting necessary functions and variables from useForm hook
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const navigator = useNavigate();

  // Function to toggle the visibility of the event selection modal
  const toggleChooseEventModal = () => {
    setChooseEventModal(!chooseEventModal);
  };

  useEffect(() => {
    // Check if there is event data in the location state
    if (location?.state?.evendData) {
      setIsEdit(true); // Set edit mode if event data exists
    }

    // Set selected event ID based on the editing mode
    if (location?.state?.eventId) {
      setSelectedEventId(
        isEdit == true
          ? location?.state?.eventId?.eventId
          : location?.state?.eventId
      );
      setValue(
        "eventId",
        isEdit == true
          ? location?.state?.eventId?.eventId
          : location?.state?.eventId
      );

      // Fetch event details from the API based on the event ID
      handleFetchRequest(
        `${ApiUrl.get_single_event_detials}${
          location?.state?.evendData
            ? location?.state?.eventId?.eventId
            : location?.state?.eventId
        }`
      )
        .then((response) => {
          if (response.status) {
            // Update form fields with the fetched event details
            setValue("type", response?.data?.eventType);
            setValue("location", response?.data?.eventTimeLocation?.location);
            setEvtLocation(response?.data?.eventTimeLocation?.location);
            setEvtTitle(response?.data?.eventName);
            console.log(response);
          }
        })
        .catch((error) => {
          console.error("Error fetching event details:", error);
        });
    }

    // Populate form fields with event data from location state if it exists
    if (location?.state?.evendData) {
      setSelectedEventId(location?.state?.eventId?.eventId);
      setValue(
        "location",
        location?.state?.evendData?.Event?.eventTimeLocation?.location
      );
      setSelectedDate(location?.state?.evendData?.performanceDetail?.date);
      setStartSelectedHour(
        location?.state?.evendData?.performanceDetail?.startTime
      );
      setendSelectedHour(
        location?.state?.evendData?.performanceDetail?.endTime
      );
      sethireTalenetForm(true);
      setValue("performance", location?.state?.evendData?.performanceType);
      setValue("type", location?.state?.evendData?.Event?.eventType);
      setValue("organization", location?.state?.evendData?.contactOrganisation);
      setValue("budget", location?.state?.evendData?.budget);
      setValue("info", location?.state?.evendData?.additionalInfo);
      setValue("travel", location?.state?.evendData?.anyTravelRequired);
      setBacklineService(
        location?.state?.evendData?.isBacklineServiceAvailable
      );
      setValue("sound", location?.state?.evendData?.isSoundCheck);
      setSoundCheck(location?.state?.evendData?.isSoundCheck);

      // If a sound check time is provided, format it for the form field
      if (location?.state?.evendData?.soundCheckTime) {
        const { date, time } = location.state.evendData.soundCheckTime;

        // Convert date format to YYYY-MM-DD
        const [day, month, year] = date.split("-");
        const formattedDate = `${year}-${month}-${day}`;

        // Convert time format to 24-hour format
        const timeParts = time.split(" ");
        let [hours, minutes] = timeParts[0].split(":");
        const period = timeParts[1];

        if (period === "PM" && hours !== "12") {
          hours = String(Number(hours) + 12);
        } else if (period === "AM" && hours === "12") {
          hours = "00";
        }

        const formattedTime = `${hours}:${minutes}`;

        // Combine formatted date and time for the form
        const soundCheckDateTime = `${formattedDate}T${formattedTime}`;
        setValue("time", soundCheckDateTime);
      }
    }

    // Set form fields for the user's first and last name
    setValue("fname", userDetails?.Profile?.firstName);
    setValue("lname", userDetails?.Profile?.lastName);
  }, [location?.state]);

  // useEffect to update form values based on event data
  useEffect(() => {
    if (eventData !== null) {
      setValue("type", eventData?.eventType);
    }
  }, [eventData]);

  // Array of available hours for selection (10 AM to 11 PM)
  const hours = [];
  for (let i = 10; i <= 23; i++) {
    const hour = i % 24; // Adjust for 24-hour format
    const value = `${hour < 10 ? "0" + hour : hour}:00`;
    const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
    const period = hour >= 12 ? "PM" : "AM";
    const displayTime = `${displayHour}:00 ${period}`;

    hours.push({
      value: value,
      displayTime: displayTime,
    });
  }

  // Function to format timeslot for API submission by removing AM/PM
  function prepareTimeslotForAPI(timeslot) {
    // Remove AM/PM and any extra spaces from the string
    return timeslot.replace(/AM|PM|\s/g, "");
  }

  // Toggle the state of sound check
  const handleChecksoundTime = () => {
    setSoundCheck((pre) => !pre);
  };
  // Fetch calendar and backline service data when component mounts
  useEffect(() => {
    setUserDetials(location?.state?.id);
    handleFetchRequest(
      `${ApiUrl?.check_calender}?month=${currentMonth}&talentId=${location?.state?.id?.userId}`
    ).then((response) => {
      if (response.status === true) {
        SetAvailibilty(response?.data);
      }
    });

    handleFetchRequest(ApiUrl?.backline_service, "GET").then((response) => {
      if (response?.status === true) {
        setBacklineServiceData(response?.data);
      } else {
        alert("Data is Not Found!");
      }
    });

    handleFetchRequest(ApiUrl.event_type).then((res) => {
      if (res.status === true) {
        setEventType(res?.data);
      }
    });
    handleFetchRequest(ApiUrl.entertainment_types).then((res) => {
      if (res.status === true) {
        setEntertainment(res?.data);
      }
    });
    handleFetchRequest(ApiUrl.performance_types).then((res) => {
      if (res.status === true) {
        setPerformanceType(res?.data);
      }
    });

    // Fetch all events created by the user
    const data = {
      where: {
        createdById: userDetails?.id,
      },
      // limit: 10,
      // pageNumber: 1,
      sortField: "createdAt",
      sortType: "DESC",
    };

    handleFetchRequest(ApiUrl.get_event, "POST", data).then((res) => {
      if (res.status === true) {
        setAllEvents(res?.data?.event);
      }
    });
  }, []);

  // Handles the toggling of backline service selection
  const handleBacklineService = (e) => {
    const checked = e.target.checked; // Get the checkbox checked state
    setBacklineService(checked); // Update backline service state
    setContinueBacklineService(checked); // Also update the continue backline service state
  };

  // Determines whether to continue backline service based on existing data
  const handleContinueBacklineService = () => {
    const dataBackline = backline_serviceData?.find(
      (data) => data?.answer === true || false // Check if any data has an answer set to true
    );
    if (dataBackline) {
      setContinueBacklineService(false); // If data is found, set continue state to false
    }
  };
  // Closes the backline service options
  const handleBacklineServiceClose = () => {
    setContinueBacklineService(false);
    setBacklineService(false);
  };

  // Fetches the availability and calendar data for the given month and talent
  useEffect(() => {
    handleFetchRequest(
      `${ApiUrl?.check_calender}?month=${currentMonth}&talentId=${location?.state?.id?.userId}`
    ).then((response) => {
      if (response.status === true) {
        SetAvailibilty(response?.data); // Set the availability state
        setCalendarData(response.data); // Update calendar data state
        setMyEvents(response?.data); // Set my events state
      }
    });
  }, [currentMonth]); // Triggers whenever the currentMonth changes

  // Determines if a time slot is selected based on start and end hour
  const SelectedTime = (hour) => {
    if (!startselectedHour) return false; // Return false if no start hour is selected
    if (startselectedHour === hour || endselectedHour === hour) return true; // Check if hour matches start or end
    if (
      endselectedHour &&
      hours.indexOf(hour) > hours.indexOf(startselectedHour) &&
      hours.indexOf(hour) < hours.indexOf(endselectedHour)
    ) {
      return true; // If hour falls between start and end, return true
    }
    return false; // Otherwise, return false
  };

  // Handles hour selection and updates start or end hour accordingly
  const handleHourClickData = (hour) => {
    if (!startselectedHour || !endselectedHour) {
      // Reset selection if there's no start hour or both hours have been selected
      setStartSelectedHour(hour);
      setendSelectedHour(hour); // Clear end hour if new selection starts
    } else {
      // If start hour is selected, update the end hour
      if (hour < startselectedHour) {
        // Ensure the start hour is always the earlier one
        setStartSelectedHour(hour);
        setendSelectedHour(startselectedHour);
      } else {
        setendSelectedHour(hour);
      }
    }
  };

  // Handles the form toggle for hiring talent based on selected value
  const hireTalenetFormToggle = () => {
    if (selectedValue == 1) {
    }
    if (selectedValue == 2) {
      const getSelectedReson = (reson) => {
        if (reson == 1) {
          return "Family Function";
        }
        if (reson == 2) {
          return "Traveling";
        }
        if (reson == 3) {
          return "Other";
        }
      };
      const data = [
        {
          timeZone: "",
          date: moment(selectedDate).format("YYYY-MM-DD"),
          startTime: prepareTimeslotForAPI(startselectedHour),
          endTime: prepareTimeslotForAPI(endselectedHour),
          reason: getSelectedReson(selectedResion),
          additionalNote: MoreInformation,
        },
      ];
      // API call to create a calendar entry
      handleFetchRequest(ApiUrl?.create_calender, "POST", data).then((res) => {
        if (res.status === true) {
          toast.success(res?.message); // Display success toas
          navigator(RoutesLink?.dashboard); // Navigate to dashboard
        } else {
          toast.error(res?.message);
        }
      });
    }
    if (selectedValue == 3) {
      const getSelectedReson = (reson) => {
        if (reson == 1) {
          return "Family Function";
        }
        if (reson == 2) {
          return "Traveling";
        }
        if (reson == 3) {
          return "Other";
        }
      };
      const data = [
        {
          timeZone: "",
          date: moment(selectedDate).format("YYYY-MM-DD"),
          startTime: prepareTimeslotForAPI(hours[0]),
          endTime: prepareTimeslotForAPI(hours[hours.length - 1]),
          reason: getSelectedReson(selectedResion),
          additionalNote: MoreInformation,
        },
      ];
      handleFetchRequest(ApiUrl?.create_calender, "POST", data).then((res) => {
        if (res.status === true) {
          toast.success(res?.message);
          navigator(RoutesLink?.dashboard);
        } else {
          toast.error(res?.message);
        }
      });
    }
  };

  const handleSelectReason = (event) => {
    setSelectedResion(event.target.value);
  };
  const handleMoreInformation = (event) => {
    setMoreInformation(event.target.value);
  };

  // Handles the form submission for creating or editing booking
  const onSubmit = (data) => {
    let dateTime = data?.time;

    let momentDateTime = moment(dateTime);

    const formdata = {
      contactFullName: data?.fname + " " + data?.lname, // Concatenate first and last name
      contactOrganisation: data?.organization,
      eventId: data?.eventId,
      performanceType: data?.performance,
      budget: data?.budget,
      isBacklineServiceAvailable: data?.backline,
      isSoundCheck: data?.sound,
      anyTravelRequired: data?.travel,
      additionalInfo: data?.info,
      status: "0",
      talentId: location?.state?.id?.userId,
      backlineService: backline_serviceData,
      // location: selectedPlace,
      performanceDetail: {
        startTime: startselectedHour,
        endTime: endselectedHour,
        date: moment(selectedDate).format("YYYY-MM-DD"),
      },
    };
    if (data?.sound == true) {
      // If sound check is required, add sound check time
      formdata.soundCheckTime = {
        date: momentDateTime.format("YYYY-MM-DD"),
        time: momentDateTime.format("h:mm A"),
      };
    }
    if (isEdit == true) {
      // Edit booking case
      const edit = {
        contactFullName: data?.fname + " " + data?.lname,
        contactOrganisation: data?.organization,
        budget: data?.budget,
        isBacklineServiceAvailable: data?.backline,
        isSoundCheck: data?.sound,
        anyTravelRequired: data?.travel,
        additionalInfo: data?.info,
        // backlineService: backline_serviceData
      };
      handleFetchRequest(
        `${ApiUrl.edit_booking}${location?.state?.evendData?.id}`,
        "POST",
        edit
      ).then((res) => {
        if (res.status === true) {
          setIsLoading(false);
          // setUserDetials(res.data);
          // getLoginUserCaption(res?.data);
          console.log("Submit Data....", res?.data);
          toast.success(res?.message);
          navigate(RoutesLink?.ManageRequest, {
            state: res?.data,
          });
        } else {
          setIsLoading(false);
          toast.error(res?.message);
        }
      });
    } else {
      // New booking case, navigate to confirmation
      navigate(RoutesLink.ConfirmHiring, {
        state: {
          formdata: formdata,
          userdata: location.state?.id,
          evtLocation: evtLocation,
          evtTitle: evtTitle,
        },
      });
    }
  };

  // Handles toggling backline service
  const handlePreviousMonth = () => {
    if (currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  // Manages the continuation of backline services
  const getDayClass = (day) => {
    const date = `${currentYear}-${String(currentMonth).padStart(
      2,
      "0"
    )}-${String(day).padStart(2, "0")}`;
    const dayData = calendarData.find((item) => item.date === date);
    // console.log("daydata", dayData);
    if (dayData) {
      if (dayData.reason) {
        return "na";
      }
      if (dayData.talentEvent) {
        return "event";
      }
      if (dayData.isBooked) {
        return "booked";
      }
    }
    return "";
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    handleFetchRequest(
      `${ApiUrl?.check_calender}?date=${date}&talentId=${location?.state?.id?.userId}`
    ).then((response) => {
      if (response.status === true) {
        setCalendarStartTime(response?.data?.[0]?.startTime);
        setCalendarEndTime(response?.data?.[0]?.endTime);
        let earliestStartTime = null;
        let latestEndTime = null;
        response.data.forEach((item) => {
          const startTime = parseFloat(
            item.startTime.split(".")[0] + "." + item.startTime.split(".")[1]
          );
          const endTime = parseFloat(
            item.endTime.split(".")[0] + "." + item.endTime.split(".")[1]
          );
          if (!earliestStartTime || startTime < earliestStartTime) {
            earliestStartTime = startTime;
          }
          if (!latestEndTime || endTime > latestEndTime) {
            latestEndTime = endTime;
          }
        });
        setDateStartTime(earliestStartTime);
        setDateEndTime(latestEndTime);
      }
    });
  };
  const handleNextMonth = () => {
    if (currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const renderCalendarDays = () => {
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
    const firstDay = new Date(currentYear, currentMonth - 1, 1).getDay();
    const currentDate = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format
    const days = [];
    for (let i = 0; i < firstDay; i++) {
      days.push(<li key={`empty-${i}`} className="not-inc"></li>);
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const date = `${currentYear}-${String(currentMonth).padStart(
        2,
        "0"
      )}-${String(day).padStart(2, "0")}`;
      const isCurrentDate = date === currentDate; // Check if the date is the current date
      const isPastDate = date < currentDate;
      // console.log("dayyy==>>", days);

      days.push(
        <li
          key={day}
          className={`day ${getDayClass(day)} ${
            selectedDate === date ? "border border-danger" : ""
          } ${isCurrentDate ? "text-danger" : ""} ${
            isPastDate ? "disabled" : ""
          }`}
          style={{
            cursor: isPastDate ? "not-allowed" : "pointer",
            opacity: isPastDate ? 0.5 : 1,
            pointerEvents:
              isPastDate || location?.state?.evendData?.performanceDetail?.date
                ? "none"
                : "auto",
          }}
          onClick={() =>
            handleDateClick(
              `${currentYear}-${String(currentMonth).padStart(2, "0")}-${String(
                day
              ).padStart(2, "0")}`
            )
          }
        >
          {day}
        </li>
      );
    }
    return days;
  };

  const handleFocus = (e) => {
    e.target.showPicker();
  };

  //about section count limit
  const [aboutText, setAboutText] = useState("");
  const maxCharacters = 500;

  const handleInputChange = (e) => {
    if (e.target.value.length <= maxCharacters) {
      setAboutText(e.target.value);
    }
  };

  return (
    <>
      <section className="px-0 main-content pt-2">
        <div className="max-width-container">
          {/* =========================== */}
          {/* Main feed grid Start */}
          {/* =========================== */}
          <div className="row justify-content-between 5">
            <div className="col-12 p-0">
              <HireingHeader userData={location?.state?.id} />
            </div>
            <div className="col-12 p-0">
              <div className="user-availability">
                <p>Check {location?.state?.id?.firstName}'s availability</p>
                {/* <span>Let client know you available time to be hired</span> */}
              </div>
            </div>
            <div className="col-12 col-lg-6 p-0 pe-lg-3 pe-xl-4 mb-4 mb-lg-0">
              <div className="card calender-card">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <div className="col-12 p-0">
                      <div className="calender">
                        <div className="calender-header">
                          <button
                            className="btn btn-light"
                            onClick={handlePreviousMonth}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="15"
                              viewBox="0 0 10 15"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_2237_17146)">
                                <path
                                  d="M-0.294044 7.06881C-0.294044 6.82079 -0.173547 6.5728 0.0669622 6.3837L7.63894 0.433306C8.1206 0.0547835 8.90156 0.0547835 9.38304 0.433306C9.86451 0.811677 9.86451 1.42527 9.38304 1.80382L2.68294 7.06881L9.3828 12.3338C9.86428 12.7124 9.86428 13.3259 9.3828 13.7042C8.90132 14.0829 8.12038 14.0829 7.6387 13.7042L0.0667124 7.75391C-0.173821 7.56471 -0.294044 7.31674 -0.294044 7.0688V7.06881Z"
                                  fill="#343B4E"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2237_17146">
                                  <rect
                                    width="14"
                                    height="9.63593"
                                    fill="white"
                                    transform="matrix(0 1 -1 0 9.74414 0.149414)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                          <div>
                            <p>
                              {new Date(
                                currentYear,
                                currentMonth - 1
                              ).toLocaleString("default", { month: "long" })}
                            </p>
                            <span>{currentYear}</span>
                          </div>
                          <button
                            className="btn btn-light"
                            onClick={handleNextMonth}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="15"
                              viewBox="0 0 12 15"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_2237_17141)">
                                <path
                                  d="M11.9679 7.23002C11.9679 7.47804 11.8354 7.72603 11.5709 7.91512L3.24461 13.8655C2.71496 14.244 1.8562 14.244 1.32676 13.8655C0.79733 13.4872 0.79733 12.8736 1.32676 12.495L8.69432 7.23002L1.32702 1.965C0.797577 1.58647 0.797577 0.972949 1.32702 0.59461C1.85647 0.215904 2.71521 0.215904 3.24487 0.59461L11.5712 6.54492C11.8357 6.73411 11.9679 6.98209 11.9679 7.23003V7.23002Z"
                                  fill="#343B4E"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2237_17141">
                                  <rect
                                    width="14"
                                    height="10.8404"
                                    fill="white"
                                    transform="matrix(0 -1 1 0 0.929688 14.1494)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                        </div>
                        <div className="calender-body">
                          <ul className="days">
                            <li>Sun</li>
                            <li>Mon</li>
                            <li>Tue</li>
                            <li>Wed</li>
                            <li>Thu</li>
                            <li>Fri</li>
                            <li>Sat</li>
                          </ul>
                          <ul>{renderCalendarDays()}</ul>
                        </div>
                        <div className="calender-footer">
                          <div className="d-flex align-items-center gap-3">
                            <span className="booked"></span>
                            <p>Booked</p>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <span className="event"></span>
                            <p>Artist event</p>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <span className="na"></span>
                            <p>Not Available</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 p-0">
                      <div className="availability-wrapper">
                        {Availibilty?.length > 0 && (
                          <>
                            {Availibilty?.map(
                              (res, index) =>
                                res?.additionalNote &&
                                res?.reason && (
                                  <>
                                    <div
                                      className="available-info"
                                      key={index}
                                      // style={{ height: "200px", overflow: "auto" }}
                                    >
                                      <div className="d-flex align-items-center">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M4.89642 2.92838C3.75416 2.92838 2.82486 3.85769 2.82486 4.99994C2.82486 6.1422 3.75417 7.0715 4.89642 7.0715C6.03868 7.0715 6.96798 6.14219 6.96798 4.99994C6.96798 3.85768 6.03867 2.92838 4.89642 2.92838ZM4.89642 0.103516C7.60064 0.103516 9.79284 2.29573 9.79284 4.99994C9.79284 7.70416 7.60063 9.89636 4.89642 9.89636C2.19221 9.89636 0 7.70415 0 4.99994C0 2.29573 2.19221 0.103516 4.89642 0.103516Z"
                                            fill="#C7C8D0"
                                          />
                                        </svg>
                                        <p>{res?.date}</p>
                                      </div>
                                      <span>
                                        Not Available for: {res?.additionalNote}
                                        <br />
                                        <span className="time">
                                          {res?.startTime} to {res?.endTime}
                                        </span>
                                      </span>
                                    </div>
                                  </>
                                )
                            )}
                          </>
                        )}

                        {/* Display timeslot for "Create my Event" and "Not Available for Same hours" */}
                        {(selectedValue == 1 || selectedValue == 2) && (
                          <div className="available-time">
                            <p>Available hours</p>
                            <div className="time">
                              <ul>
                                {selectedDate &&
                                  hours.map((list, key) => {
                                    const isBetween =
                                      startselectedHour && endselectedHour
                                        ? list.value >= startselectedHour &&
                                          list.value <= endselectedHour
                                        : false;
                                    const isPastTime =
                                      list.value < new Date().getTime();

                                    return (
                                      <li
                                        key={key}
                                        onClick={() => {
                                          if (!isPastTime && isEdit === false) {
                                            handleHourClickData(list?.value);
                                          }
                                        }}
                                        className={
                                          isBetween
                                            ? "color"
                                            : isPastTime
                                            ? "na"
                                            : ""
                                        }
                                      >
                                        {list?.displayTime}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                        )}

                        {/* Display explanation and additional information for "Not Available for Same hours" or "Not Available for full day" */}
                        {(selectedValue == 2 || selectedValue == 3) && (
                          <div className="available-time">
                            <h6>Unavailable Explanation</h6>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={selectedResion}
                              onChange={handleSelectReason}
                            >
                              <option value={1}>Family Function</option>
                              <option value={2}>Traveling</option>
                              <option value={3}>Other</option>
                            </select>
                            <div className="col-12 p-0">
                              <h6 className="mt-3">Additional Information</h6>
                              <textarea
                                className="form-control"
                                id="info"
                                rows={3}
                                onChange={handleMoreInformation}
                                placeholder="Type here..."
                              />
                            </div>
                          </div>
                        )}

                        {/* Conditional rendering of the "Confirm" button */}
                        {hireTalenetForm === true ||
                        (selectedValue == 1 && startselectedHour == null) ||
                        (selectedValue == 1 && endselectedHour == null) ? (
                          ""
                        ) : (
                          <button
                            href="#"
                            className="btn btn-primary w-100 mt-2"
                            onClick={() => {
                              hireTalenetFormToggle();
                              sethireTalenetForm(true);
                            }}
                          >
                            Confirm
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {hireTalenetForm === false ? (
              <div className="col-12 col-lg-6 p-0 ps-lg-3 ps-xl-4">
                <div className="card event-card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 p-0">
                        <h5>{location?.state?.id?.firstName}'s Events</h5>
                      </div>

                      {MyEvents?.length > 0 &&
                      MyEvents?.some((event) => event.talentEvent) ? (
                        MyEvents?.map((event, index) => {
                          if (event.talentEvent) {
                            return (
                              <div className="col-12 p-0" key={index}>
                                <div className="event-desc">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center gap-1 gap-md-3">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={18}
                                        height={17}
                                        viewBox="0 0 18 17"
                                        fill="none"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M8.90938 4.88991C6.83103 4.88991 5.14015 6.49833 5.14015 8.4753C5.14015 10.4523 6.83105 12.0607 8.90938 12.0607C10.9877 12.0607 12.6786 10.4523 12.6786 8.4753C12.6786 6.49831 10.9877 4.88991 8.90938 4.88991ZM8.9091 0C13.8295 0 17.8182 3.79421 17.8182 8.47457C17.8182 13.155 13.8294 16.9491 8.9091 16.9491C3.98875 16.9491 0 13.1549 0 8.47457C0 3.79421 3.98875 0 8.9091 0Z"
                                          fill="#F4336C"
                                        />
                                      </svg>
                                      <span className="time">
                                        {
                                          event?.talentEvent?.eventTimeLocation
                                            ?.start_date
                                        }
                                      </span>
                                    </div>
                                    <span className="time">
                                      {
                                        event?.talentEvent?.eventTimeLocation
                                          ?.start_time
                                      }{" "}
                                      -{" "}
                                      {
                                        event?.talentEvent?.eventTimeLocation
                                          ?.end_time
                                      }
                                    </span>
                                  </div>
                                  <p>
                                    {event?.talentEvent?.eventType}:{" "}
                                    {event?.talentEvent?.eventName}
                                  </p>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p className="desc">
                                      {event?.talentEvent?.eventDescription}
                                    </p>
                                    {/* <a href="#">View event</a> */}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="no-event">
                          <h3>
                            No events for {location?.state?.id?.firstName}
                          </h3>
                          <p>
                            {location?.state?.id?.firstName}'s upcoming and
                            ongoing event will show here...
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-12 col-lg-6 p-0 ps-lg-3 ps-xl-4">
                <div className="card event-card">
                  <div className="card-body">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="hiring-form"
                    >
                      <div className="row justify-content-center">
                        <div className="col-12 p-0">
                          <label htmlFor="fname" className="form-label">
                            First Name
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="fname"
                            placeholder="First Name"
                            {...register("fname", {
                              required: "First Name is required",
                            })}
                          />
                          {errors.fname && (
                            <p className="error">{errors.fname.message}</p>
                          )}
                        </div>
                        <div className="col-12 p-0">
                          <label htmlFor="lname" className="form-label">
                            Last Name
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lname"
                            placeholder="Last Name"
                            {...register("lname", {
                              required: "Last Name is required",
                            })}
                          />
                          {errors.lname && (
                            <p className="error">{errors.lname.message}</p>
                          )}
                        </div>
                        <div className="col-12 p-0">
                          <label htmlFor="organization" className="form-label">
                            Contact Organization (if Applicable)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="organization"
                            placeholder="Organization"
                            {...register("organization")}
                          />
                          {errors.organization && (
                            <p className="error">
                              {errors.organization.message}
                            </p>
                          )}
                        </div>
                        <div className="col-12 p-0">
                          <label htmlFor="event" className="form-label">
                            Choose Event
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <select
                            id="eventId"
                            className="form-select"
                            aria-label="Default select example"
                            onClick={toggleChooseEventModal}
                            disabled={isEdit}
                            {...register("eventId", {
                              required: "Please select an event",
                            })}
                          >
                            {allEvents &&
                              allEvents
                                .filter((res) => res?.id === selectedEventId) // Filter to show only the selected event
                                .map((res) => (
                                  <option key={res?.id} value={res?.id}>
                                    {res?.eventName}
                                  </option>
                                ))}
                          </select>
                          {errors.event && (
                            <p className="error">{errors.event.message}</p>
                          )}
                        </div>
                        <div className="col-12 p-0">
                          <label htmlFor="type" className="form-label">
                            Type of the Event
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <select
                            className="form-select"
                            id="type"
                            {...register("type", {
                              required: "Event Type is required",
                            })}
                            disabled={isEdit}
                          >
                            <option value="">Select Event Type</option>
                            {EventType &&
                              EventType.map((res, index) => {
                                return (
                                  <option key={index} value={res?.type}>
                                    {res?.type}
                                  </option>
                                );
                              })}
                          </select>
                          {errors.type && (
                            <p className="error">{errors.type.message}</p>
                          )}
                        </div>
                        <div className="col-12 p-0">
                          <label htmlFor="performance" className="form-label">
                            Type of Performance Requested
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <select
                            className="form-select"
                            id="performance"
                            {...register("performance", {
                              required: "Performance Type is required",
                            })}
                            disabled={isEdit}
                            value={watch("performance") || ""}
                          >
                            <option value="">Select Performance Type</option>
                            {PerformanceType &&
                              PerformanceType.map((res, index) => {
                                return (
                                  <option key={index} value={res?.type}>
                                    {res?.type}
                                  </option>
                                );
                              })}
                          </select>
                          {errors.performance && (
                            <span className="form-text text-danger">
                              {errors.performance.message}
                            </span>
                          )}
                        </div>
                        <div className="col-12 p-0">
                          <label htmlFor="location" className="form-label">
                            Location
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <input
                            type="text"
                            value={eventData?.location}
                            disabled
                            className="form-control"
                            id="location"
                            {...register("location")}
                          />
                          {/* <GooglePlaceAutocomplete
                            selectedPlace={selectedPlace}
                            setSelectedPlace={setSelectedPlace}
                            defaultValues={eventData?.location}
                          /> */}
                          {console.log("eventData?.location", eventData)}
                          {errors.location && (
                            <p className="error">{errors.location.message}</p>
                          )}
                        </div>
                        <div className="col-12 p-0">
                          <label htmlFor="budget" className="form-label">
                            Budget
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="budget"
                            placeholder="$ 0"
                            {...register("budget", {
                              required: "Budget is required",
                              pattern: {
                                value: /^\d+$/,
                                message: "Budget should be a number",
                              },
                            })}
                          />
                          {errors.budget && (
                            <p className="error">{errors.budget.message}</p>
                          )}
                        </div>
                        <div className="col-12 p-0">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="backline"
                              checked={backlineService}
                              {...register("backline", {
                                required: "Please fill the Backline Service!",
                              })}
                              onChange={handleBacklineService}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="backline"
                            >
                              Backline services/equipment will be provided?
                            </label>
                            {errors.backline && (
                              <p className="error">{errors.backline.message}</p>
                            )}
                          </div>
                        </div>
                        <BacklineService
                          open={ContinuebacklineService}
                          close={handleBacklineServiceClose}
                          backline_serviceData={backline_serviceData}
                          setBacklineServiceData={setBacklineServiceData}
                          onCountinue={handleContinueBacklineService}
                        />
                        <div className="col-12 p-0">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="sound"
                              {...register("sound")}
                              onClick={handleChecksoundTime}
                            />
                            <label className="form-check-label" htmlFor="sound">
                              Sound Check
                            </label>
                          </div>
                        </div>
                        {soundCheck && (
                          <div className="col-12 p-0">
                            <label htmlFor="time" className="form-label">
                              Sound Check Time
                              <span className="text-danger fs-2 ps-1">*</span>
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              id="time"
                              onFocus={handleFocus}
                              placeholder="00:00 AM/PM EST – 00:00 AM/PM EST"
                              min={new Date().toISOString().slice(0, 16)} // set the minimum value to the current date and time
                              {...register("time", {
                                required: "Sound Check Time is required",
                              })}
                            />

                            {errors.time && (
                              <p className="error">{errors.time.message}</p>
                            )}
                          </div>
                        )}
                        <div className="col-12 p-0">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="travel"
                              {...register("travel")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="travel"
                            >
                              Any Travel Required
                            </label>
                          </div>
                        </div>
                        {/* <div className="col-12 p-0">
                          <label htmlFor="info" className="form-label">
                            Additional Information
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            id="info"
                            rows={3}
                            placeholder="Type here..."
                            {...register("info", {
                              required: "Additional information is required",
                            })}
                          />
                          {errors.info && (
                            <p className="error">{errors.info.message}</p>
                          )}
                        </div> */}

                        <div className="col-12 p-0">
                          <label htmlFor="info" className="form-label">
                            Additional Information
                            <span className="text-danger fs-2 ps-1">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            id="info"
                            rows={3}
                            placeholder="Type here..."
                            {...register("info", {
                              maxLength: {
                                value: maxCharacters,
                                message: "About must not exceed 500 characters",
                              },
                            })}
                            value={aboutText}
                            onChange={handleInputChange}
                          />
                          <div className="form-text mt-2 text-danger">
                            {errors?.info?.message}
                          </div>
                          <div className="form-text mt-2">
                            {aboutText.length}/{maxCharacters} characters
                          </div>
                        </div>
                        <div className="col-6 p-0">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            {isLoading ? <Spinner /> : "Confirm"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* =========================== */}
          {/* Main feed grid End */}
          {/* =========================== */}
        </div>
      </section>
      {chooseEventModal && (
        <Modal
          isOpen={true}
          toggle={toggleChooseEventModal}
          className="modal-dialog modal-dialog-centered create-post-dialog modal-dialog-scrollable modal-fullscreen-sm-down"
        >
          <ChooseEventModal
            allEvents={allEvents}
            setSelectedEventId={setSelectedEventId}
            selectedEventId={selectedEventId}
            setEventData={setEventData}
            toggleChooseEventModal={toggleChooseEventModal}
            setEvtLocation={setEvtLocation}
            setEvtTitle={setEvtTitle}
          />
        </Modal>
      )}
    </>
  );
}

export default ManageEvent;
