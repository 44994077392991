import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ChooseEventModal({
  allEvents,
  setSelectedEventId,
  selectedEventId,
  setEventData,
  toggleChooseEventModal,
  setEvtLocation,
  setEvtTitle,
}) {
  const [filter, setFilter] = useState("All"); // Filter state
  const [searchTerm, setSearchTerm] = useState("");

  // Function to filter events based on the selected filter
  const filteredEvents = allEvents?.filter((event) => {
    switch (filter) {
      case "Published":
        return event.eventStatus === 3;
      case "Draft":
        return event.eventStatus === 0;
      case "Closed":
        return event.eventStatus === 1;
      default:
        return true; // Show all events
    }
  });

  return (
    <div className="modal-content">
      <div className="modal-header border-0">
        <div className="d-flex align-items-center gap-3 mb-3 w-100">
          <input
            type="text"
            className="form-control"
            placeholder="Search Event..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="dropdown">
            <button
              className="btn btn-light dropdown-toggle d-flex align-items-center gap-3"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={21}
                  height={12}
                  viewBox="0 0 21 12"
                  fill="none"
                >
                  <path
                    d="M8.07872 11.8284H12.6918V9.86084H8.07872V11.8284ZM0.00585938 0.0231934V1.99072H20.7646V0.0231934H0.00585938ZM3.46566 6.90955H17.3048V4.94202H3.46566V6.90955Z"
                    fill="#818898"
                  />
                </svg>
              </span>
              {filter}
            </button>
            <ul className="dropdown-menu">
              <li>
                <Link
                  className="dropdown-item"
                  onClick={() => setFilter("All")}
                >
                  All
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item"
                  onClick={() => setFilter("Published")}
                >
                  Published
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  onClick={() => setFilter("Draft")}
                >
                  Draft
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  onClick={() => setFilter("Closed")}
                >
                  Closed
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="modal-body ">
        <div
          className="row event-wrapper event-wrapper2 border-0 p-0 mt-4"
          style={{ minHeight: "200px" }}
        >
          {allEvents && allEvents?.length === 0 ? (
            <p>No any event</p>
          ) : (
            filteredEvents
              ?.filter(
                (item) =>
                  item?.eventName
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  item?.eventType
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase())
              )
              .map((item, index) => {
                return (
                  <div className="col-12 px-0" key={index}>
                    {console.log("first ======????", item?.eventTimeLocation)}
                    <div className="event-row">
                      <div className="d-flex gap-4">
                        <div className="event-cover">
                          <img src={item?.eventImage} alt="" />
                        </div>
                        <div className="d-flex flex-column ">
                          <div className="d-flex align-items-center gap-3">
                            {/* {item?.visibilityType === "Public" ? (
                              <h5 className="text-md-end free">
                                {item?.visibilityType}
                              </h5>
                            ) : (
                              <span className="event-type private-event">
                                {item?.visibilityType}
                              </span>
                            )} */}
                            <span
                              className={
                                item?.eventStatus == 3
                                  ? "event-type public-event"
                                  : "event-type private-event"
                              }
                            >
                              {item?.eventStatus == 3
                                ? "Published"
                                : item?.eventStatus == 0
                                ? "Draft"
                                : item?.eventStatus == 1
                                ? "Closed"
                                : ""}
                            </span>
                          </div>
                          <p>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={13}
                                height={13}
                                viewBox="0 0 13 13"
                                fill="none"
                              >
                                <path
                                  d="M10.6441 1.49053H9.39185V0.864404C9.39185 0.698346 9.32588 0.539089 9.20846 0.421668C9.09104 0.304248 8.93178 0.238281 8.76572 0.238281C8.59966 0.238281 8.44041 0.304248 8.32299 0.421668C8.20557 0.539089 8.1396 0.698346 8.1396 0.864404V1.49053H4.38286V0.864404C4.38286 0.698346 4.3169 0.539089 4.19947 0.421668C4.08205 0.304248 3.9228 0.238281 3.75674 0.238281C3.59068 0.238281 3.43142 0.304248 3.314 0.421668C3.19658 0.539089 3.13062 0.698346 3.13062 0.864404V1.49053H1.87837C1.38019 1.49053 0.902424 1.68843 0.550162 2.04069C0.197899 2.39295 0 2.87072 0 3.3689V10.8824C0 11.3805 0.197899 11.8583 0.550162 12.2106C0.902424 12.5628 1.38019 12.7607 1.87837 12.7607H10.6441C11.1423 12.7607 11.62 12.5628 11.9723 12.2106C12.3246 11.8583 12.5225 11.3805 12.5225 10.8824V3.3689C12.5225 2.87072 12.3246 2.39295 11.9723 2.04069C11.62 1.68843 11.1423 1.49053 10.6441 1.49053ZM11.2702 10.8824C11.2702 11.0484 11.2042 11.2077 11.0868 11.3251C10.9694 11.4425 10.8102 11.5085 10.6441 11.5085H1.87837C1.71231 11.5085 1.55305 11.4425 1.43563 11.3251C1.31821 11.2077 1.25225 11.0484 1.25225 10.8824V6.49951H11.2702V10.8824ZM11.2702 5.24727H1.25225V3.3689C1.25225 3.20284 1.31821 3.04358 1.43563 2.92616C1.55305 2.80874 1.71231 2.74277 1.87837 2.74277H3.13062V3.3689C3.13062 3.53495 3.19658 3.69421 3.314 3.81163C3.43142 3.92905 3.59068 3.99502 3.75674 3.99502C3.9228 3.99502 4.08205 3.92905 4.19947 3.81163C4.3169 3.69421 4.38286 3.53495 4.38286 3.3689V2.74277H8.1396V3.3689C8.1396 3.53495 8.20557 3.69421 8.32299 3.81163C8.44041 3.92905 8.59966 3.99502 8.76572 3.99502C8.93178 3.99502 9.09104 3.92905 9.20846 3.81163C9.32588 3.69421 9.39185 3.53495 9.39185 3.3689V2.74277H10.6441C10.8102 2.74277 10.9694 2.80874 11.0868 2.92616C11.2042 3.04358 11.2702 3.20284 11.2702 3.3689V5.24727Z"
                                  fill="#86889A"
                                />
                              </svg>
                            </span>
                            {item?.eventTimeLocation?.start_date},{" "}
                            {item?.eventTimeLocation?.start_time} -{" "}
                            {item?.eventTimeLocation?.end_date},{" "}
                            {item?.eventTimeLocation?.end_time}
                            {/* Nov 12, 10PM - Nov 15, 6PM */}
                          </p>
                          <div className="title">
                            {" "}
                            {item?.eventType}: {item?.eventName}
                          </div>
                          <p>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={13}
                                height={15}
                                viewBox="0 0 13 15"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M1 6.94651C1.01023 3.93422 3.46047 1.50057 6.47276 1.51077C9.48505 1.52104 11.9187 3.97128 11.9085 6.98357V7.04534C11.8714 9.00342 10.7781 10.8133 9.43769 12.2278C8.67112 13.0238 7.81507 13.7285 6.88662 14.3279C6.63835 14.5427 6.27011 14.5427 6.02184 14.3279C4.63774 13.427 3.42295 12.2896 2.43305 10.9677C1.55077 9.81495 1.04984 8.41581 1 6.96504V6.94651Z"
                                  stroke="#ADADAD"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M6.45319 9.1261C7.81236 9.1261 8.91419 8.02427 8.91419 6.6651C8.91419 5.30593 7.81236 4.2041 6.45319 4.2041C5.09401 4.2041 3.99219 5.30593 3.99219 6.6651C3.99219 8.02427 5.09401 9.1261 6.45319 9.1261Z"
                                  stroke="#ADADAD"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </span>
                            {item?.eventTimeLocation?.location}{" "}
                            {/* {item?.Event?.eventTimeLocation?.state} */}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-md-end">
                        <div className="dropdown dropdown-menu-end">
                          <div className="form-check">
                            <input
                              type="radio"
                              name="evt"
                              id="evt"
                              className="form-check-input"
                              onClick={() => {
                                setSelectedEventId(item?.id);
                                setEventData({
                                  location: item?.eventTimeLocation?.location,
                                  eventType: item?.eventType,
                                });
                                setEvtLocation(
                                  item?.eventTimeLocation?.location
                                );
                                setEvtTitle(item?.eventName);
                                toggleChooseEventModal();
                              }}
                              checked={selectedEventId == item?.id}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </div>
    </div>
  );
}
