import { toPng } from "html-to-image";
import moment from "moment";
import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutesLink } from "../../utility/RoutesLink";
import generatePDF from "react-to-pdf";

export default function TicketBill() {
  const location = useLocation();
  const ticketRef = useRef(null);
  const navigate = useNavigate();
  const pdfRef = useRef(null);

  console.log(location?.state);
  const ticketDetail = location?.state?.ticket;
  const containerStyle = {
    maxWidth: "800px",
    margin: "auto",
    padding: "16px",
    border: "1px solid #eee",
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "'Inter', sans-serif",
    color: "#555",
    marginTop: "10px",
    marginBottom: "10px",
  };

  const tableStyle = {
    fontSize: "12px",
    lineHeight: "20px",
  };

  const flexStyle = {
    display: "flex",
    alignItems: "center",
  };

  const h1Style = {
    color: "#1a1c21",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    display: "inline-block",
    paddingLeft: "8px",
    verticalAlign: "baseline",
  };

  const innerTableStyle = {
    backgroundColor: "#fff",
    width: "100%",
    borderRadius: "12px",
    fontSize: "12px",
    lineHeight: "20px",
    tableLayout: "fixed",
  };

  const innerPaddingStyle = {
    padding: "20px 16px",
  };

  const commonCellStyle = {
    lineHeight: "normal",
    color: "#1a1c21",
    fontWeight: "700",
  };

  const invoiceStyle = {
    color: "#5e6470",
    lineHeight: "20px",
    margin: "0",
  };

  const borderTopStyle = {
    padding: "12px 0",
    borderTop: "1px solid #d7dae0",
  };

  const downloadTicketAsImage = () => {
    const node = ticketRef.current;

    toPng(node, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "ticket.png";
        link.click();
        navigate(RoutesLink?.dashboard);
      })
      .catch((err) => {
        console.error("Oops, something went wrong!", err);
      });
  };

  return (
    <>
      {/* <button
        onClick={() => {
          generatePDF(pdfRef, {
            filename: "bill.pdf",
          });
        }}
        className="btn btn-light position-absolute end-0"
      >
        Download
      </button> */}
      <div style={containerStyle}>
        <div ref={pdfRef}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <td
                  style={{ padding: "0 16px 18px 0px", lineHeight: "normal" }}
                >
                  <div style={flexStyle}>
                    <img
                      src="/images/logos/jubal_logo.png"
                      height="30px"
                      alt="Jubal Logo"
                    />
                    <h1 style={h1Style}>Jubal Talents</h1>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <table style={innerTableStyle}>
                    <tbody style={innerPaddingStyle}>
                      <tr>
                        <td
                          style={{
                            verticalAlign: "top",
                            width: "30%",
                            paddingRight: "20px",
                            paddingBottom: "35px",
                          }}
                        >
                          <p style={commonCellStyle}>
                            {
                              ticketDetail?.OrderItems?.[0]?.itemDetail
                                ?.itemName
                            }
                          </p>
                          <p style={invoiceStyle}>
                            {
                              ticketDetail?.OrderItems?.[0]?.itemDetail
                                ?.eventTimeLocation?.location
                            }
                          </p>
                          <p style={invoiceStyle}>
                            {
                              ticketDetail?.OrderItems?.[0]?.itemDetail
                                ?.eventTimeLocation?.start_date
                            }
                            ,{" "}
                            {
                              ticketDetail?.OrderItems?.[0]?.itemDetail
                                ?.eventTimeLocation?.start_time
                            }{" "}
                            -
                            {
                              ticketDetail?.OrderItems?.[0]?.itemDetail
                                ?.eventTimeLocation?.end_date
                            }
                            ,{" "}
                            {
                              ticketDetail?.OrderItems?.[0]?.itemDetail
                                ?.eventTimeLocation?.end_time
                            }
                            {/* 11:00 PM - 2:00 AM */}
                          </p>
                        </td>
                        <td></td>
                        <td
                          style={{
                            verticalAlign: "top",
                            paddingBottom: "35px",
                          }}
                        ></td>
                      </tr>
                      <tr>
                        <td style={{ paddingBottom: "13px" }}>
                          <p style={invoiceStyle}>Invoice number</p>
                          <p style={commonCellStyle}>
                            #{ticketDetail?.orderId}
                          </p>
                        </td>
                        <td
                          style={{ textAlign: "center", paddingBottom: "13px" }}
                        ></td>
                        <td style={{ textAlign: "end", paddingBottom: "13px" }}>
                          <p style={invoiceStyle}>Invoice date</p>
                          <p style={commonCellStyle}>
                            {moment(ticketDetail?.createdAt).format(
                              "DD MMMM, YYYY"
                            )}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3">
                          <table style={{ width: "100%", borderSpacing: 0 }}>
                            <thead>
                              <tr style={{ textTransform: "uppercase" }}>
                                <td
                                  style={{
                                    padding: "8px 0",
                                    borderBlock: "1px solid #d7dae0",
                                  }}
                                >
                                  Item Detail
                                </td>
                                <td
                                  style={{
                                    padding: "8px 0",
                                    borderBlock: "1px solid #d7dae0",
                                    width: "40px",
                                  }}
                                >
                                  Qty
                                </td>
                                <td
                                  style={{
                                    padding: "8px 24px 8px 0",
                                    borderBlock: "1px solid #d7dae0",
                                    textAlign: "end",
                                    width: "100px",
                                  }}
                                >
                                  Rate
                                </td>
                                <td
                                  style={{
                                    padding: "8px 0",
                                    borderBlock: "1px solid #d7dae0",
                                    textAlign: "end",
                                    width: "120px",
                                  }}
                                >
                                  Amount
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ paddingBlock: "12px" }}>
                                  <p style={commonCellStyle}>General Ticket</p>
                                </td>
                                <td style={{ paddingBlock: "12px" }}>
                                  <p style={commonCellStyle}>
                                    {ticketDetail?.OrderItems?.[0]?.quantity}
                                  </p>
                                </td>
                                <td
                                  style={{
                                    paddingBlock: "12px",
                                    textAlign: "end",
                                  }}
                                >
                                  <p
                                    style={{
                                      ...commonCellStyle,
                                      paddingRight: "24px",
                                    }}
                                  >
                                    $
                                    {ticketDetail?.OrderItems?.[0]?.price?.toFixed(
                                      2
                                    )}
                                  </p>
                                </td>
                                <td
                                  style={{
                                    paddingBlock: "12px",
                                    textAlign: "end",
                                  }}
                                >
                                  <p style={commonCellStyle}>
                                    $
                                    {ticketDetail?.OrderItems?.[0]?.price?.toFixed(
                                      2
                                    )}
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td style={borderTopStyle}></td>
                                <td style={borderTopStyle} colSpan="3">
                                  <table
                                    style={{ width: "100%", borderSpacing: 0 }}
                                  >
                                    <tbody>
                                      <tr>
                                        <th
                                          style={{
                                            paddingTop: "12px",
                                            textAlign: "start",
                                          }}
                                        >
                                          Subtotal
                                        </th>
                                        <td
                                          style={{
                                            paddingTop: "12px",
                                            textAlign: "end",
                                          }}
                                        >
                                          $
                                          {ticketDetail?.OrderItems?.[0]?.price?.toFixed(
                                            2
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th
                                          style={{
                                            padding: "12px 0",
                                            textAlign: "start",
                                          }}
                                        >
                                          Service Fee
                                        </th>
                                        <td
                                          style={{
                                            padding: "12px 0",
                                            textAlign: "end",
                                          }}
                                        >
                                          $
                                          {ticketDetail?.platformFees?.toFixed(
                                            2
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th style={borderTopStyle}>
                                          Total Price
                                        </th>
                                        <th style={borderTopStyle}>
                                          $
                                          {(
                                            ticketDetail?.OrderItems?.[0]
                                              ?.price +
                                            ticketDetail?.platformFees
                                          ).toFixed(2)}
                                        </th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    verticalAlign: "top",
                                    paddingBottom: "15px",
                                    paddingTop: "35px",
                                    width: "40%",
                                  }}
                                >
                                  <p style={commonCellStyle}>Payment Method</p>
                                  <p style={invoiceStyle}>
                                    {ticketDetail?.cardDetail?.type}
                                  </p>
                                  <p style={invoiceStyle}>
                                    {" "}
                                    {ticketDetail?.cardDetail?.cardNumber
                                      ? `${"***** ".repeat(
                                          3
                                        )} ${ticketDetail?.cardDetail.cardNumber.slice(
                                          -4
                                        )}`
                                      : ""}
                                  </p>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td style={{ paddingTop: "30px" }}>
                  <p
                    style={{
                      display: "flex",
                      gap: "0 13px",
                      lineHeight: "normal",
                    }}
                  >
                    <span style={{ color: "#1a1c21", fontWeight: "700" }}>
                      Jubal Talent
                    </span>
                  </p>
                  <p style={{ color: "#1a1c21", lineHeight: "normal" }}>
                    Any questions, contact customer service at{" "}
                    <a
                      href="mailto:support@jubaltalent.com"
                      style={{ color: "#000" }}
                    >
                      support@jubaltalent.com
                    </a>
                    .
                  </p>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <button
          className="mt-4 btn btn-primary"
          type="button"
          // onClick={downloadTicketAsImage}
          onClick={() => {
            generatePDF(pdfRef, {
              filename: "bill.pdf",
            });
          }}
        >
          Download Ticket
        </button>
      </div>
    </>
  );
}
