import { useEffect, useMemo, useState } from "react"; // Import necessary hooks from React
import { Modal, ModalBody, Spinner } from "reactstrap"; // Import Modal components from Reactstrap
import { handleFetchRequest } from "../../ApiHelper/ApiHelper"; // Import function for API requests
import { ApiUrl } from "../../utility/ApiUrl"; // Import API URLs
import { toast } from "material-react-toastify"; // Import toast for notifications
import { useSelector } from "react-redux"; // Import useSelector for Redux state management

// RatingModal component definition
const RatingModal = ({ open, toggle, getUserData }) => {
  const [userRating, setRating] = useState(null); // State to hold the user's rating
  const [comments, setComments] = useState(""); // State to hold comments from the user
  const [isloading, setIsLoading] = useState(false); // State to manage loading status
  const userDetails = useSelector((store) => store?.user?.userDetails); // Select user details from Redux store

  console.log("open====>>>", open); // Debugging log to check if modal is open

  // Function to handle the rating change when the user selects a rating
  const handleRatingChange = (index) => {
    setRating(index + 1); // Update rating based on index
  };

  // Function to handle the submission of the rating and comments
  const handleSubmit = () => {
    // Prepare review data to be sent to the server
    const reviewData = {
      bookingId: getUserData?.id, // Booking ID from the received user data
      review: comments, // Comments entered by the user
      rating: userRating, // User's rating
      // Determine the user type
      addedBy:
        userDetails?.type === 3
          ? "talent"
          : userDetails?.type === 1
          ? "vendor"
          : "vendor",
    };

    setIsLoading(true); // Set loading status to true
    // Make API call to add the review
    handleFetchRequest(`${ApiUrl?.add_review}`, "POST", reviewData).then(
      (res) => {
        if (res.status === true) {
          // If the response indicates success
          setIsLoading(false); // Reset loading status
          toggle(); // Close the modal
          toast.success(res?.message); // Show success message
        }
      }
    );
  };

  // useEffect to reset rating and comments when the modal is closed
  useEffect(() => {
    if (!open) {
      setRating(null); // Reset rating
      setComments(""); // Clear comments
    }
  }, [open]);

  // Memoized function to return appropriate rating GIF and label based on user rating
  const ratingGif = useMemo(() => {
    if (userRating === 1) {
      return (
        <>
          <img
            src={`${process.env.PUBLIC_URL}/images/rating_icons/1_stars.gif`} // 1-star GIF
            alt="Logo"
            width="40px"
          />
          <p>Terrible</p>
        </>
      );
    } else if (userRating === 2) {
      return (
        <>
          <img
            src={`${process.env.PUBLIC_URL}/images/rating_icons/2_stars.gif`} // 2-star GIF
            alt="Logo"
            width="40px"
          />
          <p>Poor</p>
        </>
      );
    } else if (userRating === 3) {
      return (
        <>
          <img
            src={`${process.env.PUBLIC_URL}/images/rating_icons/3_stars.gif`} // 3-star GIF
            alt="Logo"
            width="40px"
          />
          <p>Average</p>
        </>
      );
    } else if (userRating === 4) {
      return (
        <>
          <img
            src={`${process.env.PUBLIC_URL}/images/rating_icons/4_stars.gif`} // 4-star GIF
            alt="Logo"
            width="40px"
          />
          <p>Good</p>
        </>
      );
    } else if (userRating === 5) {
      return (
        <>
          <img
            src={`${process.env.PUBLIC_URL}/images/rating_icons/5_stars.gif`} // 5-star GIF
            alt="Logo"
            width="40px"
          />
          <p>Excellent</p>
        </>
      );
    }
  }, [userRating]); // Dependency array to update GIF when userRating changes

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className="modal-dialog modal-dialog-centered rating-dialog "
      size="lg"
    >
      <ModalBody className="modal-content create-post">
        <h4>
          How was your experience with{" "}
          <span>{getUserData?.requestDetails?.contactFullName}</span>?
        </h4>
        <div className="row justify-content-center">
          <div className="col-12 p-0">
            <label htmlFor="feedback" className="form-label">
              My performance was?
            </label>
            <div className="rating-stars">
              <div className="d-flex gap-2 align-items-center">
                {[...Array(5)].map((_, index) => (
                  <svg
                    key={index}
                    xmlns="http://www.w3.org/2000/svg"
                    className={index < userRating ? "active" : ""}
                    width={40}
                    height={41}
                    viewBox="0 0 40 41"
                    onClick={() => handleRatingChange(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <path
                      d="M20 8.79199L23.708 16.3619L32 17.5833L26 23.4723L27.416 31.792L20 27.8619L12.584 31.792L14 23.4723L8 17.5833L16.292 16.3619L20 8.79199Z"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill={index < userRating ? "active" : "none"}
                    />
                  </svg>
                ))}
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center gap-1 reaction">
                {ratingGif}
              </div>
            </div>
          </div>
          <div className="col-12 p-0">
            <label htmlFor="feedback" className="form-label">
              Add your comments
            </label>
            <textarea
              className="form-control"
              id="feedback"
              rows={3}
              placeholder="Your comments"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              maxLength={500}
            />
          </div>
          <div className="col-8 p-0">
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={handleSubmit}
            >
              {isloading ? <Spinner /> : "Submit"}
            </button>
          </div>
          <div className="col-8 p-0">
            <button
              type="button"
              className="btn btn-light w-100"
              onClick={toggle}
            >
              I’ll do it later
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RatingModal;
