import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import { useForm } from "react-hook-form";
import { ApiUrl } from "../../../utility/ApiUrl";
import { useEffect, useState } from "react";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { RoutesLink } from "../../../utility/RoutesLink";

const TalentSignUpS1 = () => {
  // Hook to access the current location (state) and navigate programmatically
  const location = useLocation();

  // State to hold the list of data (categories/services) and selected data
  const [listData, setListData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // useForm hook from react-hook-form for form handling

  // Logging the location state for debugging
  console.log(location?.state);

  // Effect to fetch data based on the user type and set selected data
  useEffect(() => {
    setSelectedData(location?.state?.category_service_data); // Set previously selected data

    // Determine the API URL based on the user type
    let url =
      location?.state?.usertype === 2
        ? ApiUrl?.get_services // If usertype is 2, fetch services
        : ApiUrl?.get_categories; // Else, fetch categories

    // Fetch data from the determined URL
    handleFetchRequest(url).then((response) => {
      if (response?.status === true) {
        setListData(response?.data); // Update listData with the fetched data
      } else {
        // Handle error case if needed
      }
    });

    // Optional: Check for back data in location state (not implemented yet)
    if (location?.state?.back) {
      // Future implementation can go here
    }
  }, []);

  // Handler for adding/removing items from the selectedData array based on checkbox status
  const addHandler = (event, id) => {
    if (event.target.checked) {
      // If checkbox is checked, add the id to selectedData
      setSelectedData((preSelect) => [...(preSelect || []), id]);
    } else {
      // If unchecked, remove the id from selectedData
      let newData = (selectedData || [])?.filter((data) => {
        return data !== id; // Filter out the unchecked id
      });
      setSelectedData(newData);
    }
  };

  // Function to check if an id is already in selectedData
  const checkHandler = (data) => {
    if (Array.isArray(selectedData)) {
      // Ensure selectedData is an array
      const checkedData = selectedData.find(
        (selectId) => (selectId === data?.catagory ? true : false) // Check if the category matches
      );
      return checkedData; // Return the checked status
    } else {
      console.log("Error is Empty"); // Log an error if selectedData is not an array
    }
  };

  // Handler for updating the search term and filtering the listData based on user input
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // Update the search term
    // Perform filtering logic here
    const results = listData.filter(
      (category) => console.log("catt", category)
      // Uncomment the following line to filter based on the search term
      // category.category.toLowerCase().includes(event.target.value.toLowerCase())
    );
    // setListData(results); // Update listData with filtered results (if needed)
  };

  return (
    <>
      <Header />
      <section className="signin navbar-spc">
        <div className="max-width-container h-100">
          <div className="row h-100 justify-content-between justify-content-lg-center">
            <div className="col-12 col-md-5 col-lg-5 col-xl-6 p-0 d-none d-md-flex align-items-center justify-content-center position-relative">
              <div className="img-box">
                <img src="images/sign_in/frame.png" alt="" className="frame" />
                <img
                  src="images/sign_in/sign-img2.jpg"
                  alt=""
                  className=" content-img"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-6 p-0 d-flex justify-content-center align-items-center">
              <div className="card signin-card">
                <div className="card-body">
                  <div className="tab-content" id="myTabContent">
                    {location?.state?.usertype === 3 ? (
                      <div
                        className="tab-pane fade show active"
                        id="Talent-tab-pane"
                        role="tabpanel"
                        aria-labelledby="Talent-tab"
                        tabindex="0"
                      >
                        <div className="row tab-descp">
                          <div className="col-12 p-0">
                            <h4>Talent Community</h4>
                            <p>
                              If you would like to proceed with signing up as a
                              Jubal Talent, you will be part of the Jubal
                              Talents community granting you access to
                              performance and networking opportunities
                            </p>
                          </div>
                          <div className="col-12 p-0 cat-detl">
                            <h4>
                              We’d like to know about your Primary Category
                            </h4>
                            <p>Your music primary preferences</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="row tab-descp">
                          <div className="col-12 p-0">
                            <h4>Vendor</h4>
                            <p>
                              Become a Jubal Vendor and unlock all the features
                              and benefits of the Jubal Community. Sell tickets,
                              find and hire talent, promote your artist, create
                              events, and more.
                            </p>
                          </div>

                          <div className="col-12 p-0 cat-detl">
                            <h4>
                              What type of product or services you provided
                            </h4>
                          </div>
                        </div>
                      </>
                    )}
                    <form>
                      <div className="row">
                        <div className="col-12 p-0">
                          <div className="d-flex align-items-center passfield-wrapper">
                            <span className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={18}
                                height={18}
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M17.71 16.2871L14.31 12.8971C15.407 11.4995 16.0022 9.77373 16 7.99707C16 6.41482 15.5308 4.8681 14.6518 3.55251C13.7727 2.23692 12.5233 1.21154 11.0615 0.606038C9.59966 0.000536561 7.99113 -0.15789 6.43928 0.150792C4.88743 0.459473 3.46197 1.2214 2.34315 2.34022C1.22433 3.45904 0.462403 4.8845 0.153721 6.43635C-0.15496 7.9882 0.00346625 9.59673 0.608967 11.0585C1.21447 12.5204 2.23985 13.7698 3.55544 14.6488C4.87103 15.5279 6.41775 15.9971 8 15.9971C9.77666 15.9993 11.5025 15.4041 12.9 14.3071L16.29 17.7071C16.383 17.8008 16.4936 17.8752 16.6154 17.926C16.7373 17.9767 16.868 18.0029 17 18.0029C17.132 18.0029 17.2627 17.9767 17.3846 17.926C17.5064 17.8752 17.617 17.8008 17.71 17.7071C17.8037 17.6141 17.8781 17.5035 17.9289 17.3817C17.9797 17.2598 18.0058 17.1291 18.0058 16.9971C18.0058 16.8651 17.9797 16.7344 17.9289 16.6125C17.8781 16.4906 17.8037 16.38 17.71 16.2871ZM2 7.99707C2 6.81039 2.3519 5.65035 3.01119 4.66365C3.67047 3.67696 4.60755 2.90792 5.7039 2.4538C6.80026 1.99967 8.00666 1.88085 9.17055 2.11236C10.3344 2.34387 11.4035 2.91532 12.2426 3.75443C13.0818 4.59355 13.6532 5.66265 13.8847 6.82653C14.1162 7.99042 13.9974 9.19682 13.5433 10.2932C13.0892 11.3895 12.3201 12.3266 11.3334 12.9859C10.3467 13.6452 9.18669 13.9971 8 13.9971C6.4087 13.9971 4.88258 13.3649 3.75736 12.2397C2.63214 11.1145 2 9.58837 2 7.99707Z"
                                  fill="#818898"
                                />
                              </svg>
                            </span>
                            <input
                              type="text"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              className="form-control"
                              id="searchbar"
                              placeholder="Search services"
                            />
                            <span className="visi">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={21}
                                height={12}
                                viewBox="0 0 21 12"
                                fill="none"
                              >
                                <path
                                  d="M8.31407 11.9026H12.9271V9.93506H8.31407V11.9026ZM0.241211 0.0974121V2.06494H21V0.0974121H0.241211ZM3.70101 6.98376H17.5402V5.01624H3.70101V6.98376Z"
                                  fill="#818898"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                        <div
                          className="col-12 p-0"
                          style={{ height: "400px", overflow: "auto" }}
                        >
                          {listData
                            ?.filter((item) =>
                              location?.state?.usertype === 2
                                ? item?.service
                                    ?.toLowerCase()
                                    ?.includes(searchTerm.toLowerCase())
                                : item?.catagory
                                    ?.toLowerCase()
                                    ?.includes(searchTerm.toLowerCase())
                            )
                            ?.sort((a, b) =>
                              location?.state?.usertype === 2
                                ? a.service.localeCompare(b.service)
                                : a.catagory.localeCompare(b.catagory)
                            )
                            ?.map((data, index) => (
                              <div
                                key={index}
                                className="form-check d-flex align-items-center justify-content-between"
                              >
                                <label
                                  className="form-check-label"
                                  for={`musicPro-${index}`}
                                >
                                  {data?.service} {data?.catagory}
                                </label>

                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={data?.id}
                                  id={`musicPro-${index}`}
                                  onChange={(event) =>
                                    addHandler(
                                      event,
                                      data?.catagory
                                        ? data?.catagory
                                        : data?.service
                                    )
                                  }
                                  checked={checkHandler(data)}
                                  // checked={(location?.state?.category_service_data.every(selectId => selectId.id === data.id))}
                                />
                              </div>
                            ))}
                        </div>
                        <div class="col-12 px-0">
                          <div class="row">
                            <div class="col-6 ps-0 pe-4">
                              <Link
                                to={RoutesLink?.talent_sign_up}
                                state={{ back: location?.state }}
                                // href="talent_sign_up.html"
                                className="btn btn-light btn-back w-100"
                              >
                                Back
                              </Link>
                              {/* <Link
                                to={RoutesLink?.talent_sign_up}
                                state={location?.state}
                                // href="talent_sign_up.html"
                                class="btn btn-light btn-back w-100"
                              >
                                Back
                              </Link> */}
                            </div>
                            <div className="col-6 ps-4 pe-0">
                              <Link
                                to={RoutesLink?.talent_sign_up_s2}
                                state={{
                                  ...location?.state,
                                  category_service_data: selectedData,
                                }}
                                // href="talent_sign_up s2.html"
                                className="btn btn-primary w-100"
                              >
                                Continue
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default TalentSignUpS1;
