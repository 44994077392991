import React from "react";

function SearchBarLoader() {
  return (
    <div className="row">
      <div className="Loader col-lg-12">
        <div className="wrapper-cell">
          <div className="text" style={{ padding: "10px" }}>
            <div className=""></div>
            <div className="text-line"> </div>
            <div className="text-line"></div>
            <div className="text-line"></div>
            <div className="text-line"></div>
            <div className="text-line"></div>
            <div className="text-line"></div>
            <div className="text-line"></div>
            <div className="text-line"></div>
            <div className="text-line"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchBarLoader;
