import React, { useEffect } from "react";
import MobileHeader from "../../Components/Layout/MobileHeader";
import { Footer } from "rsuite";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RoutesLink } from "../../utility/RoutesLink";

function EventOrderSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = useSelector((store) => store?.user?.userDetails);
  console.log("loc data", location?.state);

  useEffect(() => {
    const handlePopState = (event) => {
      // Redirect to dashboard when the back button is pressed
      navigate("/dashboard"); // Replace with your actual dashboard route
    };

    // Listen for the popstate event (back button)
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);
  return (
    <>
      <MobileHeader />
      <section className="px-0 main-content">
        <div className="max-width-container">
          <div className="d-flex align-items-center justify-content-center h-100 w-100">
            <div className="card confirmed-card">
              <div className="card-body">
                <div className="confirmed">
                  <img src="images/order-success.gif" alt="" />
                  <span>
                    Hey {userDetails?.Profile?.firstName}{" "}
                    {userDetails?.Profile?.lastName}
                  </span>
                  <h2>Your order confirmed!</h2>
                  <p>
                    We’ll send you a confirmation email as soon as your order
                    ships
                  </p>
                  <span>Order Id: #{location?.state?.order?.orderId}</span>
                </div>
                <div className="ordered-items">
                  <div className="items-wrapper">
                    <h3>
                      Order items : {location?.state?.orderItem?.quantity}
                    </h3>

                    <div className="item">
                      <img
                        src={location?.state?.orderItem?.itemDetail?.itemImage}
                        alt=""
                      ></img>
                      <div className="d-flex flex-column gap-1 gap-md-2 gap-md-3 w-100">
                        <div className="d-flex justify-content-between gap-2 gap-md-3">
                          <h4>
                            {location?.state?.orderItem?.itemDetail?.itemName}
                          </h4>
                        </div>
                        <h3>${location?.state?.orderItem?.price}</h3>
                      </div>
                    </div>

                    <div className="d-flex align-items-center gap-3">
                      <button
                        className="btn btn-light"
                        onClick={() => navigate(RoutesLink?.dashboard)}
                      >
                        Go to home
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default EventOrderSuccess;
