import React from "react";
import { OrderHelperStatus, getStatusDate } from "../../Helper/Helper";
import moment from "moment";

// Assuming getStatusDate and OrderHelperStatus are defined elsewhere
const OrderTimeline = ({ item }) => {
  const arr = [];
  const statusList = item?.Statuses;

  const placedStatus = statusList?.find(
    (status) => status?.status == OrderHelperStatus.placed
  );

  if (placedStatus?.id) {
    arr.push({
      title: "Placed",
      date: getStatusDate(placedStatus?.createdAt),
      isActive: true,
    });
  }

  if (item?.status === OrderHelperStatus.placed) {
    arr.push({ title: "Confirmed", date: "" });
    arr.push({ title: "Shipped", date: "" });
    arr.push({ title: "Delivered", date: "" });
  }

  if (item?.status === OrderHelperStatus.confirmed) {
    const confirmStatus = statusList?.find(
      (status) => status?.status === OrderHelperStatus.confirmed
    );
    arr.push({
      title: "Confirmed",
      date: getStatusDate(confirmStatus?.createdAt),
      isActive: true,
    });
    arr.push({ title: "Shipped", date: "" });
    arr.push({ title: "Delivered", date: "" });
  }

  if (item?.status === OrderHelperStatus.shipped) {
    const confirmStatus = statusList?.find(
      (status) => status?.status === OrderHelperStatus.confirmed
    );
    const shipmentStatus = statusList?.find(
      (status) => status?.status === OrderHelperStatus.shipped
    );
    const expDeliveryDate = item?.expectedDeliveryDate
      ? getStatusDate(item?.expectedDeliveryDate)
      : "";
    arr.push({
      title: "Confirmed",
      date: getStatusDate(confirmStatus?.createdAt),
      isActive: true,
    });
    arr.push({
      title: "Shipped",
      date: getStatusDate(shipmentStatus?.createdAt),
      isActive: true,
    });
    arr.push({ title: "Delivered", date: expDeliveryDate });
  }

  if (item?.status === OrderHelperStatus.delivered) {
    const confirmStatus = statusList?.find(
      (status) => status?.status === OrderHelperStatus.confirmed
    );
    const shipmentStatus = statusList?.find(
      (status) => status?.status === OrderHelperStatus.shipped
    );
    const deliveredStatus = statusList?.find(
      (status) => status?.status === OrderHelperStatus.delivered
    );
    arr.push({
      title: "Confirmed",
      date: getStatusDate(confirmStatus?.createdAt),
      isActive: true,
    });
    arr.push({
      title: "Shipped",
      date: getStatusDate(shipmentStatus?.createdAt),
      isActive: true,
    });
    arr.push({
      title: "Delivered",
      date: getStatusDate(deliveredStatus?.createdAt),
      isActive: true,
    });
  }

  if (item?.status === OrderHelperStatus.refund_requested) {
    const refundRequestedStatus = statusList?.find(
      (status) => status?.status === OrderHelperStatus.refund_requested
    );
    arr.push({
      title: "Refund initiated",
      date: getStatusDate(refundRequestedStatus?.createdAt),
      isActive: true,
    });
    arr.push({
      title: "Refund in process",
      date: "",
      isActive: true,
      isRefundRequested: true,
      yelloColor: true,
    });
    arr.push({ title: "Refunded", date: "", yelloColor: true });
  }

  if (item?.status === OrderHelperStatus.refunded) {
    const refundRequestedStatus = statusList?.find(
      (status) => status?.status === OrderHelperStatus.refund_requested
    );
    const refundedStatus = statusList?.find(
      (status) => status?.status === OrderHelperStatus.refunded
    );
    arr.push({
      title: "Refund requested",
      date: getStatusDate(refundRequestedStatus?.createdAt),
      isActive: true,
      isRefundRequested: true,
    });
    arr.push({
      title: "Refunded",
      date: getStatusDate(refundedStatus?.createdAt),
      isActive: true,
    });
  }

  if (item?.status === OrderHelperStatus.refundCancelled) {
    const refundRequestedStatus = statusList?.find(
      (status) => status?.status === OrderHelperStatus.refund_requested
    );
    const refundedStatus = statusList?.find(
      (status) => status?.status === OrderHelperStatus.refundCancelled
    );
    arr.push({
      title: "Refund requested",
      date: getStatusDate(refundRequestedStatus?.createdAt),
      isActive: true,
      isRefundRequested: true,
    });
    arr.push({
      title: "Cancelled",
      date: getStatusDate(refundedStatus?.createdAt),
      isActive: true,
      isCancelled: true,
    });
  }

  if (item?.status === OrderHelperStatus.cancelled) {
    const cancelledStatus = statusList?.find(
      (status) => status?.status === OrderHelperStatus.placed
    );
    if (cancelledStatus?.id) {
      arr.push({
        title: "Cancelled",
        date: getStatusDate(placedStatus?.updatedAt),
        isActive: true,
        isCancelled: true,
      });
    }
  }

  console.log("arrr", arr);

  return (
    arr &&
    arr.map((res) => {
      console.log("res.title", res);
      return (
        <div
          key={res.status}
          className={`step ${res?.isActive ? "active" : ""} ${
            res?.yelloColor ? "refund refunded" : ""
          }`}
        >
          <div className="date">
            <h6>{res.title}</h6>
            <span>{res?.date}</span>
          </div>
        </div>
      );
    })
  );
};

export default OrderTimeline;
