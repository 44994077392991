import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { RoutesLink } from "../../../utility/RoutesLink";
import {
  SocialLinks,
  validateSocialLink,
} from "../../../Helper/SocialLinkHelper";

const TalentSignUpS4 = () => {
  // Hook for programmatic navigation
  const navigate = useNavigate();
  // Hook to get the current location object
  const location = useLocation();

  console.log("ccccc", location?.state);
  // State variables for managing data
  const [imageUrl, setImageUrl] = useState(); // Holds the uploaded image file
  const [selectData, setSelectedData] = useState({}); // Holds selected data
  const [addLink, setAddLink] = useState(false); // Flag to control adding links
  const [addedLinks, setAddedLinks] = useState([]); // Array of added social links
  const [selectedLinkType, setSelectedLinkType] = useState(""); // Currently selected link type
  const [linkvalues, setLinkValues] = useState([]); // Array of social link values
  const [linkError, setLinkError] = useState(null); // Error message for link validation

  // React Hook Form setup for managing form state
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Handler for uploading an image
  const imageUploadHandler = (e) => {
    const file = e.target.files[0]; // Get the uploaded file
    setImageUrl(file); // Set the image file in state
  };

  console.log(location?.state); // Log location state for debugging

  // Function to add more social links
  const addMoreLink = () => {
    if (linkError == null) {
      // Proceed only if there are no errors
      setAddLink(true); // Set flag to allow adding links
    }
  };

  // Handler for changing link type
  const handleLinkTypeChange = (e) => {
    const newLinkType = e.target.value; // Get selected link type
    if (newLinkType && !addedLinks.includes(newLinkType)) {
      // If link type is new, add it to the list
      setAddedLinks([...addedLinks, newLinkType]);
    }
    setSelectedLinkType(""); // Reset the selected link type
    setAddLink(false); // Reset the add link flag
  };

  // Handler for social link input changes
  const socialLinkChange = (e, link) => {
    const isValid = validateSocialLink(link.type, e.target.value); // Validate the URL

    // Set error message if the URL is invalid
    if (!isValid) {
      setLinkError("Please enter valid url");
    } else {
      setLinkError(null); // Clear error if valid
    }
    console.log(linkError); // Log current error state for debugging

    // Prepare link data for storage
    const linkData = {
      title: e.target.value,
      value: e.target.value,
      type: link?.type,
    };

    // Update link values in state
    setLinkValues((prevValues) => {
      const existingLinkIndex = prevValues.findIndex(
        (item) => item.type === link.type // Check if the link type already exists
      );

      if (existingLinkIndex > -1) {
        // If it exists, update the existing entry
        const updatedValues = [...prevValues];
        updatedValues[existingLinkIndex] = linkData;
        return updatedValues; // Return updated values
      } else {
        // If it doesn't exist, add a new entry
        return [...prevValues, linkData];
      }
    });
  };

  // Handler for form submission
  const onSubmit = (data) => {
    // Create an array of social URLs from the form data
    let socialUrl = [
      data?.insta_link,
      data?.fb_link,
      data.apple_link,
      data?.twitter_link,
    ];
    console.log("onSubmit", socialUrl); // Log submitted social URLs for debugging

    // Navigate to the next step with social URLs and image file in state
    navigate(RoutesLink?.talent_sign_up_s5, {
      state: {
        ...location?.state,
        socialUrl: linkvalues,
        post: imageUrl,
      },
    });
  };

  console.log(addedLinks); // Log added links for debugging

  // Effect to ensure at least one link type ("instagram") is present
  useEffect(() => {
    if (addedLinks?.length < 1) {
      setAddedLinks([...addedLinks, "instagram"]); // Add "instagram" if no links are present
    }
  }, [addedLinks]);

  return (
    <>
      <Header />
      <section className="signin navbar-spc">
        <div className="max-width-container h-100">
          <div className="row h-100 justify-content-between justify-content-lg-center">
            <div className="col-12 col-md-5 col-lg-5 col-xl-6 p-0 d-none d-md-flex align-items-center justify-content-center position-relative">
              <div className="img-box">
                <img src="images/sign_in/frame.png" alt="" className="frame" />
                <img
                  src="images/sign_in/sign-img2.jpg"
                  alt=""
                  className=" content-img"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-6 p-0 d-flex justify-content-center align-items-center">
              <div className="card signin-card">
                <div className="card-body">
                  {/* <ul className="nav nav-pills card-tabs" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="Talent-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Talent-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Talent-tab-pane"
                      aria-selected="true"
                    >
                      Talent
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      id="Vendor-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Vendor-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Vendor-tab-pane"
                      aria-selected="false"
                    >
                      Vendor
                    </button>
                  </li>
                </ul> */}
                  <div className="tab-content" id="myTabContent">
                    {location?.state?.usertype === 3 && (
                      <div
                      // className="tab-pane fade show active"
                      // id="Talent-tab-pane"
                      // role="tabpanel"
                      // aria-labelledby="Talent-tab"
                      // tabindex="0"
                      >
                        <div className="row tab-descp">
                          <div className="col-12 p-0">
                            <h4>Talent Community</h4>
                            <p>
                              If you would like to proceed with signing up as a
                              Jubal Talent, you will be part of the Jubal
                              Talents community granting you access to
                              performance and networking opportunities
                            </p>
                          </div>
                          <div className="col-12 p-0 cat-detl">
                            <h4>Finalize your account</h4>
                            <p>
                              Let’s share something related to your talent
                              priorities
                            </p>
                          </div>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div class="row">
                            {SocialLinks?.map(
                              (socialLink, index) =>
                                addedLinks.includes(socialLink.type) && (
                                  <div className="col-12 p-0" key={index}>
                                    <div className="d-flex align-items-center passfield-wrapper loctn">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={socialLink.type}
                                        // value={socialLink.value}
                                        onChange={(e) =>
                                          socialLinkChange(e, socialLink)
                                        }
                                        placeholder={socialLink.title}
                                      />
                                      <span className="visi smlogo">
                                        {socialLink.icon}
                                      </span>
                                    </div>
                                  </div>
                                )
                            )}

                            {addLink && (
                              <div className="col-12 px-0">
                                <label className="form-label">
                                  Select link type
                                </label>
                                <select
                                  className="form-select"
                                  // value={selectedLinkType}
                                  onChange={handleLinkTypeChange}
                                >
                                  <option value="">Select a type</option>
                                  {SocialLinks?.filter(
                                    (option) =>
                                      !addedLinks.includes(option.type)
                                  ).map((option) => (
                                    <option
                                      key={option.type}
                                      // value={option.type}
                                    >
                                      {option.type}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                            {linkError == null ? (
                              ""
                            ) : (
                              <span className="form-text text-danger">
                                {linkError}
                              </span>
                            )}
                            <div className="col-12 px-0">
                              <p className="text-center">
                                <button
                                  className="btn btn-light"
                                  type="button"
                                  onClick={addMoreLink}
                                >
                                  {linkvalues?.length > 0
                                    ? "Add more link"
                                    : "Add link"}
                                </button>
                              </p>
                            </div>

                            {/* <div class="col-12 p-0">
                              <div class="d-flex align-items-center passfield-wrapper loctn">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="fb"
                                  placeholder="https://facebook.com"
                                  {...register("fb_link", {
                                    // required: "Please enter first name",
                                  })}
                                />
                                <span className="visi smlogo">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="29"
                                    height="29"
                                    viewBox="0 0 29 29"
                                    fill="none"
                                  >
                                    <circle
                                      cx="14.2208"
                                      cy="14.486"
                                      r="13.91"
                                      fill="#F1F1F1"
                                    />
                                    <path
                                      d="M16.2222 10.4535H17.357V8.53387C16.8076 8.47673 16.2554 8.44852 15.703 8.44936C14.061 8.44936 12.9382 9.45144 12.9382 11.2866V12.8682H11.085V15.0172H12.9382V20.5227H15.1597V15.0172H17.0069L17.2846 12.8682H15.1597V11.4979C15.1597 10.864 15.3287 10.4535 16.2222 10.4535Z"
                                      fill="#818898"
                                    />
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="d-flex align-items-center passfield-wrapper loctn">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="applm"
                                  placeholder="https://applemusic.com"
                                  {...register("apple_link", {
                                    // required: "Please enter first name",
                                  })}
                                />
                                <span className="visi smlogo">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="29"
                                    height="29"
                                    viewBox="0 0 29 29"
                                    fill="none"
                                  >
                                    <circle
                                      cx="14.2208"
                                      cy="14.306"
                                      r="13.91"
                                      fill="#F1F1F1"
                                    />
                                    <path
                                      d="M18.5891 9.46624C18.5891 9.1972 18.439 9.03972 18.177 9.05809C18.0974 9.05809 17.5295 9.17139 12.8819 10.0581C12.8456 10.0581 12.6724 10.1045 12.5766 10.1859C12.4829 10.262 12.4326 10.3639 12.4143 10.4847C12.3368 10.8005 12.5258 16.4871 12.2957 16.754C12.1955 16.8677 12.077 16.9001 11.9405 16.9259C11.1224 17.072 10.5782 17.1486 10.1906 17.5243C9.52305 18.1875 9.86952 19.3451 10.8538 19.5332C11.2226 19.6023 11.916 19.5074 12.2983 19.2917C12.6356 19.115 12.8907 18.7974 12.9865 18.3752C13.0687 18.013 13.0433 18.2637 13.0433 12.8181C13.0433 12.5512 13.1164 12.4817 13.3487 12.4305C13.3487 12.4305 17.3935 11.6601 17.5798 11.6278C17.8419 11.5818 17.9674 11.6536 17.9674 11.9134C17.9674 15.6048 18.0129 15.533 17.8673 15.6997C17.7671 15.8134 17.6485 15.8458 17.512 15.8716C16.694 16.0177 16.1498 16.0943 15.7622 16.4701C15.2727 16.957 15.2797 17.73 15.8239 18.1959C16.2701 18.5625 16.7351 18.5371 17.2933 18.4325C17.9289 18.3118 18.4032 17.9544 18.5488 17.3187L18.5484 17.3196C18.6144 17.0296 18.5934 17.4587 18.5891 9.46624Z"
                                      fill="#818898"
                                    />
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="d-flex align-items-center passfield-wrapper loctn">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="fb"
                                  placeholder="https://twitter.com"
                                  {...register("twitter_link", {
                                    // required: "Please enter first name",
                                  })}
                                />
                                <span className="visi smlogo">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="29"
                                    height="29"
                                    viewBox="0 0 29 29"
                                    fill="none"
                                  >
                                    <circle
                                      cx="14.2208"
                                      cy="14.1261"
                                      r="13.91"
                                      fill="#F1F1F1"
                                    />
                                    <path
                                      d="M9.15748 9.396L13.373 15.0326L9.13086 19.6153H10.0856L13.7996 15.603L16.8004 19.6153H20.0494L15.5967 13.6617L19.5453 9.396H18.5905L15.1701 13.0912L12.4065 9.396H9.15748ZM10.5615 10.0993H12.0541L18.6452 18.912H17.1526L10.5615 10.0993Z"
                                      fill="black"
                                      stroke="#818898"
                                      strokeWidth="0.5"
                                    />
                                  </svg>
                                </span>
                              </div>
                            </div> */}

                            {/* <div class="col-12 p-0">
                              <label class="form-label uploadInput-label">
                                Upload your post/performance video
                              </label>

                              <div className="d-flex align-items-center passfield-wrapper loctn">
                                <label
                                  for="uplaod"
                                  className="w-100 uploadInput"
                                >
                                  {imageUrl ? imageUrl?.name : "Upload"}
                                </label>

                                <input
                                  type="file"
                                  className="form-control d-none"
                                  id="uplaod"
                                  onChange={imageUploadHandler}
                                  // {...register("upload_video", {
                                  //   // required: "Please enter first name",
                                  // })}'
                                />
                                <span className="visi">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13"
                                    height="14"
                                    viewBox="0 0 13 14"
                                    fill="none"
                                  >
                                    <path
                                      d="M0.986328 10.1318V11.9893C0.986328 12.3177 1.11679 12.6326 1.34901 12.8649C1.58124 13.0971 1.8962 13.2275 2.22461 13.2275H10.8926C11.221 13.2275 11.536 13.0971 11.7682 12.8649C12.0004 12.6326 12.1309 12.3177 12.1309 11.9893V10.1318M9.03516 3.32129L6.55859 0.844727M6.55859 0.844727L4.08203 3.32129M6.55859 0.844727V9.5127"
                                      stroke="#818898"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                              </div>
                            </div> */}

                            <div className="col-12 px-0">
                              <div className="row">
                                <div className="col-6 ps-0 pe-4">
                                  <Link
                                    onClick={() => navigate(-1)}
                                    // to={RoutesLink?.talent_sign_up_s3}
                                    // state={{ ...location?.state }}
                                    className="btn btn-light btn-back w-100"
                                  >
                                    Back
                                  </Link>
                                </div>
                                <div className="col-6 ps-4 pe-0">
                                  <button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                  >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                    {location?.state?.usertype === 2 && (
                      <div
                      // className="tab-pane fade"
                      // id="Vendor-tab-pane"
                      // role="tabpanel"
                      // aria-labelledby="Vendor-tab"
                      // tabindex="0"
                      >
                        <div className="row tab-descp">
                          <div className="col-12 p-0">
                            <h4>Vendor</h4>
                            <p>
                              Become a Jubal Vendor and unlock all the features
                              and benefits of the Jubal Community. Sell tickets,
                              find and hire talent, promote your artist, create
                              events, and more.
                            </p>
                          </div>

                          <div className="col-12 p-0 cat-detl">
                            <h4>Finalize your account</h4>
                            <p>
                              Let’s share something related to your talent
                              priorities
                            </p>
                          </div>
                        </div>

                        <form action="">
                          <div className="row">
                            <div className="col-12 p-0">
                              <label for="file" className="form-label w-100">
                                <div className="fileUploadBox d-flex justify-content-center flex-column align-items-center">
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="41"
                                      height="41"
                                      viewBox="0 0 41 41"
                                      fill="none"
                                    >
                                      <path
                                        d="M20.6308 0.755859C9.76922 0.755859 0.944336 9.58074 0.944336 20.4423C0.944336 31.3038 9.76922 40.1287 20.6308 40.1287C31.4923 40.1287 40.3172 31.3038 40.3172 20.4423C40.3172 9.58074 31.4923 0.755859 20.6308 0.755859ZM20.6308 2.54553C30.5259 2.54553 38.5275 10.5481 38.5275 20.4423C38.5275 22.2302 38.259 23.9527 37.7723 25.5804L32.4489 20.257C31.9075 19.7157 31.1907 19.4445 30.474 19.4445C29.7572 19.4445 29.0404 19.7157 28.4991 20.257L23.7627 24.9934L15.447 16.6777C14.9056 16.1363 14.1888 15.8652 13.4721 15.8652C12.7553 15.8652 12.0385 16.1363 11.4971 16.6777L3.3076 24.8672C2.93265 23.4219 2.73996 21.9354 2.73401 20.4423C2.73401 10.5472 10.7356 2.54553 20.6308 2.54553ZM13.4721 17.6441C13.7271 17.6441 13.9821 17.7426 14.1817 17.943L22.4974 26.2587L17.3136 31.4425C17.1506 31.6113 17.0604 31.8373 17.0624 32.0719C17.0645 32.3066 17.1586 32.531 17.3245 32.6969C17.4904 32.8628 17.7148 32.9569 17.9495 32.959C18.1841 32.961 18.4101 32.8708 18.5789 32.7078L29.7644 21.5223C29.8567 21.4276 29.967 21.3523 30.0889 21.3008C30.2107 21.2494 30.3417 21.2229 30.474 21.2229C30.6062 21.2229 30.7372 21.2494 30.8591 21.3008C30.9809 21.3523 31.0913 21.4276 31.1836 21.5223L37.1074 27.4462C34.3844 33.8514 28.0373 38.339 20.6308 38.339C12.9772 38.339 6.47443 33.5427 3.91162 26.7938L12.7616 17.9439C12.962 17.7434 13.217 17.645 13.4721 17.645V17.6441Z"
                                        fill="#CAC8C8"
                                      />
                                      <path
                                        d="M20.6309 6.12524C18.1701 6.12524 16.1567 8.13863 16.1567 10.5994C16.1567 13.0602 18.1701 15.0736 20.6309 15.0736C23.0917 15.0736 25.1051 13.0602 25.1051 10.5994C25.1051 8.13863 23.0917 6.12524 20.6309 6.12524ZM20.6309 7.91492C22.1244 7.91492 23.3154 9.10595 23.3154 10.5994C23.3154 12.0929 22.1244 13.2839 20.6309 13.2839C19.1374 13.2839 17.9464 12.0929 17.9464 10.5994C17.9464 9.10595 19.1374 7.91492 20.6309 7.91492Z"
                                        fill="#CAC8C8"
                                      />
                                    </svg>
                                  </span>

                                  <p>Upload here</p>
                                </div>
                              </label>
                              <input
                                type="file"
                                className="form-control d-none"
                                id="file"
                              />
                            </div>

                            <div className="col-12 px-4">
                              <buttom className="btn btn-primary w-100">
                                Upload company logo
                              </buttom>
                            </div>
                            <div className="col-12 px-4">
                              <Link
                                to={RoutesLink?.talent_sign_up_s3}
                                state={{ ...location?.state }}
                                className="btn btn-light btn-back w-100"
                              >
                                Back
                              </Link>
                            </div>

                            {/* <!-- <div className="col-12 px-0">
                          <div className="row">
                            <div className="col-6 ps-0 pe-4">
                              <button className="btn btn-light btn-back w-100">
                                Back
                              </button>
                            </div>
                            <div className="col-6 ps-4 pe-0">
                              <buttom className="btn btn-primary w-100"
                              >Upload company logo</buttom
                              >
                            </div>
                          </div>
                        </div> --> */}
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TalentSignUpS4;
