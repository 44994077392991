// src/reducers/cartReducer.js

import { ADD_ITEM_TO_CART, REMOVE_ITEM_FROM_CART } from "../types";

const initialState = {
  items: [],
  itemCount: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM_TO_CART: {
      return {
        ...state,
        items: [...state.items, action.payload],
        itemCount: state.items.length + 1, // Increment item count
      };
    }
    case REMOVE_ITEM_FROM_CART: {
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload.id),
        itemCount: state.items.length - 1, // Decrement item count
      };
    }
    default:
      return state;
  }
};

export default cartReducer;
